import React, { useEffect, useState } from "react";
import { Col, Image, Form } from "react-bootstrap";
import { Awrds } from "../assets/img";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../constants/Services";
import moment from "moment/moment";
import ErrorModel from "../constants/Services/Api/errorModel";
import ReactPaginate from "react-paginate";
const data = [
  {
    img: Awrds,
    award: "Award titile 1",
    issues: " Global Logistics Company",
    date: "16-10-2022",
  },
  {
    img: Awrds,
    award: "Certificate 2",
    issues: " Global Logistics Company",
    date: "16-10-2022",
  },
];

const McpAward = ({}) => {
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permissions");
  const [Awards, setAwards] = useState([]);
  const [pages, setPages] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const removeElement = (index) => {
    const newAwards = Awards.filter((_, i) => i !== index);
    setAwards(newAwards);
  };

  const getList = () => {
    let params;

    if (pages !== null) {
      params = `?type=2&page=${pages}`;
    } else {
      params = "?type=2";
    }
    api.certificate_awardList(params).then((res) => {
      if (res?.status == "success") {
        let temp = res?.certificate_awards.filter((i) => i?.document_type != 1);
        setAwards(res?.certificate_awards?.reverse());
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
  };

  const deleteCertificate = (id) => {
    // console.log(id);
    api.certificate_awardDelete(id).then((res) => {
      if (res?.status == "success") {
        getList();
        setError(res);
        setErrorBox(true);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (pages !== null) {
      getList();
    } else {
      getList();
    }
  }, [pages]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
    // console.log("err-close");
  };

  return (
    <>
      <div className="register-con ">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <div className="d-flex-al-jb mt-5">
          <h2>Awards</h2>
          {permissions?.includes("ADD-CERTIFICATE AWARDS") && (
            <button
              className="cust-btn bg-primary1 bg-primary1 px-3 py-1 text-white rounded-1 border-0"
              // to="/mcprofile/addcertificate"
              onClick={() =>
                navigate("/mcprofile/addcertificate", {
                  state: {
                    type: "add",
                    method: "award",
                  },
                })
              }
            >
              Add +
            </button>
          )}
        </div>
        <div className="d-flex-ae-jb my-3">
          <table className="w-100 cert_ficate">
            <tbody>
              {Awards?.map((list, index) => (
                <tr key={index}>
                  <td className="img-none">
                    {" "}
                    <Image
                      src={
                        // list?.SPCAD_Upload
                        "https://img.freepik.com/free-vector/golden-winners-cup_1284-18399.jpg?size=626&ext=jpg&ga=GA1.1.2022257850.1680068547&semt=sph"
                      }
                    />
                  </td>
                  <td className="d-grid">
                    <th>{list?.title}</th>
                    <td>Issue By: {list?.issued_by}</td>
                    <td>
                      Date Of Issue:{" "}
                      {moment(list?.issued_on).format("DD-MMM-YYYY")}
                    </td>
                  </td>
                  {permissions?.includes("EDIT-CERTIFICATE AWARDS") && (
                    <td>
                      <button
                        className="rounded-5 border-0 p-2 bg-hash"
                        onClick={() =>
                          navigate("/mcprofile/addcertificate", {
                            state: {
                              type: "edit",
                              method: "award",
                              id: list?.id,
                            },
                          })
                        }
                      >
                        <TbEdit />
                      </button>
                    </td>
                  )}
                  {permissions?.includes("DELETE-CERTIFICATE AWARDS") && (
                    <td>
                      <button
                        className="rounded-5 border-0 p-2 bg-hash"
                        onClick={() => deleteCertificate(list?.id)}
                      >
                        <RiDeleteBinLine />
                      </button>
                      {/* <Link to="#" onClick={() => removeElement(index)}>
                    <RiDeleteBinLine />
                  </Link> */}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="page-inner-cont  py-2" style={{ marginLeft: "auto" }}>
            <ReactPaginate
              nextLabel="›"
              onPageChange={handlePageChange}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="‹"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
        {/* Awards end*/}
      </div>
    </>
  );
};
export default McpAward;
