import GeneralTermsAndCondition from "../Terms&Condition";
import POMainAckModal from "./POMainAckModal";
import React, { useEffect, useState } from "react";
import POMainBtn from "./POMainBtn";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";

function POTermsAndCondition({ list, data }) {
  const [openCard, setOpenCard] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [paymet, setPayment] = useState([]);
  const [purchase, setPurchase] = useState([]);

  const POAckCloseHandler = () => setOpenCard(false);
  const POAckClickHandler = () => setOpenCard(true);
  const ackModalSubmitHandler = () => setOpenCard(false);

  const getTerms = () => {
    let params = "?type=1";
    api.purchaseOrderTerms(params).then((res) => {
      if (res?.status == "success") {
        setPayment(res?.terms_conditions);
        // res?.terms_conditions.map((item, ind) => {
        //   setPayment(item);
        // });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
    let param = "?type=2";
    api.purchaseOrderTerms(param).then((res) => {
      if (res?.status == "success") {
        res?.terms_conditions.map((item, ind) => {
          setPurchase(item);
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      {/* <GeneralTermsAndCondition /> */}

      <div className="pt-3 px-1">
        <h5 className="font-col-sec my-2 py-1">Terms & Conditions</h5>
        <ul className="my-3">
          {purchase?.purchase_order_term_details?.map((item, ind) => {
            return (
              <>
                <li key={ind} className="d-flex gap-2 my-1 py-1">
                  <span className="d-flex fnt-fam-f3">{ind + 1}.</span>{" "}
                  {item?.PurchaseOrderTermDetailDesc}
                </li>
              </>
            );
          })}
        </ul>
      </div>
      {/* <h5 className="font-col-sec my-2 py-1">Supplier Terms & Conditions</h5> */}
      {/* <label className="my-2">Supplier Terms & Conditions</label>
      <p className="py-1 mb-2 border-btm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer metus
        ligula, auctor eu sagittis eget, ultrices non turpis. Suspendisse
        pellentesque vestibulum sapien, non scelerisque ante vulputate in.
      </p> */}
      {/* <h5 className="font-col-sec my-2 py-1">Payment Terms & Conditions</h5> */}
      {/* <textarea className="w-100 p-3 cont-border"></textarea> */}
      <div className="pt-3 px-1">
        <h5 className="font-col-sec my-2 py-1">Payment Terms & Conditions</h5>
        <ul className="my-3">
          {paymet?.map((item, ind) => {
            return (
              <>
                <li key={ind} className="d-flex gap-2 my-1 py-1">
                  <span className="d-flex fnt-fam-f3">{ind + 1}.</span>{" "}
                  {item?.PaymentTermsName}
                </li>
              </>
            );
          })}
        </ul>
      </div>
      {/* <label className="my-2">Payment Terms & Conditions</label>
      <p className="py-1 mb-2 border-btm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer metus
        ligula, auctor eu sagittis eget, ultrices non turpis. Suspendisse
        pellentesque vestibulum sapien, non scelerisque ante vulputate in.
      </p> */}
      {data?.Sup_Approval == "A " ? null : data?.Sup_Approval ==
        "D " ? null : data?.Sup_Approval == "C " ? null : (
        <POMainBtn POAckClickHandler={POAckClickHandler} />
      )}
      <POMainAckModal
        list={list}
        openCard={openCard}
        setOpenCard={setOpenCard}
        ackModalSubmitHandler={ackModalSubmitHandler}
        POAckCloseHandler={POAckCloseHandler}
      />
    </>
  );
}

export default POTermsAndCondition;
