import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { PersonImg, PersonLogo } from "../../assets/img";

const LeftAddUserCopy = ({ routeType }) => {
  const [uploadImage, setUploadImage] = useState();
  const imageUploadHandler = (e) => {
    setUploadImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files);
  };

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    switch (file.type) {
      case "image/png":
        setUploadImage(URL.createObjectURL(e.target.files[0]));
        //('image type is png');
        break;
      case "image/jpg":
        //('image/jpg')
        setUploadImage(URL.createObjectURL(e.target.files[0]));
        break;
      case "image/jpeg":
        setUploadImage(URL.createObjectURL(e.target.files[0]));
        //('image is jpeg')
        break;
      default:
        alert("Only Upload Format png, jpg, jpeg");
      // image extension is not valid
    }
  };

  return (
    <div
      className={
        routeType == "select user"
          ? "left-add-user ms-0 d-sm-flex w-100"
          : "left-add-user"
      }
    >
      <div
        className={
          routeType == "select user ms-3"
            ? "upload-img me-3 ms-3"
            : "upload-img ms-3"
        }
        style={{
          borderWidth: uploadImage ? "1px" : "0px",
          height: "fit-content",
        }}
      >
        <Image
          // style={uploadImage ? "" : { width: "50%", height: "50%" }}
          src={uploadImage ? uploadImage : PersonImg}
          className="image-placeholder "
        />
        <input
          type="file"
          className="file-upload-input"
          onChange={fileChange}
        />
      </div>
      <div className="left-txt p-3">
        <p className="mt-2">Upload your Profile Picture</p>
        <p className="mt-2">Accepeted Image Format: .jpg/.jpeg/.png</p>
        <p className="mt-0">Maximum file size: 5MB</p>
        <p className="mt-0">Aspect Ratio: 1/1 </p>
      </div>
    </div>
  );
};

export default LeftAddUserCopy;
