import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ToWords } from "to-words";
import useUser from "../../constants/Storage/userData";

function Invoice2ndTableSection({
  setInvAmount,
  invAmount,
  invAmounts,
  setBankModal,
  selTable,
  displayBank,
  userTax,
  isGst,
  taxTotal,
  setTaxTotal,
  subcharge,
  setSubcharge,
  addcharge,
  setAddcharge,
  totalTableAmount,
  setTotalTableAmount,
  numToWords,
  setNumToWords,
  setTax_total_amount,
  setTaxArr,
  noName,
  finalTable,
  qty,
  currencyDisplay,
  setPercen,
  percen,
  setPer_centageFlag,
  per_centageFlag,
}) {
  const location = useLocation();
  const [taxDetails, setTaxDetails] = useState();
  const [result, setResult] = useState();
  const [percentageValue, setPercentageValue] = useState(100);
  const [list, setList] = useState([]);
  const [taxHeader, setTaxHeader] = useState([]);
  const { user } = useUser();
  const toWords = new ToWords();
  const [editer, setEditer] = useState(false);

  // console.log("taxDetails", taxDetails);

  // console.log("qty", qty);
  // console.log("noName", noName);

  console.log("finalTable", finalTable);
  // console.log("taxHeader", taxHeader);

  // console.log("totalTableAmount", totalTableAmount);

  const getTableValue = () => {
    const sum = {};
    const ex_rate_obj = {};
    let tax = [];
    for (var i = 0; i < finalTable.length; i++) {
      const tax = finalTable[i];
      const amount = parseFloat(tax.amount);
      sum[tax.tax] = sum[tax.tax] ? sum[tax.tax] + amount : amount;
      ex_rate_obj[tax.tax] =
        +finalTable[i]?.full_order_detail?.purchase_order?.ExRate;
    }

    console.log("sum", sum, ex_rate_obj);

    let temp = {};
    let arr = [];
    let taxHeaders = [];

    for (const [key, value] of Object.entries(sum)) {
      let final = (value * +key) / 100;
      temp[+key] = Number(final).toFixed(2);
      arr.push({
        gst_id: +key,
        tax_value: Number(final).toFixed(2),
        value: Number(+value).toFixed(2),
        total: Number(+value + +final).toFixed(2),
        ex_rate: ex_rate_obj[key],
        ex_rate_add_value: Number(+ex_rate_obj[key] * +value).toFixed(2),
      });
    }

    console.log("arr", arr, temp);
    // console.log("totalTableAmount", totalTableAmount);

    let divider = totalTableAmount / 2;

    taxHeaders.push({
      value: isGst == true ? totalTableAmount : "",
      name: "IGST",
    });

    taxHeaders.push({
      value: isGst !== true ? divider : "",
      name: "CGST",
    });

    taxHeaders.push({
      value: isGst !== true ? divider : "",
      name: "SGST",
    });

    setTaxHeader(taxHeaders);

    const subAmount = arr.reduce((a, b) => +a + +b?.ex_rate_add_value, 0);
    const taxAmount = arr.reduce((a, b) => +a + +b?.total, 0);
    const taxAmountValue = arr.reduce((a, b) => +a + +b?.tax_value, 0);

    finalTable?.map((item, ind) => {
      let obj = {
        TaxPatternID: item?.full_order_detail?.tax
          ? +item?.full_order_detail?.tax?.TaxPatternID
          : 0,
        TaxPatternName: item?.full_order_detail?.tax
          ? item?.full_order_detail?.tax?.TaxPatternName
          : "No Tax",
        TaxPercentage: +item?.full_order_detail?.tax?.TaxPercentage
          ? +item?.full_order_detail?.tax?.TaxPercentage
          : 0,
        PurchaseOrderDetailID:
          item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
          item?.full_order_detail?.LeatherPurchaseOrderDetailID,
        TaxAmount: item?.full_order_detail?.tax?.TaxPercentage
          ? Number(
              (item?.typedQuantity *
                +item?.full_order_detail?.Price *
                +item?.full_order_detail?.tax?.TaxPercentage) /
                100
            ).toFixed(2)
          : 1,
        ReceivedQuantity: item?.typedQuantity,
        Price: +item?.full_order_detail?.Price,
        Amount: Number(
          item?.full_order_detail?.Price * item?.typedQuantity || 0
        ).toFixed(2),
        total: Number(
          +item?.full_order_detail?.Price * item?.typedQuantity +
            +(item?.typedQuantity *
            +item?.full_order_detail?.Price *
            +item?.full_order_detail?.tax?.TaxPercentage
              ? item?.full_order_detail?.tax?.TaxPercentage
              : 1) /
              100
        ).toFixed(2),
        ex_rate: +item?.full_order_detail?.purchase_order?.ExRate,
        ex_rate_add_value: Number(
          +item?.full_order_detail?.Price *
            +item?.typedQuantity *
            +item?.full_order_detail?.purchase_order?.ExRate || 0
        ).toFixed(2),
      };
      tax.push(obj);
    });

    console.log("tax", tax);

    if (+user?.supplier?.CountryID == 1) {
      setTaxArr(tax);
    } else {
      setTaxArr([]);
    }
    setTotalTableAmount(subAmount);
    // console.log("arr", arr);
    setList(arr);
    setTax_total_amount(Number(taxAmount).toFixed(2));
    setResult(Number(taxAmountValue).toFixed(2));
  };

  useEffect(() => {
    if (finalTable?.length > 0) {
      getTableValue();
    }
  }, [finalTable]);

  useEffect(() => {
    // TAX
    let tax = [];
    let total_amount = [];

    // console.log("selTable", selTable);

    selTable?.map((item, ind) => {
      const res = qty.find(
        (el) =>
          el.PurchaseOrderDetailID ===
          item?.full_order_detail?.LeatherPurchaseOrderDetailID
      );

      // console.log("res2", res);

      let obj = {
        TaxPatternID: item?.full_order_detail?.tax
          ? +item?.full_order_detail?.tax?.TaxPatternID
          : 0,
        TaxPatternName: item?.full_order_detail?.tax
          ? item?.full_order_detail?.tax?.TaxPatternName
          : "No Tax",
        TaxPercentage: +item?.full_order_detail?.tax?.TaxPercentage
          ? +item?.full_order_detail?.tax?.TaxPercentage
          : 0,
        PurchaseOrderDetailID:
          item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
          item?.full_order_detail?.LeatherPurchaseOrderDetailID,
        TaxAmount: item?.full_order_detail?.tax?.TaxPercentage
          ? (+res?.ReceivedQuantity *
              +item?.full_order_detail?.Price *
              +item?.full_order_detail?.tax?.TaxPercentage) /
            100
          : 1,
        ReceivedQuantity: +res?.ReceivedQuantity,
        Price: +item?.full_order_detail?.Price,
        Amount: Number(
          item?.full_order_detail?.Price * +res?.ReceivedQuantity || 0
        ).toFixed(2),
      };
      tax.push(obj);
    });

    // setList(tax);
    // console.log("tax", tax);
    setTaxDetails(tax);
    // setTaxArr(tax);

    tax.map((item, ind) => {
      let amount;

      if (item?.TaxPatternName == "No Tax") {
        if (qty?.length > 0) {
          const res = qty.find(
            (el) => el.PurchaseOrderDetailID === item?.PurchaseOrderDetailID
          );
          amount = res?.ReceivedQuantity * item?.Price;
        } else {
          amount = item?.ReceivedQuantity * item?.Price;
        }
      } else {
        if (qty?.length > 0) {
          const res = qty.find(
            (el) => el.PurchaseOrderDetailID === item?.PurchaseOrderDetailID
          );
          amount = res?.ReceivedQuantity * item?.Price + item?.TaxAmount;
        } else {
          amount = item?.ReceivedQuantity * item?.Price + item?.TaxAmount;
        }
      }

      total_amount.push({ total_amount: amount });
    });

    // console.log("total_amount", total_amount);

    const amount = total_amount.reduce((a, b) => +a + +b.total_amount, 0);
    // setTotalTableAmount(amount);
    // console.log("amount", amount);
    // setTax_total_amount(amount);
  }, [selTable]);

  const percentageChange = (event) => {
    if (event <= 100) {
      setPer_centageFlag(true);
      let per_centage = (+totalTableAmount * +event) / 100;
      setPercentageValue(per_centage);
      setPercen(event);
    }
  };

  useEffect(() => {
    if (taxDetails) {
      const totalTableAmount = Object.values(taxDetails).reduce(
        (acc, val) => acc + val.amount,
        0
      );
      // console.log("totalTableAmount", totalTableAmount);
      setTaxTotal(totalTableAmount);
    }
  }, [taxDetails]);

  useEffect(() => {
    if (totalTableAmount && per_centageFlag) {
      let minus = +subcharge;
      let add = +addcharge;
      let percen_val = +percentageValue;
      let total_price = +totalTableAmount;
      const calulated_amt = percen_val - minus + add;

      setInvAmount(calulated_amt);
      // doConvert(+calulated_amt);
      setNumToWords(toWords.convert(+Number(+calulated_amt).toFixed(2)));
      // console.log(Number(+invAmounts).toFixed(2), "tt");
    } else {
      let minus = +subcharge;
      let add = +addcharge;
      let percen_val = (+totalTableAmount * +percentageValue) / 100;
      let total_price = +totalTableAmount;
      const calulated_amt = percen_val - minus + add;
      setInvAmount(invAmounts);
      setNumToWords(toWords.convert(+Number(+invAmounts).toFixed(2)));
      console.log(Number(+invAmounts).toFixed(2), "gg");
      // doConvert(+calulated_amt);
    }
  }, [
    addcharge,
    subcharge,
    totalTableAmount,
    currencyDisplay,
    percentageValue,
    selTable,
    qty,
    taxDetails,
  ]);

  useEffect(() => {
    if (location?.state?.type == "edit") {
      setInvAmount(invAmounts);
    }
  }, []);

  // console.log("result", numToWords);
  return (
    <div className="my-2 mid-sc-res">
      <div
        style={{ width: "inherit" }}
        className="cont-border d-flex over-flow-x-scroll"
      >
        <div
          // style={{ minWidth: "350px" }}
          className="bor-right d-flex flex-column justify-content-end px-2 py-1 w-100"
        >
          <h6
            className="primary1"
            style={{ color: "#247395", fontWeight: "bolder" }}
          >
            Grand Total In Words
          </h6>
          <h6 className="text-wrap">
            {" "}
            {currencyDisplay?.currency_name
              ? currencyDisplay?.currency_name
              : " "}{" "}
            - {numToWords ? numToWords : ""}
          </h6>
          {/* <h6 className="text-wrap">{numToWords ? numToWords : ""}</h6> */}
        </div>
        <div className="py-2 d-flex  ms-auto justify-content-between w-100">
          <div className="py-2  pe-2">
            <div className="d-flex">
              {/* <div style={{ width: "200px" }}></div> */}
              <div
                // style={{ minWidth: "410px" }}
                className="d-flex me-3 "
              >
                <div className="d-flex flex-column ms-auto me-lg-5 me-3  invoce-2ndtable-header-sec-cont">
                  <p className="mb-4"></p>
                  {/* <label className="py-1 my-1 mx-2 fs-14px">
                    Addtional Charges
                  </label>
                  <label className="py-1 my-1 mx-2 fs-14px">Deduction</label> */}
                </div>
                <div className="d-flex flex-column invoce-2ndtable-header-sec-cont ">
                  <p className="fs-14px opac_half">Sub Total</p>
                  {/* <p className="border-btm py-1 my-1 fs-14px">
                     Addtional Charges
                  </p>
                  <p className="border-btm text-nowrap py-1 my-1 fs-14px">
                   Deduction
                  </p> */}
                  <p className="py-1 my-1 fs-14px opac_half">Invoiceable %</p>
                  <p className="py-1 my-1 fs-14px opac_half">
                    Addtional Charges
                  </p>
                  <p className="py-1 my-1 fs-14px opac_half">Deduction</p>
                </div>
                <div className="d-flex flex-column">
                  <p className="fs-14px text-end">
                    {totalTableAmount
                      ? Number(totalTableAmount).toFixed(2)
                      : ""}{" "}
                    {currencyDisplay?.currency_code}
                  </p>
                  <div className="d-flex gap-2 justify-content-end">
                    <p className="border-btm py-1 my-1 fs-14px">
                      <input
                        className="border-0 w-100"
                        value={percen}
                        type={"number"}
                        onChange={(e) => percentageChange(e.target.value)}
                      />{" "}
                    </p>
                    <p className="border-btm py-1 my-1 fs-14px">
                      <p className="w-100">
                        {percentageValue
                          ? Number(percentageValue).toFixed(2)
                          : ""}
                      </p>
                    </p>
                  </div>
                  <p className="border-btm py-1 my-1 fs-14px text-end">
                    <input
                      className="border-0"
                      value={addcharge}
                      style={{ textAlign: "right" }}
                      type={"number"}
                      onChange={(e) => {
                        setPer_centageFlag(true);
                        setAddcharge(e.target.value);
                      }}
                    />
                  </p>
                  <p className="border-btm py-1 my-1 fs-14px text-end">
                    <input
                      className="border-0"
                      value={subcharge}
                      style={{ textAlign: "right" }}
                      type={"number"}
                      onChange={(e) =>
                        // setSubcharge(e.target.value.replace(/[^\s0-9]/gi, ""))
                        {
                          setPer_centageFlag(true);
                          setSubcharge(e.target.value);
                        }
                      }
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end gap-3 ms-auto pe-2 me-2 mt-3">
              <h6 className="py-1 px-2">Grand Total</h6>
              <h6 className="py-1">
                {invAmount
                  ? Number(invAmount)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""}{" "}
                {currencyDisplay?.currency_code}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex table-scroll-horizontal over-flow-x-scroll mb-2 w-100">
        {+user?.supplier?.CountryID == 1 && (
          <Col xs={8} className="py-2 d-flex w-100 m-0">
            {/* <div className=" p-0 me-2 mt-3 quotation-nd-table-cont">
            <table className="py-2 cont-border table fs-14px">
              <thead>
                <tr className="fnt-fam-f3 opac_half fs-14px">
                  <th>IGST</th>
                  <th>-</th>
                </tr>
              </thead>
              <tbody className="opac-80 ">
                <tr>
                  <td className="fs-14px">CGST</td>
                  <td className="fs-14px">9500</td>
                </tr>
                <tr>
                  <td className="fs-14px">SGST</td>
                  <td className="fs-14px">9500</td>
                </tr>
              </tbody>
            </table>
          </div> */}
            <div
              // style={{ minWidth: "410px" }}
              className="d-flex gap-3 p-0 quo-scope-btm-tb-2nd-cont w-100"
            >
              {/* <div className="d-flex quotation-nd-table-cont">
              <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                <thead>
                  <tr className="fnt-fam-f3 opac_half table-bordered">
                    <th className="fs-14px text-center">Tax Details</th>
                    <th className="fs-14px text-center">Gst</th>
                    <th className="fs-14px text-center">Taxable Amount</th>
                    <th className="fs-14px text-center">Tax Value</th>
                  </tr>
                </thead>

                <tbody className="opac-80">
                  {taxDetails &&
                    Object.values(taxDetails).map((tax_detail) => {
                      return isGst === true ? (
                        <tr>
                          <td className="text-center">IGST</td>
                          <td className="text-center">
                            {tax_detail?.tax_percent}%
                          </td>
                          <td className="text-center">{tax_detail?.amount}</td>
                          <td className="text-center">
                            {tax_detail?.inner_percent}
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td className="text-center">SGST</td>
                            <td className="text-center">
                              {tax_detail?.tax_percent}%
                            </td>
                            <td className="text-center">
                              {tax_detail?.amount}
                            </td>
                            <td className="text-center">
                              {tax_detail?.inner_percent}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">CGST</td>
                            <td className="text-center">
                              {tax_detail?.tax_percent}%
                            </td>
                            <td className="text-center">
                              {tax_detail?.amount}
                            </td>
                            <td className="text-center">
                              {tax_detail?.inner_percent}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div> */}
              <div className="d-flex quotation-nd-table-cont">
                <table className="mt-3 cont-border py-2 mb-0 bor-right table w-100">
                  {taxHeader?.map((item, ind) => {
                    return (
                      <tr
                        className="fnt-fam-f3 opac_half table-bordered text-center"
                        style={{ borderWidth: "1px" }}
                      >
                        <th className="text-nowrap" style={{ padding: "5px" }}>
                          {item?.name}
                        </th>
                        <td style={{ padding: "5px" }}>
                          <p className="opac-80">
                            {item?.value
                              ? Number(item?.value)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : "-"}
                          </p>
                        </td>
                      </tr>
                    );
                  })}

                  {/* <thead>
                    <tr className=" opac_half table-bordered text-center">
                      <th className="fnt-fam-f3 text-nowrap">IGST</th>
                      {isGst == true
                        ? taxHeader?.map((item, ind) => {
                            return (
                              <td className="fs-14px text-center">
                                {item?.value}
                              </td>
                            );
                          })
                        : "-"}
                    </tr>

                    {/* <th className="text-nowrap">{item?.name}</th> 
                  </thead> */}
                  {/* <tbody className="opac-80">
                    {isGst == true ? (
                      <tr>
                        <td className="fs-14px text-center">ISGT</td>
                      </tr>
                    ) : (
                      <>
                        {["SGST", "CGST"].map((item) => (
                          <tr>
                            <td className="fs-14px text-center">{item}</td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody> */}
                </table>
              </div>
              <div className=" p-0 quo-scope-btm-tb-2nd-cont  w-100">
                <div className="quotation-nd-table-cont">
                  <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                    <thead>
                      <tr className="fnt-fam-f3 opac_half table-bordered">
                        {/* <th className="fs-14px text-center">Tax Details</th> */}
                        <th className="fs-14px text-center text-nowrap">
                          GST %
                        </th>
                        <th className="fs-14px text-center text-nowrap">
                          Taxable Value
                        </th>
                        <th className="fs-14px text-center text-nowrap">
                          Tax Value
                        </th>
                      </tr>
                    </thead>

                    <tbody className="opac-80">
                      {list?.map((item, ind) => {
                        return (
                          <tr>
                            <td className="text-center">{item?.gst_id}</td>
                            <td className="text-center">
                              {item?.ex_rate_add_value}
                            </td>
                            <td className="text-center">{item?.tax_value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="d-flex justify-content-end light-grey-bg py-2">
                    <tbody>
                      <tr>
                        <td className="d-flex gap-2 fw-bold px-2">
                          <span>Total Tax : </span>{" "}
                          <span>
                            {result ? result : ""}{" "}
                            {currencyDisplay?.currency_code}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>{" "}
                </div>
              </div>
            </div>
          </Col>
        )}
      </div>
      <Col xs={4} className="">
        <div className="d-flex gap-5 w-90 my-3 py-2">
          <label className="font-blue fs-15px fw-bold">Bank Details</label>
          <button
            className="text-white px-3  border-0 bg-primary1"
            onClick={() => setBankModal(true)}
          >
            Map
          </button>
        </div>
        <Col className="w-90">
          <div className="d-flex py-1 flex-no-wrap">
            <label style={{ width: "150px" }}>Beneficiary Name</label>
            <p>
              <span className="me-1 pe-1">:</span>
              <span className="fw-bold">
                {displayBank[0]?.account_holder_name}
              </span>
            </p>
          </div>
          <div className="d-flex py-1 flex-no-wrap">
            <label style={{ width: "150px" }}>Bank Name</label>
            <p>
              <span className="me-1 pe-1">:</span>
              <span className="fw-bold"> {displayBank[0]?.bank_name}</span>
            </p>
          </div>
          <div className="d-flex py-1 flex-no-wrap">
            <label style={{ width: "150px" }}>Account No</label>
            <p>
              <span className="me-1 pe-1">:</span>
              <span className="fw-bold"> {displayBank[0]?.account_no}</span>
            </p>
          </div>
          <div className="d-flex py-1 flex-no-wrap">
            <label style={{ width: "150px" }}>IFSC Code</label>
            <p>
              <span className="me-1 pe-1">:</span>
              <span className="fw-bold"> {displayBank[0]?.ifsc_code}</span>
            </p>
          </div>
        </Col>
      </Col>
    </div>
  );
}

export default Invoice2ndTableSection;
