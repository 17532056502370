import React, { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { FavLogo } from "../../../assets/img";

const ErrorModel = ({ errorModalClose, errorBox, error }) => {
  let code = error?.response?.status;
  let success = error?.status;
  let messages = error?.message;
  if (code == 401) {
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/";
    }, 800);
  }
  let message = error?.response?.data?.message;
  // console.log("code", error);
  return (
    <Modal
      id="#verify"
      className="otp-modal"
      show={errorBox}
      centered
      onHide={errorModalClose}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="new-modal">
        <div className="w-100 d-flex-al-jb my-2">
          <Image className="fav-icon " src={FavLogo} />
          <button onClick={errorModalClose} className="cust-btn p-0 mb-4">
            <FaTimes />
          </button>
        </div>
        <div className="mt-2  my-5">
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            {/* {success ? success : code} */}
            {code == 500 ? "HTTP 500" : success ? success : " "}
          </p>
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            {code == 500
              ? "Internal Server Error"
              : message || error?.message || messages}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModel;
