import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import React, { useState, useEffect } from "react";
import { AiOutlineZoomIn, AiOutlineFileJpg } from "react-icons/ai";
import POScopeTable from "../../component/PurchaseOrder/POTable";
import { Col } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { RiDeleteBack2Line } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { RxTriangleUp } from "react-icons/rx";
import Calender from "../../assets/Icons/Normal Icons/Calender.svg";
import ViewDetails from "../../assets/Icons/Normal Icons/ViewDetails.svg";
import moment from "moment/moment";
import POMainBtn from "./POMainBtn";
import POMainAckModal from "./POMainAckModal";
import useUser from "../../constants/Storage/userData";
import { ToWords } from "to-words";
function PurshaseOrderScope({ list, data, setAmount }) {
  // console.log("sc", list);
  const { user } = useUser();
  const [calPoQty, setCalPoQty] = useState([]);
  const [openCard, setOpenCard] = useState(false);
  const [totalValue, setTotalValue] = useState();
  const [Addtional, setAddional] = useState();
  const [Deduction, setDeducation] = useState();
  const [totalGrand, setTotalGrand] = useState();
  const [numToWords, setNumToWords] = useState();
  const toWords = new ToWords();

  const POAckCloseHandler = () => setOpenCard(false);
  const POAckClickHandler = () => setOpenCard(true);
  const ackModalSubmitHandler = () => setOpenCard(false);

  const cal_poqty = () => {
    let added_quantity = list?.material_purchase_order_details;
    added_quantity = added_quantity?.map(
      (material) => material?.purchase_order_detail_del_dates
    );
    let temp = [];
    if (added_quantity) {
      for (let i = 0; i < added_quantity.length; i++) {
        temp.push(+added_quantity[i][0]?.Quantity);
      }
      setCalPoQty(temp);
    }
  };

  const getCalculation = () => {
    let temp = [];
    let calTax = [];
    let total = [];
    let new_arr = [];

    list?.material_purchase_order_details?.map((item, ind) => {
      let sumAmount = item?.purchase_order_detail_del_dates?.reduce(
        (a, b) => +a + +b.Quantity,
        0
      );

      let to_cal_num = Number(
        +sumAmount * +item?.Price * +list?.ExRate
      ).toFixed(2);

      new_arr.push(+to_cal_num);

      item?.purchase_order_detail_del_dates?.map((items, inds) => {
        let temps = {};
        temps.PurchaseOrderDetailID =
          item?.MaterialPurchaseOrderDetailID ||
          item.LeatherPurchaseOrderDetailID;
        temps.FactoryID = item?.FactoryID;
        temps.Price = item?.Price;
        temps.PurchaseOrderDetailIDS =
          items?.MaterialPurchaseOrderDetailID ||
          items.LeatherPurchaseOrderDetailID;
        temps.Quantity = items?.Quantity;

        if (item?.tax?.TaxPercentage) {
          temps.tax = item?.tax?.TaxPercentage;
        }
        temp.push(temps);
      });
    });

    list?.leather_purchase_order_details?.map((item, ind) => {
      let sumAmount = item?.purchase_order_detail_del_dates?.reduce(
        (a, b) => +a + +b.Quantity,
        0
      );

      let to_cal_num = Number(
        +sumAmount * +item?.Price * +list?.ExRate
      ).toFixed(2);

      new_arr.push(+to_cal_num);

      item?.purchase_order_detail_del_dates?.map((items, inds) => {
        let temps = {};
        temps.PurchaseOrderDetailID =
          item?.MaterialPurchaseOrderDetailID ||
          item.LeatherPurchaseOrderDetailID;
        temps.FactoryID = item?.FactoryID;
        temps.Price = item?.Price;
        temps.PurchaseOrderDetailIDS =
          items?.MaterialPurchaseOrderDetailID ||
          items.LeatherPurchaseOrderDetailID;
        temps.Quantity = items?.Quantity;

        if (item?.tax?.TaxPercentage) {
          temps.tax = item?.tax?.TaxPercentage;
        }
        temp.push(temps);
      });
    });

    // console.log("temp", temp);

    temp.map((item, ind) => {
      let calTaxs = {};
      calTaxs.Price = +item?.Price;
      calTaxs.Quantity = +item?.Quantity;
      if (item?.tax) {
        calTaxs.tax = +item?.tax;
        calTaxs.with_tax = (+item?.Price * +item?.Quantity * +item?.tax) / 100;
      } else {
        calTaxs.with_tax = +item?.Price * +item?.Quantity;
      }
      calTax.push(calTaxs);
    });

    // console.log("cal", calTax);

    calTax.map((item, ind) => {
      total.push({
        total:
          user?.supplier?.CountryID == 1
            ? item?.Price * item?.Quantity + item?.with_tax
            : item?.Price * item?.Quantity,
      });
    });

    // console.log("new_arr", new_arr);

    // const sumTotal = total.reduce((a, b) => +a + +b.total, 0);
    // 12-07-2024
    const sumTotal = new_arr.reduce((a, b) => +a + +b, 0);

    setTotalValue(sumTotal);
    setAmount(sumTotal);
    setTotalGrand(sumTotal);
    doConvert(sumTotal);
  };

  useEffect(() => {
    if (
      list?.material_purchase_order_details ||
      list?.leather_purchase_order_details
    ) {
      cal_poqty();
      getCalculation();
    }
  }, [list]);
  // console.log("list", list);

  // console.log("totalValue", totalValue);

  const [showModel, setShowModel] = useState(false);
  const [showDeliveryDate, setShowDeliveryDate] = useState();

  const deliveryDateModelHideHandler = () => setShowDeliveryDate();

  const deliveryDateModelShowHandler = (e) => {
    setShowDeliveryDate(e);
    if (e == showDeliveryDate) {
      setShowDeliveryDate();
      // console.log("1");
    }
    //  setShowDeliveryDate(true);
  };

  const modelHideHandler = () => setShowModel(false);
  const modelShowHandler = () => setShowModel(true);

  // let cal2 = 1 * 1000;

  // let gst2 = (cal2 * 18) / 100;

  // console.log(gst2 + 1000);

  const grandTotal = () => {
    if (Addtional) {
      setTotalGrand(+totalValue + +Addtional);
      doConvert(+totalValue + +Addtional);
    } else if (Deduction) {
      setTotalGrand(+totalValue - +Deduction);
      doConvert(+totalValue - +Addtional);
    } else {
      setTotalGrand(+totalValue);
      doConvert(+totalValue);
    }
  };
  useEffect(() => {
    grandTotal();
  }, [Addtional, Deduction]);

  const doConvert = (numberInput) => {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
            `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
            `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
            `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
            `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
            `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    // console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    // console.log("Result = ", outputText);

    setNumToWords(outputText);
  };

  // console.log("list", user?.supplier?.CountryID);

  // console.log("totalGrand", +Number(totalGrand).toFixed(2));

  return (
    <>
      <div className="mt-3 py-3 px-1">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-blue fnt-fam-f2 fs-1r">Scope</h5>
        </div>
        <div className="new-po-table">
          <div className="header-table">
            <h5 className="">Sl.No </h5>
            <h5>
              Supplier{" "}
              {list?.MaterialPurchaseOrderID
                ? "Mat. "
                : list?.MaterialPurchaseOrderID
                ? "Leather "
                : null}{" "}
              Code
            </h5>
            <h5 className="">KH SKU Code</h5>
            <h5 className="">KH SKU NAME</h5>
            <h5 className="">HSN</h5>
            <h5 className="">UOM</h5>
            <h5 className="">PO Qty</h5>
            <h5 className="">Price</h5>
            {user?.supplier?.CountryID == 1 ? (
              <h5 className="">GST%</h5>
            ) : user?.supplier?.CountryID == "1" ? (
              <h5 className="">GST%</h5>
            ) : null}
            <h5 className="">Amount</h5>
            <h5 className="">Delivery</h5>
          </div>
          {list?.material_purchase_order_details?.map((item, ind) => {
            let sumAmount = item?.purchase_order_detail_del_dates?.reduce(
              (a, b) => +a + +b.Quantity,
              0
            );

            // console.log("sumAmount", sumAmount);

            let cal = sumAmount * item?.Price;

            // console.log("cal", cal);

            let gst = (cal * item?.tax?.TaxPercentage) / 100;

            // console.log("gst", +gst);

            let amount;

            if (user?.supplier?.GSTNo && user?.supplier?.GSTNo !== "null") {
              if (item?.tax?.TaxPercentage) {
                amount = +item?.Price * +sumAmount + +gst;
              } else {
                // console.log("ddd");
                amount = +item?.Price * +sumAmount;
              }
            } else {
              // console.log("llll");
              amount = +item?.Price * +sumAmount;
            }

            // console.log(amount, sumAmount);

            // 12-07-2024
            let amount_calculation = +sumAmount * +item?.Price * +list?.ExRate;

            return (
              <div className="data-table">
                <p>{ind + 1}</p>
                <p>
                  {item?.supplier_product_code
                    ? item?.supplier_product_code
                    : "-"}
                </p>
                <p>{item?.sku?.MaterialSKUCode}</p>
                <p>{item?.sku?.MaterialSKUName}</p>
                <p>{item?.sku?.material_header?.hsn?.HSNName}</p>
                <p className="">{item?.sku?.material_header?.uom?.UomCode}</p>
                <p className="">{sumAmount}</p>
                <p className="">{Number(item?.Price).toFixed(2)}</p>
                {+user?.supplier?.CountryID == 1 && (
                  <p>
                    {item?.tax?.TaxPercentage ? item?.tax?.TaxPercentage : "-"}
                  </p>
                )}
                <p>
                  {amount_calculation
                    ? Number(amount_calculation).toFixed(2)
                    : 0}
                </p>
                <p>
                  <div className="my-1">
                    <span onClick={() => deliveryDateModelShowHandler(ind)}>
                      <img src={Calender} className="wid-15px" />
                    </span>
                    {ind == showDeliveryDate && (
                      <div className="po-scope-table-delivery-card">
                        <RxTriangleUp className="arw-svg" />
                        <div className="d-flex justify-content-between border-btm align-items-center pb-2">
                          <h4 className="">Delivery Details</h4>
                          <div
                            className="fnt-fam-f3 fs-5"
                            onClick={deliveryDateModelHideHandler}
                          >
                            <RiDeleteBack2Line />
                          </div>
                        </div>
                        {item?.purchase_order_detail_del_dates
                          ?.sort(
                            (a, b) =>
                              Date.parse(b?.DeliveryDate) -
                              Date.parse(a?.DeliveryDate)
                          )
                          ?.map((dates, inde) => {
                            let today = new Date();
                            let endDate = new Date(
                              moment(dates?.DeliveryDate).format("DD-MMM-YYYY")
                            );

                            return (
                              <div className="d-flex flex-column" key={inde}>
                                <div className=" d-flex gap-2 my-2">
                                  <div className="po-delivery-date-model-tick-cont-active">
                                    <TiTick
                                      style={
                                        today >= endDate
                                          ? {
                                              background: "#69d027",
                                            }
                                          : { background: "rgb(240 213 10)" }
                                      }
                                    />
                                  </div>
                                  <div className="text-start ps-2">
                                    <h6 className="mb-0">
                                      Delivery {inde + 1}
                                    </h6>
                                    <div>
                                      <div className="d-flex w-100 justify-content-center">
                                        <h3>
                                          <span className="">
                                            Delivery Date:{" "}
                                          </span>
                                          {dates?.DeliveryDate
                                            ? moment(
                                                dates?.DeliveryDate
                                              ).format("DD-MMM-YYYY")
                                            : ""}
                                        </h3>
                                        <h3>
                                          <span className="">Quantity:</span>
                                          {Math.round(dates?.Quantity)}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </p>
              </div>
            );
          })}
          {list?.leather_purchase_order_details?.map((item, ind) => {
            let sumAmount = item?.purchase_order_detail_del_dates?.reduce(
              (a, b) => +a + +b.Quantity,
              0
            );

            let cal = +sumAmount * +item?.Price;

            // console.log("cal", cal);

            let gst = (cal * +item?.tax?.TaxPercentage) / 100;

            // console.log("gst", gst);

            let amount;

            if (user?.supplier?.CountryID == 1) {
              if (item?.tax?.TaxPercentage) {
                amount = +item?.Price * +sumAmount + +gst;
              } else {
                amount = +item?.Price * +sumAmount;
              }
            } else {
              amount = +item?.Price * +sumAmount;
            }

            // 12-07-2024
            let amount_calculation = +sumAmount * +item?.Price * +list?.ExRate;

            return (
              <div className="data-table">
                <p>{ind + 1}</p>
                <p>
                  {item?.supplier_product_code
                    ? item?.supplier_product_code
                    : "-"}
                </p>
                <p>{item?.sku?.LeatherSKUCode}</p>
                <p>{item?.sku?.LeatherSKUName}</p>
                <p>{item?.sku?.leather_header?.hsn?.HSNName}</p>
                <p className="">{item?.sku?.leather_header?.uom?.UomCode}</p>
                <p className="">{sumAmount}</p>
                <p className="">{parseInt(item?.Price).toFixed(2)}</p>
                {+user?.supplier?.CountryID == 1 && (
                  <p>
                    {item?.tax?.TaxPercentage ? item?.tax?.TaxPercentage : "-"}
                  </p>
                )}
                <p>
                  {amount_calculation
                    ? Number(amount_calculation).toFixed(2)
                    : 0}
                </p>
                <p>
                  <div className="my-1">
                    <span onClick={() => deliveryDateModelShowHandler(ind)}>
                      <img src={Calender} className="wid-15px" />
                    </span>
                    {ind == showDeliveryDate && (
                      <div className="po-scope-table-delivery-card">
                        <RxTriangleUp className="arw-svg" />
                        <div className="d-flex justify-content-between border-btm align-items-center pb-2">
                          <h4 className="">Delivery Details</h4>
                          <div
                            className="fnt-fam-f3 fs-5"
                            onClick={deliveryDateModelHideHandler}
                          >
                            <RiDeleteBack2Line />
                          </div>
                        </div>
                        {item?.purchase_order_detail_del_dates
                          ?.sort(
                            (a, b) =>
                              Date.parse(b?.DeliveryDate) -
                              Date.parse(a?.DeliveryDate)
                          )
                          ?.map((dates, inde) => {
                            let today = new Date();
                            let endDate = new Date(
                              moment(dates?.DeliveryDate).format("DD-MMM-YYYY")
                            );

                            return (
                              <div className="d-flex flex-column" key={inde}>
                                <div className=" d-flex gap-2 my-2">
                                  <div className="po-delivery-date-model-tick-cont-active">
                                    <TiTick
                                      style={
                                        today >= endDate
                                          ? {
                                              background: "#69d027",
                                            }
                                          : { background: "#e6e6e6" }
                                      }
                                    />
                                  </div>
                                  <div className="text-start ps-2">
                                    <h6 className="mb-0">
                                      Delivery {inde + 1}
                                    </h6>
                                    <div>
                                      <div className="d-flex w-100 justify-content-center">
                                        <h3>
                                          <span className="">
                                            Delivery Date:{" "}
                                          </span>
                                          {dates?.DeliveryDate
                                            ? moment(
                                                dates?.DeliveryDate
                                              ).format("DD-MMM-YYYY")
                                            : ""}
                                        </h3>
                                        <h3>
                                          <span className="">Quantity:</span>
                                          {Math.round(dates?.Quantity)}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </p>
              </div>
            );
          })}
        </div>

        <POMainAckModal
          list={list}
          openCard={openCard}
          setOpenCard={setOpenCard}
          ackModalSubmitHandler={ackModalSubmitHandler}
          POAckCloseHandler={POAckCloseHandler}
        />

        <div className="table-scroll-horizontal over-flow-x-scroll my-2 table-sm w-100 ">
          <div className="cont-border d-flex flex-wrap">
            <Col
              lg={5}
              md={4}
              sm={12}
              className="bor-right d-flex flex-column justify-content-end px-2 py-1"
            >
              <h5
                className="font-blue fs-15px"
                style={{ fontWeight: "bolder" }}
              >
                Grand Total In Words
              </h5>
              <h5 className="fs-15px">
                {/* Thiry-Eight Thousand Two Hundred Forty-one and Seventy */}
                {/* {totalGrand
                  ? list?.currency?.CurrencyName +
                    " - " +
                    // toWords.convert(totalGrand, { currency: true })
                    toWords.convert(+Number(totalGrand).toFixed(2))
                  : "-"} */}
                {totalValue
                  ? //  list?.currency?.CurrencyName +
                    "INR" +
                    " - " +
                    // toWords.convert(totalGrand, { currency: true })
                    toWords.convert(+Number(totalValue).toFixed(2))
                  : "-"}
              </h5>
            </Col>
            <Col lg={7} md={8} sm={12} className="py-2 d-flex  flex-wrap gap-3">
              <Col md={12} sm={12} lg={12}>
                <p className="d-flex fs-13px justify-content-end mx-3">
                  <span className="mx-1">Sub Total :</span>
                  <span>
                    {" "}
                    {totalValue ? totalValue.toFixed(2) : ""}{" "}
                    {/* {list?.currency?.CurrencyCode} */}INR
                  </span>
                </p>
              </Col>
              {/* <Col lg={12} md={12} sm={12}>
                <div className="d-flex justify-content-between">
                  <p className="opac_half py-1 fs-13px mx-5">
                    Additional Charges
                  </p> */}
              {/* <p className="border-btm fs-13px py-1">Transparent Charges</p> */}
              {/* <div className="d-flex input_bottam mx-3">
                    <input
                      className="quo-scope-input fs-13px mb-2"
                      style={{ textAlign: "right" }}
                      type="number"
                      onChange={(e) => setAddional(e.target.value)}
                      value={Addtional}
                      // disabled={Deduction ? true : false}
                      readOnly
                    />
                    <span className="fs-13px">
                      {list?.currency?.CurrencyCode}
                    </span>
                  </div>
                </div>
              </Col> */}
              {/* <Col lg={12} md={12} sm={12}>
                <div className="d-flex justify-content-between">
                  <p className="opac_half py-1 fs-13px mx-5">Deduction</p> */}
              {/* <p className="border-btm fs-13px py-1">
                    E.G (Transparent Charges)
                  </p> */}
              {/* <div className="d-flex input_bottam mx-3">
                    <input
                      className="quo-scope-input fs-13px mb-2"
                      style={{ textAlign: "right" }}
                      type="number"
                      onChange={(e) => setDeducation(e.target.value)}
                      value={Deduction}
                      // disabled={Addtional ? true : false}
                      readOnly
                    />
                    <span className="fs-13px">
                      {list?.currency?.CurrencyCode}
                    </span>
                  </div>
                </div>
              </Col> */}
              <Col md={12} sm={12} lg={12}>
                {" "}
                <div className="d-flex ms-auto justify-content-end mt-5">
                  <h5 className="py-1 fnt-fam-f3 fs-14px">Grand Total :</h5>
                  <h5 className="py-1 fnt-fam-f3 fs-14px mx-3">
                    {totalValue
                      ? totalValue.toFixed(2) +
                        " " +
                        // list?.currency?.CurrencyCode
                        "INR"
                      : ""}
                    {/* {totalGrand
                      ? totalGrand.toFixed(2) +
                        " " +
                        list?.currency?.CurrencyCode
                      : ""} */}
                  </h5>
                </div>
              </Col>
            </Col>
          </div>
        </div>

        {data?.Sup_Approval == "A " ? null : data?.Sup_Approval ==
          "D " ? null : data?.Sup_Approval == "C " ? null : (
          <POMainBtn POAckClickHandler={POAckClickHandler} />
        )}
      </div>
    </>
  );
}

export default PurshaseOrderScope;
