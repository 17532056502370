import { HiArrowNarrowLeft } from "react-icons/hi";

import { CiSearch } from "react-icons/ci";
import { Col } from "react-bootstrap";
import QuoLastSectionBtn from "../../component/QuoLasSecBtn";
import { RxFilePlus } from "react-icons/rx";
import { AiOutlineZoomIn } from "react-icons/ai";
import QuoScopeMainTable from "./QuoScopeTable";
function QuoScopetableZoompage({ setZoomedTable }) {
  const zoomHandler = () => setZoomedTable(false);
  return (
    <Col lg={12} xl={10} className="mx-5 my-5 quo-scope-tab-zoom-page">
      <div>
        <div className="d-flex gap-3 align-items-center">
          <HiArrowNarrowLeft className="font-blue fs-5" onClick={zoomHandler} />
          <h5 className="font-blue mb-0 fs-5">Scope</h5>
        </div>
        <div className="d-flex mb-3 gap-2 align-items-center float-end ">
          <p
            className="bg-primary1 text-white rounded-circle py-1 px-2"
            onClick={zoomHandler}
          >
            <AiOutlineZoomIn />
          </p>
          <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1 w-100 opac_half">
            <input placeholder="Search Title" />
            <CiSearch />
          </div>
          <button className="filter-btn bg-primary2">
            <RxFilePlus />
            Filter
          </button>
        </div>

        <Col lg={12} md={12} sm={12}>
          <QuoScopeMainTable />
        </Col>
      </div>
      <QuoLastSectionBtn BtnText="Save & Next" BtnSize="wid-9_5r" />
    </Col>
  );
}

export default QuoScopetableZoompage;
