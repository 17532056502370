import { Col } from "react-bootstrap";
function CreateQuotationTabSec({ currentActive, setCurrentActive }) {
  const btnClickHandler = (e) => {
    const currentBtn = e.target.textContent.toLowerCase();
    if (currentBtn.includes("terms")) {
      setCurrentActive("terms");
    } else if (currentBtn.includes("communication")) {
      setCurrentActive("communication");
    } else if (currentBtn.includes("scope")) {
      setCurrentActive("scope");
    } else if (currentBtn.includes("acknowledgement")) {
      setCurrentActive("acknowledgement");
    } else {
      // setCurrentActive("purchase-order-info");
      setCurrentActive("scope");
    }
  };
  const Active =
    "enquiry-view-btn-bor rounded-pill fnt-fam-f2 fs-6 h-2r bg-primary1 text-white";
  const InActive =
    "enquiry-view-btn-bor rounded-pill fnt-fam-f2 fs-6 h-2r bg-light opac-30";
  return (
    <div className="d-flex flex-wrap">
      <Col lg={12} md={12} sm={12} className="d-flex gap-2 flex-wrap my-1">
        {/* <button
          className={
            currentActive == "purchase-order-info"
              ? `${Active} wid-11r fnt-fam-f2`
              : `${InActive} wid-11r fnt-fam-f2------------`
          }
          onClick={btnClickHandler}>
          PO Information
        </button> */}
        <button
          className={
            currentActive == "scope" ? `${Active} wid-9r` : `${InActive} wid-9r`
          }
          onClick={btnClickHandler}
        >
          Scope
        </button>
        <button
          className={
            currentActive == "terms" ? `${Active} px-2` : `${InActive} px-2`
          }
          onClick={btnClickHandler}
        >
          Terms & Conditions
        </button>{" "}
        <button
          className={
            currentActive == "communication"
              ? `${Active} wid-9r`
              : `${InActive} wid-9r`
          }
          onClick={btnClickHandler}
        >
          Communication
        </button>
        <button
          className={
            currentActive == "acknowledgement"
              ? `${Active} wid-11r`
              : `${InActive} wid-11r`
          }
          onClick={btnClickHandler}
        >
          Acknowledgement
        </button>
      </Col>
    </div>
  );
}

export default CreateQuotationTabSec;
