// @ts-nocheck
import React, { useState } from "react";
import { Col, Form, Image, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Logo, showPwdImg, hidePwdImg, LoginVideo } from "../assets/img";
import LogInFooter from "../logincomponent/LogInFooter";
import LogInConnect from "../logincomponent/LogInConnect";
import useToken from "../constants/Storage/useToken";
import useUser from "../constants/Storage/userData";
import httpClient from "../constants/HttpClient/httpClient";
import { api } from "../constants/Services";
import { TOKEN } from "../constants/Services/constants";
import axios from "axios";
import Loader from "../component/Loader";
import { ToastContainer, toast } from "react-toastify";
import ErrorHandler from "../constants/Services/Api/errorHandler";
import ErrorModel from "../constants/Services/Api/errorModel";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { GrFacebookOption } from "react-icons/gr";

// NEW
import ReCAPTCHA from "react-google-recaptcha";
import useProfileStatus from "../constants/Storage/userStauts";

const LogIn = () => {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const { profileStatus, setProfileStatus } = useProfileStatus();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const [errorBox, setErrorBox] = useState(false);
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPwd(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPwd(new_pass);
    }
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const submitHandle = async () => {
    if (name?.length == 0 || pwd?.length == 0) {
      setNameErr(true);
      setPassErr(true);
    } else if (errorMessage?.length == 0) {
      let data = {
        email: name,
        // user_name: name,
        password: pwd,
      };

      setLoader(true);
      // navigate("/dashboard");
      // let token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
      // setToken(token);
      api.login(data).then((res) => {
        if (res?.status == "success") {
          httpClient.defaults.headers.common["Authorization"] =
            `Bearer ${res?.token}` || "";
          localStorage.setItem("permissions", JSON.stringify(res?.permissions));
          setProfileStatus(
            res?.supplier?.supplier?.profile_status
              ? res?.supplier?.supplier?.profile_status
              : "0"
          );
          setUser(res?.supplier);
          setToken(res?.token);
          setLoader(false);
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            if (
              res?.supplier?.supplier?.business_source?.ReferenceDetailsName ==
                "Freight Forwarder" ||
              res?.supplier?.supplier?.business_source?.ReferenceDetailsName ==
                "Transport Agent"
            ) {
              navigate("/dashboard-user");
              window.location.reload();
            } else {
              navigate("/dashboard");
              window.location.reload();
            }
          }, 800);
        } else {
          setLoader(false);
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  const forgotPassHandler = () => {
    navigate("/loginforgot");
  };
  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };
  return (
    <div className="login-bg">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      {loader && <Loader />}
      <div className="video-bg">
        <video autoPlay loop muted className="">
          <source src={LoginVideo} type="video/mp4" />
        </video>
        <div className="d-flex-al-jc log_cent">
          <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
            <div className="form_box">
              <Image src={Logo} />
              <h6>Welcome To Supplier Portal</h6>
              <div>
                <Col xs={12} className="position-relative pb-2">
                  <input
                    className="form-control"
                    type="name"
                    placeholder="Company Email ID"
                    onChange={(e) => setName(e.target.value)}
                  />
                  {validEmail.test(name) == false && nameErr && (
                    <p className="err-input">Enter Vaild Company Email ID</p>
                  )}
                </Col>
                <Col xs={12} className="pwd-container position-relative pb-2">
                  <input
                    name="pwd"
                    className="form-control"
                    placeholder="Enter Password"
                    type={isRevealPwd ? "text" : "password"}
                    value={pwd}
                    onChange={(e) => handlePassword(e)}
                  />
                  <Image
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? showPwdImg : hidePwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                  {pwd?.length == 0 && passErr && (
                    <p className="err-input">Enter Password</p>
                  )}

                  {errorMessage && (
                    <p className="err-input mt-4">{errorMessage}</p>
                  )}
                </Col>
                {/* {errorMessage && (
                  <p className="err-input-new-err">{errorMessage}</p>
                )} */}

                <button
                  onClick={forgotPassHandler}
                  className="cust-btn w-100 text-blue text-end fs-13px  p-0 primary1"
                >
                  Forgot password?
                </button>
                {/* <ReCAPTCHA sitekey="6LcX6A8pAAAAAGgun2tj3cnC3KiNK5kfodxwgfjA" /> */}
                <button
                  style={{ background: "#247395" }}
                  onClick={submitHandle}
                  className="bt-tx"
                >
                  Login
                </button>
              </div>
              <LogInConnect />
              {/* <div className="log-svg my-4">
                <h5>Connect with us</h5>
                <GrFacebookOption />
                <FaTwitter />
                <BsYoutube />
                <AiFillInstagram />
                <FaLinkedinIn />
              </div> */}
            </div>
          </Col>
        </div>
      </div>

      <LogInFooter />
    </div>
  );
};
export default LogIn;
