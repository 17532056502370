import React, { useState } from "react";
import { Col, Form, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Logo, showPwdImg, hidePwdImg } from "../../../assets/img";
import LogInFooter from "../../../logincomponent/LogInFooter";
// import LogInConnect from "../../../logincomponent/LogInConnect";
const AdminLogIn = () => {
  const navigate = useNavigate();
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);

  const submitHandle = () => {
    if (name?.length == 0 || pwd?.length < 8) {
      setNameErr(true);
      setPassErr(true);
    } else {
      navigate("/admin/dashboard");
    }
  };

  return (
    <div className="login-bg">
      <div className="d-flex-al-jc  log_cent">
        <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
          <div className="form_box">
            <Image src={Logo} />
            <h6>Welcome to Operator Portal</h6>
            <Form>
              <input
                className="form-control"
                type="name"
                placeholder="User Name"
                onChange={(e) => setName(e.target.value)}
              />
              {name?.length == 0 && nameErr && (
                <span style={{ color: "red" }}>Enter the Username</span>
              )}
              <div className="pwd-container">
                <input
                  name="pwd"
                  className="form-control"
                  placeholder="Enter Password"
                  type={isRevealPwd ? "text" : "password"}
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
                <Image
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
                {pwd?.length < 8 && passErr && (
                  <span style={{ color: "red" }}>Enter 8 digit Password</span>
                )}
              </div>
              <Button onClick={submitHandle} className="bt-tx">
                Login
              </Button>
            </Form>
          </div>
        </Col>
      </div>
      <LogInFooter />
    </div>
  );
};
export default AdminLogIn;
