// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Form, Image, Button, Modal } from "react-bootstrap";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactDOM from "react-dom";
// import TimezoneSelect from "react-timezone-select";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import TimeZone from "./TimeZone";
import PermissionSetting from "./PermissionSetting";
import AddDescription from "./AddDescription";
import LeftAddUser from "./LeftAddUser";
import { api } from "../../constants/Services";
import { FavLogo, Check, PersonImg } from "../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import LeftAddUserCopy from "./LeftAddUser copy";
import Loader from "../Loader";
import ErrorModel from "../../constants/Services/Api/errorModel";

const RightAddUser = ({ routeType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const Id = location?.state?.id;
  // console.log(type);
  // console.log(routeType);
  const [show, setShow] = useState(false);
  const [departShow, setDepartShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successRole, setSuccessRole] = useState(false);
  const [view, setView] = useState(false);
  const [country, setCountry] = useState();
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [roles, setRoles] = useState([]);
  const [des, setDes] = useState([]);
  const [dep, setDep] = useState([]);
  const [desData, setDesData] = useState([]);
  const [depData, setDepData] = useState([]);
  const [roleModules, setRoleModules] = useState([]);
  const [roleData, setRolesData] = useState("");
  const [roleDatas, setRolesDatas] = useState("");
  const [buttonEnable, setButtonEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  // const getList = () => {
  //   api.departmentView().then((res) => {
  //     setDepartmentList(res.departments);
  //   });
  // };

  const departShowHandler = () => {
    setShow(false);
    setDepartShow(true);
  };
  const designationShowHandler = () => {
    setShow(true);
    setDepartShow(false);
  };

  const [employeeId, setEmpolyeeId] = useState("");
  const [userName, setUseName] = useState("");
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [tele, setTele] = useState("");
  const [teleExt, setTeleExt] = useState("");
  const [teleCode, setTeleCode] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [uploadImage, setUploadImage] = useState();
  const [uploadImagePre, setUploadImagePre] = useState();
  const [doj, setDoj] = useState("");

  // VALIDATION

  const [employeeIdErr, setEmpolyeeIdErr] = useState(false);
  const [userNameErr, setUseNameErr] = useState(false);
  const [fNameErr, setFnameErr] = useState(false);
  const [lNameErr, setLnameErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [departmentErr, setDepartmentErr] = useState(false);
  const [designationErr, setDesignationErr] = useState(false);
  const [timeZoneErr, setTimeZoneErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [teleErr, setTeleErr] = useState(false);
  const [teleExtErr, setteleExtErr] = useState(false);
  const [dojErr, setDojErr] = useState(false);

  const viewUser = () => {
    api.viewSupplierUser(Id).then((res) => {
      if (res?.status == "success") {
        // console.log("res", res);
        setEmpolyeeId(res?.supplier_user?.emp_id);
        setFname(res?.supplier_user?.first_name);
        setLname(res?.supplier_user?.last_name);
        setUseName(res?.supplier_user?.user_name);
        setEmail(res?.supplier_user?.email);
        setMobile(res?.supplier_user?.mobile_code + res?.supplier_user?.mobile);
        setMobileCode(res?.supplier_user?.mobile_code);
        setTimeZone(res?.supplier_user?.timezone);
        setTele(
          res?.supplier_user?.telephone_code + res?.supplier_user?.telephone
        );
        setTeleExt(res?.supplier_user?.SPUD_CCMobile);
        setTeleCode(res?.supplier_user?.telephone_code);
        // setCountry(res?.supplier_user?.SPUD_CCMobile);
        setRolesData(res?.supplier_user?.Mst_Role_id);
        setDepartment(res?.supplier_user?.sm_department_id);
        setDesignation(res?.supplier_user?.sm_designation_id);
        // setTimeZone(res?.supplier_user?.SPUD_TimeZone);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const submitHandle = () => {
    let formdata = new FormData();

    formdata.append("employee_id", employeeId);
    formdata.append("first_name", fName);
    formdata.append("last_name", lName);
    formdata.append("email", email);
    formdata.append("user_name", userName);
    formdata.append("department_id", department);
    formdata.append("designation_id", designation);
    formdata.append("timezone", timeZone);

    formdata.append("ext", teleExt);

    formdata.append("doj", doj);

    if (tele?.length == 12) {
      formdata.append("telephone", tele?.slice(-10));
      formdata.append("telephone_code", tele?.slice(0, 2));
    } else {
      formdata.append("telephone", tele?.slice(3, tele?.length));
      formdata.append("telephone_code", tele?.slice(0, 3));
    }

    if (mobile?.length == 12) {
      formdata.append("mobile", mobile?.slice(-10));
      // formdata.append("country", mobile?.slice(0, 2));
      formdata.append("mobile_code", mobile?.slice(0, 2));
    } else {
      formdata.append("mobile", mobile?.slice(3, mobile?.length));
      formdata.append("mobile_code", mobile?.slice(0, 3));
    }

    formdata.append("role_id", roleData);

    if (uploadImage) {
      formdata.append("image", uploadImage);
    }

    if (Id) {
      if (Id) {
        formdata.append("_method", "PUT");
      }
      setButtonEnable(true);
      api.editSupplierUser(Id, formdata).then((res) => {
        if (res?.status == "success") {
          setButtonEnable(false);
          setView(true);
          navigate("/enterprise-admin/userlist");
        } else {
          setError(res);
          setErrorBox(true);
          setButtonEnable(false);
        }
      });
    } else if (!Id) {
      if (
        employeeId?.length == 0 ||
        userName?.length == 0 ||
        fName?.length == 0 ||
        lName?.length == 0 ||
        email?.length == 0 ||
        mobile?.length == 0 ||
        timeZone?.length == 0 ||
        department?.length == 0 ||
        designation?.length == 0 ||
        roleData?.length == 0 ||
        tele?.length == 0 ||
        teleExt?.length == 0 ||
        doj?.length == 0
      ) {
        setEmpolyeeIdErr(true);
        setUseNameErr(true);
        setFnameErr(true);
        setLnameErr(true);
        setMobileErr(true);
        setEmailErr(true);
        setTimeZoneErr(true);
        setDepartmentErr(true);
        setDesignationErr(true);
        setRoleErr(true);
        setTeleErr(true);
        setteleExtErr(true);
        setDojErr(true);
      } else {
        setButtonEnable(true);
        api.addSupplierUser(formdata).then((res) => {
          if (res?.status == "success") {
            setButtonEnable(false);
            navigate("/enterprise-admin/userlist");
            setError(res);
            setErrorBox(true);
          } else {
            setError(res);
            setErrorBox(true);
            setButtonEnable(false);
          }
        });
      }
    }
  };

  const getRoles = () => {
    api.createRole().then((res) => {
      if (res?.status == "success") {
        setRoles(res?.roles);
        setDesData(res?.designations);
        setDepData(res?.departments);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const countryCode = (e, country, value) => {
    // console.log(value, country, e);
    // console.log(value);
    let data = value;
    if (data) {
      let splitCode = data?.target?.value;
      setMobile(splitCode);
    }
  };

  const teleCodes = (e, country, value) => {
    console.log(value, country, e);
    let data = value;
    // console.log("data", data);
    if (data) {
      let splitCode = data?.target?.value;
      // console.log("teleCodes", splitCode);
      // setTele(data?.target?.value)
      setTele(splitCode);
    }
    // console.log(value?.target?.value);
    // let data = value?.target?.value.split(" ");
    // setTele(data[1]);
    // setTeleCode(data[0]);
  };

  useEffect(() => {
    getRoles();
    if (Id) {
      viewUser();
    }
  }, []);

  const [permission, setPermissions] = useState([]);

  const viewRoles = () => {
    api.viewRole(roleData)?.then((res) => {
      // console.log("res", res);
      //   let temp = [];
      //   res?.modules?.map((item) => {
      //     temp.push(item?.SPMM_ModuleType);
      //   });
      //   setPermissions(temp);
      // });

      let temp = [];
      res?.permissions?.map((item) => {
        temp.push(+item);
      });
      setPermissions(temp);
      api.roleCreate().then((res) => {
        setRoleModules(res.modules);
      });
    });
  };

  // const viewRoles = () => {
  //   api.viewRole(Id).then((res) => {
  //     setName(res?.role?.SPRM_Role);
  //     // console.log("res", res);
  //     let temp = [];
  //     res?.permissions?.map((item) => {
  //       temp.push(item);
  //     });
  //     setPermissions(temp);
  //   });
  // };

  // console.log(permission);

  // TIMEZONE

  const [zoneLists, setZoneLists] = useState([]);

  const timeZones = [
    {
      value: "Dateline Standard Time",
      abbr: "DST",
      offset: -12,
      isdst: false,
      text: "(UTC-12:00) International Date Line West",
    },
    {
      value: "UTC-11",
      abbr: "U",
      offset: -11,
      isdst: false,
      text: "(UTC-11:00) Coordinated Universal Time-11",
    },
    {
      value: "Hawaiian Standard Time",
      abbr: "HST",
      offset: -10,
      isdst: false,
      text: "(UTC-10:00) Hawaii",
    },
    {
      value: "Alaskan Standard Time",
      abbr: "AKDT",
      offset: -8,
      isdst: true,
      text: "(UTC-09:00) Alaska",
    },
    {
      value: "Pacific Standard Time (Mexico)",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      text: "(UTC-08:00) Baja California",
    },
    {
      value: "Pacific Standard Time",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      text: "(UTC-08:00) Pacific Time (US & Canada)",
    },
    {
      value: "US Mountain Standard Time",
      abbr: "UMST",
      offset: -7,
      isdst: false,
      text: "(UTC-07:00) Arizona",
    },
    {
      value: "Mountain Standard Time (Mexico)",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    },
    {
      value: "Mountain Standard Time",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      text: "(UTC-07:00) Mountain Time (US & Canada)",
    },
    {
      value: "Central America Standard Time",
      abbr: "CAST",
      offset: -6,
      isdst: false,
      text: "(UTC-06:00) Central America",
    },
    {
      value: "Central Standard Time",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      text: "(UTC-06:00) Central Time (US & Canada)",
    },
    {
      value: "Central Standard Time (Mexico)",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    },
    {
      value: "Canada Central Standard Time",
      abbr: "CCST",
      offset: -6,
      isdst: false,
      text: "(UTC-06:00) Saskatchewan",
    },
    {
      value: "SA Pacific Standard Time",
      abbr: "SPST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Bogota, Lima, Quito",
    },
    {
      value: "Eastern Standard Time",
      abbr: "EDT",
      offset: -4,
      isdst: true,
      text: "(UTC-05:00) Eastern Time (US & Canada)",
    },
    {
      value: "US Eastern Standard Time",
      abbr: "UEDT",
      offset: -4,
      isdst: true,
      text: "(UTC-05:00) Indiana (East)",
    },
    {
      value: "Venezuela Standard Time",
      abbr: "VST",
      offset: -4.5,
      isdst: false,
      text: "(UTC-04:30) Caracas",
    },
    {
      value: "Paraguay Standard Time",
      abbr: "PST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Asuncion",
    },
    {
      value: "Atlantic Standard Time",
      abbr: "ADT",
      offset: -3,
      isdst: true,
      text: "(UTC-04:00) Atlantic Time (Canada)",
    },
    {
      value: "Central Brazilian Standard Time",
      abbr: "CBST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Cuiaba",
    },
    {
      value: "SA Western Standard Time",
      abbr: "SWST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    },
    {
      value: "Pacific SA Standard Time",
      abbr: "PSST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Santiago",
    },
    {
      value: "Newfoundland Standard Time",
      abbr: "NDT",
      offset: -2.5,
      isdst: true,
      text: "(UTC-03:30) Newfoundland",
    },
    {
      value: "E. South America Standard Time",
      abbr: "ESAST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Brasilia",
    },
    {
      value: "Argentina Standard Time",
      abbr: "AST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Buenos Aires",
    },
    {
      value: "SA Eastern Standard Time",
      abbr: "SEST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Cayenne, Fortaleza",
    },
    {
      value: "Greenland Standard Time",
      abbr: "GDT",
      offset: -2,
      isdst: true,
      text: "(UTC-03:00) Greenland",
    },
    {
      value: "Montevideo Standard Time",
      abbr: "MST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Montevideo",
    },
    {
      value: "Bahia Standard Time",
      abbr: "BST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Salvador",
    },
    {
      value: "UTC-02",
      abbr: "U",
      offset: -2,
      isdst: false,
      text: "(UTC-02:00) Coordinated Universal Time-02",
    },
    {
      value: "Mid-Atlantic Standard Time",
      abbr: "MDT",
      offset: -1,
      isdst: true,
      text: "(UTC-02:00) Mid-Atlantic - Old",
    },
    {
      value: "Azores Standard Time",
      abbr: "ADT",
      offset: 0,
      isdst: true,
      text: "(UTC-01:00) Azores",
    },
    {
      value: "Cape Verde Standard Time",
      abbr: "CVST",
      offset: -1,
      isdst: false,
      text: "(UTC-01:00) Cape Verde Is.",
    },
    {
      value: "Morocco Standard Time",
      abbr: "MDT",
      offset: 1,
      isdst: true,
      text: "(UTC) Casablanca",
    },
    {
      value: "UTC",
      abbr: "CUT",
      offset: 0,
      isdst: false,
      text: "(UTC) Coordinated Universal Time",
    },
    {
      value: "GMT Standard Time",
      abbr: "GDT",
      offset: 1,
      isdst: true,
      text: "(UTC) Dublin, Edinburgh, Lisbon, London",
    },
    {
      value: "Greenwich Standard Time",
      abbr: "GST",
      offset: 0,
      isdst: false,
      text: "(UTC) Monrovia, Reykjavik",
    },
    {
      value: "W. Europe Standard Time",
      abbr: "WEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    },
    {
      value: "Central Europe Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    },
    {
      value: "Romance Standard Time",
      abbr: "RDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    },
    {
      value: "Central European Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    },
    {
      value: "W. Central Africa Standard Time",
      abbr: "WCAST",
      offset: 1,
      isdst: false,
      text: "(UTC+01:00) West Central Africa",
    },
    {
      value: "Namibia Standard Time",
      abbr: "NST",
      offset: 1,
      isdst: false,
      text: "(UTC+01:00) Windhoek",
    },
    {
      value: "GTB Standard Time",
      abbr: "GDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Athens, Bucharest",
    },
    {
      value: "Middle East Standard Time",
      abbr: "MEDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Beirut",
    },
    {
      value: "Egypt Standard Time",
      abbr: "EST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Cairo",
    },
    {
      value: "Syria Standard Time",
      abbr: "SDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Damascus",
    },
    {
      value: "E. Europe Standard Time",
      abbr: "EEDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) E. Europe",
    },
    {
      value: "South Africa Standard Time",
      abbr: "SAST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Harare, Pretoria",
    },
    {
      value: "FLE Standard Time",
      abbr: "FDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    },
    {
      value: "Turkey Standard Time",
      abbr: "TDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Istanbul",
    },
    {
      value: "Israel Standard Time",
      abbr: "JDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Jerusalem",
    },
    {
      value: "Libya Standard Time",
      abbr: "LST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Tripoli",
    },
    {
      value: "Jordan Standard Time",
      abbr: "JST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Amman",
    },
    {
      value: "Arabic Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Baghdad",
    },
    {
      value: "Kaliningrad Standard Time",
      abbr: "KST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Kaliningrad, Minsk",
    },
    {
      value: "Arab Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Kuwait, Riyadh",
    },
    {
      value: "E. Africa Standard Time",
      abbr: "EAST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Nairobi",
    },
    {
      value: "Iran Standard Time",
      abbr: "IDT",
      offset: 4.5,
      isdst: true,
      text: "(UTC+03:30) Tehran",
    },
    {
      value: "Arabian Standard Time",
      abbr: "AST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Abu Dhabi, Muscat",
    },
    {
      value: "Azerbaijan Standard Time",
      abbr: "ADT",
      offset: 5,
      isdst: true,
      text: "(UTC+04:00) Baku",
    },
    {
      value: "Russian Standard Time",
      abbr: "RST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    },
    {
      value: "Mauritius Standard Time",
      abbr: "MST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Port Louis",
    },
    {
      value: "Georgian Standard Time",
      abbr: "GST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Tbilisi",
    },
    {
      value: "Caucasus Standard Time",
      abbr: "CST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Yerevan",
    },
    {
      value: "Afghanistan Standard Time",
      abbr: "AST",
      offset: 4.5,
      isdst: false,
      text: "(UTC+04:30) Kabul",
    },
    {
      value: "West Asia Standard Time",
      abbr: "WAST",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Ashgabat, Tashkent",
    },
    {
      value: "Pakistan Standard Time",
      abbr: "PST",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Islamabad, Karachi",
    },
    {
      value: "India Standard Time",
      abbr: "IST",
      offset: 5.5,
      isdst: false,
      text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
    {
      value: "Sri Lanka Standard Time",
      abbr: "SLST",
      offset: 5.5,
      isdst: false,
      text: "(UTC+05:30) Sri Jayawardenepura",
    },
    {
      value: "Nepal Standard Time",
      abbr: "NST",
      offset: 5.75,
      isdst: false,
      text: "(UTC+05:45) Kathmandu",
    },
    {
      value: "Central Asia Standard Time",
      abbr: "CAST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Astana",
    },
    {
      value: "Bangladesh Standard Time",
      abbr: "BST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Dhaka",
    },
    {
      value: "Ekaterinburg Standard Time",
      abbr: "EST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Ekaterinburg",
    },
    {
      value: "Myanmar Standard Time",
      abbr: "MST",
      offset: 6.5,
      isdst: false,
      text: "(UTC+06:30) Yangon (Rangoon)",
    },
    {
      value: "SE Asia Standard Time",
      abbr: "SAST",
      offset: 7,
      isdst: false,
      text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    },
    {
      value: "N. Central Asia Standard Time",
      abbr: "NCAST",
      offset: 7,
      isdst: false,
      text: "(UTC+07:00) Novosibirsk",
    },
    {
      value: "China Standard Time",
      abbr: "CST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    },
    {
      value: "North Asia Standard Time",
      abbr: "NAST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Krasnoyarsk",
    },
    {
      value: "Singapore Standard Time",
      abbr: "MPST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Kuala Lumpur, Singapore",
    },
    {
      value: "W. Australia Standard Time",
      abbr: "WAST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Perth",
    },
    {
      value: "Taipei Standard Time",
      abbr: "TST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Taipei",
    },
    {
      value: "Ulaanbaatar Standard Time",
      abbr: "UST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Ulaanbaatar",
    },
    {
      value: "North Asia East Standard Time",
      abbr: "NAEST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Irkutsk",
    },
    {
      value: "Tokyo Standard Time",
      abbr: "TST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    },
    {
      value: "Korea Standard Time",
      abbr: "KST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Seoul",
    },
    {
      value: "Cen. Australia Standard Time",
      abbr: "CAST",
      offset: 9.5,
      isdst: false,
      text: "(UTC+09:30) Adelaide",
    },
    {
      value: "AUS Central Standard Time",
      abbr: "ACST",
      offset: 9.5,
      isdst: false,
      text: "(UTC+09:30) Darwin",
    },
    {
      value: "E. Australia Standard Time",
      abbr: "EAST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Brisbane",
    },
    {
      value: "AUS Eastern Standard Time",
      abbr: "AEST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    },
    {
      value: "West Pacific Standard Time",
      abbr: "WPST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Guam, Port Moresby",
    },
    {
      value: "Tasmania Standard Time",
      abbr: "TST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Hobart",
    },
    {
      value: "Yakutsk Standard Time",
      abbr: "YST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Yakutsk",
    },
    {
      value: "Central Pacific Standard Time",
      abbr: "CPST",
      offset: 11,
      isdst: false,
      text: "(UTC+11:00) Solomon Is., New Caledonia",
    },
    {
      value: "Vladivostok Standard Time",
      abbr: "VST",
      offset: 11,
      isdst: false,
      text: "(UTC+11:00) Vladivostok",
    },
    {
      value: "New Zealand Standard Time",
      abbr: "NZST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Auckland, Wellington",
    },
    {
      value: "UTC+12",
      abbr: "U",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Coordinated Universal Time+12",
    },
    {
      value: "Fiji Standard Time",
      abbr: "FST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Fiji",
    },
    {
      value: "Magadan Standard Time",
      abbr: "MST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Magadan",
    },
    {
      value: "Kamchatka Standard Time",
      abbr: "KDT",
      offset: 13,
      isdst: true,
      text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    },
    {
      value: "Tonga Standard Time",
      abbr: "TST",
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Nuku'alofa",
    },
    {
      value: "Samoa Standard Time",
      abbr: "SST",
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Samoa",
    },
  ];

  useEffect(() => {
    let temp = [];
    Object.keys(timeZones).map((list, i) => {
      temp.push(list);
    });
    temp.sort();
    setZoneLists(temp);
  }, []);

  const [countries, setCountries] = useState([]);

  const countrys = () => {
    api.country().then((res) => {
      // console.log("res", res);
    });
  };

  const [value, setValue] = useState();
  const [data, setData] = useState();

  const chane = (e) => {
    setValue(e.target.value);
    setData();
  };

  // console.log(tele);
  // console.log(teleCode);

  const checkSpecialChar = (e) => {
    // console.log(e);
    // if (!/[0-9a-zA-Z]/.test(e.key)) {
    if (/[-!$%^&*()_+[\]\\|~=`{}[:;.'"<>?/,.@#\]]/g.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (roleData) {
      viewRoles();
    }
  }, [roleData]);

  // useEffect(() => {
  //   getList();
  // }, []);

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    switch (file.type) {
      case "image/png":
        setUploadImagePre(URL.createObjectURL(e.target.files[0]));
        setUploadImage(e.target.files[0]);
        //('image type is png');
        break;
      case "image/jpg":
        //('image/jpg')
        setUploadImagePre(URL.createObjectURL(e.target.files[0]));
        setUploadImage(e.target.files[0]);
        break;
      case "image/jpeg":
        setUploadImagePre(URL.createObjectURL(e.target.files[0]));
        setUploadImage(e.target.files[0]);
        //('image is jpeg')
        break;
      default:
        alert("Only Upload Format png, jpg, jpeg");
      // image extension is not valid
    }
  };

  return (
    <>
      <Col
        xs={12}
        lg={12}
        xl={8}
        xxl={8}
        className={
          routeType == "select user"
            ? "rit-add-user1"
            : "rit-add-user mx-xxl-auto"
        }
      >
        {/* <LeftAddUserCopy routeType="select user" /> */}
        {routeType == "select user" ? (
          <div
            className={
              routeType == "select user"
                ? "left-add-user ms-0 d-sm-flex w-100"
                : "left-add-user"
            }
          >
            <div
              className={
                routeType == "select user ms-3"
                  ? "upload-img me-3 ms-3"
                  : "upload-img ms-3"
              }
              style={{
                borderWidth: uploadImage ? "1px" : "0px",
                height: "fit-content",
              }}
            >
              <Image
                // style={uploadImage ? "" : { width: "50%", height: "50%" }}
                src={uploadImage ? uploadImagePre : PersonImg}
                className="image-placeholder "
              />
              <input
                type="file"
                className="file-upload-input"
                onChange={fileChange}
              />
            </div>
            <div className="left-txt p-3">
              <p className="mt-2">Upload your Profile Picture</p>
              <p className="mt-2">Accepeted Image Format: .jpg/.jpeg/.png</p>
              <p className="mt-0">Maximum file size: 5MB</p>
              <p className="mt-0">Aspect Ratio: 1/1 </p>
            </div>
          </div>
        ) : (
          <Link
            className="link-add-user mt-2 me-1 px-2 d-none d-lg-block"
            to={"/enterprise-admin/userlist"}
          >
            <FaArrowLeft />
            {type == "add" ? "Add" : "Edit"} User
          </Link>
        )}
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <Col className="d-flex flex-wrap mx-2 mx-lg-0">
          <Col
            className="input-box mt-3 px-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p>
              Employee Id <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="number"
              onChange={(e) => setEmpolyeeId(e.target.value)}
              value={employeeId}
            />
            {employeeId?.length == 0 && employeeIdErr && (
              <p className="err-input pe-3">Enter Employee Id</p>
            )}
          </Col>
          <Col
            className="input-box mt-3 px-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p>
              User Name <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              onChange={(e) => setUseName(e.target.value)}
              value={userName}
              onKeyDown={(e) => checkSpecialChar(e)}
            />
            {userName?.length == 0 && userNameErr && (
              <p className="err-input pe-3">Enter Username</p>
            )}
          </Col>
          <Col
            className="input-box mt-3 px-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p>
              First Name <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              onChange={(e) => setFname(e.target.value)}
              value={fName}
              onKeyDown={(e) => checkSpecialChar(e)}
            />
            {fName?.length == 0 && fNameErr && (
              <p className="err-input pe-3">Enter Firstname</p>
            )}
          </Col>
          <Col
            className="input-box mt-3 px-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p>
              Last Name <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              onChange={(e) => setLname(e.target.value)}
              value={lName}
              onKeyDown={(e) => checkSpecialChar(e)}
            />
            {lName?.length == 0 && lNameErr && (
              <p className="err-input pe-3">Enter Lastname</p>
            )}
          </Col>
          <Col
            className="input-box mt-4 px-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p>
              Email <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            {validEmail.test(email) == false && emailErr && (
              <p className="err-input pe-3">Enter valid Email</p>
            )}
          </Col>
          <Col
            className="input-box mt-4 px-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p>
              Date of Joining <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="date"
              onChange={(e) => setDoj(e.target.value)}
              value={doj}
            />
            {doj?.length == 0 && dojErr && (
              <p className="err-input pe-3">Enter date of joining</p>
            )}
          </Col>
          <Col
            className="input-box telephone-input-box mt-3 px-2 d-flex flex-wrap position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <div style={{ width: "100%" }}>
              <div className="d-flex">
                <div className="d-flex">
                  {/* <p style={{ width: "40px" }}>Code</p> */}
                  <p>Mobile</p>
                </div>
              </div>
              <PhoneInput
                country="in"
                onChange={(e) => {
                  setMobile(e);
                }}
                value={mobile}
              />
            </div>
            {mobile?.length == 0 && mobileErr && (
              <p className="err-input pe-3">Enter Mobile</p>
            )}
          </Col>
          <Col
            className="input-box position-relative telephone-input-box mt-3 px-2 d-flex flex-wrap position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <div style={{ width: "78%" }}>
              <div className="d-flex">
                <div className="d-flex">
                  {/* <p style={{ width: "40px" }}>Code</p> */}
                  <p>Telephone</p>
                </div>
              </div>

              <PhoneInput
                country="in"
                value={tele}
                onChange={(e) => {
                  setTele(e);
                }}
              />

              {/* <div className="d-flex">
                <select
                  name="countries"
                  onChange={(e) => setTeleCode(e.target.value)}
                  value={teleCode}
                  className="border-wid"
                >
                  <option value="+91">&#x1F1EE;&#x1F1F3; +91</option>
                  <option value="+1">&#x1F1FA;&#x1F1F8; +1</option>
                </select>
                <input
                  type="number"
                  onChange={(e) => setTele(e.target.value)}
                  value={tele}
                  className="border-nne"
                />
              </div> */}
              {tele?.length == 0 && teleErr && (
                <p
                  // className="err-input pe-3"
                  style={{
                    position: "absolute",
                    bottom: "-17px",
                    right: 0,
                    marginRight: "80px",
                    color: "#ff0000",
                  }}
                >
                  Enter Telephone
                </p>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                width: "80px",
                right: 0,
                bottom: "-2px",
                background: "white",
                paddingLeft: "10px",
              }}
            >
              <p style={{}}>Ext</p>
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 3) {
                    e.target.setCustomValidity("invalid Ext");
                  } else if (e.target.value.length == 3) {
                    e.target.setCustomValidity("");

                    setTeleExt(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={3}
                onChange={(e) => setTeleExt(e.target.value)}
                type="number"
                className="m-0 border-0"
                placeholder="Ext"
                value={teleExt}
              />
              {teleExt?.length == 0 && teleExtErr && (
                <p className="err-input pe-3">Enter Ext</p>
              )}
            </div>
          </Col>

          <Col
            className="input-box mt-1 d-flex flex-wrap mt-2 px-2 pt-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p style={{ width: "40px" }}>
              Department<span style={{ color: "red" }}>*</span>
            </p>
            <select
              className=""
              onChange={(e) => setDepartment(e.target.value)}
              value={department}
            >
              <option>select department</option>
              {depData?.map((item, ind) => (
                <option key={ind} value={item?.id}>
                  {item?.value}
                </option>
              ))}
            </select>
            <button onClick={departShowHandler} className="ad-depart ">
              Add Department
            </button>
            {department?.length == 0 && departmentErr && (
              <p className="err-input pe-3">Select Department</p>
            )}
          </Col>

          <Col
            className="input-box mt-1 d-flex flex-wrap mt-2 px-2 pt-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p style={{ width: "40px" }}>
              Designation<span style={{ color: "red" }}>*</span>
            </p>
            <select
              className=""
              onChange={(e) => setDesignation(e.target.value)}
              value={designation}
            >
              <option>select designation</option>
              {desData?.map((item, ind) => (
                <option key={ind} value={item?.id}>
                  {item?.value}
                </option>
              ))}
            </select>
            <button onClick={designationShowHandler} className="ad-depart ">
              Add Designation
            </button>
            {designation?.length == 0 && designationErr && (
              <p className="err-input pe-3">Select Designation</p>
            )}
          </Col>
          <Col
            className="input-box mt-1 d-flex flex-wrap mt-2 px-2 pt-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <p style={{ width: "40px" }}>
              Roles<span style={{ color: "red" }}>*</span>
            </p>
            <select
              className=""
              onChange={(e) => setRolesData(e.target.value)}
              value={roleData}
            >
              <option>select roles</option>
              {roles?.map((item, index) => (
                <option value={item?.id} key={index}>
                  {item?.role}
                </option>
              ))}
            </select>
            {roleData?.length == 0 && roleErr && (
              <p className="err-input pe-3">Select Role</p>
            )}
          </Col>

          <Col
            className="input-box mt-1 d-flex flex-wrap mt-3 px-2 pt-2 "
            xs={12}
            md={6}
            lg={5}
            style={{ position: "relative" }}
          >
            <p
              style={{
                position: "absolute",
                top: "0",
                zIndex: "111",
                left: "8px",
              }}
            >
              Time Zone <span style={{ color: "red" }}>*</span>
            </p>

            <select
              className="h-100"
              onChange={(e) => setTimeZone(e.target.value)}
              value={timeZone}
            >
              <option>select timezone</option>
              {timeZones?.map((item) => (
                <option value={item?.text}>{item?.text}</option>
              ))}
            </select>
            <div className="drop-select-box" />
            {timeZone?.length == 0 && timeZoneErr && (
              <p className="err-input pe-3">Select Time Zone</p>
            )}
          </Col>
          <Col
            className="input-box mt-1 d-flex flex-wrap justify-content-start  align-items-end mt-2 px-2 pt-2 position-relative"
            xs={12}
            md={6}
            lg={5}
          >
            <button
              className="cust-btn text-white bg-primary1"
              onClick={() =>
                roleModules?.length > 0
                  ? setShowModal(true)
                  : setSuccessRole(true)
              }
            >
              View
            </button>
            {/* <p style={{ width: "40px" }}>Responsible</p>  */}
            {/* <select */}
            {/* className="" 
            onChange={(e) => setRolesData(e.target.value)}
            value={roleData} */}

            {/* <option>responsive</option> */}
            {/* {permission?.map((item, index) => (
              <option key={index}>{item}</option>
            ))} */}
            {/* </select> */}
          </Col>
        </Col>
        <div className="px-2">{/* <PermissionSetting />  */}</div>
        <Col
          className="d-flex mt-3 justify-content-end px-2 mb-4"
          xs={12}
          md={12}
          lg={10}
        >
          <button
            className="cust-btn bg-lit-primary4 me-2"
            style={{ background: "#f3f3f3" }}
          >
            Clear
          </button>
          <button
            className="cust-btn text-white bg-primary1"
            onClick={submitHandle}
            disabled={buttonEnable == true ? true : false}
          >
            Submit
          </button>
        </Col>
        {show && (
          <AddDescription type="designation" setShow={setShow} show={show} />
        )}
        {departShow && (
          <AddDescription
            type="department"
            setShow={setDepartShow}
            show={departShow}
          />
        )}
      </Col>
      <Modal
        id="#po-card-acknowledge"
        className="add-user-role-modal-box fnt-fam-f2  pt-3 pb-1"
        show={showModal}
        size="md"
        centered
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="w-100 px-3 d-flex justify-content-end">
          <button onClick={() => setShowModal(false)} className="cust-btn p-0">
            <FaTimes />
          </button>
        </div>
        <Modal.Body className="descrioption-input d-flex  w-100 flex-column">
          {roleModules?.map((responblity, index) => (
            <>
              <h5 key={index} className="my-1 py-1">
                {responblity.module_type}
              </h5>
              {responblity?.permissions?.map((item, index) => (
                <div key={index} className="row">
                  <label className="d-flex ">
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={permission?.includes(item.id)}
                    />
                    <span>{item?.code}</span>
                  </label>
                </div>
              ))}
            </>
          ))}
        </Modal.Body>
      </Modal>
      <Modal
        id="#sucess"
        className="otp-modal"
        show={successRole}
        size="md"
        centered
        onHide={() => setSuccessRole(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="sup-modal ">
          <Image src={FavLogo} style={{ width: "30px" }} />
          <button
            onClick={() => setSuccessRole(false)}
            className="cust-btn p-0 mb-4"
            style={{ float: "right" }}
          >
            <FaTimes />
          </button>
          <div className="d-flex-as-jc img_tick ">
            {/* <Image src={Check} /> */}
            <div className="px-3 py-4">
              <h5>Select Role</h5>
            </div>
          </div>
        </div>
      </Modal>

      {/* <div key={index} className="row">
                  <label className="d-flex ">
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={permissions?.includes(
                        per?.SupPortPermissionMsts_PK
                      )}
                      onClick={() => roleId(per?.SupPortPermissionMsts_PK)}
                    />
                    <span>{per?.SPPM_Code}</span>
                  </label>
                </div> */}
    </>
  );
};

export default RightAddUser;
