import React from "react";
import GeneralTermsAndCondition from "../Terms&Condition";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";

import { useState } from "react";
import { Col, Image, Modal } from "react-bootstrap";
import QuoLastSectionBtn from "../../component/QuoLasSecBtn";
import { Link } from "react-router-dom";
import { FavLogo, Check } from "../../assets/img";

function QuotationTermsAndCondition() {
  const [activeBtn, setActiveBtn] = useState(1);
  const [saveTerms, setSaveTerms] = useState(false);
  const [date, setDate] = useState("");

  const dateHandler = (e) => setDate(e.target.value);

  const data = [
    {
      terms: "I Agree",
    },
    {
      terms: "I Agree with Comments",
    },
    {
      terms: "Disagree with Comments",
    },
  ];

  const renderedRadioButtons = data.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => setActiveBtn(index)}
        className="d-flex align-items-center border-0 bg-transparent p-0 me-2"
      >
        <div className="radio-btn">
          {activeBtn == index ? (
            <img src={Radio_icon} className="wid-15px" />
          ) : (
            <div
              className="radio-btn-inner"
              style={{
                background: "#fff",
              }}
            ></div>
          )}
        </div>
        {item.terms}
      </button>
    );
  });
  return (
    <div>
      <GeneralTermsAndCondition />
      <div className="radio-btn-div d-flex gap-3 my-3 py-2">
        {renderedRadioButtons}
      </div>
      <label className="my-2">
        Comments<span className="red-text">*</span>
      </label>
      <p className="py-1 mb-2 border-btm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer metus
        ligula, auctor eu sagittis eget, ultrices non turpis. Suspendisse
        pellentesque vestibulum sapien, non scelerisque ante vulputate in.
      </p>
      <p className="d-flex justify-content-end mb-2">Max 1500</p>
      <h5 className="font-col-sec my-2 py-1">Supplier Terms & Conditions</h5>
      <h5 className="primary2  my-2 py-1">Quotation Validity</h5>
      <div className="d-flex my-3 gap-1 py-2 flex-wrap">
        <Col md={12} lg={5} sm={12} xs={12} className="d-flex gap-1 flex-wrap">
          <Col md={6} lg={5} sm={12} xs={12}>
            <div>
              <label className="my-2"></label>
              <p className="border-btm pad-046r">20</p>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <div>
              <label className="m-0"></label>
              <select className="form-select ">
                <option>Days</option>
                <option>US</option>
                <option>EURO</option>
              </select>
            </div>
          </Col>
        </Col>
        <Col md={12} lg={6} xs={12} className="d-flex flex-column">
          <label className="my-2 ">Select Days</label>
          <input
            className="input-bor-btm fs-09r"
            type="date"
            onChange={dateHandler}
            value={date}
          />
        </Col>
      </div>
      <h5 className="font-col-sec my-2 py-1">Other Terms & Conditions</h5>
      <label className="my-2">Other Terms & Conditions</label>
      <p className="py-1 mb-2 border-btm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer metus
        ligula, auctor eu sagittis eget, ultrices non turpis. Suspendisse
        pellentesque vestibulum sapien, non scelerisque ante vulputate in.
      </p>
      <h5 className="font-col-sec my-2 py-1">Payment Terms & Conditions</h5>
      <label className="my-2">Payment Terms & Conditions</label>
      <p className="py-1 mb-2 border-btm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer metus
        ligula, auctor eu sagittis eget, ultrices non turpis. Suspendisse
        pellentesque vestibulum sapien, non scelerisque ante vulputate in.
      </p>
      <p className="d-flex justify-content-end  mb-2">Max 1500</p>
      <QuoLastSectionBtn
        setSaveTerms={setSaveTerms}
        BtnText="Save & Next"
        BtnSize="wid-9_5r"
      />
      <div className="m-3">
        <Modal
          id="#Save-terms"
          className="otp-modal"
          show={saveTerms}
          size="md"
          centered
          onHide={() => setSaveTerms(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="sup-modal ">
            <Image src={FavLogo} />
            <div className="mt-4">
              <div className="d-flex-as-jc img_tick ">
                <Image src={Check} />
                <div className="px-3">
                  <h5>Terms & Condtion</h5>
                  <p>Do you want to Submit ?</p>
                </div>
              </div>
              <div class="d-flex flex-row-reverse">
                <div class="row">
                  <div class="col-sm">
                    <Link
                      style={{
                        backgroundColor: "#D1D3D4",
                        width: "70px",
                        height: "30px",
                      }}
                      className="my-3 modal_btn"
                      to="#"
                      onClick={() => {
                        setSaveTerms(false);
                      }}
                    >
                      No
                    </Link>
                  </div>
                  <div class="col-sm">
                    <Link
                      style={{
                        width: "70px",
                        height: "30px",
                      }}
                      className="my-3 modal_btn"
                      to="#"
                      onClick={() => {
                        setSaveTerms(false);
                      }}
                    >
                      Yes
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default QuotationTermsAndCondition;
