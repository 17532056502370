import { Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import html2PDF from "jspdf-html2canvas";

import { api } from "../../constants/Services";
import useUser from "../../constants/Storage/userData";
import { FavLogo } from "../../assets/img";
import { ToWords } from "to-words";
import moment from "moment/moment";

function FinalInvoice() {
  const location = useLocation();
  const { user } = useUser();
  const id = location?.state?.id;
  const method = location?.state?.inv_type;
  // const [profileDetail, setProfileDetail] = useState([]);
  const [taxTotal, setTaxTotal] = useState(0);
  const toWords = new ToWords();
  const [taxHeader, setTaxHeader] = useState([]);
  const [invDetail, setInvDetail] = useState({
    accounts: [],
    dc_no: [],
    po_no: [],
    profile: [],
    dc: [],
    po: [],
    inv: [],
    bas_inv: {},
  });

  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [taxTotalValue, setTaxTotalValue] = useState([]);

  const downloadPdfDocument = () => {
    const input = document.getElementById("invoice-print-cont");

    html2PDF(input, {
      jsPDF: {
        format: "a4",
      },
      html2canvas: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      },

      imageType: "image/jpeg",
      output: "./pdf/generate.pdf",
    });
  };

  // useEffect(() => {
  //   api.profileView().then((res) => {
  //     setProfileDetail(res?.supplier);
  //     // console.log("pro", res);
  //   });
  // }, []);

  useEffect(() => {
    if (id) {
      api.invoice_view(id).then((res) => {
        const {
          accounts,
          dc_details,
          invoice,
          invoice_details,
          po_details,
          admin_supplier,
        } = res;
        if (res?.status == "success") {
          setData(res);
          let json = JSON.parse(res?.invoice?.tax_details);
          // console.log("jo", json);
          // console.log("res", res);
          let taxHeaders = [];

          let divider = +res?.invoice?.sub_total / 2;

          taxHeaders.push({
            value: +res?.invoice?.igst == 1 ? +res?.invoice?.sub_total : "",
            name: "IGST",
          });

          taxHeaders.push({
            value: +res?.invoice?.igst !== 1 ? divider : "",
            name: "CGST",
          });

          taxHeaders.push({
            value: +res?.invoice?.igst !== 1 ? divider : "",
            name: "SGST",
          });

          setTaxHeader(taxHeaders);

          // INVOICE CALCULATION
          if (json?.length > 0) {
            const sum = {};
            let tax = [];
            for (var i = 0; i < json.length; i++) {
              const tax = json[i];
              const amount = parseFloat(tax.tax_amount);
              sum[+tax.tax_perc] = sum[+tax.tax_perc]
                ? sum[+tax.tax_perc] + amount
                : amount;
            }

            let temp = {};
            let arr = [];

            for (const [key, value] of Object.entries(sum)) {
              let final = (value * +key) / 100;
              temp[+key] = Number(final).toFixed(2);
              arr.push({
                gst_id: +key,
                tax_value: Number(final).toFixed(2),
                value: Number(+value).toFixed(2),
                total: Number(+value + +final).toFixed(2),
              });
            }
            const subAmount = arr.reduce((a, b) => +a + +b?.value, 0);
            const total_tax_value = arr.reduce((a, b) => +a + +b?.tax_value, 0);

            // console.log("arr", arr);

            setList(arr);
            setTaxTotalValue(
              Number.isFinite(total_tax_value) ? total_tax_value : "-"
            );
          }
          let dc_no = dc_details?.map((item) => item?.dc_detail?.DCNNo);
          const po_no = po_details?.map(
            (item) =>
              item?.purchase_order?.MaterialPurchaseOrderNo ||
              item?.purchase_order?.LeatherPurchaseOrderNo
          );
          setInvDetail({
            accounts,
            dc_no,
            po_no,
            profile: admin_supplier,
            dc: dc_details,
            po: po_details,
            inv: invoice_details,
            bas_inv: invoice,
          });
        }

        let tax_total = +parseInt(res?.invoice?.gst_amount).toFixed(2);
        setTaxTotal(tax_total);
      });
    }
  }, []);

  // console.log("data", list);

  // console.log("taxHeader", taxHeader);
  console.log("taxHeader", method);

  return (
    <div className="invoice-final-output-cont my-3 py-3">
      <div className="d-sm-flex mb-3 py-3">
        <Col lg={5}></Col>
        <Col
          lg={7}
          sm={6}
          className="d-sm-flex justify-content-between align-items-center flex-wrap"
        >
          <h4
            className="m-0  fnt-fam-f2 fw-bold heading-cont-invoice"
            style={{ color: "transparent" }}
          >
            {/* Proforma Invoice */}
            {method === "commercial"
              ? "Commercial Invoice"
              : "Proforma Invoice"}
          </h4>
          <div className="d-sm-flex gap-2">
            {/* <button className="fnt-fam-f2 text-white heading-btn-invoice invoice-download-btn border-0 bg-primary2 fs-14px">
              Download
            </button> */}
            <button
              className="fnt-fam-f2 text-white invoice-print-btn border-0 bg-primary1 fs-14px"
              onClick={downloadPdfDocument}
            >
              Print
            </button>
          </div>
        </Col>
      </div>
      <div id="invoice-print-cont" className="p-3">
        <div className="invoice-logo-container d-md-flex flex-column align-items-center justify-content-center py-3">
          <img
            src={user?.img_url || FavLogo}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "100%",
            }}
          />
          <h5>{user?.supplier?.SupplierName}</h5>
          <div className="d-sm-flex gap-3">
            {/* <p className="fnt-fam-f2">Pan No: {invDetail?.bas_inv?.PanNo}</p> */}
            {+user?.supplier?.CountryID == 1 && (
              <p className="fnt-fam-f2">
                PAN No: {data?.admin_supplier?.PanNo}
              </p>
            )}
            {/* <p className="fnt-fam-f2">GST No: {user?.supplier?.GSTNo}</p> */}
            {+user?.supplier?.CountryID == 1 && (
              <p className="fnt-fam-f2">
                GST No:{" "}
                {data?.admin_supplier?.GSTNo
                  ? data?.admin_supplier?.GSTNo !== "null"
                    ? data?.admin_supplier?.GSTNo
                    : "-"
                  : "-"}
              </p>
            )}
            <p className="fnt-fam-f2">
              State: {data?.admin_supplier?.state?.StateName}
            </p>
            <p className="fnt-fam-f2">
              State Code: {data?.admin_supplier?.state?.StateCode}
            </p>
          </div>
          <p>
            {`${data?.admin_supplier?.Address1} ${
              data?.admin_supplier?.Address2
                ? data?.admin_supplier?.Address2
                : ""
            } ${
              data?.admin_supplier?.Address3
                ? data?.admin_supplier?.Address3
                : ""
            } ${
              data?.admin_supplier?.Address4
                ? data?.admin_supplier?.Address4
                : ""
            } ${
              data?.admin_supplier?.Address5
                ? data?.admin_supplier?.Address5
                : ""
            }`}
            {data?.admin_supplier?.city?.CityName +
              ", " +
              data?.admin_supplier?.state?.StateName +
              ", " +
              data?.admin_supplier?.country?.CountryName}
          </p>
        </div>
        <div className="mt-3">
          <h4 className="fw-bold text-center" style={{ color: "#247395" }}>
            {/* Proforma Invoice */}
            {method === "commercial"
              ? "Commercial Invoice"
              : "Proforma Invoice"}
          </h4>
        </div>
        <div className="my-3 py-2 d-md-flex gap-2">
          <Col lg={10} md={11} sm={12} className="d-flex m-auto">
            {/* <Col xs={4} className="p-2 bor-right"> */}
            <Col xs={4} className="p-2">
              <label className="font-blue fs-14 fw-bold mt-5">
                {data?.admin_supplier?.SupplierName}
              </label>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">Customer Code: </span>
                <span className="t3">{data?.admin_supplier?.SupplierCode}</span>
              </p>
              <p className="text-wrap">
                {`${
                  data?.admin_supplier?.Address1
                    ? data?.admin_supplier?.Address1
                    : ""
                }
               ${
                 data?.admin_supplier?.Address2
                   ? data?.admin_supplier?.Address2
                   : ""
               }
               ${
                 data?.admin_supplier?.Address3
                   ? data?.admin_supplier?.Address3
                   : ""
               }
                ${
                  data?.admin_supplier?.Address4
                    ? data?.admin_supplier?.Address4
                    : ""
                }
                ${
                  data?.admin_supplier?.Address5
                    ? data?.admin_supplier?.Address5
                    : ""
                }`}
                {data?.admin_supplier?.city?.CityName +
                  ", " +
                  data?.admin_supplier?.state?.StateName +
                  ", " +
                  data?.admin_supplier?.country?.CountryName}
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">PAN No: </span>
                <span className="t3">{data?.admin_supplier?.PanNo}</span>
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">GST: </span>
                <span className="t3">
                  {" "}
                  {data?.admin_supplier?.GSTNo
                    ? data?.admin_supplier?.GSTNo !== "null"
                      ? data?.admin_supplier?.GSTNo
                      : "-"
                    : "-"}
                </span>
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">State: </span>
                <span className="t3">
                  {data?.admin_supplier?.state?.StateName}
                </span>
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">State Code: </span>
                <span className="t3">
                  {data?.admin_supplier?.state?.StateCode}
                </span>
              </p>
            </Col>
            <Col
              xs={4}
              className="py-2 d-flex justify-content-center flex-column ps-3 mb-5"
              // bor-right
            >
              <label className="font-blue fs-14 fw-bold">Contact Details</label>
              {/* <p className="fnt-fam-f2 ">
              <span className="opac_half">Customer Code: </span>
              <span className="t3">CH100701</span>
            </p>
            <p className="text-wrap">
              5/7, College Rd, Thousand Lights West, Thousand Lights, Chennai,
              Tamil Nadu 600056
            </p> */}
              <p className="fnt-fam-f2 ">
                <span className="opac_half">Email: </span>
                <span className="t3">{data?.admin_supplier?.Email}</span>
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">Mobile: </span>
                <span className="t3">
                  {data?.admin_supplier?.TelephoneCode &&
                    "+" + data?.admin_supplier?.TelephoneCode}
                  {data?.admin_supplier?.Telephone &&
                    " " + data?.admin_supplier?.Telephone}
                </span>
              </p>
              {/* <p className="fnt-fam-f2 ">
              <span className="opac_half">State: </span>
              <span className="t3">Tamilnadu</span>
            </p>
            <p className="fnt-fam-f2 ">
              <span className="opac_half">State Code: </span>
              <span className="t3">CF85H141</span>
            </p> */}
            </Col>
            <Col xs={4} className="py-2 ps-xl-5 ps-2">
              <p className="fnt-fam-f2  mt-5">
                <span className="opac_half">Proforma Invoice Ref. No: </span>
                <span className="t3">{data?.invoice?.invoice_ref_no}</span>
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">Invoice Date: </span>
                <span className="t3">
                  {moment(data?.invoice?.invoice_date).format("DD-MMM-YYYY")}
                </span>
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half me-2">PO Ref. No.: </span>
                {data?.po_details?.map((item, ind) => (
                  <span className="t3" key={ind}>
                    {item?.purchase_order?.MaterialPurchaseOrderNo ||
                      item?.purchase_order?.LeatherPurchaseOrderID}{" "}
                    ,{" "}
                  </span>
                ))}
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half me-2">DC No.: </span>
                {data?.dc_details?.map((item, ind) => (
                  <span className="t3" key={ind}>
                    {item?.dc_detail?.DCNNo} ,
                  </span>
                ))}
              </p>
              <p className="fnt-fam-f2 ">
                <span className="opac_half">Invoice Amount: </span>
                <span className="t3">
                  {data?.invoice?.invoice_amount
                    ? Number(data?.invoice?.invoice_amount)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : ""}{" "}
                  {/* {" " + data?.invoice?.currency?.CurrencyCode} */}
                  INR
                </span>
              </p>
            </Col>
          </Col>
        </div>
        <div className=" mb-2 table-sm invoice-table-cont">
          <table className="my-3 py-2 table grn-table-wid">
            <thead style={{ background: "#ededed" }}>
              <tr className="fnt-fam-f3 opac-85 text-center">
                <th className="text-nowrap">Sl.No.</th>
                <th className="text-nowrap">PO No.</th>
                <th className="text-nowrap">Supplier Mat Code</th>
                <th className="text-nowrap">KH SKU Code</th>
                <th className="text-nowrap">KH SKU Name</th>
                <th className="text-nowrap">HSN Code</th>
                <th className="pd_03r text-nowrap">UOM</th>
                <th className="pd_03r text-nowrap">Invoice Qty</th>
                <th className="pd_03r text-nowrap">Unit Rate</th>
                {+user?.supplier?.CountryID == 1 && <th>GST%</th>}
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="fs-6 opac-80">
              {data?.invoice_details?.map((item, ind) => {
                return (
                  <tr key={ind} className="text-center">
                    <td className="d-flex gap-1">
                      <label className="d-flex table-chk-cont"></label>0
                      {ind + 1}
                    </td>
                    <td>
                      {item?.full_order_detail?.purchase_order
                        ?.LeatherPurchaseOrderNo ||
                        item?.full_order_detail?.purchase_order
                          ?.MaterialPurchaseOrderNo}
                    </td>
                    <td>-</td>
                    <td>
                      {item?.full_order_detail?.sku?.MaterialSKUCode ||
                        item?.full_order_detail?.sku?.LeatherSKUCode}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.MaterialSKUName ||
                        item?.full_order_detail?.sku?.LeatherSKUName}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.material_header?.hsn
                        ?.HSNCode ||
                        item?.full_order_detail?.sku?.leather_header?.hsn
                          ?.HSNCode}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.material_header?.uom
                        ?.UomCode ||
                        item?.full_order_detail?.sku?.leather_header?.uom
                          ?.UomCode}
                    </td>
                    <td>{item?.qty ? Number(item?.qty).toFixed(2) : ""}</td>
                    <td>
                      {item?.full_order_detail?.Price
                        ? Number(item?.full_order_detail?.Price).toFixed(2)
                        : ""}
                    </td>
                    {+user?.supplier?.CountryID == 1 && (
                      <td>
                        {" "}
                        {item?.full_order_detail?.tax
                          ? item?.full_order_detail?.tax?.TaxPercentage
                          : "-"}
                      </td>
                    )}
                    <td>
                      {item?.qty * item?.full_order_detail?.Price
                        ? Number(+item?.qty * +item?.full_order_detail?.Price)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="my-2 ">
          <div className="cont-border d-flex">
            <div className="bor-right d-flex flex-column justify-content-end px-2 py-1 w-100">
              <h6 className="primary1">Grand Total In Words</h6>
              <h6>
                {data?.invoice?.invoice_amount
                  ? // data?.invoice?.currency?.CurrencyName +
                    "Indian Rupee" +
                    " - " +
                    toWords.convert(+data?.invoice?.invoice_amount, {
                      // currency: true,
                    })
                  : ""}
              </h6>
            </div>
            <div className="py-2 d-flex  ms-auto justify-content-end w-100">
              <div className="py-2  pe-2">
                <div className="d-flex">
                  {/* <div className="d-flex flex-column ms-auto me-lg-5 me-md-3 w-100">
                    <p className="mb-4"></p>
                    <label className="py-1 my-1 mx-2 fs-14px text-nowrap">
                      Addtional Charges
                    </label>
                    <label className="py-1 my-1 mx-2 fs-14px">Deduction</label>
                  </div> */}
                  <div className="d-flex flex-column invoce-2ndtable-header-sec-cont ">
                    <p className="fs-14px opac_half text-nowrap ">Sub Total</p>
                    {/* <p className="border-btm py-1 my-1 fs-14px">
                      Transparent Charges
                    </p>
                    <p className="border-btm text-nowrap py-1 my-1 fs-14px">
                      E.g(Transparent Charges)
                    </p> */}
                    <p className="py-1 my-1 fs-14px opac_half text-nowrap">
                      Invoiceable %
                    </p>
                    <p className="py-1 my-1 fs-14px opac_half text-nowrap">
                      Addtional Charges
                    </p>
                    <p className="py-1 my-1 fs-14px opac_half text-nowrap">
                      Deduction
                    </p>
                  </div>
                  <div className="d-flex flex-column w-100">
                    <p className="fs-14px text-end">
                      {Number(data?.invoice?.sub_total)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      {/* {" " + data?.invoice?.currency?.CurrencyCode} */}
                      {" " + "INR"}
                    </p>
                    <p className="fs-14px text-end">
                      {data?.invoice?.percentage
                        ? Number(data?.invoice?.percentage)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-"}
                    </p>
                    <p className="border-btm py-1 my-1 fs-14px text-end">
                      {data?.invoice?.additional_charges
                        ? Number(data?.invoice?.additional_charges)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-"}
                      {" " + "INR"}
                    </p>
                    <p className="border-btm py-1 my-1 fs-14px text-end">
                      {data?.invoice?.discount_amount
                        ? Number(data?.invoice?.discount_amount)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "-"}
                      {" " + "INR"}
                    </p>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-end gap-3 ms-auto pe-2 me-2 mt-3">
                  <h6 className="py-1 px-2 fw-bold">Grand Total</h6>
                  <h6 className="py-1 fw-bold">
                    {Number(data?.invoice?.invoice_amount)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    {" " + "INR"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {+data?.admin_supplier?.CountryID == 1 && (
            <div className="d-flex">
              <div className="py-2 d-flex w-100 m-0">
                <div className="d-flex quotation-nd-table-cont mx-2">
                  <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                    {taxHeader?.map((item, ind) => {
                      return (
                        <tr
                          className="fnt-fam-f3 opac_half table-bordered text-center"
                          style={{ borderWidth: "1px" }}
                        >
                          <th
                            className="text-nowrap"
                            style={{ padding: "5px" }}
                          >
                            {item?.name}
                          </th>
                          <td style={{ padding: "5px" }}>
                            <p className="opac-80">
                              {item?.value
                                ? Number(item?.value)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <thead className="fnt-fam-f3 opac_half table-bordered text-center">
                      <th>Tax Details</th>
                    </thead>
                    <tbody className="opac-80">
                      {+data?.invoice?.igst == 1 ? (
                        <tr>
                          <td className="fs-14px text-center">ISGT</td>
                        </tr>
                      ) : (
                        <>
                          {["SGST", "CGST"].map((item) => (
                            <tr>
                              <td className="fs-14px text-center">{item}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody> */}
                  </table>
                </div>
                <div className=" p-0 quo-scope-btm-tb-2nd-cont  w-100">
                  <div className="d-flex quotation-nd-table-cont">
                    <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                      <thead>
                        <tr className="fnt-fam-f3 opac_half table-bordered">
                          <th className="fs-14px text-center">GST %</th>
                          {/* <th className="fs-14px text-center">Tax Details</th> */}
                          <th className="fs-14px text-center">Taxable Value</th>
                          <th className="fs-14px text-center">Tax Value</th>
                        </tr>
                      </thead>
                      <tbody className="opac-80">
                        {list?.map((item, ind) => {
                          return (
                            <>
                              <tr key={ind}>
                                <td className="text-center">
                                  {Number.isFinite(+item?.gst_id)
                                    ? item?.gst_id
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {item?.value
                                    ? Number(item?.value)
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : ""}
                                </td>
                                <td className="text-center">
                                  {Number.isFinite(+item?.tax_value)
                                    ? Number(item?.tax_value)
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : "-"}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        {/* {data?.invoice?.igst == 1 ? ( */}
                      </tbody>
                    </table>
                  </div>
                  <table className="d-flex justify-content-end light-grey-bg py-2">
                    <tbody>
                      <tr>
                        <td className="d-flex gap-2 fw-bold px-2">
                          <span>Total Tax</span>{" "}
                          <span>
                            {/* {data?.invoice?.gst_amount} */}
                            {/* {data?.invoice
                              ? Number(data?.invoice?.tax_total_amount).toFixed(
                                  2
                                ) + " "
                              : ""} */}
                            {taxTotalValue
                              ? Number(taxTotalValue)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : "-"}
                            {taxTotalValue == "-"
                              ? "   "
                              : " " + data?.invoice?.currency?.CurrencyCode}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <label className="font-blue fs-14 fw-bold">Bank Details</label>
          {data?.accounts?.map((item, ind) => {
            return (
              <Col className="my-2 py-1" key={ind}>
                <div className="d-flex py-1">
                  <label style={{ width: "150px" }}>Beneficiary Name</label>
                  <p>
                    <span className="me-1 pe-1">:</span>
                    <span className="fw-bold">{item?.account_holder_name}</span>
                  </p>
                </div>
                <div className="d-flex py-1">
                  <label style={{ width: "150px" }}>Bank Name</label>
                  <p>
                    <span className="me-1 pe-1">:</span>
                    <span className="fw-bold">{item?.bank_name}</span>
                  </p>
                </div>
                <div className="d-flex py-1">
                  <label style={{ width: "150px" }}>Account No</label>
                  <p>
                    <span className="me-1 pe-1">:</span>
                    <span className="fw-bold">{item?.account_no}</span>
                  </p>
                </div>
                <div className="d-flex py-1">
                  <label style={{ width: "150px" }}>IFSC Code</label>
                  <p>
                    <span className="me-1 pe-1">:</span>
                    <span className="fw-bold">{item?.ifsc_code}</span>
                  </p>
                </div>
              </Col>
            );
          })}
        </div>

        <div>
          <label className="font-blue fs-14 fw-bold">Terms & Condition</label>

          <div className="d-flex py-1 gap-3">
            <label className="font-blue fs-14 fw-bold">Payment Due Date</label>
            <p>
              <span className="me-1 pe-1">:</span>
              <span className="fw-bold">
                {data?.invoice?.payment_due_date
                  ? moment(data?.invoice?.payment_due_date).format(
                      "DD-MMM-YYYY"
                    )
                  : "-"}
              </span>
            </p>
          </div>
          <p>{data?.invoice?.terms_conditions}</p>
        </div>
        <p className="border-btm my-1 py-1"></p>
        <div className="d-flex flex-column w-100 align-items-center">
          <p>For any enquiry, reach out via email at</p>
          <div>
            <p>
              <span className="fw-bold">accounts@dodia.com</span> of call on{" "}
              <span className="fw-bold">+91 9424166100</span> | Fax:{" "}
              <span className="fw-bold">+91 24170045</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinalInvoice;
