// @ts-nocheck
import Filter from "../assets/Icons/Normal Icons/Filter.svg";
import Search from "../assets/Icons/Normal Icons/Search.svg";

function GeneralFilter({ setShowFilterContent }) {
  const onClickFilterHandler = () => setShowFilterContent(true);
  return (
    <div
      className="d-sm-flex justify-content-between mb-2 align-items-center"
      style={{ minWidth: "80%" }}
    >
      <div className="d-flex-wrap align-items-center gap-2 mb-0">
        <label>Show</label>
        <select name="" className="cont-border px-2 py-1">
          <option value="5">6</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        <label>1-5 of 20</label>
      </div>
      <div className="d-flex mt-2 mt-sm-0">
        <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
          <input placeholder="Search Title" className="w-90 h-90" />
          <img className="search-icon" src={Search} />
        </div>
        <button
          className="filter-btn bg-primary2"
          onClick={onClickFilterHandler}
        >
          <img src={Filter} className="wid-15px me-1" />
          Filter
        </button>
      </div>
    </div>
  );
}

export default GeneralFilter;
