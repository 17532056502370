// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Form, Image, Button, Modal } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Logo,
  showPwdImg,
  hidePwdImg,
  FavLogo,
  Check,
  LoginVideo,
} from "../assets/img";
import LogInFooter from "../logincomponent/LogInFooter";
import LogInConnect from "../logincomponent/LogInConnect";
import useToken from "../constants/Storage/useToken";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
const SetPasswordUser = () => {
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const emailId = location?.state?.data?.user?.SPUD_email;
  const userName = location?.state?.data?.user?.SPUD_UserName;
  // console.log(location);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isConPwd, setIsConPwd] = useState(false);
  const [cpwd, setCpwd] = useState("");
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [pwd, setPwd] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);

  const [RegSuccessModal, setRegSuccessModal] = useState(false);

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const tokenData = searchParams?.get("token");

  const tokenType = searchParams?.get("type");

  // console.log(tokenType);
  // console.log(tokenData);

  const tokenVerificate = () => {
    api.tokenVerify(tokenData).then((res) => {
      if (res?.status == "success") {
        setList(res?.supplier);
        setList2(res?.sup_user);
        setName(res?.supplier?.supplier_name);
        setName2(res?.sup_user?.user_name);
        setError(res);
        setErrorBox(true);
      } else {
        navigate("/register");
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  // console.log(list);

  const acceptReject = () => {
    let data = {
      user_name: userName,
      status: 1,
    };
    api.accept_reject(data).then((res) => {
      console.log(res);
    });
  };

  useEffect(() => {
    if (tokenData) {
      tokenVerificate();
    }
  }, []);

  const submitHandle = () => {
    // console.log("poiss");
    if (name?.length == 0 || pwd?.length < 8) {
      setNameErr(true);
      setPassErr(true);
      // console.log("rtyu");
    } else if (cpwd?.length < 8) {
      setConfirmPassErr(true);
      // console.log("rtysu");
    } else {
      // console.log("p0o");

      let data = {
        user_name: name || name2,
        password: pwd,
        password_confirmation: cpwd,
        token: tokenData,
      };

      api.setPass(data).then((res) => {
        if (res?.status == "success") {
          // acceptReject();
          setError(res);
          setErrorBox(true);
          setRegSuccessModal(true);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  return (
    <div className="login-bg">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <div className="video-bg-setpass">
        <video autoPlay loop muted className="">
          <source src={LoginVideo} type="video/mp4" />
        </video>
        <div className="d-flex-al-jc log_cent">
          <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
            <div className="form_box">
              <Image src={Logo} />
              <h6>Welcome to KH SCM</h6>
              <Col xs={12} className="my-2 position-relative pwd-container">
                <input
                  className="form-control bg-transparent"
                  type="name"
                  placeholder="User Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name || name2}
                />
                {name?.length == 0 && nameErr && (
                  <p className="err-input pe-0 mb-0">Enter the Username</p>
                )}
              </Col>
              <Col
                xs={12}
                className="bg-transparent my-2 mt-3 position-relative pwd-container"
              >
                <input
                  name="pwd"
                  className="form-control"
                  placeholder="Enter Password"
                  type={isRevealPwd ? "text" : "password"}
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
                <Image
                  type={isRevealPwd ? "text" : "password"}
                  src={isRevealPwd ? showPwdImg : hidePwdImg}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
                {pwd?.length < 8 && passErr && (
                  <p className="err-input pe-0 mb-0">Enter 8 digit Password</p>
                )}
              </Col>
              <Col
                xs={12}
                className="bg-transparent my-2 mt-3 position-relative pwd-container"
              >
                <input
                  name="pwd"
                  className="form-control"
                  placeholder="Confirm Password"
                  type={isConPwd ? "text" : "password"}
                  value={cpwd}
                  onChange={(e) => setCpwd(e.target.value)}
                />
                <Image
                  title={isConPwd ? "Hide password" : "Show password"}
                  src={isConPwd ? showPwdImg : hidePwdImg}
                  onClick={() => setIsConPwd((prevState) => !prevState)}
                />
                {cpwd?.length == 0 && confirmPassErr && (
                  <p className="err-input pe-0 mb-0">Enter Your Password</p>
                )}
                {pwd !== cpwd && cpwd?.length !== 0 && (
                  <p className="err-input pe-0 mb-0">Password mismatch</p>
                )}
              </Col>
              <button
                style={{ background: "#247395" }}
                onClick={submitHandle}
                className="bt-tx"
              >
                Set Password
              </button>
              <LogInConnect />
            </div>
          </Col>
          <div className="mt-3  ">
            <Modal
              id="#sucess"
              className="otp-modal"
              show={RegSuccessModal}
              size="md"
              centered
              onHide={() => setRegSuccessModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="sup-modal ">
                <Image className="fav-icon" src={FavLogo} />
                <div className="mt-0">
                  <div className="d-flex-as-jc img_tick ">
                    <Image src={Check} />
                    <div className="px-3">
                      <h5>Registration Successfully</h5>
                      <p>You can now login to your KH account</p>
                    </div>
                  </div>
                  <Link
                    className="my-3 cust-btn mx-auto d-flex bg-primary1 text-white py-2 text-center"
                    style={{ width: "63px" }}
                    to={"/logIn"}
                  >
                    Login
                  </Link>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <LogInFooter />
    </div>
  );
};
export default SetPasswordUser;
