function TermsAndCondition() {
  const data = [
    {
      terms:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
    },
    {
      terms:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
    },
    {
      terms:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
    },
    {
      terms:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
    },
    {
      terms:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
    },
  ];

  const renderedList = data.map((item, ind) => {
    return (
      <li key={ind} className="d-flex gap-2 my-1 py-1">
        <span className="d-flex fnt-fam-f3">{ind + 1}.</span> {item.terms}
      </li>
    );
  });
  return (
    <div className="pt-3 px-1">
      <h5 className="font-col-sec my-2 py-1">Terms & Conditions</h5>
      <ul className="my-3">{renderedList}</ul>
    </div>
  );
}

export default TermsAndCondition;
