import Admin_Broadcast_list_Button_component from "../../../component/Admin/AdminBroadCast/Ad_Broadcast_list_Btn_com";
import Admin_Broadcast_list_Table_component from "../../../component/Admin/AdminBroadCast/Ad_Broadcast_table_com";
import GeneralShortPagination from "../../../component/GeneralShortPagination";
import React from "react";
import TopHeader from "../../../navbar/TopHeader";
import TopBar from "../../../navbar/TopBar";
import LogInFooter from "../../../logincomponent/LogInFooter";

function Ad_Broadcast_List() {
  return (
    <>
      <TopBar />
      <TopHeader type="broadcast" />
      <div style={{ minHeight: "554px" }} className="m-3 p-3">
        <Admin_Broadcast_list_Button_component />
        <Admin_Broadcast_list_Table_component />
        <GeneralShortPagination />
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default Ad_Broadcast_List;
