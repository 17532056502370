import React, { useState, useEffect } from "react";
import { RiDeleteBack2Line } from "react-icons/ri";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RxTriangleUp } from "react-icons/rx";
import { HiDocumentSearch } from "react-icons/hi";
import ViewDetails from "../../assets/Icons/Normal Icons/ViewDetails.svg";
import { Col } from "react-bootstrap";
import useUser from "../../constants/Storage/userData";
function InvoiceTableContent({
  dcGetDetails,
  invTableSelectHandler,
  checkData,
  handleCheckAllChange,
  tableCheck,
  onNoNameChange,
  noName,
  setNoNames,
  noNames,
  type,
  tableTwo,
  setPer_centageFlag,
  per_centageFlag,
}) {
  // console.log("dcGetDetails", dcGetDetails);
  // console.log("noName", noName);
  // console.log("tableTwo", tableTwo);
  const { user } = useUser();
  const [tableDetails, setTableDetails] = useState([]);
  const [showModel, setShowModel] = useState(null);

  const modelHideHandler = () => setShowModel(null);
  const modelShowHandler = (sel_id) => {
    setShowModel(+sel_id);
  };

  useEffect(() => {
    let temp = [];
    // dcGetDetails?.map((item) => item?.map((item_1) => temp.push(item_1)));
    setTableDetails(dcGetDetails);
  }, []);
  return (
    <div className="mid-sc-res">
      <div
        style={{ width: "inherit" }}
        className="table-scroll-horizontal over-flow-x-scroll mb-2 table-sm invoice-table-cont "
      >
        <table className="my-3 py-2 table grn-table-wid">
          <thead style={{ background: "#ededed" }}>
            <tr className="fnt-fam-f3 opac-85 text-center">
              <th className="">
                <div className="d-flex">
                  {" "}
                  <label className="d-flex table-chk-cont">
                    <input
                      type="checkbox"
                      className="me-2 "
                      onChange={handleCheckAllChange}
                      checked={tableCheck?.length === dcGetDetails?.length}
                    />
                  </label>
                  Sl.No.
                </div>
              </th>
              <th className="text-nowrap">PO No.</th>
              <th className="text-nowrap">Del Date</th>
              <th className="text-nowrap">Supplier Mat. Code</th>
              <th className="text-nowrap">KH SKU CODE</th>
              <th className="text-nowrap">KH SKU Name</th>
              <th className="text-nowrap">HSN Code</th>
              <th className="pd_03r text-nowrap">
                UOM
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                PO Qty
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                Invoiced Qty
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                Current Invoice Qty
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              <th className="pd_03r text-nowrap">
                Unit Rate
                {/* <RiArrowDropDownLine className="fs-3" /> */}
              </th>
              {+user?.supplier?.CountryID == 1 && <th>GST%</th>}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody className="fs-6 opac-80">
            {dcGetDetails &&
              dcGetDetails?.map((item, ind) => {
                return (
                  <tr key={ind} className="text-center">
                    <td>
                      <div className="d-flex gap-1">
                        {/* {noNames ? (
                          <>
                           
                          </>
                        ) : type !== "view" && type !== "edit" ? (
                          <input
                            type="checkbox"
                            className="me-2 my-1"
                            disabled
                          />
                        ) : null} */}
                        <label className="d-flex table-chk-cont">
                          <input
                            type="checkbox"
                            className="me-2 my-1"
                            onClick={() => {
                              invTableSelectHandler(ind);
                              checkData(item, ind);
                            }}
                            checked={tableCheck?.includes(ind)}
                          />
                        </label>
                        0{ind + 1}
                      </div>
                    </td>
                    <td>
                      {item?.full_order_detail?.purchase_order
                        ?.MaterialPurchaseOrderNo ||
                        item?.full_order_detail?.purchase_order
                          ?.LeatherPurchaseOrderNo}
                    </td>
                    <td maxLength={10} style={{ textWrap: "nowrap" }}>
                      {item?.detail_delivery_details?.DeliveryDate.substring(
                        0,
                        10
                      ) ||
                        item?.full_order_detail?.purchase_order
                          ?.LeatherPurchaseOrderNo}
                    </td>
                    <td> - </td>
                    <td>
                      {item?.full_order_detail?.sku?.MaterialSKUCode ||
                        item?.full_order_detail?.sku?.LeatherSKUCode}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.MaterialSKUName ||
                        item?.full_order_detail?.sku?.LeatherSKUName}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.material_header?.hsn
                        ?.HSNCode ||
                        item?.full_order_detail?.sku?.leather_header?.hsn
                          ?.HSNCode}
                    </td>
                    <td>
                      {item?.full_order_detail?.sku?.material_header?.uom
                        ?.UomCode ||
                        item?.full_order_detail?.sku?.leather_header?.uom
                          ?.UomCode}
                    </td>
                    <td>
                      {item?.detail_delivery_details
                        ? Number(
                            item?.detail_delivery_details?.Quantity
                          ).toFixed(2)
                        : Number(item?.delivery_detail?.Quantity).toFixed(2)}
                    </td>
                    <td>
                      {item?.invoice_quantity == 0
                        ? 0
                        : item?.invoice_quantity
                        ? Number(item?.invoice_quantity).toFixed(2)
                        : Number(item?.qty).toFixed(2)}
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          noName[
                            item?.full_order_detail
                              ?.MaterialPurchaseOrderDetailID ||
                              item?.full_order_detail
                                ?.LeatherPurchaseOrderDetailID
                          ]
                        }
                        onChange={(e) =>
                          onNoNameChange(
                            e,
                            item?.full_order_detail
                              ?.MaterialPurchaseOrderDetailID ||
                              item?.full_order_detail
                                ?.LeatherPurchaseOrderDetailID,
                            +item?.detail_delivery_details?.ReceivedQuantity
                          )
                        }
                        disabled={!tableTwo?.includes(item)}
                      />
                      {/* {Number(
                        item?.detail_delivery_details?.ReceivedQuantity ||
                          item?.delivery_detail?.ReceivedQuantity
                      ).toFixed(2)} */}
                    </td>
                    <td>{Number(item?.full_order_detail?.Price).toFixed(2)}</td>
                    {+user?.supplier?.CountryID == 1 && (
                      <td>
                        {" "}
                        {item?.full_order_detail?.tax?.TaxPercentage ?? "-"}
                      </td>
                    )}
                    <td>
                      {/* {item?.detail_delivery_details?.ReceivedQuantity
                        ? Number(
                            item?.full_order_detail?.Price *
                              item?.detail_delivery_details?.ReceivedQuantity
                          ).toFixed(2)
                        : item?.delivery_detail?.ReceivedQuantity
                        ? Number(
                            item?.full_order_detail?.Price *
                              item?.delivery_detail?.ReceivedQuantity
                          ).toFixed(2)
                        : "-"} */}
                      {Number(
                        noName[
                          item?.full_order_detail
                            ?.MaterialPurchaseOrderDetailID ||
                            item?.full_order_detail
                              ?.LeatherPurchaseOrderDetailID
                        ] *
                          +item?.full_order_detail?.Price *
                          +item?.full_order_detail?.purchase_order?.ExRate
                      ).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvoiceTableContent;
