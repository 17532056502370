import React, { useState } from "react";
import { Col, Modal } from "react-bootstrap";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import Action_Icon from "../../../assets/Icons/Normal Icons/Ellipse.svg";
import { useNavigate } from "react-router";

function AdminSettings() {
  const navigate = useNavigate();
  const [addCountryModal, setAddCountryModal] = useState(false);
  const [actionDropModal, setactionDropModal] = useState("");

  const addCountryClickHandler = () => setAddCountryModal(true);
  const adModalCloseHandler = () => setAddCountryModal(false);
  const actionDropModalHandler = (ind) => {
    if (actionDropModal !== ind) {
      setactionDropModal(ind);
    } else {
      setactionDropModal("");
    }
  };

  const dummy_data = [
    {
      country: "USA",
      state: "New York",
      city: "New York",
      status: "Active",
    },
    {
      country: "India",
      state: "Tamil Nadu",
      city: "Madurai",
      status: "Deactive",
    },
    {
      country: "Australia",
      state: "Sydney",
      city: "Sydney",
      status: "Active",
    },
    {
      country: "Russia",
      state: "Pettersburg",
      city: "Pettersburg",
      status: "Deactive",
    },
    {
      country: "Australia",
      state: "England",
      city: "London",
      status: "Active",
    },
  ];
  return (
    <>
      <TopBar />
      <TopHeader type="admin-settings" />
      <div className="w-90 px-2 py-3 my-2">
        <div className="d-flex justify-content-end my-2">
          <button
            style={{ width: "96px", height: "38px" }}
            className="border-0 fs-14px fnt-fam-f2 bg-primary1 text-white"
            onClick={addCountryClickHandler}
          >
            Add Country
          </button>
        </div>
        <Col className="prduct-page">
          <div className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll">
            <table className="w-100" style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th className="text-center fs-15px">S.No</th>
                  <th className="text-center fs-15px">Country</th>
                  <th className="text-center fs-15px">State</th>
                  <th className="text-center fs-15px">City</th>
                  <th className="text-center fs-15px">Status</th>
                  <th className="text-center fs-15px">Action</th>
                </tr>
              </thead>
              <tbody>
                {dummy_data?.map((item, ind) => (
                  <>
                    <tr key={ind} className="shipment-table">
                      <td className="text-center fs-14px">{ind + 1}</td>
                      <td className="text-center fs-14px">{item?.country}</td>
                      <td className="text-center fs-14px">{item?.state}</td>
                      <td className="text-center fs-14px">{item?.city}</td>
                      <td className="text-center ">
                        <button
                          type="button"
                          style={{ width: "69px" }}
                          className={
                            item.status === "Active"
                              ? `btn btn-outline-success po-card-btn fs-14px`
                              : `btn btn-outline-danger po-card-btn fs-14px`
                          }
                        >
                          {item.status}
                        </button>
                      </td>
                      <td className="text-center position-relative">
                        <button
                          className="border-0"
                          onClick={() => actionDropModalHandler(ind)}
                        >
                          <img src={Action_Icon} style={{ width: "3px" }} />
                        </button>
                        {actionDropModal === ind && (
                          <div className="ad-set-tb-dropdown rgt-32px pointer position-absolute">
                            <p className="text-wht fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont">
                              Edit
                            </p>
                            <p className="text-wht fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont">
                              Delete
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </div>
      <Modal
        id="ad-set-modal-cont-id"
        className="otp-modal ad-set-modal-cont"
        show={addCountryModal}
        size="md"
        centered
        onHide={() => setAddCountryModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="ad-set-modal">
          <div className="my-1 py-1">
            <label className="t3 fnt-fam-f2 fs-15px">
              Country<span className="red-text">*</span>
            </label>
            : <input className="ms-1" type="text" />
          </div>
          <div className="my-1 py-1">
            <label className="t3 fnt-fam-f2 fs-15px">
              State<span className="red-text">*</span>
            </label>
            : <input className="ms-1" type="text" />
          </div>
          <div className="my-1 py-1">
            <label className="t3 fnt-fam-f2 fs-15px">
              City<span className="red-text">*</span>
            </label>
            : <input className="ms-1" type="text" />
          </div>
          <div className="d-sm-flex justify-content-end my-2 gap-2 ">
            <button className="border-0 bg-light" onClick={adModalCloseHandler}>
              Cancel
            </button>
            <button
              className="border-0 bg-primary1 text-white"
              onClick={adModalCloseHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AdminSettings;
