import React, { useState } from "react";
import { Col } from "react-bootstrap";
import LeftAddUser from "../../component/EnterpriseAdmin/LeftAddUser";
import RightAddUser from "../../component/EnterpriseAdmin/RightAddUser";
import RightAddUserCopys from "../../component/EnterpriseAdmin/RightAddUserCopy ";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";

const EnterPriseAddUser = () => {
  const [upload, setUpoad] = useState();
  return (
    <Col>
      <TopBar />
      {/* <TopHeader type="e-admin-user" /> */}
      <TopHeader type="enterprise-admin-user" />
      <Col className="enterprise-add-user d-lg-flex flex-wrap min-ht-80">
        <LeftAddUser setUpoad={setUpoad} upload={upload} />
        {/* <RightAddUser /> */}
        <RightAddUserCopys upload={upload} setUpoad={setUpoad} />
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </Col>
  );
};

export default EnterPriseAddUser;
