// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ButtonGroup, Col, Form, Image, Modal, Table } from "react-bootstrap";
// import DcTables from "../../../component/DC/DcTables";
import DcTable from "./DcTables";
import DcTop from "../../../component/DC/DcTop";
import OverallDelivery from "../../../component/DC/OverallDelivery";
import PackageDetails from "../../../component/DC/PackageDetails";
import PoTable from "../../../component/DC/PoTables";
import RightDc from "../../../component/DC/RightDc";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { TiDeleteOutline } from "react-icons/ti";
import {
  IoIosAddCircleOutline,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import { Check, FavLogo } from "../../../assets/img";
import ViewDetails from "../../../assets/Icons/Normal Icons/ViewDetails.svg";
import DcilterSec from "../../../component/DC/DcilterSec";
import { json, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../constants/Services";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import moment from "moment/moment";
import useUser from "../../../constants/Storage/userData";
import DatePicker from "../../../layout/datePicker";

const DeliveryChallanCreationNew = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data; // single create
  const checkbox = location?.state?.checkboxs; // multiple create
  const dcData = location?.state?.dcData;
  const types = location?.state?.type;
  const TransportedBy = location?.state?.TransportedBy;
  const [showRightDc, setShowRightDc] = useState(false);
  const [btn, setBtn] = useState(false);
  const [deliveryBtn, setBtnDelivery] = useState(false);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPO, setSelectedPO] = useState([]);
  const [selectedASN, setSelectedASN] = useState([]);
  const [asnTableData, setAsnTableData] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [checkboxs, setcheckboxs] = useState([]);
  const [checkboxAsn, setcheckAsn] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [listDelivery, setListDelivery] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [dcUomList, setDcUomList] = useState([]);
  const [capModal, setCapModal] = useState(false);
  const [errorQyt, setErrorQty] = useState({});
  const [dimension, setDimension] = useState([
    {
      width: "",
      length: "",
      height: "",
      packages: "",
      grossWeight: "",
      netWeight: "",
      unit_1: "",
      unit_2: "",
      volume: "",
      total: "",
    },
  ]);

  // SELECT BOX

  const [options, setOptions] = useState([]);
  const [optionChange, setOptionChange] = useState([]);
  const [optionChanges, setOptionChanges] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [optionChange2, setOptionChange2] = useState([]);
  const [options2s, setOptions2s] = useState([]);
  const [optionChange2s, setOptionChange2s] = useState([]);
  const [optionPo, setOptionPo] = useState([]);

  const [poId, setPoId] = useState([]);
  const [option1, setOption1] = useState([]);
  const [option2, setOption2] = useState({});
  const [option3, setOption3] = useState([]);
  const [option4, setOption4] = useState([]);
  const [option5, setOption5] = useState([]);

  const [optionData1, setOptionData1] = useState([]);
  const [optionData2, setOptionData2] = useState({});
  const [optionData3, setOptionData3] = useState({});
  const [optionData4, setOptionData4] = useState([]);
  const [optionData5, setOptionData5] = useState([]);

  // STATES

  const [dcDate, setDcDate] = useState(new Date());
  const [dcNo, setDcNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceNo, setInvoiceNo] = useState("");
  const [exWorks, setExWorks] = useState(new Date());
  const [dcInvoice, setDcInvoice] = useState("");
  const [pacType, setPacType] = useState("");
  const [widths, setWidths] = useState("");
  const [Legnths, setLegnths] = useState("");
  const [Heights, setHeights] = useState("");
  const [noOfBox, setNoOfBox] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [netWeight, setNetWeight] = useState("");
  const [deleveryTermId, setDeleveryTermId] = useState("");
  const [etd, setEtd] = useState(new Date());
  const [eta, setEta] = useState(new Date());
  const [shipmentId, setShipmetId] = useState("");
  const [transportBy, setTransportBy] = useState("kh");
  const [asnCreates, setAsnCrate] = useState("");
  const [asnNoNew, setAsnNoNew] = useState("");
  const [totalVolume, setTotalVolume] = useState("");
  const [totalVolumeUnit, setTotalVolumeUnit] = useState("");

  const [dcDateErr, setDcDateErr] = useState(false);
  const [totalVolumeErr, setTotalVolumeErr] = useState(false);
  const [asnNoNewErr, setAsnNoNewErr] = useState(false);
  const [dcNoErr, setDcNoErr] = useState(false);
  const [invoiceDateErr, setInvoiceDateErr] = useState(false);
  const [invoiceNoErr, setInvoiceNoErr] = useState(false);
  const [exWorksErr, setExWorksErr] = useState(false);
  const [dcInvoiceErr, setDcInvoiceErr] = useState(false);
  const [widthsErr, setWidthsErr] = useState(false);
  const [LegnthsErr, setLegnthsErr] = useState(false);
  const [HeightsErr, setHeightsErr] = useState(false);
  const [noOfBoxErr, setNoOfBoxErr] = useState(false);
  const [grossWeightErr, setGrossWeightErr] = useState(false);
  const [netWeightErr, setNetWeightErr] = useState(false);
  const [deleveryTermIdErr, setDeleveryTermIdErr] = useState(false);
  const [etdErr, setEtdErr] = useState(false);
  const [etaErr, setEtaErr] = useState(false);
  const [shipmentIdErr, setShipmetIdErr] = useState(false);
  const [transportByErr, setTransportByErr] = useState(false);
  const [asnCreatesErr, setAsnCrateErr] = useState(false);
  const [materialAs, setMaterialAs] = useState("");

  //  GET LISTS
  const [poList, setPoList] = useState([]);
  const [poLists, setPoLists] = useState([]);
  const [asnList, setasnList] = useState([]);
  const [asnLists, setasnLists] = useState([]);
  const [asnType, setAsnType] = useState({});
  const [asnTable, setAsnTable] = useState([]);
  const [shipments, setShipments] = useState([]);

  const [noName, setNoName] = useState({});
  const [noNames, setNoNames] = useState({});
  const [showRight, setShowRight] = useState(false);
  const [stageShow, setStageShow] = useState(false);
  const [edit, setEdit] = useState("");
  const [changeTable, setChangeTable] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState({});
  const [isCheck, setIsCheck] = useState({});
  const [isChecks, setIsChecks] = useState({});
  // console.log("checkbox", checkbox);
  // console.log("data", dcData);

  // console.log("location?.state", location?.state);

  // DELIVERY POPUP

  const [typeDel, setTypeDel] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [add3, setAdd3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [mailId, setMailId] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [ext, setExt] = useState("");

  const [typeDelErr, setTypesDelErr] = useState("");
  const [add1Err, setAdd1Err] = useState("");
  const [add2Err, setAdd2Err] = useState("");
  const [add3Err, setAdd3Err] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [mailIdErr, setMailIdErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [faxErr, setFaxErr] = useState("");
  const [extErr, setExtErr] = useState("");
  const [unit, setUnit] = useState(0);

  const showRightHandler = () => {
    setShowRight(!showRight);
    setShowRightDc(true);
  };

  // console.log("dcData", dcData);
  // DRAFT VIEW METHOD
  const dcDraftViewList = () => {
    let res = dcData?.draft;

    if (res?.TransportedBy == "sp") {
      setShowRightDc(true);
      setStageShow(true);
    }

    let option1_data = JSON.parse(res?.option1);
    let option2_data = JSON.parse(res?.option2);
    // let option3_data = JSON.parse(res?.option3);
    // setOption3([option3_data]);
    let optionChange2_new = JSON.parse(res?.optionChange2);
    let optionChange2s_new = JSON.parse(res?.optionChange2s);
    let optionData1_new = JSON.parse(res?.optionData1);
    let optionData2_new = JSON.parse(res?.optionData2);
    let options2_new = JSON.parse(res?.options2);
    setOptionChange2(optionChange2_new);
    setOptionChange2s(optionChange2s_new);
    setOptionData1(optionData1_new);
    setOptionData2(optionData2_new);
    setOption2(options2_new);
    setOption1(option1_data);
    setOption2(option2_data);

    let visiableArr = [];
    for (let i = 0; i < Object.values(optionData2_new).length; i++) {
      visiableArr.push(i);
    }

    let noName_new = JSON.parse(res?.order_details_noName);
    let order_details_data = JSON.parse(res?.order_details_data);
    setAsnTableData(Object.values(order_details_data));
    setNoName(noName_new);
    if (order_details_data) {
      Object.values(order_details_data).map((item, ind) => {
        setIsCheckAll((val) => ({ ...val, [ind]: true }));
      });
    }
    setIsCheck(order_details_data);
    setIsChecks(order_details_data);
    setVisiable(true);
    setVisiablePo(visiableArr);

    let dimension_temp = [];

    res?.pkg_details?.map((dimensions, ind_dim) => {
      let obj = {};
      let split = dimensions?.dimension?.split("*");
      obj.width = split[0];
      obj.length = split[1];
      obj.height = split[2];
      obj.packages = dimensions?.PackageCount;
      obj.grossWeight = dimensions?.GrossWeight;
      obj.netWeight = dimensions?.NetValue;

      dimension_temp.push(obj);
    });

    setDimension(dimension_temp);

    if (res?.dc_date) {
      setDcDate(new Date(res?.dc_date));
    }

    if (res.invoice_date) {
      setInvoiceDate(new Date(res.invoice_date));
    }

    if (res?.ex_factory_date) {
      setExWorks(new Date(res?.ex_factory_date));
    }

    setInvoiceNo(res?.invoice_no);
    setDcNo(res?.dc_no);
    setNoOfBox(res?.no_of_boxes);
    setNetWeight(res?.net_weight);
    setGrossWeight(res?.gross_weight);
    setTransportBy(res?.transport_by);
    setTotalVolume(res?.size_in_cbm);
    setDeleveryTermId(res?.delivery_term_id);
    setShipmetId(res?.shipment_mode_id);
  };

  // console.log("isCheckAll", isCheckAll);
  // console.log("isCheck", isCheck);
  // console.log("isChecks", isChecks);

  // VIEW METHOD
  const dcViewList = () => {
    let params = `?factory_id=${dcData?.FactoryID}&asn_supplier_id=${
      dcData?.LeatherASNSupplierID || dcData?.MaterialASNSupplierID
    }&material_type=${dcData?.MaterialType}`;

    let temp_1 = [...asnTableData];

    let po = [];
    let shipmentOptions = [];

    api.dcView(params).then((res) => {
      if (res?.status == "success") {
        setMaterialAs(res?.asn_supplier_detail_row?.[0]?.MaterialASNNo);
        res?.asn_supplier_detail_row?.map((item, i) => {
          console.log("DeliveryTermsID", item?.DeliveryTermsID);
          if (item?.TransportedBy == "sp") {
            setShowRightDc(true);
            setStageShow(true);
          }
          if (item?.material_a_s_n_supplier_details) {
            temp_1.push(item?.material_a_s_n_supplier_details);
          } else {
            temp_1.push(item?.leather_a_s_n_supplier_details);
          }

          // for material
          item?.material_a_s_n_supplier_details?.map((item_1, ind) => {
            setNoNames((pre) => ({
              ...pre,
              [+item_1?.MaterialPurchaseOrderDetailDelDateID]: item_1?.Quantity,
            }));

            po.push({
              value: {
                purchase_order_id:
                  item_1?.purchase_order_detail_del_date
                    ?.material_purchase_order_detail?.MaterialPurchaseOrderID,
                type: item?.material_a_s_n_supplier_details ? "M" : "L",
                FactoryName: item?.factory?.FactoryName,
              },
              label: `${item?.material_a_s_n_supplier_details ? "M" : "L"} : ${
                item_1?.purchase_order_detail_del_date
                  ?.material_purchase_order_detail?.purchase_order
                  ?.MaterialPurchaseOrderNo
              }`,
            });

            shipmentOptions.push({
              value: {
                PurchaseOrderDetailDelDateID:
                  item_1?.purchase_order_detail_del_date
                    ?.MaterialPurchaseOrderDetailDelDateID,
                type: item?.material_a_s_n_supplier_details ? "M" : "L",
                FactoryID: item?.factory?.FactoryID,
              },
              label: `${moment(
                item_1?.purchase_order_detail_del_date?.DeliveryDate
              ).format("DD-MMM-YYYY")} : ${
                item?.shipment_detail?.ReferenceDetailsName
                  ? item?.shipment_detail?.ReferenceDetailsName
                  : ""
              }`,
            });
          });

          // for leather
          item?.leather_a_s_n_supplier_details?.map((item_1, ind) => {
            setNoNames((pre) => ({
              ...pre,
              [+item_1?.LeatherPurchaseOrderDetailDelDateID]: Math.round(
                item_1?.Quantity
              ),
            }));

            po.push({
              value: {
                purchase_order_id:
                  item_1?.purchase_order_detail_del_date
                    ?.leather_purchase_order_detail?.LeatherPurchaseOrderID,
                type: item?.leather_a_s_n_supplier_details ? "L" : "M",
                FactoryName: item?.factory?.FactoryName,
              },
              label: `${item?.leather_a_s_n_supplier_details ? "L" : "M"} : ${
                item_1?.purchase_order_detail_del_date
                  ?.leather_purchase_order_detail?.purchase_order
                  ?.LeatherPurchaseOrderNo
              }`,
            });
            console.log(po, "po");
            shipmentOptions.push({
              value: {
                PurchaseOrderDetailDelDateID:
                  item_1?.purchase_order_detail_del_date
                    ?.LeatherPurchaseOrderDetailDelDateID,
                type: item?.leather_a_s_n_supplier_details ? "L" : "M",
                FactoryID: item?.factory?.FactoryID,
              },
              label: `${moment(
                item_1?.leather_order_detail_del_date?.DeliveryDate
              ).format("DD-MMM-YYYY")} : ${
                item?.shipment_detail?.ReferenceDetailsName
                  ? item?.shipment_detail?.ReferenceDetailsName
                  : ""
              }`,
            });
          });

          setOptionChange(po);
          setOptionData1(po);
          setOptionChange2(shipmentOptions);
          let dimensions = item?.Dimension?.split("*");
          if (dimensions) {
            setWidths(dimensions[0]);
            setLegnths(dimensions[1]);
            setHeights(dimensions[2]);
            let temp = [
              {
                width: dimensions[0],
                length: dimensions[1],
                height: dimensions[2],
              },
            ];
            setDimension(temp);
          }

          let dimension_temp = [];

          item?.package_detail?.map((dimensions, ind_dim) => {
            let obj = {};

            let split = dimensions?.PackageDimension?.split("*");

            obj.width = split[0];
            obj.length = split[1];
            obj.height = split[2];
            obj.packages = dimensions?.PackageCount;
            obj.grossWeight = dimensions?.GrossWeight;
            obj.netWeight = dimensions?.NetValue;
            obj.unit_1 = dimensions?.PackageVolumeUomID;
            obj.unit_3 = dimensions?.GrossWeightUomID;
            obj.unit_2 = dimensions?.PackageWeightUomID;
            obj.volume = Math.round(+dimensions?.PackageVolume);
            obj.total =
              +dimensions?.PackageCount *
              Math.round(+dimensions?.PackageVolume);

            dimension_temp.push(obj);
          });

          setDimension(dimension_temp);

          setDcDate(new Date(item?.DCDate));
          setInvoiceDate(new Date(item?.InvoiceDate));
          setInvoiceNo(item?.InvoiceNo);
          setExWorks(new Date(item?.ExFactoryDate));
          setNoOfBox(item?.NoOfBox);
          setNetWeight(item?.NetWeight);
          setGrossWeight(item?.GrossWeight);
          setTransportBy(item?.TransportedBy);
          setTotalVolume(item?.SizeINCBM);
          setDeleveryTermId(item?.DeliveryTermsID);
          setShipmetId(item?.ShipmentModeID);

          if (item?.material_a_s_n_forwarder) {
            setEta(new Date(item?.material_a_s_n_forwarder?.ActualETA));

            setEtd(new Date(item?.material_a_s_n_forwarder?.ActualETD));
          }
          if (item?.leather_a_s_n_forwarder) {
            setEta(new Date(item?.leather_a_s_n_forwarder?.ActualETA));

            setEtd(new Date(item?.leather_a_s_n_forwarder?.ActualETD));
          }

          setDcNo(item?.DCNNo);
          if (item?.material_a_s_n_supplier_details) {
            setAsnTable(item?.material_a_s_n_supplier_details);
            temp_1?.map((check, ind) => {
              setIsCheck({
                [ind]: item?.material_a_s_n_supplier_details?.map(
                  (asnTable) => asnTable
                ),
              });
            });

            setIsCheckAll(
              item?.material_a_s_n_supplier_details?.map((asnTable) => asnTable)
            );

            setChangeTable(
              item?.material_a_s_n_supplier_details?.map((asnTable) => asnTable)
            );
          } else {
            setAsnTable(item?.leather_a_s_n_supplier_details);
            temp_1?.map((check, ind) => {
              setIsCheck({
                [ind]: item?.leather_a_s_n_supplier_details?.map(
                  (asnTable) => asnTable
                ),
              });
            });

            setIsCheckAll(
              item?.leather_a_s_n_supplier_details?.map((asnTable) => asnTable)
            );

            setChangeTable(
              item?.leather_a_s_n_supplier_details?.map((asnTable) => asnTable)
            );
          }

          setAsnTableData(temp_1);
          setEdit("edit");
          setList(item);
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };
  // console.log("addressList", optionData1);

  const getList = () => {
    api.address().then((res) => {
      if (res?.status == "success") {
        setListDelivery(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.dcUomList().then((res) => {
      if (res?.status == "success") {
        setDcUomList(res?.uom_list);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.addressGet().then((res) => {
      if (res?.status == "success") {
        // setAddressList(res?.supplier_addresses);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.asnCreate().then((res) => {
      if (res?.status == "success") {
        setShipments(res?.shipments);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (dcData && types !== "draft") {
      dcViewList();
    } else if (dcData && types == "draft") {
      dcDraftViewList();
    }
  }, []);

  // console.log("data", data);

  // console.log("checkbox", checkbox);

  const onNoNameChange = (e, index, i, asn_qty, delivery_qty) => {
    const value = e.target.value;

    let sum = Math.abs(+asn_qty - +delivery_qty);

    // console.log("sum", sum);

    if (noName[i]) {
      let obj = { ...noName[i] };

      let temp = {
        ...obj,
        [index]: value < 0 ? 0 : value,
      };

      setNoName((pre) => ({ ...pre, [i]: temp }));
      if (+value > sum) {
        setErrorQty((val) => ({ ...val, [index]: 1 }));
      } else {
        setErrorQty((val) => ({ ...val, [index]: 0 }));
      }
    } else {
      let obj = {
        [index]: value < 0 ? 0 : value,
      };
      setNoName((pre) => ({ ...pre, [i]: obj }));
      if (+value > sum) {
        setErrorQty((val) => ({ ...val, [index]: 1 }));
      } else {
        setErrorQty((val) => ({ ...val, [index]: 0 }));
      }
    }
  };

  console.log("errorQyt", errorQyt);

  // console.log("noName", noName);

  const onNoNameChanges = (e, sel_id, asn_qty, delivery_qty) => {
    const value = e.target.value;
    setNoNames((pre) => ({ ...pre, [sel_id]: value }));
  };

  const [openCard, setOpenCard] = useState(false);
  const grnSupplierSubmitHandler = (supres) => setOpenCard(supres);

  const [visiable, setVisiable] = useState(false);
  const [visiablePo, setVisiablePo] = useState([]);

  const change = (num) => {
    let temp = [...visiablePo];

    if (option1?.length !== 0) {
      temp.push(num);
      setVisiable(true);
      setVisiablePo(temp);
    }
  };

  // console.log("optionData1", optionData1);
  // console.log("visiablePo", visiablePo);

  const [showModal, setShowModal] = useState(false);

  const dcClickHandler = () => setShowModal(true);
  const dcModalYesHandler = (e) => {
    e.preventDefault();
    const message =
      "Are you sure Do You Want to create the Delivery Challan without Stages?";
    e.returnValue = message;
    setShowModal(false);
    setStageShow(true);
    setShowRightDc(true);
  };

  const dcModalYesHandlers = (e) => {
    e.preventDefault();
    const message =
      "Are you sure Do You Want to create the Delivery Challan without Stages?";
    e.returnValue = message;
    setShowModal(false);
    setTimeout(() => {
      navigate("/dc-list");
    }, 300);
  };

  const handleSelectAll = (i) => {
    setChangeTable([]);
    if (isCheckAll[i] == false) {
      console.log("sed");
      let temp = [];
      console.log("temp", temp);
      setIsCheck((val) => ({ ...val, [i]: temp }));
      setIsCheckAll((val) => ({ ...val, [i]: !isCheckAll[i] }));
    } else {
      let temp = isCheck[i];
      temp.splice(0, temp.length);

      console.log("jshj");
      setIsCheck((val) => ({ ...val, [i]: temp }));
      setIsCheckAll((val) => ({ ...val, [i]: !isCheckAll[i] }));
      setChangeTable(asnTable?.map((asnTable) => asnTable));
    }
  };

  // console.log("isCheckAll", isCheckAll);

  const changeAsnTable = (i, item, ind) => {
    let temp = isCheck[i];
    const value = item;
    const index = temp.indexOf(item);

    // setChangeTable(temps);

    if (temp.includes(value)) {
      temp.splice(index, 1);
      console.log("lkjh", temp);
    } else {
      temp.push(value);
      console.log("....", temp);
    }

    setIsCheck((val) => ({ ...val, [i]: temp }));
  };

  // console.log("changeTable", data);

  // API INTERGRATION

  const [selectBoxBtn, setSelectBoxBtn] = useState(false);

  // FOR GET ASN SHIPMENTS
  const getDetails = (num, ind) => {
    let details = new FormData();

    let newTemp = [data];

    let temp = [];

    let optionTemp = [];

    let optionTemps = [...poId];

    if (num == 1) {
      if (data?.purchase_order_no) {
        optionTemp.push({
          FactoryName: data?.FactoryName,
          purchase_order_id: data?.purchase_order_id,
          type: data?.type,
        });
      } else {
        optionTemp.push({
          FactoryName: optionData1?.value?.FactoryName,
          purchase_order_id: optionData1?.value?.purchase_order_id,
          type: optionData1?.value?.type,
        });
      }
      if (!optionTemps?.includes(optionData1?.value?.purchase_order_id)) {
        optionTemps.push(optionData1?.value?.purchase_order_id);
      }
    } else {
      Object.values(optionData2)?.map((item, ind) => {
        if (!optionTemps?.includes(item?.value?.purchase_order_id)) {
          optionTemps.push(item?.value?.purchase_order_id);
        }

        // optionTemp.push({
        //   FactoryName: item?.value?.FactoryName,
        //   purchase_order_id: item?.value?.purchase_order_id,
        //   type: item?.value?.type,
        // })
      });

      // NEW
      optionTemp.push({
        FactoryName: optionData3?.value?.FactoryName,
        purchase_order_id: optionData3?.value?.purchase_order_id,
        type: optionData3?.value?.type,
      });
    }

    setOptionPo(optionTemps);

    // console.log("optionTemps", optionTemps);
    // console.log("optionTemp", optionTemp);

    // MULTIPLE

    // checkbox?.map((item, i) => {
    optionTemp?.map((item, i) => {
      if (item?.FactoryName == "FWD") {
        if (item?.type == "M") {
          details.append(`fwd_material_po_ids[${i}]`, item?.purchase_order_id);
          details.append(`fwd_leather_po_ids[${i}]`, temp);
          details.append(`lgd_material_po_ids[${i}]`, temp);
          details.append(`lgd_leather_po_ids[${i}]`, temp);
          details.append(`gd_material_po_ids[${i}]`, temp);
          details.append(`gd_leather_po_ids[${i}]`, temp);
        } else {
          details.append(`fwd_leather_po_ids[${i}]`, item?.purchase_order_id);
          details.append(`fwd_material_po_ids[${i}]`, temp);
          details.append(`lgd_material_po_ids[${i}]`, temp);
          details.append(`lgd_leather_po_ids[${i}]`, temp);
          details.append(`gd_material_po_ids[${i}]`, temp);
          details.append(`gd_leather_po_ids[${i}]`, temp);
        }
      }

      if (item?.FactoryName == "LGD") {
        if (item?.type == "M") {
          details.append(`lgd_material_po_ids[${i}]`, item?.purchase_order_id);
          details.append(`fwd_material_po_ids[${i}]`, temp);
          details.append(`fwd_leather_po_ids[${i}]`, temp);
          details.append(`lgd_leather_po_ids[${i}]`, temp);
          details.append(`gd_material_po_ids[${i}]`, temp);
          details.append(`gd_leather_po_ids[${i}]`, temp);
        } else {
          details.append(`lgd_leather_po_ids[${i}]`, item?.purchase_order_id);
          details.append(`fwd_material_po_ids[${i}]`, temp);
          details.append(`fwd_leather_po_ids[${i}]`, temp);
          details.append(`lgd_material_po_ids[${i}]`, temp);
          details.append(`gd_material_po_ids[${i}]`, temp);
          details.append(`gd_leather_po_ids[${i}]`, temp);
        }
      }

      if (item?.FactoryName == "GD") {
        if (item?.type == "M") {
          details.append(`gd_material_po_ids[${i}]`, item?.purchase_order_id);
          details.append(`lgd_leather_po_ids[${i}]`, temp);
          details.append(`fwd_material_po_ids[${i}]`, temp);
          details.append(`fwd_leather_po_ids[${i}]`, temp);
          details.append(`lgd_material_po_ids[${i}]`, temp);
          details.append(`gd_leather_po_ids[${i}]`, temp);
        } else {
          details.append(`gd_leather_po_ids[${i}]`, item?.purchase_order_id);
          details.append(`lgd_leather_po_ids[${i}]`, temp);
          details.append(`fwd_material_po_ids[${i}]`, temp);
          details.append(`fwd_leather_po_ids[${i}]`, temp);
          details.append(`lgd_material_po_ids[${i}]`, temp);
          details.append(`gd_material_po_ids[${i}]`, temp);
        }
      }
    });

    api.dcPoDetails(details).then((res) => {
      if (res?.status == "success") {
        setSelectBoxBtn(true);
        setPoId(optionTemps);
        let shipmentOptions = [];

        let addressTemp = [];

        res?.leather_purchase_orders?.map((item, ind) => {
          addressTemp.push(item?.ship_to);
        });

        res?.material_purchase_orders?.map((item, ind) => {
          addressTemp.push(item?.ship_to);
        });

        setAddressList(addressTemp);

        res?.shipment_details?.map((item, ind) => {
          if (Array.isArray(item?.detail)) {
            shipmentOptions.push({
              value: {
                PurchaseOrderDetailDelDateID:
                  item?.detail[0]?.MaterialPurchaseOrderDetailDelDateID ||
                  item?.detail[0]?.LeatherPurchaseOrderDetailDelDateID,
                type: item?.type,
                FactoryID: item?.detail[0]?.FactoryID,
              },
             
              label: `${moment(item?.date).format("DD-MMM-YYYY")} : ${
                item?.detail?.sku?.MaterialSKUCode
                  ? item?.detail?.sku?.MaterialSKUCode
                  : ""
              }`
            });
          } else {
            shipmentOptions.push({
              value: {
                PurchaseOrderDetailDelDateID:
                  item?.detail?.MaterialPurchaseOrderDetailDelDateID ||
                  item?.detail?.LeatherPurchaseOrderDetailDelDateID,
                type: item?.type,
                FactoryID: item?.detail?.FactoryID,
              },
              // label: `${moment(item?.date).format("DD-MMM-YYYY")} : ${
              //   item?.shipment_detail?.ReferenceDetailsName
              //     ? item?.shipment_detail?.ReferenceDetailsName
              //     : ""
              // }`,
              label: `${moment(item?.date).format("DD-MMM-YYYY")} : ${
                item?.detail?.sku?.MaterialSKUCode
                  ? item?.detail?.sku?.MaterialSKUCode
                  : ""
              }`,
            });
          }
        });

        // console.log("shipmentOptions", shipmentOptions);

        if (num == 1) {
          setOptions2(shipmentOptions);
          setVisiable(false);
          setOptions2s(shipmentOptions);
        } else {
          setVisiable(false);
          setOption2((val) => ({ ...val, [ind]: shipmentOptions }));
        }

        let addOne = checkbox?.filter(
          (e) => !optionTemps.includes(e.purchase_order_id)
        );

        let count = [];

        if (addOne) {
          for (let i = 0; i < addOne?.length; i++) {
            count.push(i);
          }
          setTempArray(count);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const [selectBoxAsn, setSelectBoxAsn] = useState(false);

  // console.log("optionChange2", optionChange2);

  const getAsnTable = (num, ind) => {
    // console.log("list");
    let formdata = new FormData();
    let temp = [...selectedPO];
    let asn = [...selectedASN];
    let temp_1 = [...asnTableData];

    let disabled = [...option3];
    let shipmentOption = [];

    if (num == 1) {
      optionChange2?.map((item, ind) => {
        shipmentOption.push({
          FactoryID: item?.value?.FactoryID,
          PurchaseOrderDetailDelDateID:
            item?.value?.PurchaseOrderDetailDelDateID,
          type: item?.value?.type,
        });
      });
    } else if (num == 2) {
      Object.values(optionChange2s)?.map((item, ind) => {
        // console.log("itemitem", item);

        item?.map((items, inds) => {
          shipmentOption.push({
            FactoryID: items?.value?.FactoryID,
            PurchaseOrderDetailDelDateID:
              items?.value?.PurchaseOrderDetailDelDateID,
            type: items?.value?.type,
          });
        });
      });
    }

    formdata.append("factory_id", shipmentOption[0]?.FactoryID);

    formdata.append("type", shipmentOption[0]?.type);

    shipmentOption?.map((item, i) => {
      formdata.append(`asn_nos[${i}]`, item?.PurchaseOrderDetailDelDateID);
    });

    api.dcPoDetail(formdata).then((res) => {
      if (res?.status == "success") {
        setSelectBoxAsn(true);
        temp.push(...checkboxs);
        asn.push(...checkboxAsn);
        temp_1.push(res?.purchase_orders);
        setAsnTable(res?.purchase_orders);

        disabled.push(ind);
        setOption3(disabled);
        // temp_1?.map((item, ind) => {

        // });

        let data = res?.purchase_orders?.map((asnTable) => asnTable);

        let index;
        if (ind == undefined) {
          index = 0;
        } else {
          index = ind + 1;
        }

        setIsCheck((val) => ({ ...val, [index]: data }));
        setIsChecks((val) => ({ ...val, [index]: data }));

        setIsCheckAll((val) => ({ ...val, [index]: true }));
        setChangeTable(res?.purchase_orders?.map((asnTable) => asnTable));
        setasnLists([]);
        setcheckAsn([]);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
    setSelectedPO(temp);
    setSelectedASN(asn);
    setAsnTableData(temp_1);
  };

  const submitHandle = () => {
    var formdata = new FormData();

    if (data) {
      formdata.append("factory_id", data?.FactoryID);
      if (data?.type == "M") {
        formdata.append("type", "M");
      } else {
        formdata.append("type", "L");
      }
    } else if (checkbox) {
      formdata.append("factory_id", checkbox[0]?.FactoryID);
      if (checkbox[0]?.type == "M") {
        formdata.append("type", "M");
      } else {
        formdata.append("type", "L");
      }
    } else if (dcData) {
      formdata.append("factory_id", dcData?.FactoryID);
      formdata.append(
        "asn_supplier_id",
        dcData?.LeatherASNSupplierID || dcData?.MaterialASNSupplierID
      );
      formdata.append("type", dcData?.MaterialType);
    }

    if (
      // dcDate?.length == 0 ||
      dcNo?.length == 0 ||
      // invoiceNo?.length == 0 ||
      // invoiceDate?.length == 0 ||
      // exWorks?.length == 0 ||
      noOfBox?.length == 0 ||
      netWeight?.length == 0 ||
      // widths?.length == 0 ||
      // Heights?.length == 0 ||
      // Legnths?.length == 0 ||
      grossWeight?.length == 0 ||
      shipmentId?.length == 0 ||
      transportBy?.length == 0 ||
      // eta?.length == 0 ||
      // etd?.length == 0 ||
      totalVolume?.length == 0
      // ||
      // asnNoNew?.length == 0
    ) {
      // setDcDateErr(true);
      setDcNoErr(true);
      // setInvoiceDateErr(true);
      // setExWorksErr(true);
      setNoOfBoxErr(true);
      setNetWeightErr(true);
      // setWidthsErr(true);
      // setHeightsErr(true);
      // setLegnthsErr(true);
      // setAsnNoNewErr(true);
      setGrossWeightErr(true);
      setShipmetIdErr(true);
      setTransportByErr(true);
      // setEtaErr(true);
      // setEtdErr(true);
      setDcInvoiceErr(true);
      // setInvoiceNoErr(true);
      setTotalVolumeErr(true);
    } else {
      formdata.append("dc_date", moment(dcDate).format("YYYY-MM-DD"));
      formdata.append("dc_no", dcNo);
      formdata.append("asn_no", 0);
      formdata.append("invoice_no", invoiceNo);
      formdata.append("invoice_date", moment(invoiceDate).format("YYYY-MM-DD"));
      formdata.append("ex_factory_date", moment(exWorks).format("YYYY-MM-DD"));
      formdata.append("no_of_boxes", noOfBox);
      formdata.append("net_weight", netWeight);
      formdata.append("gross_weight", grossWeight);
      formdata.append("dimension", 1 + "*" + 1 + "*" + 1);

      dimension?.map((item, ind) => {
        formdata.append(
          `pkg_details[${ind}][dimension]`,
          item?.width + "*" + item?.height + "*" + item?.length
        );
        formdata.append(`pkg_details[${ind}][label]`, "Sample Label");
        formdata.append(`pkg_details[${ind}][type]`, "type");
        formdata.append(`pkg_details[${ind}][PackageCount]`, item?.packages);
        formdata.append(`pkg_details[${ind}][weight]`, item?.width);
        formdata.append(`pkg_details[${ind}][GrossWeight]`, item?.grossWeight);
        formdata.append(`pkg_details[${ind}][NetValue]`, item?.netWeight);
        formdata.append(`pkg_details[${ind}][PackageVolume]`, item?.volume);
        formdata.append(
          `pkg_details[${ind}][PackageVolumeUomID]`,
          item?.unit_1
        );
        formdata.append(
          `pkg_details[${ind}][PackageWeightUomID]`,
          item?.unit_2
        );
        formdata.append(`pkg_details[${ind}][GrossWeightUomID]`, item?.unit_2);
      });

      formdata.append("shipment_mode_id", shipmentId);

      formdata.append("weight_unit_id", 41);
      formdata.append("currency_id", user?.supplier?.CurrencyID);
      formdata.append("delivery_term_id", deleveryTermId);
      // formdata.append("delivery_term_id", "1");
      formdata.append("transport_by", transportBy);

      formdata.append("actual_eta", moment(eta).format("YYYY-MM-DD"));
      formdata.append("actual_etd", moment(etd).format("YYYY-MM-DD"));
      formdata.append("size_in_cbm", totalVolume);

      let count = 1;

      if (dcData) {
        Object.keys(noNames).map((item, ind) => {
          if (errorQyt[item] == 1) {
            count = count + 1;
          }
          formdata.append(`details[${ind}][id]`, item);
          formdata.append(`details[${ind}][qty]`, noNames[item]);
        });

        if (count == 1) {
          api.dcUpdate(formdata).then((res) => {
            if (res?.status == "success") {
              setTimeout(() => {
                navigate("/dc-list");
              }, 1500);

              setError(res);
              setErrorBox(true);
            } else {
              setError(res);
              setErrorBox(true);
            }
          });
        }
      } else {
        let counter = 0;

        Object.values(isCheck).forEach((items, index) => {
          items?.forEach((item) => {
            if (
              errorQyt[
                item?.MaterialPurchaseOrderDetailDelDateID ||
                  item?.LeatherPurchaseOrderDetailDelDateID
              ] == 1
            ) {
              count = count + 1;
            }

            formdata.append(
              `order_details[${counter}][del_date_id]`,
              item?.MaterialPurchaseOrderDetailDelDateID ||
                item?.LeatherPurchaseOrderDetailDelDateID
            );

            formdata.append(
              `order_details[${counter}][sku_id]`,
              item?.material_purchase_order_detail?.MaterialSKUID ||
                item?.leather_purchase_order_detail?.LeatherSKUID
            );

            formdata.append(
              `order_details[${counter}][price]`,
              item?.material_purchase_order_detail?.Price ||
                item?.leather_purchase_order_detail?.Price
            );

            formdata.append(`order_details[${counter}][color_id]`, "1");

            formdata.append(
              `order_details[${counter}][qty]`,
              noName[index][
                item?.MaterialPurchaseOrderDetailDelDateID ||
                  item?.LeatherPurchaseOrderDetailDelDateID
              ]
            );

            counter++;
          });
        });
        // Object.values(isCheck).map((items, index) => {
        //   items?.map((item, ind) => {
        //     formdata.append(
        //       `order_details[${ind}][del_date_id]`,
        //       item?.MaterialPurchaseOrderDetailDelDateID ||
        //         item?.LeatherPurchaseOrderDetailDelDateID
        //     );

        //     formdata.append(
        //       `order_details[${ind}][sku_id]`,
        //       item?.material_purchase_order_detail?.MaterialSKUID ||
        //         item?.leather_purchase_order_detail?.LeatherSKUID
        //     );

        //     formdata.append(
        //       `order_details[${ind}][price]`,
        //       item?.material_purchase_order_detail?.Price ||
        //         item?.leather_purchase_order_detail?.Price
        //     );

        //     formdata.append(`order_details[${ind}][color_id]`, "1");

        //     formdata.append(
        //       `order_details[${ind}][qty]`,
        //       noName[index][
        //         item?.MaterialPurchaseOrderDetailDelDateID ||
        //           item?.LeatherPurchaseOrderDetailDelDateID
        //       ]
        //     );
        //   });
        // });

        formdata.append("year_id", isCheck[0]?.[0]?.YearID);

        // formdata.append("pkg_details[0][dimension]", "1*1*1");
        // formdata.append("pkg_details[0][weight]", 1);
        if (count == 1) {
          setBtn(true);
          api.dcCreate(formdata).then((res) => {
            if (res?.status == "success") {
              setBtn(false);
              setAsnCrate(res?.asn_forwarder_id);
              if (transportBy == "kh") {
                setError(res);
                setErrorBox(true);
                setTimeout(() => {
                  navigate("/dc-list");
                }, 1500);
              } else {
                dcClickHandler();
              }
            } else {
              setBtn(false);
              setError(res);
              setErrorBox(true);
            }
          });
        }
      }
    }
  };
  // console.log(isCheck, "isCheck");
  // SAVE DRAFT
  const saveDraftHandler = () => {
    var formdata = new FormData();

    if (data) {
      formdata.append("factory_id", data?.FactoryID);
      if (data?.type == "M") {
        formdata.append("mat_type", "M");
      } else {
        formdata.append("mat_type", "L");
      }
    } else if (checkbox) {
      formdata.append("factory_id", checkbox[0]?.FactoryID);
      if (checkbox[0]?.type == "M") {
        formdata.append("mat_type", "M");
      } else {
        formdata.append("mat_type", "L");
      }
    } else if (dcData) {
      formdata.append("factory_id", dcData?.FactoryID);
      formdata.append(
        "asn_supplier_id",
        dcData?.LeatherASNSupplierID || dcData?.MaterialASNSupplierID
      );
      formdata.append("mat_type", dcData?.MaterialType);
    }
    formdata.append("type", 3);

    formdata.append("dc_date", moment(dcDate).format("YYYY-MM-DD"));
    formdata.append("dc_no", dcNo);
    formdata.append("asn_no", 0);
    formdata.append("invoice_no", invoiceNo);
    formdata.append("invoice_date", moment(invoiceDate).format("YYYY-MM-DD"));
    formdata.append("ex_factory_date", moment(exWorks).format("YYYY-MM-DD"));
    formdata.append("no_of_boxes", 1);
    formdata.append("net_weight", 1);
    formdata.append("gross_weight", 1);
    formdata.append("dimension", 1 + "*" + 1 + "*" + 1);

    // FOR ASN TABLE
    formdata.append("asnTableData", JSON.stringify(asnTableData));

    // FOR PO LIST DROPDOWN
    formdata.append("poList", JSON.stringify(poList));
    formdata.append("option1", JSON.stringify(option1));
    formdata.append("optionData1", JSON.stringify(optionData1));
    formdata.append("optionData2", JSON.stringify(optionData2));
    formdata.append("selectBoxBtn", selectBoxBtn);
    formdata.append("poId", poId);

    // FOR SHIPMENT LIST DROPDOWN
    formdata.append("options2", JSON.stringify(options2));
    formdata.append("optionChange2", JSON.stringify(optionChange2));
    formdata.append("optionChange2s", JSON.stringify(optionChange2s));
    formdata.append("option2", JSON.stringify(option2));
    formdata.append("selectBoxAsn", selectBoxAsn);
    formdata.append("option3", option3);

    dimension?.map((item, ind) => {
      formdata.append(
        `pkg_details[${ind}][dimension]`,
        item?.width + "*" + item?.height + "*" + item?.length
      );

      formdata.append(`pkg_details[${ind}][label]`, "Sample Label");
      formdata.append(`pkg_details[${ind}][type]`, "type");

      formdata.append(`pkg_details[${ind}][PackageCount]`, item?.packages);
      formdata.append(`pkg_details[${ind}][weight]`, item?.width);

      formdata.append(`pkg_details[${ind}][GrossWeight]`, item?.grossWeight);

      formdata.append(`pkg_details[${ind}][NetValue]`, item?.netWeight);
    });

    formdata.append("shipment_mode_id", shipmentId);
    formdata.append("currency_id", user?.supplier?.CurrencyID);
    formdata.append("delivery_term_id", deleveryTermId);
    // formdata.append("delivery_term_id", "1");
    formdata.append("transport_by", transportBy);

    formdata.append("actual_eta", moment(eta).format("YYYY-MM-DD"));
    formdata.append("actual_etd", moment(etd).format("YYYY-MM-DD"));
    formdata.append("size_in_cbm", totalVolume);

    formdata.append("order_details_data", JSON.stringify(isCheck));
    formdata.append("order_details_noName", JSON.stringify(noName));

    Object.values(isCheck).map((items, index) => {
      items?.map((item, ind) => {
        formdata.append(
          `order_details[${ind}][del_date_id]`,
          item?.MaterialPurchaseOrderDetailDelDateID ||
            item?.LeatherPurchaseOrderDetailDelDateID
        );

        formdata.append(
          `order_details[${ind}][sku_id]`,
          item?.material_purchase_order_detail?.MaterialSKUID ||
            item?.leather_purchase_order_detail?.LeatherSKUID
        );

        formdata.append(
          `order_details[${ind}][price]`,
          item?.material_purchase_order_detail?.Price ||
            item?.leather_purchase_order_detail?.Price
        );

        formdata.append(`order_details[${ind}][color_id]`, "1");

        formdata.append(
          `order_details[${ind}][qty]`,
          noName[index][
            item?.MaterialPurchaseOrderDetailDelDateID ||
              item?.LeatherPurchaseOrderDetailDelDateID
          ]
        );
      });
    });

    formdata.append("year_id", isCheck[0]?.[0]?.YearID);

    api.saveDraft(formdata).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        if (types == "draft") {
          // deleteDrafts(view?.id);
        } else {
          setTimeout(() => {
            navigate("/dc-list");
          }, 800);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // DELETE DRAFT
  const deleteDrafts = (id) => {
    let data = {
      draft_id: id,
    };
    api.deleteDraft(data).then((res) => {
      if (res?.status === "success") {
        setTimeout(() => {
          setTimeout(() => {
            navigate("/commercial-invoice/list");
          }, 800);
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // useEffect(() => {
  //   console.log("noNames", noNames);
  //   Object.values(noNames).map((item, ind) => {
  //     console.log("it", item);
  //   });
  //   Object.keys(noNames).map((item, ind) => {
  //     console.log("it", item);
  //     console.log("dd", noNames[item]);
  //   });

  //   for (const [key, val] of Object.entries(noNames)) {
  //     console.log(`order_details[id]`, key, `order_details[qty]`, val);
  //   }
  // }, [noNames]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    let temp = [];
    if (data) {
      // console.log("data", data);
      temp.push(data);
      setPoList(temp);
      setOptionChange({
        value: {
          purchase_order_id: data?.purchase_order_id,
          type: data?.type,
          FactoryName: data?.FactoryName,
        },
        label: `${data?.type} : ${data?.purchase_order_no}`,
      });
      setOptionData1({
        value: {
          purchase_order_id: data?.purchase_order_id,
          type: data?.type,
          FactoryName: data?.FactoryName,
        },
        label: `${data?.type} : ${data?.purchase_order_no}`,
      });
      getDetails(1);
    } else if (checkbox) {
      setPoList(checkbox);
    }
  }, []);

  // console.log("dimension", dimension);

  const submitHandleDelivery = () => {
    if (
      typeDel?.length == 0 ||
      add1?.length == 0 ||
      // add2?.length == 0 ||
      // add3?.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      // mailId?.length == 0 ||
      phone?.length == 0
    ) {
      setTypesDelErr(true);
      setAdd1Err(true);
      // setAdd2Err(true);
      // setAdd3Err(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      // setMailIdErr(true);
      setPhoneErr(true);
    } else {
      let formData = new FormData();
      setBtnDelivery(true);

      formData.append("branch_id", typeDel);
      formData.append("latitude", "0.87576534");
      formData.append("longitude", "0.89675455");
      formData.append("address_1", add1);

      formData.append("country_id", country);
      formData.append("state_id", state);
      formData.append("city_id", city);

      if (add2) {
        formData.append("address_2", add2);
      }

      if (add3) {
        formData.append("address_3", add3);
      }

      if (mailId) {
        formData.append("email", mailId);
      }

      if (ext) {
        formData.append("telephone_ext", ext);
      }

      if (fax) {
        formData.append("fax", fax);
      }

      if (phone?.length == 12) {
        formData.append("telephone", phone?.slice(-10));
        formData.append("telephone_code", phone?.slice(0, 2));
      } else {
        formData.append("telephone", phone?.slice(3, phone?.length));
        formData.append("telephone_code", phone?.slice(0, 3));
      }

      api.addressAdd(formData).then((res) => {
        if (res?.status == "success") {
          setOpen(false);
          getList();
        } else {
          setBtnDelivery(false);
          setError(res);
        }
        setBtn(false);
      });
    }
  };

  const topHead =
    types == "edit"
      ? "dc_edit"
      : types == "view"
      ? "dc_view"
      : types == "add"
      ? "dc"
      : "dc_edit";

  // SELECT BOX

  useEffect(() => {
    let temp = [];

    let poTemp = poList?.filter((e) => !poId?.includes(e.purchase_order_id));

    poTemp?.map((item, ind) => {
      temp.push({
        value: {
          purchase_order_id: item?.purchase_order_id,
          type: item?.type,
          FactoryName: item?.FactoryName,
        },
        label: `${item?.type} : ${item?.purchase_order_no}`,
      });
    });

    // console.log("tem", temp);

    setOption1(temp);
  }, [poList, poId]);

  // console.log("optionData2", optionData2);

  useEffect(() => {
    window.addEventListener("beforeunload", dcModalYesHandlers);
    return () => {
      window.removeEventListener("beforeunload", dcModalYesHandlers);
    };
  }, []);

  const addDimension = () => {
    let temp = [...dimension];

    let check;

    dimension?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (check) {
      setCapModal(true);
    } else {
      temp.push({
        width: "",
        height: "",
        length: "",
        packages: "",
        grossWeight: "",
        netWeight: "",
        unit_1: "",
        unit_2: "",
        // unit_3: "",
      });
    }

    setDimension(temp);
  };

  const removeImage = (item, index) => {
    let temp = [...dimension];
    temp.splice(index, 1);
    setDimension(temp);
  };

  const [indexVal, setIndexVal] = useState("");

  const handleChange = (e, ind) => {
    setIndexVal(ind);
    let name = e.target.name;
    let value = e.target.value;

    let temp = [...dimension];
    temp[ind][name] = value;
    setDimension(temp);
  };

  // FOR TOTAL VALUME
  useEffect(() => {
    if (indexVal !== "") {
      let temp = [...dimension];

      // FOR VALUME
      let height = +temp[indexVal].height;
      let length = +temp[indexVal].length;
      let width = +temp[indexVal].width;
      let total = height * length * width;
      temp[indexVal]["volume"] = total;
      // FOR TOTAL VALUME
      temp[indexVal]["total"] = +total * +temp[indexVal].packages;
      let data = { ...temp[indexVal] };
      temp.splice(indexVal, 1);
      temp.splice(indexVal, 0, data);
      setDimension(temp);
      setIndexVal("");
    }
  }, [dimension]);

  const sumDimension = () => {
    let formdata_1 = new FormData();
    let formdata_2 = new FormData();
    let formdata_3 = new FormData();

    let check;

    dimension?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (check) {
      setCapModal(true);
    } else {
      dimension?.map((item, ind) => {
        formdata_1.append(
          `conversions[${ind}][unit]`,
          item?.unit_2 == 17
            ? "Grams"
            : item?.unit_2 == 20
            ? "Kilograms"
            : item?.unit_2 == 26
            ? "Metric Ton"
            : item?.unit_2 == 32
            ? "Quintal"
            : ""
        );
        formdata_1.append(`conversions[${ind}][value]`, item?.grossWeight);
        formdata_2.append(
          `conversions[${ind}][unit]`,
          +item?.unit_2 == 17
            ? "Grams"
            : +item?.unit_2 == 20
            ? "Kilograms"
            : +item?.unit_2 == 26
            ? "Metric Ton"
            : +item?.unit_2 == 32
            ? "Quintal"
            : ""
        );
        formdata_2.append(`conversions[${ind}][value]`, item?.netWeight);
      });

      const total_packages = dimension.reduce((a, b) => +a + +b?.packages, 0);

      if (dimension?.length == 1) {
        let temp;
        dimension?.map((item, ind) => {
          temp = +item?.volume * +item?.packages;
          setTotalVolumeUnit(item?.unit_1);
        });
        setTotalVolume(temp);
      } else {
        let minimumCostItem = Math.min(...dimension.map((item) => item.total));
        let finder = dimension.find((i) => i?.total == minimumCostItem);

        // formdata_3.append("conversion_to", finder?.unit_1);
        dimension?.map((item, ind) => {
          formdata_3.append(
            `conversions[${ind}][unit]`,
            +item?.unit_1 == 11
              ? "CM"
              : +item?.unit_1 == 25
              ? "Meter"
              : +item?.unit_1 == 50
              ? "Feet"
              : +item?.unit_1 == 45
              ? "Yard"
              : ""
          );
          formdata_3.append(`conversions[${ind}][value]`, item?.total);
        });

        api.dcCubicCoversion(formdata_3).then((res) => {
          if (res?.status == "success") {
            setTotalVolume(Number(res?.result).toFixed(2));
            setTotalVolumeUnit(finder?.unit_1);
          } else {
            setErrorBox(false);
            setError(res);
          }
        });
      }

      api.dcCoversion(formdata_1).then((res) => {
        if (res?.status == "success") {
          let result = +total_packages * +res?.result;
          setGrossWeight(Number(result).toFixed(2));
        } else {
          setErrorBox(false);
          setError(res);
        }
      });

      api.dcCoversion(formdata_2).then((res) => {
        if (res?.status == "success") {
          let result = +total_packages * +res?.result;
          setNetWeight(Number(result).toFixed(2));
        } else {
          setErrorBox(false);
          setError(res);
        }
      });

      setNoOfBox(total_packages);
    }
  };

  // console.log("dimension", dimension);

  const handlePochange = (e, ind) => {
    setOptionData2((val) => ({ ...val, [ind]: e }));
    setOptionData3(e);
  };

  // console.log("op", optionData3);

  const handleShipchange = (e, ind) => {
    setOptionChange2s((val) => ({ ...val, [ind]: e }));
  };

  // useEffect(() => {
  //   const res = Array.from(
  //     dimension.reduce(
  //       (m, { name, value }) => m.set(name, (m.get(name) || 0) + value),
  //       new Map()
  //     ),
  //     ([name, value]) => ({ name, value })
  //   );

  //   console.log("res", res);
  // }, [dimension]);

  // useEffect(() => {
  //   const no_of_Packages = dimension.reduce((a, b) => +a + +b?.packages, 0);
  //   const total_Net_Weight = dimension.reduce((a, b) => +a + +b?.netWeight, 0);
  //   const total_Gross_Weight = dimension.reduce(
  //     (a, b) => +a + +b?.grossWeight,
  //     0
  //   );
  //   setNoOfBox(no_of_Packages);
  //   setNetWeight(total_Net_Weight);
  //   setGrossWeight(total_Gross_Weight);
  // }, [dimension]);

  // console.log("dimension", types);

  return (
    <Col xs={12}>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type={topHead} />
      <div className="d-sm-flex">
        <div className={`${showRight ? "left-dc" : "left-dc1"}`}>
          <div className="d-flex justify-content-between">
            <Col md={6} xs={12}>
              <Col xs={12} className="d-flex flex-wrap px-3">
                <Col className="input-box px-2 mb-2" xs={12} sm={6} lg={6}>
                  <label>
                    DC No {types !== "view" && <span className="err">*</span>}
                  </label>
                  <input
                    className=""
                    onChange={(e) => setDcNo(e.target.value)}
                    value={dcNo}
                    disabled={
                      types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : false
                    }
                  />
                  {dcNo?.length == 0 && dcNoErr && (
                    <p className="dc_err">Enter DC No</p>
                  )}
                </Col>
                <Col className="input-box px-2 mb-2" xs={12} sm={6} lg={6}>
                  <label>
                    DC Date {types !== "view" && <span className="err">*</span>}
                  </label>

                  <DatePicker
                    setStartDate={setDcDate}
                    startDate={dcDate}
                    disabled={
                      types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : false
                    }
                  />
                  {dcDate?.length == 0 && dcDateErr && (
                    <p className="dc_err">Enter DC Date</p>
                  )}
                </Col>
                <Col className="input-box px-2 mb-2" xs={12} sm={6} lg={6}>
                  <label>KH ASN No</label>

                  <input
                    // setStartDate={setDcDate}
                    // startDate={dcDate}
                    value={materialAs}
                    disabled
                  />
                </Col>

                <Col className="input-box px-2 mb-2" xs={12} sm={6} lg={6}>
                  <label>Invoice No </label>
                  <input
                    className=""
                    onChange={(e) => setInvoiceNo(e.target.value)}
                    value={invoiceNo}
                    disabled={
                      types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : false
                    }
                  />
                  {/* {invoiceNo?.length == 0 && invoiceNoErr && (
                    <p className="dc_err">Enter Invoice No</p>
                  )} */}
                </Col>
                <Col className="input-box px-2 mb-2" xs={12} sm={6} lg={6}>
                  <label>Invoice Date </label>
                  <DatePicker
                    setStartDate={setInvoiceDate}
                    startDate={invoiceDate}
                    disabled={
                      types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : false
                    }
                  />
                  {/* {invoiceDate?.length == 0 && invoiceDateErr && (
                    <p className="dc_err">Enter Invoice Date</p>
                  )} */}
                </Col>

                <Col
                  className="input-box px-2 mb-2 position-relative"
                  xs={12}
                  sm={3}
                  md={3}
                  xxl={3}
                >
                  <label>
                    Po No<span className="err"></span>
                  </label>
                  {types == "add" && (
                    <Select
                      closeMenuOnSelect={false}
                      name="colors"
                      options={option1}
                      value={optionData1}
                      onChange={(e) => setOptionData1(e)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={
                        selectBoxBtn
                          ? true
                          : types == "view"
                          ? true
                          : TransportedBy == "sp"
                          ? true
                          : edit == "edit"
                          ? true
                          : false
                      }
                    />
                  )}
                  {types != "add" && (
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      name="colors"
                      options={option1}
                      value={optionData1}
                      onChange={(e) => setOptionData1(e)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={
                        selectBoxBtn
                          ? true
                          : types == "view"
                          ? true
                          : TransportedBy == "sp"
                          ? true
                          : edit == "edit"
                          ? true
                          : false
                      }
                    />
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    {types == "add" && option1?.length > 1 && (
                      <button
                        className="cust-btns bg-primary1 text-white rounded-1"
                        onClick={() => getDetails(1)}
                        // disabled={selectBoxBtn ? true : false}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Col>
                <Col
                  className="input-box mb-2 px-2 position-relative"
                  xs={12}
                  sm={9}
                  lg={9}
                >
                  <label>
                    Shipment No<span className="err"></span>
                  </label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    name="colors"
                    options={options2}
                    value={optionChange2}
                    onChange={(e) => setOptionChange2(e)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={
                      selectBoxAsn
                        ? true
                        : types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : edit == "edit"
                        ? true
                        : false
                    }
                  />
                  <div className="d-flex justify-content-between mt-2">
                    {types == "add" && (
                      <>
                        <p className="text-danger" style={{ fontSize: "12px" }}>
                          Note: Multiple Selection
                        </p>
                        <button
                          className="cust-btns bg-primary1 text-white rounded-1"
                          onClick={() => getAsnTable(1)}
                          disabled={selectBoxAsn ? true : false}
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                </Col>

                {types == "add" &&
                  poList?.length > 1 &&
                  option1?.length !== 0 && (
                    <div
                      className="pointer"
                      onClick={() => (visiable ? " " : change(1))}
                    >
                      <p>
                        Add PO <IoIosAddCircleOutline />
                      </p>
                    </div>
                  )}

                {visiablePo?.map((item, ind) => {
                  return (
                    <div className="d-flex w-100" key={ind}>
                      {" "}
                      <Col
                        className="input-box mb-2 position-relative"
                        xs={12}
                        sm={3}
                        md={3}
                        xxl={3}
                      >
                        <label>
                          PO No<span className="err"></span>
                        </label>

                        <Select
                          closeMenuOnSelect={false}
                          name="colors"
                          options={option1}
                          value={optionData2[ind]}
                          onChange={(e) => handlePochange(e, ind)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={poId?.includes(
                            optionData2?.[ind]?.value?.purchase_order_id
                          )}
                        />

                        <div className="d-flex justify-content-end mt-2">
                          {types == "add" && (
                            <button
                              className="cust-btns bg-primary1 text-white rounded-1"
                              onClick={() => getDetails(2, ind)}
                              disabled={poId?.includes(
                                optionData2?.[ind]?.value?.purchase_order_id
                              )}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </Col>
                      <Col
                        className="input-box mb-2 px-2 position-relative"
                        xs={12}
                        sm={9}
                        lg={9}
                      >
                        <label>
                          Shipment No<span className="err"></span>
                        </label>
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          name="colors"
                          options={option2[ind]}
                          value={optionChange2s[ind]}
                          onChange={(e) => handleShipchange(e, ind)}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={option3?.includes(ind) ? true : false}
                        />
                        <div className="d-flex justify-content-between mt-2">
                          {types == "add" && (
                            <>
                              <p
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                Note: Multiple Selection
                              </p>
                              <button
                                className="cust-btns bg-primary1 text-white rounded-1"
                                onClick={() => getAsnTable(2, ind)}
                                disabled={option3?.includes(ind) ? true : false}
                              >
                                Submit
                              </button>
                            </>
                          )}
                        </div>
                      </Col>
                    </div>
                  );
                })}
              </Col>
            </Col>
            <div>
              {/* {types == "edit" && (
                <button
                  className="cust-btn bg-primary1 mb-2 rounded-1 text-white  mx-5"
                  onClick={() => window.print()}
                >
                  Print
                </button>
              )} */}
            </div>
          </div>{" "}
          <div className="px-4">
            <div>
              {poList?.map((item, index) => (
                <div className="d-flex flex-wrap" key={index}>
                  <p className="fnt-fam-f2 me-3 fs-14">
                    <span className="opac-85">Purchase Order Ref No:</span>
                    {" " + item?.purchase_order_no}
                  </p>
                  <p className="fnt-fam-f2 fs-14">
                    <span className="opac-85">Purchase Date:</span>
                    {item?.purchase_order_date
                      ? " " +
                        moment(item?.purchase_order_date).format("DD-MMM-YYYY")
                      : ""}
                  </p>
                </div>
              ))}
            </div>
            <div
              className={
                showRightDc === false
                  ? "prduct-page dc-prod-table"
                  : "prduct-page dc-prod-table"
              }
            >
              {asnTableData &&
                asnTableData?.map((item, i) => {
                  return (
                    <DcTable
                      i={i}
                      asnTable={item}
                      changeAsnTable={changeAsnTable}
                      onNoNameChange={onNoNameChange}
                      onNoNameChanges={onNoNameChanges}
                      noName={noName}
                      noNames={noNames}
                      type={edit}
                      handleSelectAll={handleSelectAll}
                      isCheck={isCheck}
                      isCheckAll={isCheckAll}
                      errorQyt={errorQyt}
                    />
                  );
                })}
            </div>
          </div>
          <Col xs={12}>
            <div className="package-detail pt-3 pb-2 mt-2 mb-1 px-4">
              <h5>Package Details</h5>
              {dimension?.map((item, ind) => {
                return (
                  <>
                    <div className="d-flex" key={ind}>
                      <Col xs={4} lg={1} md={4} style={{ width: "12.333333%" }}>
                        <div className="input-box pe-2 me-2 mb-2">
                          <label>
                            Width{" "}
                            {types !== "view" && <span className="err">*</span>}
                          </label>
                          <input
                            className=""
                            type={"number"}
                            name="width"
                            // onChange={(e) => setWidths(e.target.value)}
                            onChange={(e) => handleChange(e, ind)}
                            value={item?.width}
                            disabled={
                              types == "view"
                                ? true
                                : TransportedBy == "sp"
                                ? true
                                : false
                            }
                          />
                          {/* {widths?.length == 0 && widthsErr && (
                            <p className="dc_err">Enter width</p>
                          )} */}
                        </div>
                      </Col>
                      <Col xs={4} lg={1} md={4} style={{ width: "12.3333%" }}>
                        <div className="input-box pe-2 me-2 mb-2">
                          <label>
                            Length{" "}
                            {types !== "view" && <span className="err">*</span>}
                          </label>
                          <input
                            className=""
                            name="length"
                            type={"number"}
                            // onChange={(e) => setLegnths(e.target.value)}
                            onChange={(e) => handleChange(e, ind)}
                            value={item?.length}
                            disabled={
                              types == "view"
                                ? true
                                : TransportedBy == "sp"
                                ? true
                                : false
                            }
                          />
                          {/* {Legnths?.length == 0 && LegnthsErr && (
                            <p className="dc_err">Enter Length</p>
                          )} */}
                        </div>
                      </Col>
                      <Col xs={4} lg={1} md={4} style={{ width: "20.3333%" }}>
                        <div className="input-box pe-2 me-2 mb-2">
                          <label>
                            Height{" "}
                            {types !== "view" && <span className="err">*</span>}
                          </label>
                          <div className="d-flex mt-1">
                            <input
                              className=""
                              type={"number"}
                              name={"height"}
                              // onChange={(e) => setHeights(e.target.value)}
                              onChange={(e) => handleChange(e, ind)}
                              value={item?.height}
                              disabled={
                                types == "view"
                                  ? true
                                  : TransportedBy == "sp"
                                  ? true
                                  : false
                              }
                            />

                            <select
                              className="ms-2 w-100"
                              disabled={
                                types == "view"
                                  ? true
                                  : TransportedBy == "sp"
                                  ? true
                                  : false
                              }
                              value={item?.unit_1}
                              name="unit_1"
                              onChange={(e) => {
                                handleChange(e, ind);
                                setUnit(e.target.value);
                              }}
                            >
                              <option hidden>Select</option>
                              {/* <option value={"CM"}>CM</option>
                              <option value={"Meter"}>METER</option>
                              <option value={"Feet"}>FEET</option>
                              <option value={"Yard"}>YARD</option> */}
                              <option value={11}>CM</option>
                              <option value={25}>METER</option>
                              <option value={50}>FEET</option>
                              <option value={45}>YARD</option>
                            </select>
                          </div>
                          {/* {Heights?.length == 0 && HeightsErr && (
                            <p className="dc_err">Enter Height</p>
                          )} */}
                        </div>
                      </Col>
                      <Col xs={4} lg={1} md={4} style={{ width: "17.3333%" }}>
                        <div className="input-box pe-2 me-2 mb-2">
                          <label>
                            Volume{" "}
                            {types !== "view" && <span className="err">*</span>}
                          </label>
                          <div className="d-flex mt-1">
                            <input
                              className=""
                              type={"number"}
                              name="volume"
                              onChange={(e) => {
                                handleChange(e, ind);
                              }}
                              value={item?.volume}
                              readOnly
                              // disabled={
                              //   types == "view"
                              //     ? true
                              //     : TransportedBy == "sp"
                              //     ? true
                              //     : false
                              // }
                            />

                            <p className="ms-2 w-100 border-bottom">
                              {+item?.unit_1 == 11
                                ? "CUBIC CM"
                                : +item?.unit_1 == 25
                                ? "CUBIC METER"
                                : +item?.unit_1 == 50
                                ? "CUBIC FEET"
                                : +item?.unit_1 == 45
                                ? "CUBIC YARD"
                                : ""}
                            </p>
                            {/* <select
                              className="ms-2 w-100"
                              disabled
                              value={item?.unit_1}
                              name="unit_1"
                              onChange={(e) => handleChange(e, ind)}
                            >
                              <option hidden>Select</option>
                              <option value={11}>CM</option>
                              <option value={25}>METER</option>
                              <option value={50}>FEET</option>
                              <option value={45}>YARD</option>
                            </select> */}
                          </div>
                          {totalVolume?.length == 0 && totalVolumeErr && (
                            <p className="dc_err">Enter No. of Packages</p>
                          )}
                        </div>
                      </Col>
                    </div>{" "}
                    <div className="d-flex">
                      <Col xs={6} lg={3} md={6}>
                        <div className="d-flex">
                          <div className="input-box pe-2 me-2 mb-2 w-100">
                            <label>
                              Net Weight{" "}
                              {types !== "view" && (
                                <span className="err">*</span>
                              )}
                            </label>
                            <div className="d-flex mt-1">
                              <input
                                className=""
                                type={"number"}
                                name={"netWeight"}
                                onChange={(e) => handleChange(e, ind)}
                                value={item?.netWeight}
                                disabled={
                                  types == "view"
                                    ? true
                                    : TransportedBy == "sp"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div className="input-box pe-2 me-2 mb-2 w-100 mt-4">
                            <select
                              className="ms-2 "
                              disabled={
                                types == "view"
                                  ? true
                                  : TransportedBy == "sp"
                                  ? true
                                  : false
                              }
                              name="unit_2"
                              value={item?.unit_2}
                              onChange={(e) => handleChange(e, ind)}
                            >
                              <option hidden>Select</option>
                              <option value={17}>GRAMS</option>
                              <option value={20}>KILOGRAMS</option>
                              <option value={26}>METRIC TON</option>
                              <option value={32}>QUINATL</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3} md={6}>
                        <div className="d-flex">
                          <div className="input-box pe-2 me-2 mb-2 w-100">
                            <label>
                              Gross Weight{" "}
                              {types !== "view" && (
                                <span className="err">*</span>
                              )}
                            </label>
                            <div className="d-flex mt-1">
                              <input
                                className=""
                                type={"number"}
                                name={"grossWeight"}
                                onChange={(e) => handleChange(e, ind)}
                                value={item?.grossWeight}
                                disabled={
                                  types == "view"
                                    ? true
                                    : TransportedBy == "sp"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                          <div className="input-box pe-2 me-2 mb-2 w-100 mt-4">
                            <select
                              className="ms-2 "
                              disabled={
                                types == "view"
                                  ? true
                                  : TransportedBy == "sp"
                                  ? true
                                  : false
                              }
                              name="unit_2"
                              value={item?.unit_2}
                              onChange={(e) => handleChange(e, ind)}
                            >
                              <option hidden>Select</option>
                              {/* <option value={"Grams"}>GRAMS</option>
                              <option value={"Kilograms"}>KILOGRAMS</option>
                              <option value={"Metric Ton"}>METRIC TON</option>
                              <option value={"Quintal"}>QUINATL</option> */}
                              <option value={17}>GRAMS</option>
                              <option value={20}>KILOGRAMS</option>
                              <option value={26}>METRIC TON</option>
                              <option value={32}>QUINATL</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={2} md={6} style={{ width: "12.666667%" }}>
                        <div className="input-box pe-2 me-2 mb-2">
                          <label>
                            Packages{" "}
                            {types !== "view" && <span className="err">*</span>}
                          </label>
                          <input
                            className=""
                            type={"number"}
                            name="packages"
                            // onChange={(e) => setNoOfBox(e.target.value)}
                            onChange={(e) => handleChange(e, ind)}
                            value={item?.packages}
                            disabled={
                              types == "view"
                                ? true
                                : TransportedBy == "sp"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <div>
                        {dimension?.length > 1 && types !== "view" && (
                          <TiDeleteOutline
                            onClick={() => removeImage(item, ind)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}

              <Col xs={8} lg={8} md={8}>
                {types !== "view" && TransportedBy !== "sp" && (
                  <div className="d-flex gap-2 justify-content-end">
                    <button
                      className="cust-btn bg-primary1 mb-2 rounded-1 text-white me-2"
                      style={{ height: "30px", marginTop: "auto" }}
                      onClick={() => addDimension()}
                    >
                      Add +
                    </button>
                    <button
                      className="cust-btn bg-primary1 mb-2 rounded-1 text-white me-2"
                      style={{ height: "30px", marginTop: "auto" }}
                      onClick={() => sumDimension()}
                    >
                      Sum
                    </button>
                  </div>
                )}
              </Col>
              <div className="d-flex">
                <Col xs={6} lg={2} md={6} style={{ width: "12.666667%" }}>
                  <div className="input-box pe-2 me-2 mb-2">
                    <label>
                      No. of Packages{" "}
                      {types !== "view" && <span className="err">*</span>}
                    </label>
                    <input
                      className=""
                      type={"number"}
                      name="packages"
                      onChange={(e) => setNoOfBox(e.target.value)}
                      value={noOfBox}
                      // disabled={
                      //   types == "view"
                      //     ? true
                      //     : TransportedBy == "sp"
                      //     ? true
                      //     : false
                      // }
                      disabled
                    />
                    {noOfBox?.length == 0 && noOfBoxErr && (
                      <p className="dc_err">Enter No. of Packages</p>
                    )}
                  </div>
                </Col>
                <Col xs={6} lg={2} md={4}>
                  <div className="input-box pe-2 me-2 mb-2">
                    <label>
                      Total Net Weight{" "}
                      {types !== "view" && <span className="err">*</span>}
                    </label>
                    <div className="d-flex mt-1">
                      <input
                        className=""
                        type={"number"}
                        name={"netWeight"}
                        onChange={(e) => setNetWeight(e.target.value)}
                        value={netWeight}
                        // disabled={
                        //   types == "view"
                        //     ? true
                        //     : TransportedBy == "sp"
                        //     ? true
                        //     : false
                        // }
                        disabled
                      />
                    </div>
                    {grossWeight?.length == 0 && grossWeightErr && (
                      <p className="dc_err">Enter Gross Weight</p>
                    )}
                  </div>
                </Col>
                <Col xs={6} lg={2} md={6}>
                  <div className="input-box pe-2 me-2 mb-2">
                    <label>
                      Total Gross Weight{" "}
                      {types !== "view" && <span className="err">*</span>}
                    </label>
                    <div className="d-flex mt-1">
                      <input
                        className=""
                        type={"number"}
                        name={"grossWeight"}
                        onChange={(e) => setGrossWeight(e.target.value)}
                        value={grossWeight}
                        // disabled={
                        //   types == "view"
                        //     ? true
                        //     : TransportedBy == "sp"
                        //     ? true
                        //     : false
                        // }
                        disabled
                      />
                      <p className="ms-2 w-50 border-bottom">TONNES</p>
                    </div>
                    {netWeight?.length == 0 && netWeightErr && (
                      <p className="dc_err">Enter Net Weight</p>
                    )}
                  </div>
                </Col>
                <Col xs={6} lg={2} md={6}>
                  <div className="input-box pe-2 me-2 mb-2">
                    <label>
                      Total Volume{" "}
                      {types !== "view" && <span className="err">*</span>}
                    </label>
                    <div className="d-flex mt-1">
                      <input
                        className=""
                        type={"number"}
                        onChange={(e) => setTotalVolume(e.target.value)}
                        value={totalVolume}
                        disabled
                      />
                      <p className="ms-2 w-100 border-bottom">
                        {/* {console.log("unit", unit)} */}
                        {+unit == 11
                          ? "CUBIC CM"
                          : +unit == 25
                          ? "CUBIC METER"
                          : +unit == 50
                          ? "CUBIC FEET"
                          : +unit == 45
                          ? "CUBIC YARD"
                          : ""}
                      </p>
                    </div>
                    {totalVolume?.length == 0 && totalVolumeErr && (
                      <p className="dc_err">Enter No. of Packages</p>
                    )}
                  </div>
                </Col>
              </div>
            </div>
          </Col>
          <div className="package-detail px-4 mt-2">
            <h5>Overall Delivery schedule</h5>
            <div className="d-flex flex-wrap">
              <div className="">
                <div className="input-boxs pe-2 me-2 mb-2">
                  <label>
                    Expected Time of Dispatch(ETD)
                    {types !== "view" && <span className="err">*</span>}
                  </label>

                  <DatePicker
                    setStartDate={setEtd}
                    startDate={etd}
                    disabled={
                      types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : false
                    }
                  />
                  {etd?.length == 0 && etdErr && (
                    <p className="dc_err">Enter ETD</p>
                  )}
                </div>
              </div>
              <div className="">
                <div className="input-boxs pe-2 me-2 mb-2">
                  <label>
                    Expected Time of Arrival(ETA)
                    {types !== "view" && <span className="err">*</span>}
                  </label>
                  {/* <div className="d-flex"> */}
                  <DatePicker
                    setStartDate={setEta}
                    startDate={eta}
                    disabled={
                      types == "view"
                        ? true
                        : TransportedBy == "sp"
                        ? true
                        : false
                    }
                  />
                  {eta?.length == 0 && etaErr && (
                    <p className="dc_err">Enter ETA</p>
                  )}
                  {/* </div> */}
                </div>
              </div>

              <Col className="input-box px-2 mb-2" xs={12} sm={6} lg={4}>
                <label>
                  Ex.Works Readiness
                  {types !== "view" && <span className="err">*</span>}
                </label>

                <DatePicker
                  setStartDate={setExWorks}
                  startDate={exWorks}
                  disabled={
                    types == "view"
                      ? true
                      : TransportedBy == "sp"
                      ? true
                      : false
                  }
                />
                {exWorks?.length == 0 && exWorksErr && (
                  <p className="dc_err">Enter Ex.Works Date</p>
                )}
              </Col>
            </div>
            <h5 className="mt-2">Delivery Term</h5>
            <div className="d-flex">
              <Col
                className="input-box px-2 mb-2"
                xs={6}
                sm={2}
                md={1}
                lg={1}
                style={{ width: "12.333333%" }}
              >
                <label>Mode of Shipment</label>

                <select
                  onChange={(e) => setShipmetId(e.target.value)}
                  value={shipmentId}
                  disabled={
                    types == "view"
                      ? true
                      : TransportedBy == "sp"
                      ? true
                      : false
                  }
                >
                  <option value={""}>Select MOS</option>
                  {shipments?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                {shipmentId?.length == 0 && shipmentIdErr && (
                  <p className="dc_err">select shipment</p>
                )}
              </Col>
              <div className="col-delivery-scheds input-box pe-2 mb-2 me-2">
                <label>Inco Term</label>
                <input
                  className="me-2"
                  value={user?.supplier?.delivery_term?.ReferenceDetailsCode}
                  disabled={
                    types == "view"
                      ? true
                      : TransportedBy == "sp"
                      ? true
                      : false
                  }
                />
              </div>
              <div className="col-delivery-scheds input-box  pe-2 mb-2 me-2">
                <label>Delivery Place</label>
                <select
                  className="me-2"
                  onChange={(e) => setDeleveryTermId(e.target.value)}
                  value={deleveryTermId}
                  disabled={
                    types == "view"
                      ? true
                      : TransportedBy == "sp"
                      ? true
                      : false
                  }
                  style={{ width: "75%" }}
                >
                  <option>Select Delivery</option>
                  {addressList?.map((item, ind) => (
                    // <option value={item?.id} key={ind}>
                    //   {item?.address1}
                    // </option>
                    <option value={item?.PlantID} key={ind}>
                      {item?.AddressLine1}
                    </option>
                  ))}
                </select>
                {/* <div className="d-flex justify-content-end mt-2">
                  <p className="mx-5">
                    {types !== "view" && TransportedBy !== "sp" && (
                      <button
                        className="mx-5 ad-depart_new"
                        onClick={() => setOpen(true)}
                        disabled={
                          types == "view"
                            ? true
                            : TransportedBy == "sp"
                            ? true
                            : false
                        }
                      >
                        Add Delivery Place
                      </button>
                    )}
                  </p>
                </div> */}
              </div>
              {types == "add" && (
                <div className="my-2">
                  <label className="">
                    Transport By
                    {types !== "view" && <span className="err">*</span>}
                  </label>
                  <input
                    className="me-2 mx-3"
                    type="radio"
                    id="specifyColor"
                    name="radio1"
                    value="GFG"
                    onChange={(e) => setTransportBy("kh")}
                    checked={transportBy == "kh" ? true : false}
                    // disabled={types == "add" ? false : true}
                  />
                  <label className="me-2">KH</label>
                  <input
                    className="me-2"
                    type="radio"
                    id="specifyColor"
                    name="radio1"
                    value="GFG"
                    onChange={(e) => setTransportBy("sp")}
                    checked={transportBy == "sp" ? true : false}
                    // disabled={types == "add" ? false : true}
                  />
                  <label>Supplier</label>
                </div>
              )}
            </div>
            <div className="d-sm-flex me-4 justify-content-between align-items-center">
              <p className="fs-13 mb-2">
                Note: Once you save the DC you will fill the shipping stages
              </p>
              {types == "view" ? null : types !== "edit" &&
                showRightDc ? null : (
                <div className="d-flex mb-2 gap-3">
                  <button className="cust-btn bg-lit-primary4 me-3 rounded-1">
                    Clear
                  </button>
                  <button
                    className="clear-btn  px-4 py-1 fnt-fam-f2 border-0 rounded-1 opac_half"
                    onClick={() => saveDraftHandler()}
                  >
                    Save as Draft
                  </button>
                  <button
                    className="cust-btn bg-primary1 text-white rounded-1"
                    // onClick={dcClickHandler}
                    onClick={submitHandle}
                    disabled={btn ? true : false}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <Modal
              className="border-0 border"
              id="#Save-terms"
              show={showModal}
              size="sm"
              centered
              onHide={() => setShowModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="sup-modal pt-3">
                <img className="fav-icon ms-2" src={FavLogo} />
                <div className="mt-0">
                  <div className="d-flex-as-jc img_tick mt-3">
                    {/* <img src={Check} /> */}
                    <div className="px-1 ms-2 mb-3">
                      <h5 className="d-flex gap-2 align-items-center mx-3">
                        Create shipping stages
                        <BsExclamationCircle className="primary2" />
                      </h5>
                      <p className="w-90 mt-2">
                        Do you want to update the shipping status now ?
                      </p>
                    </div>
                  </div>
                  <div className="d-flex bg-light-blue px-2 justify-content-end">
                    <button
                      className="my-2 cust-btn bg-lit-primary4 me-2"
                      onClick={(e) => dcModalYesHandlers(e)}
                    >
                      No
                    </button>
                    <button
                      className="my-2 cust-btn bg-primary1 text-white"
                      onClick={(e) => dcModalYesHandler(e)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {/* <Col xs={12} md={4} lg={4}> */}

        {stageShow ? (
          <>
            <div className={` ${showRight ? "rit-dc-stage" : "rit-dc-stage1"}`}>
              <div
                onClick={showRightHandler}
                className="orange-arw d-none d-md-flex"
              >
                {showRight ? (
                  <i class="fa-solid fa-chevron-right"></i>
                ) : (
                  <i class="fa-solid fa-chevron-left"></i>
                )}
              </div>
              {showRightDc && (
                <RightDc
                  shipments={shipments}
                  shipmentId={shipmentId}
                  asnTable={asnTable}
                  asnCreates={asnCreates}
                  data={checkbox ? checkbox : data}
                  list={list}
                  location={location}
                />
              )}
            </div>
          </>
        ) : null}
      </div>

      <Modal
        id="#sucess"
        className="otp-modal"
        show={capModal}
        size="md"
        centered
        onHide={() => setCapModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div
          className="new-modal"
          style={{ border: "1px solid gainsboro", borderRadius: "3px" }}
        >
          <Image className="fav-icon" src={FavLogo} />
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="px-2">
              <h5 className="fs-14" style={{ margin: "0" }}>
                Please Fill All Value
              </h5>
            </div>
          </div>

          <div className="d-flex-as-jc img_tick mb-2">
            <button
              className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
              onClick={() => setCapModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>

      {/* FOR ADD DELIVERY */}
      <Modal
        centered
        show={open}
        onHide={() => setOpen(false)}
        className="depart-design-input-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {" "}
            Delivery Add
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="descrioption-input">
          <div className="my-3">
            <Form>
              <div className="d-flex-wrap">
                <Col md={6} xs={12} className="pe-3 my-2">
                  <label>
                    {" "}
                    Type<span className="red-text">*</span>
                  </label>
                  <select
                    className="form-select "
                    onChange={(e) => setTypeDel(e.target.value)}
                    value={typeDel}
                  >
                    <option hidden>Select Type</option>
                    {listDelivery?.branches?.map((item) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </select>
                  {typeDel?.length == 0 && typeDelErr && (
                    <p className="err-input2 pe-3">Select Type</p>
                  )}
                </Col>
                <Col md={6} xs={12} className="pe-3 my-2">
                  <label>
                    Country<span className="red-text">*</span>
                  </label>
                  <select
                    className="form-select "
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    value={country}
                  >
                    <option hidden>Select Country</option>
                    {listDelivery?.countries?.map((item) => (
                      <option value={item?.country_id}>
                        {item?.country_name}
                      </option>
                    ))}
                  </select>
                  {country?.length == 0 && countryErr && (
                    <p className="err-input2 pe-3">Select Country</p>
                  )}
                </Col>
                <Col md={6} xs={12} className="pe-3 my-2">
                  <label>
                    State<span className="red-text">*</span>
                  </label>
                  <select
                    className="form-select "
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                  >
                    <option hidden>Select State</option>
                    {listDelivery?.states?.map((item) => (
                      <option value={item?.state_id}>{item?.state_name}</option>
                    ))}
                  </select>
                  {state?.length == 0 && stateErr && (
                    <p className="err-input2 pe-3">Select State</p>
                  )}
                </Col>

                <Col md={6} xs={12} className="pe-3 my-2">
                  <label>
                    City<span className="red-text">*</span>
                  </label>
                  <select
                    className="form-select "
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  >
                    <option>Select City</option>
                    {listDelivery?.cities?.map((item) => (
                      <option value={item?.city_id}>{item?.city_name}</option>
                    ))}
                  </select>
                  {city?.length == 0 && cityErr && (
                    <p className="err-input2 pe-3">Select City</p>
                  )}
                </Col>
                <Col xs={12} className="pe-3 my-2">
                  <label>
                    Address line 1 <span className="red-text">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    onChange={(e) => setAdd1(e.target.value)}
                    value={add1}
                  />
                  {add1?.length == 0 && add1Err && (
                    <p className="err-input2 pe-3">Enter Address 1</p>
                  )}
                </Col>
                <Col xs={12} className="pe-3 my-2">
                  <label>Address line 2</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    onChange={(e) => setAdd2(e.target.value)}
                    value={add2}
                  />
                  {add2?.length == 0 && add2Err && (
                    <p className="err-input2 pe-3">Enter Address 2</p>
                  )}
                </Col>
                <Col xs={12} className="pe-3 my-2">
                  <label>Address line 3</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    onChange={(e) => setAdd3(e.target.value)}
                    value={add3}
                  />
                  {add3?.length == 0 && add3Err && (
                    <p className="err-input2 pe-3">Enter Address 3</p>
                  )}
                </Col>

                <Col md={6} xs={12} className="pe-3 my-2">
                  <label>Mail ID</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder=""
                    onChange={(e) => setMailId(e.target.value)}
                    value={mailId}
                  />
                  {mailId?.length == 0 && mailIdErr && (
                    <p className="err-input2 pe-3">Enter Mail ID</p>
                  )}
                </Col>

                <Col md={6} xs={12} className="pe-3 my-2 d-flex">
                  <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <label>
                      Contact Number <span className="red-text">*</span>
                    </label>
                    <PhoneInput
                      inputStyle={{ width: "auto" }}
                      country="in"
                      onChange={(e) => setPhone(e)}
                      value={phone}
                    />
                  </div>
                  <div className="px-2">
                    <label>
                      Ext
                      {/* <span className="red-text">*</span> */}
                    </label>
                    <input
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        if (e.target.value.length !== 3) {
                          e.target.setCustomValidity("invalid Ext");
                        } else if (e.target.value.length == 3) {
                          e.target.setCustomValidity("");

                          setExt(e.target.value);
                        }
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      maxlength={3}
                      onChange={(e) => setExt(e.target.value)}
                      type="number"
                      value={ext}
                      className="form-control"
                      placeholder="Ext"
                    />
                  </div>
                </Col>
                <Col md={6} xs={12} className="pe-3 my-2">
                  <label>
                    Fax
                    {/* <span className="red-text">*</span> */}
                  </label>
                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Ext");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setFax(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={10}
                    onChange={(e) => setFax(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Fax"
                    value={fax}
                  />
                </Col>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <div className="px-3 w-100 d-flex justify-content-end">
          <button
            className="cust-btn bg-primary1 py-2 text-white "
            disabled={deliveryBtn ? true : false}
            onClick={submitHandleDelivery}
          >
            Add
          </button>
        </div>
      </Modal>
    </Col>
  );
};

export default DeliveryChallanCreationNew;
