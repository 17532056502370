import { useNavigate } from "react-router-dom";

function QuoLastSectionBtn({
  setSaveTerms,
  BtnText,
  BtnSize,
  customFunction,
  saveDraftHandler,
}) {
  const navigate = useNavigate();
  const onClickHandler = () => {
    customFunction();
    if (setSaveTerms) setSaveTerms(true);
  };
  const BUTTONLARGE = "wid-9_5r";
  const BUTTONNORMAL = "wid-5r";

  return (
    <div className="d-flex justify-content-end gap-2 flex-wrap my-3 py-2">
      <button
        className="text-blue fnt-fam-f2  border-0 me-2 bg-light px-3 py-1 border-0 ms-1"
        onClick={() => navigate(-1)}
      >
        Close
      </button>
      {/* <button className="wid-9r rounded-1 fnt-fam-f2 wid-9_5r h-2_5r blue-trans-button">
        Save as Draft
      </button> */}
      <button
        className={`bg-primary1 fnt-fam-f2 ms-2 text-white px-3 py-1 border-0 ms-1`}
        onClick={() => saveDraftHandler()}
      >
        Save as Draft
      </button>
      <button
        className={`bg-primary1 fnt-fam-f2 ms-2 text-white px-3 py-1 border-0 ms-1`}
        onClick={onClickHandler}
      >
        {BtnText}
      </button>
    </div>
  );
}

export default QuoLastSectionBtn;
