import GeneralTermsAndCondition from "../Terms&Condition";
function EnqTermsAndCondition() {
  return (
    <div className="m-hgt-70vh">
      <GeneralTermsAndCondition />
      <button className="bg-primary1 text-white border-0 fnt-fam-f2 px-3 py-1 float-end">
        Close
      </button>
    </div>
  );
}

export default EnqTermsAndCondition;
