import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
function AsnAddExtraContainer() {
  const [currentBtnDisplay, setCurrentBtnDisplay] = useState("save");
  const [showDropdown, setShowDropDown] = useState("");

  const asnDropHandler = (ind) => {
    // console.log(ind);
    if (showDropdown === ind) {
      setShowDropDown("");
    } else {
      setShowDropDown(ind);
    }
  };

  const asnDropChangeHandler = (e) => {
    console.log(e.target);
  };

  const asnSaveClick = () => setCurrentBtnDisplay("submit");
  return (
    <>
      <div className="border-btm">
        <div className="asn-add-inner-cont-res my-3 py-2">
          <div className="d-sm-flex flex-wrap gap-3 align-items-center">
            <p className="fs-13px wid-110px fnt-fam-f1 my-1 fw-bold">
              No of Packages
            </p>
            <div className="cont-border my-1 fs-13px d-sm-flex gap-1 justify-content-center fnt-fam-f1 rounded-1 asn-add-show--inner-cont">
              <select style={{ appearance: "none" }} className="border-0 m-0">
                <option>Number</option>
              </select>
              <BsChevronDown
                style={{
                  padding: "5px 0 0 0",
                }}
              />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1  rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
          </div>
          <div className="d-sm-flex flex-wrap gap-3 align-items-center">
            <p className="fs-13px wid-110px fnt-fam-f1 my-1 fw-bold">
              Total Gross Weight
            </p>
            <div className="cont-border my-1 fs-13px d-sm-flex gap-1 justify-content-center fnt-fam-f1 rounded-1 asn-add-show--inner-cont">
              <select style={{ appearance: "none" }} className="border-0 w-90">
                <option>Kgs</option>
              </select>
              <BsChevronDown
                style={{
                  padding: "5px 0 0 0",
                }}
              />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="500" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="500" className="border-0 w-90" />
            </div>
            <div className="cont-border my-1 ps-2 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="600" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="150" className="border-0 w-90" />
            </div>
          </div>
          <div className="d-sm-flex flex-wrap gap-3 align-items-center">
            <p className="fs-13px wid-110px fnt-fam-f1 my-1 fw-bold">
              Total Net Weight
            </p>
            <div
              className="cont-border position-relative my-1 fs-13px d-sm-flex gap-1 justify-content-between px-2 fnt-fam-f1 rounded-1 asn-add-show--inner-cont"
              onClick={() => asnDropHandler(3)}
            >
              <p className="">Kgs</p>
              {showDropdown === 3 && (
                <ul className="position-absolute asn-drop-down cont-border pointer">
                  <li className="pad-s-7px" onClick={asnDropChangeHandler}>
                    Kgs
                  </li>
                  <li className="pad-s-7px" onClick={asnDropChangeHandler}>
                    Lbs
                  </li>
                  <li className="pad-s-7px" onClick={asnDropChangeHandler}>
                    Tones
                  </li>
                </ul>
              )}

              <BsChevronDown
                style={{
                  padding: "5px 0 0 0",
                }}
              />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="700" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="600" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="500" className="border-0 w-90" />
            </div>
            <div className="cont-border my-1 ps-2 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="200" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="600" className="border-0 w-90" />
            </div>
          </div>
          <div className="d-sm-flex flex-wrap gap-3 align-items-center">
            <p className="fs-13px wid-110px fnt-fam-f1 my-1 fw-bold">
              Total Volume
            </p>
            <div
              className="cont-border my-1 fs-13px d-sm-flex gap-1 position-relative justify-content-between px-2 fnt-fam-f1 rounded-1 asn-add-show--inner-cont"
              onClick={() => asnDropHandler(4)}
            >
              <p className="">m3</p>
              {showDropdown === 4 && (
                <ul className="position-absolute asn-drop-down cont-border pointer">
                  <li className="pad-s-7px" onClick={asnDropChangeHandler}>
                    m3
                  </li>
                  <li className="pad-s-7px" onClick={asnDropChangeHandler}>
                    m2
                  </li>
                  <li className="pad-s-7px" onClick={asnDropChangeHandler}>
                    Qf
                  </li>
                </ul>
              )}
              <BsChevronDown
                style={{
                  padding: "5px 0 0 0",
                }}
              />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-100" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border my-1 ps-2 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
            <div className="cont-border ps-2 my-1 rounded-1 asn-add-show--inner-cont">
              <input type="text" placeHolder="10" className="border-0 w-90" />
            </div>
          </div>
          <div className="d-sm-flex flex-wrap gap-3 align-items-center">
            <div style={{ width: "600px" }}></div>

            <div className="my-1 border-0 asn-add-show--inner-cont">
              {currentBtnDisplay === "save" && (
                <button
                  className="cust-btn bg-primary1 text-white"
                  onClick={asnSaveClick}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <h5 className="fs-14px mb-1 py-1 mt-3 primary1 fnt-fam-f1">
        Total Packaging Details
      </h5>
      <Col
        lg={8}
        md={12}
        xs={12}
        className="d-md-flex gap-5 my-1 py-1 flex-wrap"
      >
        <p className="my-1">No of Packages: 2900</p>
        <p className="my-1">Total Gross Weight: 2500 Kgs</p>
        <p className="my-1">Total Net Weight: 2900</p>
        <p className="my-1">Total Volume: 5000sqf</p>
      </Col>
      <h5 className="fs-14px primary1 my-1 py-1 fnt-fam-f1">
        Overall Delivery Schedule
      </h5>
      <div className="d-sm-flex flex-wrap">
        <Col
          lg={9}
          md={12}
          xs={12}
          className="d-md-flex gap-5 my-1 py-1 flex-wrap"
        >
          <p className="my-1">Expected Time of Dispatched(ETD):10-01-2023</p>
          <p className="my-1">Expected Time of Arrival(ETA):12-01-2023</p>
        </Col>

        <Col
          lg={2}
          md={12}
          xs={12}
          className="d-md-flex gap-5 my-1 py-1 flex-wrap ps-lg-3 ms-lg-3"
        >
          {currentBtnDisplay === "submit" && (
            <Link to="/asn-listing">
              <button className="cust-btn bg-primary1 text-white">
                Submit
              </button>
            </Link>
          )}
        </Col>
      </div>
    </>
  );
}

export default AsnAddExtraContainer;
