import React from "react";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import Filter from "../../../assets/Icons/Normal Icons/Filter.svg";
import { RxFilePlus } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
function SpFilterSec({ showRightDc }) {
  return (
    <div
      className={
        showRightDc === false
          ? "d-sm-flex justify-content-between mb-0 flex-wrap align-items-center shoe-acces-filter wid-150perc"
          : "d-sm-flex justify-content-between mb-0 flex-wrap align-items-center shoe-acces-filter"
      }
    >
      <div className="d-flex-wrap align-items-center gap-1 mb-0">
        <label>Selected Item:</label>
        <label>15</label>
      </div>
      <div className="d-flex flex-wrap  mt-sm-0">
        <div
          style={{ height: "34px" }}
          className="search-box grn-search-bar d-flex gap-2 mb-2 align-items-center px-2 rounded-1"
        >
          <input placeholder="Search Title" className="w-90" />
          <img className="search-icon" src={Search} />
        </div>
        <div className="d-flex gap-2 flex-wrap me-2 pe-0">
          <button
            style={{ height: "34px" }}
            className="grn-filter-btn bg-primary2 d-flex align-items-center"
          >
            <img src={Filter} className="wid-15px me-1" />
            Filter
          </button>
        </div>
      </div>
    </div>
  );
}

export default SpFilterSec;
