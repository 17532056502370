import { RxTriangleUp } from "react-icons/rx";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import React, { useState } from "react";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import { RiDeleteBack2Line } from "react-icons/ri";
import { BsExclamationCircle } from "react-icons/bs";
import ViewDetails from "../../assets/Icons/Normal Icons/ViewDetails.svg";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Col } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
function EnquiryViewScopeTable() {
  const [showModel, setShowModel] = useState(false);

  const modelHideHandler = () => setShowModel(false);

  const modelShowHandler = () => setShowModel(true);
  return (
    <div className="my-5 py-2 mx-0">
      <div className="d-flex mb-3  float-end ">
        <div className="search-box d-sm-flex gap-2 align-items-center rounded-1 w-100 opac_half">
          <input
            style={{ marginLeft: "2px" }}
            placeholder="Search Title"
            className="d-inline-block"
          />
          <img className="search-icon" src={Search} />
        </div>
        <button className="filter-btn bg-primary2">
          <img src={Filter} className="wid-15px me-1" />
          Filter
        </button>
      </div>

      <Col lg={12} md={12} sm={12}>
        <div className="tbale-scroll-horizontal w-100">
          <table className="my-3 py-2 table">
            <thead>
              <tr className="fnt-fam-f3 opac-85 ">
                <th className="text-center">Sl.No </th>
                <th className="text-nowrap text-center pd_03r">
                  Supplier Material Code
                  <RiArrowDropDownLine className="fs-3" />
                </th>
                <th className="pd_03r text-center">
                  KH Material SKU <RiArrowDropDownLine className="fs-3" />
                </th>
                <th className="text-center">HSN</th>
                <th className="text-center">UOM </th>
                <th className="text-center">Quantity</th>
                <th className="text-nowrap text-center pd_03r">
                  Order Purpose
                  <span>
                    <RiArrowDropDownLine className="fs-3" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="fs-6 opac-80">
              <tr>
                <td className="text-center">01</td>
                <td className="text-center">Mark</td>
                <td>
                  <p className=" my-1">
                    R95CH456789
                    <span className="font-col-sec">
                      <img src={ViewDetails} className="wid-15px ms-1" />
                    </span>
                  </p>
                  <p className="text-wrap my-1  wid_15">
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a
                  </p>
                </td>
                <td className="text-center">B$5678945</td>
                <td className="text-center">Mark</td>
                <td className="text-center">Otto</td>
                <td className="text-center">@mdo</td>
              </tr>
              <tr>
                <td className="text-center">02</td>
                <td className="text-center">Mark</td>
                <td>
                  <div className="my-1 position-relative">
                    R95CH456789{" "}
                    <span className="font-col-sec" onClick={modelShowHandler}>
                      <img src={ViewDetails} className="wid-15px ms-1" />
                    </span>
                    {showModel && (
                      <div className="enq-vie-tab-model-box p-3 ">
                        <p className="enq-view-modelbox-arrow-cont ">
                          <RxTriangleUp />
                        </p>
                        <div className="d-flex justify-content-between border-btm pb-2">
                          <p className="fnt-fam-f3 fs-5 opac-85 d-flex gap-2 align-items-center">
                            More Details <BsExclamationCircle />
                          </p>
                          <p
                            className="fnt-fam-f3 fs-5"
                            onClick={modelHideHandler}
                          >
                            <RiDeleteBack2Line />
                          </p>
                        </div>
                        <div className="d-flex">
                          <Col xs={5} className="bor-right">
                            <p className="py-1 my-1">
                              <span className="opac_half">REF ID : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">REF ID : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">KH Division : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">KH Division : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">Issued By : </span>
                              REQ12345
                            </p>
                          </Col>
                          <Col cs={7} className="px-2">
                            <p className="py-1 my-1">
                              <span className="opac_half">REF ID : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">REF ID : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">KH Division : </span>
                              REQ12345
                            </p>
                            <p className="py-1 my-1">
                              <span className="opac_half">Issued By : </span>
                              REQ12345
                            </p>
                          </Col>
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="text-wrap my-1 wid_15">
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a
                  </p>
                </td>
                <td className="text-center">B$5678945</td>
                <td className="text-center">Mark</td>
                <td className="text-center">Otto</td>
                <td className="text-center">@mdo</td>
              </tr>
              <tr>
                <td className="text-center">03</td>
                <td className="text-center">Mark</td>
                <td>
                  <p className=" my-1">
                    R95CH456789
                    <span className="font-col-sec">
                      <img src={ViewDetails} className="wid-15px ms-1" />
                    </span>
                  </p>
                  <p className="text-wrap my-1  wid_15">
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a
                  </p>
                </td>
                <td className="text-center">B$5678945</td>
                <td className="text-center">Mark</td>
                <td className="text-center">Otto</td>
                <td className="text-center">@mdo</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
      <div className="d-sm-flex justify-content-between mb-2 align-items-center">
        <div className="d-flex-wrap align-items-center gap-2 mb-0">
          <label>Show</label>
          <select name="" className="dropdown-btm-grey px-2 py-1">
            <option value="5">10</option>
            <option value="10">30</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
          <label>1-5 of 20</label>
        </div>
        <div className="d-flex mt-2 mt-sm-0 enquiry-page-cont">
          <Pagination>
            <Pagination.First className="enquiry-pag-list" />
            <Pagination.Prev className="enquiry-pag-list" />
            <Pagination.Next className="enquiry-pag-list" />
            <Pagination.Last className="enquiry-pag-list" />
          </Pagination>
        </div>
      </div>
    </div>
  );
}

export default EnquiryViewScopeTable;
