import React, { useState, useEffect } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { CompanyImg } from "../assets/img";
import LeftAddUser from "./EnterpriseAdmin/LeftAddUser";
import { api } from "../constants/Services";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import ErrorHandler from "../constants/Services/Api/errorHandler";
import moment from "moment/moment";
import ErrorModel from "../constants/Services/Api/errorModel";
import TopBar from "../navbar/TopBar";
import useUser from "../constants/Storage/userData";
import useProfileStatus from "../constants/Storage/userStauts";
import DatePicker from "../layout/datePicker";

const fileTypes = ["JPEG", "PNG", "GIF"];
const AddCertiAward = ({ type, id }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profileStatus, setProfileStatus } = useProfileStatus();
  const { user } = useUser();
  const types = location?.state?.type;
  const method = location?.state?.method;
  const Id = location?.state?.id;
  // console.log("location", method);
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [valididtyToggle, setValididtyToggle] = useState(false);

  const [certificate, setCetificate] = useState("");
  const [issuesBy, setIssuesBy] = useState("");
  const [issuesOn, setIssuesOn] = useState(new Date());
  const [validity, setValididty] = useState(new Date());
  const [image, setImage] = useState([]);
  const [imageUrl, setimageUrl] = useState([]);

  const certificate_awardView = () => {
    api.certificate_awardView(Id).then((res) => {
      let imagesDoc = [];
      if (res?.status == "success") {
        setCetificate(res?.certificate_award?.title);
        setIssuesBy(res?.certificate_award?.issued_by);
        setValididtyToggle(
          res?.certificate_award?.is_lifetime_valid == "2" ? true : false
        );

        setIssuesOn(
          res?.certificate_award?.issued_on
            ? new Date(res?.certificate_award?.issued_on)
            : ""
        );
        setValididty(
          res?.certificate_award?.validity
            ? new Date(res?.certificate_award?.validity)
            : ""
        );

        if (res?.certificate_award?.document1) {
          imagesDoc.push(res?.certificate_award?.document1);
        }

        if (res?.certificate_award?.document2) {
          imagesDoc.push(res?.certificate_award?.document2);
        }

        if (res?.certificate_award?.document3) {
          imagesDoc.push(res?.certificate_award?.document2);
        }
        setImage(imagesDoc);
        setimageUrl(imagesDoc);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (Id) {
      certificate_awardView();
    }
  }, []);

  // DRAG AND DROP IMAGE

  const [uploadImage, setUploadImage] = useState();

  const imageUploadHandler = (e) => {
    setUploadImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files);
  };

  // VALIDATION

  const [certificateErr, setCetificateErr] = useState(false);
  const [issuesByErr, setIssuesByErr] = useState(false);
  const [issuesOnErr, setIssuesOnErr] = useState(false);
  const [ImageErr, setImageErr] = useState(false);

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const onChangeUpload = (e) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let upload_view = [];
      let upload = [];
      temp?.map((url) => {
        upload_view.push({
          url: URL.createObjectURL(url),
          name: url?.name,
          id: "",
          type: "edit",
        });
        upload.push(url);
      });
      setImage(upload);
      setimageUrl((upload_edit) => [...upload_edit, ...upload_view]);
    }
  };

  // console.log("image", image);

  const removeImage = (id, index) => {
    let temp = [...imageUrl];
    let temps = [...image];
    temp.splice(index, 1);
    temps.splice(index, 1);
    setimageUrl(temp);
    setImage(temps);
  };

  const skipHandle = () => {
    let formdata = new FormData();
    formdata.append("supplier_id", user?.supplier?.SupplierID);
    formdata.append("type", 3);
    api.skipUpdate(formdata).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setProfileStatus("3");
        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
        }, 1500);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const submitHandle = () => {
    if (
      certificate?.length == 0 ||
      issuesBy?.length == 0 ||
      issuesOn?.length == 0
      //   Image?.length == 0
    ) {
      setCetificateErr(true);
      setIssuesByErr(true);
      setIssuesOnErr(true);
      //   setImageErr(true);
    } else {
      if (Id) {
        let formData = new FormData();
        if (method == "award") {
          formData.append("type", 2);
        } else {
          formData.append("type", 1);
        }
        formData.append("title", certificate);
        formData.append("issued_by", issuesBy);
        formData.append("issued_on", moment(issuesOn).format("YYYY-MM-DD"));

        if (method == "certificate") {
          formData.append(
            "is_lifetime_valid",
            valididtyToggle == false ? 1 : 2
          );

          if (valididtyToggle != false) {
            formData.append(
              "valid_till",
              moment(validity).format("YYYY-MM-DD")
            );
          }
        } else if (method == "award") {
          formData.append("is_lifetime_valid", 2);
        }

        // if (image) {
        //   image.map((item, ind) => {
        //     formData.append(`document${ind + 1}`, item);
        //   });
        // }

        if (image) {
          if (image?.length == 1) {
            image.map((item, ind) => {
              formData.append(`document${ind + 1}`, item);
              formData.append("document[2]", "");
              formData.append("document[3]", "");
            });
          } else if (image?.length == 2) {
            image.map((item, ind) => {
              formData.append(`document${ind + 1}`, item);
              formData.append("document[3]", "");
            });
          } else if (image?.length == 3) {
            image.map((item, ind) => {
              formData.append(`document${ind + 1}`, item);
            });
          }
        }

        formData.append("_method", "PUT");

        api.certificate_awardEdit(Id, formData).then((res) => {
          if (res?.status == "success") {
            setError(res);
            setErrorBox(true);
            setTimeout(() => {
              navigate("/mcprofile/certificate");
            }, 1000);
          } else {
            setError(res);
            setErrorBox(true);
          }
        });
      } else if (!Id) {
        let formData = new FormData();
        if (method == "award") {
          formData.append("type", 2);
        } else {
          formData.append("type", 1);
        }
        formData.append("title", certificate);
        formData.append("issued_by", issuesBy);
        formData.append("issued_on", moment(issuesOn).format("YYYY-MM-DD"));

        if (method == "certificate") {
          formData.append(
            "is_lifetime_valid",
            valididtyToggle == false ? 1 : 2
          );

          if (valididtyToggle != false) {
            formData.append(
              "valid_till",
              moment(validity).format("YYYY-MM-DD")
            );
          }
        } else if (method == "award") {
          formData.append("is_lifetime_valid", 2);
        }

        if (image) {
          image.map((item, ind) => {
            formData.append(`documents[${ind}]`, item);
          });
        }

        api.certificate_awardAdd(formData).then((res) => {
          if (res?.status == "success") {
            setError(res);
            setErrorBox(true);
            setTimeout(() => {
              navigate("/mcprofile/certificate");
            }, 1000);
          } else {
            setError(res);
            setErrorBox(true);
          }
        });
      }
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
    // console.log("err-close");
  };

  // console.log("toggle", valididtyToggle == false ? 1 : 2);

  return (
    <div className="reg-tag min-ht-80">
      <TopBar />
      {/* {error && <ErrorHandler error={error} />} */}
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Link to="/mcprofile/certificate" className="add_reg">
        <HiArrowNarrowLeft /> {type == "add" ? "Add" : "Edit"}{" "}
        {method == "award" ? "Award" : "Certificates"}
      </Link>
      <div className="my-3">
        <Form>
          <div className="d-flex-wrap">
            <Col xs={12} className="pe-3 my-2">
              <label>
                {method == "award" ? "Award" : "Certificate"} Types
                <span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder={
                  method == "award" ? "Enter Award" : "Enter Certificate"
                }
                onChange={(e) => setCetificate(e.target.value)}
                value={certificate}
              />
              {certificate?.length == 0 && certificateErr && (
                <span className="red-text">
                  Enter {method == "award" ? "Award" : "Certificate"} Type
                </span>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Issue By<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter global Logistics"
                onChange={(e) => setIssuesBy(e.target.value)}
                value={issuesBy}
              />
              {issuesBy?.length == 0 && issuesByErr && (
                <span className="red-text">Enter Issue By</span>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Issue On<span className="red-text">*</span>
              </label>
              <div className="form-control">
                <DatePicker
                  startDate={issuesOn}
                  setStartDate={setIssuesOn}
                  placeholder={"Select Date"}
                />
              </div>
              {issuesOn?.length == 0 && issuesOnErr && (
                <span className="red-text">Enter Issue On</span>
              )}
            </Col>

            {method == "certificate" ? (
              <>
                <Col md={6} xs={12} className="pe-3 my-2 dat-show">
                  <label>Validity</label>
                  <div class="material-switch pull-right">
                    <input
                      id="someSwitchOptionSuccess"
                      name="someSwitchOption001"
                      type="checkbox"
                      onChange={() => setValididtyToggle(!valididtyToggle)}
                      checked={valididtyToggle ? true : false}
                    />
                    <label
                      for="someSwitchOptionSuccess"
                      class="label-success"
                    ></label>
                  </div>
                </Col>
                {valididtyToggle && (
                  <Col md={6} xs={12} className="pe-3 my-2">
                    <label>
                      {" "}
                      <span className="red-text"></span>
                    </label>
                    <div className="form-control">
                      <DatePicker
                        startDate={validity}
                        setStartDate={setValididty}
                        placeholder={"Validity date"}
                      />
                    </div>
                  </Col>
                )}
              </>
            ) : null}

            {/* <Col xs={12} className="pe-3 my-2 file_upload">
              <label>
                Related Documents <span className="red-text">*</span>
              </label>
              <span className="ab-des" handleChange={handleChange}>
                Drag and drop your files here or Upload a file
              </span>

              <FileUploader
                multiple={true}
                name="file"
                types={fileTypes}
                onChange={(e) => setImage(e)}
              />

              <span>
                Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to
                upload maximum of 1 upload
              </span>
            </Col> */}

            {/* <Col xs={12} className="pe-3 my-2 file_upload">
              <p className="primary1">
                <label>
                  Related Documents <span className="red-text">*</span>
                </label>
              </p>
              <input
                className="custom-file-tech-bank-new"
                type="file"
                onChange={(e) => setImage(e.target.value)}
                // value={upload}
              />
              <p className="blue-fnt opac-half">
                Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to
                upload maximum of 1 upload
              </p>
            </Col> */}
            <Col className="my-3 my-2 file_upload" xs={12}>
              <div className="prod-inf my-2">
                Related Documents <span className="red-text">*</span>
              </div>
              <Col>
                <p className="primary1"></p>
                <input
                  className="custom-file-tech-bank-new"
                  type="file"
                  multiple
                  onChange={onChangeUpload}
                  // value={upload}
                />
                <p className="blue-fnt opac-half">
                  Note: Format: jpg, jpeg, png. Upload upto 5MB each file
                  allowed to upload maximum of 3 file
                </p>
              </Col>
              {imageUrl?.map((item, ind) => (
                <div className="d-flex gap-1 my-4" key={ind}>
                  <div className="d-flex align-items-center pdf-icon-cont">
                    <AiOutlineFilePdf />
                  </div>
                  <p className="d-flex align-items-center">{"document"}</p>
                  <TiDeleteOutline onClick={() => removeImage(ind)} />
                </div>
              ))}
            </Col>
          </div>
        </Form>
      </div>

      <div div className="pe-3 my-2 d-flex-al-je ben_design">
        <Link to="/dashboard" className="btn brn-can">
          Cancel
        </Link>
        {method == "certificate" && type == "add" && profileStatus == "2" && (
          <Button
            className=" btn brn-can text-dark"
            onClick={() => skipHandle()}
          >
            Skip & Submit
          </Button>
        )}
        <Button className=" btn sub-btn" onClick={submitHandle}>
          {type == "add" ? "Save & Submit" : "Edit"}
        </Button>
      </div>
    </div>
  );
};
export default AddCertiAward;
