// @ts-nocheck
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import UserCard from "../../component/EnterpriseAdmin/Card";
import { TbEdit } from "react-icons/tb";
import DropDown from "../../component/EnterpriseAdmin/DropDown";
import Pagination from "react-bootstrap/Pagination";
import LogInFooter from "../../logincomponent/LogInFooter";
import Dropdown from "react-bootstrap/Dropdown";
import { RxDotsVertical } from "react-icons/rx";
import { RxFile, RxFilePlus } from "react-icons/rx";
import { Link } from "react-router-dom";
import { Button, Modal, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { api } from "../../constants/Services";
import Loader from "../../component/Loader";
import ErrorModel from "../../constants/Services/Api/errorModel";

function EnterPriseDepartment() {
  const [departmentList, setDepartmentList] = useState([]);
  const [name, setName] = useState("");
  const [editId, setEditId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [loader, setLoader] = useState(false);

  const edit = (id) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("_method", "PUT");
    api.departmentEdit(id, formData).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setLoader(false);
        getList();
      } else {
        setError(res);
        setErrorBox(true);
        setLoader(false);
      }
      // console.log("departmentEdit", res);
    });
  };

  const department_Update = () => {
    edit(editId);
    setShowModal(false);
  };

  const deparmentEditClickHandler = (id, name) => {
    setName(name);
    setEditId(id);
    setShowModal(true);
  };
  const data = [
    {
      id: 1,
      skucode: "Quality",
      name: "14",
      hsncode: "Active",
    },
    {
      id: 2,
      skucode: "Marketing",
      name: "02",
      hsncode: "Active",
    },
    {
      id: 3,
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
    },
    {
      id: 4,
      skucode: "Qc",
      name: " 02",
      hsncode: "Active",
    },
    {
      id: 5,
      skucode: "Testing",
      name: "05",
      hsncode: "Active",
    },
  ];

  const getList = () => {
    api.departmentView().then((res) => {
      // console.log("department_list", res);
      setDepartmentList(res.departments);
    });
  };

  // console.log("depar-list", departmentList);

  const statusChange = (id) => {
    // console.log("id", id);
    setLoader(true);
    api.departmentStatus(id).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setLoader(false);
        getList();
      } else {
        setError(res);
        setErrorBox(true);
        setLoader(false);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="user-list-whole-container">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        {loader && <Loader />}
        <TopBar />
        <TopHeader type="enterprise_admin" />
        <div className="user-list-userlist-container">
          <div className="search-bar-whole-cont px-2 d-sm-flex flex-wrap justify-content-between">
            <Col className="search-bar-cont">
              <input
                type="search"
                id="form1"
                class="form-control search-field"
                placeholder="Search by Supplier Details"
                aria-label="Search"
              />
            </Col>
            <div className="d-flex flex-wrap mt-1 mt-sm-0">
              <button className="filter-btn bg-primary2 ">
                <RxFilePlus />
                Filter
              </button>
              <Link
                to="/enterprise-admin/add-user"
                className="filter-btn adduser-btn"
              >
                <RxFilePlus />
                Add User
              </Link>
            </div>
          </div>
          <div className="action-container px-md-3 d-flex">
            <div className="action-page-cont">
              <p>Show</p>
              <div>
                <DropDown title="10" />
              </div>
              <p>34 Results</p>
            </div>
            <div className="action-page-cont">
              <DropDown title="Manage Departments" />
            </div>
          </div>
          <div className="department-list-container tbale-scroll-horizontal table-sm w-100">
            <table className="wid_full">
              <thead className="department-list-heading-container">
                <tr>
                  <th className="pad_heading ">Departments</th>
                  <th className="pad_heading ">Users</th>
                  <th className="pad_heading ">Status</th>
                  <th className="pad_heading ">Action</th>
                </tr>
              </thead>
              <tbody className="mx-2">
                {departmentList.map((list, index) => (
                  <tr key={index}>
                    <td className="pad_heading ">
                      {list?.SPDD_DepartmentName}
                    </td>
                    <td className="pad_heading ">14</td>
                    <td className="pad_heading">
                      <button
                        className={
                          list.SPDD_Status === 1
                            ? "department-status-container-active"
                            : "department-status-container-de-active"
                        }
                        style={{ border: "none" }}
                        onClick={() =>
                          statusChange(list?.SupPortDepartmentDtls_PK)
                        }
                      >
                        {list?.SPDD_Status === 1 ? "Active" : "In Active"}
                      </button>
                    </td>
                    <td className="department-dropdown-cont pad_heading">
                      {/* SupPortDepartmentDtls_PK  */}

                      <TbEdit
                        onClick={() =>
                          deparmentEditClickHandler(
                            list?.SupPortDepartmentDtls_PK,
                            list?.SPDD_DepartmentName
                          )
                        }
                      />
                      {/* <Dropdown classname="card-dropdown">
                      <Dropdown.Toggle id="dropdown-basic" variant="light">
                        <RxDotsVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <div className="page-status-cont">
              <p>Show</p>
              <DropDown title="10" />
              <p>1-6of20</p>
            </div>
            <div className="page-inner-cont">
              <Pagination>
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item> <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
              </Pagination>
            </div>
          </div>
        </div>
        <div className="rg-footer">
          <LogInFooter />
        </div>
      </div>
      <Modal
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        className="depart-design-input-modal"
      >
        <Modal.Body className="descrioption-input">
          <h6 className="fw-bold">Department Name</h6>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <div className="px-3 w-100 d-flex justify-content-center">
          <button
            onClick={department_Update}
            className="cust-btn bg-primary1 py-2 rounded-1 text-white"
          >
            Update
          </button>
        </div>
      </Modal>
    </>
  );
}

export default EnterPriseDepartment;
