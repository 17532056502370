import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Nodata } from "../assets/img";

const Mcpnodata = ({ }) => {


    return (

        <div className="nodata-con  text-center my-3">
            <Image src={Nodata} />
            <h2> No data Available</h2>
            <p>Add Your Registered address</p>


        </div>

    );
};
export default Mcpnodata;
