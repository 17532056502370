import React, { useEffect, useState } from "react";
import { Col, Image, Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import TableASN from "../../../component/ASN/TableASN";
import TopASN from "../../../component/ASN/TopASN";
import GeneralPagination from "../../../component/GeneralPagination";
import ASNList from "../../../component/PurchaseOrder/ASNList";
import POFilter from "../../../component/PurchaseOrder/POFilter";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import Calendar_Icon from "../../../assets/Icons/Normal Icons/Calender.svg";
import ModalFilter from "../../../component/ASN/ModalFilter";
import ShippmentMenu1 from "../../../component/ASN/ShippmentMenu1";
import SearchTitle from "../../../component/ProductList/SearchTitle";
import AsnAddExtraContainer from "../../../component/ASN/AddExtraContainer";
import { asnTableData } from "../../../layout/dummyData";
import Ship_Icon from "../../../assets/Icons/ASN/Ship.svg";
import Plane_Icon from "../../../assets/Icons/ASN/Airways.svg";
import Train_Icon from "../../../assets/Icons/ASN/Train.svg";
import Lorry_Icon from "../../../assets/Icons/ASN/Road.svg";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import { api } from "../../../constants/Services";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import moment from "moment/moment";
import { FaTimes } from "react-icons/fa";
import { FavLogo } from "../../../assets/img";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "../../../layout/datePicker";

const ASNScreenView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data; // PO TO ASN
  const table = location?.state?.table; // ASN TABLE TO ASN
  const types = location?.state?.type;
  const [showModal, setShowModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState(null);
  const [addExtraContShow, setAddExtraContShow] = useState(false);
  const [productName, setProductName] = useState(null);
  const [list, setList] = useState([]);
  const [add, setAdd] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [errorBoxs, setErrorBoxs] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shipmentDate, setShipmentDate] = useState([]);
  const [delDates, setDelDates] = useState([]);

  const [currentBtnDisplay, setCurrentBtnDisplay] = useState("save");
  const [showDropdown, setShowDropDown] = useState("");

  const [asnNo, setAsnNo] = useState("");
  const [asnDate, setAsnDate] = useState("");

  // console.log("types", types);
  // console.log("data", data);

  // MODEL

  const [shipmentQuty, setshipmentQuty] = useState();
  const [UOM, setUOM] = useState();
  const [UOMs, setUOMs] = useState();
  const [ExWorkDate, setExWorkDate] = useState(new Date());
  const [ExpectedtimeofDispatch, setExpectedtimeofDispatch] = useState({});
  const [ExpectedtimeofArrival, setExpectedtimeofArrival] = useState({});
  const [modeShipment, setModeShipment] = useState({});
  const [Remarks, setRemarks] = useState({});
  const [counts, setCounts] = useState([]);
  const [countDate, setCountDate] = useState([]);
  const [btn, setBtn] = useState(false);
  const [deliveryplanid, setDeliveryplanid] = useState();
  const [indexStore, setIndexStore] = useState();
  var temp_i;
  var temp_quantity;
  const temp_data_array = [[], []];
  const [ExpectedtimeofDispatch2, setExpectedtimeofDispatch2] = useState(
    new Date()
  );
  const [ExpectedtimeofArrival2, setExpectedtimeofArrival2] = useState(
    new Date()
  );
  const [modeShipment2, setModeShipment2] = useState("");
  const [Remarks2, setRemarks2] = useState("");

  const [twoArray, setTwoArray] = useState([]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const [solingSheet, setSolingSheet] = useState();

  const asnViewToolTipHandler = (ind) => {
    if (ind == solingSheet) {
      setSolingSheet();
    } else {
      setSolingSheet(ind);
    }
  };

  const asnSaveClick = () => setCurrentBtnDisplay("submit");

  // console.log("table", table);

  // TABLE DATES

  const [group, setGroup] = useState([]);

  const getPoGroup = () => {
    let formdata = new FormData();

    formdata.append("factory_id", table?.FactoryID);
    formdata.append("type", table?.type);
    formdata.append("po_id", table?.purchase_order_id);

    api.asnPoGroup(formdata).then((res) => {
      if (res?.status == "success") {
        let temp = [];
        res?.shipment_details?.map((item, index) => {
          temp.push({
            date: moment(item?.date).format("DD-MMM-YYYY"),
            detail: item?.detail,
            shipment_detail: item?.shipment_detail,
            shipment_mode_id: item?.shipment_mode_id,
            type: item?.type,
          });
        });

        temp.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

        setGroup(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getDelDate = (viewData, index) => {
    // console.log("viewData", viewData);
    if (shipmentModal == index) {
      setShipmentModal(null);
    } else {
      setShipmentModal(index);
      setDelDates(viewData);
    }
  };

  // useEffect(() => {
  //   if (table) {
  //     getPoGroup();
  //   }
  // }, []);

  // TABLE BUTTON CLICK

  const asnAddClickHandler = (info, detail, del_date_id, UomList) => {
    // console.log("info", info);
    // console.log("detail", detail);
    // console.log("info", del_date_id);
    // console.log("UomList", UomList);
    setDeliveryplanid(detail);
    setIndexStore(del_date_id);
    // EDIT & UPDATE

    setExpectedtimeofDispatch2(
      detail?.supplier_delivery_plan?.ETD
        ? new Date(detail?.supplier_delivery_plan?.ETD)
        : ""
    );
    setExpectedtimeofArrival2(
      detail?.supplier_delivery_plan?.ETA
        ? new Date(detail?.supplier_delivery_plan?.ETA)
        : ""
    );
    setModeShipment2(detail?.supplier_delivery_plan?.ShipmentModeID);
    setRemarks2(detail?.supplier_delivery_plan?.Remarks);
    setBtn(false);
    setErrorBoxs(false);

    setshipmentQuty(Math.round(detail?.Quantity));
    setUOM(
      UomList?.sku?.material_header?.uom?.UomDescription ||
        UomList?.sku?.leather_header?.uom?.UomDescription
    );
    setUOMs(info);
    setExWorkDate(
      detail?.supplier_delivery_plan?.ExWorkDate
        ? new Date(detail?.supplier_delivery_plan?.ExWorkDate)
        : ""
    );
    setShowModal(true);
  };

  const asnAddModalClickHandler = () => {
    setAddExtraContShow(true);
    setShowModal(false);
  };

  // GET ASN TABLE

  const getList = () => {
    let detail = {};
    detail.factory_id = table?.FactoryID;
    detail.purchase_order_id = table?.purchase_order_id;
    detail.type = table?.type;

    let formdata = new FormData();

    formdata.append("factory_id", table?.FactoryID);
    formdata.append("type", table?.type);
    formdata.append("po_id", table?.purchase_order_id);

    api.asnTable(detail).then((resp) => {
      if (resp?.status == "success") {
        api.asnPoGroup(formdata).then((res) => {
          if (res?.status == "success") {
            let temps = [];
            res?.shipment_details?.map((item, index) => {
              temps.push({
                date: moment(item?.date).format("DD-MMM-YYYY"),
                detail: item?.detail,
                shipment_detail: item?.shipment_detail,
                shipment_mode_id: item?.shipment_mode_id,
                type: item?.type,
              });
            });

            temps.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

            setGroup(temps);

            let shortArr = [];

            resp?.purchase_order_details?.map((item, ind) => {
              item?.purchase_order_detail_del_dates?.map((items, inds) => {
                shortArr.push(items);
              });
              // shortArr.push({
              //   PurchaseOrderDetailID:
              //     item?.MaterialPurchaseOrderDetailID ||
              //     item?.LeatherPurchaseOrderDetailID,
              //   PurchaseOrderID:
              //     item?.MaterialPurchaseOrderID || item?.LeatherPurchaseOrderID,
              //   MaterialSKUID: item?.MaterialSKUID,
              //   purchase_order_detail_del_dates:
              //     item?.purchase_order_detail_del_dates,
              //   purchase_order_detail_del_dates_sum_quantity:
              //     item?.purchase_order_detail_del_dates_sum_quantity,
              //   sku: item?.sku?.MaterialSKUName,
              //   sum_quantity: item?.sum_quantity,
              //   sum_received_quantity: item?.sum_received_quantity,
              //   uom: item?.uom?.UomDescription,
              // });
            });
            let temp = [];
            // new Array(resp.purchase_order_details.length)
            //   .fill(null)
            //   .map(() => new Array(temps.length).fill(null));
            // console.log("shortArr", shortArr);
            // resp?.purchase_order_details?.map((item, index) => {
            //   temp[index] = [];
            //   res?.shipment_details?.map((items, i) => {
            //     temp[index][i] = null;
            //   });
            // });
            console.log("temps", temps);
            for (let i = 0; i < resp?.purchase_order_details?.length; i++) {
              temp[i] = [];
              for (let j = 0; j < temps?.length; j++) {
                temp[i][j] = null;
              }
            }

            console.log("temp", temp);

            for (let i = 0; i < resp?.purchase_order_details?.length; i++) {
              for (let j = 0; j < temps?.length; j++) {
                if (
                  moment(
                    resp?.purchase_order_details[i]
                      .purchase_order_detail_del_dates[j]
                      ?.supplier_delivery_plan?.ETA
                  ).format("DD-MMM-YYYY") === temps[j]?.date &&
                  +resp?.purchase_order_details[i]
                    .purchase_order_detail_del_dates[j]?.supplier_delivery_plan
                    ?.ShipmentModeID === temps[j]?.shipment_mode_id
                ) {
                  temp[i][j] =
                    resp?.purchase_order_details[
                      i
                    ].purchase_order_detail_del_dates[j];
                } else {
                  let temp_obj =
                    resp?.purchase_order_details[i]
                      .purchase_order_detail_del_dates[j];
                  for (let s = 0; s < temps?.length; s++) {
                    if (
                      moment(
                        resp?.purchase_order_details[i]
                          .purchase_order_detail_del_dates[j]
                          ?.supplier_delivery_plan?.ETA
                      ).format("DD-MMM-YYYY") === temps[s]?.date &&
                      +resp?.purchase_order_details[i]
                        .purchase_order_detail_del_dates[j]
                        ?.supplier_delivery_plan?.ShipmentModeID ===
                        temps[s]?.shipment_mode_id
                    ) {
                      temp[i][s] = temp_obj;
                    }
                  }
                }
              }
            }

            // console.log("temp2", temp);

            setList(resp);
            setTwoArray(temp);
          } else {
            setError(res);
            setErrorBox(true);
          }
        });
      } else {
        setError(resp);
        setErrorBox(true);
      }
    });
  };

  // console.log("twoArray", twoArray);
  // console.log("list", list);

  // UPDATE SHIPMENTS

  const storeShipments = () => {
    var formdata = new FormData();

    if (deliveryplanid?.MaterialPurchaseOrderDetailDelDateID) {
      formdata.append("type", "M");
    } else {
      formdata.append("type", "L");
    }

    formdata.append("factory_id", deliveryplanid?.FactoryID);

    formdata.append(
      "delivery_plan_id",
      deliveryplanid?.supplier_delivery_plan?.MaterialSupplierDeliveryPlanID ||
        deliveryplanid?.supplier_delivery_plan?.LeatherSupplierDeliveryPlanID
    );
    formdata.append(
      "ETD",
      moment(ExpectedtimeofDispatch2).format("YYYY-MM-DD")
    );
    formdata.append("ETA", moment(ExpectedtimeofArrival2).format("YYYY-MM-DD"));
    formdata.append("ShipmentModeID", modeShipment2);
    formdata.append("Remarks", Remarks2);
    formdata.append("EWD", moment(ExWorkDate).format("YYYY-MM-DD"));
    setBtn(true);
    setShowModal(false);
    api.asnUpdate(formdata).then((res) => {
      if (res?.status == "success") {
        setAddExtraContShow(true);
        setShowModal(false);
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          getList();
        }, 500);
        // asnSaveClick();
      } else {
        setError(res);
        setErrorBox(true);
      }
      setBtn(false);
    });
  };

  // MODE OF SHIPMENTS LIST

  const asnCreates = () => {
    api.asnCreate().then((res) => {
      if (res?.status == "success") {
        setShipments(res?.shipments);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // TABLE DATES

  useEffect(() => {
    asnCreates();
    if (data || table) {
      getList();
    }
  }, []);

  console.log("twoArray", twoArray);

  return (
    <>
      <Col className="min-ht-80">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <TopBar />
        <TopHeader type="asn_view" />
        <Col className="px-5 m-xxl-auto my-5" xs={12}>
          <Col className="asn-top">
            <div className="d-flex justify-content-between">
              <div className="d-sm-flex flex-wrap">
                <p className="top-list light-primary3 me-4 me-sm-5">
                  PO ID:{" "}
                  <span className="text-dark">
                    {list?.purchase_order?.MaterialPurchaseOrderID ||
                      list?.purchase_order?.LeatherPurchaseOrderID ||
                      list?.leather_purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.purchase_order
                        ?.LeatherPurchaseOrderID ||
                      list?.material_purchase_order_detail_del_date
                        ?.material_purchase_order_detail?.purchase_order
                        ?.MaterialPurchaseOrderID}
                  </span>
                </p>
                <p className="top-list light-primary3 me-4 me-sm-5">
                  PO Ref. No.:{" "}
                  <span className="text-dark">
                    {" "}
                    {list?.purchase_order?.MaterialPurchaseOrderNo ||
                      list?.purchase_order?.LeatherPurchaseOrderNo ||
                      list?.leather_purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.purchase_order
                        ?.LeatherPurchaseOrderNo ||
                      list?.material_purchase_order_detail_del_date
                        ?.material_purchase_order_detail?.purchase_order
                        ?.MaterialPurchaseOrderNo}
                  </span>
                </p>
                {/* <p className="top-list light-primary3  me-4 me-sm-5">
                  Amdt No.:{" "}
                  <span className="text-dark">
                    {list?.purchase_order?.AmnNo ||
                      list?.leather_purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.purchase_order
                        ?.AmnNo ||
                      list?.material_purchase_order_detail_del_date
                        ?.material_purchase_order_detail?.purchase_order?.AmnNo}
                  </span>
                </p> */}
                <p className="top-list light-primary3 ">
                  PO Date:{" "}
                  <span className="text-dark">
                    {list?.purchase_order?.MaterialPurchaseOrderDate
                      ? moment(
                          list?.purchase_order?.MaterialPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : list?.purchase_order?.LeatherPurchaseOrderDate
                      ? moment(
                          list?.purchase_order?.LeatherPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : list?.leather_purchase_order_detail_del_date
                          ?.leather_purchase_order_detail?.purchase_order
                          ?.LeatherPurchaseOrderDate
                      ? moment(
                          list?.leather_purchase_order_detail_del_date
                            ?.leather_purchase_order_detail?.purchase_order
                            ?.LeatherPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : list?.material_purchase_order_detail_del_date
                          ?.material_purchase_order_detail?.purchase_order
                          ?.MaterialPurchaseOrderDate
                      ? moment(
                          list?.material_purchase_order_detail_del_date
                            ?.material_purchase_order_detail?.purchase_order
                            ?.MaterialPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : ""}
                  </span>
                </p>
              </div>
              <div>
                <button
                  className="rounded-2 border-0 p-2 bg-hash"
                  style={{ fontWeight: "bold" }}
                  onClick={() => navigate("/asn-listing")}
                >
                  Close
                </button>
              </div>
            </div>
          </Col>

          <Col className="prduct-page mt-3">
            <div className="bill-material d-flex justify-content-between w-100 align-items-center">
              <div className="mb-3">
                <h5>Bill of Materials</h5>
                <p>No.of Items: {list?.purchase_order_details?.length}</p>
              </div>
            </div>
            <div
              className="product-tabless asn-table px-0 mx-0 d-flex"
              style={{ overflowX: "auto", minHeight: "300px" }}
            >
              <div>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="product_th text-center">S.No</th>
                      <th className="product_th text-center text-nowrap">
                        Supplier Mat. Code
                      </th>
                      <th className="product_th product_th text-start">
                        KH SKU Code
                      </th>
                      <th
                        className="product_th product_th text-start"
                        style={{ width: "200px" }}
                      >
                        KH Material SKU
                      </th>
                      <th className="product_th text-center">UOM</th>
                      <th className="product_th text-center">PO Qty</th>
                    </tr>

                    <tr className="">
                      <th className="text-center mt-2"></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th
                        className="text-center mt-5"
                        style={{ color: "#ffffff" }}
                      >
                        shipment
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.purchase_order_details?.map((item, ind) => {
                      return (
                        <tr key={ind} className="asn-main-tble">
                          <td className="text-center tdData">{ind + 1}</td>
                          <td className="text-center tdData">
                            <p className="mt-2">{"-"}</p>
                          </td>
                          <td className="text-start tdData">
                            {item?.sku?.MaterialSKUCode ||
                              item?.sku?.LeatherSKUCode}
                          </td>
                          <td
                            className="text-center tdData"
                            style={{ minWidth: "150px" }}
                          >
                            {/* <p className="mt-2" style={{ minWidth: "150px" }}> */}
                            {item?.sku?.MaterialSKUName ||
                              item?.sku?.LeatherSKUName}{" "}
                            {/* </p> */}
                          </td>
                          <td className="text-center tdData">
                            <div className="d-flex flex-column">
                              <button
                                style={{
                                  border: "none",
                                  background: "none",
                                  fontSize: "13px",
                                  fontFamily: "f1",
                                  color: "white",
                                }}
                              >
                                {moment(item?.DeliveryDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </button>
                              <button className="cust-btn bg-lit-primary4">
                                {item?.sku?.material_header?.uom
                                  ? item?.sku?.material_header?.uom?.UomCode
                                  : item?.sku?.leather_header?.uom
                                  ? item?.sku?.leather_header?.uom?.UomCode
                                  : "-"}
                              </button>
                            </div>
                          </td>
                          <td className="text-center tdData">
                            <div className="d-flex flex-column">
                              <button
                                style={{
                                  border: "none",
                                  background: "none",
                                  fontSize: "13px",
                                  fontFamily: "f1",
                                  color: "white",
                                }}
                              >
                                {moment(item?.DeliveryDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </button>
                              <button className="cust-btn bg-lit-primary4 me-1">
                                {item?.purchase_order_detail_del_dates_sum_quantity
                                  ? Math.round(
                                      item?.purchase_order_detail_del_dates_sum_quantity
                                    )
                                  : "-"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <table className="w-100">
                  <thead>
                    <tr>
                      {group?.map((name, indx) => (
                        <>
                          <th className="product_th text-center" key={indx}>
                            <span>Shipment {indx + 1}</span>
                          </th>
                        </>
                      ))}
                    </tr>
                    {table && (
                      <tr className="">
                        {group?.map((item, ind) => {
                          // console.log("item", item);
                          let today = new Date();
                          let endDate = new Date(
                            moment(item?.date).format("DD-MMM-YYYY")
                          );
                          // console.log("today", today, "endDate", endDate);
                          return (
                            <>
                              <th
                                className="text-center"
                                key={ind}
                                style={{ position: "relative" }}
                              >
                                <button
                                  onClick={() => getDelDate(item, ind)}
                                  className="cust-btn p-0 "
                                  style={{
                                    fontSize: "13px",
                                    width: "92px !important",
                                  }}
                                >
                                  <div
                                    style={
                                      today >= endDate
                                        ? {
                                            color: "red",
                                            display: "block",
                                          }
                                        : {
                                            color: "black",
                                            display: "block",
                                          }
                                    }
                                  >
                                    {item?.date ? item?.date : "-"}
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>
                                          {item?.shipment_mode_id == 185
                                            ? "AIR Transport"
                                            : item?.shipment_mode_id == 186
                                            ? "SEA Transport"
                                            : item?.shipment_mode_id == 348
                                            ? "ROAD Transport"
                                            : ""}
                                        </Tooltip>
                                      )}
                                      placement="bottom"
                                    >
                                      <img
                                        src={
                                          item?.shipment_mode_id == 185
                                            ? Plane_Icon
                                            : item?.shipment_mode_id == 186
                                            ? Ship_Icon
                                            : item?.shipment_mode_id == 348
                                            ? Lorry_Icon
                                            : ""
                                        }
                                        style={{
                                          width: "15px",
                                          marginLeft: "5px",
                                        }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </button>
                                {shipmentModal == ind ? (
                                  <div className="model-btn-shipment-asn">
                                    <div className="d-flex justify-content-between my-1">
                                      <h4>Shipment {ind + 1}</h4>
                                      <h5>
                                        MOS:{" "}
                                        {delDates?.shipment_mode_id == 185
                                          ? "AIR"
                                          : delDates?.shipment_mode_id == 186
                                          ? "SEA"
                                          : delDates?.shipment_mode_id == 348
                                          ? "ROAD"
                                          : ""}
                                      </h5>
                                    </div>
                                    <div className="eta-etd d-flex justify-content-between">
                                      <div className="text-start">
                                        <p>Expected Time of Dispatch(ETD)</p>
                                        <h6>
                                          {" "}
                                          {moment(
                                            delDates?.detail[0]?.ETD
                                          ).format("DD-MMM-YYYY")}
                                        </h6>
                                      </div>
                                      <div className="text-start">
                                        <p>Expected time of Arrival(ETA)</p>
                                        <h6>
                                          {moment(
                                            delDates?.detail[0]?.ETA
                                          ).format("DD-MMM-YYYY")}
                                        </h6>
                                      </div>
                                    </div>
                                    <table className="w-100 ship-meth-table">
                                      <thead>
                                        <tr>
                                          <th className="bg-transparent py-1">
                                            S.No
                                          </th>
                                          <th className="bg-transparent py-1">
                                            Material SKU
                                          </th>
                                          <th className="bg-transparent py-1">
                                            Quantity
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {delDates?.detail?.map(
                                          (data, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="shipment-table"
                                                style={{ height: "100%" }}
                                              >
                                                <td className="tdData_new">
                                                  {index + 1}
                                                </td>
                                                <td className="tdData_new">
                                                  {data.sku?.MaterialSKUCode ||
                                                    data?.sku
                                                      ?.LeatherSKUCode}{" "}
                                                  <button className="cust-btn p-0 ex-icon">
                                                    <HiOutlineExclamationCircle
                                                      onClick={() =>
                                                        asnViewToolTipHandler(
                                                          index
                                                        )
                                                      }
                                                    />
                                                    {solingSheet == index && (
                                                      <p
                                                        style={{
                                                          zIndex: "1",
                                                          fontSize: "12px",
                                                        }}
                                                        className="cust-btn text-white px-2 shoe-sole"
                                                      >
                                                        {data?.sku
                                                          ?.MaterialSKUName ||
                                                          data?.sku
                                                            ?.LeatherSKUName}
                                                      </p>
                                                    )}
                                                  </button>
                                                </td>
                                                <td className="tdData_new">
                                                  {Number(
                                                    data.Quantity
                                                  ).toFixed(2)}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                    <button
                                      onClick={() => setShipmentModal(null)}
                                      className="cust-btn mt-2 me-0 ms-auto d-flex"
                                      style={{ border: "1px solid #000" }}
                                    >
                                      Close
                                    </button>
                                  </div>
                                ) : null}
                              </th>
                            </>
                          );
                        })}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {twoArray?.map((item, ind) => {
                      return (
                        <tr key={ind} className="asn-main-tble">
                          {item?.map((items, inds) => {
                            return (
                              <>
                                {item[inds]?.supplier_delivery_plan
                                  ?.Quantity ? (
                                  <td
                                    className={
                                      types == "add"
                                        ? "text-center tdData"
                                        : "text-center tdData"
                                    }
                                    style={{ position: "relative" }}
                                  >
                                    <div className="d-flex flex-column">
                                      <>
                                        {types == "add" ? null : (
                                          <button
                                            style={{
                                              border: "none",
                                              background: "none",
                                              fontSize: "13px",
                                              fontFamily: "f1",
                                            }}
                                          >
                                            {moment(
                                              item[inds]?.DeliveryDate
                                            ).format("DD-MMM-YYYY")}
                                          </button>
                                        )}
                                        <button
                                          className="cust-btn bg-lit-primary4 me-1"
                                          onClick={() =>
                                            asnAddClickHandler(
                                              item,
                                              item[inds],
                                              item[inds]
                                                ?.MaterialPurchaseOrderDetailDelDateID ||
                                                item[inds]
                                                  ?.LeatherPurchaseOrderDetailDelDateID,
                                              list?.purchase_order_details[ind]
                                            )
                                          }
                                        >
                                          {Math.round(
                                            item[inds]?.supplier_delivery_plan
                                              ?.Quantity
                                          )}
                                        </button>
                                      </>
                                    </div>
                                  </td>
                                ) : (
                                  <td className="text-center tdData">
                                    {" "}
                                    <div className="d-flex flex-column">
                                      <>
                                        {types == "add" ? null : (
                                          <button
                                            style={{
                                              border: "none",
                                              background: "none",
                                              fontSize: "13px",
                                              fontFamily: "f1",
                                              color: "#ffffff",
                                            }}
                                          >
                                            {moment(
                                              item[inds]?.DeliveryDate
                                            ).format("DD-MMM-YYYY")}
                                          </button>
                                        )}
                                        <button className="cust-btn bg-lit-primary4 me-1">
                                          -
                                        </button>
                                      </>
                                    </div>
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* {addExtraContShow ? (
              <>
                <Col
                  lg={8}
                  md={12}
                  xs={12}
                  className="d-md-flex gap-5 my-1 py-1 flex-wrap"
                >
                  <p className="my-1">
                    No of Packages:{" "}
                    {list?.no_of_packages
                      ? parseInt(list?.no_of_packages)
                      : list?.NoOfPackages
                      ? parseInt(list?.NoOfPackages)
                      : ""}
                  </p>
                  <p className="my-1">
                    Total Gross Weight:{" "}
                    {list?.total_gross_weight
                      ? parseInt(list?.total_gross_weight)
                      : list?.TotalGrossWeight
                      ? parseInt(list?.TotalGrossWeight)
                      : ""}{" "}
                    Kgs
                  </p>
                  <p className="my-1">
                    Total Net Weight:{" "}
                    {list?.total_net_weight
                      ? parseInt(list?.total_net_weight)
                      : list?.TotalNetWeight
                      ? parseInt(list?.TotalNetWeight)
                      : ""}
                  </p>
                  <p className="my-1">
                    Total Volume:{" "}
                    {list?.total_volumes
                      ? parseInt(list?.total_volumes)
                      : list?.TotalVolumn
                      ? parseInt(list?.TotalVolumn)
                      : ""}
                    sqf
                  </p>
                </Col>
                <h5 className="fs-14px primary1 my-1 py-1 fnt-fam-f1">
                  Overall Delivery Schedule
                </h5>
                <div className="d-sm-flex flex-wrap">
                  <Col
                    lg={2}
                    md={12}
                    xs={12}
                    className="d-md-flex gap-5 my-1 py-1 flex-wrap ps-lg-3 ms-lg-3"
                  >
                    {currentBtnDisplay === "submit" && (
                      <button
                        className="cust-btn bg-primary1 text-white rounded-1"
                        onClick={() => navigate("/asn-listing")}
                        disabled={btn ? true : false}
                      >
                        Submit
                      </button>
                    )}
                  </Col>
                </div>
              </>
            ) : null} */}
          </Col>
          {/* <GeneralPagination />  */}
        </Col>
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
      <Modal
        id="#verify"
        className="prod-filter"
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">{"Edit"} Shipment</h5>
        </Modal.Header>
        <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Shipment Qty<span className="err">*</span>
              </label>
              <input
                type="text"
                placeholder=" "
                onChange={(e) => setshipmentQuty(e.target.value)}
                value={shipmentQuty}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                UOM<span className="err">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => setUOM(e.target.value)}
                value={UOM}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Ex. Work Date<span className="err">*</span>
              </label>
              <DatePicker setStartDate={setExWorkDate} startDate={ExWorkDate} />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Mode of Shipment<span className="err">*</span>
              </label>
              <select
                onChange={(e) => setModeShipment2(e.target.value)}
                value={modeShipment2}
                disabled={types == "view" ? true : false}
              >
                <option>select mode of shipment </option>
                {shipments?.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Expected Time of Dispatch(ETD)<span className="err">*</span>
              </label>
              <DatePicker
                setStartDate={setExpectedtimeofDispatch2}
                startDate={ExpectedtimeofDispatch2}
                disabled={types == "view" ? true : false}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Expected time of Arrival(ETA)<span className="err">*</span>
              </label>
              <DatePicker
                setStartDate={setExpectedtimeofArrival2}
                startDate={ExpectedtimeofArrival2}
                disabled={types == "view" ? true : false}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="input-box mt-3">
              <label>Remarks</label>
              <textarea
                className="w-100 bo"
                row="3"
                onChange={(e) => setRemarks2(e.target.value)}
                value={Remarks2}
                disabled={types == "view" ? true : false}
              />
            </div>
          </Col>
        </Modal.Body>
        {types == "view" ? null : (
          <div className="d-flex justify-content-end">
            <button
              className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>

            <button
              className="cust-btn mb-2 bg-primary1 me-1 text-white"
              // onClick={asnAddModalClickHandler}
              // onClick={submitHandler}
              onClick={() => storeShipments()}
              disabled={btn ? true : false}
            >
              {"Edit"}
            </button>
          </div>
        )}
      </Modal>
      <Modal
        id="#verify"
        className="otp-modal"
        show={errorBoxs}
        centered
        onHide={() => setErrorBoxs(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="new-modal">
          <div className="w-100 d-flex-al-jb my-2">
            <Image className="fav-icon " src={FavLogo} />
            <button
              onClick={() => setErrorBoxs(false)}
              className="cust-btn p-0 mb-4"
            >
              <FaTimes />
            </button>
          </div>
          <div className="mt-2  my-5">
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              You Can't Add Data , The Supplier Delivery Plan is Empty
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ASNScreenView;
