// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, InputGroup, Form } from "react-bootstrap";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GoogleMapReact from "google-map-react";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
// import audios from "../assets/Water Drop.mp3";
const location = {
  address: "1600 Amphitheatre Parkway, Mountain View, california.",
  lat: 37.42216,
  lng: -122.08427,
};
const AddRegaddress = ({ zoomLevel, location }) => {
  const type = location?.state?.type;
  const id = location?.state?.id;
  // console.log("id", id);
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [types, setTypes] = useState("");
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [transportType, setTransportType] = useState();
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [add3, setAdd3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [mailId, setMailId] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [ext, setExt] = useState("");

  const [typesErr, setTypesErr] = useState("");
  const [add1Err, setAdd1Err] = useState("");
  const [add2Err, setAdd2Err] = useState("");
  const [add3Err, setAdd3Err] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const [mailIdErr, setMailIdErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [faxErr, setFaxErr] = useState("");
  const [extErr, setExtErr] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [sortData, setSortData] = useState([]);

  const getList = () => {
    api.address().then((res) => {
      if (res?.status == "success") {
        setList(res);

        let stateList = res?.states?.sort((a, b) => {
          if (a.state_name < b.state_name) return -1;
          if (a.state_name > b.state_name) return 1;
          return 0;
        });

        let cityList = res?.cities?.sort((a, b) => {
          if (a.city_name < b.city_name) return -1;
          if (a.city_name > b.city_name) return 1;
          return 0;
        });

        let countryList = res?.countries?.sort((a, b) => {
          if (a.country_name < b.country_name) return -1;
          if (a.country_name > b.country_name) return 1;
          return 0;
        });

        let branches = res?.branches?.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        setSortData({
          state: stateList,
          cities: cityList,
          countries: countryList,
          branches: branches,
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const countrys = () => {
    api.country().then((res) => {
      if (res?.status == "success") {
        setCountries(res?.countries);
        // setCountry();
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const onChange = (e) => {
    // console.log(e);
    stateList(e.target.value);
    cityList(e.target.value);
  };

  const stateList = (e) => {
    console.log("state", e);

    api.stateList(e).then((res) => {
      if (res?.status == "success") {
        setStates(res?.states);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const cityList = (e) => {
    console.log("city", e);
    api.cityList(e).then((res) => {
      if (res?.status == "success") {
        setCities(res?.cities);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const addressView = () => {
    api.addressView(id).then((res) => {
      if (res?.status == "success") {
        let data = res?.address;
        setTypes(data?.RefSupplierBranchTyeID);
        // setTransportType(data?.SPRAD_TransportType);
        setLat(data?.latitude);
        setLong(data?.longitude);
        setAdd1(data?.address1);
        setAdd2(data?.address2);
        setAdd3(data?.address3);
        setCountry(data?.CountryID);
        setState(data?.StateID);
        setCity(data?.CityID);
        setMailId(data?.email);
        setPhone(data?.telephone_code + data?.telephone);
        setFax(data?.fax);
        setExt(data?.telephone_ext);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // console.log(id);

  // const sumSou = () => {
  //   new Audio(audios).play();
  // };

  const submitHandle = () => {
    if (
      types?.length == 0 ||
      add1?.length == 0 ||
      // add2?.length == 0 ||
      // add3?.length == 0 ||
      country?.length == 0 ||
      state?.length == 0 ||
      city?.length == 0 ||
      // mailId?.length == 0 ||
      phone?.length == 0
    ) {
      setTypesErr(true);
      setAdd1Err(true);
      // setAdd2Err(true);
      // setAdd3Err(true);
      setCountryErr(true);
      setStateErr(true);
      setCityErr(true);
      // setMailIdErr(true);
      setPhoneErr(true);
    } else {
      let formData = new FormData();
      setBtn(true);

      formData.append("branch_id", types);
      // formData.append("transport_type", transportType);
      formData.append("latitude", "0.87576534");
      formData.append("longitude", "0.89675455");
      formData.append("address_1", add1);

      formData.append("country_id", country);
      formData.append("state_id", state);
      formData.append("city_id", city);

      if (add2) {
        formData.append("address_2", add2);
      }

      if (add3) {
        formData.append("address_3", add3);
      }

      if (mailId) {
        formData.append("email", mailId);
      }

      if (ext) {
        formData.append("telephone_ext", ext);
      }

      if (fax) {
        formData.append("fax", fax);
      }

      if (phone?.length == 12) {
        formData.append("telephone", phone?.slice(-10));
        formData.append("telephone_code", phone?.slice(0, 2));
      } else {
        formData.append("telephone", phone?.slice(3, phone?.length));
        formData.append("telephone_code", phone?.slice(0, 3));
      }

      if (id) {
        formData.append("_method", "PUT");
        api.addressEdit(id, formData).then((res) => {
          // console.log("edit", res);
          // let data = res?.address;
          // setTypes(data?.SPRAD_OfficeType);
          // setTransportType(data?.SPRAD_TransportType);
          // setLat(data?.SPRAD_Latitude);
          // setLong(data?.SPRAD_Longitude);
          // setAdd1(data?.SPRAD_AddrLine1);
          // setAdd2(data?.SPRAD_AddrLine2);
          // setAdd3(data?.SPRAD_AddrLine3);
          // setCountry(data?.SPRAD_SupPortCountryMst_FK);
          // setState(data?.SPRAD_SupPortStateMs_FK);
          // setCity(data?.SPRAD_SupPortCityMst_FK);
          // setMailId(data?.SPRAD_Email);
          // setPhone(data?.SPRAD_TelephoneNumber);
          // setFax(data?.SPRAD_Fax);
          if (res?.status == "success") {
            setBtn(false);
            setError(res);
            setErrorBox(true);
            setTimeout(() => {
              navigate("/mcprofile");
            }, 1000);
          } else {
            setBtn(false);
            setError(res);
            setErrorBox(true);
          }
        });
      } else if (!id) {
        api.addressAdd(formData).then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            setError(res);
            setErrorBox(true);
            setTimeout(() => {
              navigate("/mcprofile");
            }, 1000);
          } else {
            setBtn(false);
            setError(res);
            setErrorBox(true);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      addressView();
    }
  }, []);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <div className="reg-tag">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Link to="/mcprofile" className="add_reg">
        <HiArrowNarrowLeft />
        {type == "add" ? "Add" : "Edit"} Registered Address
      </Link>
      <div className="my-3">
        <Form>
          <div className="d-flex-wrap">
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                {" "}
                Type<span className="red-text">*</span>
              </label>
              {/* <select
                className="form-select "
                onChange={(e) => setTypes(e.target.value)}
                value={types}
              >
                <option value={1}> Headquarters</option>
                <option value={2}>Corporate Office</option>
                <option value={3}>Branches</option>
                <option value={4}>Factory Address</option>
                <option value={5}>Ware House</option>
              </select> */}
              <select
                className="form-select "
                onChange={(e) => setTypes(e.target.value)}
                value={types}
              >
                <option hidden>Select Type</option>
                {sortData?.branches?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
              {types?.length == 0 && typesErr && (
                <p className="err-input2 pe-3">Select Type</p>
              )}
            </Col>
            <Col xs={12} className="pe-3 my-2">
              {/* <InputGroup className="  ser-botn">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search Your Location in Google Map"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup> */}
              {/* <div className="google-map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={location}
                  defaultZoom={zoomLevel}
                ></GoogleMapReact>
              </div> */}
            </Col>
            {/* <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Latitude<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="0.00000"
                onChange={(e) => setLat(e.target.value)}
                value={lat}
              />
            </Col> */}
            {/* <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Longitude<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="0.00000"
                onChange={(e) => setLong(e.target.value)}
                value={long}
              />
            </Col> */}

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Country<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => {
                  setCountry(e.target.value);
                  onChange(e.target.value);
                }}
                value={country}
              >
                <option hidden>Select Country</option>
                {sortData?.countries?.map((item) => (
                  <option value={item?.country_id}>{item?.country_name}</option>
                ))}
              </select>
              {country?.length == 0 && countryErr && (
                <p className="err-input2 pe-3">Select Country</p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                State<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setState(e.target.value)}
                value={state}
              >
                <option hidden>Select State</option>
                {sortData?.state?.map((item) => (
                  <option value={item?.state_id}>{item?.state_name}</option>
                ))}
              </select>
              {state?.length == 0 && stateErr && (
                <p className="err-input2 pe-3">Select State</p>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                City<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setCity(e.target.value)}
                value={city}
              >
                <option hidden>Select City</option>
                {sortData?.cities?.map((item) => (
                  <option value={item?.city_id}>{item?.city_name}</option>
                ))}
              </select>
              {city?.length == 0 && cityErr && (
                <p className="err-input2 pe-3">Select City</p>
              )}
            </Col>
            <Col xs={12} className="pe-3 my-2">
              <label>
                Address line 1 <span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setAdd1(e.target.value)}
                value={add1}
              />
              {add1?.length == 0 && add1Err && (
                <p className="err-input2 pe-3">Enter Address 1</p>
              )}
            </Col>
            <Col xs={12} className="pe-3 my-2">
              <label>Address line 2</label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setAdd2(e.target.value)}
                value={add2}
              />
              {add2?.length == 0 && add2Err && (
                <p className="err-input2 pe-3">Enter Address 2</p>
              )}
            </Col>
            <Col xs={12} className="pe-3 my-2">
              <label>Address line 3</label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setAdd3(e.target.value)}
                value={add3}
              />
              {add3?.length == 0 && add3Err && (
                <p className="err-input2 pe-3">Enter Address 3</p>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>Mail ID</label>
              <input
                className="form-control"
                type="email"
                placeholder=""
                onChange={(e) => setMailId(e.target.value)}
                value={mailId}
              />
              {mailId?.length == 0 && mailIdErr && (
                <p className="err-input2 pe-3">Enter Mail ID</p>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2 d-flex">
              <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                <label>
                  Contact Number <span className="red-text">*</span>
                </label>
                <PhoneInput
                  inputStyle={{ width: "auto" }}
                  country="in"
                  onChange={(e) => setPhone(e)}
                  value={phone}
                />
              </div>
              <div className="px-2">
                <label>
                  Ext
                  {/* <span className="red-text">*</span> */}
                </label>
                <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 3) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 3) {
                      e.target.setCustomValidity("");

                      setExt(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={3}
                  onChange={(e) => setExt(e.target.value)}
                  type="number"
                  value={ext}
                  className="form-control"
                  placeholder="Ext"
                />
              </div>
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Fax
                {/* <span className="red-text">*</span> */}
              </label>
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 10) {
                    e.target.setCustomValidity("invalid Ext");
                  } else if (e.target.value.length == 10) {
                    e.target.setCustomValidity("");

                    setFax(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={10}
                onChange={(e) => setFax(e.target.value)}
                type="number"
                className="form-control"
                placeholder="Fax"
                value={fax}
              />
            </Col>
          </div>
        </Form>
      </div>
      <div className="pe-3 my-2 d-flex-al-je ben_design">
        {/* <Link to="#" className="btn brn-can">
          Clear
        </Link> */}
        <button
          className=" btn sub-btn"
          onClick={submitHandle}
          disabled={btn ? true : false}
        >
          {type == "add" ? "Add" : "Edit"}
        </button>
      </div>
    </div>
  );
};
export default AddRegaddress;
