import QuoScopeMainTable from "./QuoScopeTable";
import QuoLastSectionBtn from "../../component/QuoLasSecBtn";
import Quotaion2ndTableSection from "./Quotation2ndTable";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import { AiOutlineZoomIn } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Col } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

function QuotationScopePage({ setZoomedTable }) {
  const scopeZoomHandler = () => setZoomedTable(true);
  return (
    <>
      <div className="mt-3 py-3 px-1">
        <h5 className="font-blue fs-5">Scope</h5>
        <p className="my-3 pt-2 d-flex justify-content-between">
          <label>
            Description<span className="red-text">*</span>
          </label>
          <FaEdit className="opac_half fs-5" />
        </p>

        <p className="py-1 mb-2 border-btm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer metus
          ligula, auctor eu sagittis eget, ultrices non turpis. Suspendisse
          pellentesque vestibulum sapien, non scelerisque ante vulputate in.
        </p>
        <p className="d-flex justify-content-end  mb-2">Max 1500</p>
      </div>
      <div className="my-5 py-2 mx-0">
        <div className="d-flex mb-3 gap-2 align-items-center float-end ">
          <p
            className="bg-primary1 text-white rounded-circle py-1 px-2"
            onClick={scopeZoomHandler}
          >
            <AiOutlineZoomIn />
          </p>
          <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1 w-100 opac_half">
            <input placeholder="Search Title" />
            <img className="search-icon" src={Search} />
          </div>
          <button className="filter-btn bg-primary2">
            <img src={Filter} className="wid-15px me-1" />
            Filter
          </button>
        </div>

        <Col lg={12} md={12} sm={12}>
          <QuoScopeMainTable />
        </Col>
        <div className="d-sm-flex justify-content-between mb-2 align-items-center">
          <div className="d-flex-wrap align-items-center gap-2 mb-0">
            <label>Items Per Page</label>
            <select name="" className="dropdown-btm-grey px-2 py-1">
              <option value="5">10</option>
              <option value="10">30</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
            <label>1-5 of 20</label>
          </div>
          <div className="d-flex mt-2 mt-sm-0 enquiry-page-cont">
            <Pagination>
              <Pagination.First className="enquiry-pag-list" />
              <Pagination.Prev className="enquiry-pag-list" />
              <Pagination.Next className="enquiry-pag-list" />
              <Pagination.Last className="enquiry-pag-list" />
            </Pagination>
          </div>
        </div>
        <Quotaion2ndTableSection />
        <QuoLastSectionBtn BtnText="Save & Next" BtnSize="wid-9_5r" />
      </div>
    </>
  );
}

export default QuotationScopePage;
