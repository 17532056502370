import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";
import eye_icon from "../../assets/Icons/Normal Icons/Eye.svg";
// import { FiEdit } from "react-icons/fi";
// import Action_Icon from "../../assets/Icons/Normal Icons/Ellipse.svg";
import { useNavigate } from "react-router";
import { api } from "../../constants/Services";
// import { ToastContainer, toast } from "react-toastify";
import { TbEdit } from "react-icons/tb";
import LogInFooter from "../../logincomponent/LogInFooter";
import ErrorModel from "../../constants/Services/Api/errorModel";
import ReactPaginate from "react-paginate";

function Roles() {
  const navigate = useNavigate();
  const [actionDropModal, setactionDropModal] = useState("");
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const permission = localStorage.getItem("permissions");

  // PAGINATION

  const [adminData, setAdminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsPerSort, setItemsSort] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(9);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");

  const getRoles = () => {
    api.getRoles().then((res) => {
      // console.log("res", res);
      if (res?.status == "success") {
        setList(res.roles);
        setAdminData(res?.roles);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const statusChanger = (id) => {
    api.statusChange(id).then((res) => {
      if (res?.status == "success") {
        getRoles();
        setError(res);
        setErrorBox(true);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  useEffect(() => {
    getRoles();
  }, []);

  const actionDropModalHandler = (ind) => {
    console.log(actionDropModal);
    if (actionDropModal !== ind) {
      setactionDropModal(ind);
    } else {
      setactionDropModal("");
    }
  };

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="role" />
      {list == null ? (
        <div className="w-100 text-center mt-4">
          <h5>No User Found</h5>
        </div>
      ) : (
        <div className="w-90 px-2 py-3 my-2 min-ht-80">
          {permission?.includes("ADD-ROLES") && (
            <div className="d-flex justify-content-end my-2">
              <button
                className="border-0 fs-14px fnt-fam-f2 bg-primary1 text-white add-role-btn"
                onClick={() =>
                  navigate("/enterprise-admin/module-permission-settings")
                }
              >
                Add Role
              </button>
            </div>
          )}
          <Col className="prduct-page">
            {/* <div className="container"> */}
            <div className="product-table min-ht-80 asn-table px-0 mx-0 my-3 overflox-x-scroll">
              <table className="w-100" style={{ minWidth: "750px" }}>
                <thead>
                  <tr>
                    <th className="text-center fs-15px">S.No</th>
                    {/* <th className="text-center fs-15px">Name</th> */}
                    <th className="text-center fs-15px">Role</th>
                    <th className="text-center fs-15px">Status</th>
                    {permission?.includes("VIEW-ROLES") && (
                      <th className="text-center fs-15px">Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((item, index) => (
                    <>
                      <tr key={index} className="shipment-table">
                        <td className="text-center fs-14px">
                          {" "}
                          {index == 9
                            ? last
                            : counter
                            ? `${counter}${index + 1}`
                            : index + 1}
                        </td>
                        {/* <td className="text-center fs-14px">{item?.name}</td> */}
                        <td className="text-center fs-14px">{item?.role}</td>
                        <td className="text-center ">
                          <button
                            type="button"
                            style={{ width: "85px" }}
                            className={
                              item?.status == 1
                                ? `btn btn-outline-success po-card-btn fs-14px`
                                : `btn btn-outline-danger po-card-btn fs-14px`
                            }
                            onClick={() => statusChanger(item?.id)}
                            disabled={
                              permission?.includes("STATUS-ROLES")
                                ? false
                                : true
                            }
                          >
                            {item?.status == 1 ? "Active" : "Deactive"}
                          </button>
                        </td>
                        {permission?.includes("VIEW-ROLES") && (
                          <td className="text-center position-relative">
                            <img
                              style={{ width: "24px" }}
                              className="pointer"
                              src={eye_icon}
                              alt="icon-eye"
                              onClick={() =>
                                navigate(
                                  "/enterprise-admin/module-permission-settings",
                                  {
                                    state: {
                                      type: "view",
                                      id: item?.id,
                                    },
                                  }
                                )
                              }
                            />
                            {/* <button
                            className="rounded-5 border-0 p-2 bg-hash "
                            onClick={() =>
                              navigate(
                                "/enterprise-admin/module-permission-settings",
                                {
                                  state: {
                                    type: "edit",
                                    id: item?.id,
                                  },
                                }
                              )
                            }>
                            <TbEdit />
                          </button> */}
                          </td>
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            {/* </div> */}
          </Col>
          <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      )}
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default Roles;
