import React, { useEffect, useState } from "react";
import { Col, InputGroup, Form, Button } from "react-bootstrap";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GoogleMapReact from "google-map-react";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
import TopHeader from "../navbar/TopHeader";
import TopBar from "../navbar/TopBar";
import useUser from "../constants/Storage/userData";
import useProfileStatus from "../constants/Storage/userStauts";
const location = {
  address: "1600 Amphitheatre Parkway, Mountain View, california.",
  lat: 37.42216,
  lng: -122.08427,
};
const AddTranspoint = ({ zoomLevel }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const { user } = useUser();
  const { profileStatus, setProfileStatus } = useProfileStatus();
  const types = location?.state?.type;
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [type, setType] = useState("");
  const [branch, setBranch] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [address3Err, setAddress3Err] = useState(false);
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [country, setCountry] = useState("");
  const [mailId, setMailId] = useState("");
  const [ext, setExt] = useState("");
  const [fax, setFax] = useState("");
  const [teleCode, setTeleCode] = useState("");
  const [tele, setTele] = useState("");

  // VALIDATION

  const [typeErr, setTypeErr] = useState(false);
  const [branchErr, setBranchErr] = useState(false);
  const [latErr, setLatErr] = useState(false);
  const [longErr, setLongErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [statesErr, setStatesErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [mailIdErr, setMailIdErr] = useState(false);
  const [extErr, setExtErr] = useState(false);
  const [faxErr, setFaxErr] = useState(false);
  const [teleCodeErr, setTeleCodeErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [sortData, setSortData] = useState([]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const submitHandle = () => {
    if (
      type?.length == 0 ||
      branch?.length == 0 ||
      // lat?.length == 0 ||
      // long?.length == 0 ||
      address?.length == 0 ||
      city?.length == 0 ||
      states?.length == 0 ||
      country?.length == 0 ||
      address3?.length == 0
      // ||
      // mailId?.length == 0
      //  ||
      // ext?.length == 0
      // ||
      // fax?.length == 0
    ) {
      setTypeErr(true);
      setBranchErr(true);
      // setLatErr(true);
      // setLongErr(true);
      setAddressErr(true);
      setAddress3Err(true);
      // setTeleCodeErr(true);
      setCityErr(true);
      setStatesErr(true);
      setCountryErr(true);
      // setMailIdErr(true);
      // setExtErr(true);
      // setFaxErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("branch_id", branch);
      formdata.append("latitude", "0.674656765");
      formdata.append("longitude", "0.7867576");
      if (mailId) {
        formdata.append("email", mailId);
      }

      formdata.append("country_id", country);
      formdata.append("state_id", states);
      formdata.append("city_id", city);
      formdata.append("address_1", address);
      formdata.append("transport_type", type);

      if (fax) {
        formdata.append("fax", fax);
      }

      if (ext) {
        formdata.append("telephone_ext", ext);
      }

      if (teleCode?.length > 0) {
        if (teleCode?.length == 12) {
          formdata.append("telephone", teleCode?.slice(-10));
          formdata.append("telephone_code", teleCode?.slice(0, 2));
        } else {
          formdata.append("telephone", teleCode?.slice(3, teleCode?.length));
          formdata.append("telephone_code", teleCode?.slice(0, 3));
        }
      }

      if (address2) {
        formdata.append("address_2", address2);
      }

      if (address3) {
        formdata.append("address_3", address3);
      }

      if (Id) {
        setBtn(true);
        formdata.append("_method", "PUT");
        api.transportEdit(Id, formdata).then((res) => {
          if (res?.status == "success") {
            setError(res);
            setErrorBox(true);
            setBtn(false);
            setTimeout(() => {
              navigate("/mcprofile/transport");
            }, 1000);
          } else {
            setError(res);
            setErrorBox(true);
            setBtn(false);
          }
        });
      } else if (!Id) {
        setBtn(true);

        api.transportAdd(formdata).then((res) => {
          if (res?.status == "success") {
            setError(res);
            setErrorBox(true);
            setBtn(false);

            setTimeout(() => {
              navigate("/mcprofile/addcertificate", {
                state: {
                  type: "add",
                  method: "certificate",
                },
              });
            }, 1500);
          } else {
            setError(res);
            setErrorBox(true);
            setBtn(false);
          }
        });
      }
    }
  };

  const skipHandle = () => {
    let formdata = new FormData();
    formdata.append("supplier_id", user?.supplier?.SupplierID);
    formdata.append("type", 2);
    api.skipUpdate(formdata).then((res) => {
      if (res?.status == "success") {
        setProfileStatus("2");
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          navigate("/mcprofile/addcertificate", {
            state: {
              type: "add",
              method: "certificate",
            },
          });
          window.location.reload();
        }, 1500);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getList = () => {
    api.address().then((res) => {
      if (res?.status == "success") {
        setList(res);

        let stateList = res?.states?.sort((a, b) => {
          if (a.state_name < b.state_name) return -1;
          if (a.state_name > b.state_name) return 1;
          return 0;
        });

        let cityList = res?.cities?.sort((a, b) => {
          if (a.city_name < b.city_name) return -1;
          if (a.city_name > b.city_name) return 1;
          return 0;
        });

        let countryList = res?.countries?.sort((a, b) => {
          if (a.country_name < b.country_name) return -1;
          if (a.country_name > b.country_name) return 1;
          return 0;
        });

        let branches = res?.branches?.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        setSortData({
          state: stateList,
          cities: cityList,
          countries: countryList,
          branches: branches,
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const view = () => {
    api.transportView(Id).then((res) => {
      if (res?.status == "success") {
        let data = res?.transport;
        setBranch(data?.RefSupplierBranchTyeID);
        setAddress(data?.address1);
        setAddress2(data?.address2);
        setAddress3(data?.address3);
        setCity(data?.CityID);
        setCountry(data?.CountryID);
        setExt(data?.telephone_ext);
        setMailId(data?.email);
        setFax(data?.fax);
        setType(data?.transport_type);
        setLat(data?.latitude);
        setLong(data?.longitude);
        setTele(data?.telephone);
        setTeleCode(data?.telephone_code + data?.telephone);
        setStates(data?.StateID);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (Id) {
      view();
    }
    getList();
  }, []);

  return (
    <div className="reg-tag">
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Link to="/mcprofile/transport" className="add_reg">
        <HiArrowNarrowLeft /> {types == "edit" ? "Edit" : "Add"} Nearest
        Transport Points
      </Link>
      <div className="my-3">
        <Form>
          <div className="d-flex-wrap">
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                {" "}
                Type<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option hidden>Select Type</option>
                <option value={1}>Road</option>
                <option value={2}>Rail</option>
                <option value={3}>Port </option>
              </select>
              {type?.length == 0 && typeErr && (
                <p className="err-input2 pe-3">Select Type</p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                {" "}
                Branch<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setBranch(e.target.value)}
                value={branch}
              >
                <option hidden>Select Branch</option>
                {list?.branches?.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
              {branch?.length == 0 && branchErr && (
                <p className="err-input2 pe-3">Select branch</p>
              )}
            </Col>
            <Col xs={12} className="pe-3 my-2">
              {/* <InputGroup className="  ser-botn">
                <InputGroup.Text id="basic-addon1">
                  <AiOutlineSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search Your Location in Google Map"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup> */}
              {/* <div className="google-map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={location}
                  defaultZoom={zoomLevel}
                ></GoogleMapReact>
              </div> */}
            </Col>
            {/* <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Latitude<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="0.00000"
                onChange={(e) => setLat(e.target.value)}
                value={lat}
              />
              {lat?.length == 0 && latErr && (
                <p className="err-input2 pe-3">Enter Latitude</p>
              )}
            </Col> */}
            {/* <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Longitude<span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="0.00000"
                onChange={(e) => setLong(e.target.value)}
                value={long}
              />
              {long?.length == 0 && longErr && (
                <p className="err-input2 pe-3">Enter Longitude</p>
              )}
            </Col> */}

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                Country<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                <option>Select Country</option>
                {sortData?.countries?.map((item) => (
                  <option value={item?.country_id}>{item?.country_name}</option>
                ))}
              </select>
              {country?.length == 0 && countryErr && (
                <p className="err-input2 pe-3">Select Country</p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                State
                <span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setStates(e.target.value)}
                value={states}
              >
                <option>Select State</option>
                {sortData?.state?.map((item) => (
                  <option value={item?.state_id}>{item?.state_name}</option>
                ))}
              </select>
              {states?.length == 0 && statesErr && (
                <p className="err-input2 pe-3">Select State</p>
              )}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>
                City<span className="red-text">*</span>
              </label>
              <select
                className="form-select "
                onChange={(e) => setCity(e.target.value)}
                value={city}
              >
                <option>Select City</option>
                {sortData?.cities?.map((item) => (
                  <option value={item?.city_id}>{item?.city_name}</option>
                ))}
              </select>
              {city?.length == 0 && cityErr && (
                <p className="err-input2 pe-3">Select City</p>
              )}
            </Col>

            <Col xs={12} className="pe-3 my-2">
              <label>
                Address line 1 <span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
              {address.length == 0 && addressErr && (
                <p className="err-input2 pe-3">Enter Address</p>
              )}
            </Col>
            <Col xs={12} className="pe-3 my-2">
              <label>Address line 2 </label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setAddress2(e.target.value)}
                value={address2}
              />
            </Col>
            <Col xs={12} className="pe-3 my-2">
              <label>
                Name of the Transport <span className="red-text">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setAddress3(e.target.value)}
                value={address3}
              />
              {address3.length == 0 && address3Err && (
                <p className="err-input2 pe-3">Enter Name of the Transport</p>
              )}
            </Col>

            <Col md={6} xs={12} className="pe-3 my-2">
              <label>Mail ID</label>
              <input
                className="form-control"
                type="email"
                placeholder=""
                onChange={(e) => setMailId(e.target.value)}
                value={mailId}
              />
              {validEmail.test(mailId) == false && mailIdErr && (
                <p className="err-input2 pe-3">Enter Mail ID</p>
              )}
            </Col>

            {/* <Col md={6} xs={12} className="pe-3 my-2 d-flex">
              <div>
                <label>Code </label>
                <PhoneInput country="us" />
              </div>
              <div className="input-box position-relative telephone-input-box d-flex">
                <div style={{ width: "78%" }}>
                  <div className="d-flex">
                    <div className="d-flex">
                      <p style={{ width: "40px" }}>Code</p>
                      <p>Telephone</p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <select
                      name="countries"
                      onChange={(e) => setTeleCode(e.target.value)}
                      value={teleCode}
                      className="border-wid"
                    >
                      <option value="+91">&#x1F1EE;&#x1F1F3; +91</option>
                      <option value="+1">&#x1F1FA;&#x1F1F8; +1</option>
                    </select>
                    <input
                      type="number"
                      onChange={(e) => setTele(e.target.value)}
                      value={tele}
                      className="border-nne"
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "80px",
                    right: 0,
                    bottom: "-2px",
                    background: "white",
                    paddingLeft: "10px",
                  }}
                >
                  <p style={{}}>Ext</p>
                  <input
                    className="border-0"
                    type="number"
                    onChange={(e) => setExt(e.target.value)}
                    value={ext}
                  />
                  {ext?.length == 0 && extErr && (
                    <p className="err-input2 pe-3">Enter Ext</p>
                  )}
                </div>
              </div>

              <div className="px-2">
                <label>Ext</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder=" "
                  onChange={(e) => setExt(e.target.value)}
                  value={ext}
                />
                 {ext?.length == 0 && extErr && (
                <p className="err-input2 pe-3">Enter Ext</p>
              )}
              </div>
             
            </Col> */}
            <Col
              md={6}
              xs={12}
              className="pe-2 my-2 d-flex position-relative  rit-mobile-regis2"
            >
              <div style={{ width: "100%" }} className="">
                <div class="d-flex border_btm">
                  <div class="tele_number" style={{ width: "80%" }}>
                    <p>Telephone</p>
                    <PhoneInput
                      country="in"
                      className=""
                      value={teleCode}
                      onChange={(e) => {
                        setTeleCode(e);
                        // console.log(e);
                      }}
                    />
                    {teleCode?.length == 0 && teleCodeErr && (
                      <p
                        style={{
                          marginRight: "30px",
                        }}
                        className="err-input2 pe-3"
                      >
                        Enter Telephone No
                      </p>
                    )}
                  </div>
                  <div class="telephone_ext" style={{ width: "20%" }}>
                    <p>Ext</p>
                    <input
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        if (e.target.value.length !== 3) {
                          e.target.setCustomValidity("invalid Ext");
                        } else if (e.target.value.length == 3) {
                          e.target.setCustomValidity("");

                          setExt(e.target.value);
                        }
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      maxlength={3}
                      onChange={(e) => setExt(e.target.value)}
                      value={ext}
                      type="number"
                      className="m-0 border-0"
                      placeholder="Ext"
                    />
                    {ext?.length == 0 && extErr && (
                      <p className="err-input2">Enter Ext</p>
                    )}
                  </div>
                </div>
                {/* <div className="d-flex">
                  <div className="d-flex">
                    <p
                    >
                      Telephone
                    </p>
                  </div>
                </div> */}
                {/* <PhoneInput
                  country="in"
                  className="border-btm"
                  value={teleCode}
                  onChange={(e) => {
                    setTeleCode(e);
                  }}
                />
                {teleCode?.length == 0 && teleCodeErr && (
                  <p
                    style={{
                      marginRight: "30px",
                    }}
                    className="err-input2 pe-3"
                  >
                    Enter Telephone No
                  </p>
                )} */}
              </div>
              {/* <div className="px-2 ext-style">
                <label> Ext</label>
                <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 3) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 3) {
                      e.target.setCustomValidity("");

                      setExt(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={3}
                  onChange={(e) => setExt(e.target.value)}
                  value={ext}
                  type="number"
                  className="m-0 border-0"
                  placeholder="Ext"
                />

                {ext?.length == 0 && extErr && (
                  <p className="err-input2">Enter Ext</p>
                )}
              </div> */}
            </Col>
            <Col md={6} xs={12} className="pe-3 my-2">
              <label>Fax</label>
              {/* <input
                className="form-control"
                type="text"
                placeholder=""
                onChange={(e) => setFax(e.target.value)}
                value={fax}
              /> */}
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 10) {
                    e.target.setCustomValidity("invalid Ext");
                  } else if (e.target.value.length == 10) {
                    e.target.setCustomValidity("");

                    setFax(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={10}
                onChange={(e) => setFax(e.target.value)}
                value={fax}
                type="number"
                className="form-control"
                placeholder="fax"
              />
              {fax?.length == 0 && faxErr && (
                <p className="err-input2 pe-3">Enter Fax</p>
              )}
            </Col>
          </div>
        </Form>
      </div>

      <div div className="pe-3 my-2 d-flex-al-je ben_design">
        <Link to="/dashboard" className="btn brn-can">
          Cancel
        </Link>
        {types !== "edit" && profileStatus == "1" && (
          <Button className=" btn brn-can text-dark" onClick={skipHandle}>
            Skip & Next
          </Button>
        )}
        <Button
          className=" btn sub-btn"
          onClick={submitHandle}
          disabled={btn ? true : false}
        >
          {types == "edit" ? "Save" : "Save & Next"}
        </Button>
      </div>
    </div>
  );
};
export default AddTranspoint;
