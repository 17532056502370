import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Close_icon from "../../../assets/Icons/Normal Icons/Close.svg";
import { AdPie1, AdPie2, AdPie3 } from "../../../assets/img";
import info_icon from "../../../assets/Icons/Normal Icons/Info.svg";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";

function Ad_Dashboard() {
  const [adInfoShow, setAdInfoShow] = useState(false);
  const infoClickHandler = () => setAdInfoShow(!adInfoShow);

  let adInfoShowCont;
  if (adInfoShow) {
    adInfoShowCont = (
      <div className="ad-dash-inner-modal-cont p-3 ">
        <div className="d-flex justify-content-between pb-2">
          <p className="fnt-fam-f3 t3 w-100 d-flex justify-content-between align-items-center">
            <span className="fs-1r">Active Suppliers</span>
            <span>
              <img src={Close_icon} style={{ width: "14px" }} />
            </span>
          </p>
        </div>
        <p className="fnt-fam-f2 fs-15px">Who got the Award or Quotation</p>
      </div>
    );
  }
  return (
    <Col>
      <TopHeader type="admin-dashboard" />
      <TopBar />
      <div className="py-3 px-2  light-vilot-bg w-100">
        <div className="wid-92">
          <div className="ad_dash_img_card_outer-cont mid-sc-res d-sm-flex flex-xl-nowrap flex-sm-wrap flex-lg-shrink-1 flex-xl-shrink-1 me-2">
            <Col
              lg={4}
              md={6}
              sm={12}
              style={{ maxWidth: "330px" }}
              className="dashbord-card-cont me-2 flex-xl-shrink-1 d-sm-flex flex-column my-1 p-3 rounded-1"
            >
              <h5 className="fnt-fam-f3 mb-3 fs-15px">Overall Summary</h5>
              <div className="d-sm-flex  mb-auto">
                <Col xs={7} className="mt-3">
                  <img src={AdPie1} className="w-90" />
                </Col>
                <Col xs={5}>
                  <div className="">
                    <p className="py-1 my-1 fs-14px fnt-fam-f2">
                      Total Suppliers
                    </p>
                    <h6 className="py-1 fs-14px fnt-fam-f3 my-1">2,000</h6>
                  </div>
                  <div className="position-relative">
                    <p className="pb-1 mb-1 mt-2 pt-2 fs-14px fnt-fam-f2">
                      Active Suppliers
                    </p>
                    <h6 className="py-1 my-1 fnt-fam-f3 fs-14px d-sm-flex align-items-center">
                      1800
                      <span className="ps-1 pointer" onClick={infoClickHandler}>
                        <img className="wid-15px" src={info_icon} />
                      </span>
                    </h6>
                    {adInfoShowCont}
                  </div>
                </Col>
              </div>
              <ul className=" mt-1 py-1 px-2  ad-pie-fst-li-cont">
                <li className="fs-10px me-1 d-inline-block fnt-fam-f2">
                  Total Suppliers
                </li>
                <li className="fs-10px ms-1 d-inline-block fnt-fam-f2">
                  Active Suppliers
                </li>
              </ul>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              style={{ maxWidth: "330px" }}
              className="dashbord-card-cont me-2 ms-lg-2 flex-xl-shrink-1 d-sm-flex flex-column my-1 p-3"
            >
              <img src={AdPie2} className="w-90 mb-auto" />

              <ul className=" mt-1 py-1 px-2  ad-pie-fst-li-cont">
                <li className="fs-10px me-1 d-inline-block fnt-fam-f2">
                  Total Suppliers
                </li>
                <li className="fs-10px ms-1 d-inline-block fnt-fam-f2">
                  Active Suppliers
                </li>
              </ul>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="dashbord-card-cont ms-lg-2 flex-xl-shrink-1 d-grid p-3 my-1"
            >
              <h5 className="fnt-fam-f3 fs-15px">
                Accounts Status (INR)
                <span className="ps-2 ms-1 mb-1">
                  <img className="wid-15px" src={info_icon} />
                </span>
              </h5>
              <div className="d-grid mb-2 pb-2 w-90">
                <img src={AdPie3} className="w-90" />
              </div>
              <ul className="mt-2  pt-1 px-2 justify-content-center ad-pie-3rd-li-cont">
                <li className="fs-10px   me-2 d-inline-block fnt-fam-f2">
                  Total Invoices
                </li>
                <li className="fs-10px me-2 d-inline-block fnt-fam-f2">Paid</li>
                <li className="fs-10px  me-2 d-inline-block fnt-fam-f2">
                  Yet to Pay
                </li>
                <li className="fs-10px d-inline-block  fnt-fam-f2">Overdue</li>
              </ul>
            </Col>
          </div>
          <div className="d-md-flex my-1 py-1 justify-content-between mid-sc-res">
            <h5 className="fnt-fam-f1 fs-15px fw-bold">Summary</h5>
            <h5 className="opac_half fnt-fam-f1 fs-15px">View All</h5>
          </div>
          <div className="d-md-flex flex-wrap gap-3">
            <button className="bg-primary1 text-white px-4 py-2 rounded-1 border-0 fs-13px fnt-fam-f1">
              Enqiuries (36)
            </button>
            <button className="px-4 py-2 rounded-1 bg-transparent border-0 fs-13px fnt-fam-f1">
              Quotation (12)
            </button>
            <button className="px-4 py-2 rounded-1 bg-transparent border-0 fs-13px fnt-fam-f1">
              Purchase Order(10)
            </button>
            <button className="px-4 py-2 rounded-1 bg-transparent border-0 fs-13px fnt-fam-f1">
              Advance Shipping Notice (05)
            </button>
            <button className="px-4 py-2 rounded-1 bg-transparent border-0 fs-13px fnt-fam-f1">
              Delivery Challan(10)
            </button>
            <button className="px-4 py-2 rounded-1 bg-transparent border-0 fs-13px fnt-fam-f1">
              Goods Received Note (20)
            </button>
            <button className="px-4 py-2 rounded-1 bg-transparent border-0 fs-13px fnt-fam-f1">
              Returns (10)
            </button>
          </div>
          <div className="dashbord-card-cont mid-sc-res">
            <div className="px-4 table-scroll-horizontal over-flow-x-scroll my-2 w-100">
              <table className="table table-borderless min-w-1kpx">
                <thead>
                  <tr className="fs-14px fnt-fam-f1 dash-th-hgt">
                    <th>KH Division</th>
                    <th>#ID</th>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Supplier Name</th>
                    <th>Closing Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="fs-14px fnt-fam-f1 dash-tr-hgt">
                    <td>GD</td>
                    <td className="px-0">#123456</td>
                    <td className="px-0">28.01.2023</td>
                    <td className="px-0">Lorem ipsum dolor sit amet</td>
                    <td className="px-0">Ai Aradmoon Trading Co.LLC</td>
                    <td className="px-0">28.01.2024</td>
                    <td className="px-0">
                      <button
                        type="button"
                        className="btn btn-outline-primary po-card-btn fs-14px"
                      >
                        Yet to Respond
                      </button>
                    </td>
                  </tr>
                  <tr className="fs-14px fnt-fam-f1 dash-tr-hgt">
                    <td>FWD</td>
                    <td className="px-0">#52140300</td>
                    <td className="px-0">28.01.2023</td>
                    <td className="px-0">Lorem ipsum dolor sit amet</td>
                    <td className="px-0">
                      HEAVY EQUIPMENT MAINTENANCE AND TRADING CO LLC
                    </td>
                    <td className="px-0">20.02.2024</td>
                    <td className="px-0">
                      <button
                        type="button"
                        className="btn warning-button po-card-btn"
                      >
                        Responded
                      </button>
                    </td>
                  </tr>
                  <tr className="fs-14px fnt-fam-f1 dash-tr-hgt">
                    <td>LGD</td>
                    <td className="px-0">#96254137</td>
                    <td className="px-0">12-02-2023</td>
                    <td className="px-0">Lorem ipsum dolor sit amet</td>
                    <td className="px-0">
                      AL- SUWAIHARAH TRADING & CONTRACTING
                    </td>
                    <td className="px-0">12-03-2024</td>
                    <td className="px-0">
                      <button
                        type="button"
                        className="btn btn-outline-dark po-card-btn fs-14px"
                      >
                        Closed
                      </button>
                    </td>
                  </tr>
                  <tr className="fs-14px fnt-fam-f1 dash-tr-hgt">
                    <td>LGD</td>
                    <td className="px-0">#123476</td>
                    <td className="px-0">10-02-2023</td>
                    <td className="px-0">Lorem ipsum dolor sit amet</td>
                    <td className="px-0">ARAB ADVANCED TECHNOLOGIES LLC</td>
                    <td className="px-0">12-03-2024</td>
                    <td className="px-0">
                      <button
                        type="button"
                        className="btn btn-outline-success fs-14px po-card-btn"
                      >
                        Award Received
                      </button>
                    </td>
                  </tr>
                  <tr className="fs-14px fnt-fam-f1 dash-tr-hgt">
                    <td>GD</td>
                    <td className="px-0">#123456</td>
                    <td className="px-0">28.01.2023</td>
                    <td className="px-0">Lorem ipsum dolor sit amet</td>
                    <td className="px-0">Ai Aradmoon Trading Co.LLC</td>
                    <td className="px-0">28.01.2024</td>
                    <td className="px-0">
                      <button
                        type="button"
                        className="btn btn-outline-danger fs-14px po-card-btn"
                      >
                        Decline
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-md-flex justify-content-between mid-sc-res">
            <h5 className="fnt-fam-f1 fs-15px fw-bold">
              Accounts status (INR)
            </h5>
            <h5 className="opac_half fnt-fam-f1 fs-15px">View Report</h5>
          </div>
          <div className="dashbord-card-cont p-3 d-sm-flex flex-wrap mid-sc-res">
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Total Orders: <span className="fw-bold">07</span>
              </p>
              <p className="fs-15px fw-bold">10,000</p>
            </div>
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Total Invoices: <span className="fw-bold">10</span>
              </p>
              <p className="fs-15px text-primary fw-bold">10,00,000</p>
            </div>
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Payment Received: <span className="fw-bold">06</span>
              </p>
              <p className="fs-15px fw-bold text-success">7,00,000</p>
            </div>
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Pending Payments: <span className="fw-bold">04</span>
              </p>
              <p className="fs-15px aqua-text fw-bold">3,00,000</p>
            </div>
            <div className="p-2 wid-220px">
              <p className="fs-15px">
                Payment Overdues: <span className="fw-bold">02</span>
              </p>
              <p className="fs-15px fw-bold text-danger">1,30,000</p>
            </div>
          </div>

          <div className="d-md-flex flex-wrap ">
            <Col
              sm={12}
              md={12}
              lg={6}
              className="d-md-flex justify-content-between mt-2 pt-3 pe-4"
            >
              <h5 className="fnt-fam-f1 fs-15px fw-bold">Deliveries: 42</h5>
              <h5 className="opac_half fnt-fam-f1 pe-2 fs-15px">View All</h5>
            </Col>
            <div className="dashbord-card-cont m-r-14px my-1 dash-btm-cards-cont  mid-sc-res py-2 d-sm-flex  flex-column flex-wrap">
              <div className="py-2 w-100">
                <div className="d-sm-flex border-btm ">
                  <p className="dash-card-choice-cont text-center py-2 dash-choice-active">
                    In Transit (12)
                  </p>
                  <p className="dash-card-choice-cont text-center py-2">
                    Delivered
                  </p>
                  <p className="dash-card-choice-cont text-center py-2">
                    GRN with OSD
                  </p>
                  <p className="dash-card-choice-cont text-center py-2">
                    Return
                  </p>
                </div>
              </div>
              <div className="border-btm my-1 w-100 px-3 py-2">
                <div className="d-md-flex py-2">
                  <p className="bor-thick-right wid-141px me-3">
                    PO No:DC02545
                  </p>
                  <p className="bor-thick-right wid-141px me-3">DC No:1001</p>
                  <p className="wid-141px">DC Date:1001</p>
                </div>
                <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Ts-501 plane type cutting m/c 25
                </h5>
                <div className="d-md-flex py-2">
                  <p className="wid-141px me-3">KH Division:LGD</p>
                  <p className="wid-141px me-3">ETA:05-03-2023</p>
                </div>
              </div>
              <div className="border-btm my-1 w-100 px-3 py-2">
                <div className="d-md-flex py-2">
                  <p className="bor-thick-right wid-141px me-3">
                    PO No:DC02545
                  </p>
                  <p className="bor-thick-right wid-141px me-3">DC No:1001</p>
                  <p className="wid-141px">DC Date:1001</p>
                </div>
                <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Ts-501 plane type cutting m/c 25
                </h5>
                <div className="d-md-flex py-2">
                  <p className="wid-141px me-3">KH Division:LGD</p>
                  <p className="wid-141px me-3">ETA:05-03-2023</p>
                </div>
              </div>
              <div className="my-1 px-3 w-100 py-2">
                <div className="d-md-flex py-2">
                  <p className="bor-thick-right wid-141px me-3">
                    PO No:DC02545
                  </p>
                  <p className="bor-thick-right wid-141px me-3">DC No:1001</p>
                  <p className="wid-141px">DC Date:1001</p>
                </div>
                <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Ts-501 plane type cutting m/c 25
                </h5>
                <div className="d-md-flex py-1">
                  <p className="wid-141px me-3">KH Division:LGD</p>
                  <p className="wid-141px me-3">ETA:05-03-2023</p>
                </div>
              </div>
            </div>
            <div className="dashbord-card-cont  my-1 dash-btm-cards-cont mid-sc-res py-2 d-sm-flex  flex-column flex-wrap">
              <div
                style={{ height: "3rem" }}
                className="d-md-flex justify-content-between px-3 py-2 border-btm w-100 align-items-center"
              >
                <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Upcoming Deliveries
                </h5>
                <h5 className="opac_half fnt-fam-f1 fs-15px">View All</h5>
              </div>
              <div className="border-btm w-100 my-1 px-3 py-2">
                <div className="d-md-flex py-2">
                  <p className="bor-thick-right wid-141px me-3">
                    PO No:DC02545
                  </p>
                  <p className="bor-thick-right wid-141px me-3">ASN No:1001</p>
                  <p className="bor-thick-right wid-141px me-3">
                    Shipment No:02
                  </p>
                  <p className="wid-141px me-3">No of items:15</p>
                </div>
                <h5 className="fnt-fam-f1 fs-15px fw-bold">Bonded leather</h5>
                <div className="d-md-flex py-1">
                  <p className="wid-141px me-3">KH Division:FWD</p>
                  <p className="wid-141px me-3">ETD:15-03-2023</p>
                  <p className="wid-141px me-3">ETA:24-03-2023</p>
                </div>
              </div>
              <div className="border-btm  w-100 my-1 px-3 py-2">
                <div className="d-md-flex py-2">
                  <p className="bor-thick-right wid-141px me-3">
                    PO No:DC02545
                  </p>
                  <p className="bor-thick-right wid-141px me-3">ASN No:1001</p>
                  <p className="bor-thick-right wid-141px me-3">
                    Shipment No:02
                  </p>
                  <p className="wid-141px">No of items:15</p>
                </div>
                <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Leather Skiving machine
                </h5>
                <div className="d-md-flex py-1">
                  <p className="wid-141px me-3">KH Division:GD</p>
                  <p className="wid-141px me-3">ETD:25-03-2023</p>
                  <p className="wid-141px me-3">ETA:09-04-2023</p>
                </div>
              </div>
              <div className="my-1 px-3 w-100 py-2">
                <div className="d-md-flex py-2">
                  <p className="bor-thick-right wid-141px me-3">
                    PO No:DC02545
                  </p>
                  <p className="bor-thick-right wid-141px me-3">ASN No:1001</p>
                  <p className="bor-thick-right wid-141px me-3">
                    Shipment No:02
                  </p>
                  <p className="wid-141px me-3">No of items:15</p>
                </div>
                <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Ts-690a 30cm hot melt roll coater
                </h5>
                <div className="d-md-flex py-1">
                  <p className="wid-141px me-3">KH Division:LGD</p>
                  <p className="wid-141px me-3">ETD:05-04-2023</p>
                  <p className="wid-141px me-3">ETA:08-04-2023</p>
                </div>
              </div>
            </div>
            <div className="dash-btm-cards-cont mid-sc-res dashbord-card-cont p-3 my-4">
              <h5 className="fnt-fam-f1 fs-15px fw-bold">Invite Supplier</h5>
              <p className=" fnt-fam-f2 fs-14px ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ac
                gravida lorem. Curabitur scelerisque mi vel tellus volutpat, non
                malesuada nunc ornare. In eu erat sit amet ex iaculis consequat.
              </p>
              <div className="d-sm-flex justify-content-end">
                <button className="bg-primary1 fnt-fam-f2 small-blue-button text-white border-0">
                  View List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </Col>
  );
}

export default Ad_Dashboard;
