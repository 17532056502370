import { Col, Image, Table } from "react-bootstrap";
import { PersonImg } from "../assets/img";
function GeneralCommunication({ details, type }) {
  // console.log("details", details);
  return (
    <>
      {type == "not" && (
        <Col
          className="px-3 px-lg-0 pt-3 choos-profile-tables"
          xs={12}
          md={12}
          lg={12}
        >
          <h5 className="font-col-sec my-2 py-1">Communication</h5>
          <div className="tbale-scroll-horizontal table-sm w-100 py-2 my-1">
            <Table className="px-2 mx-3">
              <tbody>
                <tr className="py-2 my-1">
                  <td className="profil-img">
                    <Image
                      src={details?.employee?.EmployeeImage || PersonImg}
                      // src="https://picsum.photos/110/110"
                      className="image-placeholder rounded-1"
                    />
                  </td>
                  <td className="px-2">
                    <h5 className="text-dark">
                      {details?.employee?.EmployeeName}
                    </h5>
                    <p className="sub-title">Department</p>
                    <p>
                      {details?.employee?.department?.mst_dept?.DepartmentName}
                    </p>
                  </td>
                  <td className="px-2">
                    <p className="sub-title">Designation</p>
                    <p>
                      {
                        details?.employee?.department?.designation
                          ?.DesignationName
                      }
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      )}
      {type == "acknowledgement" && (
        <Col
          xs={12}
          md={12}
          lg={12}
          className="px-3 px-lg-0 pt-3 choos-profile-tables"
        >
          <h5 style={{ color: "#247395" }}>Remarks</h5>
          <p className="px-2">{details?.Remarks}</p>
        </Col>
      )}
    </>
  );
}

export default GeneralCommunication;
