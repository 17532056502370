import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { DrivenLogo } from "../assets/img";

const LogInFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex-al-jb fot_des">
      <Col md={4} xs={12}>
        <button
          className="border-0 bg-transparent"
          // onClick={() => navigate("/privacy")}
        >
          Privacy Statement
        </button>
        <span>|</span>

        <button
          className="border-0 bg-transparent"
          // onClick={() => navigate("/terms")}
        >
          Terms of Use
        </button>
      </Col>
      <Col md={4} xs={12}>
        <button className="border-0 bg-transparent">
          @2022 KH Groups. All Rights Reserved.
        </button>
      </Col>
      <Col md={4} xs={12}>
        <button className="border-0 bg-transparent">
          Developed by <Image src={DrivenLogo} />
        </button>
      </Col>
    </div>
  );
};
export default LogInFooter;
