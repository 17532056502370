import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import POInfo from "./POInfo";
import POTermsAndCondition from "./POTerms&Cond";
import POCommunication from "./POCommunication";
import POTabSec from "../../component/PurchaseOrder/PurchaseOrderTabSec";
import POScope from "./PurchaseOrderScope";
import { Col } from "react-bootstrap";
import LogInFooter from "../../logincomponent/LogInFooter";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import moment from "moment/moment";
import { ToWords } from "to-words";

function PurchaseOrderView() {
  const navigate = useNavigate();
  const [currentActive, setCurrentActive] = useState("scope");
  const location = useLocation();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const data = location?.state?.data;
  const [list, setList] = useState([]);
  const [values, setValue] = useState([]);
  const [Amount, setAmount] = useState();
  const toWords = new ToWords();
  // console.log(data);

  const getView = () => {
    let detail = {
      factory_id: data?.FactoryID,
      type: data?.type,
    };

    detail.purchase_order_id = data?.purchase_order_id
      ? data?.purchase_order_id
      : data?.purchase_order_id;

    api.purchaseOrderView(detail).then((res) => {
      if (res?.status == "success") {
        setList(res?.purchase_order);

        if (res?.purchase_order?.material_purchase_order_details) {
          res?.purchase_order?.material_purchase_order_details.map((item) => {
            setValue(item?.Price);
          });
        }

        if (res?.purchase_order?.leather_purchase_order_details) {
          res?.purchase_order?.leather_purchase_order_details.map((item) => {
            setValue(item?.Price);
          });
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    if (data) {
      getView();
    }
  }, []);

  // Method 1
  // const summed = arr.reduce((acc, cur, i) => {
  //   const item = i > 0 && acc.find(({name}) => name === cur.name)
  //   if (item) item.value += cur.value;
  //   else acc.push({ name: cur.name, value: cur.value }); // don't push cur here
  //   return acc;
  // }, [])
  // console.log(arr); // not modified
  // console.log(summed);

  // Method 2
  //   var total = 0;
  // arr.forEach(item => {
  //     total += item.value;
  // });

  let currentContent;
  let footer_class;
  switch (currentActive) {
    case "terms":
      currentContent = <POTermsAndCondition list={list} data={data} />;
      break;
    case "communication":
      currentContent = <POCommunication list={list} data={data} type={"not"} />;
      footer_class = "footer-cont-res";
      break;
    case "scope":
      currentContent = (
        <POScope list={list} data={data} setAmount={setAmount} />
      );
      break;
    // case "purchase-order-info":
    //   currentContent = <POInfo />;
    //   footer_class = "footer-cont-res";
    //   break;
    case "acknowledgement":
      currentContent = (
        <POCommunication list={list} data={data} type={"acknowledgement"} />
      );
      footer_class = "footer-cont-res";
      break;
    default:
      currentContent = (
        <POScope list={list} data={data} setAmount={setAmount} />
      );
  }

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="purchase-order" />
      <div className="d-md-flex min-ht-80">
        <div className="ps-2 ms-2 py-2 bor-right po-view-side-cont">
          {/* <h5 className="fnt-fam-f3 py-1 my-1 fs-15 text-uppercase">
            Fortune promoseven llc (A division of NUkhaba press disbution and
            release)
          </h5> */}
          {/* <div className="d-flex py-2">
            <Col md={4}>
              <p>PO ID </p>
            </Col>
            <Col>
              <p>
                :{" "}
                {list?.MaterialPurchaseOrderID || list?.LeatherPurchaseOrderID}
              </p>
            </Col>
          </div> */}
          <div className="d-flex py-2">
            <Col md={4}>
              <p>PO REF No </p>
            </Col>
            <Col>
              <p>
                :{" "}
                {list?.MaterialPurchaseOrderNo || list?.LeatherPurchaseOrderNo}
              </p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4}>
              <p>PO Date </p>
            </Col>
            <Col>
              <p>
                :{" "}
                {list?.MaterialPurchaseOrderDate
                  ? moment(list?.MaterialPurchaseOrderDate).format(
                      "DD-MMM-YYYY"
                    )
                  : list?.LeatherPurchaseOrderDate
                  ? moment(list?.LeatherPurchaseOrderDate).format("DD-MMM-YYYY")
                  : ""}
              </p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4}>
              <p>Status </p>
            </Col>
            <Col>
              <p>
                :{" "}
                {list?.Sup_Approval == "A "
                  ? "Accepted"
                  : list?.Sup_Approval == "P "
                  ? "Yet to Acknowledge"
                  : list?.Sup_Approval == "D "
                  ? "Declined"
                  : list?.Sup_Approval == "C "
                  ? "Accepetd"
                  : "Yet to Acknowledge"}
              </p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4}>
              <p>Currency</p>
            </Col>
            <Col>
              <p>:{/* {list?.currency?.CurrencyCode} */} INR</p>
            </Col>
          </div>
          {/* <div className="d-flex py-2">
            <Col md={3}>
              {/* <p>Currency Exchange</p> 
              <p>Exchange Rate to INR</p>
            </Col>
            <Col>
              <p>: {list?.ExRate ? Number(list?.ExRate).toFixed(2) : ""}</p>
            </Col>
          </div> */}
          <div className="d-flex py-2">
            <Col md={4}>
              <p>PO Value</p>
            </Col>
            <Col>
              <p>: {Amount ? Amount.toFixed(2) : ""}</p>
            </Col>
          </div>

          {/* <div className="d-flex py-2">
            <Col md={3}>
              <p>Payment Received INR</p>
            </Col>
            <Col>
              <p>: {""}</p>
            </Col>
          </div> */}
          <div className="d-flex py-2">
            <Col md={4}>
              <p>Buyer Name</p>
            </Col>
            <Col>
              <p>: {list?.buyer?.BuyerName}</p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4}>
              <p>Issued by</p>
            </Col>
            <Col>
              <p>: {list?.employee?.EmployeeName}</p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4}>
              <p>KH Divison</p>
            </Col>
            <Col>
              <p>
                :{" "}
                {+list?.FactoryID == 2
                  ? "GD"
                  : +list?.FactoryID == 4
                  ? "FWD"
                  : +list?.FactoryID == 5
                  ? "LGD"
                  : ""}
              </p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4}>
              <p>MOS</p>
            </Col>
            <Col>
              <p>: {list?.shipment_mode?.ReferenceDetailsName}</p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4} style={{ width: "34.333333%" }}>
              <p>Delivery Place</p>
            </Col>
            <Col>
              <p>
                :{" "}
                {list?.ship_to?.AddressLine1 +
                  "  " +
                  list?.ship_to?.AddressLine2 +
                  "  " +
                  list?.ship_to?.AddressLine3}
              </p>
            </Col>
          </div>
          <div className="d-flex py-2">
            <Col md={4} style={{ width: "34.333333%" }}>
              <p>Delivery Term</p>
            </Col>
            <Col>
              <p>: {list?.delivery_term?.ReferenceDetailsName}</p>
            </Col>
          </div>
          <div className="d-flex gap-2 py-2">
            <Col md={4} style={{ width: "34.333333%" }}>
              <p className="text-nowrap">Payment Term</p>
            </Col>
            <Col>
              <p>: {list?.payment_term?.ReferenceDetailsName}</p>
            </Col>
          </div>
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">PO ID </span>: {"  "}
            {list?.MaterialPurchaseOrderID || list?.LeatherPurchaseOrderID}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">PO REF No </span>: {"  "}
            {list?.MaterialPurchaseOrderNo}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">PO Date </span>: {"  "}
            {list?.MaterialPurchaseOrderDate
              ? moment(list?.MaterialPurchaseOrderDate).format("DD-MMM-YYYY")
              : list?.LeatherPurchaseOrderDate
              ? moment(list?.LeatherPurchaseOrderDate).format("DD-MMM-YYYY")
              : ""}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Status </span>: {"  "}{" "}
            {list?.Approval == "A"
              ? "Accepted"
              : list?.Approval == "P"
              ? "Yet to Acknowledged"
              : list?.Approval == "D"
              ? "Declined"
              : list?.Approval == "C"
              ? "Cancelled"
              : null}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Currency </span>: {"  "}
            {list?.currency?.CurrencyCode}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Currency Exchange</span>: {"  "}
            {list?.ExRate ? parseInt(list?.ExRate).toFixed(2) : ""}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">PO Value INR </span>: {"  "}
            {values ? parseInt(values).toFixed(2) : ""}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Invoiced Value INR: </span>
            {" - "}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Payment Received INR </span>: {"  "}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Buyer Name </span>: {"  "}
            <span> {list?.buyer?.BuyerName}</span>
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Issued by </span>:{""}
            {list?.employee?.EmployeeName}
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Incoterms: </span>DDP
          </p> */}
          {/* <p className="py-1 my-1 ">
            <span className="opac_half">Delivery Place </span>: {"  "}
            {list?.ship_to?.AddressLine1}
          </p> */}
        </div>
        <div className="p-3 flex-wrap enqiry-view-2nd-sec">
          <div className="d-flex justify-content-between">
            <div>
              <POTabSec
                currentActive={currentActive}
                setCurrentActive={setCurrentActive}
              />
            </div>
            <div>
              <button
                className="rounded-2 border-0 p-2 bg-hash"
                style={{ fontFamily: "f2" }}
                onClick={() => navigate("/purchase-order/list")}
              >
                Close
              </button>
            </div>
          </div>
          {currentContent}
        </div>
      </div>
      <div className={`${footer_class} rg-footer `}>
        <LogInFooter />
      </div>
    </>
  );
}

export default PurchaseOrderView;
