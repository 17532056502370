import moment from "moment/moment";
import Badge from "react-bootstrap/Badge";

function User_Dash_Table({
  dummy_data,
  num,
  data,
  asnList,
  grnList,
  dcList,
  rnList,
}) {
  const commaValue = (price) => {
    let amount = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return price;
  };
  return (
    <div className="dashbord-card-cont mid-sc-res">
      <div className="px-4 table-scroll-horizontal over-flow-x-scroll my-2 w-100">
        <table className="table table-borderless min-w-1kpx">
          <thead className="fs-13px fnt-fam-f1 dash-th-hgt">
            <tr>
              {dummy_data.map((title, ind) => {
                return (
                  <th className="text-center px-0" key={ind}>
                    {title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.FactoryName}</td>
                    <td>
                      {item?.LeatherPurchaseOrderNo ||
                        item?.MaterialPurchaseOrderNo ||
                        item?.purchase_order_no}
                    </td>
                    <td>
                      {moment(
                        item?.LeatherPurchaseOrderDate ||
                          item?.MaterialPurchaseOrderDate ||
                          item?.purchase_order_date
                      ).format("DD-MMM-YYYY")}
                      {/* {item?.LeatherPurchaseOrderNo ||
                        item?.MaterialPurchaseOrderNo ||
                        item?.purchase_order_no} */}
                    </td>
                    <td>
                      {item?.total_price
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {/* {item?.CurrencyName?.startsWith("United")
                        ? " USD"
                        : item?.CurrencyName?.startsWith("United Arab")
                        ? " AE"
                        : item?.CurrencyName?.startsWith("Canada")
                        ? " CA"
                        : item?.CurrencyName?.startsWith("Australia")
                        ? " AU"
                        : " INR"} */}
                      INR
                    </td>
                    {/* <td>
                    
                    </td> */}
                    <td>
                      {item?.type == "L"
                        ? "Leather"
                        : item?.type == "M"
                        ? "Material"
                        : ""}
                    </td>
                    {/* <td>{item?.date}</td> */}
                    <td>
                      <p
                        className={
                          item?.Sup_Approval == "A "
                            ? "text-success"
                            : item?.Sup_Approval == "P "
                            ? "text-warning"
                            : item?.Sup_Approval == "D "
                            ? "text-danger"
                            : item?.Sup_Approval == "C "
                            ? "text-success"
                            : "text-warning"
                        }
                      >
                        {item?.Sup_Approval == "A "
                          ? "Accepted"
                          : item?.Sup_Approval == "P "
                          ? "Yet to Acknowledge"
                          : item?.Sup_Approval == "D "
                          ? "Decline"
                          : item?.Sup_Approval == "C "
                          ? "Accepetd"
                          : "Yet to Acknowledge"}
                      </p>
                    </td>
                  </>

                  {/* <td>
                    {num == 0
                      ? "007"
                      : num == 1
                      ? "007"
                      : num == 2
                      ? "007"
                      : num == 3
                      ? "007"
                      : num == 4
                      ? "007"
                      : num == 5
                      ? "007"
                      : ""}
                  </td>
                  <td className="px-0">
                    {num == 0
                      ? "#123456"
                      : num == 1
                      ? "#12344"
                      : num == 2
                      ? "#12344"
                      : num == 3
                      ? "#12344"
                      : num == 4
                      ? "#12344"
                      : num == 5
                      ? " "
                      : ""}
                  </td>
                  <td className="px-0">
                    {num == 0
                      ? "28.01.2023"
                      : num == 1
                      ? "28.01.2023"
                      : num == 2
                      ? "28.01.2023"
                      : num == 3
                      ? "28.01.2023"
                      : num == 4
                      ? "28.01.2023"
                      : num == 5
                      ? " "
                      : ""}
                  </td>
                  {num == 0 ? null : (
                    <td className="px-0">
                      {
                        // num == 0
                        //   ? "John"
                        //   :
                        num == 1
                          ? "678-1"
                          : num == 2
                          ? "9286827"
                          : num == 3
                          ? "9286827"
                          : num == 4
                          ? "2785672"
                          : num == 5
                          ? "87829"
                          : ""
                      }
                    </td>
                  )}

                  <td className="px-0">
                    {num == 0
                      ? "Material"
                      : num == 1
                      ? "Material"
                      : num == 2
                      ? "678-1"
                      : num == 3
                      ? "678-1"
                      : num == 4
                      ? "98687"
                      : num == 5
                      ? " "
                      : ""}
                  </td>
                  <td className="px-0">
                    {num == 0
                      ? "28.01.2024"
                      : num == 1
                      ? "2"
                      : num == 2
                      ? "Material"
                      : num == 3
                      ? "Material"
                      : num == 4
                      ? "678-1"
                      : num == 5
                      ? "Material"
                      : ""}
                  </td>
                  {num == 2 ? (
                    <>
                      <td>{"05/28/2023"}</td>
                      <td>{"08/28/2023"}</td>
                    </>
                  ) : null}

                  {num == 3 ? (
                    <>
                      <td>{"0976"}</td>
                    </>
                  ) : null}

                  {num == 4 ? (
                    <>
                      <td>{"Material"}</td>
                    </>
                  ) : null} */}

                  {/* <td></td> */}
                  {/* {num == 3 ? null : (
                    <td className="px-0">
                      {num == 0 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary po-card-btn fs-14px"
                          >
                            Status Received
                          </button>
                          <Badge bg="secondary" className="ms-2 py-1">
                            New
                          </Badge>
                        </>
                      ) : num == 1 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary po-card-btn fs-14px"
                          >
                            Yet to Respond
                          </button>
                        </>
                      ) : num == 2 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary po-card-btn fs-14px"
                          >
                            Yet to Dispatch
                          </button>
                        </>
                      ) : num == 4 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-secondary po-card-btn fs-14px"
                          >
                            Resolved
                          </button>
                        </>
                      ) : null}
                    </td>
                  )} */}
                </tr>
              );
            })}
            {asnList?.data?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>
                      {moment(item?.purchase_order_date).format("DD-MMM-YYYY")}
                    </td>
                    <td>{item?.FactoryName}</td>
                    <td>{item?.purchase_order_no}</td>

                    <td>
                      {item?.type == "M"
                        ? "Material"
                        : item?.type == "L"
                        ? "Leather"
                        : ""}
                    </td>
                    <td>{item?.shipping_count}</td>
                    <td>
                      <p
                        className={
                          item?.Sup_Approval == "A "
                            ? "text-success"
                            : item?.Sup_Approval == "P "
                            ? "text-warning"
                            : item?.Sup_Approval == "D "
                            ? "text-danger"
                            : item?.Sup_Approval == "C "
                            ? "text-success"
                            : "text-warning"
                        }
                      >
                        {item?.Sup_Approval == "A "
                          ? "Accepted"
                          : item?.Sup_Approval == "P "
                          ? "Pending"
                          : item?.Sup_Approval == "D "
                          ? "Decline"
                          : item?.Sup_Approval == "C "
                          ? "Accepetd"
                          : "Yet to Acknowledge"}
                      </p>
                    </td>
                    {/* <td>{item?.status}</td> */}
                  </>
                </tr>
              );
            })}
            {grnList?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.factory?.FactoryName}</td>
                    <td>
                      {item?.LeatherGoodsReceiptNoteNo ||
                        item?.MaterialGoodsReceiptNoteNo}
                    </td>
                    <td>
                      {item?.LeatherGoodsReceiptNoteDate
                        ? moment(item?.LeatherGoodsReceiptNoteDate).format(
                            "DD-MMM-YYYY"
                          )
                        : item?.MaterialGoodsReceiptNoteDate
                        ? moment(item?.MaterialGoodsReceiptNoteDate).format(
                            "DD-MMM-YYYY"
                          )
                        : ""}
                    </td>
                    <td>{item?.DCNo}</td>
                    {/* <td>{item?.status}</td> */}
                    <td>
                      {item?.type == "M"
                        ? "Material"
                        : item?.type == "L"
                        ? "Leather"
                        : ""}
                    </td>
                    {/* <td>
                      {item?.Approval == "A"
                        ? "Approved"
                        : item?.Approval == "C"
                        ? "Cancelled"
                        : ""}
                    </td> */}
                  </>
                </tr>
              );
            })}
            {dcList?.data?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.FactoryName}</td>
                    <td>{item?.DCNNo}</td>
                    <td>
                      {item?.DCDate
                        ? moment(item?.DCDate).format("DD-MMM-YYYY")
                        : ""}
                    </td>
                    <td>{item?.InvoiceNo}</td>
                    {/* <td>{item?.status}</td> */}
                    <td>
                      {item?.MaterialType == "M"
                        ? "Material"
                        : item?.MaterialType == "L"
                        ? "Leather"
                        : ""}
                    </td>
                    {/* <td>{item?.etd}</td>
                    <td>{item?.eta}</td> */}
                    <td>
                      <p
                        className={
                          item?.Approval == "1 "
                            ? "text-primary"
                            : item?.Approval == "2 "
                            ? "text-danger"
                            : item?.Approval == "3 "
                            ? "text-success"
                            : item?.Approval == "4 "
                            ? "text-primary"
                            : ""
                        }
                      >
                        {" "}
                        {item?.Approval == "1 "
                          ? "Transmit"
                          : item?.Approval == "2 "
                          ? "Dispatch"
                          : item?.Approval == "3 "
                          ? "Delivered"
                          : item?.Approval == "4 "
                          ? "Completed"
                          : ""}
                      </p>
                    </td>
                  </>
                </tr>
              );
            })}
            {rnList?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.FactoryName}</td>
                    <td>{item?.ReturnToSupplierNo}</td>
                    <td>
                      {item?.ReturnToSupplierDate
                        ? moment(item?.ReturnToSupplierDate).format(
                            "DD-MMM-YYYY"
                          )
                        : ""}
                    </td>
                    <td>{item?.GRNNo}</td>
                    <td>{item?.DCNo}</td>
                    <td>{item?.PONo}</td>
                    <td>{item?.type == "M" ? "Material" : "Leather"}</td>
                    {/* <td>{item?.eta}</td>
                    <td>
                      <p
                        className={
                          item?.Approval == "A"
                            ? "text-success"
                            : item?.Approval == "C"
                            ? "text-danger"
                            : ""
                        }
                      >
                        {item?.Approval == "A"
                          ? "Approved"
                          : item?.Approval == "C"
                          ? "Cancelled"
                          : ""}
                      </p>
                    </td> */}
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default User_Dash_Table;
