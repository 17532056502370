import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import LogInFooter from "../../logincomponent/LogInFooter";
import EnquiryPagination from "../../component/Enquiry/EnquiryPagination";
import QuotationFilter from "../../component/Quotation/QuotationFilter";
import QuotationCard from "../../component/Quotation/QuotationCard";

function QuotationList() {
  return (
    <>
      <TopBar />
      <TopHeader type="quotaion-list" />
      <div className="enquiry-list-cont my-3 py-2">
        <QuotationFilter />
        <QuotationCard type="primary" /> <QuotationCard type="danger" />
        <QuotationCard type="warning" /> <QuotationCard type="success" />
        <EnquiryPagination />
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default QuotationList;
