import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
import { Logo, ProfileImg, FavLogo } from "../assets/img";
import Navbar from "react-bootstrap/Navbar";
import { IoMdNotificationsOutline, IoMdQrScanner } from "react-icons/io";

import Dropdown from "react-bootstrap/Dropdown";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
import useUser from "../constants/Storage/userData";
const TopBar = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const logOut = () => {
    api.logout().then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        localStorage.clear();
        window.location.reload();
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <Navbar className="w-100 top_bar d-flex-al">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Col md={4} xs={4}>
        <div className="logo_img">
          <Image src={Logo} />
        </div>
      </Col>
      <Col md={8} xs={8}>
        <div className="d-flex-al justify-content-end   top_link">
          {/* <Link className="noti_icon" to="/notification">
            <IoMdNotificationsOutline />
            <div className="notif-status">1</div>
          </Link> */}
          {/* <Link className="d-sm-block d-none">
            <IoMdQrScanner />
          </Link> */}
          <Dropdown className="user-bg ">
            <Dropdown.Toggle className=" d-flex-al" id="dropdown-basic">
              <Link>
                <Image
                  src={user?.img_url || FavLogo}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100%",
                  }}
                />
              </Link>
              <div className="d-md-block d-none">
                <h6 className="text-uppercase">
                  {user?.email == user?.supplier?.CompanyEmail
                    ? user?.supplier?.SupplierName
                    : user?.user_name}{" "}
                </h6>
                <p>{user?.supplier?.SupplierCode}</p>
                <p>
                  {/* {user?.email == user?.supplier?.Email
                    ? user?.supplier?.SupplierCode
                    : user?.emp_id} */}
                </p>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <Link to="/profile"> Profile</Link>
              </Dropdown.Item> */}
              <Dropdown.Item>
                <button style={{ marginLeft: "15px" }} onClick={logOut}>
                  Logout
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
      {/* <Modal
                    id="#sucess"
                    className="otp-modal"
                    show={RegSuccessModal}
                    size="md"
                    centered
                    onHide={() => setRegSuccessModal(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                  >
                    <div className="new-modal">
                      <Image className="fav-icon" src={FavLogo} />
                      <div className="mt-3">
                        <div className="d-flex-as-jc img_tick ">
                          <Image className="tick" src={Check} />
                          <div className="px-3">
                            <h5 className="fs-14">Registration Request</h5>
                            <p>
                              Your Registration has been sent to KH you will
                              receive the email with link once appoverd by KH
                            </p>
                          </div>
                        </div>
                        <button
                          className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
                          onClick={() =>
                            // navigate("/setpassword", {
                            //   state: {
                            //     data: Otp,
                            //   },
                            // }
                            // )
                            reload()
                          }
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </Modal> */}
    </Navbar>
  );
};

export default TopBar;
