import EnquiryViewScopeTable from "../../component/Enquiry/EnqViewScopeTb";

function EnquiryViewScope() {
  return (
    <>
      <div className="pt-3 px-1 notifi-detail-header-cont">
        <h5 className="font-col-sec my-2 fnt-fam-f2 py-1">Scope</h5>
        <label className="my-2 py-1">
          Description<span className="red-text">*</span>
        </label>
        <p>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one of the more obscure
          Latin words, consectetur,{" "}
        </p>
      </div>
      <p className="float-end opac_half px-3">Max 1500</p>
      <EnquiryViewScopeTable />
      {/* <button className="bg-primary1 text-white border-0 px-3 fnt-fam-f2  py-1 float-end">
        Close
      </button> */}
    </>
  );
}

export default EnquiryViewScope;
