import { useState } from "react";
import { USER_STATUS } from "../Services/constants";

export default function useProfileStatus() {
  const getProfileStatus = () => {
    const userString = JSON.parse(localStorage.getItem(USER_STATUS));

    return userString;
  };

  const [profileStatus, setProfileStatus] = useState(getProfileStatus());

  const saveStatus = (userStatus) => {
    localStorage.setItem(USER_STATUS, JSON.stringify(userStatus));
    setProfileStatus(userStatus);
  };

  return {
    setProfileStatus: saveStatus,
    profileStatus,
  };
}
