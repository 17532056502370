// @ts-nocheck
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";

const TopHeader = (props) => {
  const navigate = useNavigate();
  return (
    <div className="hede-top">
      {props.type === "profile" && (
        <div className="d-flex-al-jb">
          <h4>Master Company Profile</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link to="/mcprofile">Master Company Profile </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "transport" && (
        <div className="d-flex-al-jb">
          <h4>Master Company Profile</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link to="/mcprofile/transport">Transport </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/mcprofile">Master Company Profile </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "certificate" && (
        <div className="d-flex-al-jb">
          <h4>Master Company Profile</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link to="/mcprofile/certificate">Certificate </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/mcprofile">Master Company Profile </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "addreg" && (
        <div className="d-flex-al-jb">
          <h4>Master Company Profile</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link to="/mcprofile/addregistered">Add Registered Address </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/mcprofile">Master Company Profile </Link>{" "}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "e-admin-user" && (
        <div className="d-flex-al-jb">
          <h4>Enterprise Admin</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <AiOutlineHome />
            </li>
            <li>
              <Link to="/dashboard">Enterprise Admin</Link>
            </li>
            <MdOutlineKeyboardArrowRight />
            <li>
              <Link to="/product">Map Product</Link>
            </li>
          </ul>
        </div>
      )}
      {props.type == "enterprise-admin-user" && (
        <div className="d-flex-al-jb">
          <h4>Enterprise Admin</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link to="/enterprise-admin/userlist">Enterprise Admin</Link>
            </li>

            {/* <li>
              <Link to="/product">Map Product</Link>
            </li> */}
            <MdOutlineKeyboardArrowLeft />
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "role" && (
        <div className="d-flex-al-jb">
          <h4>Roles</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link to="/dashboard">Enterprise Admin Roles</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "product-profile" && (
        <div className="d-flex-al-jb">
          <h4>Products Profile</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <AiOutlineHome />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <MdOutlineKeyboardArrowLeft />
            <li>
              <Link to="/product/productList">Products Profile</Link>
            </li>
          </ul>
        </div>
      )}{" "}
      {props.type == "map-product" && (
        <div className="d-flex-al-jb">
          <h4>Map Product</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <AiOutlineHome />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <MdOutlineKeyboardArrowRight />
            <li>
              <Link to="/product/addproduct">Map Product</Link>
            </li>
          </ul>
        </div>
      )}
      {(props.type == "enterprise_admin_userlist" ||
        props.type == "enterprice-admin-detail" ||
        props.type == "enterprice-admin") && (
        <div className="d-flex-al-jb">
          <h4>Enterprise Admin</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <Link>
                {(props.type == "enterprise_admin_userlist" ||
                  props.type == "enterprice-admin-detail") &&
                  "Enterprise Admin"}
                {props.type == "enterprice-admin" &&
                  "Module Permission Settings"}
              </Link>
            </li>
            <MdOutlineKeyboardArrowLeft />
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
          </ul>
        </div>
      )}
      {props.type == "account-settings" && (
        <div className="d-flex-al-jb">
          <h4>Settings</h4>
          <ul className="d-flex-al  bread-crumb  ">
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li>
              <Link>Settings</Link>
            </li>

            <MdOutlineKeyboardArrowLeft />
            <li onClick={() => navigate("/dashboard")}>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "admin-settings" && (
        <div className="d-flex-al-jb">
          <h4>Settings</h4>
          <ul className="d-flex-al  bread-crumb  ">
            <li>
              <AiOutlineHome />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <MdOutlineKeyboardArrowRight />
            <li>
              <Link>Settings</Link>
            </li>
          </ul>
        </div>
      )}
      {props.type == "notification" && (
        <div className="d-flex-al-jb">
          <h4>Notification</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/dashboard">Notification</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "broadcast" && (
        <div className="d-flex-al-jb">
          <h4>BroadCast</h4>
          <ul className="d-flex-al  bread-crumb">
            <li className="font-col-sec">
              <Link to="/admin/broadcast/list">BroadCast</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "enquiry-list" && (
        <div className="d-flex-al-jb">
          <h4>Enquiry Listing</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/enquiry-list" className="font-col-sec">
                Enquiry Listing
              </Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "quotaion-list" && (
        <div className="d-flex-al-jb">
          <h4>Quotation Listing</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/enquiry-list" className="font-col-sec">
                Quotation Listing
              </Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "enquiry-view" && (
        <div className="d-flex-al-jb">
          <h4>Enquiry View</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/enquiry-list">Enquiry View</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "create-quotation" && (
        <div className="d-flex-al-jb">
          <h4>Create Quotation</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/create-quotation">Create Quotation</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "purchase-order" && (
        <div className="d-flex-al-jb">
          <h4>Purchase Order View</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/purchase-order/view">Purchase Order View</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "purchase-order-list" && (
        <div className="d-flex-al-jb">
          <h4 onClick={() => navigate("/dashboard")}>
            <FaArrowLeft /> Purchase Orders{" "}
          </h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/purchase-order/list">Purchase Orders</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "dc" && (
        <div className="d-flex-al-jb">
          <h4>Delivery Challan Create</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/dc">Delivery Challan Create</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "dc_edit" && (
        <div className="d-flex-al-jb">
          <h4>Delivery Challan Edit</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/dc">Delivery Challan Edit</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "dc_view" && (
        <div className="d-flex-al-jb">
          <h4>Delivery Challan View</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/dc">Delivery Challan View</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "dc-list" && (
        <div className="d-flex-al-jb">
          <h4 onClick={() => navigate("/dashboard")}>
            {" "}
            <FaArrowLeft /> Delivery Challan List
          </h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/dc-list">Delivery Challan List</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "dashboard" && (
        <div className="d-flex-al-jb">
          <h4 className="primary1">Dashboard</h4>
        </div>
      )}
      {props.type == "admin-dashboard" && (
        <div className="d-flex-al-jb">
          <h4 className="fnt-fam-f3">Dashboard</h4>
          <li>
            <AiOutlineHome />
          </li>
        </div>
      )}
      {props.type == "shipping-tracker" && (
        <div className="d-flex-al-jb">
          <h4>Logistics Tracker</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/purchase-order/shpping-tracker">Delivery Challan</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "asn" && (
        <div className="d-flex-al-jb">
          <h4 onClick={() => navigate("/dashboard")}>
            <FaArrowLeft /> Shipping Plan
          </h4>
          {/* <h4>Advance Shipping Notices</h4> */}
          <ul className="d-flex-al  bread-crumb">
            <li>
              {/* <Link to="/asn-listing">Advance Shipping Notices</Link> */}
              <Link to="/asn-listing">Shipping Plan</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}{" "}
      {props.type == "asn_view" && (
        <div className="d-flex-al-jb">
          {/* <h4>Advance Shipping Notice</h4> */}
          <h4>Shipping Plan</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/asn/view">SP</Link>
              {/* <Link to="/asn/view">ASN</Link> */}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/asn-listing">Shipping Plan</Link>
              {/* <Link to="/asn-listing">Advance Shipping Notices</Link> */}
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}{" "}
      {props.type == "grn-list" && (
        <div className="d-flex-al-jb">
          <h4 onClick={() => navigate("/dashboard")}>
            <FaArrowLeft /> Goods Received Notes
          </h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/purchase-order/grn">Goods Received Notes</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "grn" && (
        <div className="d-flex-al-jb">
          <h4>Goods Received Note</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/create-quotation">Goods Received Note</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}{" "}
      {props.type == "return-list" && (
        <div className="d-flex-al-jb">
          <h4 onClick={() => navigate("/dashboard")}>
            <FaArrowLeft /> Return Notes
          </h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/create-quotation">Return Notes</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "return-notice" && (
        <div className="d-flex-al-jb">
          <h4>Return Notice</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/enquiry/create-quotation">Goods Received Note</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "proforma-invoice" && (
        <div className="d-flex-al-jb">
          <h4>Proforma Invoice</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice">Proforma Invoice</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "commercial-invoice" && (
        <div className="d-flex-al-jb">
          <h4>Commercial Invoice</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice">Commercial Invoice</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "invoice-list" && (
        <div className="d-flex-al-jb">
          <h4 onClick={(e) => navigate("/dashboard")}>
            <FaArrowLeft /> Proforma Invoice
          </h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/list">Proforma Invoice List</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "invoice-gst" && (
        <div className="d-flex-al-jb">
          <h4>Invoice GST Payment Update View</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/list">Proforma Invoice</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "commercial-invoice-list" && (
        <div className="d-flex-al-jb">
          <h4 onClick={() => navigate("/dashboard")}>
            <FaArrowLeft /> Commercial Invoice
          </h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/list">Commercial Invoice List</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "create-invoice" && (
        <div className="d-flex-al-jb">
          <h4>Proforma Invoice By Upload</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/create-invoice">Proforma Invoice</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "create-invoice-view" && (
        <div className="d-flex-al-jb">
          <h4>Proforma Invoice View</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/create-invoice">Proforma Invoice View</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "create-invoice-edit" && (
        <div className="d-flex-al-jb">
          <h4>Proforma Invoice Edit</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/create-invoice">Proforma Invoice Edit</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
      {props.type == "create-commercial-invoice" && (
        <div className="d-flex-al-jb">
          <h4>Commercial Invoice By Upload</h4>
          <ul className="d-flex-al  bread-crumb">
            <li>
              <Link to="/invoice/create-invoice">Commercial Invoice</Link>
            </li>
            <li>
              <MdOutlineKeyboardArrowLeft />
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <AiOutlineHome />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default TopHeader;
