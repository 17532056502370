import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import CreateQuoTabsec from "../../component/Enquiry/EnqCreateQuoTabsec";
import LogInFooter from "../../logincomponent/LogInFooter";
import QuotationInformation from "./QuotationInfo";
import QuoScopeZoompage from "./QuoScopeZoomPage";
import QuoCommuSec from "./QuoComm";
import QuotationTermsAndCondition from "./QuoTerms";
import QuoScopePage from "./QuotationScope";
import { Col } from "react-bootstrap";
import { useState } from "react";
function CreateQuotation() {
  const [currentActive, setCurrentActive] = useState("quotation-info");
  const [zoomedTable, setZoomedTable] = useState(false);
  let currentContent;
  let footerRes;
  if (currentActive == "quotation-info" || currentActive == "communication") {
    footerRes = "footer-cont-res";
  }
  switch (currentActive) {
    case "terms":
      currentContent = <QuotationTermsAndCondition />;
      break;
    case "communication":
      currentContent = <QuoCommuSec />;
      break;
    case "scope":
      currentContent = <QuoScopePage setZoomedTable={setZoomedTable} />;
      break;
    case "quotation-info":
      currentContent = <QuotationInformation />;
      break;
    default:
      currentContent = <QuotationInformation />;
  }

  return (
    <div>
      <TopBar />
      <TopHeader type="create-quotation" />
      {zoomedTable === true ? (
        <QuoScopeZoompage setZoomedTable={setZoomedTable} />
      ) : (
        <div className="d-flex  xtra-lg-screen-responsive-cont">
          <Col lg={3} md={4} sm={12} className="px-4 py-2 bor-right">
            <h5 className="fnt-fam-f3 py-1 my-1 fs-15px text-uppercase">
              Fortune promoseven llc (A division of NUkhaba press disbution and
              release)
            </h5>
            <p className="py-1 my-1 fnt-fam-f2 fs-14px">
              <span className="opac_half">REF ID: </span>REQ-10-0001
            </p>
            <p className="py-1 my-1 fnt-fam-f2 fs-14px">
              <span className="opac_half">RFQ REF ID: </span>REQ-0001
            </p>
            <p className="py-1 my-1 fnt-fam-f2 fs-14px">
              <span className="opac_half">Closing Date & Time:</span>14-10-2022
              10.10AM
            </p>
            <p className="py-1 my-1 fnt-fam-f2 fs-14px">
              <span className="opac_half">Buyer Name: </span> -
            </p>
            <p className="py-1 my-1 fnt-fam-f2 fs-14px">
              <span className="opac_half">Issued By : </span>Mohammad Billal
            </p>
            <div className="my-3 py-2 fnt-fam-f2 fs-14px">
              <h5 className="primary1 fs-15px">KH Contact Details</h5>
              <p className="py-1 my-1 fnt-fam-f2 fs-14px">
                <span className="opac_half">Name: </span>Subair Hameed
              </p>
              <p className="py-1 my-1 fnt-fam-f2 fs-14px">
                <span className="opac_half">Phone: </span>+91 9856238956
              </p>
              <p className="py-1 my-1 fnt-fam-f2 fs-14px">
                <span className="opac_half">Email ID: </span>
                sahulhameed12@khgroups.com
              </p>
            </div>
          </Col>
          <Col
            lg={9}
            md={8}
            sm={12}
            xs={12}
            className="p-3 flex-wrap enqiry-view-2nd-sec"
          >
            <div className="enquiry-view-sec my-2">
              <CreateQuoTabsec
                currentActive={currentActive}
                setCurrentActive={setCurrentActive}
              />
              {currentContent}
            </div>
          </Col>
        </div>
      )}
      <div className={`rg-footer ${footerRes}`}>
        <LogInFooter />
      </div>
    </div>
  );
}

export default CreateQuotation;
