import React from "react";

function Quotaion2ndTableSection() {
  return (
    <div className="table-scroll-horizontal over-flow-x-scroll my-2 ">
      <div className="cont-border d-flex min-wid-1k">
        <div className="bor-right d-flex flex-column justify-content-end px-2 py-1">
          <h6 className="primary1">Grand Total in words</h6>
          <h6>Thiry-Eight Thousand Two Hundred Forty-one and Seventy</h6>
        </div>
        <div className="py-2 d-sm-flex justify-content-between">
          <div className="py-2  pe-2">
            <div className="d-sm-flex">
              <div className="d-sm-flex ps-2 flex-column invoce-2ndtable-header-sec-cont">
                <p className="mb-4"></p>
                <select className="form-select py-1 my-1 mx-2 fs-14px">
                  <option>Addtional Charges</option>
                  <option>US</option>
                  <option>EURO</option>
                </select>
                <select className="form-select  my-1 py-1 mx-2 fs-14px">
                  <option>Deduction</option>
                  <option>US</option>
                  <option>EURO</option>
                </select>
                <p className="mx-2 py-1 my-1 fs-14px">+ Add</p>
              </div>
              <div className="d-sm-flex flex-column invoce-2ndtable-header-sec-cont ">
                <p className="fs-14px opac_half">Sub Total</p>
                <p className="border-btm py-1 my-1 fs-14px">
                  Transparent Charges
                </p>
                <p className="border-btm text-nowrap py-1 my-1 fs-14px">
                  E.g(Transparent Charges)
                </p>
              </div>
              <div className="d-sm-flex flex-column">
                <p className="fs-14px">34,765.250 INR</p>
                <p className="border-btm py-1 my-1 fs-14px text-end">
                  10,000 INR
                </p>
                <p className="border-btm py-1 my-1 fs-14px text-end">
                  -10,000 INR
                </p>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end gap-3 pe-2 me-2 mt-5">
              <h6 className="py-1 px-2">Grand Total</h6>
              <h6 className="py-1">38,241.70INR</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex min-wid-1k">
        <div style={{ minWidth: "716px" }} className="py-2 d-flex w-100 m-0">
          <div className=" p-0 me-2 mt-3 quotation-nd-table-cont">
            <table className="py-2 cont-border table fs-14px">
              <thead>
                <tr className="fnt-fam-f3 opac_half fs-14px">
                  <th>IGST</th>
                  <th>-</th>
                </tr>
              </thead>
              <tbody className="opac-80 ">
                <tr>
                  <td className="fs-14px">CGST</td>
                  <td className="fs-14px">9500</td>
                </tr>
                <tr>
                  <td className="fs-14px">SGST</td>
                  <td className="fs-14px">9500</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            // style={{ minWidth: "580px" }}
            className=" p-0 quo-scope-btm-tb-2nd-cont  w-100"
          >
            <div className="d-flex quotation-nd-table-cont">
              <table className="mt-3 cont-border py-2 mb-0 bor-right table">
                <thead>
                  <tr className="fnt-fam-f3 opac_half table-bordered">
                    <th className="fs-14px text-center">GST</th>
                    <th className="fs-14px text-center">Taxable Amount</th>
                    <th className="fs-14px text-center">Tax Value</th>
                  </tr>
                </thead>
                <tbody className="opac-80">
                  <tr>
                    <td className="fs-14px text-center">5%</td>
                    <td className="fs-14px text-center">2000</td>
                    <td className="fs-14px text-center">100</td>
                  </tr>
                  <tr>
                    <td className="fs-14px text-center">12%</td>
                    <td className="fs-14px text-center">5000</td>
                    <td className="fs-14px text-center">600</td>
                  </tr>
                </tbody>
              </table>
              <table className="mt-3 mb-0 cont-border py-2 table">
                <thead>
                  <tr className="fnt-fam-f3 opac_half ">
                    <th className="fs-14px text-center">GST%</th>
                    <th className="fs-14px text-center">Taxable Amount</th>
                    <th className="fs-14px text-center">Tax Value</th>
                  </tr>
                </thead>
                <tbody className="opac-80">
                  <tr>
                    <td className="fs-14px text-center">18%</td>
                    <td className="fs-14px text-center">4000</td>
                    <td className="fs-14px text-center">320</td>
                  </tr>
                  <tr>
                    <td className="fs-14px text-center">28%</td>
                    <td className="fs-14px text-center">8000</td>
                    <td className="fs-14px text-center">960</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table className="d-flex justify-content-end light-grey-bg py-2">
              <tbody>
                <tr>
                  <td className="d-flex gap-2 fw-bold px-2">
                    <span>Total Tax</span> <span>19,500 INR</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotaion2ndTableSection;
