import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import LogInFooter from "../../logincomponent/LogInFooter";
import EnquiryPagination from "../../component/Enquiry/EnquiryPagination";
import EnquiryFilter from "../../component/Enquiry/EnquiryFilter";
import EnquiryCard from "../../component/Enquiry/EnquiryCard";

function EnquiryList() {
  return (
    <>
      <TopBar />
      <TopHeader type="enquiry-list" />
      <div className="enquiry-list-cont my-3 py-2">
        <EnquiryFilter />
        <EnquiryCard type="primary" /> <EnquiryCard type="danger" />
        <EnquiryCard type="warning" /> <EnquiryCard type="success" />
        <EnquiryPagination />
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default EnquiryList;
