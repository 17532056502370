import { useState } from "react";
import { RiDeleteBack2Line } from "react-icons/ri";
import { RxTriangleUp } from "react-icons/rx";
import ViewDetails from "../../assets/Icons/Normal Icons/ViewDetails.svg";

import { Col } from "react-bootstrap";
function QuoScopeTable() {
  const [showModel, setShowModel] = useState(false);

  const modelHideHandler = () => setShowModel(false);

  const modelShowHandler = () => setShowModel(true);
  return (
    <div className="table-scroll-horizontal over-flow-x-scroll my-2 table-sm w-100">
      <table className="my-3 py-2 table">
        <thead>
          <tr className="fnt-fam-f2 opac-85 ">
            <th>Sl.No </th>
            <th className="text-nowrap">Supplier Material Code</th>
            <th className="">KH Material SKU</th>
            <th>Size 1</th>
            <th className="text-nowrap">Size 2</th>
            <th>Width</th>
            <th>HSN</th>
            <th>UOM</th>
            <th className="text-nowrap">RFQ Quantity</th>
            <th className="text-nowrap">Quota</th>
          </tr>
        </thead>
        <tbody className="fs-6 opac-80">
          <tr>
            <td>01</td>
            <td>Mark</td>
            <td>
              <p className=" my-1">
                R95CH456789
                <span className="font-col-sec">
                  <img src={ViewDetails} className="wid-15px ms-1" />
                </span>
              </p>
              <p className="text-wrap my-1  wid_15">
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
              </p>
            </td>
            <td>B$5678945</td>
            <td>1</td>
            <td>20</td>
            <td>B454RT598637894</td>
            <td>Box</td>
            <td>150</td>
            <td>140</td>
          </tr>
          <tr>
            <td>02</td>
            <td>Mark</td>
            <td>
              <div className="my-1 position-relative">
                R95CH456789{" "}
                <span className="font-col-sec" onClick={modelShowHandler}>
                  <img src={ViewDetails} className="wid-15px ms-1" />
                </span>
                {showModel && (
                  <div className="quo-scop-tab-model-box p-3 ">
                    <p className="enq-view-modelbox-arrow-cont ">
                      <RxTriangleUp />
                    </p>
                    <div className="d-flex justify-content-between border-btm pb-2">
                      <p className="fnt-fam-f3 fs-5 opac-85 d-flex gap-2 align-items-center">
                        Product Details
                      </p>
                      <p className="fnt-fam-f3 fs-5" onClick={modelHideHandler}>
                        <RiDeleteBack2Line />
                      </p>
                    </div>
                    <div className="d-flex">
                      <Col xs={6}>
                        <p className="py-1 my-1">
                          <span className="opac_half">REF ID : </span>
                          REQ12345
                        </p>
                      </Col>
                      <Col cs={6} className="px-2">
                        <p className="py-1 my-1">
                          <span className="opac_half">REF ID : </span>
                          REQ12345
                        </p>
                      </Col>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-wrap my-1 wid_15">
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
              </p>
            </td>
            <td>B$5678945</td>
            <td>1</td>
            <td>2</td>
            <td>10</td>
            <td>Mark</td>

            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>03</td>
            <td>Mark</td>
            <td>
              <p className=" my-1">
                R95CH456789
                <span className="font-col-sec">
                  <img src={ViewDetails} className="wid-15px ms-1" />
                </span>
              </p>
              <p className="text-wrap my-1  wid_15">
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
              </p>
            </td>
            <td>B$5678945</td>
            <td>1</td>
            <td>2</td>
            <td>10</td>
            <td>Mark</td>
            <td>Otto</td>

            <td>@mdo</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default QuoScopeTable;
