// @ts-nocheck
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";
import UserCard from "../../component/EnterpriseAdmin/Card";
import DropDown from "../../component/EnterpriseAdmin/DropDown";
import Pagination from "react-bootstrap/Pagination";
import LogInFooter from "../../logincomponent/LogInFooter";
import Dropdown from "react-bootstrap/Dropdown";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router";
import { api } from "../../constants/Services";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import useUser from "../../constants/Storage/userData";
import { PersonImg } from "../../assets/img";
import { Link } from "react-router-dom";

function EnterPriseUserLoginDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const Id = location?.state?.id;
  // console.log(location);
  const data = [
    {
      skucode: "Quality",
      name: "14",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Marketing",
      name: "02",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Hr",
      name: "01",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Qc",
      name: " 02",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
    {
      skucode: "Testing",
      name: "05",
      hsncode: "Active",
      ipaddress: "19.3203.100",
    },
  ];

  const [list, setList] = useState([]);

  const getList = () => {
    api.monitorLog(Id).then((res) => {
      setList(res?.activity_logs);
    });
  };

  // console.log("list", list);

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, []);

  return (
    <div className="user-list-whole-container">
      <TopBar />
      <TopHeader type="enterprise_admin" />
      <div className="user-login-details-cont min-ht-80">
        <Link
          className="user-login-details-backarrow-cont"
          to={"/enterprise-admin/userlist"}
        >
          <BsArrowLeft />
          Monitor User log
        </Link>
        <div className="User-img-cont">
          <img
            className="user-login-detail-img"
            src={PersonImg}
            style={{ width: "80px" }}
          />
          <p>
            {location?.state?.firstName} {location?.state?.lastName}
          </p>
          <p>{location?.state?.firstName}</p>
        </div>
        <div className="action-container d-md-flex justify-content-between mb-2 mt-2">
          <div className="action-page-cont">
            <p>Note: The Maximum Date range allowed is 30 days</p>
          </div>
          <input
            className="dat-border"
            type="date"
            placeholder="Select Date Range"
          />
          {/* <div className="action-page-cont">
            <DropDown title="Manage Departments" />
          </div> */}
        </div>
        <div className="tbale-scroll-horizontal table-sm">
          <table className="department-list-container w-100">
            <thead className="department-list-heading-container">
              <tr>
                <th className="pad_heading ">S.no</th>
                <th className="pad_heading ">Date & Time</th>
                <th className="pad_heading ">Module</th>
                <th className="pad_heading ">Action</th>
                <th className="pad_heading ">Ip Address</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => (
                <tr key={index}>
                  <td className="pad_heading ">{index + 1}</td>
                  <td className="pad_heading ">
                    {" "}
                    {moment(item?.SPUAT_CreatedOn).format(
                      "DD-MMM-YYYY hh:mm A"
                    )}
                  </td>
                  <td className="pad_heading ">
                    {" "}
                    {item?.module?.SPMM_ModuleType}
                  </td>
                  <td className="pad_heading ">
                    {" "}
                    {item?.permission?.SPPM_Status == 1 ? "Active" : "DeActive"}
                  </td>
                  <td className="pad_heading "> {item?.SPUAT_IPAddress}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
}

export default EnterPriseUserLoginDetails;
