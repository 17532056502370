// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Form, Image, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import RegHeader from "../registercomponent/RegHeader";
import RegOtpModal from "../registercomponent/RegOtpModal";
import { showPwdImg, hidePwdImg, loaderIcon } from "../assets/img";
import LogInFooter from "../logincomponent/LogInFooter";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { api } from "../constants/Services";
import Select from "react-select";
import { FavLogo, Check } from "../assets/img";
import Timer from "../login/TimeCounter";
import Loader from "../component/Loader";
import { FaTimes } from "react-icons/fa";
import ErrorModel from "../constants/Services/Api/errorModel";
import {
  businessOptions,
  chooseOptions,
  countryOptions,
} from "../layout/dummyData";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

const Register = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [resentLoader, setResentLoader] = useState(false);
  const [registrationLoader, setRegistrationLoader] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [validity, setValidity] = useState("45");
  const [npwd, setNpwd] = useState("");
  const [cpwd, setCpwd] = useState("");
  const [isNewPwd, setIsNewPwd] = useState(false);
  const [isConPwd, setIsConPwd] = useState(false);
  const [RegOtpModal, setRegOtpModal] = useState(false);
  const [RegSuccessModal, setRegSuccessModal] = useState(false);
  const [capModal, setCapModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [categorires, setCategorires] = useState([]);
  const [sources, setSources] = useState([]);
  const [resendOtp, setResendOtp] = useState(true);

  // VALIDATION

  const [country, setCountry] = useState("");
  const [category, setCategory] = useState("");
  const [sName, setSName] = useState("");
  const [bSource, setBSource] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [fName, setFName] = useState("");
  const [LNmae, setLName] = useState("");
  const [PEmail, setPEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [uName, setUName] = useState("");
  const [ext, setExt] = useState("");
  const [telephone, setTelephone] = useState("");
  const [telephoneCode, setTelephoneCode] = useState("");
  const [shortName, setShortName] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [currency, setCurrency] = useState("");
  const [address, setAddress] = useState("");
  const [CreatedUser, setCreatedUser] = useState("");
  const [gst, setGst] = useState("");
  const [msmeId, setMsmeId] = useState("121");
  const [msmeNo, setMsmeNo] = useState("");
  const [msmeNoErr, setMsmeNoErr] = useState(false);
  const [Otp, setOtp] = useState("");

  // VALIDATION

  const [countryErr, setCountryErr] = useState(false);
  const [categoryErr, setCategoryErr] = useState(false);
  const [sNameErr, setSNameErr] = useState(false);
  const [bSourceErr, setBSourceErr] = useState(false);
  const [uNameErr, setUNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [fNameErr, setFNameErr] = useState(false);
  const [LNmaeErr, setLNameErr] = useState(false);
  const [PEmailErr, setPEmailErr] = useState(false);
  const [telephoneErr, setTelephoneErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [websiteErr, setWebsiteErr] = useState(false);
  const [npwdErr, setNpwdErr] = useState(false);
  const [cpwdErr, setCpwdErr] = useState(false);
  const [extErr, setExtErr] = useState(false);
  const [successOtp, setSuccessOtp] = useState(false);
  const [shortNameErr, setShortNameErr] = useState(false);
  const [sourceTypeErr, setSourceTypeErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [currencyErr, setCurrencyErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [CreatedUseErr, setCreatedUserErr] = useState(false);
  const [gstErr, setGstErr] = useState(false);
  const [msmeIdErr, setMsmeIdErr] = useState(false);

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const [list, setList] = useState([]);

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  const validPEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  const url = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );

  // COUNTRYS

  // console.log("sortData", sortData);
  // console.log(msmeId);
  // console.log(bSource);

  const getCountry = () => {
    api.country().then((resp) => {
      if (resp.status == "success") {
        // console.log("res :", resp);
        setCountries(resp?.countries);
        setCategorires(resp?.supplier_categories);
        setSources(resp?.bussiness_source_types);
        setList(resp);

        let stateList = resp?.states?.sort((a, b) => {
          if (a.state_name < b.state_name) return -1;
          if (a.state_name > b.state_name) return 1;
          return 0;
        });

        let cityList = resp?.cities?.sort((a, b) => {
          if (a.city_name < b.city_name) return -1;
          if (a.city_name > b.city_name) return 1;
          return 0;
        });

        let countryList = resp?.countries?.sort((a, b) => {
          if (a.country_name < b.country_name) return -1;
          if (a.country_name > b.country_name) return 1;
          return 0;
        });

        setSortData({
          state: stateList,
          cities: cityList,
          countries: countryList,
        });

        // setLoader(false);
      } else {
        // setLoader(false);
        setError(resp);
        setErrorBox(true);
      }
    });
  };

  const submitHandle = () => {
    let user_captcha_value =
      document.getElementById("user_captcha_input").value;
    // console.log("data");
    if (
      country?.length == 0 ||
      category?.length == 0 ||
      sName?.length == 0 ||
      bSource?.length == 0 ||
      // validEmail.test(email) == false ||
      // url.test(website) == false ||
      email?.length == 0 ||
      website?.length == 0 ||
      fName?.length == 0 ||
      LNmae?.length == 0 ||
      // mobile?.length < 10 ||
      // telephone?.length == 0 ||
      // validPEmail.test(PEmail) == false ||
      PEmail?.length == 0 ||
      address?.length == 0 ||
      // msmeId?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0 ||
      currency?.length == 0 ||
      // CreatedUser?.length == 0 ||
      // gst?.length == 0 ||
      sourceType?.length == 0 ||
      shortName?.length == 0
      // ||
      // msmeNo?.length == 0
    ) {
      // console.log("data1");
      setCountryErr(true);
      // setMsmeIdErr(true);
      // setMsmeIdErr(true);
      setAddressErr(true);
      setSourceTypeErr(true);
      setShortNameErr(true);
      // setCreatedUserErr(true);
      setCityErr(true);
      setStateErr(true);
      // setGstErr(true);
      setCurrencyErr(true);
      setCategoryErr(true);
      setSNameErr(true);
      setBSourceErr(true);
      setEmailErr(true);
      setWebsiteErr(true);
      setFNameErr(true);
      setLNameErr(true);
      // setMobileErr(true);
      // setTelephoneErr(true);
      setPEmailErr(true);
    } else if (validateCaptcha(user_captcha_value) == false) {
      setCapModal(true);
    } else {
      // console.log("data2");
      let formData = new FormData();
      formData.append("country", country);
      formData.append("category", category);
      formData.append("supplier_name", sName);
      formData.append("user_name", sName);
      formData.append("source_type", bSource);
      formData.append("email", email);
      formData.append("normal_email", email);
      formData.append("primary_email", PEmail);
      formData.append("website", website);
      formData.append("first_name", fName);
      formData.append("last_name", LNmae);

      if (msmeNo) {
        formData.append("msme_no", msmeNo);
      }

      if (telephone?.length == 12) {
        formData.append("telephone", telephone?.slice(-10));
        formData.append("telephone_code", telephone?.slice(0, 2));
      } else {
        formData.append("telephone", telephone?.slice(3, telephone?.length));
        formData.append("telephone_code", telephone?.slice(0, 3));
      }

      if (mobile?.length == 12) {
        formData.append("mobile", mobile?.slice(-10));
        formData.append("mobile_code", mobile?.slice(0, 2));
      } else {
        formData.append("mobile", mobile?.slice(3, mobile?.length));
        formData.append("mobile_code", mobile?.slice(0, 3));
      }

      formData.append("short_name", shortName);
      formData.append("telephone_ext", ext);
      formData.append("address1", address);
      formData.append("city_id", city);
      formData.append("state_id", state);
      formData.append("currency_id", currency);
      if (msmeId) {
        formData.append("msme_id", msmeId);
      }
      // formData.append("created_user_id", CreatedUser);
      formData.append("created_user_id", 7);
      formData.append("source_type_id", sourceType);

      if (gst?.length > 0) {
        formData.append("gst_no", gst);
      }

      // setLoader(true);
      setButtonEnable(true);
      api.register(formData).then((res) => {
        // console.log("res", res);
        if (res?.status == "success") {
          // alert(res?.otp);
          setRegOtpModal(true);
          // setLoader(false);
          setTimeout(() => {
            setResendOtp(false);
          }, 45000);
        } else {
          // console.log(res);
          // setLoader(false);
          setError(res);
          setErrorBox(true);
        }
        setButtonEnable(false);
      });
    }
  };

  const submitHandleOtp = () => {
    let data = {
      email: PEmail,
      // email: email,
      otp: Otp,
    };
    setRegistrationLoader(true);
    api.otp(data).then((res) => {
      // console.log("res", res);
      // setOtp(res);
      if (res?.status == "success") {
        setRegOtpModal(false);
        setRegSuccessModal(true);
        setRegistrationLoader(false);
      } else {
        // console.log("otp", res);
        setRegistrationLoader(false);
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const reSendOtp = () => {
    let data = {
      email: PEmail,
    };
    setResentLoader(true);
    setResendOtp(true);
    setRegOtpModal(true);
    api.resendOtp(data).then((res) => {
      if (res?.status == "success") {
        setOtp("");
        setValidity("45");
        // console.log("res", res);
        setResentLoader(false);
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          setResendOtp(false);
        }, 45000);
      } else {
        // console.log("res", res);
        setResentLoader(false);
        setError(res);
        setErrorBox(true);
      }
    });
  };
  useEffect(() => {
    getCountry();
  }, []);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const reload = () => {
    // window.location.reload();
    navigate("/login");
  };

  const checkSpecialChar = (e) => {
    if (/[-!$%^&*()_+[\]\\|~=`{}[:;.'"<>?/,.@#\]]/g.test(e.key)) {
      // if (/[`~!@#$%^&*()-_+{}[\]\\|,.//?;':"]/g.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "grey", "blue");
  }, []);

  const doSubmit = () => {
    let user_captcha_value =
      document.getElementById("user_captcha_input").value;

    if (validateCaptcha(user_captcha_value) == true) {
      submitHandle();
    } else {
      // alert("Captcha Does Not Match");
      setCapModal(true);
    }
  };

  const [conCode, setConCode] = useState({});

  useEffect(() => {
    let temp = countries?.find((i) => i?.country_id === Number(country));
    if (temp) {
      setConCode(temp);
    } else {
      setConCode({});
    }
  }, [country]);

  return (
    <div className="reg-ster ">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      {/* {loader && <Loader />} */}
      <RegHeader />
      <div className="d-flex-wrap w-90 my-4">
        <Col className="px-3" md={10} xl={9} xs={12}>
          <div className="reg-form">
            <h5>Supplier Registration</h5>
            <p>
              Please provide the required information in the following 2 steps
              to Complete the Registration.
            </p>
            <div className="d-flex-al">
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Country <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option hidden>Select Country</option>
                  {sortData?.countries?.map((item) => (
                    <option value={item?.country_id}>
                      {item?.country_name}
                    </option>
                  ))}
                </select>
                {country?.length == 0 && countryErr && (
                  <p className="err-input pe-3">Choose Country</p>
                )}
              </Col>
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Category <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value={""} hidden>
                    Choose Category
                  </option>
                  {list?.supplier_categories?.map((item, i) => {
                    return (
                      item?.name !== "ML" && (
                        <option value={item?.name} key={i}>
                          {item?.name == "M"
                            ? "Material"
                            : item?.name == "L"
                            ? "Leather"
                            : // : item?.name == "ML"
                              // ? "Material & leather"
                              ""}
                        </option>
                      )
                    );
                  })}
                  {/* {categorires?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))} */}
                </select>
                {category?.length == 0 && categoryErr && (
                  <p className="err-input pe-3">Choose Category</p>
                )}
              </Col>
              <Col xs={12} className="pe-3 my-2 position-relative mb-md-0">
                <label>
                  Supplier Name <span className="red-text">*</span>
                </label>
                <input
                  className="form-control mb-0"
                  type="name"
                  placeholder="Enter Supplier Name"
                  onChange={(e) => setSName(e.target.value)}
                  value={sName}
                  onKeyDown={(e) => checkSpecialChar(e)}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 30) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 30) {
                      e.target.setCustomValidity("");

                      setSName(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={30}
                />
                {sName?.length == 0 && sNameErr && (
                  <p className="err-input pe-3">Enter Supplier Name</p>
                )}
              </Col>
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Short Name <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Short Name "
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 30) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 30) {
                      e.target.setCustomValidity("");

                      setShortName(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={30}
                  onChange={(e) => setShortName(e.target.value)}
                  value={shortName}
                  onKeyDown={(e) => checkSpecialChar(e)}
                />
                {shortName?.length == 0 && shortNameErr && (
                  <p className="err-input pe-3">Enter Short Name</p>
                )}
              </Col>

              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Company Website <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Company Website"
                  onChange={(e) => setWebsite(e.target.value)}
                />
                {url.test(website) == false && websiteErr && (
                  <p className="err-input pe-3">Enter Valid Website</p>
                )}
              </Col>
              <Col
                md={6}
                xs={12}
                className="pe-3 my-2 position-relative mt-md-0"
              >
                <label>
                  Business Source <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  value={bSource}
                  onChange={(e) => setBSource(e.target.value)}
                >
                  <option hidden>Choose Business Source</option>
                  {sources?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))}
                </select>
                {bSource?.length == 0 && bSourceErr && (
                  <p className="err-input pe-3">Select Business Source </p>
                )}
                {/* <p className="text-white opacity-0">d</p> */}
              </Col>
              <Col
                md={6}
                xs={12}
                className="pe-3 my-2 position-relative mt-md-0"
              >
                <label>
                  Source Type <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  value={sourceType}
                  onChange={(e) => setSourceType(e.target.value)}
                >
                  <option hidden>Choose Source Type</option>
                  {list?.source_types?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))}
                </select>
                {sourceType?.length == 0 && sourceTypeErr && (
                  <p className="err-input pe-3">Choose Source type </p>
                )}
                {/* <p className="text-white opacity-0">d</p> */}
              </Col>
              <Col
                md={6}
                xs={12}
                className="pe-3 my-2 position-relative mt-md-0"
              >
                <label>
                  State <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option hidden>Choose State</option>
                  {sortData?.state?.map((item) => (
                    <option value={item?.state_id}>{item?.state_name}</option>
                  ))}
                </select>
                {state?.length == 0 && stateErr && (
                  <p className="err-input pe-3">Choose State </p>
                )}
                {/* <p className="text-white opacity-0">d</p> */}
              </Col>

              <Col
                md={6}
                xs={12}
                className="pe-3 my-2 position-relative mt-md-0"
              >
                <label>
                  City <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option hidden>Choose City</option>
                  {sortData?.cities?.map((item) => (
                    <option value={item?.city_id}>{item?.city_name}</option>
                  ))}
                </select>
                {city?.length == 0 && cityErr && (
                  <p className="err-input pe-3">Choose City </p>
                )}
                {/* <p className="text-white opacity-0">d</p> */}
              </Col>
              <Col md={12} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Address <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Address "
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 150) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 150) {
                      e.target.setCustomValidity("");

                      setAddress(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={150}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
                {address?.length == 0 && addressErr && (
                  <p className="err-input pe-3">Enter Address</p>
                )}
              </Col>
              <Col md={12} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Company Email ID <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Company Email ID"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 60) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 60) {
                      e.target.setCustomValidity("");

                      setEmail(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={60}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="d-flex justify-content-between">
                  <p className="err-input-err">
                    Note : Your Email ID Will Become Login Name
                  </p>
                  {validEmail.test(email) == false && emailErr && (
                    // <p className="err-input1 pe-3">Enter Valid Email</p>
                    <p className="err-input pe-3" style={{ bottom: "0" }}>
                      Enter Valid Company Email ID
                    </p>
                  )}
                </div>
              </Col>
              <Col
                md={6}
                xs={12}
                className="pe-3 my-2 position-relative mt-md-0"
              >
                <label>
                  Currency <span className="red-text">*</span>
                </label>
                <select
                  className="form-select pb-0"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option hidden>Choose Currency</option>
                  {list?.currencies?.map((item) => (
                    <option value={item?.currency_id}>
                      {item?.currency_code}
                    </option>
                  ))}
                </select>
                {currency?.length == 0 && currencyErr && (
                  <p className="err-input pe-3">Choose Currency</p>
                )}
                {/* <p className="text-white opacity-0">d</p> */}
              </Col>
              {country == 1 ? (
                <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                  <label>
                    GST
                    {/* <span className="red-text">*</span> */}
                  </label>
                  <input
                    className="form-control"
                    type="name"
                    placeholder="Enter GST "
                    onChange={(e) => setGst(e.target.value)}
                    value={gst}
                  />
                  {gst?.length == 0 && gstErr && (
                    <p className="err-input pe-3">Enter GST</p>
                  )}
                </Col>
              ) : null}

              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>MSME Category</label>
                <select
                  className="form-select pb-0"
                  value={msmeId}
                  onChange={(e) => setMsmeId(e.target.value)}
                >
                  <option value={""} hidden>
                    Choose MSME Category
                  </option>
                  {list?.msme_types?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))}
                </select>
                {msmeId?.length == 0 && msmeIdErr && (
                  <p className="err-input pe-3">Choose MSME Category</p>
                )}
              </Col>
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  {country == 1 ? "MSME Number" : "IE Code"}
                  {/* <span className="red-text">*</span> */}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={country == 1 ? "Enter MSME No" : "Enter IE Code"}
                  onChange={(e) => setMsmeNo(e.target.value)}
                  value={msmeNo}
                />
                {msmeNo?.length == 0 && msmeNoErr && (
                  <p className="err-input pe-3">
                    Enter {country == 1 ? "MSME No" : "IE Code"}
                  </p>
                )}
              </Col>

              {/* <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Created User<span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Created User "
                  onChange={(e) => setCreatedUser(e.target.value)}
                  value={CreatedUser}
                />
                {CreatedUser?.length == 0 && CreatedUseErr && (
                  <p className="err-input pe-3">Enter created user</p>
                )}
              </Col> */}
            </div>
            {/* <div className="d-flex-al">
                <Col md={6} xs={12} className="pe-3 my-2">
                  <div className="pwd-container">
                    <label>
                      Password <span className="red-text">*</span>
                    </label>

                    <input
                      name="pwd"
                      className="form-control"
                      placeholder="Password"
                      type={isNewPwd ? "text" : "password"}
                      value={npwd}
                      onChange={(e) => setNpwd(e.target.value)}
                    />
                    <span className="text-success">Strong</span>

                    <Image
                      title={isNewPwd ? "Hide password" : "Show password"}
                      // @ts-ignore
                      src={isNewPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsNewPwd((prevState) => !prevState)}
                    />
                  </div>
                </Col>
                {npwd?.length == 0 && npwdErr && (
                  <span>Enter 8 Digit Password</span>
                )}
                <Col md={6} xs={12} className="pe-3 my-2">
                  <div className="pwd-container">
                    <label>
                      Confirm Password <span className="red-text">*</span>
                    </label>

                    <input
                      name="pwd"
                      className="form-control"
                      placeholder="Confirm Password"
                      type={isConPwd ? "text" : "password"}
                      value={cpwd}
                      onChange={(e) => setCpwd(e.target.value)}
                    />
                    <span className="text-success">Strong</span>

                    <Image
                      title={isConPwd ? "Hide password" : "Show password"}
                      // @ts-ignore
                      src={isConPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsConPwd((prevState) => !prevState)}
                    />
                  </div>
                </Col>
                {cpwd?.length == 0 && cpwdErr && (
                  <span>Enter Confirm Password</span>
                )}
              </div> */}
            <div className="d-flex-al mt-4 pr_cont">
              <Col xs={12} className="pe-3  ">
                <h6>
                  <b>Primary Contact</b>
                </h6>
              </Col>
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  First Name <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter First Name"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 30) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 30) {
                      e.target.setCustomValidity("");

                      setFName(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={30}
                  onChange={(e) => setFName(e.target.value)}
                  onKeyDown={(e) => checkSpecialChar(e)}
                />
                {fName?.length == 0 && fNameErr && (
                  <p className="err-input pe-3">Enter First Name</p>
                )}
              </Col>
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Last Name <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Last Name"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 30) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 30) {
                      e.target.setCustomValidity("");

                      setLName(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={30}
                  onChange={(e) => setLName(e.target.value)}
                  onKeyDown={(e) => checkSpecialChar(e)}
                />
                {LNmae?.length == 0 && LNmaeErr && (
                  <p className="err-input pe-3">Enter Last Name</p>
                )}
              </Col>
              <Col md={12} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Email Id <span className="red-text">*</span>
                </label>
                <input
                  className="form-control"
                  type="name"
                  placeholder="Enter Email Id"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 60) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 60) {
                      e.target.setCustomValidity("");

                      setPEmail(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={60}
                  value={PEmail}
                  onChange={(e) => setPEmail(e.target.value)}
                />
                <div className="d-flex justify-content-between">
                  <p>Note : OTP Will send to This Mail ID</p>
                  {validPEmail.test(PEmail) == false && PEmailErr && (
                    <p className="err-input pe-3" style={{ bottom: 0 }}>
                      Enter Valid Email
                    </p>
                  )}
                </div>
              </Col>
              <Col md={6} xs={12} className="pe-3 my-2 position-relative">
                <label>
                  Mobile <span className="red-text">*</span>
                </label>
                <PhoneInput
                  country={
                    Object.values(conCode)?.length > 0
                      ? conCode?.country_code == "IND"
                        ? "in"
                        : conCode?.country_code.toLowerCase()
                      : "in"
                  }
                  className="border-btm"
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e);
                    // console.log(e);
                  }}
                />
                {/* <input
                  className="form-control"
                  type="mobile"
                  placeholder="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                /> */}
                {mobile?.length == 0 && mobileErr && (
                  <p className="err-input pe-3">Enter Mobile No</p>
                )}
              </Col>
              <Col
                md={6}
                xs={12}
                className="pe-2 my-2 d-flex position-relative  rit-mobile-regis2"
              >
                <div style={{ width: "100%" }} className="">
                  <div className="d-flex">
                    <div className="d-flex">
                      {/* <p style={{ width: "40px" }}>Code</p> */}
                      <p
                      // style={{ paddingLeft: "30px" }}
                      >
                        Telephone
                      </p>
                    </div>
                  </div>
                  <PhoneInput
                    country={
                      Object.values(conCode)?.length > 0
                        ? conCode?.country_code == "IND"
                          ? "in"
                          : conCode?.country_code.toLowerCase()
                        : "in"
                    }
                    className="border-btm"
                    value={telephone}
                    onChange={(e) => {
                      setTelephone(e);
                      // console.log(e);
                    }}
                  />
                  {telephone?.length == 0 && telephoneErr && (
                    <p className="err-input pe-3">Enter Telephone No </p>
                  )}
                </div>
                <div className="px-2 ext-style">
                  <label> Ext</label>
                  {/* <input
                    className="m-0 border-0"
                    type="number"
                    maxLength="3"
                    placeholder=" "
                    onChange={(e) => setExt(e.target.value)}
                  /> */}
                  <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 3) {
                        e.target.setCustomValidity("invalid Ext");
                      } else if (e.target.value.length == 3) {
                        e.target.setCustomValidity("");

                        setExt(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxlength={3}
                    onChange={(e) => setExt(e.target.value)}
                    type="number"
                    className="m-0 border-0"
                    placeholder="Ext"
                  />

                  {/* {ext?.length == 0 && extErr && (
                    <p className="err-input pe-3">Enter Ext</p>
                  )} */}
                </div>
              </Col>
              {/* <Col
                  md={6}
                  xs={12}
                  className="pe-3 my-2 d-flex rit-mobile-regis"
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <div className="d-flex">
                      <div className="d-flex">
                        <p style={{ width: "40px" }}>Code</p>
                        <p style={{ paddingLeft: "30px" }}>Mobile</p>
                      </div>
                    </div>
                    <PhoneInput
                      country="in"
                      onChange={(e) => {
                        setMobile(e);
                        console.log(e);
                      }}
                    />
                  </div>
                </Col> */}
              <Col xs={12} className="pe-3 my-2">
                <div className="d-flex justify-content-end">
                  <div>
                    <LoadCanvasTemplate />
                    <input
                      placeholder="Enter Captcha Value"
                      autocomplete="off"
                      id="user_captcha_input"
                      name="user_captcha_input"
                      type="text"
                    ></input>
                  </div>
                </div>
              </Col>
              <Col xs={12} className="pe-3 my-2">
                <div className="text_des">
                  <p>
                    I have fully read and understood the
                    {/* @ts-ignore */}
                    <Link> Terms and Conditions.</Link> I am authorized by my
                    Company to agree and abide by the Terms and Conditions.
                  </p>
                  <p>
                    KH is Protected by reCAPTCHA and the
                    <Link> Terms and Conditions </Link>
                    and <Link> Terms of service</Link> apply.
                  </p>
                </div>
              </Col>
              {/* <RegOtpModal fetch={submitHandle} /> */}
              <div className="mt-3  w-100 ">
                <div className="pe-3 my-2 d-flex-al-je">
                  <button className="cust-btn bg-lit-primary4">Cancel</button>
                  <button
                    // onClick={() => setRegSuccessModal(true)}
                    onClick={submitHandle}
                    // disabled={buttonEnable ? true : false}
                    className="cust-btn bg-primary1 text-white ms-2"
                  >
                    Agree & Submit
                  </button>
                  {/* <Link to="/setpassword" className="btn brn-can ">
                      Cancel Link
                    </Link> */}
                </div>
                <Modal
                  id="#verify"
                  className="otp-modal"
                  show={RegOtpModal}
                  centered
                  onHide={() => setRegOtpModal(true)}
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <div className="new-modal">
                    <div className="w-100 d-flex-al-jb">
                      <Image className="fav-icon" src={FavLogo} />
                      <button
                        onClick={() => setRegOtpModal(false)}
                        className="cust-btn p-0"
                      >
                        <FaTimes />
                      </button>
                    </div>
                    <div className="mt-2">
                      <p>Note : OTP Sent to your registered E-mail ID</p>
                      <div>
                        <input
                          className="form-control"
                          type="number"
                          // placeholder="Enter OTP"
                          onChange={(e) => setOtp(e.target.value)}
                          value={Otp}
                        />
                        <div className="d-flex-al-jb">
                          <button
                            disabled={resendOtp && true}
                            onClick={reSendOtp}
                            className="cust-btn p-0 primary1"
                          >
                            <span style={{ opacity: resendOtp ? "0.3" : 1 }}>
                              Resend OTP{" "}
                            </span>
                            {resentLoader && (
                              <img style={{ width: "25px" }} src={loaderIcon} />
                            )}
                          </button>

                          <button className="cust-btn px-0 d-flex">
                            Valid Till {" - "}
                            <Timer
                              setValidity={setValidity}
                              validity={validity}
                            />
                          </button>
                        </div>
                        {/* <RegSuccess /> */}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={submitHandleOtp}
                    className="cust-btn bg-primary1 text-white py-2 mb-2 mt-2"
                  >
                    Verify{" "}
                    {registrationLoader && (
                      <img src={loaderIcon} style={{ width: "19px" }} />
                    )}
                  </button>
                </Modal>
                <div className="mt-3">
                  <Modal
                    id="#sucess"
                    className="otp-modal"
                    show={RegSuccessModal}
                    size="md"
                    centered
                    onHide={() => setRegSuccessModal(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                  >
                    <div className="new-modal">
                      <Image className="fav-icon" src={FavLogo} />
                      <div className="mt-3 d-flex">
                        <Image
                          style={{ height: "25px" }}
                          className="tick"
                          src={Check}
                        />

                        <div className="d-flex-as-jc img_tick ">
                          <div className="px-3">
                            <h5 className="fs-14">Registration Request</h5>
                            <p>
                              Your Registration has been sent to KH you will
                              receive the email with link once appoverd by KH
                            </p>
                          </div>
                          <button
                            className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
                            onClick={() =>
                              // navigate("/setpassword", {
                              //   state: {
                              //     data: Otp,
                              //   },
                              // }
                              // )
                              reload()
                            }
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Modal
          id="#sucess"
          className="otp-modal"
          show={capModal}
          size="md"
          centered
          onHide={() => setCapModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="new-modal">
            <Image className="fav-icon" src={FavLogo} />
            <div className="d-flex justify-content-center align-items-center mt-3">
              <Image style={{ height: "25px" }} className="tick" src={Check} />
              <div className="px-2">
                <h5 className="fs-14" style={{ margin: "0" }}>
                  Captcha Does Not Match
                </h5>
              </div>
            </div>

            <div className="d-flex-as-jc img_tick ">
              <button
                className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
                onClick={() => setCapModal(false)}
              >
                OK
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};
export default Register;
