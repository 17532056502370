import React, { useState } from "react";
import ReactDOM from "react-dom";
import TimezoneSelect from "react-timezone-select";

const TimeZone = () => {
  const [selectedTimezone, setSelectedTimezone] = useState({});

  return (
    <div className="select-wrapper">
      <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
      <div
        style={
          {
            //   padding: "20px",
            //   margin: "20px auto",
          }
        }
      />
    </div>
  );
};

export default TimeZone;
