import React, { useEffect, useState } from "react";
import { Col, Pagination, Modal } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ModalFilter from "../component/ProductList/ModalFilter";
import PaginationData from "../component/ProductList/PaginationData";
import ProductListData from "../component/ProductList/ProductListData";
import SearchTitle from "../component/ProductList/SearchTitle";
import ProductPages from "../component/ProductPages";
import LogInFooter from "../logincomponent/LogInFooter";
import TopBar from "../navbar/TopBar";
import TopHeader from "../navbar/TopHeader";
import { api } from "../constants/Services";
import ReactPaginate from "react-paginate";
import ErrorModel from "../constants/Services/Api/errorModel";
import Search from "../assets/Icons/Normal Icons/Search.svg";
import Filter from "../assets/Icons/Normal Icons/Filter.svg";

const ProductList = () => {
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permissions");
  const [showModal, setShowModal] = useState(false);
  const [dropShow, setDropShow] = useState(null);
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [pageCounts, setPageCounts] = useState(10);
  const [searchs, setSearchs] = useState("");
  const [activePages, setActivePages] = useState([]);
  const [updated, setUpdate] = useState();
  const [code, setCode] = useState();
  const [created, setCreate] = useState();
  const [sku, setSku] = useState("");
  const [mat, setMat] = useState();
  const [statusFilter, setStatusFilter] = useState([]);

  const dropHandler = (data) => {
    // setDropShow(!dropShow);
    if (dropShow == data) {
      setDropShow(null);
    } else {
      setDropShow(data);
    }
  };

  const openHandler = () => {
    setShowModal(true);
  };

  const handlePageChange = (selectedObject) => {
    if (searchs !== "") {
      console.log("llll", selectedObject.selected);
      setPages(selectedObject.selected + 1);
    } else {
      console.log("sss", selectedObject.selected + 1);
      setPage(selectedObject.selected + 1);
    }
  };

  const getList = () => {
    let param = `?page=${page}`;
    
    let status = [];

    if (searchs?.length > 0) {
      let data = {
        search: searchs,
      };
      api.masterProdFilter(data, param).then((res) => {
        if (res?.status == "success") {
          setActivePages(res);
          setList(res?.supplier_products);
          setPageCounts(res?.pagination?.total / res?.pagination?.per_page);

          res?.supplier_products?.map((item, ind) => {
            status.push({
              id: item?.Sup_Approval,
              MaterialHeaderID: item?.MaterialHeaderID,
              LeatherHeaderID: item?.LeatherHeaderID,
            });
          });

          const idstatus = status.map((id) => id);

          const filteredstatus = status.filter(
            (id, index) => !idstatus.includes(id, index + 1)
          );
          setStatusFilter(filteredstatus);
        } else {
          setError(res);
          setErrorBox(true);
          setShowModal(false);
        }
      });
    } else {
      api.supplierProducts(param).then((res) => {
        if (res?.status == "success") {
          setActivePages(res);
          setList(res?.supplier_products);
          setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  const GetFilter = () => {
    let params = `?page=${page}`;

    // if (mat) {
    //   params = params + `&material_type=${mat}`;
    // }

    if (updated) {
      params = params + `&updated_on=${updated}`;
    }

    if (created) {
      params = params + `&created_on=${created}`;
    }

    if (sku) {
      let split = sku?.split(",");
      params = params + `&material_type=${split[0]}&sku_code=${split[1]}`;
    }

    if (code) {
      params = params + `&code=${code}`;
    }

    api.supplierProducts(params).then((res) => {
      if (res?.status == "success") {
        setActivePages(res);
        setShowModal(false);
        setList(res?.supplier_products);
        setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const deleteProduct = (id) => {
    setBtn(true);
    api.masterProDelete(id).then((res) => {
      if (res?.status == "success") {
        setBtn(false);
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          getList();
        }, 1000);
      } else {
        setBtn(false);
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getList();
  }, [page, searchs, pages]);

  return (
    <Col>
      <TopBar />
      <TopHeader type="product-profile" />
      <Col
        xs={12}
        lg={10}
        xxl={7}
        className="p-4 tl-prod-lst mx-auto min-ht-80"
      >
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h4 className="pro">Products List</h4>
          <div className="d-flex">
            <div className="search-box d-sm-flex pe-2 align-items-center">
              <input
                placeholder="Search Title"
                className="w-90"
                onChange={(e) => setSearchs(e.target.value)}
              />
              <img src={Search} className="wid-15px" />
            </div>
            <button onClick={openHandler} className="filter-btn bg-primary2">
              <img src={Filter} className="wid-15px me-1" />
              Filter
            </button>
            {permissions?.includes("ADD-PRODUCTS") && (
              <button
                className="map-btn bg-primary1"
                onClick={() => navigate("/product")}
              >
                Map Product
              </button>
            )}
          </div>
          {/* <SearchTitle
            showModal={showModal}
            setShowModal={setShowModal}
            navigate={navigate}
          /> */}
        </div>
        <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
          <div className="action-page-cont">
            <p>Showing</p>
            <div className="page-status-cont">
              <p
                style={{
                  border: "1px solid #ced4da",
                  padding: "4px",
                  margin: "10px 0px",
                  textAlign: "center",
                  width: "35px",
                  height: "28px",
                }}
              >
                {activePages?.supplier_products?.length}
              </p>
            </div>
            <p>of {activePages?.pagination?.total} Products</p>
          </div>
        </div>
        <div className="min-ht-80">
          {list?.map((item, indxe) => (
            <div className="box " key={indxe}>
              <div className="img-box mb-3">
                <img src={item?.img_url} width={100} height={100} />
              </div>
              <Col
                className="card-container border-0"
                style={{ boxShadow: "none" }}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <p className="prod-title me-4">
                      <span>Product Id:</span> {item?.id}
                    </p>
                    <p className="prod-title me-4">
                      <span>Product Code:</span> {item?.product_code}
                    </p>
                    <p className="prod-title">
                      <span>KH SKU Code:</span>{" "}
                      {item?.material_sku?.MaterialSKUCode ||
                        item?.leather_sku?.LeatherSKUCode}
                    </p>
                  </div>
                  <button
                    onClick={() => dropHandler(indxe)}
                    className="cust-btn dropdown-container-tool p-0"
                  >
                    <FaEllipsisV />
                  </button>
                  {dropShow == indxe && (
                    <div className="sort-drop">
                      {permissions?.includes("EDIT-PRODUCTS") && (
                        <button
                          className="btn-use-uses"
                          onClick={() =>
                            navigate("/product/addProduct", {
                              state: { item: item, type: "edit" },
                            })
                          }
                        >
                          Edit
                        </button>
                      )}

                      {permissions?.includes("DELETE-PRODUCTS") && (
                        <button
                          className="btn-use-uses"
                          onClick={() => deleteProduct(item?.id)}
                          disabled={btn ? true : false}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <h5 className="title-h5 mt-2">
                  {item?.material_header?.material_sku?.MaterialSKUName ||
                    item?.material_sku?.MaterialSKUName ||
                    item?.leather_header?.leather_sku?.LeatherSKUName ||
                    item?.leather_sku?.LeatherSKUName}
                </h5>
                <Col xs={12} className="d-flex mt-2 flex-wrap">
                  <p className="prod-title1 col-12 col-lg-4">
                    <span>Material Type:</span>{" "}
                    {item?.material_header?.material_type
                      ?.ReferenceDetailsName ||
                      item?.leather_header?.leather_type?.ReferenceDetailsName}
                  </p>
                  <p className="prod-title1 col-12 col-lg-4">
                    <span>Material Group:</span>{" "}
                    {item?.material_header?.material_group?.MaterialGroupName ||
                      item?.leather_header?.leather_group?.LeatherGroupName}
                  </p>
                  <p className="prod-title1 col-12 col-lg-4">
                    <span>Sub Group:</span>{" "}
                    {item?.material_header?.material_sub_group
                      ?.MaterialSubGroupName ||
                      item?.leather_header?.leather_sub_group
                        ?.LeatherSubGroupName}
                  </p>
                </Col>
              </Col>
            </div>
          ))}
        </div>
        {/* <ProductListData />
        <ProductListData />
        <ProductListData />
        <ProductListData />
        <PaginationData /> */}
      </Col>
      {/* <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center"> */}
      <div className="page-nate-new py-2">
        <ReactPaginate
          nextLabel="›"
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCounts}
          previousLabel="‹"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
      {/* <ModalFilter setShowModal={setShowModal} showModal={showModal} /> */}
      <Modal
        id="#verify"
        className="prod-filter"
        show={showModal}
        centered
        onHide={() => {
          setShowModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">Product Filter</h5>
        </Modal.Header>
        <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
          <Col xs={12} lg={6}>
            <div className="input-box">
              <label>Material Type</label>
              <select onChange={(e) => setMat(e.target.value)} value={mat}>
                <option value={""}>Select Material Type</option>
                <option>Material</option>
                <option>Leather</option>
              </select>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box">
              <label>KH SKU Code</label>
              <select onChange={(e) => setSku(e.target.value)} value={sku}>
                <option value={""}>Select KH SKU Code</option>
                {list?.map((item, i) => (
                  <option
                    value={[
                      item?.id,
                      item?.material_sku?.MaterialSKUCode ||
                        item?.leather_sku?.LeatherSKUCode,
                    ]}
                  >
                    {item?.material_sku?.MaterialSKUCode ||
                      item?.leather_sku?.LeatherSKUCode}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box">
              <label>Product Code</label>
              <select onChange={(e) => setCode(e.target.value)} value={code}>
                <option value={""}>Select Product Code</option>
                {list?.map((item, i) => (
                  <option value={item?.product_code}>
                    {item?.product_code}
                  </option>
                ))}
              </select>
            </div>
          </Col>

          {/* <Col xs={12} lg={6}>
            <div className="input-box">
              <label>Created On</label>
              <input
                type="date"
                onChange={(e) => setCreate(e.target.value)}
                value={created}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box">
              <label>Updated On</label>
              <input
                type="date"
                onChange={(e) => setUpdate(e.target.value)}
                value={updated}
              />
            </div>
          </Col> */}
        </Modal.Body>
        <div className="d-flex justify-content-end">
          <button
            className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
            onClick={() => {
              setShowModal(false);
              setMat("");
              setCode("");
              setCreate("");
              setSku("");
              setUpdate("");
              getList();
            }}
          >
            Clear
          </button>
          <button
            className="cust-btn mb-2 bg-primary1 me-1 text-white"
            onClick={GetFilter}
          >
            Apply
          </button>
        </div>
      </Modal>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </Col>
  );
};

export default ProductList;
