import { Col } from "react-bootstrap";
function CreateQuotationTabSec({ currentActive, setCurrentActive }) {
  const btnClickHandler = (e) => {
    const currentBtn = e.target.textContent.toLowerCase();

    if (currentBtn.includes("terms")) {
      setCurrentActive("terms");
    } else if (currentBtn.includes("communication")) {
      setCurrentActive("communication");
    } else if (currentBtn.includes("scope")) {
      setCurrentActive("scope");
    } else {
      setCurrentActive("quotation-info");
    }
  };
  const Active =
    "enquiry-view-btn-bor rounded-pill  fnt-fam-f3  fs-6  h-2r bg-primary1 text-white";
  const InActive =
    "enquiry-view-btn-bor rounded-pill fnt-fam-f3 fs-6  h-2r bg-light opac-30";
  return (
    <div className="d-flex flex-wrap">
      <Col
        lg={12}
        md={12}
        sm={12}
        className="d-flex gap-2 flex-wrap my-1 fnt-fam-f2"
      >
        <button
          className={
            currentActive == "quotation-info"
              ? `${Active} wid-11r`
              : `${InActive} wid-11r`
          }
          onClick={btnClickHandler}
        >
          Quotation Information
        </button>
        <button
          className={
            currentActive == "scope" ? `${Active} wid-9r` : `${InActive} wid-9r`
          }
          onClick={btnClickHandler}
        >
          Scope
        </button>
        <button
          className={
            currentActive == "terms" ? `${Active} wid-9r` : `${InActive} wid-9r`
          }
          onClick={btnClickHandler}
        >
          Terms & Condition
        </button>{" "}
        <button
          className={
            currentActive == "communication"
              ? `${Active} wid-9r`
              : `${InActive} wid-9r`
          }
          onClick={btnClickHandler}
        >
          Communication
        </button>{" "}
      </Col>
    </div>
  );
}

export default CreateQuotationTabSec;
