import React, { useEffect, useState } from "react";
import { Col, Image, Modal } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { ImLoop } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
import AddDescription from "./AddDescription";
import AddList from "./AddList";
import AddProductRit from "./AddProductRit";
import ProductSpecification from "./ProductSpecification";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Delete_Icon from "../assets/Icons/Normal Icons/Delete.svg";
import { PersonImg } from "../assets/img";
import useUser from "../constants/Storage/userData";
import DatePicker from "../layout/datePicker";
import moment from "moment/moment";

const MapProductDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const item = location?.state?.item;
  const type = location?.state?.type;
  const factoriesBox = location?.state?.factoriesBox;
  const storedData = location?.state?.stored;
  const [showModal, setShowModal] = useState(false);
  const { user, setUser } = useUser();
  const [showaddForm, setShowaddForm] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [factList, setFactList] = useState([]);
  const [input, setInput] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [checked, setChecked] = useState([]);
  const [lists, stateList] = useState([]);
  const [factoriesList, setFactoriesList] = useState([]);

  const details = () => {
    api.createDetails().then((res) => {
      if (res?.status == "success") {
        setInput(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // MAP PRODUCETS

  const [product_category, setproduct_category] = useState("");
  const [product_id, setproduct_id] = useState(data);
  const [sku_id, setsku_id] = useState(data);
  const [product_code, setproduct_code] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [product_image, setproduct_image] = useState("");
  const [product_imagePre, setproduct_imagePre] = useState("");
  const [country_origin, setcountry_origin] = useState("");
  const [issue_date, setissue_date] = useState(new Date());
  const [expiry_date, setexpiry_date] = useState();
  const [types, settypes] = useState(1);
  const [duration, setduration] = useState("");
  const [uom_id, setuom_id] = useState("");
  const [min_order_qty, setmin_order_qty] = useState("");
  const [max_order_qty, setmax_order_qty] = useState("");
  const [price, setprice] = useState("");
  const [orginal_certificates, setorginal_certificates] = useState([]);
  const [orginal_certificatesView, setorginal_certificatesView] = useState([]);
  const [technical_documents, settechnical_documents] = useState([]);
  const [technical_documentsView, settechnical_documentsView] = useState([]);
  const [product_pictures, setproduct_pictures] = useState([]);
  const [product_picturesView, setproduct_picturesView] = useState([]);
  const [locations, setlocations] = useState([]);
  const [currency_id, setCurrency_id] = useState("");

  const [product_categoryErr, setproduct_categoryErr] = useState(false);
  const [product_idErr, setproduct_idErr] = useState(false);
  const [product_codeErr, setproduct_codeErr] = useState(false);
  const [product_nameErr, setproduct_nameErr] = useState(false);
  const [product_imageErr, setproduct_imageErr] = useState(false);
  const [country_originErr, setcountry_originErr] = useState(false);
  const [issue_dateErr, setissue_dateErr] = useState(false);
  const [expiry_dateErr, setexpiry_dateErr] = useState(false);
  const [typeErr, settypeErr] = useState(false);
  const [durationErr, setdurationErr] = useState(false);
  const [uom_idErr, setuom_idErr] = useState(false);
  const [min_order_qtyErr, setmin_order_qtyErr] = useState(false);
  const [max_order_qtyErr, setmax_order_qtyErr] = useState(false);
  const [priceErr, setpriceErr] = useState(false);
  const [orginal_certificatesErr, setorginal_certificatesErr] = useState(false);
  const [technical_documentsErr, settechnical_documentsErr] = useState(false);
  const [product_picturesErr, setproduct_picturesErr] = useState(false);
  const [locationsErr, setlocationsErr] = useState(false);

  const [radioBtn, setRadioBtn] = useState(false);
  const [activeBtn, setActiveBtn] = useState(1);

  const onCLickBtnHandler = () => {
    setShowaddForm(true);
  };
  const getView = () => {
    let detail = {};
    if (data?.MaterialHeaderID) {
      detail.product_category = "M";
      detail.product_id = data?.MaterialHeaderID;
      detail.sku_id = data?.MaterialSKUID;
    } else if (data?.LeatherHeaderID) {
      detail.product_category = "L";
      detail.product_id = data?.LeatherHeaderID;
      detail.sku_id = data?.LeatherSKUID;
    }
    api.masterProducetView(detail).then((res) => {
      if (res?.status == "success") {
        setList(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getProView = () => {
    api.masterProdView(item?.id).then((res) => {
      if (res?.status == "success") {
        setList(res);
        setShowaddForm(!showaddForm);

        let detail = res?.supplier_product;
        // console.log(detail);
        if (detail?.MaterialHeaderID) {
          setproduct_id(detail?.MaterialHeaderID);
        } else {
          setproduct_id(detail?.LeatherHeaderID);
        }

        if (detail?.MaterialSKUID) {
          setsku_id(detail?.MaterialSKUID);
        } else {
          setsku_id(detail?.LeatherSKUID);
        }

        setproduct_code(detail?.product_code);
        setproduct_name(detail?.product_name);
        // setproduct_image(detail?.image);
        setproduct_imagePre(detail?.img_url);
        setcountry_origin(detail?.country_origin);
        setexpiry_date(new Date(detail?.expiry_date));
        setissue_date(new Date(detail?.issue_date));
        setduration(detail?.duration);
        setuom_id(detail?.UomID);
        setmin_order_qty(detail?.min_order_qty);
        setmax_order_qty(detail?.max_order_qty);
        setprice(detail?.price);
        settypes(detail?.type);
        setActiveBtn(detail?.type);
        setCurrency_id(detail?.currency_id);
        let pictures = detail?.documents?.filter((i) => i?.type == 1);
        let orginal = detail?.documents?.filter((i) => i?.type == 2);
        let technical = detail?.documents?.filter((i) => i?.type == 3);
        // setproduct_pictures(pictures);
        setlocations(detail?.locations);
        setproduct_picturesView(pictures);
        // setorginal_certificates(orginal);
        setorginal_certificatesView(orginal);
        // settechnical_documents(technical);
        settechnical_documentsView(technical);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    if (data) {
      getView();
    } else if (item) {
      getProView();
    }
    details();
  }, []);

  const getAddress = () => {
    api.addressList().then((res) => {
      if (res?.status == "success") {
        setFactList(res?.branches);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.addressGet().then((res) => {
      if (res?.status == "success") {
        setFactoriesList(res?.supplier_addresses);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (factoriesBox) {
      getAddress();
      onCLickBtnHandler();
    }
  }, []);

  useEffect(() => {
    if (factoriesBox) {
      setlocations(factoriesBox);
    }
  }, []);

  // MAP PRODUCT ADD

  const onChangeCertificate = (e) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let pdf_view = [];
      let pdf_edit = [...orginal_certificatesView];
      let pdf = [];
      temp?.map((url) => {
        {
          pdf_view.push({
            url: URL.createObjectURL(url),
            name: url?.name,
            id: "",
            type: "edit",
          });
          pdf.push(url);
        }
      });
      setorginal_certificates(pdf);
      setorginal_certificatesView((pdf_edit) => [...pdf_edit, ...pdf_view]);
    }
  };

  const onChangePictures = (e) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let pdf_view = [];
      let pdf_edit = [...product_picturesView];
      let pdf = [];
      temp?.map((url) => {
        {
          pdf_view.push({
            url: URL.createObjectURL(url),
            name: url?.name,
            id: "",
            type: "edit",
          });
          pdf.push(url);
        }
      });
      setproduct_pictures(pdf);
      setproduct_picturesView((pdf_edit) => [...pdf_edit, ...pdf_view]);
    }
  };

  const onChangetechnical = (e) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let pdf_view = [];
      let pdf_edit = [...technical_documentsView];
      let pdf = [];
      temp?.map((url) => {
        {
          pdf_view.push({
            url: URL.createObjectURL(url),
            name: url?.name,
            id: "",
            type: "edit",
          });
          pdf.push(url);
        }
      });
      settechnical_documents(pdf);
      settechnical_documentsView((pdf_edit) => [...pdf_edit, ...pdf_view]);
    }
  };

  const removeTechnical_documents = (id, index) => {
    let temp = [...technical_documentsView];
    let temps = [...technical_documents];
    temp.splice(index, 1);
    temps.splice(index, 1);
    if (id) {
      api.masterDocumentDelete(id).then((res) => {
        if (res?.status == "success") {
          settechnical_documentsView(temp);
          settechnical_documents(temps);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else if (!id) {
      settechnical_documentsView(temp);
      settechnical_documents(temps);
    }
  };

  const removePicture = (id, index) => {
    let temp = [...product_pictures];
    let temps = [...product_picturesView];
    temp.splice(index, 1);
    temps.splice(index, 1);
    if (id) {
      api.masterDocumentDelete(id).then((res) => {
        if (res?.status == "success") {
          setproduct_picturesView(temp);
          setproduct_pictures(temps);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else if (!id) {
      setproduct_picturesView(temp);
      setproduct_pictures(temps);
    }
  };

  const removeCertificate = (id, index) => {
    let temp = [...orginal_certificatesView];
    let temps = [...orginal_certificates];
    temp.splice(index, 1);
    temps.splice(index, 1);
    if (id) {
      api.masterDocumentDelete(id).then((res) => {
        if (res?.status == "success") {
          setorginal_certificatesView(temp);
          setorginal_certificates(temps);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else if (!id) {
      setorginal_certificatesView(temp);
      setorginal_certificates(temps);
    }
  };

  const removeFactorys = (id, index) => {
    let temp = [...locations];
    temp.splice(index, 1);
    setlocations(temp);
  };

  const documentEdit = () => {
    api.masterDocumentEdit(data).then((res) => {
      if (res?.status == "success") {
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const detail = [];

  const submitHandle = () => {
    let formdata = new FormData();

    if (item) {
      formdata.append("product_category", item?.MaterialHeaderID ? "M" : "L");
    } else {
      formdata.append("product_category", data?.MaterialHeaderID ? "M" : "L");
    }

    if (item) {
      formdata.append("product_id", product_id);
    } else {
      formdata.append(
        "product_id",
        product_id?.MaterialHeaderID
          ? product_id?.MaterialHeaderID
          : product_id?.LeatherHeaderID
      );
    }

    if (item) {
      formdata.append("sku_id", sku_id);
    } else {
      formdata.append(
        "sku_id",
        sku_id?.MaterialSKUID ? sku_id?.MaterialSKUID : sku_id?.LeatherSKUID
      );
    }

    formdata.append("product_code", product_code);
    formdata.append("product_name", product_name);
    formdata.append("country_origin", country_origin);
    formdata.append("issue_date", moment(issue_date).format("YYYY-MM-DD"));
    if (expiry_date) {
      formdata.append("expiry_date", moment(expiry_date).format("YYYY-MM-DD"));
    }
    formdata.append("type", types);
    formdata.append("duration", duration);
    formdata.append("uom_id", uom_id);
    formdata.append("min_order_qty", min_order_qty);
    formdata.append("max_order_qty", max_order_qty);
    formdata.append("price", price);
    formdata.append("currency_id", currency_id);

    if (product_image) {
      formdata.append("product_image", product_image);
    }

    if (orginal_certificates) {
      orginal_certificates?.map((item, ind) => {
        formdata.append(`orginal_certificates[${ind}]`, item);
      });
    }

    if (technical_documents) {
      technical_documents?.map((item, ind) => {
        formdata.append(`technical_documents[${ind}]`, item);
      });
    }

    if (product_pictures) {
      product_pictures?.map((item, ind) => {
        formdata.append(`product_pictures[${ind}]`, item);
      });
    }

    if (type == "edit") {
      if (+types == 1 && locations?.length > 0) {
        locations?.map((item, ind) => {
          if (item?.Mst_Supplier_Address_id) {
            formdata.append(`locations[${ind}][id]`, item?.id);
            formdata.append(
              `locations[${ind}][address_id]`,
              item?.Mst_Supplier_Address_id
            );
          } else {
            formdata.append(`locations[${ind}][id]`, "");
            formdata.append(`locations[${ind}][address_id]`, item?.id);
          }
        });
      } else if (+types == 1) {
        formdata.append("locations", JSON.stringify(detail));
      }
      // formdata.append("_method", "PUT");
      formdata.append("supplier_product_id", item?.id);
      api.masterProdEdit(formdata).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/product/productList");
          }, 800);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else {
      if (+types == 1 && locations?.length > 0) {
        locations?.map((item, ind) => {
          formdata.append(`locations[${ind}][id]`, "");
          formdata.append(`locations[${ind}][address_id]`, item?.id);
        });
      } else if (+types == 1) {
        formdata.append("locations", JSON.stringify(detail));
      }

      api.masterProdAdd(formdata).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/product/productList");
          }, 800);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  // console.log(product_id);

  // console.log(locations);

  const [certificate, setCertificate] = useState("");

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const [uploadImage, setUploadImage] = useState();

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    switch (file.type) {
      case "image/png":
        setproduct_imagePre(URL.createObjectURL(e.target.files[0]));
        setproduct_image(e.target.files[0]);
        //('image type is png');
        break;
      case "image/jpg":
        //('image/jpg')
        setproduct_imagePre(URL.createObjectURL(e.target.files[0]));
        setproduct_image(e.target.files[0]);
        break;
      case "image/jpeg":
        setproduct_imagePre(URL.createObjectURL(e.target.files[0]));
        setproduct_image(e.target.files[0]);
        //('image is jpeg')
        break;
      default:
        alert("Only Upload Format png, jpg, jpeg");
      // image extension is not valid
    }
  };

  const checkBoxHandler = (data) => {
    let checkboxs = [...checked];

    if (checkboxs.includes(data)) {
      let ids = checkboxs.indexOf(data);
      checkboxs.splice(ids, 1);
    } else {
      checkboxs.push(data);
    }
    setChecked(checkboxs);
  };

  const locationHandle = () => {
    setlocations(checked);
    setShowModal(false);
    setChecked([]);
  };

  const countryList = [
    {
      country: "India",
      currency_code: "INR",
    },
    {
      country: "Afghanistan",
      currency_code: "AFN",
    },
    {
      country: "Albania",
      currency_code: "ALL",
    },
    {
      country: "Algeria",
      currency_code: "DZD",
    },
    {
      country: "American Samoa",
      currency_code: "USD",
    },
    {
      country: "Andorra",
      currency_code: "EUR",
    },
    {
      country: "Angola",
      currency_code: "AOA",
    },
    {
      country: "Anguilla",
      currency_code: "XCD",
    },
    {
      country: "Antarctica",
      currency_code: "XCD",
    },
    {
      country: "Antigua and Barbuda",
      currency_code: "XCD",
    },
    {
      country: "Argentina",
      currency_code: "ARS",
    },
    {
      country: "Armenia",
      currency_code: "AMD",
    },
    {
      country: "Aruba",
      currency_code: "AWG",
    },
    {
      country: "Australia",
      currency_code: "AUD",
    },
    {
      country: "Austria",
      currency_code: "EUR",
    },
    {
      country: "Azerbaijan",
      currency_code: "AZN",
    },
    {
      country: "Bahamas",
      currency_code: "BSD",
    },
    {
      country: "Bahrain",
      currency_code: "BHD",
    },
    {
      country: "Bangladesh",
      currency_code: "BDT",
    },
    {
      country: "Barbados",
      currency_code: "BBD",
    },
    {
      country: "Belarus",
      currency_code: "BYR",
    },
    {
      country: "Belgium",
      currency_code: "EUR",
    },
    {
      country: "Belize",
      currency_code: "BZD",
    },
    {
      country: "Benin",
      currency_code: "XOF",
    },
    {
      country: "Bermuda",
      currency_code: "BMD",
    },
    {
      country: "Bhutan",
      currency_code: "BTN",
    },
    {
      country: "Bolivia",
      currency_code: "BOB",
    },
    {
      country: "Bosnia and Herzegovina",
      currency_code: "BAM",
    },
    {
      country: "Botswana",
      currency_code: "BWP",
    },
    {
      country: "Bouvet Island",
      currency_code: "NOK",
    },
    {
      country: "Brazil",
      currency_code: "BRL",
    },
    {
      country: "British Indian Ocean Territory",
      currency_code: "USD",
    },
    {
      country: "Brunei",
      currency_code: "BND",
    },
    {
      country: "Bulgaria",
      currency_code: "BGN",
    },
    {
      country: "Burkina Faso",
      currency_code: "XOF",
    },
    {
      country: "Burundi",
      currency_code: "BIF",
    },
    {
      country: "Cambodia",
      currency_code: "KHR",
    },
    {
      country: "Cameroon",
      currency_code: "XAF",
    },
    {
      country: "Canada",
      currency_code: "CAD",
    },
    {
      country: "Cape Verde",
      currency_code: "CVE",
    },
    {
      country: "Cayman Islands",
      currency_code: "KYD",
    },
    {
      country: "Central African Republic",
      currency_code: "XAF",
    },
    {
      country: "Chad",
      currency_code: "XAF",
    },
    {
      country: "Chile",
      currency_code: "CLP",
    },
    {
      country: "China",
      currency_code: "CNY",
    },
    {
      country: "Christmas Island",
      currency_code: "AUD",
    },
    {
      country: "Cocos (Keeling) Islands",
      currency_code: "AUD",
    },
    {
      country: "Colombia",
      currency_code: "COP",
    },
    {
      country: "Comoros",
      currency_code: "KMF",
    },
    {
      country: "Congo",
      currency_code: "XAF",
    },
    {
      country: "Cook Islands",
      currency_code: "NZD",
    },
    {
      country: "Costa Rica",
      currency_code: "CRC",
    },
    {
      country: "Croatia",
      currency_code: "HRK",
    },
    {
      country: "Cuba",
      currency_code: "CUP",
    },
    {
      country: "Cyprus",
      currency_code: "EUR",
    },
    {
      country: "Czech Republic",
      currency_code: "CZK",
    },
    {
      country: "Denmark",
      currency_code: "DKK",
    },
    {
      country: "Djibouti",
      currency_code: "DJF",
    },
    {
      country: "Dominica",
      currency_code: "XCD",
    },
    {
      country: "Dominican Republic",
      currency_code: "DOP",
    },
    {
      country: "East Timor",
      currency_code: "USD",
    },
    {
      country: "Ecuador",
      currency_code: "ECS",
    },
    {
      country: "Egypt",
      currency_code: "EGP",
    },
    {
      country: "El Salvador",
      currency_code: "SVC",
    },
    {
      country: "England",
      currency_code: "GBP",
    },
    {
      country: "Equatorial Guinea",
      currency_code: "XAF",
    },
    {
      country: "Eritrea",
      currency_code: "ERN",
    },
    {
      country: "Estonia",
      currency_code: "EUR",
    },
    {
      country: "Ethiopia",
      currency_code: "ETB",
    },
    {
      country: "Falkland Islands",
      currency_code: "FKP",
    },
    {
      country: "Faroe Islands",
      currency_code: "DKK",
    },
    {
      country: "Fiji Islands",
      currency_code: "FJD",
    },
    {
      country: "Finland",
      currency_code: "EUR",
    },
    {
      country: "France",
      currency_code: "EUR",
    },
    {
      country: "French Guiana",
      currency_code: "EUR",
    },
    {
      country: "French Polynesia",
      currency_code: "XPF",
    },
    {
      country: "French Southern territories",
      currency_code: "EUR",
    },
    {
      country: "Gabon",
      currency_code: "XAF",
    },
    {
      country: "Gambia",
      currency_code: "GMD",
    },
    {
      country: "Georgia",
      currency_code: "GEL",
    },
    {
      country: "Germany",
      currency_code: "EUR",
    },
    {
      country: "Ghana",
      currency_code: "GHS",
    },
    {
      country: "Gibraltar",
      currency_code: "GIP",
    },
    {
      country: "Greece",
      currency_code: "EUR",
    },
    {
      country: "Greenland",
      currency_code: "DKK",
    },
    {
      country: "Grenada",
      currency_code: "XCD",
    },
    {
      country: "Guadeloupe",
      currency_code: "EUR",
    },
    {
      country: "Guam",
      currency_code: "USD",
    },
    {
      country: "Guatemala",
      currency_code: "QTQ",
    },
    {
      country: "Guinea",
      currency_code: "GNF",
    },
    {
      country: "Guinea-Bissau",
      currency_code: "CFA",
    },
    {
      country: "Guyana",
      currency_code: "GYD",
    },
    {
      country: "Haiti",
      currency_code: "HTG",
    },
    {
      country: "Heard Island and McDonald Islands",
      currency_code: "AUD",
    },
    {
      country: "Holy See (Vatican City State)",
      currency_code: "EUR",
    },
    {
      country: "Honduras",
      currency_code: "HNL",
    },
    {
      country: "Hong Kong",
      currency_code: "HKD",
    },
    {
      country: "Hungary",
      currency_code: "HUF",
    },
    {
      country: "Iceland",
      currency_code: "ISK",
    },

    {
      country: "Indonesia",
      currency_code: "IDR",
    },
    {
      country: "Iran",
      currency_code: "IRR",
    },
    {
      country: "Iraq",
      currency_code: "IQD",
    },
    {
      country: "Ireland",
      currency_code: "EUR",
    },
    {
      country: "Israel",
      currency_code: "ILS",
    },
    {
      country: "Italy",
      currency_code: "EUR",
    },
    {
      country: "Ivory Coast",
      currency_code: "XOF",
    },
    {
      country: "Jamaica",
      currency_code: "JMD",
    },
    {
      country: "Japan",
      currency_code: "JPY",
    },
    {
      country: "Jordan",
      currency_code: "JOD",
    },
    {
      country: "Kazakhstan",
      currency_code: "KZT",
    },
    {
      country: "Kenya",
      currency_code: "KES",
    },
    {
      country: "Kiribati",
      currency_code: "AUD",
    },
    {
      country: "Kuwait",
      currency_code: "KWD",
    },
    {
      country: "Kyrgyzstan",
      currency_code: "KGS",
    },
    {
      country: "Laos",
      currency_code: "LAK",
    },
    {
      country: "Latvia",
      currency_code: "LVL",
    },
    {
      country: "Lebanon",
      currency_code: "LBP",
    },
    {
      country: "Lesotho",
      currency_code: "LSL",
    },
    {
      country: "Liberia",
      currency_code: "LRD",
    },
    {
      country: "Libyan Arab Jamahiriya",
      currency_code: "LYD",
    },
    {
      country: "Liechtenstein",
      currency_code: "CHF",
    },
    {
      country: "Lithuania",
      currency_code: "LTL",
    },
    {
      country: "Luxembourg",
      currency_code: "EUR",
    },
    {
      country: "Macau",
      currency_code: "MOP",
    },
    {
      country: "North Macedonia",
      currency_code: "MKD",
    },
    {
      country: "Madagascar",
      currency_code: "MGF",
    },
    {
      country: "Malawi",
      currency_code: "MWK",
    },
    {
      country: "Malaysia",
      currency_code: "MYR",
    },
    {
      country: "Maldives",
      currency_code: "MVR",
    },
    {
      country: "Mali",
      currency_code: "XOF",
    },
    {
      country: "Malta",
      currency_code: "EUR",
    },
    {
      country: "Marshall Islands",
      currency_code: "USD",
    },
    {
      country: "Martinique",
      currency_code: "EUR",
    },
    {
      country: "Mauritania",
      currency_code: "MRO",
    },
    {
      country: "Mauritius",
      currency_code: "MUR",
    },
    {
      country: "Mayotte",
      currency_code: "EUR",
    },
    {
      country: "Mexico",
      currency_code: "MXN",
    },
    {
      country: "Micronesia, Federated States of",
      currency_code: "USD",
    },
    {
      country: "Moldova",
      currency_code: "MDL",
    },
    {
      country: "Monaco",
      currency_code: "EUR",
    },
    {
      country: "Mongolia",
      currency_code: "MNT",
    },
    {
      country: "Montserrat",
      currency_code: "XCD",
    },
    {
      country: "Morocco",
      currency_code: "MAD",
    },
    {
      country: "Mozambique",
      currency_code: "MZN",
    },
    {
      country: "Myanmar",
      currency_code: "MMR",
    },
    {
      country: "Namibia",
      currency_code: "NAD",
    },
    {
      country: "Nauru",
      currency_code: "AUD",
    },
    {
      country: "Nepal",
      currency_code: "NPR",
    },
    {
      country: "Netherlands",
      currency_code: "EUR",
    },
    {
      country: "Netherlands Antilles",
      currency_code: "ANG",
    },
    {
      country: "New Caledonia",
      currency_code: "XPF",
    },
    {
      country: "New Zealand",
      currency_code: "NZD",
    },
    {
      country: "Nicaragua",
      currency_code: "NIO",
    },
    {
      country: "Niger",
      currency_code: "XOF",
    },
    {
      country: "Nigeria",
      currency_code: "NGN",
    },
    {
      country: "Niue",
      currency_code: "NZD",
    },
    {
      country: "Norfolk Island",
      currency_code: "AUD",
    },
    {
      country: "North Korea",
      currency_code: "KPW",
    },
    {
      country: "Northern Ireland",
      currency_code: "GBP",
    },
    {
      country: "Northern Mariana Islands",
      currency_code: "USD",
    },
    {
      country: "Norway",
      currency_code: "NOK",
    },
    {
      country: "Oman",
      currency_code: "OMR",
    },
    {
      country: "Pakistan",
      currency_code: "PKR",
    },
    {
      country: "Palau",
      currency_code: "USD",
    },
    {
      country: "Palestine",
      currency_code: null,
    },
    {
      country: "Panama",
      currency_code: "PAB",
    },
    {
      country: "Papua New Guinea",
      currency_code: "PGK",
    },
    {
      country: "Paraguay",
      currency_code: "PYG",
    },
    {
      country: "Peru",
      currency_code: "PEN",
    },
    {
      country: "Philippines",
      currency_code: "PHP",
    },
    {
      country: "Pitcairn Islands",
      currency_code: "NZD",
    },
    {
      country: "Poland",
      currency_code: "PLN",
    },
    {
      country: "Portugal",
      currency_code: "EUR",
    },
    {
      country: "Puerto Rico",
      currency_code: "USD",
    },
    {
      country: "Qatar",
      currency_code: "QAR",
    },
    {
      country: "Reunion",
      currency_code: "EUR",
    },
    {
      country: "Romania",
      currency_code: "RON",
    },
    {
      country: "Russian Federation",
      currency_code: "RUB",
    },
    {
      country: "Rwanda",
      currency_code: "RWF",
    },
    {
      country: "Saint Helena",
      currency_code: "SHP",
    },
    {
      country: "Saint Kitts and Nevis",
      currency_code: "XCD",
    },
    {
      country: "Saint Lucia",
      currency_code: "XCD",
    },
    {
      country: "Saint Pierre and Miquelon",
      currency_code: "EUR",
    },
    {
      country: "Saint Vincent and the Grenadines",
      currency_code: "XCD",
    },
    {
      country: "Samoa",
      currency_code: "WST",
    },
    {
      country: "San Marino",
      currency_code: "EUR",
    },
    {
      country: "Sao Tome and Principe",
      currency_code: "STD",
    },
    {
      country: "Saudi Arabia",
      currency_code: "SAR",
    },
    {
      country: "Scotland",
      currency_code: "GBP",
    },
    {
      country: "Senegal",
      currency_code: "XOF",
    },
    {
      country: "Serbia",
      currency_code: "RSD",
    },
    {
      country: "Seychelles",
      currency_code: "SCR",
    },
    {
      country: "Sierra Leone",
      currency_code: "SLL",
    },
    {
      country: "Singapore",
      currency_code: "SGD",
    },
    {
      country: "Slovakia",
      currency_code: "EUR",
    },
    {
      country: "Slovenia",
      currency_code: "EUR",
    },
    {
      country: "Solomon Islands",
      currency_code: "SBD",
    },
    {
      country: "Somalia",
      currency_code: "SOS",
    },
    {
      country: "South Africa",
      currency_code: "ZAR",
    },
    {
      country: "South Georgia and the South Sandwich Islands",
      currency_code: "GBP",
    },
    {
      country: "South Korea",
      currency_code: "KRW",
    },
    {
      country: "South Sudan",
      currency_code: "SSP",
    },
    {
      country: "Spain",
      currency_code: "EUR",
    },
    {
      country: "Sri Lanka",
      currency_code: "LKR",
    },
    {
      country: "Sudan",
      currency_code: "SDG",
    },
    {
      country: "Suriname",
      currency_code: "SRD",
    },
    {
      country: "Svalbard and Jan Mayen",
      currency_code: "NOK",
    },
    {
      country: "Swaziland",
      currency_code: "SZL",
    },
    {
      country: "Sweden",
      currency_code: "SEK",
    },
    {
      country: "Switzerland",
      currency_code: "CHF",
    },
    {
      country: "Syria",
      currency_code: "SYP",
    },
    {
      country: "Tajikistan",
      currency_code: "TJS",
    },
    {
      country: "Tanzania",
      currency_code: "TZS",
    },
    {
      country: "Thailand",
      currency_code: "THB",
    },
    {
      country: "The Democratic Republic of Congo",
      currency_code: "CDF",
    },
    {
      country: "Togo",
      currency_code: "XOF",
    },
    {
      country: "Tokelau",
      currency_code: "NZD",
    },
    {
      country: "Tonga",
      currency_code: "TOP",
    },
    {
      country: "Trinidad and Tobago",
      currency_code: "TTD",
    },
    {
      country: "Tunisia",
      currency_code: "TND",
    },
    {
      country: "Turkey",
      currency_code: "TRY",
    },
    {
      country: "Turkmenistan",
      currency_code: "TMT",
    },
    {
      country: "Turks and Caicos Islands",
      currency_code: "USD",
    },
    {
      country: "Tuvalu",
      currency_code: "AUD",
    },
    {
      country: "Uganda",
      currency_code: "UGX",
    },
    {
      country: "Ukraine",
      currency_code: "UAH",
    },
    {
      country: "United Arab Emirates",
      currency_code: "AED",
    },
    {
      country: "United Kingdom",
      currency_code: "GBP",
    },
    {
      country: "United States",
      currency_code: "USD",
    },
    {
      country: "United States Minor Outlying Islands",
      currency_code: "USD",
    },
    {
      country: "Uruguay",
      currency_code: "UYU",
    },
    {
      country: "Uzbekistan",
      currency_code: "UZS",
    },
    {
      country: "Vanuatu",
      currency_code: "VUV",
    },
    {
      country: "Venezuela",
      currency_code: "VEF",
    },
    {
      country: "Vietnam",
      currency_code: "VND",
    },
    {
      country: "Virgin Islands, British",
      currency_code: "USD",
    },
    {
      country: "Virgin Islands, U.S.",
      currency_code: "USD",
    },
    {
      country: "Wales",
      currency_code: "GBP",
    },
    {
      country: "Wallis and Futuna",
      currency_code: "XPF",
    },
    {
      country: "Western Sahara",
      currency_code: "MAD",
    },
    {
      country: "Yemen",
      currency_code: "YER",
    },
    {
      country: "Zambia",
      currency_code: "ZMW",
    },
    {
      country: "Zimbabwe",
      currency_code: "ZWD",
    },
  ];

  const factoriesNav = () => {
    const stored = {
      product_code: product_code,
      product_id: product_id,
      product_image: product_image,
      product_imagePre: product_imagePre,
      product_name: product_name,
      country_origin: country_origin,
      issue_date: issue_date,
      expiry_date: expiry_date,
      types: types,
      uom_id: uom_id,
      max_order_qty: max_order_qty,
      min_order_qty: min_order_qty,
      price: price,
      orginal_certificates: orginal_certificates,
      technical_documents: technical_documents,
      product_pictures: product_pictures,
      duration: duration,
      sku_id: sku_id,
    };
    // navigate("/mcprofile/transport", {
    navigate("/mcprofile", {
      state: {
        factory: "factory",
        data: data,
        item: item,
        stored: stored,
        type: type,
      },
    });
  };

  const storied = () => {
    setproduct_id(storedData?.product_id);
    setproduct_code(storedData?.product_code);
    setproduct_name(storedData?.product_name);
    setissue_date(storedData?.issue_date);
    setexpiry_date(storedData?.expiry_date);
    setcountry_origin(storedData?.country_origin);
    setmax_order_qty(storedData?.max_order_qty);
    setmin_order_qty(storedData?.min_order_qty);
    setorginal_certificates(storedData?.orginal_certificates);
    setprice(storedData?.price);
    setproduct_image(storedData?.product_image);
    setproduct_pictures(storedData?.product_pictures);
    settechnical_documents(storedData?.technical_documents);
    settypes(storedData?.types);
    setuom_id(storedData?.uom_id);
    setduration(storedData?.duration);
    setproduct_imagePre(storedData?.product_imagePre);
  };

  useEffect(() => {
    if (storedData) {
      storied();
    }
  }, []);

  console.log("locations", locations);

  return (
    <Col
      className="d-md-flex flex-wrap"
      xs={12}
      style={{ position: "relative" }}
    >
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Col xs={12} md={6} className="add-prod-left px-3">
        {/* <Link
          to="/product"
          className="cust-btn p-0 mb-2 d-flex align-items-center"
          style={{ // color: "#247395 !important" }}
        >
          <FaArrowLeft className="me-2" /> Add Product
        </Link> */}
        {/* <div>
          <div className="d-flex align-items-center">
            <p className="mb-0">
              P054RT598637894 - Currected Grain Leather Peeling
            </p>
            <Link to="/product" className="border-0 opac_half fw-bold p-0 ms-3">
              <ImLoop />
            </Link>
          </div>
          <p className="mb-0">Currected Grain - 54RT59863789</p>
          <p className="mb-0">Leather Peeling</p>
        </div> */}
        <AddList list={list} />

        {/* <ProductSpecification /> */}
        {/* <AddDescription /> */}
        {type == "edit" ? null : (
          <div className="d-flex justify-content-end mt-2 w-100">
            <div className="d-flex gap-3">
              {/* <Link
                to="/dashboard"
                style={{ fontSize: "15px", border: "1px solid #aeaeae" }}
                className="bg-light text-end ms-auto me-0 d-block mt-2 px-2 d-flex align-items-center fnt-fam-f2 opac_half"
              >
                Cancel
              </Link> */}

              {showaddForm ? null : (
                <button
                  onClick={onCLickBtnHandler}
                  className="cust-btn bg-primary1 text-white text-end ms-auto me-0 d-block mt-2"
                >
                  Proceed to Map
                  {/* Navigate Next */}
                </button>
              )}
            </div>
          </div>
        )}
      </Col>
      <Col
        xs={12}
        md={6}
        style={{
          display: showaddForm ? "block" : "none",
        }}
        className="rit-ad-prod"
      >
        {/* <AddProductRit data={data} list={list} /> */}
        <div style={{ marginRight: "10px" }}>
          {errorBox && (
            <ErrorModel
              error={error}
              errorBox={errorBox}
              errorModalClose={errorModalClose}
            />
          )}
          <div>
            <p>
              Supplier Product Code <span className="red-text">*</span>{" "}
            </p>
            <input
              value={product_code}
              onChange={(e) => setproduct_code(e.target.value)}
              placeholder=""
              className="prod-code-input"
            />
          </div>
          <div>
            <p>
              Supplier Product Name <span className="red-text">*</span>
            </p>
            <input
              value={product_name}
              onChange={(e) => setproduct_name(e.target.value)}
              placeholder=""
              className="prod-code-input"
            />
          </div>
          <Col>
            <h4 className="title my-1 py-1">
              Product Image <span className="red-text">*</span>
            </h4>
            <Col xs={12} className="d-flex">
              <Col xs={4} md={4}>
                {/* <div className="adproduct-rgt-div-add-img d-sm-flex justify-content-center align-items-center fs-12px opac-85">
              Add Photo
            </div> */}
                {/* <input
              className="custom-add-file"
              type="file"
              onChange={(e) => {
                setproduct_image(e.target.files[0]);
                setproduct_imagePre(URL.createObjectURL(e.target.files[0]));
              }}
            /> */}
                {/* {product_imagePre ? (
              <img
                style={{
                  width: "150px",
                  height: "80px",
                  borderRadius: "10px",
                }}
                src={product_imagePre}
              />
            ) : null} */}
                <div className="mcp_image">
                  <div className="prof-imag">
                    <Image
                      ref={uploadedImage}
                      src={product_imagePre ? product_imagePre : PersonImg}
                      onClick={() => imageUploader.current.click()}
                      className="prf-img  "
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={fileChange}
                      ref={imageUploader}
                      className="d-none"
                    />
                  </div>
                </div>
              </Col>
              {/* {product_imagePre ? (
            <Col xs={4} md={4} className="mx-2 my-2">
              <img
                style={{ width: "150px", height: "80px", borderRadius: "10px" }}
                src={product_imagePre}
              />
            </Col>
          ) : null} */}

              {/* <Col xs={4} md={2}>
            <div className="adproduct-rgt-div-img bg-light-grey"></div>
            
            <p className="fs-12px opac-85 ps-2">
              Remove
              <span>
                <img src={Delete_Icon} className="wid-15px ms-1" />
               
              </span>
            </p>
          </Col> */}
            </Col>
          </Col>
          <div className="prod-inf mt-3 mb-2">
            <h4>Product Information</h4>
            <MdOutlineKeyboardArrowDown className="text-white" />
          </div>
          <Col xs={12} sm={10} ms={6}>
            <p>
              Country of Origin <span className="red-text">*</span>
            </p>
            <select
              className="prod-code-input"
              value={country_origin}
              onChange={(e) => setcountry_origin(e.target.value)}
            >
              <option>Select Country</option>
              {countryList?.map((item, i) => (
                <option value={item?.country} key={i}>
                  {item?.country}
                </option>
              ))}
            </select>
          </Col>
          <Col xs={12} className="d-flex flex-wrap">
            <Col
              xs={12}
              md={6}
              style={{ paddingRight: "3px" }}
              className="input-box mb-3"
            >
              <p>
                Date of Issue <span className="red-text">*</span>
              </p>
              <DatePicker setStartDate={setissue_date} startDate={issue_date} />
            </Col>
            <Col
              xs={12}
              md={6}
              style={{ paddingLeft: "3px" }}
              className="input-box mb-3"
            >
              <p>Date of Expiry</p>
              <div className="file-input">
                <DatePicker
                  setStartDate={setexpiry_date}
                  startDate={expiry_date}
                  placeholder={"Select Date"}
                />
              </div>
            </Col>
          </Col>
          <Col xs={12} style={{ paddingLeft: "3px" }}>
            <p className="primary1">{certificate}</p>
            <input
              className="custom-file-input"
              onChange={(e) => onChangeCertificate(e)}
              type="file"
              multiple
            />
            <h5 className="primary1 note py-2">
              Note: Format: jpg, jpeg, png. Upload upto 5MB each file allowed to
              upload maximum of 3 file
            </h5>
          </Col>
          {orginal_certificatesView?.map((item, ind) => (
            <div className="d-flex gap-1 my-4" key={ind}>
              <div className="d-flex align-items-center pdf-icon-cont">
                <AiOutlineFilePdf />
              </div>
              <p className="d-flex align-items-center">
                {"Origin Certificate"}
              </p>
              <TiDeleteOutline
                onClick={() => removeCertificate(item?.id, ind)}
              />
            </div>
          ))}
          {/* <OrderCapacity /> */}
          <Col className="order-capacity mt-3">
            <div className="d-flex align-items-center my-2 ">
              <input
                className="me-2"
                type="radio"
                id="specifyColor"
                name="radio1"
                value="GFG"
                checked={types == 1 ? true : false}
                onClick={(e) => {
                  setActiveBtn(1);
                  settypes(1);
                }}
              />
              <label className="me-2">Manufacturer</label>
              <input
                className="me-2"
                type="radio"
                id="specifyColor"
                name="radio1"
                value="GFG"
                checked={types == 2 ? true : false}
                onClick={(e) => {
                  setActiveBtn(2);
                  settypes(2);
                }}
              />
              <label>Trader</label>
            </div>
            <h4 className="title mb-2">
              {activeBtn == 1 ? "Production" : "Order"} Capacity
            </h4>

            <Col xs={12} className="d-flex flex-wrap">
              {/* <Col xs={12} md={6} style={{ paddingLeft: "3px" }}>
            <p>Production Capacity</p>
            <div className="file-input">
              {/* <input
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
                placeholder=""
                className="prod-code-input"
              /> 
              <select className="prod-code-input">
                <option>Choose...</option>
                <option>Monthly</option>
                <option>Half</option>
                <option>Yearly</option>
              </select>
            </div>
          </Col> */}
              <Col xs={12} md={6} style={{ paddingRight: "3px" }}>
                <p>
                  Duration <span className="red-text">*</span>
                </p>
                {/* <input
              value={duration}
              onChange={(e) => setduration(e.target.value)}
              placeholder=""
              className="prod-code-input"
            /> */}
                <select
                  className="prod-code-input"
                  value={duration}
                  onChange={(e) => setduration(e.target.value)}
                >
                  <option>Choose Duration</option>
                  <option value={"Monthly"}>Monthly</option>
                  <option value={"Half"}>Half</option>
                  <option value={"Yearly"}>Yearly</option>
                </select>
              </Col>
              <Col xs={12} md={6} style={{ paddingLeft: "3px" }}>
                <p>
                  UOM <span className="red-text">*</span>
                </p>
                <div className="file-input">
                  <select
                    className="prod-code-input"
                    value={uom_id}
                    onChange={(e) => setuom_id(e.target.value)}
                  >
                    <option>Select UOM</option>
                    {input?.uoms?.map((item, i) => (
                      <option value={item?.UomID}>{item?.UomCode}</option>
                    ))}
                  </select>
                  {/* <input
                    value={uom_id}
                    onChange={(e) => setuom_id(e.target.value)}
                    placeholder=""
                    type="number"
                    className="prod-code-input"
                  /> */}
                </div>
              </Col>
            </Col>
            <Col xs={12} className="d-flex flex-wrap">
              <Col xs={12} md={6} style={{ paddingRight: "3px" }}>
                <p>
                  Minimum {activeBtn == 1 ? "Production" : "Order"} Qty{" "}
                  <span className="red-text">*</span>
                </p>
                <input
                  value={min_order_qty}
                  onChange={(e) => setmin_order_qty(e.target.value)}
                  placeholder=""
                  className="prod-code-input"
                  type={"number"}
                />
              </Col>
              <Col xs={12} md={6} style={{ paddingLeft: "3px" }}>
                <p>
                  Maximum {activeBtn == 1 ? "Production" : "Order"} Qty{" "}
                  <span className="red-text">*</span>
                </p>
                <div className="file-input">
                  <input
                    value={max_order_qty}
                    onChange={(e) => setmax_order_qty(e.target.value)}
                    placeholder=""
                    className="prod-code-input"
                    type={"number"}
                  />
                </div>
              </Col>
            </Col>
            <Col xs={12} className="d-flex flex-wrap">
              <Col xs={12} md={6} style={{ paddingRight: "3px" }}>
                <p>
                  Base Price <span className="red-text">*</span>
                </p>
                <input
                  value={price}
                  onChange={(e) => setprice(e.target.value)}
                  placeholder=""
                  className="prod-code-input"
                  type={"number"}
                />
              </Col>
              <Col xs={12} md={6} style={{ paddingRight: "3px" }}>
                <p>Currency</p>
                <select
                  className="prod-code-input"
                  value={currency_id}
                  onChange={(e) => setCurrency_id(e.target.value)}
                >
                  <option>Select Currency</option>
                  {input?.currencies?.map((item, i) => (
                    <option value={item?.currency_id} key={i}>
                      {item?.currency_code}
                    </option>
                  ))}
                </select>
              </Col>
            </Col>
            {/* <Col xs={12} className="d-flex flex-wrap">
          <Col xs={12} ms={6} style={{ paddingLeft: "3px" }}>
            <p>Base Price</p>
            <input
              value={productCode}
              onChange={(e) => setProductCode(e.target.value)}
              placeholder=""
              className="prod-code-input"
            />
          </Col>
          <Col xs={12} ms={6} style={{ paddingLeft: "3px" }}>
            <p>Production Capacity</p>
            <input
              value={productCode}
              onChange={(e) => setProductCode(e.target.value)}
              placeholder=""
              className="prod-code-input"
            />
          </Col>
        </Col> */}
          </Col>
          {/* <FactoryIfo /> */}
          {activeBtn == 1 ? (
            <Col>
              <div className="prod-inf">
                <h4>Factory Info</h4>
                <MdOutlineKeyboardArrowDown className="text-white" />
              </div>
              {locations?.length > 0 ? (
                locations?.map((fact, ins) => {
                  let transport = factList?.find(
                    (i) =>
                      i?.id == fact?.RefSupplierBranchTyeID ||
                      fact?.supplier_address?.RefSupplierBranchTyeID
                  );
                  let add = [
                    fact?.address1 || fact?.supplier_address?.address1,
                    fact?.address2 || fact?.supplier_address?.address2,
                    fact?.address3 || fact?.supplier_address?.address3,
                  ];
                  return (
                    <div className="address-fact" key={ins}>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="gade-chennai">{transport?.name}</p>
                        <div className="d-sm-flex gap-3">
                          <button
                            className="border-0 bg-transparent px-2"
                            onClick={(e) =>
                              removeFactorys(fact?.SupplierDetailID, ins)
                            }
                          >
                            <img src={Delete_Icon} className="wid-15px" />
                          </button>
                        </div>
                      </div>
                      <Col className="d-flex gap-2 justify-content-between">
                        <div className="d-flex gap-2">
                          <div>
                            <p>Mail ID </p>
                            <p>Mobile </p>
                          </div>
                          <div>
                            <p>
                              {fact?.email || fact?.supplier_address?.email}
                            </p>
                            <p>
                              {fact?.supplier_address
                                ? fact?.supplier_address?.telephone_code +
                                  " " +
                                  fact?.supplier_address?.telephone
                                : fact?.telephone_code + " " + fact?.telephone}
                            </p>
                          </div>
                        </div>
                        <Col>
                          <p>Address </p>
                          <p>
                            {fact?.address1
                              ? `${fact?.address1 && fact?.address1} ${
                                  fact?.address2 && "," + fact?.address2
                                } ${fact?.address3 && "," + fact?.address3}`
                              : `${
                                  fact?.supplier_address?.address1 &&
                                  fact?.supplier_address?.address1
                                } ${
                                  fact?.supplier_address?.address2 &&
                                  "," + fact?.supplier_address?.address2
                                }
                          ${
                            fact?.supplier_address?.address3 &&
                            "," + fact?.supplier_address?.address3
                          }`}
                          </p>
                        </Col>
                      </Col>
                    </div>
                  );
                })
              ) : (
                <div className="text-center my-2 py-2">
                  <p style={{ color: "#555555" }}>
                    Map Factory Information from
                  </p>
                  <p style={{ color: "#555555" }}>Master Company Profile</p>
                  <button
                    className="bg-black text-white rounded border 0 w-50 my-2"
                    onClick={() => setShowModal(true)}
                  >
                    Map Factory Info
                  </button>
                </div>
              )}

              {/* {locations?.map((item, ind) => {
                let add = [
                  item?.AddressLine1,
                  item?.AddressLine2,
                  item?.AddressLine3,
                ];
                return (
                  <>
                    <div className="address-fact" key={ind}>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="gade-chennai">{item?.FactoryName}</p>
                        <div className="d-sm-flex gap-3">
                          <button
                            className="border-0 bg-transparent ms-1"
                            onClick={(e) =>
                              removeFactorys(item?.FactoryID, ind)
                            }
                          >
                            <img src={Delete_Icon} className="wid-15px" />
                          </button>
                        </div>
                      </div>
                      <Col className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div style={{ width: "100px" }}>
                            <p>Factory ID </p>
                            <p>Company ID </p>
                          </div>
                          <div style={{ width: "100px" }}>
                            <p>{item?.FactoryID}</p>
                            <p>{item?.CompanyID}</p>
                          </div>
                        </div>
                        <Col>
                          <p>Address </p>
                          <p>{add.toString()}</p>
                        </Col>
                      </Col>
                    </div>
                  </>
                );
              })} */}
            </Col>
          ) : null}

          {/* <div className="address-fact">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="gade-chennai">Gade chennai Factory inc</p>
                  <div className="d-sm-flex gap-3">
                    <button className="border-0 bg-transparent me-1">
                  <b>+</b>
                </button> 
                    <button className="border-0 bg-transparent ms-1">
                      <img src={Delete_Icon} className="wid-15px" />
                    </button>
                  </div>
                </div>
                <Col className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div style={{ width: "100px" }}>
                      <p>Factory ID </p>
                      <p>Factory ID </p>
                    </div>
                    <div style={{ width: "100px" }}>
                      <p>FAC00010</p>
                      <p>Assembly</p>
                    </div>
                  </div>
                  <Col>
                    <p>Address </p>
                    <p>
                      140, Block No, Ground Floor, TS, SH 49A, Tharamani,
                      Chennai, Tamil Nadu 600113
                    </p>
                  </Col>
                </Col>
              </div> */}

          <div className="prod-inf my-2">
            <h4>Supplier Uploads</h4>
            <MdOutlineKeyboardArrowDown className="text-white" />
          </div>
          {/* <SupplierUpload text_show="Upload Technical Document" /> */}

          <Col xs={12} style={{ paddingLeft: "3px" }}>
            <p className="primary1">{certificate}</p>
            <input
              className="custom-file-inputs"
              onChange={(e) => onChangetechnical(e)}
              type="file"
              multiple
            />
            <h5 className="primary1 note py-2">
              Note: Format: jpg, jpeg, png. Upload upto 5MB each file allowed to
              upload maximum of 3 file
            </h5>
          </Col>
          {technical_documentsView?.map((item, ind) => (
            <div className="d-flex gap-1 my-4" key={ind}>
              <div className="d-flex align-items-center pdf-icon-cont">
                <AiOutlineFilePdf />
              </div>
              <p className="d-flex align-items-center">
                {"Technical Document"}
              </p>
              <TiDeleteOutline
                onClick={() => removeTechnical_documents(item?.id, ind)}
              />
            </div>
          ))}

          {/* <SupplierUpload text_show="Upload Product Pictures(Optional)" /> */}
          {/* <Col>
            <Col className="position-relative">
              <p className="primary1">{certificate}</p>
              <p className="supplier-upload-file-upload-text">
                {"Upload Product Pictures(Optional)"}
              </p>
              <input
                className="custom-file-tech"
                onChange={(e) => onChangePictures(e)}
                type="file"
                multiple
              />
              <h5 className="primary1 note">
                Note: Format: jpg, jpeg, png. Upload upto 5MB each file allowed
                to upload maximum of 3 file
              </h5>
            </Col>
          </Col> */}
          <Col xs={12} style={{ paddingLeft: "3px" }}>
            <p className="primary1">{certificate}</p>
            <input
              className="custom-file-inputss"
              onChange={(e) => onChangePictures(e)}
              type="file"
              multiple
            />
            <h5 className="primary1 note py-2">
              Note: Format: jpg, jpeg, png. Upload upto 5MB each file allowed to
              upload maximum of 3 file
            </h5>
          </Col>
          {product_picturesView?.map((item, ind) => (
            <div className="d-flex gap-1 my-4" key={ind}>
              <div className="d-flex align-items-center pdf-icon-cont">
                <AiOutlineFilePdf />
              </div>
              <p className="d-flex align-items-center">{"Product Pictures"}</p>
              <TiDeleteOutline onClick={() => removePicture(item?.id, ind)} />
            </div>
          ))}
          <div className="d-flex mt-2 mb-4 justify-content-end">
            <button
              className="cust-btn me-2"
              style={{ border: "1px solid #000" }}
              onClick={() => navigate("/dashboard")}
            >
              Cancel
            </button>
            <button
              className="cust-btn bg-primary1 text-white"
              onClick={submitHandle}
            >
              Submit
            </button>
            {/* <button className="cust-btn bg-primary1 text-white">Submit</button> */}
          </div>
        </div>
      </Col>

      <Modal
        id="#verify"
        className="prod-filter"
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">Filter</h5>
        </Modal.Header>
        <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
          <Col xs={12} lg={12}>
            <div
              className="register-con "
              style={{ height: "300px", overflow: "auto" }}
            >
              <div className="d-flex-ae-jb px-4">
                <table className="w-100">
                  <tbody>
                    {factoriesList?.map((list, index) => {
                      let name = lists?.filter(
                        (i) => i?.id == list?.RefSupplierBranchTyeID
                      );
                      return (
                        <tr key={index}>
                          <td className="d-grid">
                            <th className="d-flex">
                              <p className={"mx-2"}>{name[0]?.name}</p>
                            </th>
                            <td>
                              Address:{" "}
                              {`${list?.address1 && list?.address1}${
                                list?.address2 !== null
                                  ? "," + list?.address2
                                  : " "
                              }${
                                list?.address3 !== null
                                  ? "," + list?.address3
                                  : " "
                              }`}
                            </td>
                            <td>
                              <span>
                                Telephone:{" "}
                                {list?.telephone_code + " " + list?.telephone}
                              </span>
                              <span>Fax: {list?.fax}</span>
                              <span>Mail: {list?.email}</span>
                            </td>
                          </td>
                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              onChange={(e) => checkBoxHandler(list)}
                              checked={checked?.includes(list)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Modal.Body>
        <div className="d-flex justify-content-end">
          <button
            className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
            onClick={() => setShowModal(false)}
          >
            Clear
          </button>
          <button
            className="cust-btn mb-2 bg-primary1 me-1 text-white"
            onClick={locationHandle}
          >
            Apply
          </button>
        </div>
      </Modal>
    </Col>
  );
};

export default MapProductDetail;
