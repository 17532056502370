import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import User_Dash_Table from "./User_Dash_table";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import useUser from "../../constants/Storage/userData";
import ErrorModel from "../../constants/Services/Api/errorModel";
import moment from "moment/moment";

function Dashboard() {
  const navigate = useNavigate();
  const { user } = useUser();
  const permission = localStorage.getItem("permissions");
  const [currentActive, setCurrentActive] = useState(0);
  const [tabs, setTabs] = useState(0);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState({});
  const [asnList, setAsnList] = useState({});
  const [grnList, setGrnList] = useState([]);
  const [grnCount, setGrnCount] = useState(0);
  const [dcList, setDcList] = useState({});
  const [rnList, setRnList] = useState([]);
  const [counts, setCounts] = useState([]);

  const getList = () => {
    let params = `?supplier_id=${user?.SupplierID}`;
    // let params = `?supplier_id=${7}`;
    api.dashboard().then((res) => {
      if (res?.status == "success") {
        // setList(res?.purchase_orders_dashboard);
        setCounts(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    let param = `?page=${1}`;

    api.purchaseOrderList(param).then((res) => {
      if (res?.status == "success") {
        let count = res?.pagination?.total;
        let response = res?.purchase_orders?.splice(0, 5);
        setList({ data: response, count: count });
        // setCounts(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.asnList(param).then((res) => {
      if (res?.status == "success") {
        let count = res?.pagination?.total;
        let response = res?.asn_lists?.splice(0, 5);
        setAsnList({ data: response, count: count });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    // api.dashboardAsn().then((res) => {
    //   if (res?.status == "success") {
    //     setAsnList(res?.asn_lists);
    //   } else {
    //     setError(res);
    //     setErrorBox(true);
    //   }
    // });

    api.dashboardGrn().then((res) => {
      if (res?.status == "success") {
        setGrnList(res?.goods_receipt_note);
        setGrnCount(res?.total_grn);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.dcTables(param).then((res) => {
      if (res?.status == "success") {
        let count = res?.pagination?.total;
        let response = res?.asn_supplier_details?.splice(0, 5);
        setDcList({ data: response, count: count });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    // api.dashboardDc().then((res) => {
    //   if (res?.status == "success") {
    //     setDcList(res?.asn_supplier_details);
    //   } else {
    //     setError(res);
    //     setErrorBox(true);
    //   }
    // });

    api.dashboardRetrunNote().then((res) => {
      if (res?.status == "success") {
        setRnList(res?.return_notes);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const [Transit, setTransit] = useState([]);
  const [Delivered, setDelivered] = useState([]);
  const [GrnOst, setGrnOst] = useState([]);
  const [ReturnNote, setReturnNote] = useState([]);
  const [OnGoing, setOnGoing] = useState([]);
  const [invoiceAmount, setInvoiceAmount] = useState({});
  const [UpcomingList, setUpcomingList] = useState([]);

  const getDashboard = () => {
    let temp = [];
    api.dathboardTransit().then((res) => {
      if (res?.status == "success") {
        setTransit(res?.ongoing_in_transit_asns);
        res?.ongoing_in_transit_asns?.map((item, ind) => {
          temp.push(item);
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api.dashboardDelivered().then((res) => {
      if (res?.status == "success") {
        setDelivered(res?.dc_delivered);
        res?.dc_delivered?.map((item, ind) => {
          temp.push(item);
        });
      } else {
        setError(res);
        setErrorBox(true);
      }

      api.dashboardOnGrn().then((res) => {
        if (res?.status == "success") {
          setGrnOst(res?.goods_receipt_notes);
          res?.goods_receipt_notes?.map((item, ind) => {
            temp.push(item);
          });
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    });

    api.dashboardReturn().then((res) => {
      if (res?.status == "success") {
        setReturnNote(res?.return_notes_ongoing);
        res?.return_notes_ongoing?.map((item, ind) => {
          temp.push(item);
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    setOnGoing(temp);

    api.dashboardAmount().then((res) => {
      if (res?.status == "success") {
        setInvoiceAmount(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getUpcoming = () => {
    api.dashboardUpcoming().then((res) => {
      if (res?.status == "success") {
        setUpcomingList(res?.upcoming_deliveries_asns);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const dummy_data_enq = [
    // "Awarded Date",
    "S.No.",
    "KH Division",
    "PO. Ref. No",
    "PO. Date",
    // "PO. Ref. No",
    "PO Value",
    // "Supplier Name",
    "Material Type",
    // "Delivery Date",
    "ACK Status",
  ];
  const dummy_data_quo = [
    "S.No.",
    "ASN Date",
    "KH Division",
    // "Reference Number",
    // "ASN Number",
    "PO. Ref. No",
    // "Title",
    // "PO No",
    "Material Type",
    "No. of Shipments",
    // "Awarded Date",
    // "RFQ Number",
    // "Value",
    "ACK Status",
  ];

  const dummy_data_po = [
    // "Reference Number",
    // "Date",
    // "Title",
    // "Awarded Date",
    // "PO Value",
    // "Title",
    "S.No.",
    "KH Division",
    "DC No.",
    "DC Date",
    "Invoice No.",
    // "PO No",
    "Material Type",
    // "ETD",
    // "ETA",
    // "Closing Date",
    "Shiment Status",
  ];
  const dummy_data_asn = [
    // "Reference Number",
    // "Date",
    // "Title",
    // "Awarded Date",
    // "PO Number",
    // "No of Shipments",
    // "Status",
    "S.No.",
    "KH Division",
    "GRN No.",
    "GRN Date",
    "DC Number",
    // "PO No",
    "Material Type",
    // "OSD No",
  ];
  const dummy_data_dc = [
    // "Reference Number",
    // "Date",
    // "Title",
    // "Awarded Date",
    // "PO Number",
    // "No of items",
    // "Status",
    "S.No.",
    "KH Division",
    "Return Note No",
    "RN Date",
    "GRN No.",
    "DC No.",
    "PO. No.",
    "Material",
    // "ACK status",
  ];
  const dummy_data_grn = [
    "S.No.",
    "Reference Number",
    "Date",
    "Title",
    "Awarded Date",
    "PO. Number",
    "OSD Items",
    "ACK Status",
  ];

  const dummy_data_rn = [
    "S.No.",
    "Reference Number",
    "Date",
    "Title",
    "Awarded Date",
    "PO. Number",
    "GRN Number",
    "ACK Status",
  ];

  const buttons_titles = [
    // "Enqiuries (36)",
    // "Quotation (12)",
    `Purchase Order (${list?.count ? list?.count : 0})`,
    `Advance Shipping Notice (${asnList?.count ? asnList?.count : 0})`,
    `Delivery Challan (${dcList?.count ? dcList?.count : 0})`,
    `Goods Received Note (${grnCount})`,
    `Return Notes (${rnList?.length})`,
  ];

  let table_temp_heading;

  switch (currentActive) {
    case 0:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_enq} num={0} data={list} />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_po} />;

      break;
    case 1:
      table_temp_heading = (
        <User_Dash_Table
          dummy_data={dummy_data_quo}
          num={1}
          asnList={asnList}
        />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_asn} />;

      break;
    case 2:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_po} num={2} dcList={dcList} />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_dc} />;

      break;
    case 3:
      table_temp_heading = (
        <User_Dash_Table
          dummy_data={dummy_data_asn}
          num={3}
          grnList={grnList}
        />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_grn} />;

      break;
    case 4:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_dc} num={4} rnList={rnList} />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_rn} />;

      break;
    case 5:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_grn} num={5} />
      );

      break;
    case 6:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_rn} num={6} />
      );

      break;
    default:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_quo} num={0} />
      );
  }
  const dashSecBtnClickHandler = (ind) => {
    setCurrentActive(ind);
  };
  let active_class;
  const renderedButtons = buttons_titles.map((title, ind) => {
    if (ind === currentActive) {
      active_class = "bg-primary1 text-white";
    } else {
      active_class = "bg-transparent";
    }
    return (
      <button
        key={ind}
        className={`${active_class} px-3 py-2 rounded-1 border-0 fs-14px fnt-fam-f1`}
        onClick={() => dashSecBtnClickHandler(ind)}
      >
        {title}
        {/* <span class="badge badge-secondary">New</span> */}
      </button>
    );
  });

  const navigation = () => {
    if (currentActive == 0 && permission?.includes("LIST-PURCHASEORDER")) {
      navigate("/purchase-order/list");
    } else if (
      currentActive == 1 &&
      permission?.includes("LIST-ADVANCESHIPPINGNOTE")
    ) {
      navigate("/asn-listing");
    } else if (
      currentActive == 2 &&
      permission?.includes("LIST-DELIVERYCHALLAN")
    ) {
      navigate("/dc-list");
    } else if (
      currentActive == 3 &&
      permission?.includes("LIST-GOODSRECEIPTNOTE")
    ) {
      navigate("/purchase-order/grn");
    } else if (currentActive == 4 && permission?.includes("LIST-RETURNNOTE")) {
      navigate("/purchase-order/return-notice-table");
    } else {
      navigate("/dashboard");
    }
  };

  const navigations = () => {
    if (tabs == 0 && permission?.includes("LIST-PURCHASEORDER")) {
      navigate("/purchase-order/list");
    } else if (tabs == 1 && permission?.includes("LIST-PURCHASEORDER")) {
      navigate("/purchase-order/list");
    } else if (tabs == 2 && permission?.includes("LIST-GOODSRECEIPTNOTE")) {
      navigate("/purchase-order/goods-received-note");
    } else if (tabs == 3 && permission?.includes("LIST-RETURNNOTE")) {
      navigate("/purchase-order/return-notice");
    } else {
      if (permission?.includes("LIST-PURCHASEORDER")) {
        navigate("/purchase-order/list");
      } else {
        navigate("/dashboard");
      }
    }
  };

  const style = {
    background: "#247396",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "none",
  };

  const styles = {
    background: "transparent",
    color: "#000",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "none",
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getDashboard();
    getUpcoming();
  }, []);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <Col>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="dashboard" />
      <TopBar />
      <div className="py-3 px-2  light-vilot-bg w-100 min-ht-80">
        <div className="wid-92">
          <div
            style={{ width: "93%" }}
            className="d-md-flex my-1 py-1 justify-content-between mid-sc-res"
          >
            <h5 className="fnt-fam-f1 fs-15px fw-bold">Summary</h5>
          </div>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="d-md-flex flex-wrap gap-2">{renderedButtons}</div>

            {user?.is_primary == "2" &&
            user?.sm_department_id == null ? null : (
              <button
                class="opac_half fnt-fam-f1 fs-15px"
                style={{
                  cursor: "pointer",
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={navigation}
              >
                View All
              </button>
            )}
          </div>
          {table_temp_heading}
          <div className="d-md-flex justify-content-between mid-sc-res">
            <h5 className="fnt-fam-f1 fs-15px fw-bold">
              Accounts Status {+user?.supplier?.CountryID == 1 ? "(INR)" : " "}
            </h5>

            {/* <button
              class="opac_half fnt-fam-f1 fs-15px"
              style={{
                cursor: "pointer",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={() => navigate("")}
            >
              View Report
            </button> */}
          </div>
          <div className="dashbord-card-cont p-3 d-sm-flex flex-wrap mid-sc-res">
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Total Orders: <span className="fw-bold">{list?.count}</span>
              </p>
              <p className="fs-15px fw-bold">
                {counts?.total_price
                  ? Number(counts?.total_price)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0}
              </p>
            </div>
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Total Invoices:{" "}
                <span className="fw-bold">{counts?.invoice_count}</span>
              </p>
              <p className="fs-15px text-primary fw-bold">
                {counts?.invoices
                  ? Number(counts?.invoices)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0}
              </p>
            </div>
            {/* <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Payment Received: <span className="fw-bold">{"0"}</span>
              </p>
              <p className="fs-15px fw-bold text-success">{"0"}</p>
            </div> */}
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Pending Payments:{" "}
                <span className="fw-bold">{counts?.invoice_count}</span>
              </p>
              <p className="fs-15px aqua-text fw-bold">
                {counts?.invoices
                  ? Number(counts?.invoices)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0}
              </p>
            </div>
            {/* <div className="p-2 wid-220px">
              <p className="fs-15px">
                Payment Overdues: <span className="fw-bold">{"0"}</span>
              </p>
              <p className="fs-15px fw-bold text-danger">{"0"}</p>
            </div> */}
            {/* <div className="p-2 wid-220px">
              <p className="fs-15px">
                Base Currency: <span className="fw-bold"></span>
              </p>
              <p className="fs-15px fw-bold text-info">{"INR"}</p>
            </div> */}
          </div>
          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold my-2 py-1">Deliveries</h5>
          <div className="d-md-flex flex-wrap ">
            <div className="dashbord-card-cont m-r-14px my-1 dash-btm-cards-cont  mid-sc-res py-2 d-sm-flex  flex-column flex-wrap">
              <div className="py-2">
                <div className="d-md-flex justify-content-between px-3">
                  <h5 className="fnt-fam-f1 primary1 fs-15px fw-bold">
                    Ongoing Deliveries: {OnGoing?.length}
                  </h5>
                  {user?.is_primary == "2" &&
                  user?.sm_department_id == null ? null : (
                    <button
                      class="opac_half fnt-fam-f1 fs-15px"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={navigations}
                    >
                      View All
                    </button>
                  )}
                </div>
                <div className=" d-md-flex flex-wrap gap-2 px-3">
                  <button
                    style={tabs == 0 ? style : styles}
                    onClick={(e) => setTabs(0)}
                  >
                    In Transit ({dcList?.count ? dcList?.count : 0})
                  </button>
                  <button
                    style={tabs == 1 ? style : styles}
                    onClick={(e) => setTabs(1)}
                  >
                    Delivered ({Delivered?.length})
                  </button>
                  <button
                    style={tabs == 2 ? style : styles}
                    onClick={(e) => setTabs(2)}
                  >
                    GRN */}
          {/* with OSD */}
          {/* ({GrnOst?.length})
                  </button>
                  <button
                    style={tabs == 3 ? style : styles}
                    onClick={(e) => setTabs(3)}
                  >
                    Return ({ReturnNote?.length})
                  </button>
                  {tabs == 0 ? (
                    <>
                      {" "}
                      {dcList?.data?.map((item, ind) => {
                        return (
                          <div
                            className={`${
                              dcList?.data?.length > 0 && "border-btm"
                            } my-1 px-3 py-2`}
                            key={ind}
                          >
                            <div className="d-md-flex py-2">
                              <p className="bor-thick-right wid-141px me-3">
                                DC No.: {item?.DCNNo}
                              </p>
                              <p className="wid-161px me-3">
                                DC Date:{" "}
                                {item?.DCDate
                                  ? moment(item?.DCDate).format("DD-MMM-YYYY")
                                  : ""}
                              </p> */}
          {/* <p className="bor-thick-right wid-141px me-3">
                                DC date: {item?.date}
                              </p> */}
          {/* </div> */}
          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
              Ts-501 plane type cutting m/c 25
            </h5> */}

          {/* <div className="d-md-flex gap-3 py-2">
                              <p className=" text-nowrap">
                                Material Type:{" "}
                                {item?.MaterialType == "M"
                                  ? "Material"
                                  : "Leather"}
                              </p>
                              <p className=" me-3">
                                Invoice No.: {item?.InvoiceNo}
                              </p>
                              <p className="wid-161px me-3">
                                KH Division: {item?.FactoryName}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : tabs == 1 ? (
                    <>
                      {Delivered?.map((item, ind) => {
                        return (
                          <div
                            className={`${
                              Delivered?.length > 0 && "border-btm"
                            } my-1 px-3 py-2`}
                            key={ind}
                          >
                            <div className="d-md-flex py-2">
                              <p className="bor-thick-right wid-141px me-3">
                                DC No.: {item?.DCNNo}
                              </p>
                              <p className="bor-thick-right wid-141px me-3">
                                DC date:{" "}
                                {item?.DCDate
                                  ? moment(item?.DCDate).format("DD-MMM-YYYY")
                                  : ""}
                              </p>
                              <p className="wid-141px">
                                Invoice No.: {item?.InvoiceNo}
                              </p>
                            </div> */}
          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
              Ts-501 plane type cutting m/c 25
            </h5> */}
          {/* <div className="d-md-flex py-2">
                              <p className="wid-161px me-3">
                                KH Division: {item?.FactoryName}
                              </p>
                              <p className="wid-161px me-3"> */}
          {/* Date of Dispatch:{" "} */}
          {/* MOS:{" "}
                                {item?.ShipmentModeID == 185
                                  ? "AIR Transport"
                                  : item?.ShipmentModeID == 186
                                  ? "SEA Transport"
                                  : item?.ShipmentModeID == 348
                                  ? "ROAD Transport"
                                  : ""}
                              </p>
                              <p className="wid-161px me-3">
                                Created Date:{" "}
                                {item?.CreatedDateTime
                                  ? moment(item?.CreatedDateTime).format(
                                      "DD-MMM-YYYY"
                                    )
                                  : ""}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : tabs == 2 ? (
                    <>
                      {GrnOst?.map((item, ind) => {
                        return (
                          <div className="border-btm my-1 px-3 py-2" key={ind}>
                            <div className="d-md-flex  py-2">
                              <p className="bor-thick-right wid-141px me-3">
                                GRN No.:{" "}
                                {item?.LeatherGoodsReceiptNoteNo ||
                                  item?.MaterialGoodsReceiptNoteNo}
                              </p>
                              <p className="bor-thick-right wid-155px me-3">
                                GRN Date:{" "}
                                {item?.LeatherGoodsReceiptNoteDate
                                  ? moment(
                                      item?.LeatherGoodsReceiptNoteDate
                                    ).format("DD-MMM-YYYY")
                                  : item?.MaterialGoodsReceiptNoteDate
                                  ? moment(
                                      item?.MaterialGoodsReceiptNoteDate
                                    ).format("DD-MMM-YYYY")
                                  : ""}
                              </p>
                              <p className="wid-141px">DC No.: {item?.DCNo}</p>
                              <p className="wid-141px">PO. No.: {""}</p>
                            </div> */}
          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
                  Ts-501 plane type cutting m/c 25
                </h5> */}
          {/* <div className="d-md-flex justify-content-between flex-wrap py-2">
                              <p className="wid-161px me-3 mt-3">
                                Material Type: {item?.type}
                              </p> */}
          {/* <p className="wid-142px me-3 mt-3">
                            Material group category: M
                          </p>
                          <p className="wid-141px me-3 mt-3">
                            Material sub category: NA
                          </p> */}
          {/* <p className="wid-171px me-3 mt-3">
                                KH Division: {item?.factory?.FactoryName}
                              </p>
                              <p className="wid-151px me-3 mt-3">
                                Delivered Date: {""}
                              </p>
                              <p className="wid-171px me-3 mt-3">
                                No. Of items with OSD: {""}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : tabs == 3 ? (
                    <>
                      {ReturnNote?.map((item, ind) => {
                        return (
                          <div className="border-btm my-1 px-3 py-2" key={ind}>
                            <div className="d-md-flex py-2">
                              <p className="bor-thick-right wid-141px me-3">
                                Return Note:{" "}
                                {item?.LeatherReturnToSupplierNo ||
                                  item?.MaterialReturnToSupplierNo}
                              </p>
                              <p className="bor-thick-right wid-141px me-3">
                                GRN No.: {item?.grn}
                              </p>
                              <p className="bor-thick-right wid-141px me-3">
                                DC No.: {item?.dc}
                              </p>

                              <p className="wid-141px">PO. No.: {item?.po}</p>
                            </div>

                            <div className="d-md-flex justify-content-between flex-wrap py-2">
                              <p className="wid-171px me-3 mt-3">
                                Material Type:{" "}
                                {item?.LeatherReturnToSupplierNo
                                  ? "Leather"
                                  : item?.MaterialReturnToSupplierNo
                                  ? "Material"
                                  : ""}
                              </p>
                              <p className="wid-171px me-3 mt-3">
                                Material Group Category: {""}
                              </p>
                              <p className="wid-171px me-3 mt-3">
                                Material Sub Category: {""}
                              </p>
                              <p className="wid-151px me-3 mt-3">
                                KH Division: {item?.FactoryName}
                              </p>
                              <p className="wid-161px me-3 mt-3">
                                Delivered Date: {""}
                              </p>
                              <p className="wid-151px me-3 mt-3">
                                No. Of Items Returned: {""}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null} */}

          {/* <p className="dash-card-choice-cont py-1 dash-choice-active">
                    In Transit (12)
                  </p>
                  <p className="dash-card-choice-cont py-1">Delivered</p>
                  <p className="dash-card-choice-cont py-1">GRN with OSD</p>
                  <p className="dash-card-choice-cont py-1">Return</p> */}
          {/* </div>
              </div>
            </div>
            <div className="dashbord-card-cont  my-1 dash-btm-cards-cont mid-sc-res py-2 d-sm-flex  flex-column flex-wrap">
              <div className="d-md-flex justify-content-between px-3 py-2 border-btm w-100 h-4r">
                <h5 className="fnt-fam-f1 primary1 fs-15px fw-bold">
                  Upcoming Deliveries
                </h5>
                {user?.is_primary == "2" &&
                user?.sm_department_id == null ? null : (
                  <button
                    class="opac_half fnt-fam-f1 fs-15px"
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      permission?.includes("LIST-ADVANCESHIPPINGNOTE")
                        ? navigate("/asn-listing")
                        : navigate("/dashboard")
                    }
                  >
                    View All
                  </button>
                )}
              </div>
              {UpcomingList?.map((item, ind) => {
                return (
                  <div className="border-btm w-100 my-1 px-3 py-2" key={ind}>
                    <div className="d-md-flex py-2">
                      <p className="bor-thick-right wid-141px me-3">
                        PO No.:{" "}
                        {item?.LeatherPurchaseOrderNo ||
                          item?.MaterialPurchaseOrderNo}
                      </p>
                      <p className="bor-thick-right wid-141px me-3">
                        ASN No.: {item?.ASNNo}
                      </p> */}
          {/* <p className="bor-thick-right wid-141px me-3">
                        Shipment No: {item?.shipping_count}
                      </p> */}
          {/* <p className="wid-141px me-3">
                        No of items: {item?.NoOfPackages}
                      </p> */}
        </div>
        {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">Bonded leather</h5> */}
        {/* <div className="d-md-flex py-1">
                      <p className="wid-141px me-3">
                        KH Division: {item?.FactoryName}
                      </p>
                      <p className="wid-141px me-3">
                        ETD:{" "}
                        {item?.ETD
                          ? moment(item?.ETD).format("DD-MMM-YYYY")
                          : ""}
                      </p>
                      <p className="wid-141px me-3">
                        ETA:{" "}
                        {item?.ETA
                          ? moment(item?.ETA).format("DD-MMM-YYYY")
                          : ""}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </Col>
  );
}

export default Dashboard;
