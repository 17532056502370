import { BsArrowLeft } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { api } from "../../constants/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorModel from "../../constants/Services/Api/errorModel";
function UserAccBankDetails({ setAddBooking, setPayment, id, setId }) {
  const permissions = localStorage.getItem("permissions");
  // console.log("id", id);
  const [listCountry, setCountry] = useState();
  const [listState, setState] = useState();
  const [listCity, setCity] = useState();
  const [view, setView] = useState();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [banks, setBanks] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [data, setData] = useState();

  const [currencyId, setCurrencyId] = useState("");
  const [countryId, setcountryId] = useState("");
  const [stateId, setstateId] = useState("");
  const [cityId, setcityId] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [beneficiaryName, setbeneficiaryName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [bankkey, setBankkey] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [febwireCode, setFebwireCode] = useState("");
  const [upload, setUpload] = useState([]);
  const [uploadUrl, setUploadUrl] = useState([]);
  const [ifce, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchId, setBranchId] = useState("");

  const [currencyIdErr, setCurrencyIdErr] = useState(false);
  const [countryIdErr, setcountryIdErr] = useState(false);
  const [stateIdErr, setstateIdErr] = useState(false);
  const [cityIdErr, setcityIdErr] = useState(false);
  const [bankNameErr, setBankNameErr] = useState(false);
  const [accountNumberErr, setaccountNumberErr] = useState(false);
  const [beneficiaryNameErr, setbeneficiaryNameErr] = useState(false);
  const [latitudeErr, setLatitudeErr] = useState(false);
  const [longitudeErr, setLongitudeErr] = useState(false);
  const [address1Err, setAddress1Err] = useState(false);
  const [address2Err, setAddress2Err] = useState(false);
  const [address3Err, setAddress3Err] = useState(false);
  const [bankkeyErr, setBankkeyErr] = useState(false);
  const [swiftCodeErr, setSwiftCodeErr] = useState(false);
  const [iBanNumberErr, setIBanNumberErr] = useState(false);
  const [sortCodeErr, setSortCodeErr] = useState(false);
  const [febwireCodeErr, setFebwireCodeErr] = useState(false);
  const [uploadErr, setUploadErr] = useState(false);
  const [ifceErr, setIfscErr] = useState(false);
  const [branchIdErr, setBranchIdErr] = useState(false);

  const getList = () => {
    api.country().then((res) => {
      if (res?.status == "success") {
        // console.log("res", res?.countries);
        setCountry(res?.currencies);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getBank = () => {
    api.supplierBankList().then((res) => {
      if (res?.status == "success") {
        setBanks(res?.banks);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getBranch = () => {
    let data = {
      bank_id: bankName,
    };
    api.supplierBranchList(data).then((res) => {
      if (res?.status == "success") {
        setBranchList(res?.bank_branches);
        setBranchs(res?.bank_branches);
        // setBranchs(res?.)
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const handleChange = (e) => {
    // getStateList(e.target.value);
    // getCity(e.target.value);
    setCurrencyId(e.target.value);
  };

  const getStateList = (id) => {
    api.stateList(id).then((res) => {
      // console.log("state", res);
      setState(res?.states);
    });
  };

  const getCity = (id) => {
    api.cityList(id).then((res) => {
      // console.log("city", res);
      setCity(res?.cities);
    });
  };

  const viewPayments = () => {
    api.paymentView(id).then((res) => {
      // console.log(res?.bank?.ifsc_code);
      if (res?.status == "success") {
        getBank();
        api
          .supplierBranchList({ bank_id: res?.bank?.bank_name })
          .then((resp) => {
            if (resp?.status == "success") {
              setBranchs(resp?.bank_branches);
              let filesRes = resp?.bank_branches?.filter(
                (i) => i?.BankBranchID == res?.bank?.branch_id
              );
              if (filesRes?.length > 0) {
                setData(filesRes[0]);
              } else {
                setData([]);
              }
            } else {
              setError(res);
              setErrorBox(true);
            }
          });

        setaccountNumber(res?.bank.account_no);
        setAddress1(res?.bank_detail?.SPBD_AddrLine1);
        setAddress2(res?.bank_detail?.SPBD_AddrLine2);
        setAddress3(res?.bank_detail?.SPBD_AddrLine3);
        setBankkey(res?.bank_detail?.SPBD_BankKey);
        setBankName(res?.bank?.bank_name);
        setbeneficiaryName(res?.bank?.account_holder_name);
        setCurrencyId(res?.bank_detail?.SPBD_Currency);
        setFebwireCode(res?.bank_detail?.SPBD_FebwireCode);
        setIbanNumber(res?.bank_detail?.SPBD_IBANNumber);
        setLatitude(res?.bank_detail?.SPBD_Latitude);
        setLongitude(res?.bank_detail?.SPBD_Longitude);
        setSortCode(res?.bank_detail?.SPBD_SortCode);
        setcityId(res?.bank_detail?.SPBD_SupPortCityMst_FK);
        setcountryId(res?.bank_detail?.SPBD_SupPortCountryMst_FK);
        setstateId(res?.bank_detail?.SPBD_SupPortStateMs_FK);
        setSwiftCode(res?.bank_detail?.SPBD_SwiftCode);
        setUpload(res?.bank_detail?.SPBD_Upload);
        setIfsc(res?.bank?.ifsc_code);
        setBranchName(res?.bank_detail?.branch_name);
        setBranchId(res?.bank?.branch_id);
        let temp = [];
        if (res?.bank?.confirmation_letter1) {
          temp.push(res?.bank?.confirmation_letter1);
        }
        if (res?.bank?.confirmation_letter2) {
          temp.push(res?.bank?.confirmation_letter2);
        }
        if (res?.bank?.confirmation_letter3) {
          temp.push(res?.bank?.confirmation_letter3);
        }
        setUploadUrl(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (!id) {
      getList();
      getBank();
    }
  }, []);

  useEffect(() => {
    if (id) {
      viewPayments();
    }
  }, []);

  useEffect(() => {
    if (bankName) {
      getBranch();
    }
  }, [bankName]);

  const branchData = () => {
    let temp = branchList?.filter((i) => i?.BankBranchID == branchId);

    // console.log("temp", temp);

    if (temp?.length > 0) {
      setData(temp[0]);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    if (branchId) {
      branchData();
    }
  }, [branchId]);

  const onChangeUpload = (e) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let upload_view = [];
      let uploads = [];
      temp?.map((url) => {
        upload_view.push({
          url: URL.createObjectURL(url),
          name: url?.name,
          id: "",
          type: "edit",
        });
        uploads.push(url);
      });
      setUpload(uploads);
      setUploadUrl((upload_edit) => [...upload_edit, ...upload_view]);
    }
  };

  const removeImage = (id, index) => {
    let temp = [...uploadUrl];
    let temps = [...upload];
    temp.splice(index, 1);
    temps.splice(index, 1);
    setUploadUrl(temp);
    setUpload(temps);
  };

  // console.log(upload);

  const submitHandle = () => {
    let formdata = new FormData();

    // formdata.append("currency_id", currencyId);

    formdata.append("bank_name", bankName);
    formdata.append("account_number", accountNumber);
    formdata.append("beneficiary_name", beneficiaryName);

    formdata.append("ifsc_code", data?.IFSC);
    formdata.append("branch_id", branchId);

    if (latitude) {
      // formdata.append("latitude", latitude);
    }

    if (longitude) {
      // formdata.append("longitude", longitude);
    }

    if (stateId) {
      // formdata.append("state_id", stateId);
    }

    if (countryId) {
      // formdata.append("country_id", countryId);
    }

    if (cityId) {
      // formdata.append("city_id", cityId);
    }

    if (address1) {
      // formdata.append("address_1", address1);
    }
    if (address2) {
      // formdata.append("address_2", address2);
    }

    if (address3) {
      // formdata.append("address_3", address3);
    }

    if (sortCode) {
      // formdata.append("sort_code", sortCode);
    }

    if (ibanNumber) {
      // formdata.append("i_ban_number", ibanNumber);
    }

    if (branchName) {
      // formdata.append("branch_name", branchName);
    }

    if (bankkey) {
      // formdata.append("bank_key", bankkey);
    }

    if (swiftCode) {
      // formdata.append("swift_code", swiftCode);
    }

    if (febwireCode) {
      // formdata.append("febwire_code", febwireCode);
    }

    if (id) {
      if (upload?.length > 0) {
        upload.map((item, ind) => {
          formdata.append(`document${ind + 1}`, item);
        });
      }

      formdata.append("_method", "PUT");
      api.paymentEdit(id, formdata).then((res) => {
        // console.log("res", res);
        if (res?.status == "success") {
          setAddBooking(false);
          setPayment(true);
          setId("");
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else if (!id) {
      if (upload?.length > 0) {
        upload.map((item, ind) => {
          formdata.append(`documents[${ind}]`, item);
        });
      }

      api.paymentAdd(formdata).then((res) => {
        // console.log("res", res);
        if (res?.status == "success") {
          setAddBooking(false);
          setPayment(true);
          setId("");
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
      // if (
      //   currencyId?.length == 0 ||
      //   countryId?.length == 0 ||
      //   stateId?.length == 0 ||
      //   cityId?.length == 0 ||
      //   bankName?.length == 0 ||
      //   accountNumber?.length == 0 ||
      //   beneficiaryName?.length == 0 ||
      //   latitude?.length == 0 ||
      //   longitude?.length == 0 ||
      //   upload?.length == null ||
      //   ifce?.length == 0 ||
      //   ibanNumber == 0
      // ) {
      //   setCurrencyIdErr(true);
      //   setcountryIdErr(true);
      //   setcityIdErr(true);
      //   setstateIdErr(true);
      //   setBankName(true);
      //   setaccountNumberErr(true);
      //   setbeneficiaryNameErr(true);
      //   setLatitudeErr(true);
      //   setLongitudeErr(true);
      //   setUploadErr(true);
      //   setIfscErr(true);
      //   setIBanNumberErr(true);
      // } else {
      //   api.paymentAdd(formdata).then((res) => {
      //     // console.log("res", res);
      //     if (res?.status == "success") {
      //       setAddBooking(false);
      //       setPayment(true);
      //       setId("");
      //     } else {
      //       setError(res);
      //       setErrorBox(true);
      //     }
      //   });
      // }
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  // console.log("data", data);

  return (
    <div className="acc-payment-cont">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <button
        className="cust-btn px-0 primary1"
        onClick={() => {
          setAddBooking(false);
          setPayment(true);
          setId("");
        }}
      >
        <FaArrowLeft style={{ fontSize: "18px", marginRight: "10px" }} />
        <span>{id ? "Edit" : "Add"} Bank Details</span>
      </button>
      <div className="bank-detail-inner-cont">
        {/* <Col md={6} xs={12} className="pe-3 my-2">
          <label>
            AC Currency Type<span className="red-text">*</span>
          </label>
          <select
            className="form-select "
            onChange={(e) => handleChange(e)}
            value={currencyId}
          >
            <option>Select Currency Type</option>
            {listCountry?.map((list, index) => (
              <option value={list?.currency_id}>{list?.currency_name}</option>
            ))}
            {/* <option>INR</option>
            <option>US</option>
            <option>EURO</option>  
          </select>
          {currencyId?.length == 0 && currencyIdErr && (
            <span>Select Currency</span>
          )}
        </Col> */}
        <div className="d-flex gap-3 align-items-end">
          <Col md={6} xs={12} className="pe-3 my-2">
            <label>
              Bank Name<span className="red-text">*</span>
            </label>
            <select
              className="form-select "
              onChange={(e) => setBankName(e.target.value)}
              value={bankName}
            >
              <option>Select Bank Name</option>
              {banks?.map((item, ind) => (
                <option value={item?.BankID} key={ind}>
                  {item?.BankName}
                </option>
              ))}
              {/* <option value={"ICICI Bank"}>ICICI Bank</option>
              <option value={"US"}>US</option>
              <option value={"EURO"}>EURO</option> */}
            </select>
            {bankName?.length == 0 && bankNameErr && <span>Select Bank</span>}
          </Col>
          <Col md={6} xs={12} className="pe-3 my-2">
            <label>
              Branch Name<span className="red-text">*</span>
            </label>
            <select
              className="form-select "
              onChange={(e) => setBranchId(e.target.value)}
              value={branchId}
            >
              <option>Select Branch Name</option>
              {branchs?.map((item, ind) => (
                <option value={item?.BankBranchID} key={ind}>
                  {item?.BankBranchName}
                </option>
              ))}
              {/* <option value={"ICICI Bank"}>ICICI Bank</option>
              <option value={"US"}>US</option>
              <option value={"EURO"}>EURO</option> */}
            </select>
            {branchId?.length == 0 && branchIdErr && <span>Select Bank</span>}
          </Col>
        </div>
        <Col md={6} xs={12} lg={12} className="pe-3 my-2">
          <label>
            Account Number<span className="red-text">*</span>
          </label>
          <input
            className="form-control"
            onChange={(e) => setaccountNumber(e.target.value)}
            value={accountNumber}
          />{" "}
          {accountNumber?.length == 0 && accountNumberErr && (
            <span>Enter Account Number</span>
          )}
        </Col>
        <div className="d-flex gap-3 align-items-end">
          {/* <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">Bank Key</label>
            <input
              className="form-control"
              onChange={(e) => setBankkey(e.target.value)}
              value={bankkey}
            />
            {bankkey?.length == 0 && bankkeyErr && <span>Enter Bank Key</span>}
          </Col> */}
          <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">
              Beneficiary Name <span className="red-text">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => setbeneficiaryName(e.target.value)}
              value={beneficiaryName}
            />{" "}
            {beneficiaryName?.length == 0 && beneficiaryNameErr && (
              <span>Enter Beneficiary</span>
            )}
          </Col>
          <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">BIC/Swift Code</label>
            <input
              className="form-control"
              onChange={(e) => setSwiftCode(e.target.value)}
              value={data?.SwiftCode}
              disabled
            />{" "}
            {swiftCode?.length == 0 && swiftCodeErr && (
              <span>Enter Swift code</span>
            )}
          </Col>
        </div>
        <div className="d-flex gap-3 align-items-end">
          {/* <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">BIC/Swift Code</label>
            <input
              className="form-control"
              onChange={(e) => setSwiftCode(e.target.value)}
              value={branchs?.SwiftCode}
            />{" "}
            {swiftCode?.length == 0 && swiftCodeErr && (
              <span>Enter Swift code</span>
            )}
          </Col> */}
          {/* <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">
              International Bank Account Number
            </label>
            <input
              className="form-control"
              onChange={(e) => setIbanNumber(e.target.value)}
              value={ibanNumber}
            />{" "}
            {ibanNumber?.length == 0 && iBanNumberErr && (
              <span>Enter International Bank Account Number</span>
            )}
          </Col> */}
        </div>
        <div className="d-flex gap-3 align-items-end">
          {/* <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">Sort Code</label>
            <input
              className="form-control"
              onChange={(e) => setSortCode(e.target.value)}
              value={sortCode}
            />{" "}
            {sortCode?.length == 0 && sortCodeErr && (
              <span>Enter Sort Code</span>
            )}
          </Col> */}
          {/* <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">Fedwire Code</label>
            <input
              className="form-control"
              onChange={(e) => setFebwireCode(e.target.value)}
              value={febwireCode}
            />{" "}
            {febwireCode?.length == 0 && febwireCodeErr && (
              <span>Enter Fedwire Code</span>
            )}
          </Col> */}
        </div>
        <Col md={6} xs={12} className="pe-3 my-2">
          <label className="py-2 my-1">IFSC Code</label>
          <input
            className="form-control"
            onChange={(e) => setIfsc(e.target.value)}
            value={data?.IFSC}
            disabled
          />
          {ifce?.length == 0 && ifceErr && <p>enter ifsc</p>}
        </Col>
      </div>
      {/* <div className="search-bar-cont d-flex">
        <Col
          sm={1}
          className="d-flex align-items-center justify-content-center"
        >
          <CiSearch />
        </Col>
        <Col sm={11}>
          <input
            type="search"
            id="form1"
            class="form-control search-field"
            placeholder="Search your location in Google Maps"
            aria-label="Search"
          />
        </Col>
      </div> */}
      {/* <div className="map-cont">
        <p>Map Here</p>
        <div className="d-flex gap-3 align-items-end">
          <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">
              Latitute <span className="red-text">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => setLatitude(e.target.value)}
              value={branchs?.Lat}
            />{" "}
            {latitude?.length == 0 && latitudeErr && (
              <span>Enter Latitute</span>
            )}
          </Col>
          <Col md={6} xs={12} className="pe-3 my-2">
            <label className="py-2 my-1">
              Lonititute<span className="red-text">*</span>
            </label>
            <input
              className="form-control"
              onChange={(e) => setLongitude(e.target.value)}
              value={branchs?.Long}
            />{" "}
            {longitude?.length == 0 && longitudeErr && (
              <span>Enter Lonititute</span>
            )}
          </Col>
        </div>
      </div> */}
      <Col md={12} xs={12} lg={12} className="pe-3 my-2">
        <label>Address line 1</label>
        <input
          className="form-control"
          onChange={(e) => setAddress1(e.target.value)}
          value={data?.Address1}
          disabled
        />
        {address1?.length == 0 && address1Err && <span>Enter Address 1</span>}
      </Col>
      <Col md={12} xs={12} lg={12} className="pe-3 my-2">
        <label>Address line 2</label>
        <input
          className="form-control"
          onChange={(e) => setAddress2(e.target.value)}
          value={data?.Address2}
          disabled
        />
        {address2?.length == 0 && address2Err && <span>Enter Address 2</span>}
      </Col>
      {/* <div className="d-flex justify-content-between my-3">
        <h5 className="blue-fnt"></h5>
        <button className="bg-primary1 bg-primary1 px-3 py-1 text-white rounded-1 border-0">
          Add +
        </button>
      </div> */}
      <label className="py-2 my-1">Address 3</label>
      <div className="d-flex gap-3">
        <Col md={6} xs={12} className="pe-3 my-2">
          <label>City</label>
          <input
            className="form-control"
            onChange={(e) => setAddress2(e.target.value)}
            value={data?.city?.CityName}
            disabled
          />
          {cityId?.length == 0 && cityIdErr && <span>Select City</span>}
        </Col>
        <Col md={6} xs={12} className="pe-3 my-2">
          <label>State</label>
          <input
            className="form-control"
            onChange={(e) => setAddress2(e.target.value)}
            value={data?.state?.StateName}
            disabled
          />
          {stateId?.length == 0 && stateIdErr && <span>Select State</span>}
        </Col>
      </div>
      <Col md={6} xs={12} className="pe-3 my-2 mb-4">
        <label>Country</label>
        <input
          className="form-control"
          onChange={(e) => setAddress2(e.target.value)}
          value={data?.country?.CountryName}
          disabled
        />
        {countryId?.length == 0 && countryIdErr && <span>Select Country</span>}
      </Col>

      <Col className="my-3">
        <div className="prod-inf my-2">
          <h5 className="blue-fnt">
            Bank Confirmation Letter <span className="red-text">*</span>
          </h5>
        </div>
        <Col>
          <p className="primary1"></p>
          <input
            className="custom-file-tech-bank"
            type="file"
            multiple
            onChange={onChangeUpload}
          />
          <p className="blue-fnt opac-half">
            Note: Format: jpg, jpeg, png. Upload upto 5MB each file allowed to
            upload maximum of 3 file
          </p>
        </Col>
      </Col>
      <div>
        {uploadUrl?.map((item, ind) => (
          <div className="d-flex gap-1 my-4" key={ind}>
            <div className="d-flex align-items-center pdf-icon-cont">
              <AiOutlineFilePdf />
            </div>
            <p className="d-flex align-items-center">{"Confirmation Letter"}</p>
            <TiDeleteOutline onClick={() => removeImage(ind)} />
          </div>
        ))}
      </div>
      <div className="d-flex mt-2 mb-4 justify-content-end gap-3 my-2">
        <button
          className="clear-btn  fnt-fam-f2 px-4 py-1 border-0 rounded-1 opac_half"
          style={{ color: "black" }}
        >
          Clear
        </button>
        <button
          className="bg-primary1 fnt-fam-f2 bg-primary1 px-4 py-1 text-white rounded-1 border-0"
          onClick={submitHandle}
        >
          {id ? "Edit" : "Add"}
        </button>
      </div>
    </div>
  );
}

export default UserAccBankDetails;
