import React, { useEffect, useState } from "react";
import { Col, Image, Tab, Nav, Navbar } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavMcp from "../component/NavMcp";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
import LogInFooter from "../logincomponent/LogInFooter";
import TopBar from "../navbar/TopBar";
import TopHeader from "../navbar/TopHeader";

const data = [
  {
    bulidingname: "Road",
    address: "  No24 Sipcot Industrial Complex , Vellore",
    mobile: "8987898789",
    fax: "205464",
    mail: "gade@gmail.com",
  },
  {
    bulidingname: "Rail",
    address: "  No24 Sipcot Industrial Complex , Vellore",
    mobile: "8987898789",
    fax: "205464",
    mail: "gade@gmail.com",
  },
  {
    bulidingname: "Port",
    address: "  No24 Sipcot Industrial Complex , Vellore",
    mobile: "8987898789",
    fax: "205464",
    mail: "gade@gmail.com",
  },
];
const Transport = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = localStorage.getItem("permissions");
  const factoryList = location?.state?.factory;
  const [pages, setPages] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const [TransPort, setTransPort] = useState([]);
  const [lists, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [factoriesBox, setFactoriesBox] = useState([]);
  const [factories, setFactories] = useState([]);

  // console.log(location);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const removeElement = (index) => {
    const newTransPort = TransPort.filter((_, i) => i !== index);
    setTransPort(newTransPort);
  };

  const getList = () => {
    let params;

    if (pages !== null) {
      params = `?page=${pages}`;
    } else {
      params = " ";
    }

    api.transportGet(params).then((res) => {
      if (res?.status == "success") {
        setTransPort(res?.supplier_transports);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getAddress = () => {
    api.address().then((res) => {
      if (res?.status == "success") {
        setList(res?.branches);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const delete_address = (id) => {
    setBtn(true);
    api.transportDelete(id).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        getList();
        setBtn(false);
      } else {
        setError(res);
        setErrorBox(true);
        setBtn(false);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
  };

  const changeFactoriesBox = (event, item) => {
    let temps = [...factories];
    const value = event;

    const index = temps.indexOf(event);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setFactories(temps);
    } else {
      temps.push(value);
      setFactories(temps);
    }
  };

  const changeNoPo = (event, ind) => {
    let temps = [...factoriesBox];
    const value = event;

    const index = temps.indexOf(event);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setFactoriesBox(temps);
    } else {
      temps.push(value);
      setFactoriesBox(temps);
    }

    console.log(temps);
  };

  console.log("factoriesBox", factoriesBox);

  const navigateMap = () => {
    navigate("/product/addProduct", {
      state: {
        data: location?.state?.data,
        item: location?.state?.item,
        factoriesBox: factoriesBox,
        stored: location?.state?.stored,
        type: location?.state?.type,
      },
    });
  };

  useEffect(() => {
    getAddress();
  }, []);

  useEffect(() => {
    if (pages !== null) {
      getList();
    } else {
      getList();
    }
  }, [pages]);

  return (
    <div className="register-con ">
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="transport" />
      <div className="m-2 d-flex-wrap   ">
        <Col lg={2} md={4} xs={12} className="px-2 my-2">
          <Nav variant="pills" className="flex-column nav_mctap">
            <Nav.Item>
              <Nav.Link eventKey="inform ">
                <Link to="/mcprofile" className="nav-link">
                  {" "}
                  Basic Information{" "}
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="transport" className="active">
                <Link to="/mcprofile/transport" className="nav-link">
                  {" "}
                  Nearest Transport Points{" "}
                </Link>{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="certificate">
                <Link to="/mcprofile/certificate" className="nav-link">
                  Certificate/Awards{" "}
                </Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col lg={7} md={8} xs={12} className="px-3 my-2 min-ht-80">
          <div className="tab-content">
            <div id="transport" className="tab-pane active">
              <div className="d-flex-al-jb my-3">
                <h2>Nearest Transporting Points</h2>
                {permissions?.includes("ADD-NEAREST TRANSPORT POINTS") && (
                  <button
                    className="cust-btn bg-primary1 bg-primary1 px-3 py-1 text-white rounded-1 border-0"
                    onClick={() => navigate("/mcprofile/addtransport")}
                    // to="/mcprofile/addtransport"
                  >
                    Add +
                  </button>
                )}
              </div>
              <div className="d-flex-ae-jb my-3">
                <table className="w-100">
                  <tbody>
                    {TransPort?.map((list, index) => {
                      let transport = lists?.filter(
                        (i) => i?.id == list?.RefSupplierBranchTyeID
                      );
                      return (
                        <tr key={index}>
                          <td className="d-grid">
                            <th className="d-flex">
                              {factoryList ? (
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    changeFactoriesBox(list?.id, list);
                                    changeNoPo(list, index);
                                  }}
                                  checked={factories?.includes(list?.id)}
                                />
                              ) : null}
                              <p className={factoryList ? "mx-2" : ""}>
                                {transport[0]?.name}{" "}
                              </p>
                            </th>
                            <td>Address: {list?.address1}</td>
                            <td>
                              <span>
                                Telephone:{" "}
                                {list?.telephone ? list?.telephone : "NA"}
                              </span>
                              <span>Fax: {list?.fax ? list?.fax : "NA"}</span>
                              <span>
                                Mail: {list?.email ? list?.email : "NA"}
                              </span>
                            </td>
                          </td>
                          {permissions?.includes(
                            "EDIT-NEAREST TRANSPORT POINTS"
                          ) && (
                            <td>
                              <button
                                className="rounded-5 border-0 p-2 bg-hash "
                                onClick={() =>
                                  navigate("/mcprofile/addtransport", {
                                    state: {
                                      type: "edit",
                                      id: list?.id,
                                    },
                                  })
                                }
                              >
                                <TbEdit />
                              </button>
                            </td>
                          )}
                          {permissions?.includes(
                            "DELETE-NEAREST TRANSPORT POINTS"
                          ) && (
                            <td>
                              <button
                                className="rounded-5 border-0 p-2 bg-hash"
                                onClick={() => delete_address(list?.id)}
                                disabled={btn ? true : false}
                              >
                                <RiDeleteBinLine />
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {factoriesBox?.length > 0 ? (
                  <div>
                    <button
                      className="bg-black text-white rounded border 0 px-2 py-1"
                      onClick={navigateMap}
                    >
                      Submit To Map Factory
                    </button>
                  </div>
                ) : null}

                <div
                  className="page-inner-cont py-2 "
                  style={{ marginLeft: "auto" }}
                >
                  <ReactPaginate
                    nextLabel="›"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="‹"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
              {/* <div div className="pe-3 my-5 d-flex-al-je ben_design">
                <Link to="/dashboard" className="btn brn-can">
                  Cancel
                </Link>
                <Link to="#" className="btn bode-btn fnt-fam-f2 ">
                  Save as Draft
                </Link>
                <Link className=" btn sub-btn fnt-fam-f2">Save & Next</Link>
              </div> */}
            </div>
          </div>
        </Col>
      </div>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};
export default Transport;
