import { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Col } from "react-bootstrap";

import Search from "../../assets/Icons/Normal Icons/Search.svg";

function BroadCastDropDown() {
  const [showDropDown, setShowDropDown] = useState(false);

  const dropDownClickHandler = () => setShowDropDown(!showDropDown);
  const options = [
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
    { label: "Thing 1", value: 1 },
    { label: "Thing 2", value: 2 },
  ];

  const dropDownSearchHandler = (e) => console.log(e.target.value);

  const dropdownList = options.map((item, index) => {
    return (
      <li key={index} className="mx-2 my-1 p-1 fs-6  d-flex gap-3">
        <input type="checkbox" /> {item.value}
      </li>
    );
  });

  return (
    <>
      <div
        className="d-flex justify-content-between notifi-detail-header-cont"
        onClick={dropDownClickHandler}
      >
        <p>sdfsdfs</p>
        <RiArrowDropDownLine />
      </div>
      {showDropDown && (
        <div className="broadcast-multi-sel-dropdown">
          {" "}
          <div className="search-bar-cont d-flex align-items-center m-2 px-2">
            <Col sm={11}>
              <input
                type="search"
                id="form1"
                className="form-control search-field"
                onChange={dropDownSearchHandler}
                placeholder="Search Notification"
                aria-label="Search"
              />
            </Col>
            <Col sm={1}>
              <img className="search-icon" src={Search} />
            </Col>
          </div>
          {dropdownList}
        </div>
      )}
    </>
  );
}

export default BroadCastDropDown;
