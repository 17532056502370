import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { usersType } from "../../layout/dummyData";
import RightAddUser from "../EnterpriseAdmin/RightAddUser";
import RightAddUserCopy from "../EnterpriseAdmin/RightAddUser copy";
import ChangeAdminMapUser from "./ChangeAdminMapUser";

const SelectUser = () => {
  const [activeBtn, setActiveBtn] = useState(0);
  console.log(activeBtn);
  return (
    <Col className=" rit-add-user ps-3">
      <Link className="d-none d-lg-block link-add-user mt-2 me-1 px-3 px-lg-0 mb-3">
        <FaArrowLeft />
        Select User
      </Link>
      <Col className="order-capacity">
        <div className="d-flex align-items-center mx-3 mx-lg-0 mt-2 mb-3 mt-lg-0">
          {usersType?.map((item, index) => {
            return (
              <button
                onClick={() => setActiveBtn(index)}
                className="d-flex align-items-center border-0 bg-transparent p-0 me-4"
              >
                <div className="radio-btn">
                  {/* <div
                    className="radio-btn-inner"
                    style={{
                      background: activeBtn == index ? "#247395" : "#fff",
                    }}
                  ></div> */}
                  {activeBtn == index ? (
                    <img src={Radio_icon} className="wid-15px" />
                  ) : (
                    <div
                      className="radio-btn-inner"
                      style={{
                        background: "#fff",
                      }}
                    ></div>
                  )}
                </div>
                <p className="me-1" style={{ fontSize: "14px" }}>
                  {item?.btn}
                </p>
              </button>
            );
          })}
        </div>
        {activeBtn == 0 && (
          <div>
            <ChangeAdminMapUser />
          </div>
        )}
        {activeBtn == 1 && (
          <div>
            <RightAddUserCopy routeType="select user" />
          </div>
        )}
      </Col>
    </Col>
  );
};

export default SelectUser;
