// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { shippmentCate } from "../../layout/dummyData";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineFilePdf } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

const RightDc = ({
  setShowFilter,
  data,
  asnCreates,
  shipments,
  asnTable,
  shipmentId,
  list,
  location,
}) => {
  const navigate = useNavigate();
  const types = location?.state?.type;
  const TransportedBy = location?.state?.TransportedBy;
  // console.log(types);
  const dcData = location?.state?.dcData;
  const [catIndex, setCatIndex] = useState({}); // CHECK BOX
  const [catIn, setCatIn] = useState([+shipmentId]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [certificate, setCertificate] = useState({});
  const [imageUrl, setimageUrl] = useState([]);
  const [showList, setShowList] = useState(0);
  const [etd, setEtd] = useState({});
  const [eta, setEta] = useState({});
  const [contactPerson, setContactPerson] = useState({});
  const [contactNo, setContactNo] = useState({});
  const [contactEmail, setContactEmail] = useState({});
  const [trackerNo, setTrakerNo] = useState({});
  const [remarks, setRemarks] = useState({});
  const [portList, setPortList] = useState([]);
  const [stages, setStages] = useState([0]); // Map Stages
  const [counts, setCounts] = useState(1);
  const [startPoint, setStartPoint] = useState({});
  const [endPoint, setEndPoint] = useState({});
  const [companyName, setCompanyName] = useState({});
  const [wayBil, setWayBill] = useState({});

  // const checkBox = (e) => {
  //   setCatIndex(e);
  // };

  const checkBoxs = (id, ind, index) => {
    let temp = { ...catIndex };

    temp[index] = id;

    setCatIndex(temp);

    // let temp = [...catIndex];
    // const indexs = temp.indexOf(id);
    // // console.log("indexs", indexs);
    // // if (temp.includes(`${index}_${ind}`)) {
    // //   temp.splice(temp.indexOf(`${index}_${ind}`), 1);
    // // } else {
    // //   temp.push(`${index}_${ind}`);
    // // }
    // const wholeIndex = `${index}_${ind}`;
    // const firstIndex = index;
    // const tmpInd = temp.filter((el) => el[index][0] == id);
    // console.log("tmpInd", tmpInd);
    // if (tmpInd.length == 0) {
    //   temp.push({ [index]: [id, wholeIndex] });
    // } else {
    //   temp.splice(indexs, 1);
    // }
    // setCatIndex(temp);
    // const value = id;
    // setCatIndex((pre) => ({ ...pre, [`${index}${ind}`]: value }));
    // let temps = [];
    // const value = event;
    // const indexs = temps.indexOf(event);
    // if (temps.includes(value)) {
    //   temps.splice(indexs, 1);
    //   setCatIndex(temps);
    // } else {
    //   temps.push((val) => ({ ...val, [`${ind}${index}`]: value }));
    //   setCatIndex(temps);
    // }
  };

  // console.log("catIndex", catIndex);

  // useEffect(() => {
  //   let formData = new FormData();
  //   const objKeys = Object.keys(catIndex);
  //   objKeys.forEach((el) => {
  //     formData.append(`shipment[${el}]`, catIndex[el]);
  //   });
  //   for (var key of formData.entries()) {
  //     console.log(key[0] + ", " + key[1]);
  //   }
  // }, [catIndex]);

  // console.log("certificate", certificate);
  // console.log("location_rd", dcData);

  // useEffect(() => {
  //   // TAX
  //   let tax = [];
  //   let total_amount = [];

  //   selTable?.map((item, ind) => {
  //     tax.push({
  //       TaxPatternID: item?.full_order_detail?.tax
  //         ? +item?.full_order_detail?.tax?.TaxPatternID
  //         : 0,
  //       TaxPatternName: item?.full_order_detail?.tax
  //         ? item?.full_order_detail?.tax?.TaxPatternName
  //         : "No Tax",
  //       TaxPercentage: item?.full_order_detail?.tax
  //         ? item?.full_order_detail?.tax?.TaxPercentage
  //         : 0,
  //       PurchaseOrderDetailID:
  //         item?.full_order_detail?.MaterialPurchaseOrderDetailID ||
  //         item?.full_order_detail?.LeatherPurchaseOrderDetailID,
  //       TaxAmount:
  //         +item?.detail_delivery_details *
  //         +item?.full_order_detail?.Price *
  //         item?.full_order_detail?.tax
  //           ? item?.full_order_detail?.tax?.TaxPercentage
  //           : 0 / 100,
  //       ReceivedQuantity: +item?.detail_delivery_details?.ReceivedQuantity,
  //       Price: +item?.full_order_detail?.Price,
  //     });
  //   });

  //   tax.map((item, ind) => {
  //     let current_qty = Object.values(noName);
  //   });

  //   console.log("tax1", tax);
  // }, [selTable]);

  const getPort = () => {
    api.dcStagesPort().then((res) => {
      if (res?.status == "success") {
        setPortList(res?.port_list);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const showListHandler = (ind) => {
    if (ind == showList) {
      setShowList();
    } else {
      setShowList(ind);
    }
    // setShowList(!showList);
  };

  // console.log("asnTable", asnTable);
  // console.log("showList", showList);

  const addStages = () => {
    let count = 0;
    let temp = [...stages];

    temp.push(count + 1);

    setStages(temp);
  };

  // console.log("list", list);

  // HANDLE CHANGES

  const checkBox = (e, ind) => {
    const value = e;
    setCatIndex((val) => ({ ...val, [ind]: value }));
  };

  const handleEtd = (date, ind) => {
    const value = date;
    setEtd((val) => ({ ...val, [ind]: new Date(value) }));
  };

  const handleEta = (date, ind) => {
    const value = date;
    setEta((val) => ({ ...val, [ind]: new Date(value) }));
  };

  const handleStartPoint = (e, ind) => {
    const value = e.target.value;
    setStartPoint((val) => ({ ...val, [ind]: value }));
  };

  const handleEndPoint = (e, ind) => {
    const value = e.target.value;
    setEndPoint((val) => ({ ...val, [ind]: value }));
  };

  const handleWayBill = (e, ind) => {
    const value = e.target.value;
    setWayBill((val) => ({ ...val, [ind]: value }));
  };

  const handleTarkeNo = (e, ind) => {
    const value = e.target.value;
    setTrakerNo((val) => ({ ...val, [ind]: value }));
  };

  const handleCompanyName = (e, ind) => {
    const value = e.target.value;
    setCompanyName((val) => ({ ...val, [ind]: value }));
  };

  const handleContactPerson = (e, ind) => {
    const value = e.target.value;
    setContactPerson((val) => ({ ...val, [ind]: value }));
  };

  const handleContactNo = (e, ind) => {
    const value = e.target.value;
    setContactNo((val) => ({ ...val, [ind]: value }));
  };

  const handleContactEmail = (e, ind) => {
    const value = e.target.value;
    setContactEmail((val) => ({ ...val, [ind]: value }));
  };

  const handleRemarks = (e, ind) => {
    const value = e.target.value;
    setRemarks((val) => ({ ...val, [ind]: value }));
  };

  // console.log("dcData", dcData);

  const submitHandle = () => {
    let formdata = new FormData();

    let etdData = Object.values(etd);
    let etaData = Object.values(eta);
    let catIndexData = Object.values(catIndex);
    let contactPersonData = Object.values(contactPerson);
    let contactNoData = Object.values(contactNo);
    let contactEmailData = Object.values(contactEmail);
    let trackerNoData = Object.values(trackerNo);
    let remarksData = Object.values(remarks);
    let endPointData = Object.values(endPoint);
    let startPointData = Object.values(startPoint);
    let wayBilData = Object.values(wayBil);
    let companyNameData = Object.values(companyName);

    etdData?.map((item, index) => {
      formdata.append(
        `dc_stages[${index}][etd]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    etaData?.map((item, index) => {
      formdata.append(
        `dc_stages[${index}][eta]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    catIndexData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][shipment_mode_id]`, item);
    });

    startPointData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][port_dispatch_id]`, item);
    });

    endPointData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][port_arrival_id]`, item);
    });

    contactPersonData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][contact_person]`, item);
    });

    contactNoData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][contact_no]`, item);
    });

    contactEmailData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][contact_email]`, item);
    });

    trackerNoData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][tracker_no]`, item);
    });

    remarksData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][remark]`, item);
    });

    wayBilData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][way_bill_ref_no]`, item);
    });

    companyNameData?.map((item, index) => {
      formdata.append(`dc_stages[${index}][transport_company]`, item);
    });

    formdata.append("factory_id", list?.FactoryID);
    formdata.append("year_id", list?.YearID);

    if (TransportedBy == "sp") {
      Object.values(certificate).map((item, index) => {
        item?.map((document, ind) => {
          if (document?.name) {
            formdata.append(
              `dc_stages[${index}][documents][${ind}][id]`,
              document?.id ? document?.id : " "
            );
            formdata.append(
              `dc_stages[${index}][documents][${ind}][title]`,
              document?.name
            );
            formdata.append(
              `dc_stages[${index}][documents][${ind}][document]`,
              document
            );
          }
        });
      });
    } else {
      Object.values(certificate).map((item, index) => {
        item?.map((document, ind) => {
          formdata.append(
            `dc_stages[${index}][documents][${ind}][id]`,
            document?.id ? document?.id : ""
          );
          formdata.append(
            `dc_stages[${index}][documents][${ind}][title]`,
            document?.name
          );
          formdata.append(
            `dc_stages[${index}][documents][${ind}][document]`,
            document
          );
        });
      });
    }

    if (TransportedBy == "sp") {
      // formdata.append("contact_person", contactPersonData[0]);
      // formdata.append("contact_no", contactNoData[0]);
      // formdata.append("contact_email", contactEmailData[0]);
      // formdata.append("tracker_no", trackerNoData[0]);
      // formdata.append("remark", remarksData[0]);
      // formdata.append("etd", etdData[0]);
      // formdata.append("eta", etaData[0]);
      // formdata.append("shipment_mode_id", shipmentId);
      // formdata.append("port_dispatch_id", "1");
      formdata.append("factory_id", dcData?.FactoryID);
      formdata.append("type", dcData?.MaterialType);
      // formdata.append("leg_detail_id", dcData?.order_detail_id);

      list?.material_a_s_n_forwarder?.material_a_s_n_forwarder_leg_details?.map(
        (item, ind) => {
          formdata.append(
            `dc_stages[${ind}][leg_detail_id]`,
            item?.MaterialASNForwarderLegDetailID
          );
        }
      );

      list?.leather_a_s_n_forwarder?.leather_a_s_n_forwarder_leg_details?.map(
        (item, ind) => {
          formdata.append(
            `dc_stages[${ind}][leg_detail_id]`,
            item?.LeatherASNForwarderLegDetailID
          );
        }
      );

      // formdata.append("port_arrival_id", endPointData[0]);
      // formdata.append("port_dispatch_id", startPointData[0]);
      // formdata.append("way_bill_ref_no", wayBilData[0]);
      // formdata.append("transport_company", companyNameData[0]);
      setBtn(true);
      api.dcUpdateStagesMany(formdata).then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/dc-list");
          }, 1500);
        } else {
          setBtn(false);
          setError(res);
          setErrorBox(true);
        }
      });
    } else {
      formdata.append("factory_id", data?.FactoryID || data[0]?.FactoryID);
      formdata.append("year_id", asnTable[0]?.YearID);
      formdata.append("type", data?.type || data[0]?.type);
      formdata.append("asn_forwarder_id", asnCreates);

      // formdata.append("etd", etdData[0]);
      // formdata.append("eta", etaData[0]);
      // formdata.append("shipment_mode_id", shipmentId);
      // formdata.append("shipment_mode_id", catIndexData[0]);
      // formdata.append("port_dispatch_id", "1");
      // formdata.append("contact_person", contactPersonData[0]);
      // formdata.append("contact_no", contactNoData[0]);
      // formdata.append("contact_email", contactEmailData[0]);
      // formdata.append("tracker_no", trackerNoData[0]);
      // formdata.append("remark", remarksData[0]);

      // formdata.append("port_arrival_id", endPointData[0]);
      // formdata.append("port_dispatch_id", startPointData[0]);
      // formdata.append("way_bill_ref_no", wayBilData[0]);
      // formdata.append("transport_company", companyNameData[0]);

      setBtn(true);
      api.dcCreateStagesMany(formdata).then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/dc-list");
          }, 1500);
        } else {
          setBtn(false);
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  const getView = () => {
    let temp = [];

    let len;
    if (list?.material_a_s_n_forwarder?.material_a_s_n_forwarder_leg_details) {
      len =
        list?.material_a_s_n_forwarder?.material_a_s_n_forwarder_leg_details
          ?.length;
    } else {
      len =
        list?.leather_a_s_n_forwarder?.leather_a_s_n_forwarder_leg_details
          ?.length;
    }

    for (let i = 0; i < len; i++) {
      temp.push(i);
    }

    //  MATERIAL
    list?.material_a_s_n_forwarder?.material_a_s_n_forwarder_leg_details?.map(
      (item, ind) => {
        if (item?.ETA) {
          setEta((val) => ({
            ...val,
            [ind]: new Date(item?.ETA),
          }));
        }

        if (item?.ETD) {
          setEtd((val) => ({
            ...val,
            [ind]: new Date(item?.ETD),
          }));
        }

        let temp = [];

        item?.material_a_s_n_forwarder_leg_detail_docs?.map((docs, docIndx) => {
          let obj = {
            document_url: docs?.document_url,
            id: docs?.MaterialASNForwarderLegDetailDocID,
            AttachmentTitle: docs?.AttachmentTitle,
          };

          temp.push(obj);

          setCertificate((val) => ({ ...val, [ind]: temp }));
        });

        setTrakerNo((val) => ({ ...val, [ind]: item?.TrackerNO }));
        setContactPerson((val) => ({ ...val, [ind]: item?.ContactPerson }));
        setContactEmail((val) => ({ ...val, [ind]: item?.ContactEmail }));
        setContactNo((val) => ({ ...val, [ind]: item?.ContactNo }));
        setRemarks((val) => ({ ...val, [ind]: item?.Remark }));
        setCatIn((val) => ({ ...val, [ind]: item?.ShipmentModeID }));
        setCatIndex((val) => ({ ...val, [ind]: item?.ShipmentModeID }));
        setWayBill((val) => ({ ...val, [ind]: item?.WayBillRefNo }));
        setCompanyName((val) => ({
          ...val,
          [ind]: item?.TransportCompanyName,
        }));
        setStartPoint((val) => ({ ...val, [ind]: item?.PortOfArrivalID }));
        setEndPoint((val) => ({ ...val, [ind]: item?.PortOfDispachID }));
      }
    );

    // LEATHER
    list?.leather_a_s_n_forwarder?.leather_a_s_n_forwarder_leg_details?.map(
      (item, ind) => {
        if (item?.ETA) {
          setEta((val) => ({
            ...val,
            [ind]: new Date(item?.ETA),
          }));
        }

        if (item?.ETD) {
          setEtd((val) => ({
            ...val,
            [ind]: new Date(item?.ETD),
          }));
        }

        let temp = [];

        item?.leather_a_s_n_forwarder_leg_detail_docs?.map((docs, docIndx) => {
          let obj = {
            document_url: docs?.document_url,
            id: docs?.MaterialASNForwarderLegDetailDocID,
            AttachmentTitle: docs?.AttachmentTitle,
          };

          temp.push(obj);

          setCertificate((val) => ({ ...val, [ind]: temp }));
        });

        setTrakerNo((val) => ({ ...val, [ind]: item?.TrackerNO }));
        setContactPerson((val) => ({ ...val, [ind]: item?.ContactPerson }));
        setContactEmail((val) => ({ ...val, [ind]: item?.ContactEmail }));
        setContactNo((val) => ({ ...val, [ind]: item?.ContactNo }));
        setRemarks((val) => ({ ...val, [ind]: item?.Remark }));
        setCatIn((val) => ({ ...val, [ind]: item?.ShipmentModeID }));
        setCatIndex((val) => ({ ...val, [ind]: item?.ShipmentModeID }));
        setWayBill((val) => ({ ...val, [ind]: item?.WayBillRefNo }));
        setCompanyName((val) => ({
          ...val,
          [ind]: item?.TransportCompanyName,
        }));
        setStartPoint((val) => ({ ...val, [ind]: item?.PortOfArrivalID }));
        setEndPoint((val) => ({ ...val, [ind]: item?.PortOfDispachID }));
      }
    );

    // list?.leather_a_s_n_forwarder?.leather_a_s_n_forwarder_leg_details?.map(
    //   (item, ind) => {
    //     setEta(moment(item?.ETA).format("YYYY-MM-DD"));
    //     setEta(moment(item?.ETD).format("YYYY-MM-DD"));
    //     setTrakerNo(item?.TrackerNO);
    //     setContactPerson(item?.ContactPerson);
    //     setContactEmail(item?.ContactEmail);
    //     setContactNo(item?.ContactNo);
    //     setRemarks(item?.Remark);
    //     setCatIn(item?.ShipmentModeID);
    //     setCatIndex(item?.ShipmentModeID);
    //   }
    // );

    setStages(temp);
  };

  useEffect(() => {
    if (types !== "add") {
      getView();
    }
    getPort();
  }, []);

  const onChangeUpload = (e, index) => {
    let max_length = 3;
    if (Array.from(e.target.files).length > max_length) {
      e.preventDefault();
      alert(`Cannot upload files more than ${max_length}`);
    } else {
      let temp = [...e.target.files];
      let upload_view = [];
      let upload = [];
      temp?.map((url) => {
        upload_view.push({
          url: URL.createObjectURL(url),
          name: url?.name,
          id: "",
          type: "edit",
        });
        upload.push(url);
      });

      if (certificate[index]) {
        // FOR UPDATE
        let obj = certificate[index];
        obj.push(...e.target.files);
        setCertificate((val) => ({ ...val, [index]: obj }));
      } else {
        // FOR CREATE
        setCertificate((val) => ({ ...val, [index]: temp }));
      }

      setimageUrl((upload_edit) => [...upload_edit, ...upload_view]);
    }
  };

  const removeCertificate = (index, ind, docs) => {
    if (docs?.id) {
    }
    let temp = [...certificate[index]];
    temp.splice(ind, 1);
    setCertificate((val) => ({ ...val, [index]: temp }));
  };

  // console.log("stages", stages);
  // console.log("count", counts);

  // console.log("wayBil", wayBil);
  console.log("certificate", certificate);

  return (
    <>
      <div className="mode-shippmet cont-border">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        {stages?.map((item, index) => {
          return (
            <>
              <h4 className="stage-1" onClick={() => showListHandler(index)}>
                Stage {index + 1} <MdOutlineKeyboardArrowDown />
              </h4>
              {showList == index && (
                <div className="p-2">
                  <div className="px-2 mode-shippmet-inner">
                    <h5>Mode of shippment</h5>
                    <div className="d-flex flex-wrap px-1">
                      {shipments?.map((item, ind) => {
                        return (
                          <>
                            <div className="stages-btn">
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  // value={catIndex[index] == item?.id}
                                  checked={catIndex[index] == item?.id}
                                  onChange={(e) => checkBox(item?.id, index)}
                                  disabled={
                                    types == "view"
                                      ? true
                                      : types == "edit"
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  {item?.name}
                                </label>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="w-100 d-sm-flex flex-wrap input-box-border2">
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>Planned Start Date</label>
                        <Datepicker
                          selected={eta[index]}
                          onChange={(date) => handleEta(date, index)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Planned Start Date"
                          todayButton="Today"
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>Start Point</label>
                        <select
                          onChange={(e) => handleStartPoint(e, index)}
                          value={startPoint[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        >
                          <option hidden>Select Start Point</option>
                          {portList?.map((item, ind) => (
                            <option value={item?.PortOfOriginID} key={ind}>
                              {item?.PortofOriginName}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>Planned End Date</label>
                        <Datepicker
                          selected={etd[index]}
                          onChange={(date) => handleEtd(date, index)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Planned End Date"
                          todayButton="Today"
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>End Point</label>
                        <select
                          onChange={(e) => handleEndPoint(e, index)}
                          value={endPoint[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        >
                          <option hidden>Select End Point</option>
                          {portList?.map((item, ind) => (
                            <option value={item?.PortOfOriginID} key={ind}>
                              {item?.PortofOriginName}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>WayBill Ref. No</label>
                        <input
                          type="number"
                          placeholder="WayBill Ref. No: XXXXXXXXXXX"
                          onChange={(e) => handleWayBill(e, index)}
                          value={wayBil[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>Tracker ID</label>
                        <input
                          type="number"
                          placeholder="Tracker ID"
                          onChange={(e) => handleTarkeNo(e, index)}
                          value={trackerNo[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <div className="w-100 px-1 my-1 position-relative">
                        <label>Transport Company Name</label>
                        <input
                          type="text"
                          placeholder="Transport Company Name"
                          onChange={(e) => handleCompanyName(e, index)}
                          value={companyName[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="w-100 px-1 my-1 position-relative">
                        <label>Contact Person Name</label>
                        <input
                          type="text"
                          placeholder="Contact Person Name"
                          onChange={(e) => handleContactPerson(e, index)}
                          value={contactPerson[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </div>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>Contact Mobile No</label>
                        <input
                          type="number"
                          placeholder="Mobile"
                          onChange={(e) => handleContactNo(e, index)}
                          value={contactNo[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className="px-1 my-1 position-relative"
                      >
                        <label>Email</label>
                        <input
                          type="text"
                          placeholder="Email"
                          onChange={(e) => handleContactEmail(e, index)}
                          value={contactEmail[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <div className="w-100 px-1 my-1">
                        <label>Any Comments</label>
                        <textarea
                          style={{ height: "60px" }}
                          placeholder="Any Comments"
                          onChange={(e) => handleRemarks(e, index)}
                          value={remarks[index]}
                          disabled={
                            types == "view"
                              ? true
                              : types == "edit"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                    <Col
                      className="rit-ad-prod_new"
                      xs={12}
                      style={{ paddingLeft: "3px" }}
                    >
                      <h4 className="relat-doc">Related Documents</h4>
                      {/* <p className="primary1">{certificate}</p> */}
                      <input
                        className="custom-file-dc-input"
                        onChange={(e) => onChangeUpload(e, index)}
                        type="file"
                        multiple
                      />
                      <p className="note py-1 my-1">
                        Note: Maximum attachments 3, file jpg, jpeg, pdf.
                      </p>
                      {Object.values(certificate)?.length > 0 &&
                        certificate?.[index]?.map((docs, ind) => {
                          return (
                            <div className="d-flex gap-1 my-4" key={ind}>
                              <div className="d-flex align-items-center pdf-icon-cont">
                                <AiOutlineFilePdf />
                              </div>
                              <p className="d-flex align-items-center">
                                {"document"}
                              </p>
                              <TiDeleteOutline
                                onClick={() =>
                                  removeCertificate(index, ind, docs)
                                }
                              />
                            </div>
                          );
                        })}
                    </Col>
                    {types == "view" ? null : types ==
                      "edit" ? null : TransportedBy == "sp" ? null : (
                      <div className="d-flex justify-content-end">
                        {/* <button className="cust-btn bg-primary2 text-white me-2">
                        Clear
                      </button> */}

                        <button
                          className="cust-btn bg-primary1 text-white me-2"
                          onClick={() => showListHandler(index)}
                        >
                          Save
                        </button>
                      </div>
                    )}

                    <div style={{ height: "70px" }} />
                  </div>
                </div>
              )}
            </>
          );
        })}

        {/* <h4 className="stage-1" onClick={() => showListHandler(1)}>
        Stage 1 <MdOutlineKeyboardArrowDown />
      </h4> */}

        {/* {showList == 1 ? (
        <div className="p-2">
          <div className="px-2 mode-shippmet-inner">
            <h5>Mode of shippment</h5>
            <div className="d-flex flex-wrap px-1">
              {shipments?.map((item, ind) => {
                return (
                  <>
                    {/* <button
                      key={ind}
                      onClick={() => {
                        checkBox(ind);
                      }}
                      className="d-flex align-items-center mt-1 border-0 bg-transparent me-5"
                    >
                      <div
                        className={
                          catIndex == ind
                            ? "check-Btn bg-primary1"
                            : "check-Btn"
                        }
                      >
                        {catIndex == ind && <FaCheck />}
                      </div>
                      <p className="mb-0">{item?.name}</p>
                    </button> 
                    <div className="stages-btn">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                          onChange={(e) => checkBox(item?.id)}
                          // checked={catIn?.includes(item?.id)}
                          checked={catIndex?.includes(item?.id)}
                        />
                        <label class="form-check-label" for="inlineCheckbox1">
                          {item?.name}
                        </label>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="w-100 d-sm-flex flex-wrap input-box-border2">
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                {/* <p className="dc-date-cont-text">Planned start Date</p> 
                <input
                  type="date"
                  type="date"
                  onChange={(e) => setEta(e.target.value)}
                  value={eta}
                />
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                {/* <p className="dc-date-cont-text">Start Point</p> 
                <select
                  onChange={(e) => setStartPoint(e.target.value)}
                  value={startPoint}
                >
                  <option>select start point</option>
                  {portList?.map((item, ind) => (
                    <option value={item?.PortOfOriginID} key={ind}>
                      {item?.PortofOriginName}
                    </option>
                  ))}
                </select>
                {/* <input type="text" placeholder="Start Point" /> 
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                {/* <p className="dc-date-cont-text">Planned End Date</p> 
                <input
                  type="date"
                  onChange={(e) => setEtd(e.target.value)}
                  value={etd}
                />
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                {/* <input type="text" placeholder="End Point" /> 
                <select
                  onChange={(e) => setEndPoint(e.target.value)}
                  value={endPoint}
                >
                  <option>select end point</option>
                  {portList?.map((item, ind) => (
                    <option value={item?.PortOfOriginID} key={ind}>
                      {item?.PortofOriginName}
                    </option>
                  ))}
                </select>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                <input
                  type="text"
                  placeholder="WayBill Ref. No: XXXXXXXXXXX"
                  onChange={(e) => setWayBill(e.target.value)}
                  value={wayBil}
                />
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                <input
                  type="text"
                  placeholder="Tracker ID"
                  onChange={(e) => setTrakerNo(e.target.value)}
                  value={trackerNo}
                />
              </Col>
              <div className="w-100 px-1 my-1 position-relative">
                <input
                  type="text"
                  placeholder="Transport Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                />
              </div>
              <div className="w-100 px-1 my-1 position-relative">
                <input
                  type="text"
                  placeholder="Contact Person Name"
                  onChange={(e) => setContactPerson(e.target.value)}
                  value={contactPerson}
                />
              </div>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                <input
                  type="text"
                  placeholder="Mobile"
                  onChange={(e) => setContactNo(e.target.value)}
                  value={contactNo}
                />
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="px-1 my-1 position-relative"
              >
                <input
                  type="text"
                  placeholder="Email"
                  onChange={(e) => setContactEmail(e.target.value)}
                  value={contactEmail}
                />
              </Col>
              <div className="w-100 px-1 my-1">
                <textarea
                  style={{ height: "60px" }}
                  placeholder="Any Comments"
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                />
              </div>
            </div>
            {/* <Col className="rit-ad-prod" xs={12} style={{ paddingLeft: "3px" }}>
            <h4 className="relat-doc">Related Documents</h4>
            <p className="primary1">{certificate}</p>
            <input
              className="custom-file-dc-input"
              onChange={(e) => setCertificate(e.target.value)}
              type="file"
            />
            <h5 className="note py-1 my-1">
              Note: Maximum attachments 3, file jpg, jpeg, pdf.
            </h5>
          </Col> 
            <div className="d-flex justify-content-end">
              <button className="cust-btn bg-primary2 text-white me-2">
                Clear
              </button>
              <button
                className="cust-btn bg-primary1 text-white me-2"
                onClick={submitHandle}
              >
                Save
              </button>
            </div>
            <div style={{ height: "70px" }} />
          </div>
          {/* <div className="dc-rs-btm-drop-cont my-2 px-2">
          <span>Stage</span> <BsChevronDown />
        </div>
        <div className="dc-rs-btm-drop-cont my-2 px-2">
          <span>Stage</span> <BsChevronDown />
        </div>
        <div className="dc-rs-btm-drop-cont my-2 px-2">
          <span>Stage</span> <BsChevronDown />
        </div> 
          <div className="d-flex justify-content-between">
            <button className="cust-btn bg-dark text-white" onClick={addStages}>
              Stage
            </button>
            <button className="cust-btn bg-primary1 text-white me-2">
              Submit
            </button>
          </div>
        </div>
      ) : null} */}
      </div>
      {types == "view" ? null : (
        <div
          className={
            types == "edit"
              ? "d-flex justify-content-end mt-2 mx-2"
              : TransportedBy == "sp"
              ? "d-flex justify-content-end mt-2 mx-2"
              : "d-flex justify-content-between mt-2"
          }
        >
          {types == "edit" ? null : TransportedBy == "sp" ? null : (
            <button className="cust-btn bg-dark text-white" onClick={addStages}>
              Add Stage
            </button>
          )}

          {types == "add" ? (
            <button
              className="cust-btn bg-primary1 text-white"
              onClick={submitHandle}
              disabled={btn ? true : false}
            >
              Submit
            </button>
          ) : TransportedBy == "sp" ? (
            list?.material_a_s_n_forwarder?.material_a_s_n_forwarder_leg_details
              ?.length > 0 ? (
              <button
                className="cust-btn bg-primary1 text-white"
                onClick={submitHandle}
                disabled={btn ? true : false}
              >
                Submit
              </button>
            ) : null
          ) : list?.leather_a_s_n_forwarder?.leather_a_s_n_forwarder_leg_details
              ?.length > 0 ? (
            <button
              className="cust-btn bg-primary1 text-white"
              onClick={submitHandle}
              disabled={btn ? true : false}
            >
              Submit
            </button>
          ) : null}
        </div>
      )}
    </>
  );
};

export default RightDc;
