import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import Mcpnodata from "./Mcpnodata";
import { api } from "../constants/Services";
import { Button } from "react-bootstrap";
import ErrorModel from "../constants/Services/Api/errorModel";
const data = [
  {
    id: 1,
    bulidingname: "Headquarters",
    address: "  No24 Sipcot Industrial Complex , Vellore",
    mobile: "8987898789",
    fax: "205464",
    mail: "gade@gmail.com",
  },
  {
    id: 2,
    bulidingname: "Office",
    address: "  No24 Sipcot Industrial Complex , Vellore",
    mobile: "8987898789",
    fax: "205464",
    mail: "gade@gmail.com",
  },
];
const McpRegister = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [RegAddress, setRegAddress] = useState([]);
  const [lists, stateList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);

  // MAP PRODUCT

  const factoryList = location?.state?.factory;
  const [factoriesBox, setFactoriesBox] = useState([]);
  const [factories, setFactories] = useState([]);

  const changeFactoriesBox = (event, item) => {
    let temps = [...factories];
    const value = event;

    const index = temps.indexOf(event);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setFactories(temps);
    } else {
      temps.push(value);
      setFactories(temps);
    }

    // console.log("changeFactoriesBox", temps);
  };

  const changeNoPo = (event, ind) => {
    let temps = [...factoriesBox];
    const value = event;

    const index = temps.indexOf(event);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setFactoriesBox(temps);
    } else {
      temps.push(value);
      setFactoriesBox(temps);
    }

    console.log("changeNoPo", temps);
  };

  const navigateMap = () => {
    navigate("/product/addProduct", {
      state: {
        data: location?.state?.data,
        item: location?.state?.item,
        factoriesBox: factoriesBox,
        stored: location?.state?.stored,
        type: location?.state?.type,
      },
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const getAddress = () => {
    api.addressList().then((res) => {
      if (res?.status == "success") {
        stateList(res?.branches);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const addressList = () => {
    api.addressGet().then((res) => {
      if (res?.status == "success") {
        setRegAddress(res?.supplier_addresses);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getAddress();
    addressList();
  }, []);

  const deleteAddress = (id) => {
    setBtn(true);
    api.addressDelete(id).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setBtn(false);
        setTimeout(() => {
          addressList();
        }, 800);
      } else {
        setError(res);
        setErrorBox(true);
        setBtn(false);
      }
    });
  };

  const removeElement = (index) => {
    const newRegAddress = RegAddress.filter((_, i) => i !== index);
    setRegAddress(newRegAddress);
  };

  return (
    <div className="register-con ">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <div className="d-flex-al-jb my-4">
        <h2>Registered Address</h2>
        <button
          className="cust-btn bg-primary1 bg-primary1 px-3 py-1 text-white rounded-1 border-0"
          onClick={() =>
            navigate("/mcprofile/addregistered", {
              state: {
                type: "add",
              },
            })
          }
        >
          Add +
        </button>
      </div>
      <div className="d-flex-ae-jb">
        <table className="w-100">
          <tbody>
            {RegAddress?.map((list, index) => {
              let name = lists?.filter(
                (i) => i?.id == list?.RefSupplierBranchTyeID
              );
              return (
                <tr key={index}>
                  <td className="d-grid">
                    <th className="d-flex">
                      {" "}
                      {factoryList ? (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            changeFactoriesBox(+list?.id, list);
                            changeNoPo(list, index);
                          }}
                          checked={factories?.includes(list?.id)}
                        />
                      ) : null}
                      <p className={factoryList ? "mx-2" : ""}>
                        {name[0]?.name}
                      </p>
                    </th>
                    <td>
                      Address:
                      {`${list?.address1 && list?.address1}${
                        list?.address2 !== null ? "," + list?.address2 : " "
                      }${list?.address3 !== null ? "," + list?.address3 : " "}`}
                    </td>
                    <td>
                      <span>
                        Telephone:{" "}
                        {list?.telephone_code + " " + list?.telephone}
                      </span>
                      <span>Fax: {list?.fax ? list?.fax : "NA"}</span>
                      <span>Mail: {list?.email ? list?.email : "Na"}</span>
                    </td>
                  </td>
                  <td>
                    <button
                      // to="/mcprofile/addregistered"
                      className="rounded-5 border-0 p-2 bg-hash"
                      onClick={() =>
                        navigate("/mcprofile/addregistered", {
                          state: {
                            type: "edit",
                            id: list?.id,
                          },
                        })
                      }
                    >
                      <TbEdit />
                    </button>
                  </td>
                  <td>
                    <button
                      //  onClick={() => removeElement(index)}
                      className="rounded-5 border-0 p-2 bg-hash"
                      onClick={() => deleteAddress(list?.id)}
                      disabled={btn ? true : false}
                    >
                      <RiDeleteBinLine />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {factoriesBox?.length > 0 ? (
        <div className="d-flex justify-content-end mt-2">
          <button
            className="bg-black text-white rounded border 0 px-2 py-1"
            onClick={navigateMap}
          >
            Submit To Map Factory
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default McpRegister;
