// @ts-nocheck
import React from "react";
import { loaderIcon } from "../assets/img";

const Loader = () => {
  return (
    <div className="loader-icon">
      <img src={loaderIcon} />
    </div>
  );
};

export default Loader;
