// @ts-nocheck
import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Logo, Reg } from "../assets/img";
import { BsArrowRight } from "react-icons/bs";

const RegHeader = () => {
  return (
    <div className="reg-header">
      <div className="d-flex-al-jb w-90">
        <Col md={4} xs={12} className="log_des">
          <Image src={Logo} />
        </Col>
        <Col md={8} xs={12}>
          <div className="d-flex-al justify-content-md-end  justify-content-center">
            <div className="d-flex-al-jc img_tick ">
              <Image src={Reg} />
              <div className="px-3">
                <h5>Register Now</h5>
                <p>Supplier Registration From</p>
              </div>
            </div>
            <Link className="modal_btn  m-md-0 d-flex-al ms-2 me-0" to="/login">
              <span className="px-2">Login</span>
              <BsArrowRight />
            </Link>
          </div>
        </Col>
      </div>
    </div>
  );
};
export default RegHeader;
