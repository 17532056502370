// @ts-nocheck
import React from "react";
import { Col, Table } from "react-bootstrap";
import { FaArrowLeft, FaEdit, FaExclamationCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";

const EmailTemplate = () => {
  return (
    <div className="col-md-12 d-grid">
      <TopBar />
      <TopHeader type="enterprice-admin" />
      {/* <Col className="rit-add-user ms-lg-3 mt-lg-3 mb-lg-2">
                <Link
                    to="/enterprise-admin/add-user"
                    className="link-add-user mt-2 me-1 px-2"
                >
                    <FaArrowLeft />
                    Module Permission Settings
                </Link>
            </Col> */}
      <div className=" px-3 pt-2">
        <Col className="ea-admin-detail">
          <h5>Dear Mohamad Bilal</h5>
          <p className="mb-0">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
            sapiente maxime sint quos, saepe incidunt? Molestias corporis
            laboriosam ratione voluptates, aliquid dignissimos quisquam
            obcaecati quae dolorem voluptas dolorum, rem culpa!
          </p>
        </Col>
        <Col className="add-prod-left">
          <button className="cust-btn p-0 mb-1 mt-2">Add Product</button>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Contac Person </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">Rubesh</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Mobile </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">+9876567</p>
          </div>
        </Col>
        <Col className="add-prod-left">
          <button className="cust-btn p-0 mb-1 mt-2">Your Details</button>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">First Name</p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">Rubesh</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Last Name </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">+9876567</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Email </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">example@gmail.com</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Mobile </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">+9876567</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Telephone </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">+9876567</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Department </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">CRN(+2 others)</p>
          </div>
          <div className="sku-code mt-0">
            <p className="lft-rit primary1 mb-0">Designation </p>
            <p className="mb-0 primary1">:</p>
            <p className="lft-rit1 text-dark mb-0">Worker</p>
          </div>
        </Col>
      </div>
      <div className="px-3">
        <div className="ea-dmin-desclimer">
          <div className="d-flex align-items-center bg-lit-primary4 p-3">
            <div className="top-svg">
              <FaExclamationCircle />
            </div>
            <p className="mb-0 ms-3" style={{ fontSize: "13px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
              dignissimos optio sed quod qui nostrum soluta odit. Est ipsam,
              magnam blanditiis aspernatur tempore dolore nemo neque soluta
              dicta non corrupti.
            </p>
          </div>
          <div className="d-flex justify-content-end my-2 me-2">
            <button
              className="cust-btn bg-white primary1 rounded-1 me-2"
              style={{
                border: "2px solid #247395",
              }}
            >
              Decline
            </button>
            <button className="cust-btn bg-primary1 text-white rounded-1">
              Confirm
            </button>
          </div>
        </div>
        <div className="text-end">
          <p className="mb-0 hash">Regards,</p>
          <p style={{ fontFamily: "f2" }}>KH Team</p>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};

export default EmailTemplate;
