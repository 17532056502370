import React from "react";

const AddList = ({ list }) => {
  // console.log(list);
  return (
    <div>
      <div className="sku-code mt-2">
        <p className="lft-rit primary1 mb-0">SKU Code </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.MaterialSKUCode ||
            list?.leather_product?.LeatherSKUCode ||
            list?.material_sku?.MaterialSKUCode ||
            list?.leather_sku?.LeatherSKUCode}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">SKU Name </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.MaterialSKUName ||
            list?.leather_product?.LeatherSKUName ||
            list?.material_sku?.MaterialSKUName ||
            list?.leather_sku?.LeatherSKUName}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">HSN Code </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.material_header?.hsn?.HSNCode ||
            list?.leather_product?.leather_header?.hsn?.HSNCode}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Unit of Measurement </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.material_header?.uom?.UomDescription ||
            list?.leather_product?.leather_header?.uom?.UomDescription}
        </p>
      </div>
      <div className="sku-code mt-2">
        <p className="lft-rit primary1 mb-0">Product Category </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.leather_product?.LeatherHeaderID
            ? "Leather"
            : list?.material_product?.MaterialHeaderID
            ? "Material"
            : list?.product_category == "M"
            ? "Material"
            : list?.product_category == "L"
            ? "Leather"
            : ""}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Material Type </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.material_header?.material_type
            ?.ReferenceDetailsName ||
            list?.leather_product?.leather_header?.leather_type
              ?.ReferenceDetailsName}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Material Group </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.material_header?.material_group
            ?.MaterialGroupName ||
            list?.leather_product?.leather_header?.leather_group
              ?.LeatherGroupName}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Material Sub-Group </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.material_header?.material_sub_group
            ?.MaterialSubGroupName ||
            list?.leather_product?.leather_header?.leather_sub_group
              ?.LeatherSubGroupName}
        </p>
      </div>
      {/* <div className="sku-code">
        <p className="lft-rit primary1 mb-0">KH Internal Reference Number </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">U345678</p>
      </div> */}
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">KH Barcode if any </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">-</p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Purchase Type</p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.material_header?.purchase_type
            ?.ReferenceDetailsName ||
            list?.leather_product?.leather_header?.purchase_type
              ?.ReferenceDetailsName}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">CM No </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {" "}
          {list?.material_product?.material_header?.CMNo ||
            list?.leather_product?.leather_header?.CMNo ||
            list?.material_header?.CMNo ||
            list?.leather_header?.CMNo}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Mapped Divisions </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.factory?.FactoryName ||
            list?.leather_product?.factory?.FactoryName}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Source </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {" "}
          {list?.material_product?.source?.ReferenceDetailsName ||
            list?.leather_product?.source?.ReferenceDetailsName}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Material Status </p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.Active == 1
            ? "Active"
            : "InActive" || list?.leather_product?.Active == 1
            ? "Active"
            : "InActive"}
        </p>
      </div>
      <div className="sku-code">
        <p className="lft-rit primary1 mb-0">Purchase Group</p>
        <p className="mb-0 primary1">:</p>
        <p className="lft-rit1 text-dark mb-0">
          {list?.material_product?.purchase_group?.ReferenceDetailsName ||
            list?.leather_product?.purchase_group?.ReferenceDetailsName}
        </p>
      </div>
    </div>
  );
};

export default AddList;
