import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function EnquiryViewFirstSec({
  responseType,
  currentActive,
  setCurrentActive,
  setCloseCommunication,
}) {
  console.log(responseType);
  let declineButton;
  let textShow = "View Quotation";
  if (responseType === "warning") {
    declineButton = (
      <button
        className="bg-primary2 border-0 rounded-1 fnt-fam-f2  h-2r wid-5r text-white"
        onClick={() => setCloseCommunication(true)}
      >
        Decline
      </button>
    );
    textShow = "Create Quotation";
  }

  const btnClickHandler = (e) => {
    const currentBtn = e.target.textContent.toLowerCase();
    if (currentBtn.includes("terms")) {
      setCurrentActive("terms");
    } else if (currentBtn.includes("communication")) {
      setCurrentActive("communication");
    } else if (currentBtn.includes("scope")) {
      setCurrentActive("scope");
    }
  };
  const Active =
    "enquiry-view-btn-bor rounded-pill  fnt-fam-f2  fs-6 wid-9r h-2r bg-primary1 text-white";
  const InActive =
    "enquiry-view-btn-bor rounded-pill fnt-fam-f2 fs-6 wid-9r h-2r bg-light opac-30";
  return (
    <div className="d-flex flex-wrap">
      <Col
        lg={8}
        md={12}
        sm={12}
        className="d-flex gap-2 flex-wrap my-1 fnt-fam-f3"
      >
        <button
          className={currentActive == "scope" ? Active : InActive}
          onClick={btnClickHandler}
        >
          Scope
        </button>
        <button
          className={currentActive == "terms" ? Active : InActive}
          onClick={btnClickHandler}
        >
          Terms & Condition
        </button>
        <button
          className={currentActive == "communication" ? Active : InActive}
          onClick={btnClickHandler}
        >
          Communication
        </button>
      </Col>
      <Col
        lg={4}
        md={12}
        sm={12}
        className="d-flex gap-2 my-1 flex-wrap enuiryfont"
      >
        <button className="bg-primary1 text-white border-0 rounded-1 px-3 fnt-fam-f2  py-1 float-end">
          Close
        </button>
        {responseType === "warning" ? declineButton : ""}
        <Link to="/enquiry/create-quotation">
          <button className="bg-primary1 border-0 fnt-fam-f2  rounded-1 h-2r wid-9r text-white">
            {textShow}
          </button>
        </Link>
      </Col>
    </div>
  );
}

export default EnquiryViewFirstSec;
