import React, { useState, useEffect, useRef } from "react";
import TopHeader from "../../navbar/TopHeader";
import ErrorModel from "../../constants/Services/Api/errorModel";
import TopBar from "../../navbar/TopBar";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { AiOutlineDown } from "react-icons/ai";
import { api } from "../../constants/Services";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineFileJpg } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FileUploader } from "react-drag-drop-files";
import { FaExclamationCircle } from "react-icons/fa";
import { Col } from "react-bootstrap";
import LogInFooter from "../../logincomponent/LogInFooter";
import Select from "react-select";
import DatePicker from "../../layout/datePicker";
import moment from "moment/moment";

function CreateInvoice() {
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const Proforma = location?.state?.Proforma;
  const upload_details = location?.state?.checkboxs;
  const view = location?.state?.item;
  const type = location?.state?.type;
  const method = location?.state?.method;

  // SELECT BOX

  const [option, setoption] = useState([]);
  const [optionChange, setOptionChange] = useState([]);
  const [options, setoptions] = useState([]);
  const [optionChanges, setOptionChanges] = useState([]);
  const [input, setInput] = useState([]);

  const [inVRef, setInvRef] = useState("");
  const [invDate, setInvDate] = useState(new Date());
  const [invAmount, setInvAmount] = useState("");
  const [invAmountErr, setInvAmountErr] = useState(false);
  const [amountVal, setAmountVal] = useState(0);
  const [invState, setInvState] = useState("");
  const [currency, setCurrency] = useState("");
  const [poValue, setPoValues] = useState("");
  const [drop, setDrop] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [poCheck, setPoCheck] = useState([]);
  const [dcCheck, setDcCheck] = useState([]);
  const [dcDrop, setDcDrop] = useState(false);
  const [dcDropDetails, setDcDropDetails] = useState([]);
  const [supFile, setSupFile] = useState(null);
  const [supFiles, setSupFiles] = useState(null);
  const [grnDrop, setGrnDrop] = useState(false);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [payDueDate, setPayDueDate] = useState(new Date());
  const [grnDate, setGrnDate] = useState(new Date());
  const [dcDate, setDcDate] = useState([]);
  const [alreadyInvoice, setAlreadyInvoice] = useState(0);
  const handleChange = (file) => {
    setFile(file);
  };

  const getList = () => {
    api.createDetails().then((res) => {
      if (res?.status == "success") {
        setInput(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  // console.log("Proforma", Proforma);

  useEffect(() => {
    let temp = [];
    let option = [];

    if (Proforma) {
      temp.push(Proforma);
      setOptionChange([
        {
          value: {
            id: Proforma?.purchase_order_id,
            type: Proforma?.type,
            factory_id: Proforma?.FactoryID,
          },
          label: Proforma?.purchase_order_no,
        },
      ]);

      setCurrency(Proforma.CurrencyID);
    } else if (upload_details) {
      upload_details.map((item, ind) => {
        temp.push(item);
      });
    }

    temp.map((item, ind) => {
      option.push({
        value: {
          id: item?.purchase_order_id,
          type: item?.type,
          factory_id: item?.FactoryID,
          currency_id: item?.CurrencyID,
          po_value: item?.total_price,
        },
        label: item?.purchase_order_no,
      });
    });
    setoption(option);
  }, [Proforma, upload_details]);

  useEffect(() => {
    if (Proforma && input?.currencies?.length > 0) {
      let count = input?.currencies?.find(
        (i) => i?.currency_id == +Proforma.CurrencyID
      );
      setPoValues(Proforma.total_price + " " + count?.currency_code);
      setAmountVal(Proforma.total_price);
    }
  }, [input]);

  useEffect(() => {
    let temp = [];
    let amountTemp = 0;
    let valueCurrency = [];
    if (optionChange?.length > 0) {
      optionChange?.map((item, ind) => {
        temp.push(+item?.value);
        let count = input?.currencies?.find(
          (i) => i?.currency_id == +item.value?.currency_id
        );
        amountTemp = amountTemp + item?.value?.po_value;
        valueCurrency.push(item?.value?.po_value + " " + count?.currency_code);
      });
    } else {
      valueCurrency = [];
      temp.push(+optionChange?.value);
    }

    if (amountTemp) {
      let value = Number(amountTemp).toFixed(2);
      setAmountVal(+value);
    } else {
      setAmountVal(0);
    }

    if (upload_details) {
      if (valueCurrency?.length > 0) {
        const separator = ",  ";
        const result = valueCurrency.reduce(
          (accumulator, currentValue) => accumulator + separator + currentValue
        );
        setPoValues(result);
      } else {
        setPoValues(" ");
      }
    }

    setInvAmount("");
    setInvAmountErr(false);

    setPoCheck(temp);
  }, [optionChange]);

  useEffect(() => {
    let temps = [];
    if (optionChanges?.length > 0) {
      optionChanges?.map((item, ind) => {
        temps.push(+item?.value);
      });
      setDcCheck(temps);
    } else {
      temps.push(+optionChanges?.value);
    }

    setDcCheck(temps);
  }, [optionChanges]);

  const getPoID = () => {
    let temp = [Proforma];
    const formData = new FormData();

    if (Proforma) {
      // Single
      temp.map((item, ind) => {
        formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
        formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
        formData.append(`po_ids[${ind}][type]`, item?.type);
      });
    } else {
      // Mulitiple
      upload_details?.map((item, ind) => {
        formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
        formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID);
        formData.append(`po_ids[${ind}][type]`, item?.type);
      });
    }
    api.purchaseOrderUploadPageDetails(formData).then((res) => {
      if (res?.status === "success") {
        let temp = [];
        setDcDropDetails(res?.dcs);
        setAlreadyInvoice(res?.invoice_amount);
        res?.dcs?.map((item, ind) => {
          temp.push({
            value: {
              id: item?.dc_id,
              factory_id: item?.factory_id,
              type: item?.type,
              date: moment(item?.dc_date).format("DD-MM-YYYY"),
            },
            label: item?.dc_no,
          });
        });
        setoptions(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (Proforma || upload_details) {
      getPoID();
    }

    getList();
  }, []);

  const invoiceCheckHandlers = (event, ind) => {
    let temps = [...poCheck];
    const value = event;

    const index = temps.indexOf(event);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setPoCheck(temps);
    } else {
      temps.push(value);
      setPoCheck(temps);
    }
  };

  const invoiceCheckHandler = (events, ind) => {
    let temps = [...dcCheck];
    const value = events;

    const index = temps.indexOf(events);

    if (temps.includes(value)) {
      temps.splice(index, 1);
      setDcCheck(temps);
    } else {
      temps.push(value);
      setDcCheck(temps);
    }
  };

  // console.log("poCheck", poCheck);

  const dropCloseHandler = (event) => {
    event.stopPropagation();
    dcDrop &&
      event.target?.parentElement?.parentElement?.id !== "dc-drop-cont" &&
      setDcDrop(!dcDrop);
    drop &&
      event.target?.parentElement?.parentElement?.id !== "po-drop-cont" &&
      setDrop(!drop);
  };

  const createInvoiceUploadHandler = () => {
    let temp = [Proforma];
    const formData = new FormData();

    let count = 1;

    formData.append("invoice_type", 1);
    formData.append("invoice_ref_no", inVRef);
    formData.append("invoice_date", moment(invDate).format("YYYY-MM-DD"));
    formData.append("invoice_amount", invAmount);
    formData.append("invoice_stage", invState);
    formData.append("currency_id", currency);
    formData.append(
      "payment_due_date",
      moment(payDueDate).format("YYYY-MM-DD")
    );

    if (invAmountErr) {
      count = count + 1;
    }

    if (file) {
      formData.append("copy_invoice", file);
    }

    if (supFile) {
      formData.append("support_document", supFile);
    }

    if (view && type !== "draft") {
      formData.append("invoice_id", view?.id);
      api.invoiceUploadEdit(formData).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/invoice/list");
          }, 800);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else {
      // // Single
      // temp?.map((item, ind) => {
      //   if (poCheck?.includes(+item?.purchase_order_id)) {
      //     formData.append(`po_ids[${ind}][id]`, item?.purchase_order_id);
      //     formData.append(`po_ids[${ind}][factory_id]`, item?.FactoryID ?? 4);
      //     formData.append(`po_ids[${ind}][type]`, item?.type);
      //   }
      // });

      // Multiple
      optionChange?.map((item, ind) => {
        formData.append(`po_ids[${ind}][id]`, item?.value?.id);
        formData.append(
          `po_ids[${ind}][factory_id]`,
          item?.value?.factory_id ?? 4
        );
        formData.append(`po_ids[${ind}][type]`, item?.value?.type);
      });

      optionChanges?.map((item, ind) => {
        formData.append(`dc_ids[${ind}][id]`, item?.value?.id);
        formData.append(
          `dc_ids[${ind}][factory_id]`,
          item?.value?.factory_id ?? 4
        );
        formData.append(`dc_ids[${ind}][type]`, item?.value?.type);
      });

      if (count == 1) {
        api.purchaseOrderUploadSave(formData).then((res) => {
          if (res?.status == "success") {
            setError(res);
            setErrorBox(true);
            if (type == "draft") {
              deleteDrafts(view?.id);
            } else {
              setTimeout(() => {
                navigate("/invoice/list");
              }, 800);
            }
          } else {
            setError(res);
            setErrorBox(true);
          }
        });
      }
    }
  };

  // DELETE DRAFT
  const deleteDrafts = (id) => {
    let data = {
      draft_id: id,
    };
    api.deleteDraft(data).then((res) => {
      if (res?.status === "success") {
        setTimeout(() => {
          setTimeout(() => {
            navigate("/invoice/list");
          }, 800);
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const saveDraftHandler = () => {
    let temp = [Proforma];

    const formData = new FormData();

    formData.append("invoice_type", 1);
    formData.append("invoice_ref_no", inVRef);
    formData.append("invoice_date", moment(invDate).format("YYYY-MM-DD"));
    formData.append("invoice_amount", invAmount);
    formData.append("invoice_stage", invState);
    formData.append("currency_id", currency);
    formData.append("type", 1);
    formData.append("method_type", "upload");
    formData.append("method", method);

    if (file) {
      formData.append("copy_invoice", file);
    }

    if (supFile) {
      formData.append("support_document", supFile);
    }

    option?.map((item, ind) => {
      formData.append(`po_ids[${ind}]`, JSON.stringify(item));
    });

    options?.map((item, ind) => {
      formData.append(`dc_ids[${ind}]`, JSON.stringify(item));
    });

    optionChange?.map((item, ind) => {
      formData.append(`po[${ind}]`, JSON.stringify(item));
    });

    optionChanges?.map((item, ind) => {
      formData.append(`dc[${ind}]`, JSON.stringify(item));
    });

    api.saveDraft(formData).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        if (type == "draft") {
          deleteDrafts(view?.id);
        } else {
          setTimeout(() => {
            navigate("/invoice/list");
          }, 800);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const [newImage, setNewImage] = useState();

  // useEffect(() => {
  //   // NEW

  //   let newFile;
  //   let newFile64;

  //   const getBase64 = (file) => {
  //     return new Promise((resolve) => {
  //       let fileInfo;
  //       let baseURL = "";
  //       // Make new FileReader
  //       let reader = new FileReader();

  //       // Convert the file to base64 text
  //       reader.readAsDataURL(file);

  //       // on reader load somthing...
  //       reader.onload = () => {
  //         // Make a fileInfo Object
  //         console.log("Called", reader);
  //         baseURL = reader.result;
  //         console.log(baseURL);
  //         resolve(baseURL);
  //       };
  //       console.log(fileInfo);
  //     });
  //   };

  //   const handleFileInputChange = (e) => {
  //     getBase64(file)
  //       .then((result) => {
  //         file["base64"] = result;
  //         console.log("File Is", file);

  //         newFile64 = result;
  //         newFile = file;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //     newFile = file;
  //   };

  //   handleFileInputChange(file);
  // }, [file]);

  const getView = () => {
    api.invoiceView(view?.id).then((res) => {
      if (res?.status == "success") {
        let temp = [];
        let temps = [];
        let resp = res?.invoice;
        setInvRef(resp?.invoice_ref_no);
        setInvDate(new Date(resp?.invoice_date));
        setInvAmount(resp?.invoice_amount);
        setInvState(resp?.invoice_stage);
        setSupFiles(resp?.support_document);
        setFiles(resp?.copy_invoice);

        res?.po_details?.map((item, ind) => {
          temps.push({
            value:
              item?.purchase_order?.MaterialPurchaseOrderID ||
              item?.purchase_order?.LeatherPurchaseOrderID,
            label:
              item?.purchase_order?.MaterialPurchaseOrderID ||
              item?.purchase_order?.LeatherPurchaseOrderID,
          });
        });
        setoption(temps);

        res?.dcs?.map((item, ind) => {
          temp.push({
            value: item?.dc_id,
            label: item?.dc_no,
          });
        });
        setoptions(temp);

        temps.map((item, ind) => {
          setOptionChange({ label: item?.label, value: item?.value });
        });

        temp.map((item, ind) => {
          setOptionChanges({ label: item?.label, value: item?.value });
        });
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getDraftView = () => {
    let res = view?.draft;
    let temp = [];
    let temps = [];
    let resp = res?.invoice;
    setInvRef(res?.invoice_ref_no);
    setInvDate(new Date(res?.invoice_date));
    setInvAmount(res?.invoice_amount);
    setInvState(res?.invoice_stage);
    setSupFiles(res?.support_document);
    setFiles(res?.copy_invoice);
    setCurrency(+res?.currency_id);
    res?.po_ids?.map((item, ind) => {
      let persar = JSON.parse(item);
      temps.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setoption(temps);

    res?.dc_ids?.map((item, ind) => {
      let persar = JSON.parse(item);
      temp.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setoptions(temp);

    let select = [];
    let selectDc = [];

    res?.po?.map((item, ind) => {
      let persar = JSON.parse(item);
      select.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setOptionChange(select);

    res?.dc?.map((item, ind) => {
      let persar = JSON.parse(item);
      selectDc.push({
        value: persar?.value,
        label: persar?.label,
      });
    });
    setOptionChanges(selectDc);
  };

  // console.log("optionChange", optionChange);
  // console.log("optionChanges", optionChanges);

  useEffect(() => {
    if (view && type !== "draft") {
      getView();
    } else if (view && type == "draft") {
      getDraftView();
    }
  }, []);
  useEffect(() => {
    const dateArr =
      optionChanges.length > 0 &&
      optionChanges.map((data) => {
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        function getMonth(month) {
          return months[parseInt(month, 10) - 1];
        }
        let splitDate = data.value.date.split("-");
        let dateVal = `${splitDate[0]}-${getMonth(splitDate[1])}-${
          splitDate[2]
        }`;
        return dateVal;
      });
    setDcDate(dateArr);
  }, [optionChanges]);

  const handleInvAmount = (event) => {
    let po_amount = +amountVal;
    let previous_amount = +alreadyInvoice;
    let minus_value = Number(po_amount - previous_amount).toFixed(2);

    // console.log(po_amount, previous_amount, +minus_value);

    if (+minus_value < event) {
      setInvAmountErr(true);
    } else {
      setInvAmountErr(false);
    }

    setInvAmount(event);
  };

  return (
    <Col
    // onClick={(e) => dropCloseHandler(e)}
    >
      <TopBar />
      <TopHeader
        type={
          type == "view"
            ? "create-invoice-view"
            : type == "edit"
            ? "create-invoice-edit"
            : "create-invoice"
        }
      />
      <Col
        lg={8}
        md={9}
        sm={10}
        xs={11}
        xxl={5}
        xl={5}
        style={{ minHeight: "80vh" }}
        className="create-inv-cont m-auto"
      >
        <div className="d-sm-flex gap-2 align-items-center fnt-fam-f2 my-4 py-2 primary1">
          <HiArrowNarrowLeft onClick={() => navigate(-1)} />
          <h6 className="m-0">
            {method
              ? method == "proforma"
                ? "Proforma  "
                : method == "commercial"
                ? "Commercial  "
                : ""
              : ""}
            Invoice{" "}
            {type == "view" ? "View" : type == "edit" ? "Edit" : "Upload"}
          </h6>
        </div>
        <div className="d-sm-flex gap-3 mb-2">
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              Invoice Reference No
              {type !== "view" && <span className="red-text">*</span>}
            </label>
            <p className="border-btm py-1">
              <input
                className="border-0 w-100"
                value={inVRef}
                type="number"
                onChange={(e) => setInvRef(e.target.value)}
                disabled={type == "view" ? true : false}
              />
            </p>
          </Col>

          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              Invoice Date
              {type !== "view" && <span className="red-text">*</span>}
            </label>

            <p className="border-btm py-1">
              <DatePicker
                setStartDate={setInvDate}
                startDate={invDate}
                disabled={type == "view" ? true : false}
                placeholder={"Select Date"}
              />
              {/* <input
                type="date"
                className="border-0 w-100"
                value={invDate}
                onChange={(e) => setInvDate(e.target.value)}
                disabled={type == "view" ? true : false}
              /> */}
            </p>
          </Col>
        </div>
        <div className="d-sm-flex gap-3 mb-2 ">
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="checkbox-drop-parent-box"
          >
            <label className="my-1">
              PO Ref No{type !== "view" && <span className="red-text">*</span>}
            </label>
            <Select
              closeMenuOnSelect={false}
              name="colors"
              options={option}
              value={optionChange}
              onChange={(e) => setOptionChange(e)}
              className="basic-multi-select"
              classNamePrefix="select"
              isMulti
              isDisabled={type == "view" ? true : false}
              // isDisabled={selectBoxBtn ? true : false}
            />
            {/* <p
              className="pointer border-btm d-flex"
              onClick={() => setDrop(!drop)}
            >
              Select Po Ref No
              <span className="ms-auto">
                <AiOutlineDown />
              </span>
            </p>
            {drop && (
              <ul className="checkbox-drop-box" id="po-drop-cont">
                {Proforma && (
                  <li className="d-flex gap-2 my-1">
                    <input
                      type="checkbox"
                      onClick={(e) =>
                        invoiceCheckHandlers(Proforma?.purchase_order_id)
                      }
                      checked={poCheck?.includes(Proforma?.purchase_order_id)}
                    />
                    <p className="py-1">{Proforma?.purchase_order_no}</p>
                  </li>
                )}
                {upload_details &&
                  upload_details?.map((item, ind) => (
                    <li key={ind} className="d-flex gap-2 my-1">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          invoiceCheckHandlers(item?.purchase_order_id, ind)
                        }
                        checked={poCheck?.includes(item?.purchase_order_id)}
                      />
                      <p className="py-1">{item?.purchase_order_no}</p>
                    </li>
                  ))}
              </ul>
            )} */}
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="checkbox-drop-parent-box"
          >
            <label className="my-1">DC No</label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              name="colors"
              options={poCheck?.length > 0 ? options : ""}
              value={optionChanges}
              onChange={(e) => setOptionChanges(e)}
              className="basic-multi-select"
              classNamePrefix="select"
              isDisabled={type == "view" ? true : false}
              // isDisabled={selectBoxBtn ? true : false}
            />
            {/* <p
              className="pointer border-btm d-flex"
              onClick={() => setDcDrop(!dcDrop)}
            >
              Select DC No
              <span className="ms-auto">
                <AiOutlineDown />
              </span>
            </p>
            {dcDrop && (
              <ul className="checkbox-drop-box" id="dc-drop-cont">
                {dcDropDetails?.map((item, ind) => (
                  <li className="d-flex gap-2 my-1">
                    <input
                      type="checkbox"
                      onClick={(e) => invoiceCheckHandler(item?.dc_id, ind)}
                      checked={dcCheck?.includes(item?.dc_id)}
                    />
                    <p className="py-1">{item?.dc_no}</p>
                  </li>
                ))}
              </ul>
            )} */}
          </Col>
          {/* <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              PO Date<span className="red-text">*</span>
            </label>
            <p className="border-btm py-1">20/02/2023</p>
          </Col> */}
        </div>
        <div className="d-sm-flex gap-3 mb-2">
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">GRN Reference No</label>
            {/* <p className="border-btm py-1">
              <DatePicker
                setStartDate={setGrnDate}
                startDate={grnDate}
                placeholder={"Select Date"}
              />
            </p>{" "} */}

            <p className="border-btm py-1">
              <input
                className="border-0 w-100"
                value={grnDate}
                type="number"
                onChange={(e) => setGrnDate(e.target.value)}
                disabled={type == "view" ? true : false}
              />
            </p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">PO Value</label>
            <p className="border-btm py-1">
              {poValue?.length > 1 ? poValue : "-"}
            </p>
          </Col>
        </div>

        <div className="d-sm-flex gap-3 mb-2">
          {/* <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">DC Date</label>
            <p className="border-btm py-1">
              {dcDate.length > 2 ? (
                <textarea
                  class="border-0 w-100"
                  type="text"
                  readOnly
                  value={dcDate.length > 0 ? dcDate.join(",  ") : ""}
                ></textarea>
              ) : (
                <input
                  class="border-0 w-100"
                  type="text"
                  readOnly
                  value={dcDate.length > 0 ? dcDate.join(",  ") : ""}
                />
              )}
            </p>
          </Col> */}

          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">Already Invoiced Value</label>
            <p className="border-btm py-1">
              {alreadyInvoice ? alreadyInvoice : "-"}
            </p>
          </Col>

          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">
              Invoice Stage
              {type !== "view" && <span className="red-text">*</span>}
            </label>
            <p
              className={
                // dcDate?.length > 2 ? "border-btm py-1 mt-4" :
                "border-btm py-1"
              }
            >
              <select
                onChange={(e) => setInvState(e.target.value)}
                disabled={type == "view" ? true : false}
                className={`border-0 w-100 pointer ${
                  type == "view" && "select_box_arrow_hider"
                }`}
                value={invState}
              >
                <option>Select Stage</option>
                {/* <option value={1}>Advance</option>
                <option value={2}>Partial</option>
                <option value={3}>Final</option> */}
                {/* <option value={1}>Advance Payment</option>
                <option value={2}>TT Payment</option>
                <option value={3}>50% Advance</option> */}
                {input?.stages?.map((item, ind) => {
                  return (
                    <option value={item?.ReferenceDetailsID} key={ind}>
                      {item?.ReferenceDetailsName}
                    </option>
                  );
                })}
              </select>
            </p>
          </Col>
        </div>
        <div className="d-sm-flex gap-3 mb-2">
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="d-flex w-100 gap-3 position-relative">
              <div className="w-100">
                <label className="my-1">
                  Invoice Value
                  {type !== "view" && <span className="red-text">*</span>}
                </label>
                <p className="border-btm py-1">
                  <input
                    className="border-0 w-100"
                    value={invAmount}
                    type="number"
                    onChange={(e) => handleInvAmount(e.target.value)}
                    disabled={type == "view" ? true : false}
                  />
                </p>

                {invAmount?.length > 0 && invAmountErr && (
                  <p
                    className=""
                    style={{
                      position: "absolute",
                      bottom: "-18px",
                      fontSize: "12px",
                      fontFamily: "f2",
                      color: "#ff0000",
                    }}
                  >
                    Exceeding Max Price
                  </p>
                )}
              </div>
              <div className="w-100">
                <label className="my-1">
                  Currency
                  {type !== "view" && <span className="red-text">*</span>}
                </label>
                <p className="border-btm py-1">
                  <select
                    onChange={(e) => setCurrency(e.target.value)}
                    disabled={type == "view" ? true : false}
                    className={`border-0 w-100 pointer ${
                      type == "view" && "select_box_arrow_hider"
                    }`}
                    value={currency}
                  >
                    <option hidden>Select Currency</option>
                    {input?.currencies?.map((item, ind) => (
                      <option value={item?.currency_id} key={ind}>
                        {item?.currency_code}
                      </option>
                    ))}
                  </select>
                </p>
              </div>{" "}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">Payment Due Date</label>
            <p className="border-btm py-1">
              <DatePicker
                setStartDate={setPayDueDate}
                startDate={payDueDate}
                placeholder={"Select Date"}
                disabled={type == "view" ? true : false}
              />
            </p>
          </Col>
        </div>
        <div className="d-sm-flex gap-3 mb-2 ">
          {/* <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="checkbox-drop-parent-box">
            <label className="my-1">GRN No</label>
            <p
              className="pointer border-btm d-flex"
              onClick={() => setGrnDrop(!grnDrop)}>
              Select GRN No
              <span className="ms-auto">
                <AiOutlineDown />
              </span>
            </p>
            {grnDrop && (
              <ul className="checkbox-drop-box">
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR89561897</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR823423</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR895434537</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR98561897</p>
                </li>
                <li className="d-flex gap-2">
                  <input type="checkbox" />
                  <p className="py-1">GR123461897</p>
                </li>
              </ul>
            )}
          </Col> */}
          {/* <Col lg={6} md={6} sm={12} xs={12}>
            <label className="my-1">GRN Date</label>
            <p className="border-btm py-1">20/02/2023</p>
          </Col> */}
        </div>

        <Col xs={12} className="pe-3 my-2 file_upload">
          <p className="primary1">
            <label>
              Uploaded Copy Invoice{" "}
              {type !== "view" && <span className="red-text">*</span>}
            </label>
          </p>
          <input
            className="custom-file-tech-bank-new"
            type="file"
            onChange={(e) => {
              setFile(e.target.files[0]);
              setFiles(e.target.files[0]);
            }}
            disabled={type == "view" ? true : false}
            // value={file}
          />
          <p className="blue-fnt opac-half fs-13px">
            Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to upload
            maximum of 1 upload
          </p>
        </Col>
        {files && (
          <p className="d-flex justify-content-between px-2 py-2 my-2 align-items-center file-upload-cont-bg wid-97-perc">
            <span className="d-flex gap-2">
              <AiOutlineFileJpg className="text-success fs-4" />
              {/* {file?.name} */}
              Uploaded Copy Invoice
            </span>
            <span
              className="create-inv-delete-icon-cont"
              onClick={() =>
                type == "view" ? null : setFile(null) && setFiles(null)
              }
            >
              <RiDeleteBin6Line />
            </span>
          </p>
        )}

        <Col xs={12} className="pe-3 my-2 file_upload">
          <p className="primary1">
            <label>
              Supported Document{" "}
              {type !== "view" && <span className="red-text">*</span>}
            </label>
          </p>
          <input
            className="custom-file-tech-bank-new"
            type="file"
            onChange={(e) => {
              setSupFile(e.target.files[0]);
              setSupFiles(e.target.files[0]);
            }}
            disabled={type == "view" ? true : false}
            // value={supFile}
          />
          <p className="blue-fnt opac-half fs-13px">
            Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to upload
            maximum of 1 upload
          </p>
        </Col>
        {supFiles && (
          <p className="d-flex justify-content-between px-2 my-2 py-2 align-items-center file-upload-cont-bg wid-97-perc">
            <span className="d-flex gap-2">
              <AiOutlineFileJpg className="text-success fs-4" />
              {"Supported Document"}
            </span>
            <span
              className="create-inv-delete-icon-cont"
              onClick={() =>
                type == "view" ? null : setSupFile(null) && setSupFiles(null)
              }
            >
              <RiDeleteBin6Line />
            </span>
          </p>
        )}

        {type == "view" ? null : (
          <div className="d-flex mt-2 mb-4 justify-content-end gap-3 my-2 wid-105-perc">
            <button
              className="clear-btn  px-4 py-1 fnt-fam-f2 border-0 rounded-1 opac_half"
              onClick={() => navigate(-1)}
            >
              Close
            </button>
            <button
              className="clear-btn  px-4 py-1 fnt-fam-f2 border-0 rounded-1 opac_half"
              onClick={() => saveDraftHandler()}
            >
              Save as Draft
            </button>
            <button
              className="bg-primary1 bg-primary1 fnt-fam-f2 px-4 py-1 text-white rounded-1 border-0"
              onClick={createInvoiceUploadHandler}
            >
              Submit
            </button>
          </div>
        )}
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
    </Col>
  );
}

export default CreateInvoice;
