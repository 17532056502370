import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";
import { useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import moment from "moment/moment";
import { FaEllipsisV } from "react-icons/fa";

function DashboardUser() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [dcList, setDcList] = useState([]);
  const [showInd, setShowInd] = useState();

  const getList = () => {
    api.dashboardDc().then((res) => {
      if (res?.status == "success") {
        setDcList(res?.asn_supplier_details);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const dummy_data_dc = [
    "Action",
    "S.No",
    "KH Division",
    "PO Number",
    "DC No",
    "Date",
    "Supplier Name",
    "No of Items",
    "ETA",
    "Status",
  ];

  useEffect(() => {
    getList();
  }, []);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
    }
  };

  return (
    <Col>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="dashboard" />
      <TopBar />
      <div className="py-3 px-2  light-vilot-bg w-100 min-ht-80">
        <div className="wid-92">
          <div
            style={{ width: "93%" }}
            className="d-md-flex my-1 py-1 justify-content-between mid-sc-res"
          >
            <h5 className="fnt-fam-f1 fs-15px fw-bold">Summary</h5>
          </div>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="d-md-flex flex-wrap gap-2">
              <button
                className={`bg-primary1 text-white px-3 py-2 rounded-1 border-0 fs-14px fnt-fam-f1`}
              >
                {`Delivery Challan (${dcList?.length})`}
                {/* <span class="badge badge-secondary">New</span> */}
              </button>
            </div>
            <button
              class="opac_half fnt-fam-f1 fs-15px"
              style={{
                cursor: "pointer",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={() => navigate("/dc-list")}
            >
              View All
            </button>
          </div>
          <div className="dashbord-card-cont mid-sc-res">
            <div className="px-4 table-scroll-horizontal over-flow-x-scroll my-2 w-100">
              <table className="table table-borderless min-w-1kpx">
                <thead className="fs-13px fnt-fam-f1 dash-th-hgt">
                  <tr className="text-center">
                    {dummy_data_dc?.map((item, ind) => (
                      <th className="text-center px-0">{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dcList?.map((item, ind) => {
                    return (
                      <tr
                        className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                        key={ind}
                      >
                        <>
                          {" "}
                          <td className="position-relative text-center">
                            <button
                              onClick={() => dropHandler(ind)}
                              className="cust-btn dropdown-container-tool pt-1"
                            >
                              <FaEllipsisV />
                            </button>
                            {ind == showInd && (
                              <div
                                style={{ zIndex: 2 }}
                                className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                              >
                                <button
                                  className="asn_views text-nowrap pb-1"
                                  onClick={() =>
                                    navigate("/dc", {
                                      state: { dcData: item, type: "view" },
                                    })
                                  }
                                >
                                  View
                                </button>

                                <button
                                  className="asn_views text-nowrap pb-1"
                                  onClick={() =>
                                    navigate("/dc", {
                                      state: { dcData: item, type: "edit" },
                                    })
                                  }
                                >
                                  Edit
                                </button>

                                <button
                                  className="asn_views text-nowrap pb-1"
                                  onClick={() =>
                                    navigate("/dc", {
                                      state: {
                                        dcData: item,
                                        TransportedBy: item?.TransportedBy,
                                      },
                                    })
                                  }
                                >
                                  Update Shipping Tracker
                                </button>
                              </div>
                            )}
                          </td>
                          <td>{ind + 1}</td>
                          <td>{item?.FactoryName}</td>
                          <td>{item?.DCNNo}</td>
                          <td>
                            {item?.DCDate
                              ? moment(item?.DCDate).format("DD-MMM-YYYY")
                              : ""}
                          </td>
                          <td>{item?.supplier}</td>
                          <td>{item?.status}</td>
                          <td>{item?.NoOfBox}</td>
                          <td>{item?.eta}</td>
                          <td>
                            <button
                              className={
                                item?.Approval == "1 "
                                  ? "btn btn-outline-primary"
                                  : item?.Approval == "2 "
                                  ? "btn btn-outline-danger"
                                  : item?.Approval == "3 "
                                  ? "btn btn-outline-success"
                                  : item?.Approval == "4 "
                                  ? "btn btn-outline-primary"
                                  : ""
                              }
                            >
                              {" "}
                              {item?.Approval == "1 "
                                ? "Transmit"
                                : item?.Approval == "2 "
                                ? "Dispatch"
                                : item?.Approval == "3 "
                                ? "Delivered"
                                : item?.Approval == "4 "
                                ? "Completed"
                                : ""}
                            </button>
                          </td>
                        </>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </Col>
  );
}

export default DashboardUser;
