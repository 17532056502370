import InvoiceCard from "../../../component/PurchaseOrder/Invoice/InvoiceCard";
import GeneralFilter from "../../../component/GeneralFilter";
import GeneralPagination from "../../../component/GeneralPagination";
import { FaEllipsisV } from "react-icons/fa";
import LogInFooter from "../../../logincomponent/LogInFooter";
import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import TopHeader from "../../../navbar/TopHeader";
import TopBar from "../../../navbar/TopBar";
import PFList from "../../../component/PurchaseOrder/Invoice/PFList";
import ModalFilter from "../../../component/ASN/ModalFilter";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import { api } from "../../../constants/Services";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";

function GRNPages() {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");
  const [showInd, setShowInd] = useState();
  const [activeBtn, setActiveBtn] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [activePages, setActivePages] = useState([]);

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const [proCat, setProCat] = useState();
  const [grnNo, setGrnNo] = useState();
  const [dcNo, setDcNo] = useState();

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
      console.log("1");
    }
  };
  const dropDownMenuClickHandler = () => {
    setShowModal(!showModal);
    setShowInd();
  };

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
    setCount(selectedObject.selected);
  };

  const dummy = [
    {
      sNo: "01",
      pfn: "DF00001",
      date: "01-12-2022",
      invoice: "9789890",
      dates: "12-12-2023",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "01-12-2022",
      dcn: "GR890865",
    },
    {
      sNo: "02",
      pfn: "DF00002",
      date: "02-12-2022",
      dates: "12-12-2023",
      invoice: "9789890",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "02-12-2022",
      dcn: "GR890862",
    },
    {
      sNo: "01",
      pfn: "DF00001",
      date: "01-12-2022",
      dates: "12-12-2023",
      invoice: "9789890",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "01-12-2022",
      dcn: "GR890865",
    },
    {
      sNo: "02",
      pfn: "DF00002",
      date: "02-12-2022",
      invoice: "9789890",
      dates: "12-12-2023",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "02-12-2022",
      dcn: "GR890862",
    },
    {
      sNo: "01",
      pfn: "DF00001",
      date: "01-12-2022",
      dates: "12-12-2023",
      invoice: "9789890",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "01-12-2022",
      dcn: "GR890865",
    },
    {
      sNo: "02",
      pfn: "DF00002",
      date: "02-12-2022",
      dates: "12-12-2023",
      invoice: "9789890",
      categ:
        "Fortune promoseven llc (A division of NUkhaba press disbution and release)",
      poRef: "PRF022",
      poDate: "02-12-2022",
      dcn: "GR890862",
    },
  ];
  const data = [
    {
      terms: "Accept",
    },
    {
      terms: "Accept with Comments",
    },
    {
      terms: "Decline with Comments",
    },
  ];

  const getList = () => {
    let params;

    if (search !== "") {
      params = `?search_string=${search}&page=${pages}`;
    } else {
      params = `?page=${pages}`;
    }

    api.grnTabel(params).then((res) => {
      if (res?.status == "success") {
        setActivePages(res?.pagination);
        setList(res?.grn_lists);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const grnFilter = () => {
    let param = `?type=${proCat}`;

    if (grnNo) {
      param = param + `&grn_no=${grnNo}`;
    }

    if (dcNo) {
      param = param + `&dc_no=${dcNo}`;
    }
    api.grnTabelFilter(param).then((res) => {
      if (res?.status == "success") {
        setActivePages(res?.pagination);
        setList(res?.grn_lists);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setShowModal(!showModal);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    if (search !== "") {
      getList();
    } else {
      getList();
    }
  }, [search, pages]);

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="grn-list" />
      <div
        style={{ minHeight: "80vh" }}
        className="enquiry-list-cont my-3 py-2 position-relative"
      >
        <div className="d-sm-flex justify-content-between mb-2 align-items-center w-100">
          <div className="action-page-cont">
            <p>Showing</p>
            <div className="page-status-cont">
              <p
                style={{
                  border: "1px solid #ced4da",
                  padding: "4px",
                  margin: "10px 0px",
                  textAlign: "center",
                  width: "35px",
                  height: "28px",
                }}
              >
                {list?.length}
              </p>
            </div>
            <p>of {activePages?.total} Goods Received Notes</p>
          </div>
          <div className="d-flex mt-2 mt-sm-0">
            <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
              <input
                placeholder="Search Title"
                className="w-90 h-90"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <img className="search-icon" src={Search} />
            </div>
            <button
              className="filter-btn bg-primary2"
              // onClick={onClickFilterHandler}
              onClick={dropDownMenuClickHandler}
            >
              <img src={Filter} className="wid-15px me-1" />
              Filter
            </button>
          </div>
        </div>
        {/* <PFList /> */}
        <div className="min-ht-80">
          <div
            style={{ minHeight: "60vh" }}
            className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100"
          >
            <table className="mb-1 py-1 table">
              <thead style={{ background: "#ededed" }}>
                <tr className="fnt-fam-f3 opac-85 border-0 text-center">
                  <th className="fs-14px">S.No.</th>
                  <th className="fs-14px text-nowrap">GRN No.</th>
                  <th className="text-nowrap fs-14px">GRN Date</th>
                  <th className="text-nowrap fs-14px">DC NO.</th>
                  <th className="text-nowrap fs-14px">DC Date</th>
                  <th className="fs-14px text-nowrap">Product Category</th>
                  <th className="fs-14px text-nowrap">Invoice No.</th>
                  <th className="fs-14px text-nowrap">Invoice Date</th>
                  {permission?.includes("VIEW-GOODSRECEIPTNOTE") && (
                    <th className="fs-14px text-center">Action</th>
                  )}
                </tr>
              </thead>
              <tbody className="fs-6 opac-80 text-center">
                {list?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>
                        <p>
                          {ind + 1 == 10 ? count + 1 : count}
                          {ind + 1 == 10 ? 0 : ind + 1}
                        </p>
                      </td>
                      <td>
                        <p>{item?.grn_no}</p>
                      </td>
                      <td>
                        <p>
                          {item?.grn_date
                            ? moment(item?.grn_date).format("DD-MMM-YYYY")
                            : ""}
                        </p>
                      </td>
                      <td>{item?.dc_no}</td>
                      <td>
                        {item?.dc_date
                          ? moment(item?.dc_date).format("DD-MMM-YYYY")
                          : ""}
                      </td>
                      <td>
                        <p>
                          {item?.type == "M"
                            ? "Material"
                            : item?.type == "L"
                            ? "Leather"
                            : ""}
                        </p>
                      </td>
                      <td>{item?.invoice_no}</td>
                      <td>
                        <p>
                          {item?.invoice_date
                            ? moment(item?.invoice_date).format("DD-MMM-YYYY")
                            : ""}
                        </p>
                      </td>
                      {item?.status == "Yet to Dispatch" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #0d6efd" }}
                            className="p-1 rounded-0 text-primary"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}
                      {item?.status == "Partially Delivered" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #e74c3c" }}
                            className="p-1 rounded-0 text-danger"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}{" "}
                      {item?.status == "Inprogress" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #0d6efd" }}
                            className="p-1 rounded-0 text-primary"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}
                      {item?.status == "Completed" && (
                        <td className="text-center">
                          <p
                            style={{ border: "1px solid #e74c3c" }}
                            className="p-1 rounded-0 text-danger"
                          >
                            {item?.status}
                          </p>
                        </td>
                      )}
                      <td className="position-relative text-center">
                        <button
                          onClick={() => dropHandler(ind)}
                          className="cust-btn dropdown-container-tool pt-1"
                        >
                          <FaEllipsisV />
                        </button>
                        {ind == showInd && (
                          <div
                            style={{ zIndex: 2 }}
                            className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs w-100"
                          >
                            {permission?.includes("VIEW-GOODSRECEIPTNOTE") && (
                              <button
                                onClick={() =>
                                  navigate(
                                    "/purchase-order/goods-received-note",
                                    {
                                      state: { data: item, type: "view" },
                                    }
                                  )
                                }
                                className="asn_views text-nowrap pb-1"
                              >
                                View
                              </button>
                            )}
                            {/* <Link to="/asn" className="text-wht pb-1">
                        Create ASN
                      </Link> */}
                            {/* <button
                              onClick={() =>
                                navigate(
                                  "/purchase-order/goods-received-note",
                                  {
                                    state: { data: item, type: "edit" },
                                  }
                                )
                              }
                              className="asn_views text-nowrap pb-1"
                            >
                              Edit
                            </button> */}
                            {/* <button
                              className="asn_views text-nowrap pb-1"
                              onClick={dropDownMenuClickHandler}
                            >
                              Follow Up
                            </button> */}
                            {/* <Link className="text-wht pb-1" onClick={invSelHandler}>
                        Create Profomal Invoice
                      </Link>
                      <Link className="asn_views pb-1">
                        Create Delivery Challan
                      </Link>
                      <Link
                        className="asn_views text-nowrap py-1"
                        onClick={invSelHandler}
                      >
                        Create Commercial Invoice
                      </Link>
                      <Link
                        to="/purchase-order/shipping-tracker"
                        className="asn_views py-1"
                      >
                        Update Shipping Tracks
                      </Link> */}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <ModalFilter setShowModal={setShowModal} showModal={showModal} /> */}
          </div>
        </div>
        {/* <InvoiceCard />
        <InvoiceCard />
        <InvoiceCard />
        <InvoiceCard /> */}
        <div className="page-inner-cont py-2 d-flex justify-content-end">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        <Modal
          id="#verify"
          className="prod-filter"
          show={showModal}
          centered
          onHide={() => {
            setShowModal(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">GRN Filter</h5>
          </Modal.Header>
          <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
            <Col xs={12} lg={6}>
              <div className="input-box">
                <label>Product Category</label>
                <select
                  onChange={(e) => setProCat(e.target.value)}
                  value={proCat}
                >
                  <option value={""}>Select Category</option>
                  <option value={"M"}>Material</option>
                  <option value={"L"}>Leather</option>
                </select>
              </div>
            </Col>
            <Col xs={6} lg={6}>
              <div className="input-box">
                <label>GRN No</label>
                <select
                  onChange={(e) => setGrnNo(e.target.value)}
                  value={grnNo}
                >
                  <option>Select GRN No</option>
                  {list?.map((item, i) => (
                    <option value={item?.grn_no}>{item?.grn_no}</option>
                  ))}
                </select>
              </div>
            </Col>
            <Col xs={6} lg={6}>
              <div className="input-box">
                <label>DC No</label>
                <select onChange={(e) => setDcNo(e.target.value)} value={dcNo}>
                  <option>Select Dc No</option>
                  {list?.map((item) => (
                    <option value={item?.dc_no}>{item?.dc_no}</option>
                  ))}
                </select>
              </div>
            </Col>
          </Modal.Body>
          <div className="d-flex justify-content-end">
            <button
              className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
              onClick={() => {
                setShowModal(!showModal);
                setGrnNo("");
                setProCat("");
                setDcNo("");
                getList();
              }}
            >
              Clear
            </button>
            <button
              className="cust-btn mb-2 bg-primary1 me-1 text-white"
              onClick={grnFilter}
            >
              Apply
            </button>
          </div>
        </Modal>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default GRNPages;
