import React, { useEffect, useState } from "react";
import { Col, Image, Table, Modal, Form } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  CompanyImg,
  FavLogo,
  Check,
  showPwdImg,
  hidePwdImg,
  PersonImg,
} from "../../assets/img";
import { api } from "../../constants/Services";
import useUser from "../../constants/Storage/userData";
import useToken from "../../constants/Storage/useToken";
import httpClient from "../../constants/HttpClient/httpClient";
import { usersType } from "../../layout/dummyData";
import ErrorModel from "../../constants/Services/Api/errorModel";
import Loader from "../Loader";
import ReactPaginate from "react-paginate";

const ChangeAdminMapUser = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { setToken } = useToken();
  const numb2 = [1, 2, 3, 4, 5];
  const [list, setList] = useState([]);
  const [userId, setUserId] = useState([user?.id]);
  const [activeBtn, setActiveBtn] = useState(0);
  const [Id, setId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // CHANGE ADMIN

  const [changeAdmin, setChangeAdmin] = useState(false);
  const [password, setPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [dep, setDep] = useState([]);
  const [des, setDes] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [pages, setPages] = useState(null);
  const [pageCounts, setPageCounts] = useState(10);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  // console.log(activeBtn);

  const onclickChangeHandler = () => {
    setChangeAdmin(true);
  };

  const createRoles = () => {
    api.createRole().then((res) => {
      if (res?.status == "success") {
        setDep(res?.departments);
        setDes(res?.designations);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getList = () => {
    let params;

    if (pages !== null) {
      params = `?page=${pages}`;
    } else {
      params = "";
    }
    api.listSupplierUser(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.suppliers);
        setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
  };

  const adminChange = () => {
    api.changeAdmin(Id).then((res) => {
      if (res?.status == "success") {
        setPassword(false);
        setSuccess(true);
        // setError(res);
        // setErrorBox(true);
        setBtn(false);
        setLoading(false);
      } else {
        setError(res);
        setErrorBox(true);
        setBtn(false);
        setLoading(false);
      }
    });
  };

  const change = (id, index) => {
    // console.log(id);
    setId(id?.id);
    setName(id?.SPUD_UserName);
    // setActiveBtn(index);
  };

  const changer = (data) => {
    console.log("data", data?.id);
    let temp = [];
    temp.push(data?.id);
    // console.log(temp);
    setUserId(temp);
    setId(data?.id);
    setName(data?.user_name);
  };

  // console.log("name", Id);

  const passwordCheck = () => {
    let data = {
      user_name: user?.user_name,
      password: pwd,
    };

    // console.log("data", data);

    setBtn(true);
    setLoading(true);
    api.changeAdminPass(data).then((res) => {
      if (res?.status == "success") {
        api.changeAdminEmail(Id).then((resp) => {
          if (resp?.status == "success") {
            // setError(res);
            // setErrorBox(true);
            // setLoading(false);
            adminChange();
          } else {
            setPassword(false);
            setError(resp);
            setErrorBox(true);
            setBtn(false);
            setLoading(false);
          }
        });
      } else {
        setError(res);
        setErrorBox(true);
        setBtn(false);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    createRoles();
    if (pages !== null) {
      getList();
    } else {
      getList();
    }
  }, [pages]);

  return (
    <>
      <Col
        className="px-3 px-lg-0 pt-2 choos-profile-table"
        xs={12}
        md={12}
        lg={10}
      >
        {loading ? <Loader /> : null}
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <Table>
          <tbody>
            {list?.map((item, index) => {
              let dep_value = dep?.filter(
                (i) => i?.id == item?.sm_department_id
              );

              let des_value = des?.filter(
                (i) => i?.id == item?.sm_designation_id
              );
              return (
                <tr className="" key={index}>
                  <td>
                    {/* <button
                      onClick={() => setActiveBtn(index)}
                      className="d-flex align-items-center border-0 bg-transparent p-0 me-2"
                    >
                      <div className="radio-btn">
                        {activeBtn == index ? (
                          <img src={Radio_icon} className="wid-15px" />
                        ) : (
                          <div
                            className="radio-btn-inner"
                            style={{
                              background: "#fff",
                            }}
                          ></div>
                        )}
                      </div>
                    </button> */}

                    <input
                      type="radio"
                      id="specifyColor"
                      name="radio1"
                      value="GFG"
                      checked={userId?.includes(item.id)}
                      onClick={() => changer(item)}
                    />
                  </td>

                  <td className="profil-img">
                    <Image
                      src={item?.img_url || PersonImg}
                      className="image-placeholder"
                    />
                  </td>
                  <td className="px-2">
                    <h5 className="text-dark">{item?.user_name}</h5>
                    <p className="sub-title">Department</p>
                    <p>
                      {item?.sm_department_id != null
                        ? dep_value[0]?.value
                        : ""}
                    </p>
                  </td>
                  <td className="px-2">
                    <p className="sub-title">Designation</p>
                    <p>
                      {item?.sm_designation_id != null
                        ? des_value[0]?.value
                        : ""}
                    </p>
                  </td>
                  <td className="px-2">
                    <p className="sub-title">Mobile No</p>
                    <p>{item?.mobile}</p>
                  </td>
                  <td className="px-2">
                    <p className="sub-title">Email</p>
                    <p>{item?.email}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="page-nate-new py-2">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCounts}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </Col>
      <Col
        className="d-flex mt-1 justify-content-end mb-2 pe-3 pe-lg-0"
        xs={12}
        md={12}
        lg={10}
      >
        <button
          className="cust-btn bg-lit-primary4 me-2"
          style={{ background: "#f3f3f3" }}
          onClick={() => navigate("/dashboard")}
        >
          Clear
        </button>
        <button
          onClick={onclickChangeHandler}
          className="cust-btn text-white bg-primary1"
        >
          Change
        </button>
      </Col>

      {/* CHANGE ADMIN */}

      <div className="mt-3  ">
        <Modal
          id="#sucess"
          className="otp-modal"
          show={changeAdmin}
          size="md"
          centered
          onHide={() => setChangeAdmin(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="sup-modal ">
            <Image src={FavLogo} />
            <div className="mt-4">
              <div className="d-flex-as-jc img_tick ">
                <Image src={Check} />
                <div className="px-3">
                  <h5>Change Admin</h5>
                  <p>Do you want to change your Company's Admin ?</p>
                </div>
              </div>
              <div class="d-flex flex-row-reverse">
                <div class="row">
                  <div class="col-sm">
                    <Link
                      style={{
                        backgroundColor: "#D1D3D4",
                        width: "70px",
                        height: "30px",
                      }}
                      className="my-3 modal_btn"
                      to="#"
                      onClick={() => {
                        setChangeAdmin(false);
                      }}
                    >
                      No
                    </Link>
                  </div>
                  <div class="col-sm">
                    <Link
                      style={{
                        width: "70px",
                        height: "30px",
                      }}
                      className="my-3 modal_btn"
                      to="#"
                      onClick={() => {
                        setChangeAdmin(false);
                        setPassword(true);
                      }}
                    >
                      Yes
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {/* ADMIN PASSWORD */}

      <div className="mt-3  ">
        <Modal
          id="#sucess"
          className="otp-modal"
          show={password}
          size="md"
          centered
          onHide={() => setPassword(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="sup-modal ">
            <Image src={FavLogo} />
            <div className="mt-4">
              <div className="d-flex-as-jc img_tick ">
                <div className="px-3">
                  <p>Note: Enter Your Admin's Password to proceed further,</p>
                  <div className="pwd-container my-3">
                    <input
                      name="pwd"
                      className="form-control"
                      placeholder="Enter Password"
                      type={isRevealPwd ? "text" : "password"}
                      onChange={(e) => setPwd(e.target.value)}
                    />
                    <Image
                      title={isRevealPwd ? "Show password" : "Hide password"}
                      src={isRevealPwd ? showPwdImg : hidePwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  </div>
                </div>
              </div>
              <button
                className="my-3 modal_btn"
                to="#"
                style={{
                  width: "70px",
                  height: "30px",
                }}
                onClick={passwordCheck}
                disabled={btn ? true : false}
              >
                OK
              </button>
            </div>
          </div>
        </Modal>
      </div>

      {/*   SUCCESS  */}

      <div className="mt-3  ">
        <Modal
          id="#sucess"
          className="otp-modal"
          show={success}
          size="md"
          centered
          onHide={() => setSuccess(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="sup-modal ">
            <Image src={FavLogo} />
            <div className="mt-4">
              <div className="d-flex-as-jc img_tick ">
                <Image src={Check} />
                <div className="px-3">
                  <h5>Successfull</h5>
                  <p>Company Admin Changed Successfully</p>
                </div>
              </div>
              <Link
                className="my-3 modal_btn"
                to="#"
                style={{
                  width: "70px",
                  height: "30px",
                }}
                onClick={() => {
                  setSuccess(false);
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                OK
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ChangeAdminMapUser;
