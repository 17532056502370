import InvoiceCard from "../../component/PurchaseOrder/Invoice/InvoiceCard";
import GeneralFilter from "../../component/GeneralFilter";
import GeneralPagination from "../../component/GeneralPagination";
import { FaEllipsisV } from "react-icons/fa";
import LogInFooter from "../../logincomponent/LogInFooter";
import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import PFList from "../../component/PurchaseOrder/Invoice/PFList";
import ModalFilter from "../../component/ASN/ModalFilter";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import Search from "../../assets/Icons/Normal Icons/Search.svg";
import ErrorModel from "../../constants/Services/Api/errorModel";
import { api } from "../../constants/Services";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import useUser from "../../constants/Storage/userData";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function InvoiceLists() {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");
  const { user } = useUser();
  const [showInd, setShowInd] = useState();
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [listDraft, setListDraft] = useState([]);
  const [lists, setLists] = useState([]);
  const [page, setPage] = useState({
    current_page: 1,
    per_page: 0,
    total: 0,
  });

  const [pages, setPages] = useState(null);
  const [pageCount, setPageCount] = useState();

  // START PAGINATION

  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [invoiceStage, setInvoiceStage] = useState();
  const [proformaIn, setProformaIn] = useState();
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const dropHandler = (e) => {
    setShowInd(e);
    if (e == showInd) {
      setShowInd();
      console.log("1");
    }
  };
  const dropDownMenuClickHandler = () => {
    setShowModal(!showModal);
    setShowInd();
  };

  const getList = () => {
    let params = "invoice_no";
    if (search !== "") {
      api
        .invoice_list(1, +page?.current_page, search)
        .then((res) => {
          if (res?.status == "success") {
            let temp = [];

            res?.invoices?.map((item, index) => {
              temp.push(item?.invoice_stage);
            });
            setLists([...new Set(temp)]);
            // console.log("porforma-res", res);
            setList(res?.invoices);
            setPageCount(res?.pagination);
            let { total, per_page, current_page } = res?.pagination;
            setPage({
              total,
              per_page,
              current_page,
            });
          } else {
            setError(res);
            setErrorBox(true);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      api
        .invoice_list(1, +page?.current_page, "")
        .then((res) => {
          if (res?.status == "success") {
            let temp = [];

            res?.invoices?.map((item, index) => {
              temp.push(item?.invoice_stage);
            });
            setLists([...new Set(temp)]);
            // console.log("porforma-res", res);
            setList(res?.invoices);
            setPageCount(res?.pagination);
            let { total, per_page, current_page } = res?.pagination;
            setPage({
              total,
              per_page,
              current_page,
            });
          } else {
            setError(res);
            setErrorBox(true);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    // LIST DRAFT
    api
      .listDraft()
      .then((res) => {
        if (res?.status == "success") {
          let temp = res?.drafts?.filter((i) => i?.draft?.method == "proforma");
          setListDraft(temp);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const getFiter = () => {
    let params = `?invoice_no=${proformaIn}&type=1`;

    if (invoiceStage) {
      params = params + `&invoice_stage=${invoiceStage}`;
    }

    api
      .invoiceFilter(params)
      .then((res) => {
        if (res?.status == "success") {
          setShowModal(!showModal);
          setList(res?.invoices);
          setPageCount(res?.pagination);
          let { total, per_page, current_page } = res?.pagination;
          setPage({
            total,
            per_page,
            current_page,
          });
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const handlePageChange = (selectedObject) => {
    setPage({ ...page, current_page: selectedObject.selected + 1 });
    setPages(selectedObject.selected + 1);
    setCount(selectedObject.selected);
  };

  useEffect(() => {
    getList();
  }, [page?.current_page, search]);

  // DELETE DRAFT
  const deleteDrafts = (id, resp) => {
    let data = {
      draft_id: id,
    };
    api
      .deleteDraft(data)
      .then((res) => {
        if (res?.status === "success") {
          setShowInd();
          getList();
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="invoice-list" />
      <div className="invoice_list_new">
        <Tabs
          defaultActiveKey="list"
          id="uncontrolled-tab-example"
          className="mx-3"
          // onSelect={(e) => console.log("e", e)}
        >
          <Tab className="mb-2" eventKey="list" title="Submitted">
            <div
              style={{ minHeight: "80vh" }}
              className="enquiry-list-cont my-3 py-2 position-relative"
            >
              <div className="d-sm-flex justify-content-between mb-2 align-items-center w-100">
                <div className="action-page-cont">
                  <p>Showing</p>
                  <div className="page-status-cont">
                    <p
                      style={{
                        border: "1px solid #ced4da",
                        padding: "4px",
                        margin: "10px 0px",
                        textAlign: "center",
                        width: "35px",
                        height: "28px",
                      }}
                    >
                      {list?.length}
                    </p>
                  </div>
                  <p>of {pageCount?.total} Proforma Invoice</p>
                </div>
                <div className="d-flex mt-2 mt-sm-0">
                  <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                    <input
                      placeholder="Search Title"
                      className="w-90 h-90"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      type="text"
                    />
                    <img className="search-icon" src={Search} alt="search" />
                  </div>
                  <button
                    className="filter-btn bg-primary2"
                    // onClick={onClickFilterHandler}
                    onClick={dropDownMenuClickHandler}
                  >
                    <img src={Filter} className="wid-15px me-1" alt="filter" />
                    Filter
                  </button>
                </div>
              </div>
              {/* <PFList /> */}
              <div className="min-ht-80">
                <div
                  style={{ minHeight: "60vh" }}
                  className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100"
                >
                  <table className="mb-1 py-1 table">
                    <thead style={{ background: "#ededed" }}>
                      <tr className="border-0 text-center">
                        <th className="text-nowrap">S.No</th>
                        <th className="text-nowrap">Proforma Invoice Ref.</th>
                        <th className="text-nowrap">Invoice Date</th>
                        <th className="text-nowrap">Invoice Stage</th>
                        <th className="text-nowrap">Invoice Amount</th>
                        <th className="text-center text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody className="opac-80 text-center">
                      {list?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <p>
                                {ind + 1 == 10 ? count + 1 : count}
                                {ind + 1 == 10 ? 0 : ind + 1}
                              </p>
                            </td>
                            <td>
                              <p>{item?.invoice_ref_no}</p>
                            </td>
                            <td>
                              <p>
                                {moment(item?.invoice_date).format(
                                  "DD-MMM-YYYY"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>
                                {/* {item?.invoice_stage == 1
                                  ? "Advance Payment"
                                  : item?.invoice_stage == 2
                                  ? "TT Payment"
                                  : "50% Advance"} */}
                                {item?.stage?.ReferenceDetailsName}
                              </p>
                            </td>
                            <td>{parseInt(item?.invoice_amount).toFixed(2)}</td>
                            {item?.status == "Yet to Dispatch" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #0d6efd" }}
                                  className="p-1 rounded-0 text-primary"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            {item?.status == "Partially Delivered" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #e74c3c" }}
                                  className="p-1 rounded-0 text-danger"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}{" "}
                            {item?.status == "Inprogress" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #0d6efd" }}
                                  className="p-1 rounded-0 text-primary"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            {item?.status == "Completed" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #e74c3c" }}
                                  className="p-1 rounded-0 text-danger"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            <td className="position-relative text-center">
                              <button
                                onClick={() => dropHandler(ind)}
                                className="cust-btn dropdown-container-tool pt-1"
                              >
                                <FaEllipsisV />
                              </button>
                              {ind == showInd && (
                                <div
                                  style={{ zIndex: 2 }}
                                  className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                                >
                                  {permission?.includes("VIEW-INVOICE") && (
                                    <button
                                      onClick={() =>
                                        item?.terms_conditions?.length ==
                                        undefined
                                          ? navigate("/invoice/create", {
                                              state: {
                                                item: item,
                                                type: "view",
                                                method: "proforma",
                                              },
                                            })
                                          : navigate("/invoice", {
                                              state: {
                                                item: item,
                                                type: "view",
                                                method: "proforma",
                                              },
                                            })
                                      }
                                      className="asn_views text-nowrap pb-1"
                                    >
                                      View
                                    </button>
                                  )}
                                  {/* <Link to="/asn" className="text-wht pb-1">
                        Create ASN
                      </Link> */}
                                  {permission?.includes("EDIT-INVOICE") && (
                                    <button
                                      onClick={() =>
                                        item?.terms_conditions?.length ==
                                        undefined
                                          ? navigate("/invoice/create", {
                                              state: {
                                                item: item,
                                                type: "edit",
                                                method: "proforma",
                                              },
                                            })
                                          : navigate("/invoice", {
                                              state: {
                                                item: item,
                                                type: "edit",
                                                method: "proforma",
                                              },
                                            })
                                      }
                                      className="asn_views text-nowrap pb-1"
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {/* <button
                              className="asn_views text-nowrap pb-1"
                              // onClick={dropDownMenuClickHandler}
                            >
                              Follow Up
                            </button> */}
                                  {+user?.supplier?.CountryID == 1 && (
                                    <>
                                      <button
                                        className="asn_views text-nowrap pb-1"
                                        onClick={() =>
                                          navigate("/invoice/gst", {
                                            state: {
                                              item: item,
                                              type: "update",
                                              method: "proforma",
                                            },
                                          })
                                        }
                                      >
                                        GST Update
                                      </button>
                                      <button
                                        className="asn_views text-nowrap pb-1"
                                        onClick={() =>
                                          navigate("/invoice/gst", {
                                            state: {
                                              item: item,
                                              type: "view",
                                              method: "proforma",
                                            },
                                          })
                                        }
                                      >
                                        GST View
                                      </button>
                                    </>
                                  )}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <ModalFilter setShowModal={setShowModal} showModal={showModal} /> */}
                </div>
              </div>
              {/* <InvoiceCard />
        <InvoiceCard />
        <InvoiceCard />
        <InvoiceCard /> */}
              <div className="page-inner-cont py-2 d-flex justify-content-end">
                <ReactPaginate
                  nextLabel="›"
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  pageCount={Math.ceil(page?.total / page?.per_page)}
                  previousLabel="‹"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
              <Modal
                id="#verify"
                className="prod-filter"
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <h5 className="mb-0">Filter</h5>
                </Modal.Header>
                <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>Proforma Invoice Ref.</label>
                      <select
                        onChange={(e) => setProformaIn(e.target.value)}
                        value={proformaIn}
                      >
                        <option>Select Proforma Invoice Ref.</option>
                        {list?.map((item, i) => (
                          <option value={item?.invoice_ref_no}>
                            {item?.invoice_ref_no}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>Invoice Stage</label>
                      <select
                        onChange={(e) => setInvoiceStage(e.target.value)}
                        value={invoiceStage}
                      >
                        <option>Select Invoice Stage</option>
                        {lists?.map((item, i) => {
                          return (
                            <option value={item}>
                              {/* {item == 1
                                ? "Advanced"
                                : item == 2
                                ? "Partial"
                                : "Final"} */}
                              {item == 1
                                ? "KT - Advance Payment"
                                : item == 2
                                ? "TT Payment"
                                : "50% Advance"}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Col>
                </Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                    onClick={() => {
                      setShowModal(!showModal);
                      setProformaIn("");
                      setInvoiceStage("");
                      getList();
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="cust-btn mb-2 bg-primary1 me-1 text-white"
                    onClick={getFiter}
                  >
                    Apply
                  </button>
                </div>
              </Modal>
            </div>
          </Tab>
          <Tab className="mb-2" eventKey="draft" title="Draft">
            <div
              style={{ minHeight: "80vh" }}
              className="enquiry-list-cont my-3 py-2 position-relative"
            >
              {/* <div className="d-sm-flex justify-content-between mb-2 align-items-center w-100">
              <div className="action-page-cont">
                <p>Showing</p>
                <div className="page-status-cont">
                  <p
                    style={{
                      border: "1px solid #ced4da",
                      padding: "4px",
                      margin: "10px 0px",
                      textAlign: "center",
                      width: "35px",
                      height: "28px",
                    }}
                  >
                    {list?.length}
                  </p>
                </div>
                <p>of {pageCount?.total} Proforma Invoice</p>
              </div>
              <div className="d-flex mt-2 mt-sm-0">
                <div className="search-box d-flex gap-2 align-items-center px-2 rounded-1">
                  <input
                    placeholder="Search Title"
                    className="w-90 h-90"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    type="text"
                  />
                  <img className="search-icon" src={Search} alt="search" />
                </div>
                <button
                  className="filter-btn bg-primary2"
                  // onClick={onClickFilterHandler}
                  onClick={dropDownMenuClickHandler}
                >
                  <img src={Filter} className="wid-15px me-1" alt="filter" />
                  Filter
                </button>
              </div>
            </div> */}
              {/* <PFList /> */}
              <div className="min-ht-80">
                <div
                  style={{ minHeight: "60vh" }}
                  className="table-scroll-horizontal over-flow-x-scroll my-2 responsive-width w-100"
                >
                  <table className="mb-1 py-1 table">
                    <thead style={{ background: "#ededed" }}>
                      <tr className="border-0 text-center">
                        <th className="text-nowrap">S.No.</th>
                        <th className="text-nowrap">Proforma Invoice Ref.</th>
                        <th className="text-nowrap">Invoice Date</th>
                        <th className="text-nowrap">Invoice Stage</th>
                        <th className="text-nowrap">Invoice Amount</th>
                        <th className="text-center text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody className="opac-80 text-center">
                      {listDraft?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <p>
                                {ind + 1 == 10 ? count + 1 : count}
                                {ind + 1 == 10 ? 0 : ind + 1}
                              </p>
                            </td>
                            <td>
                              <p>
                                {moment(item?.draft?.invoice_date).format(
                                  "DD-MMM-YYYY"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>
                                {moment(item?.invoice_date).format(
                                  "DD-MMM-YYYY"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>
                                {item?.invoice_stage == 1
                                  ? "Advance Payment"
                                  : item?.invoice_stage == 2
                                  ? "TT Payment"
                                  : "50% Advance"}
                              </p>
                            </td>
                            <td>
                              {item?.invoice_amount
                                ? Number(item?.invoice_amount)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "-"}
                            </td>
                            {item?.status == "Yet to Dispatch" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #0d6efd" }}
                                  className="p-1 rounded-0 text-primary"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            {item?.status == "Partially Delivered" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #e74c3c" }}
                                  className="p-1 rounded-0 text-danger"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}{" "}
                            {item?.status == "Inprogress" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #0d6efd" }}
                                  className="p-1 rounded-0 text-primary"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            {item?.status == "Completed" && (
                              <td className="text-center">
                                <p
                                  style={{ border: "1px solid #e74c3c" }}
                                  className="p-1 rounded-0 text-danger"
                                >
                                  {item?.status}
                                </p>
                              </td>
                            )}
                            <td className="position-relative text-center">
                              <button
                                onClick={() => dropHandler(ind)}
                                className="cust-btn dropdown-container-tool pt-1"
                              >
                                <FaEllipsisV />
                              </button>
                              {ind == showInd && (
                                <div
                                  style={{ zIndex: 2 }}
                                  className="po-card-drop-down1 text-start d-flex flex-column p-3 drop-downs"
                                >
                                  {/* {permission?.includes("VIEW-INVOICE") && (
                                    <button
                                      onClick={() =>
                                        item?.draft?.method_type !== "create"
                                          ? navigate("/invoice/create", {
                                              state: {
                                                item: item,
                                                type: "draft",
                                                method: "proforma",
                                              },
                                            })
                                          : navigate("/invoice", {
                                              state: {
                                                item: item,
                                                type: "draft",
                                                method: "proforma",
                                              },
                                            })
                                      }
                                      className="asn_views text-nowrap pb-1"
                                    >
                                      View
                                    </button>
                                  )} */}
                                  {permission?.includes("EDIT-INVOICE") && (
                                    <button
                                      onClick={() =>
                                        item?.draft?.method_type !== "create"
                                          ? navigate("/invoice/create", {
                                              state: {
                                                item: item,
                                                type: "draft",
                                                method: "proforma",
                                              },
                                            })
                                          : navigate("/invoice", {
                                              state: {
                                                item: item,
                                                type: "draft",
                                                method: "proforma",
                                              },
                                            })
                                      }
                                      className="asn_views text-nowrap pb-1"
                                    >
                                      Edit
                                    </button>
                                  )}
                                  <button
                                    className="asn_views text-nowrap pb-1"
                                    onClick={() => deleteDrafts(item?.id, item)}
                                  >
                                    Delete Invoice
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="page-inner-cont py-2 d-flex justify-content-end">
              <ReactPaginate
                nextLabel="›"
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(page?.total / page?.per_page)}
                previousLabel="‹"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div> */}
              <Modal
                id="#verify"
                className="prod-filter"
                show={showModal}
                centered
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <h5 className="mb-0">Filter</h5>
                </Modal.Header>
                <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>Proforma Invoice Ref.</label>
                      <select
                        onChange={(e) => setProformaIn(e.target.value)}
                        value={proformaIn}
                      >
                        <option>Select Proforma Invoice Ref.</option>
                        {list?.map((item, i) => (
                          <option value={item?.invoice_ref_no}>
                            {item?.invoice_ref_no}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col xs={6} lg={6}>
                    <div className="input-box">
                      <label>Invoice Stage</label>
                      <select
                        onChange={(e) => setInvoiceStage(e.target.value)}
                        value={invoiceStage}
                      >
                        <option>Select Invoice Stage</option>
                        {lists?.map((item, i) => {
                          return (
                            <option value={item}>
                              {/* {item == 1
                                ? "Advanced"
                                : item == 2
                                ? "Partial"
                                : "Final"} */}
                              {/* {item == 1
                                ? "KT - Advance Payment"
                                : item == 2
                                ? "TT Payment"
                                : "50% Advance"} */}

                              {item == 1
                                ? "Advance Payment"
                                : item == 2
                                ? "TT Payment"
                                : "50% Advance"}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Col>
                </Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
                    onClick={() => {
                      setShowModal(!showModal);
                      setProformaIn("");
                      setInvoiceStage("");
                      getList();
                    }}
                  >
                    Clear
                  </button>
                  <button
                    className="cust-btn mb-2 bg-primary1 me-1 text-white"
                    onClick={getFiter}
                  >
                    Apply
                  </button>
                </div>
              </Modal>
            </div>
          </Tab>
        </Tabs>
      </div>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default InvoiceLists;
