import React, { useEffect, useState } from "react";
import { Col, Image, Tab, Nav, Navbar, Pagination } from "react-bootstrap";
import { Dekra, Medal } from "../assets/img";
import McpAward from "../component/McpAward";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import NavMcp from "../component/NavMcp";
import LogInFooter from "../logincomponent/LogInFooter";
import TopHeader from "../navbar/TopHeader";
import { api } from "../constants/Services";
import moment from "moment/moment";
import ErrorModel from "../constants/Services/Api/errorModel";
import Loader from "../component/Loader";
import TopBar from "../navbar/TopBar";
import ReactPaginate from "react-paginate";
const data = [
  {
    id: 1,
    img: Dekra,
    certificate: "Certificate 1",
    issues: " Global Logistics Company",
    date: "16-10-2022",
    validity: "16-10-2022",
  },
  {
    id: 2,
    img: Dekra,
    certificate: "Certificate 2",
    issues: " Global Logistics Company",
    date: "16-10-2022",
    validity: "16-09-2022",
  },
  {
    id: 3,
    img: Dekra,
    certificate: "Certificate 3",
    issues: " Global Logistics Company",
    date: "16-10-2022",
    validity: "nil",
  },
];

const Certificate = ({}) => {
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permissions");
  const [CertiFicate, setCertiFicate] = useState([]);
  const [pages, setPages] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [loader, setLoader] = useState(false);

  const removeElement = (index) => {
    const newCertiFicate = CertiFicate.filter((_, i) => i !== index);
    setCertiFicate(newCertiFicate);
  };

  const getList = () => {
    setLoader(true);

    let params;

    if (pages !== null) {
      params = `?type=1&page=${pages}`;
    } else {
      params = "?type=1";
    }

    api.certificate_awardList(params).then((res) => {
      if (res?.status == "success") {
        let temp = res?.certificate_awards.filter((i) => i?.document_type == 1);
        // console.log(temp);
        setCertiFicate(res?.certificate_awards?.reverse());
        // setPages(res?.pagination?.current_page);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setLoader(false);
      } else {
        setLoader(false);
      }
      // let temp = res?.certificate_awards.filter((i) => i?.SPCAD_Type == 1);
      // // console.log(temp);
      // setCertiFicate(temp.reverse());
    });
  };

  const handlePageChange = (selectedObject) => {
    setPages(selectedObject.selected + 1);
  };

  const deleteCertificate = (id) => {
    // console.log(id);
    api.certificate_awardDelete(id).then((res) => {
      if (res?.status == "success") {
        getList();
        setError(res);
        setErrorBox(true);
        setLoader(false);
      } else {
        setError(res);
        setErrorBox(true);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    if (pages !== null) {
      getList();
    } else {
      getList();
    }
  }, [pages]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
    // console.log("err-close");
  };

  return (
    <div className="register-con ">
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      {loader && <Loader />}
      <TopHeader type="certificate" />
      <div className="m-2 d-flex-wrap   ">
        <Col lg={2} md={4} xs={12} className="px-2 my-2">
          <Nav variant="pills" className="flex-column nav_mctap">
            <Nav.Item>
              <Nav.Link eventKey="inform">
                <Link to="/mcprofile" className="nav-link">
                  {" "}
                  Basic Information{" "}
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="transport">
                <Link to="/mcprofile/transport" className="nav-link">
                  {" "}
                  Nearest Transport Points{" "}
                </Link>{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="certificate" className="active">
                <Link to="/mcprofile/certificate" className="nav-link">
                  Certificate/Awards{" "}
                </Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col
          lg={7}
          md={8}
          xs={12}
          className="px-3 my-2 min-ht-80"
          eventKey="certificate"
        >
          {/* certificates start*/}

          <div className="d-flex-al-jb my-3">
            <h2>Certificate</h2>
            {permissions?.includes("ADD-CERTIFICATE AWARDS") && (
              <button
                className="cust-btn bg-primary1 bg-primary1 px-3 py-1 text-white rounded-1 border-0"
                // to="/mcprofile/addcertificate"
                onClick={() =>
                  navigate("/mcprofile/addcertificate", {
                    state: {
                      type: "add",
                      method: "certificate",
                    },
                  })
                }
              >
                Add +
              </button>
            )}
          </div>
          <div className="d-flex-ae-jb my-3">
            <table className="w-100 cert_ficate">
              <tbody>
                {CertiFicate?.map((list, index) => (
                  <tr key={index}>
                    <td className="img-none">
                      <Image
                        src={
                          // list?.SPCAD_Upload ||
                          // Dekra
                          Medal
                        }
                      />
                    </td>
                    <td className="d-grid">
                      <th>{list?.title}</th>
                      <td>Issues By: {list?.issued_by}</td>
                      <td>
                        Date Of Issue:
                        {list?.issued_on
                          ? moment(list?.issued_on).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td>
                        Validity:{" "}
                        {list?.validity
                          ? moment(list?.validity).format("DD-MM-YYYY")
                          : "-"}
                      </td>
                    </td>
                    {permissions?.includes("EDIT-CERTIFICATE AWARDS") && (
                      <td>
                        {/* <Link to="/mcprofile/addcertificate">
                      <TbEdit />
                      </Link> */}
                        <button
                          className="rounded-5 border-0 p-2 bg-hash "
                          onClick={() =>
                            navigate("/mcprofile/addcertificate", {
                              state: {
                                type: "edit",
                                method: "certificate",
                                id: list?.id,
                              },
                            })
                          }
                        >
                          <TbEdit />
                        </button>
                      </td>
                    )}
                    {permissions?.includes("DELETE-CERTIFICATE AWARDS") && (
                      <td>
                        <button
                          className="rounded-5 border-0 p-2 bg-hash"
                          onClick={() => deleteCertificate(list?.id)}
                        >
                          <RiDeleteBinLine />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              className="page-inner-cont py-2"
              style={{ marginLeft: "auto" }}
            >
              <ReactPaginate
                nextLabel="›"
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="‹"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>

          {/* certificates end*/}
          <McpAward />
          {/* <div className="pe-3 my-5 d-flex-al-je ben_design">
            <Link to="/mcprofile" className="btn brn-can">
              Cancel
            </Link>
            <Link to="#" className="btn bode-btn">
              Save as Draft
            </Link>
            <Link className=" btn sub-btn">Save & Next</Link>
          </div> */}
        </Col>
      </div>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};
export default Certificate;
