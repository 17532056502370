import GeneralCommunication from "../GeneralCommunication";
import POMainAckModal from "./POMainAckModal";
import React, { useState } from "react";
import POMainBtn from "./POMainBtn";

function POCommunication({ list, data, type }) {
  const [openCard, setOpenCard] = useState(false);

  const POAckCloseHandler = () => setOpenCard(false);
  const POAckClickHandler = () => setOpenCard(true);
  const ackModalSubmitHandler = () => setOpenCard(false);
  return (
    <div className="min-ht-80">
      <GeneralCommunication details={list} type={type} />
      {data?.Sup_Approval == "A " ? null : data?.Sup_Approval ==
        "D " ? null : data?.Sup_Approval == "C " ? null : (
        <POMainBtn POAckClickHandler={POAckClickHandler} />
      )}

      <POMainAckModal
        list={list}
        data={data}
        openCard={openCard}
        setOpenCard={setOpenCard}
        ackModalSubmitHandler={ackModalSubmitHandler}
        POAckCloseHandler={POAckCloseHandler}
      />
    </div>
  );
}

export default POCommunication;
