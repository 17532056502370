// @ts-nocheck
import React, { useState } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo, showPwdImg, hidePwdImg } from "../assets/img";
import LogInFooter from "../logincomponent/LogInFooter";
import LogInConnect from "../logincomponent/LogInConnect";
import { api } from "../constants/Services";
const NewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location?.state);
  const [npwd, setNpwd] = useState("");
  const [isNewPwd, setIsNewPwd] = useState(false);
  const [cpwd, setCpwd] = useState("");
  const [isConPwd, setIsConPwd] = useState(false);
  const [npwdErr, setNpwdErr] = useState(false);
  const [cpwdErr, setCpwdErr] = useState(false);

  const submitHandler = () => {
    console.log("hsff");
    if (npwd?.length < 8 || cpwd?.length < 8) {
      setNpwdErr(true);
      setCpwdErr(true);
      console.log("esrtyu");
    } else if (npwd !== cpwd) {
      // setCpwdErr(true);
      console.log("esrtyu");
    }

    let formdata = new FormData();
    formdata.append("user_name", location?.state);
    formdata.append("email", location?.state);
    formdata.append("password", npwd);
    formdata.append("password_confirmation", cpwd);
    api.setPassword(formdata).then((res) => {
      console.log(res);
      alert(res?.message);
      navigate("/");
    });
  };
  return (
    <div className="login-bg">
      <div className="d-flex-al-jc  log_cent">
        <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
          <div className="form_box">
            <Image src={Logo} />
            <h6> Set New Password for Login</h6>
            <Col xs={12} className="pwd-container position-relative pb-2">
              <input
                name="pwd"
                className="form-control"
                placeholder="New Password"
                type={isNewPwd ? "text" : "password"}
                value={npwd}
                onChange={(e) => setNpwd(e.target.value)}
              />
              <Image
                title={isNewPwd ? "Hide password" : "Show password"}
                src={isNewPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsNewPwd((prevState) => !prevState)}
              />
              {npwd?.length < 8 && npwdErr && (
                <p className="err-input">Enter New Password Minimum 8</p>
              )}
            </Col>
            <Col xs={12} className="pwd-container position-relative pb-2">
              <input
                name="pwd"
                className="form-control"
                placeholder="Confirm Password"
                type={isConPwd ? "text" : "password"}
                value={cpwd}
                onChange={(e) => setCpwd(e.target.value)}
              />
              <Image
                title={isConPwd ? "Hide password" : "Show password"}
                src={isConPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsConPwd((prevState) => !prevState)}
              />
              {cpwd?.length < 8 && cpwdErr && (
                <p className="err-input">Enter Confirm Password Minimum 8</p>
              )}
              {npwd !== cpwd && <p className="err-input">Password mismatch</p>}
            </Col>
            <button onClick={submitHandler} className="bt-tx">
              Set Password
            </button>
            <LogInConnect />
          </div>
        </Col>
      </div>
      <LogInFooter />
    </div>
  );
};
export default NewPassword;
