import { RiArrowUpDownFill } from "react-icons/ri";
import { FaEllipsisV } from "react-icons/fa";
import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";

function Ad_BroadCast_Table_component() {
  const [dropShow, setDropShow] = useState("");

  const dropDownMenuClickHandler = (ind) => {
    if (dropShow === ind) {
      setDropShow("");
    } else {
      setDropShow(ind);
    }
  };

  const dummy_data = [
    {
      title: "Lorem ipsum dolor sit amet consetueor daiscshing",
      created_date: "09-11-2022",
      Scheduled: "10-11-2022",
      target: "KH Suppliers +200",
      status: "Scheduled",
    },
    {
      title: "Lorem ipsum dolor sit amet consetueor daiscshing",
      created_date: "09-11-2022",
      Scheduled: "15-11-2022",
      target: "Investor",
      status: "Scheduled",
    },
    {
      title: "Lorem ipsum dolor sit amet consetueor daiscshing",
      created_date: "09-11-2022",
      Scheduled: "08-12-2022",
      target: "Project Owner",
      status: "Scheduled",
    },
    {
      title: "Lorem ipsum dolor sit amet consetueor daiscshing",
      created_date: "09-12-2022",
      Scheduled: "11-11-2022",
      target: "Buyers",
      status: "Scheduled",
    },
    {
      title: "Lorem ipsum dolor sit amet consetueor daiscshing",
      created_date: "09-11-2022",
      Scheduled: "10-12-2022",
      target: "KH Suppliers",
      status: "Scheduled",
    },
    {
      title: "Lorem ipsum dolor sit amet consetueor daiscshing",
      created_date: "09-11-2022",
      Scheduled: "07-12-2022",
      target: "KH Suppliers",
      status: "Draft",
    },
  ];
  return (
    <div className="mid-sc-res my-2 py-1">
      <div className="table-scroll-horizontal over-flow-x-scroll my-2 w-100">
        <table className="table table-borderless min-w-1kpx">
          <thead className="fs-14px bg-light-acqu fnt-fam-f1 ad-dash-th-hgt">
            <tr>
              <th className="ps-4">
                Title
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4">
                Created on
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4">
                Scheduled on
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4">
                Targeted Stakeholder
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4">
                Status
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4">
                Action
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  style={{ width: "302px" }}
                  className="ad-table-search-box  d-flex gap-2 fnt-fam-f2 fs-13px ms-3 align-items-center px-2 rounded-1"
                >
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "114px" }}
                  className="ad-table-search-box ms-3 d-flex gap-2 align-items-center px-2 rounded-1"
                >
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "114px" }}
                  className="ad-table-search-box fs-13px ms-3 d-flex gap-2 align-items-center px-2 rounded-1"
                >
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "154px" }}
                  className="ad-table-search-box fs-13px ms-3 d-flex gap-2 align-items-center px-2 rounded-1"
                >
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "83px" }}
                  className="ad-table-search-box d-flex  align-items-center px-1 fs-13px ms-3 rounded-1"
                >
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
            </tr>
            {dummy_data.map((item, ind) => {
              return (
                <tr
                  key={ind}
                  style={{ lineHeight: "inherit" }}
                  className="fs-14px fnt-fam-f1 dash-tr-hgt border-btm"
                >
                  <td className="ps-4">{item.title}</td>
                  <td className="ps-4">{item.created_date}</td>
                  <td className="ps-4">{item.Scheduled}</td>
                  <td className="ps-4">{item.target}</td>
                  <td className="ps-4">
                    {item.status === "Scheduled" ? (
                      <button className="text-white border-0 ad-bc-table-btn light-green-bg">
                        {item.status}
                      </button>
                    ) : (
                      <button className="text-white border-0 ad-bc-table-btn bg-dark">
                        {item.status}
                      </button>
                    )}
                  </td>
                  <td className="ps-4 position-relative">
                    <button
                      className="cust-btn dropdown-container-tool p-0"
                      onClick={() => dropDownMenuClickHandler(ind)}
                    >
                      <FaEllipsisV />
                    </button>
                    {dropShow === ind && (
                      <div className="ad-bc-tb-dropdown pointer">
                        <p className="fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont primary2">
                          View
                        </p>
                        <p className="text-wht fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont">
                          Edit
                        </p>
                        <p className="text-wht fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont">
                          Delete
                        </p>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Ad_BroadCast_Table_component;
