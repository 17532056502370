import React, { useState } from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ startDate, setStartDate, disabled, placeholder }) => {
  return (
    <Datepicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat="dd-MMM-yyyy"
      todayButton="Today"
      disabled={disabled ? true : false}
      placeholderText={placeholder && placeholder}
    />
  );
};

export default DatePicker;
