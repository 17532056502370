// @ts-nocheck
import React, { useEffect, useState } from "react";
import TopHeader from "../navbar/TopHeader";
import LogInFooter from "../logincomponent/LogInFooter";
import BasicInform from "../component/BasicInform";
import TopBar from "../navbar/TopBar";
import { Col, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavMcp from "../component/NavMcp";
import { api } from "../constants/Services";
const MCProfile = ({}) => {
  const permissions = localStorage.getItem("permissions");
  return (
    <div className="mcp-page">
      <TopBar />
      <TopHeader type="profile" />
      <div className="m-2 d-flex-wrap   ">
        <Col lg={2} md={4} xs={12} className="px-2 my-2">
          <Nav variant="pills" className="flex-column nav_mctap">
            {permissions?.includes("LIST-BASIC INFORMATION") && (
              <Nav.Item>
                <Nav.Link eventKey="inform " className="active">
                  <Link to="/mcprofile" className="nav-link">
                    Basic Information
                  </Link>
                </Nav.Link>
              </Nav.Item>
            )}
            {permissions?.includes("LIST-NEAREST TRANSPORT POINTS") && (
              <Nav.Item>
                <Nav.Link eventKey="transport">
                  <Link to="/mcprofile/transport" className="nav-link">
                    Nearest Transport Points
                  </Link>
                </Nav.Link>
              </Nav.Item>
            )}
            {permissions?.includes("LIST-CERTIFICATE AWARDS") && (
              <Nav.Item>
                <Nav.Link eventKey="certificate">
                  <Link to="/mcprofile/certificate" className="nav-link">
                    Certificate/Awards
                  </Link>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Col>
        <Col lg={7} md={8} xs={12} className="px-3 my-2 ">
          <BasicInform />
        </Col>
      </div>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};
export default MCProfile;
