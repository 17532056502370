import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import { Col } from "react-bootstrap";
function EnquiryCard({ type }) {
  const [dropShow, setDropShow] = useState(false);
  const navigate = useNavigate();

  const enqCardClickHandler = (e) => {
    navigate("/enquiry/enquiry-list/enquiry-view", {
      state: { type: `${type}` },
    });
  };

  const dropHandler = () => {
    setDropShow(!dropShow);
  };

  return (
    <div className="my-4">
      <div className="enquiry-list-card-cont p-0">
        <div className="position-absolute enq-drop-whole-container">
          <button
            className="cust-btn dropdown-container-tool p-0"
            onClick={dropHandler}
          >
            <FaEllipsisV />
          </button>
          {dropShow && (
            <div className="enq-sort-drop">
              <Link>View</Link>
              <Link>Delete</Link>
            </div>
          )}
        </div>
        {/* <Link 
          type="enquiry-view"
          to="/enquiry/enquiry-list/enquiry-view"
          onClick={enqCardClickHandler}
        > */}
        <div onClick={enqCardClickHandler} style={{ cursor: "pointer" }}>
          <div className="d-flex gap-4 flex-wrap fnt-fam-f1 py-1 mt-2 px-3">
            <p className="fs-1r">
              <span className="opac_half">RFQ ID:</span> P00001
            </p>
            <p className="fs-1r">
              <span className="opac_half">RFQ Ref No:</span> P00001
            </p>
            <p className="fs-1r">
              <span className="opac_half">RFQ Date:</span> 01-12-2022
            </p>
          </div>
          <p className="text-uppercase fw-bold py-1 px-3 enuiryfont">
            Fortune promoseven llc (A division of NUkhaba press disbution and
            release)
          </p>
          <p className="py-1 mb-2 px-3">
            {type == "primary" && (
              <button
                type="button"
                className="btn btn-outline-primary po-card-btn"
              >
                Responded
              </button>
            )}
            {type == "success" && (
              <button
                type="button"
                className="btn btn-outline-success po-card-btn"
              >
                Awaraded
              </button>
            )}
            {type == "danger" && (
              <button
                type="button"
                className="btn btn-outline-danger po-card-btn"
              >
                Decline
              </button>
            )}
            {type == "warning" && (
              <button type="button" className="btn warning-button po-card-btn">
                Yet to Respond
              </button>
            )}
          </p>
          <div className="opac_half d-flex gap-3 align-items-center enquiry-footer-card p-3 flex-wrap">
            <p>
              <span>Closing Date and Time:</span>14.10.2023 10AM
            </p>
            <p>
              <span>KH Division:</span>KH Export
            </p>
            <p>
              <span>Issued By:</span>Mohammed Bilal
            </p>
          </div>
        </div>
        {/* </Link>  */}
      </div>
    </div>
  );
}

export default EnquiryCard;
