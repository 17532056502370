import { Col } from "react-bootstrap";

function QuotationInfo() {
  return (
    <div className="create-quo-info-cont my-4 py-2">
      <h5 className="font-blue fs-5">Quotation Information</h5>
      <Col md={12} xs={12} xxl={8} className="pe-3 my-2">
        <label className="my-1">
          Quotation Title <span className="red-text">*</span>
        </label>
        <p className="text-capitalize border-btm py-1">
          Provding Rentering retail works maintains for purchase office in
          minstry
        </p>
      </Col>
      <div className="d-flex gap-3">
        <Col md={6} lg={6} xs={12} className="pe-3 my-2">
          <label className="my-1">
            Quotation Ref No <span className="red-text">*</span>
          </label>
          <p className="text-capitalize border-btm py-1">Q-0032</p>
        </Col>
        <Col md={6} lg={6} xs={12} className="pe-3 my-2">
          <label className="my-1">
            Quotation Date <span className="red-text">*</span>
          </label>
          <p className="text-capitalize border-btm py-1">20/12/2022</p>
        </Col>
      </div>
      <div className="d-flex gap-3">
        <Col md={6} lg={6} xs={12} className="pe-3 my-2">
          <label className="my-1">
            Currency <span className="red-text">*</span>
          </label>
          <p className="text-capitalize border-btm py-1">INR</p>
        </Col>
        <Col md={6} lg={6} xs={12} className="pe-3 my-2">
          <label className="my-1">Convention Rate to INR</label>
          <p className="text-capitalize border-btm py-1">1</p>
        </Col>
      </div>
      <div className="d-flex justify-content-end gap-2 d-flex-wrap my-3 py-2 fs-1r">
        <button className="text-blue border-0 fnt-fam-f2   wid-9r h-2_5r bg-light">
          Cancel
        </button>
        <button className="wid-9r rounded-1 wid-9_5r h-2_5r fnt-fam-f2  blue-trans-button">
          Save as Draft
        </button>
        <button className="bg-primary1 wid-9_5r h-2_5r fnt-fam-f2  text-white border-0 wid-9r rounded-1">
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default QuotationInfo;
