import { IoIosArrowRoundBack } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineFileJpg } from "react-icons/ai";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";

import "../Admin.scss";
import { Col } from "react-bootstrap";
function AdminCreateBroadcastComponent() {
  const date = "2023-01-12";
  return (
    <div className="admin-create-bc-cont my-3 py-2">
      <div className="d-md-flex gap-1 py-1 my-2 align-items-center">
        <Link to="/admin/broadcast/list">
          <IoIosArrowRoundBack className="primary1" />{" "}
        </Link>
        <h5 className="fs-15px fnt-fam-f2 primary1  mb-0">
          Create New Broadcast
        </h5>
      </div>
      <div className="d-sm-flex flex-wrap py-3">
        <Col lg={4} md={12} sm={12} className="py-1 my-1 bor-right pe-4">
          <p className="fnt-fam-f2 fs-14px">
            Select Suppliers: <span className="primary1">40</span>
          </p>
          <div className="py-1 my-1 fnt-fam-f2 fs-14px d-flex-al gap-2">
            <input type="checkbox" /> <span>Select All</span>
          </div>
          <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
              Source Type
            </label>
            <div className="d-sm-flex justify-content-between border-btm align-items-center">
              <select className="border-0 pb-1 my-1 no-apprence fs-14px">
                <option>Outsource</option>
              </select>
              <RiArrowDropDownLine className="fs-1r" />
            </div>
          </div>
          <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">Source</label>
            <div className="d-sm-flex justify-content-between border-btm align-items-center">
              <select className="border-0 pb-1 my-1 no-apprence ">
                <option>Domestic</option>
              </select>
              <RiArrowDropDownLine className="fs-1r" />
            </div>
          </div>
          <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
              Buisness Source
            </label>
            <div className="d-sm-flex justify-content-between border-btm align-items-center">
              <select className="border-0 pb-1 my-1 no-apprence fs-14px">
                <option>Manufacturer</option>
              </select>
              <RiArrowDropDownLine className="fs-1r" />
            </div>
          </div>
          <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">Category</label>
            <div className="d-sm-flex justify-content-between border-btm align-items-center">
              <select className="border-0 pb-1 my-1 no-apprence fs-14px">
                <option>Material & Leather</option>
              </select>
              <RiArrowDropDownLine className="fs-1r" />
            </div>
          </div>
          <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
              Material Type
            </label>
            <div className="d-sm-flex justify-content-between border-btm align-items-center">
              <select className="border-0 pb-1 my-1 no-apprence fs-14px">
                <option>Hardware</option>
              </select>
              <RiArrowDropDownLine className="fs-1r" />
            </div>
          </div>
          <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
              Material Type
            </label>
            <div className="">
              <div className="d-sm-flex justify-content-between border-btm align-items-center">
                <p className="fs-14px">$10002-Mohammed Bilal</p>

                {/* <select className="border-0 pb-1 my-1 no-apprence">
                <option>$10002-Mohammed Bilal</option>
              </select> */}
                <RiArrowDropDownLine className="fs-1r" />
              </div>
              <div className="w-100 cont-border px-2 ad-create-bc-drop-cont">
                <div
                  // style={{ width: "302px" }}
                  className="ad-table-search-box  d-flex gap-2 fnt-fam-f2 fs-13px  align-items-center my-2 px-2 rounded-1"
                >
                  <input placeholder="Search" />
                  <img className="search-icon" src={Search} />
                </div>
                <div className="my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$10002-Mohammed Bilal</span>
                </div>
                <div className=" my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$1003-Karthick</span>
                </div>
                <div className=" my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$10003-Mohammed Bilal</span>
                </div>
                <div className=" my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$10005-Mohammed Madeena</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={8} md={12} sm={12} className="py-1 my-1 px-2">
          <div>
            <label className="fnt-fam-f2 my-1 py-1">
              BroadCast Title<span className="red-text">*</span>
            </label>
            <p className="fnt-fam-f2 my-1 pt-1  pb-2 border-btm">
              Lorem ipsum dolor sit amet
            </p>
          </div>
          <div>
            <label className="fnt-fam-f2 my-1 py-1">
              Description<span className="red-text">*</span>
            </label>
            <p className="fnt-fam-f2 my-1 pt-1  pb-2 border-btm">
              Donec id nulla id magna venenatis commodo. Fusce finibus ex a erat
              tempor, in feugiat lorem fermentum. Praesent a arcu in dolor
              bibendum posuere nec id justo.
            </p>
          </div>
          <p className="d-sm-flex justify-content-end fs-15px t6 fnt-fam-f2">
            Max 2500
          </p>
          <label className="d-block py-2 my-1">
            Schedule Date<span className="red-text">*</span>
          </label>
          <Col
            lg={5}
            md={8}
            sm={12}
            className="ad-create-broadcast d-sm-flex justify-content-between border-btm me-auto"
          >
            <span className="fs-13px">
              {moment(date).format("DD-MM-YYYY")} 13:15PM
            </span>
            <input
              type="date"
              className="border-0"
              value={date}
              style={{ width: "50px" }}
            />
          </Col>
          <Col xs={12} className="pe-3 my-2 file_upload ad-file-upload">
            <h5 className="primary1 fs-15px">Documents</h5>
            <span className="ad-file-upload-text">
              Drag and drop your files here or Upload a file
            </span>

            <FileUploader multiple={true} name="file" className="pad-20px" />

            <span className="fs-13 fnt-fam-f2">
              Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to upload
              maximum of 1 upload
            </span>
          </Col>
          <div className="d-flex justify-content-between px-2 py-2 my-3 align-items-center file-upload-cont-bg wid-105-perc">
            <div className="d-flex gap-2">
              <AiOutlineFileJpg className="text-success fs-4" />
              BroadCast.jpeg
            </div>
            <div className="d-sm-flex gap-3 align-items-center">
              <h6 className="primary1 fs-14px fnt-fam-f2 mb-0">View</h6>
              <span className="create-inv-delete-icon-cont">
                <RiDeleteBin6Line />
              </span>
            </div>
          </div>
          <div className="d-flex mt-3 mb-4 justify-content-end gap-3 my-2 wid-105-perc">
            <button className="clear-btn  px-3 py-1 fnt-fam-f2 border-0 rounded-1 opac_half">
              Clear
            </button>
            <button className="bg-primary1 bg-primary1 fnt-fam-f2 px-3 py-1 text-white rounded-1 border-0">
              Publish
            </button>
          </div>
        </Col>
      </div>
    </div>
  );
}

export default AdminCreateBroadcastComponent;
