import { Pagination } from "react-bootstrap";

function GeneralPagination() {
  return (
    <div className="d-sm-flex justify-content-between mb-2 align-items-center">
      <div className="d-flex-wrap align-items-center gap-2 mb-0">
        <label>Show</label>
        <select name="" className="cont-border px-2 py-1">
          <option value="5">6</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        <label>1-5 of 20</label>
      </div>
      <div className="d-flex mt-2 mt-sm-0 enquiry-page-cont">
        <Pagination>
          <Pagination.First className="enquiry-pag-list" />
          <Pagination.Prev className="enquiry-pag-list" />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item> <Pagination.Ellipsis />
          <Pagination.Item>{20}</Pagination.Item>
          <Pagination.Next className="enquiry-pag-list" />
          <Pagination.Last className="enquiry-pag-list" />
        </Pagination>
      </div>
    </div>
  );
}

export default GeneralPagination;
