import React from "react";

const DropDown = ({ title }) => {
  return (
    <select>
      <option>{title}</option>
    </select>
  );
};

export default DropDown;
