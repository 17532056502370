import React from "react";
import TopHeader from "../navbar/TopHeader";
import LogInFooter from "../logincomponent/LogInFooter";
import NavMcp from "../component/NavMcp";
import AddCertiAward from "../component/AddCertiAward";
import { Link, useLocation } from "react-router-dom";
import { Col, Tab, Nav, Navbar } from "react-bootstrap";
const AddCertificate = ({}) => {
  const location = useLocation();
  const type = location?.state?.type;
  const id = location?.state?.id;
  // console.log(location?.state?.type);

  return (
    <div className="mcp-page">
      <TopHeader type="certificate" />
      <div className="m-2 d-flex-wrap  ">
        <Col xl={2} lg={3} md={4} xs={12} className="px-2 my-2">
          <Nav variant="pills" className="flex-column nav_mctap">
            <Nav.Item>
              <Nav.Link eventKey="inform">
                <Link to="/mcprofile" className="nav-link">
                  Basic Information
                </Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="transport">
                <Link to="/mcprofile/transport" className="nav-link">
                  Nearest Transport Points
                </Link>{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="certificate" className="active">
                <Link to="/mcprofile/certificate" className="nav-link">
                  Certificate/Awards{" "}
                </Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col lg={7} md={8} xs={12} className="px-3 my-2">
          <AddCertiAward type={type} id={id} />
        </Col>
      </div>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};
export default AddCertificate;
