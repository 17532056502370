import { Image, Modal } from "react-bootstrap";
import React, { useState } from "react";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import { useNavigate } from "react-router-dom";
import { FavLogo } from "../../assets/img";

function POMainAckModal({
  list,
  openCard,
  setOpenCard,
  ackModalSubmitHandler,
  POAckCloseHandler,
  data,
  type,
}) {
  const [activeBtn, setActiveBtn] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [capModal, setCapModal] = useState(false);

  const navigate = useNavigate();

  const detailData = [
    {
      terms: "Accept",
    },
    {
      terms: "Accept with Comments",
    },
    {
      terms: "Decline with Comments",
    },
  ];

  // console.log("data", data);

  const submitHandle = () => {
    let details = {};
    if (activeBtn == 0) {
      details.Approval = "A";
    } else if (activeBtn == 1) {
      details.Approval = "C";
      details.Remarks = remarks;
    } else if (activeBtn == 2) {
      details.Approval = "D";
      details.Remarks = remarks;
    }

    details.factory_id = list?.FactoryID;

    if (list?.MaterialPurchaseOrderID) {
      details.type = "M";
      details.purchase_order_id = list?.MaterialPurchaseOrderID;
    } else if (list?.LeatherPurchaseOrderID) {
      details.type = "L";
      details.purchase_order_id = list?.LeatherPurchaseOrderID;
    }
    // setBtn(true);

    if (
      (activeBtn == 1 && remarks?.length == 0) ||
      (activeBtn == 2 && remarks?.length == 0)
    ) {
      setCapModal(true);
    } else {
      api.approvalRemark(details).then((res) => {
        if (res?.status == "success") {
          ackModalSubmitHandler();
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/purchase-order/list");
          }, 800);
        } else {
          setError(res);
          setErrorBox(true);
        }
        setBtn(false);
      });
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const renderedRadioButtons = detailData.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => setActiveBtn(index)}
        className="d-flex align-items-center border-0 bg-transparent p-0 text-nowrap po-card-radio-btn"
      >
        <div className="radio-btn">
          {activeBtn == index ? (
            <img src={Radio_icon} className="wid-15px" />
          ) : (
            <div
              className="radio-btn-inner"
              style={{
                background: "#fff",
              }}
            ></div>
          )}
        </div>
        {item.terms}
      </button>
    );
  });
  return (
    <>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Modal
        id="#po-card-acknowledge"
        className="po-acknoweld-card-cont px-3 fnt-fam-f2  pt-3 pb-1"
        show={openCard}
        size="md"
        centered
        onHide={() => setOpenCard(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className=" d-flex justify-content-between pb-2 mt-3">
          <h5 className="primary1 fs-15px mb-3 fnt-fam-f2">Acknowledgement</h5>
          <span style={{ cursor: "pointer" }} onClick={POAckCloseHandler}>
            {" "}
            X{" "}
          </span>
        </div>
        <div className="d-flex flex-wrap gap-3 mb-2">
          {renderedRadioButtons}
        </div>
        {activeBtn == 1 || activeBtn == 2 ? (
          <>
            <label className="my-2">
              Comments<span className="red-text">*</span>
            </label>
            {/* <p className="border-btm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu
            lacinia massa. Donec at diam sed mauris sollicitudin dignissim
            placerat eu nisl. Curabitur faucibus consequat sodales.
          </p> */}
            <textarea
              className="border-btm"
              onChange={(e) => setRemarks(e.target.value)}
              maxLength={150}
            />
            <p className="d-flex justify-content-end opac-85">Max 150</p>
          </>
        ) : null}

        <div className="d-flex mt-4 mb-1 justify-content-end gap-3">
          <button
            className="clear-can-btn border-0 rounded-1 fnt-fam-f2"
            style={{ color: "black" }}
            onClick={() => ackModalSubmitHandler()}
          >
            Cancel
          </button>
          <button
            className={
              btn
                ? "bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f2 opacity-25"
                : "bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f2"
            }
            onClick={submitHandle}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        id="#po-card-acknowledge"
        className="po-acknoweld-card-cont px-3 fnt-fam-f2  pt-3 pb-1"
        show={capModal}
        size="md"
        centered
        onHide={() => setCapModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div
          className="new-modal"
          style={{ border: "1px solid gainsboro", borderRadius: "3px" }}
        >
          <Image className="fav-icon" src={FavLogo} />
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="px-2">
              <h5 className="fs-14" style={{ margin: "0" }}>
                Please Fill Comments
              </h5>
            </div>
          </div>

          <div className="d-flex-as-jc img_tick mb-2">
            <button
              className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
              onClick={() => setCapModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default POMainAckModal;
