// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { Link } from "react-router-dom";
import { CompanyImg, FavLogo, PersonImg } from "../../assets/img";
import { PersonLogo } from "../../assets/img";
import { api } from "../../constants/Services";
import useUser from "../../constants/Storage/userData";

const ProfileLeft = ({
  changeAdmin,
  setChangeAdmin,
  setPayment,
  payment,
  setAccountSett,
  accountSett,
  setAddBooking,
}) => {
  const permissions = localStorage.getItem("permissions");
  const [uploadImage, setUploadImage] = useState();
  const { user } = useUser();
  const [imageUrl, setImageUrl] = useState("");
  const [Country, setCountry] = useState();
  // console.log("user", user);
  // let usename = [user?.SPUD_FirstName, user?.SPUD_LastName];
  let usename = user?.SPUD_FirstName;
  const changeAdminHandler = () => {
    setChangeAdmin(true);
    setPayment(false);
    setAddBooking(false);
  };
  const onClickAccountHandler = () => {
    setChangeAdmin(false);
    setPayment(false);
    setAddBooking(false);
    setAccountSett(true);
  };
  const onClickPaymentHandler = () => {
    setChangeAdmin(false);
    setPayment(true);
    setAddBooking(false);
    setAccountSett(false);
  };

  const countries = [
    {
      country_id: 1,
      country_name: "India",
      country_code: "Ind",
    },
    {
      country_id: 2,
      country_name: "United States Of America (The)",
      country_code: "USA",
    },
    {
      country_id: 3,
      country_name: "Italy",
      country_code: "ITA",
    },
    {
      country_id: 4,
      country_name: "Sweden",
      country_code: "SWD",
    },
    {
      country_id: 5,
      country_name: "Korea (the Republic of)",
      country_code: "KOR",
    },
    {
      country_id: 7,
      country_name: "Denmark",
      country_code: "Den",
    },
    {
      country_id: 8,
      country_name: "Germany",
      country_code: "Ger",
    },
    {
      country_id: 9,
      country_name: "Spain",
      country_code: "ESP",
    },
    {
      country_id: 10,
      country_name: "Netherlands",
      country_code: "NLD",
    },
    {
      country_id: 11,
      country_name: "Afghanistan",
      country_code: "AFG",
    },
    {
      country_id: 12,
      country_name: "Albania",
      country_code: "ALB",
    },
    {
      country_id: 13,
      country_name: "Algeria",
      country_code: "DZA",
    },
    {
      country_id: 14,
      country_name: "American Samoa",
      country_code: "ASM",
    },
    {
      country_id: 15,
      country_name: "Andorra",
      country_code: "AND",
    },
    {
      country_id: 16,
      country_name: "Angola",
      country_code: "AGO",
    },
    {
      country_id: 17,
      country_name: "Anguilla",
      country_code: "AIA",
    },
    {
      country_id: 18,
      country_name: "Antarctica",
      country_code: "ATA",
    },
    {
      country_id: 19,
      country_name: "Antigua and Barbuda",
      country_code: "ATG",
    },
    {
      country_id: 20,
      country_name: "Argentina",
      country_code: "ARG",
    },
    {
      country_id: 21,
      country_name: "Armenia",
      country_code: "ARM",
    },
    {
      country_id: 22,
      country_name: "Aruba",
      country_code: "ABW",
    },
    {
      country_id: 23,
      country_name: "Australia",
      country_code: "AUS",
    },
    {
      country_id: 24,
      country_name: "Austria",
      country_code: "AUT",
    },
    {
      country_id: 25,
      country_name: "Azerbaijan",
      country_code: "AZE",
    },
    {
      country_id: 26,
      country_name: "Bahamas (the)",
      country_code: "BHS",
    },
    {
      country_id: 27,
      country_name: "Bahrain",
      country_code: "BHR",
    },
    {
      country_id: 28,
      country_name: "Bangladesh",
      country_code: "BGD",
    },
    {
      country_id: 29,
      country_name: "Barbados",
      country_code: "BRB",
    },
    {
      country_id: 30,
      country_name: "Belarus",
      country_code: "BLR",
    },
    {
      country_id: 31,
      country_name: "Belgium",
      country_code: "BEL",
    },
    {
      country_id: 32,
      country_name: "Belize",
      country_code: "BLZ",
    },
    {
      country_id: 33,
      country_name: "Benin",
      country_code: "BEN",
    },
    {
      country_id: 34,
      country_name: "Bermuda",
      country_code: "BMU",
    },
    {
      country_id: 35,
      country_name: "Bhutan",
      country_code: "BTN",
    },
    {
      country_id: 36,
      country_name: "Bolivia (Plurinational State of)",
      country_code: "BOL",
    },
    {
      country_id: 37,
      country_name: "Bonaire, Sint Eustatius and Saba",
      country_code: "BES",
    },
    {
      country_id: 38,
      country_name: "Bosnia and Herzegovina",
      country_code: "BIH",
    },
    {
      country_id: 39,
      country_name: "Botswana",
      country_code: "BWA",
    },
    {
      country_id: 40,
      country_name: "Bouvet Island",
      country_code: "BVT",
    },
    {
      country_id: 41,
      country_name: "Brazil",
      country_code: "BRA",
    },
    {
      country_id: 42,
      country_name: "British Indian Ocean Territory (the)",
      country_code: "IOT",
    },
    {
      country_id: 43,
      country_name: "Brunei Darussalam",
      country_code: "BRN",
    },
    {
      country_id: 44,
      country_name: "Bulgaria",
      country_code: "BGR",
    },
    {
      country_id: 45,
      country_name: "Burkina Faso",
      country_code: "BFA",
    },
    {
      country_id: 46,
      country_name: "Burundi",
      country_code: "BDI",
    },
    {
      country_id: 47,
      country_name: "Cabo Verde",
      country_code: "CPV",
    },
    {
      country_id: 48,
      country_name: "Cambodia",
      country_code: "KHM",
    },
    {
      country_id: 49,
      country_name: "Cameroon",
      country_code: "CMR",
    },
    {
      country_id: 50,
      country_name: "Canada",
      country_code: "CAN",
    },
    {
      country_id: 51,
      country_name: "Cayman Islands (the)",
      country_code: "CYM",
    },
    {
      country_id: 52,
      country_name: "Central African Republic (the)",
      country_code: "CAF",
    },
    {
      country_id: 53,
      country_name: "Chad",
      country_code: "TCD",
    },
    {
      country_id: 54,
      country_name: "Chile",
      country_code: "CHL",
    },
    {
      country_id: 55,
      country_name: "China",
      country_code: "CHN",
    },
    {
      country_id: 56,
      country_name: "Christmas Island",
      country_code: "CXR",
    },
    {
      country_id: 57,
      country_name: "Cocos (Keeling) Islands (the)",
      country_code: "CCK",
    },
    {
      country_id: 58,
      country_name: "Colombia",
      country_code: "COL",
    },
    {
      country_id: 59,
      country_name: "Comoros (the)",
      country_code: "COM",
    },
    {
      country_id: 60,
      country_name: "Congo (the Democratic Republic of the)",
      country_code: "COD",
    },
    {
      country_id: 61,
      country_name: "Congo (the)",
      country_code: "COG",
    },
    {
      country_id: 62,
      country_name: "Cook Islands (the)",
      country_code: "COK",
    },
    {
      country_id: 63,
      country_name: "Costa Rica",
      country_code: "CRI",
    },
    {
      country_id: 64,
      country_name: "Croatia",
      country_code: "HRV",
    },
    {
      country_id: 65,
      country_name: "Cuba",
      country_code: "CUB",
    },
    {
      country_id: 66,
      country_name: "Cura\u83bdao",
      country_code: "CUW",
    },
    {
      country_id: 67,
      country_name: "Cyprus",
      country_code: "CYP",
    },
    {
      country_id: 68,
      country_name: "Czechia",
      country_code: "CZE",
    },
    {
      country_id: 69,
      country_name: "C\u4e48te d'Ivoire",
      country_code: "CIV",
    },
    {
      country_id: 70,
      country_name: "Djibouti",
      country_code: "DJI",
    },
    {
      country_id: 71,
      country_name: "Dominica",
      country_code: "DMA",
    },
    {
      country_id: 72,
      country_name: "Dominican Republic (the)",
      country_code: "DOM",
    },
    {
      country_id: 73,
      country_name: "Ecuador",
      country_code: "ECU",
    },
    {
      country_id: 74,
      country_name: "Egypt",
      country_code: "EGY",
    },
    {
      country_id: 75,
      country_name: "El Salvador",
      country_code: "SLV",
    },
    {
      country_id: 76,
      country_name: "Equatorial Guinea",
      country_code: "GNQ",
    },
    {
      country_id: 77,
      country_name: "Eritrea",
      country_code: "ERI",
    },
    {
      country_id: 78,
      country_name: "Estonia",
      country_code: "EST",
    },
    {
      country_id: 79,
      country_name: "Eswatini",
      country_code: "SWZ",
    },
    {
      country_id: 80,
      country_name: "Ethiopia",
      country_code: "ETH",
    },
    {
      country_id: 81,
      country_name: "Falkland Islands (the) [Malvinas]",
      country_code: "FLK",
    },
    {
      country_id: 82,
      country_name: "Faroe Islands (the)",
      country_code: "FRO",
    },
    {
      country_id: 83,
      country_name: "Fiji",
      country_code: "FJI",
    },
    {
      country_id: 84,
      country_name: "Finland",
      country_code: "FIN",
    },
    {
      country_id: 85,
      country_name: "France",
      country_code: "FRA",
    },
    {
      country_id: 86,
      country_name: "French Guiana",
      country_code: "GUF",
    },
    {
      country_id: 87,
      country_name: "French Polynesia",
      country_code: "PYF",
    },
    {
      country_id: 88,
      country_name: "French Southern Territories (the)",
      country_code: "ATF",
    },
    {
      country_id: 89,
      country_name: "Gabon",
      country_code: "GAB",
    },
    {
      country_id: 90,
      country_name: "Gambia (the)",
      country_code: "GMB",
    },
    {
      country_id: 91,
      country_name: "Georgia",
      country_code: "GEO",
    },
    {
      country_id: 92,
      country_name: "Ghana",
      country_code: "GHA",
    },
    {
      country_id: 93,
      country_name: "Gibraltar",
      country_code: "GIB",
    },
    {
      country_id: 94,
      country_name: "Greece",
      country_code: "GRC",
    },
    {
      country_id: 95,
      country_name: "Greenland",
      country_code: "GRL",
    },
    {
      country_id: 96,
      country_name: "Grenada",
      country_code: "GRD",
    },
    {
      country_id: 97,
      country_name: "Guadeloupe",
      country_code: "GLP",
    },
    {
      country_id: 98,
      country_name: "Guam",
      country_code: "GUM",
    },
    {
      country_id: 99,
      country_name: "Guatemala",
      country_code: "GTM",
    },
    {
      country_id: 100,
      country_name: "Guernsey",
      country_code: "GGY",
    },
    {
      country_id: 101,
      country_name: "Guinea",
      country_code: "GIN",
    },
    {
      country_id: 102,
      country_name: "Guinea-Bissau",
      country_code: "GNB",
    },
    {
      country_id: 103,
      country_name: "Guyana",
      country_code: "GUY",
    },
    {
      country_id: 104,
      country_name: "Haiti",
      country_code: "HTI",
    },
    {
      country_id: 105,
      country_name: "Heard Island and McDonald Islands",
      country_code: "HMD",
    },
    {
      country_id: 106,
      country_name: "Holy See (the)",
      country_code: "VAT",
    },
    {
      country_id: 107,
      country_name: "Honduras",
      country_code: "HND",
    },
    {
      country_id: 108,
      country_name: "Hong Kong",
      country_code: "HKG",
    },
    {
      country_id: 109,
      country_name: "Hungary",
      country_code: "HUN",
    },
    {
      country_id: 110,
      country_name: "Iceland",
      country_code: "ISL",
    },
    {
      country_id: 111,
      country_name: "Indonesia",
      country_code: "IDN",
    },
    {
      country_id: 112,
      country_name: "Iran (Islamic Republic of)",
      country_code: "IRN",
    },
    {
      country_id: 113,
      country_name: "Iraq",
      country_code: "IRQ",
    },
    {
      country_id: 114,
      country_name: "Ireland",
      country_code: "IRL",
    },
    {
      country_id: 115,
      country_name: "Isle of Man",
      country_code: "IMN",
    },
    {
      country_id: 116,
      country_name: "Israel",
      country_code: "ISR",
    },
    {
      country_id: 117,
      country_name: "Jamaica",
      country_code: "JAM",
    },
    {
      country_id: 118,
      country_name: "Japan",
      country_code: "JPN",
    },
    {
      country_id: 119,
      country_name: "Jersey",
      country_code: "JEY",
    },
    {
      country_id: 120,
      country_name: "Jordan",
      country_code: "JOR",
    },
    {
      country_id: 121,
      country_name: "Kazakhstan",
      country_code: "KAZ",
    },
    {
      country_id: 122,
      country_name: "Kenya",
      country_code: "KEN",
    },
    {
      country_id: 123,
      country_name: "Kiribati",
      country_code: "KIR",
    },
    {
      country_id: 124,
      country_name: "Korea (the Democratic People's Republic of)",
      country_code: "PRK",
    },
    {
      country_id: 126,
      country_name: "Kuwait",
      country_code: "KWT",
    },
    {
      country_id: 127,
      country_name: "Kyrgyzstan",
      country_code: "KGZ",
    },
    {
      country_id: 128,
      country_name: "Lao People's Democratic Republic (the)",
      country_code: "LAO",
    },
    {
      country_id: 129,
      country_name: "Latvia",
      country_code: "LVA",
    },
    {
      country_id: 130,
      country_name: "Lebanon",
      country_code: "LBN",
    },
    {
      country_id: 131,
      country_name: "Lesotho",
      country_code: "LSO",
    },
    {
      country_id: 132,
      country_name: "Liberia",
      country_code: "LBR",
    },
    {
      country_id: 133,
      country_name: "Libya",
      country_code: "LBY",
    },
    {
      country_id: 134,
      country_name: "Liechtenstein",
      country_code: "LIE",
    },
    {
      country_id: 135,
      country_name: "Lithuania",
      country_code: "LTU",
    },
    {
      country_id: 136,
      country_name: "Luxembourg",
      country_code: "LUX",
    },
    {
      country_id: 137,
      country_name: "Macao",
      country_code: "MAC",
    },
    {
      country_id: 138,
      country_name: "Madagascar",
      country_code: "MDG",
    },
    {
      country_id: 139,
      country_name: "Malawi",
      country_code: "MWI",
    },
    {
      country_id: 140,
      country_name: "Malaysia",
      country_code: "MYS",
    },
    {
      country_id: 141,
      country_name: "Maldives",
      country_code: "MDV",
    },
    {
      country_id: 142,
      country_name: "Mali",
      country_code: "MLI",
    },
    {
      country_id: 143,
      country_name: "Malta",
      country_code: "MLT",
    },
    {
      country_id: 144,
      country_name: "Marshall Islands (the)",
      country_code: "MHL",
    },
    {
      country_id: 145,
      country_name: "Martinique",
      country_code: "MTQ",
    },
    {
      country_id: 146,
      country_name: "Mauritania",
      country_code: "MRT",
    },
    {
      country_id: 147,
      country_name: "Mauritius",
      country_code: "MUS",
    },
    {
      country_id: 148,
      country_name: "Mayotte",
      country_code: "MYT",
    },
    {
      country_id: 149,
      country_name: "Mexico",
      country_code: "MEX",
    },
    {
      country_id: 150,
      country_name: "Micronesia (Federated States of)",
      country_code: "FSM",
    },
    {
      country_id: 151,
      country_name: "Moldova (the Republic of)",
      country_code: "MDA",
    },
    {
      country_id: 152,
      country_name: "Monaco",
      country_code: "MCO",
    },
    {
      country_id: 153,
      country_name: "Mongolia",
      country_code: "MNG",
    },
    {
      country_id: 154,
      country_name: "Montenegro",
      country_code: "MNE",
    },
    {
      country_id: 155,
      country_name: "Montserrat",
      country_code: "MSR",
    },
    {
      country_id: 156,
      country_name: "Morocco",
      country_code: "MAR",
    },
    {
      country_id: 157,
      country_name: "Mozambique",
      country_code: "MOZ",
    },
    {
      country_id: 158,
      country_name: "Myanmar",
      country_code: "MMR",
    },
    {
      country_id: 159,
      country_name: "Namibia",
      country_code: "NAM",
    },
    {
      country_id: 160,
      country_name: "Nauru",
      country_code: "NRU",
    },
    {
      country_id: 161,
      country_name: "Nepal",
      country_code: "NPL",
    },
    {
      country_id: 162,
      country_name: "Netherlands (the)",
      country_code: "NLD",
    },
    {
      country_id: 163,
      country_name: "New Caledonia",
      country_code: "NCL",
    },
    {
      country_id: 164,
      country_name: "New Zealand",
      country_code: "NZL",
    },
    {
      country_id: 165,
      country_name: "Nicaragua",
      country_code: "NIC",
    },
    {
      country_id: 166,
      country_name: "Niger (the)",
      country_code: "NER",
    },
    {
      country_id: 167,
      country_name: "Nigeria",
      country_code: "NGA",
    },
    {
      country_id: 168,
      country_name: "Niue",
      country_code: "NIU",
    },
    {
      country_id: 169,
      country_name: "Norfolk Island",
      country_code: "NFK",
    },
    {
      country_id: 170,
      country_name: "Northern Mariana Islands (the)",
      country_code: "MNP",
    },
    {
      country_id: 171,
      country_name: "Norway",
      country_code: "NOR",
    },
    {
      country_id: 172,
      country_name: "Oman",
      country_code: "OMN",
    },
    {
      country_id: 173,
      country_name: "Pakistan",
      country_code: "PAK",
    },
    {
      country_id: 174,
      country_name: "Palau",
      country_code: "PLW",
    },
    {
      country_id: 175,
      country_name: "Palestine, State of",
      country_code: "PSE",
    },
    {
      country_id: 176,
      country_name: "Panama",
      country_code: "PAN",
    },
    {
      country_id: 177,
      country_name: "Papua New Guinea",
      country_code: "PNG",
    },
    {
      country_id: 178,
      country_name: "Paraguay",
      country_code: "PRY",
    },
    {
      country_id: 179,
      country_name: "Peru",
      country_code: "PER",
    },
    {
      country_id: 180,
      country_name: "Philippines (the)",
      country_code: "PHL",
    },
    {
      country_id: 181,
      country_name: "Pitcairn",
      country_code: "PCN",
    },
    {
      country_id: 182,
      country_name: "Poland",
      country_code: "POL",
    },
    {
      country_id: 183,
      country_name: "Portugal",
      country_code: "PRT",
    },
    {
      country_id: 184,
      country_name: "Puerto Rico",
      country_code: "PRI",
    },
    {
      country_id: 185,
      country_name: "Qatar",
      country_code: "QAT",
    },
    {
      country_id: 186,
      country_name: "Republic of North Macedonia",
      country_code: "MKD",
    },
    {
      country_id: 187,
      country_name: "Romania",
      country_code: "ROU",
    },
    {
      country_id: 188,
      country_name: "Russian Federation (the)",
      country_code: "RUS",
    },
    {
      country_id: 189,
      country_name: "Rwanda",
      country_code: "RWA",
    },
    {
      country_id: 190,
      country_name: "R\u8305union",
      country_code: "REU",
    },
    {
      country_id: 191,
      country_name: "Saint Barth\u8305lemy",
      country_code: "BLM",
    },
    {
      country_id: 192,
      country_name: "Saint Helena, Ascension and Tristan da Cunha",
      country_code: "SHN",
    },
    {
      country_id: 193,
      country_name: "Saint Kitts and Nevis",
      country_code: "KNA",
    },
    {
      country_id: 194,
      country_name: "Saint Lucia",
      country_code: "LCA",
    },
    {
      country_id: 195,
      country_name: "Saint Martin (French part)",
      country_code: "MAF",
    },
    {
      country_id: 196,
      country_name: "Saint Pierre and Miquelon",
      country_code: "SPM",
    },
    {
      country_id: 197,
      country_name: "Saint Vincent and the Grenadines",
      country_code: "VCT",
    },
    {
      country_id: 198,
      country_name: "Samoa",
      country_code: "WSM",
    },
    {
      country_id: 199,
      country_name: "San Marino",
      country_code: "SMR",
    },
    {
      country_id: 200,
      country_name: "Sao Tome and Principe",
      country_code: "STP",
    },
    {
      country_id: 201,
      country_name: "Saudi Arabia",
      country_code: "SAU",
    },
    {
      country_id: 202,
      country_name: "Senegal",
      country_code: "SEN",
    },
    {
      country_id: 203,
      country_name: "Serbia",
      country_code: "SRB",
    },
    {
      country_id: 204,
      country_name: "Seychelles",
      country_code: "SYC",
    },
    {
      country_id: 205,
      country_name: "Sierra Leone",
      country_code: "SLE",
    },
    {
      country_id: 206,
      country_name: "Singapore",
      country_code: "SGP",
    },
    {
      country_id: 207,
      country_name: "Sint Maarten (Dutch part)",
      country_code: "SXM",
    },
    {
      country_id: 208,
      country_name: "Slovakia",
      country_code: "SVK",
    },
    {
      country_id: 209,
      country_name: "Slovenia",
      country_code: "SVN",
    },
    {
      country_id: 210,
      country_name: "Solomon Islands",
      country_code: "SLB",
    },
    {
      country_id: 211,
      country_name: "Somalia",
      country_code: "SOM",
    },
    {
      country_id: 212,
      country_name: "South Africa",
      country_code: "ZAF",
    },
    {
      country_id: 213,
      country_name: "South Georgia and the South Sandwich Islands",
      country_code: "SGS",
    },
    {
      country_id: 214,
      country_name: "South Sudan",
      country_code: "SSD",
    },
    {
      country_id: 215,
      country_name: "Sri Lanka",
      country_code: "LKA",
    },
    {
      country_id: 216,
      country_name: "Sudan (the)",
      country_code: "SDN",
    },
    {
      country_id: 217,
      country_name: "Suriname",
      country_code: "SUR",
    },
    {
      country_id: 218,
      country_name: "Svalbard and Jan Mayen",
      country_code: "SJM",
    },
    {
      country_id: 219,
      country_name: "Switzerland",
      country_code: "CHE",
    },
    {
      country_id: 220,
      country_name: "Syrian Arab Republic",
      country_code: "SYR",
    },
    {
      country_id: 221,
      country_name: "Taiwan (Province of China)",
      country_code: "TWN",
    },
    {
      country_id: 222,
      country_name: "Tajikistan",
      country_code: "TJK",
    },
    {
      country_id: 223,
      country_name: "Tanzania, United Republic of",
      country_code: "TZA",
    },
    {
      country_id: 224,
      country_name: "Thailand",
      country_code: "THA",
    },
    {
      country_id: 225,
      country_name: "Timor-Leste",
      country_code: "TLS",
    },
    {
      country_id: 226,
      country_name: "Togo",
      country_code: "TGO",
    },
    {
      country_id: 227,
      country_name: "Tokelau",
      country_code: "TKL",
    },
    {
      country_id: 228,
      country_name: "Tonga",
      country_code: "TON",
    },
    {
      country_id: 229,
      country_name: "Trinidad and Tobago",
      country_code: "TTO",
    },
    {
      country_id: 230,
      country_name: "Tunisia",
      country_code: "TUN",
    },
    {
      country_id: 231,
      country_name: "Turkey",
      country_code: "TUR",
    },
    {
      country_id: 232,
      country_name: "Turkmenistan",
      country_code: "TKM",
    },
    {
      country_id: 233,
      country_name: "Turks and Caicos Islands (the)",
      country_code: "TCA",
    },
    {
      country_id: 234,
      country_name: "Tuvalu",
      country_code: "TUV",
    },
    {
      country_id: 235,
      country_name: "Uganda",
      country_code: "UGA",
    },
    {
      country_id: 236,
      country_name: "Ukraine",
      country_code: "UKR",
    },
    {
      country_id: 237,
      country_name: "United Arab Emirates (the)",
      country_code: "ARE",
    },
    {
      country_id: 238,
      country_name: "United Kingdom of Great Britain",
      country_code: "GBR",
    },
    {
      country_id: 239,
      country_name: "United States Minor Outlying Islands (the)",
      country_code: "UMI",
    },
    {
      country_id: 241,
      country_name: "Uruguay",
      country_code: "URY",
    },
    {
      country_id: 242,
      country_name: "Uzbekistan",
      country_code: "UZB",
    },
    {
      country_id: 243,
      country_name: "Vanuatu",
      country_code: "VUT",
    },
    {
      country_id: 244,
      country_name: "Venezuela (Bolivarian Republic of)",
      country_code: "VEN",
    },
    {
      country_id: 245,
      country_name: "Viet Nam",
      country_code: "VNM",
    },
    {
      country_id: 246,
      country_name: "Virgin Islands (British)",
      country_code: "VGB",
    },
    {
      country_id: 247,
      country_name: "Virgin Islands (U.S.)",
      country_code: "VIR",
    },
    {
      country_id: 248,
      country_name: "Wallis and Futuna",
      country_code: "WLF",
    },
    {
      country_id: 249,
      country_name: "Western Sahara",
      country_code: "ESH",
    },
    {
      country_id: 250,
      country_name: "Yemen",
      country_code: "YEM",
    },
    {
      country_id: 251,
      country_name: "Zambia",
      country_code: "ZMB",
    },
    {
      country_id: 252,
      country_name: "Zimbabwe",
      country_code: "ZWE",
    },
    {
      country_id: 253,
      country_name: "Aland Islands",
      country_code: "Ala",
    },
    {
      country_id: 256,
      country_name: "Testing",
      country_code: "TST",
    },
  ];

  const profileView = () => {
    api.profileView().then((res) => {
      if (res?.status == "success") {
        setImageUrl(res?.supplier?.img_url);
      }
    });
  };

  useEffect(() => {
    let temp = countries.filter(
      (i) => i?.country_id == user?.supplier?.CountryID
    );
    if (temp) {
      setCountry(temp[0]);
    }

    profileView();
  }, []);

  return (
    <div
      className="left-add-user lin-rit-lg-profile account-settings-left"
      style={{
        width: "230px",
      }}
    >
      <div
        className="upload-img mx-md-auto"
        style={{
          borderWidth: uploadImage ? "1px" : "0px",
          height: "fit-content",
        }}
      ></div>
      <div className="left-txts text-md-center">
        <div className="">
          <Image
            src={imageUrl ? imageUrl : FavLogo}
            style={{ borderRadius: "100%", width: "70px", height: "60px" }}
            className="image-placeholder"
          />
        </div>
        <p className="mt-2 text-dark">
          {/* <b>{usename.toString()}</b> */}
          <b>{usename}</b>
        </p>
        <p className="mt-0 primary1 admin-p">
          {user?.role?.code} - Primary Contact
        </p>
        <p className="mt-1">
          {/* <Image
            style={{ width: "20px", marginRight: "8px" }}
            src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg"
          /> */}
          {Country?.country_name}
        </p>
        {user?.is_primary == 2 ? null : (
          <button
            style={{ opacity: changeAdmin ? 0.2 : 1 }}
            onClick={changeAdminHandler}
            className="change-admin-btn bg-primary1 text-white rounded-1 mt-2"
            disabled={!changeAdmin ? false : true}
          >
            Change Admin
          </button>
        )}
      </div>
      <ul className="prof-left">
        <li className="ps-lg-2">
          <button
            onClick={onClickAccountHandler}
            className={accountSett ? "cust-btn primary1 ps-0" : "cust-btn ps-0"}
          >
            <b>User Settings</b>
          </button>
        </li>
        {permissions?.includes("LIST-PAYMENT SETTINGS") && (
          <li className="ps-lg-2">
            <button
              onClick={onClickPaymentHandler}
              className={payment ? "cust-btn primary1 ps-0" : "cust-btn ps-0"}
            >
              <b>Payment Settings</b>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ProfileLeft;
