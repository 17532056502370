import { Col, Image } from "react-bootstrap";
import TopBar from "../navbar/TopBar";
import { FavLogo } from "../assets/img";
import TopHeader from "../navbar/TopHeader";
import Dropdown from "react-bootstrap/Dropdown";
import { RxDotsVertical } from "react-icons/rx";
import LogInFooter from "../logincomponent/LogInFooter";
import { RxFilePlus } from "react-icons/rx";
import { AiOutlineFilePdf } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { FiDownload } from "react-icons/fi";
import { useEffect, useState } from "react";
import { api } from "../constants/Services";

function Notification() {
  const [list, setList] = useState([]);

  const getList = () => {
    api.getNotify().then((res) => {
      setList(res?.notifications);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <TopBar />
      <TopHeader type="notification" />
      <div className="notification-cont my-5">
        <div className="flex-resposive">
          <Col sm={12} xs={12} lg={4} md={4} className="my-2">
            <div className="d-flex px-3 py-1 justify-content-between">
              <Col sm={8} className="my-2 p-0">
                <h6 className="my-1 ">Notification</h6>
                <p className="my-1">
                  <span>382 Messages</span>
                  <span>120 Unreads</span>
                </p>
              </Col>
              <Col sm={4} className="p-3">
                {" "}
                <RxFilePlus className="adduser-btn" />
              </Col>
            </div>
            <div className="search-bar-cont d-flex align-items-center p-1 mx-3 my-1">
              <Col sm={1}>
                <CiSearch />
              </Col>
              <Col sm={11}>
                <input
                  type="search"
                  id="form1"
                  class="form-control search-field"
                  placeholder="Search Notification"
                  aria-label="Search"
                />
              </Col>
            </div>
            <p className="d-flex align-items-center m-3">
              <h5 className="m-0">Unread</h5> <span className="px-2">10</span>
            </p>
            <div className="notifi-detail-header-cont">
              <div className="d-flex justify-content-between">
                <Col sm={8} className="d-flex">
                  <Image className="w-25 p-2" src={FavLogo} />
                  <div>
                    <h6>Enquirey</h6>
                    <p>Kh Group (Division)</p>
                  </div>
                </Col>
                <Col sm={4} className="d-flex justify-content-end p-2">
                  <p className="fs-time"> 4.40 AM</p>
                </Col>
              </div>
              <p className="p-2">
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney
              </p>
            </div>{" "}
            <div className="notifi-detail-header-cont">
              <div className="d-flex justify-content-between">
                <Col sm={8} className="d-flex">
                  <Image className="w-25 p-2" src={FavLogo} />
                  <div>
                    <h6>Enquirey</h6>
                    <p>Kh Group (Division)</p>
                  </div>
                </Col>
                <Col sm={4} className="d-flex justify-content-end p-2">
                  <p className="fs-time"> 4.40 AM</p>
                </Col>
              </div>
              <p className="p-2">
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney
              </p>
            </div>{" "}
            <div className="notifi-detail-header-cont">
              <div className="d-flex justify-content-between">
                <Col sm={8} className="d-flex">
                  <Image className="w-25 p-2" src={FavLogo} />
                  <div>
                    <h6>Enquirey</h6>
                    <p>Kh Group (Division)</p>
                  </div>
                </Col>
                <Col sm={4} className="d-flex justify-content-end p-2">
                  <p className="fs-time"> 4.40 AM</p>
                </Col>
              </div>
              <p className="p-2">
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney
              </p>
            </div>{" "}
            <div className="notifi-detail-header-cont">
              <div className="d-flex justify-content-between">
                <Col sm={8} className="d-flex">
                  <Image className="w-25 p-2" src={FavLogo} />
                  <div>
                    <h6>Enquirey</h6>
                    <p>Kh Group (Division)</p>
                  </div>
                </Col>
                <Col sm={4} className="d-flex justify-content-end p-2">
                  <p className="fs-time"> 4.40 AM</p>
                </Col>
              </div>
              <p className="p-2">
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney
              </p>
            </div>
          </Col>
          <Col sm={12} xs={12} lg={8} md={8} className="notifi-detail-cont">
            {" "}
            <div className="d-flex justify-content-between notifi-detail-header-cont">
              <Col sm={8} className="d-flex">
                <div className="logo_img">
                  <Image src={FavLogo} />
                </div>
                <div>
                  <h5>Enquirey</h5>
                  <p>January 02 2023-10.43</p>
                </div>
              </Col>
              <Col sm={4} className="d-flex justify-content-end p-2">
                <div className="department-dropdown-cont">
                  <Dropdown classname="card-dropdown">
                    <Dropdown.Toggle id="dropdown-basic" variant="light">
                      <RxDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark">
                      <Dropdown.Item href="#/action-1">Bookmark</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <span className="primary2"> Delete</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </div>
            <div className="my-1 p-3 notifi-detail-header-cont">
              <h5 className="p-1">Mohammed M</h5>
              <p className="p-1">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </p>
            </div>
            <div className="p-3">
              <p className="p-1 d-flex justify-content-between">
                <Col sm={8} xs={8}>
                  <h5>Attachments</h5>
                </Col>
                <Col sm={4} xs={4}>
                  <p className="font-blue">Download all</p>
                </Col>
              </p>
            </div>
            <div className="d-flex-wrap p-3 gap-3 ">
              <div className="d-flex justify-content-between pdf-cont p-2">
                <div className="d-flex gap-1">
                  <div className="d-flex align-items-center pdf-icon-cont">
                    <AiOutlineFilePdf />
                  </div>
                  <div className="d-flex flex-lg-column">
                    <p>PDF-1</p>
                    <p>25 Kb</p>
                  </div>
                </div>
                <div className="down-icon-cont">
                  <FiDownload />
                </div>
              </div>
              <div className="d-flex justify-content-between pdf-cont p-2">
                <div className="d-flex gap-1">
                  <div className="d-flex align-items-center pdf-icon-cont">
                    <AiOutlineFilePdf />
                  </div>
                  <div className="d-flex flex-lg-column">
                    <p>PDF-1</p>
                    <p>25 Kb</p>
                  </div>
                </div>
                <div className="down-icon-cont">
                  <FiDownload />
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default Notification;
