import React, { useEffect, useState } from "react";
import { Col, Image, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa";
import TableASN from "../../../component/ASN/TableASN";
import TopASN from "../../../component/ASN/TopASN";
import GeneralPagination from "../../../component/GeneralPagination";
import ASNList from "../../../component/PurchaseOrder/ASNList";
import POFilter from "../../../component/PurchaseOrder/POFilter";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import Calendar_Icon from "../../../assets/Icons/Normal Icons/Calender.svg";
import ModalFilter from "../../../component/ASN/ModalFilter";
import ShippmentMenu1 from "../../../component/ASN/ShippmentMenu1";
import SearchTitle from "../../../component/ProductList/SearchTitle";
import AsnAddExtraContainer from "../../../component/ASN/AddExtraContainer";
import { asnTableData } from "../../../layout/dummyData";
import Ship_Icon from "../../../assets/Icons/ASN/Ship.svg";
import Plane_Icon from "../../../assets/Icons/ASN/Airways.svg";
import Train_Icon from "../../../assets/Icons/ASN/Train.svg";
import Lorry_Icon from "../../../assets/Icons/ASN/Road.svg";
import Search from "../../../assets/Icons/Normal Icons/Search.svg";
import { api } from "../../../constants/Services";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import ErrorModel from "../../../constants/Services/Api/errorModel";
import moment from "moment/moment";
import { FaTimes } from "react-icons/fa";
import { FavLogo } from "../../../assets/img";
import Datepicker from "react-datepicker";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const ASNScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data; // PO TO ASN
  const table = location?.state?.table; // ASN TABLE TO ASN
  const types = location?.state?.type;
  console.log(location, "table");
  const [showModal, setShowModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState(null);
  const [addExtraContShow, setAddExtraContShow] = useState(false);
  const [productName, setProductName] = useState(null);
  const [list, setList] = useState([]);
  const [add, setAdd] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [errorBoxs, setErrorBoxs] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shipmentDate, setShipmentDate] = useState([]);
  const [delDates, setDelDates] = useState([]);
  const [saveBtn, setSaveBtn] = useState(false);

  const [currentBtnDisplay, setCurrentBtnDisplay] = useState("save");
  const [showDropdown, setShowDropDown] = useState("");

  const [asnNo, setAsnNo] = useState("");
  const [asnDate, setAsnDate] = useState([]);

  // console.log("types", types);
  // console.log("asnDate", list?.purchase_order_details);

  // MODEL

  const [shipmentQuty, setshipmentQuty] = useState();
  const [UOM, setUOM] = useState();
  const [UOMs, setUOMs] = useState();
  const [ExWorkDate, setExWorkDate] = useState({});
  const [ExWorkDate2, setExWorkDate2] = useState("");
  const [ExpectedtimeofDispatch, setExpectedtimeofDispatch] = useState({});
  const [ExpectedtimeofArrival, setExpectedtimeofArrival] = useState({});
  const [modeShipment, setModeShipment] = useState({});
  const [Remarks, setRemarks] = useState({});
  const [counts, setCounts] = useState([]);
  const [countDate, setCountDate] = useState([]);
  const [btn, setBtn] = useState(false);
  const [deliveryplanid, setDeliveryplanid] = useState();
  const [indexStore, setIndexStore] = useState();
  const [skuCode, setSkuCode] = useState({});

  const [ExpectedtimeofDispatch2, setExpectedtimeofDispatch2] = useState("");
  const [ExpectedtimeofArrival2, setExpectedtimeofArrival2] = useState("");
  const [modeShipment2, setModeShipment2] = useState("");
  const [Remarks2, setRemarks2] = useState("");
  const [capModal, setCapModal] = useState(false);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const [solingSheet, setSolingSheet] = useState();

  const asnViewToolTipHandler = (ind) => {
    if (ind == solingSheet) {
      setSolingSheet();
    } else {
      setSolingSheet(ind);
    }
  };

  const asnSaveClick = () => setCurrentBtnDisplay("submit");

  // console.log("table", table);

  // TABLE DATES

  const [group, setGroup] = useState([]);

  const getPoGroup = () => {
    let formdata = new FormData();

    formdata.append("factory_id", table?.FactoryID);
    formdata.append("type", table?.type);
    formdata.append("po_id", table?.purchase_order_id);

    api.asnPoGroup(formdata).then((res) => {
      if (res?.status == "success") {
        let temp = [];
        res?.shipment_details?.map((item, index) => {
          temp.push({
            date: moment(item?.date).format("DD-MMM-YYYY"),
            detail: item?.detail,
            shipment_detail: item?.shipment_detail,
            shipment_mode_id: item?.shipment_mode_id,
            type: item?.type,
          });
        });

        temp.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

        // setGroup(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getDelDate = (viewData, index) => {
    // console.log("viewData", viewData);
    if (shipmentModal == index) {
      setShipmentModal(null);
    } else {
      setShipmentModal(index);
      setDelDates(viewData);
    }
  };

  useEffect(() => {
    // if (table) {
    //   getPoGroup();
    // }
  }, []);

  // TABLE BUTTON CLICK

  const asnAddClickHandler = (info, detail, del_date_id, uomList, ind) => {
    // console.log("info", info);
    // console.log("data", detail);
    // console.log("del_date_id", del_date_id);
    // console.log("uomList", uomList);
    // console.log("ind", ind);
    setDeliveryplanid(detail);
    console.log(detail?.DeliveryDate);
    // if()
    setExpectedtimeofArrival((val) => ({
      ...val,
      [del_date_id]: new Date(detail?.DeliveryDate),
    }));
    setExWorkDate2(ind);
    setIndexStore(del_date_id);
    let finder = list?.purchase_order_details?.find(
      (i) =>
        +i?.MaterialPurchaseOrderDetailID ==
        +detail?.MaterialPurchaseOrderDetailID
    );
    let finders = list?.purchase_order_details?.find(
      (i) =>
        +i?.LeatherPurchaseOrderDetailID ==
        +detail?.LeatherPurchaseOrderDetailID
    );

    let sku_code;
    if (finder) {
      sku_code = finder;
    } else if (finders) {
      sku_code = finders;
    }
    // console.log("sku_code", sku_code);
    setSkuCode(sku_code);
    if (data) {
      if (detail?.supplier_delivery_plan !== null) {
        setBtn(false);
        setErrorBoxs(false);
      } else {
        setBtn(false);
        setErrorBoxs(false);
        // setBtn(false);
      }

      // CREATE

      // setModeShipment((val) => ({
      //   ...val,
      //   [del_date_id]: detail?.supplier_delivery_plan?.ShipmentModeID
      //     ? detail?.supplier_delivery_plan?.ShipmentModeID
      //     : "",
      // }));
      // setExpectedtimeofDispatch((val) => ({
      //   ...val,
      //   [del_date_id]: detail?.supplier_delivery_plan?.ETD
      //     ? moment(detail?.supplier_delivery_plan?.ETD).format("YYYY-MM-DD")
      //     : "",
      // }));
      // setExpectedtimeofArrival((val) => ({
      //   ...val,
      //   [del_date_id]: detail?.supplier_delivery_plan?.ETA
      //     ? moment(detail?.supplier_delivery_plan?.ETA).format("YYYY-MM-DD")
      //     : "",
      // }));
      // setRemarks((val) => ({
      //   ...val,
      //   [del_date_id]: detail?.supplier_delivery_plan?.Remarks
      //     ? detail?.supplier_delivery_plan?.Remarks
      //     : "",
      // }));
    } else if (table) {
      // EDIT & UPDATE

      setExpectedtimeofDispatch2(
        detail?.supplier_delivery_plan?.ETD
          ? moment(detail?.supplier_delivery_plan?.ETD).format("YYYY-MM-DD")
          : ""
      );
      setExpectedtimeofArrival2(
        detail?.supplier_delivery_plan?.ETA
          ? moment(detail?.supplier_delivery_plan?.ETA).format("YYYY-MM-DD")
          : ""
      );
      setModeShipment2(detail?.supplier_delivery_plan?.ShipmentModeID);
      setRemarks2(detail?.supplier_delivery_plan?.Remarks);
      setBtn(false);
      setErrorBoxs(false);
    }

    setshipmentQuty(Math.round(detail?.Quantity));
    setUOM(
      uomList?.sku?.material_header?.uom?.UomDescription ||
        uomList?.sku?.leather_header?.uom?.UomDescription
    );
    setUOMs(info);
    // setExWorkDate2(
    //   detail?.ExWorkDate ? moment(detail?.ExWorkDate).format("YYYY-MM-DD") : ""
    // );
    setShowModal(true);
  };

  const asnAddModalClickHandler = () => {
    setAddExtraContShow(true);
    setShowModal(false);
  };

  // console.log("skuCode", skuCode);

  // GET ASN TABLE
  const getList = () => {
    let detail = {};
    if (data) {
      detail.factory_id = data?.FactoryID;
      detail.purchase_order_id = data?.purchase_order_id;
      detail.type = data?.type;
    } else if (table) {
      detail.factory_id = table?.draft?.factory_id;
      detail.purchase_order_id = table?.draft?.po_id;
      detail.type = table?.draft?.type;
    }

    api.asnTable(detail).then((res) => {
      if (res?.status == "success") {
        let dateArr = [];

        res?.purchase_order_details?.map((item, ind) => {
          item?.purchase_order_detail_del_dates?.map((item1, ind1) => {
            dateArr.push(moment(item1?.DeliveryDate).format("DD-MMM-YYYY"));
          });
        });

        let dublicateRemove = Array.from(new Set(dateArr));

        dublicateRemove.sort((a, b) => Date.parse(a) - Date.parse(b));

        const twoArr = [];

        for (let i = 0; i < res?.purchase_order_details?.length; i++) {
          twoArr[i] = [];
          for (let j = 0; j < dublicateRemove?.length; j++) {
            twoArr[i][j] = null;
          }
        }

        for (let i = 0; i < res?.purchase_order_details?.length; i++) {
          for (let j = 0; j < dublicateRemove?.length; j++) {
            if (
              moment(
                res?.purchase_order_details[i].purchase_order_detail_del_dates[
                  j
                ]?.DeliveryDate
              ).format("DD-MMM-YYYY") === dublicateRemove[j]
            ) {
              twoArr[i][j] =
                res?.purchase_order_details[i].purchase_order_detail_del_dates[
                  j
                ];
            } else {
              let temp_obj =
                res?.purchase_order_details[i].purchase_order_detail_del_dates[
                  j
                ];
              for (let s = 0; s < dublicateRemove?.length; s++) {
                if (
                  moment(
                    res?.purchase_order_details[i]
                      .purchase_order_detail_del_dates[j]?.DeliveryDate
                  ).format("DD-MMM-YYYY") === dublicateRemove[s] &&
                  twoArr[i][s] == undefined
                ) {
                  twoArr[i][s] = temp_obj;
                }
              }
            }
          }
        }

        setAsnDate(twoArr);

        // 2D ARRAY END

        let temps = [];
        let temp = [];
        let newTemp = [];
        let lastTemp = [];

        // OLD FORMULA
        // res?.purchase_order_details?.map((item, ind) => {
        //   temps.push(item?.purchase_order_detail_del_dates);
        // });

        // temps.map((item) => temp.push(item?.length));

        // let allCount = temp.reduce((total, sum) => total + sum, 0);

        // let max = Math.max(...temp);

        // for (let i = 0; i < max; i++) {
        //   newTemp.push(i);
        // }

        // for (let i = 0; i < allCount; i++) {
        //   lastTemp.push(i);
        // }

        // NEW FORMULA

        res?.purchase_order_details?.map((item, ind) => {
          item?.purchase_order_detail_del_dates?.map((item1, ind1) => {
            temps.push(moment(item1?.DeliveryDate).format("DD-MMM-YYYY"));
          });
        });

        // console.log("temps", temps);

        let dummyData = Array.from(new Set(temps));

        dummyData.sort((a, b) => Date.parse(a) - Date.parse(b));

        dummyData.map((item) => temp.push(item?.length));

        // console.log("dummyData", dummyData);

        // console.log("temp", temp);

        let allCount = temp.reduce((total, sum) => total + sum, 0);

        let max = Math.max(...temp);

        // console.log("max", max);

        for (let i = 0; i < max; i++) {
          newTemp.push(i);
        }

        for (let i = 0; i < temps?.length; i++) {
          lastTemp.push(i);
        }

        // console.log("twoArrFirst", twoArr);

        // for (let i = 0; i < res?.purchase_order_details?.length; i++) {
        //   twoArr[i] = [];
        //   for (let j = 0; j < dummyData?.length; j++) {
        //     twoArr[i][j] = null;
        //   }
        // }

        // console.log("twoArrFirstTwo", twoArr);

        // for (let i = 0; i < res?.purchase_order_details?.length; i++) {
        //   for (let j = 0; j < dummyData?.length; j++) {
        //     if (
        //       moment(
        //         res?.purchase_order_details[i].purchase_order_detail_del_dates[
        //           j
        //         ]?.DeliveryDate
        //       ).format("DD-MMM-YYYY") === dummyData[j]
        //     ) {
        //       twoArr[i][j] =
        //         res?.purchase_order_details[i].purchase_order_detail_del_dates[
        //           j
        //         ];

        //       console.log("twoArrIf", twoArr);
        //     } else {
        //       let temp_obj =
        //         res?.purchase_order_details[i].purchase_order_detail_del_dates[
        //           j
        //         ];
        //       for (let s = 0; s < dummyData?.length; s++) {
        //         if (
        //           moment(
        //             res?.purchase_order_details[i]
        //               .purchase_order_detail_del_dates[j]?.DeliveryDate
        //           ).format("DD-MMM-YYYY") === dummyData[s]
        //         ) {
        //           twoArr[i][s] = temp_obj;
        //         }
        //       }

        //       console.log("twoArrElse", twoArr);
        //     }
        //   }
        // }

        // console.log("twoArr", twoArr);
        // setAsnDate(twoArr);

        setCounts(dummyData);
        setCountDate(lastTemp);

        let tempArr = [];
        res?.purchase_order_details?.map((items, inds) => {
          items.purchase_order_detail_del_dates?.map((item, ind) => {
            tempArr.push(moment(item?.DeliveryDate).format("DD-MMM-YYYY"));
          });
        });

        let dateArrs = Array.from(new Set(tempArr));

        // setCountDate(dateArr);

        let addres = [];

        if (res?.supplier?.Address1) {
          addres.push(res?.supplier?.Address1);
        }

        if (res?.supplier?.Address2) {
          addres.push(res?.supplier?.Address2);
        }

        if (res?.supplier?.Address3) {
          addres.push(res?.supplier?.Address3);
        }

        if (res?.supplier?.Address4) {
          addres.push(res?.supplier?.Address4);
        }

        if (res?.supplier?.Address5) {
          addres.push(res?.supplier?.Address5);
        }

        setList(res);
        setAdd(addres);
        setCounts(dublicateRemove);
        setAsnDate(twoArr);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // DARFT TABLE
  const getDraftList = () => {
    let dateArr = [];
    console.log("table", table, table?.draft);
    let response = table?.draft?.list;
    let groupResponse = table?.draft?.group;
    let asnDateResponse = table?.draft?.asnDate;
    let countsResponse = table?.draft?.counts;

    let res;
    if (response?.length > 0) {
      res = JSON.parse(response);
    }
    let groups;
    if (groupResponse?.length > 0) {
      groups = JSON.parse(groupResponse);
    }
    let asnDates;
    if (asnDates?.length > 0) {
      JSON.parse(asnDateResponse);
    }

    let count;
    if (count?.length > 0) {
      count = JSON.parse(countsResponse);
    }

    console.log("res", res);

    if (groups?.length > 0 && asnDates?.length > 0 && count?.length > 0) {
      console.log("if");
      setGroup(groups);
      setCounts(count);
      setAsnDate(asnDates);

      let addres = [];

      if (res?.supplier?.Address1) {
        addres.push(res?.supplier?.Address1);
      }

      if (res?.supplier?.Address2) {
        addres.push(res?.supplier?.Address2);
      }

      if (res?.supplier?.Address3) {
        addres.push(res?.supplier?.Address3);
      }

      if (res?.supplier?.Address4) {
        addres.push(res?.supplier?.Address4);
      }

      if (res?.supplier?.Address5) {
        addres.push(res?.supplier?.Address5);
      }

      setList(res);
      setAdd(addres);

      table?.draft?.shipment_details?.map((item, ind) => {
        setExWorkDate((val) => ({
          ...val,
          [item?.del_date_id]: new Date(item?.ewd),
        }));

        setExpectedtimeofDispatch((val) => ({
          ...val,
          [item?.del_date_id]: new Date(item?.etd),
        }));

        setExpectedtimeofArrival((val) => ({
          ...val,
          [item?.del_date_id]: new Date(item?.eta),
        }));

        setModeShipment((val) => ({
          ...val,
          [item?.del_date_id]: item?.mode_of_shipment,
        }));

        setRemarks((val) => ({
          ...val,
          [item?.del_date_id]: item?.remarks,
        }));
      });
    } else {
      console.log("else");
      res?.purchase_order_details?.map((item, ind) => {
        item?.purchase_order_detail_del_dates?.map((item1, ind1) => {
          dateArr.push(moment(item1?.DeliveryDate).format("DD-MMM-YYYY"));
        });
      });

      let dublicateRemove = Array.from(new Set(dateArr));

      dublicateRemove.sort((a, b) => Date.parse(a) - Date.parse(b));

      const twoArr = [];

      for (let i = 0; i < res?.purchase_order_details?.length; i++) {
        twoArr[i] = [];
        for (let j = 0; j < dublicateRemove?.length; j++) {
          twoArr[i][j] = null;
        }
      }

      for (let i = 0; i < res?.purchase_order_details?.length; i++) {
        for (let j = 0; j < dublicateRemove?.length; j++) {
          if (
            moment(
              res?.purchase_order_details[i].purchase_order_detail_del_dates[j]
                ?.DeliveryDate
            ).format("DD-MMM-YYYY") === dublicateRemove[j]
          ) {
            twoArr[i][j] =
              res?.purchase_order_details[i].purchase_order_detail_del_dates[j];
          } else {
            let temp_obj =
              res?.purchase_order_details[i].purchase_order_detail_del_dates[j];
            for (let s = 0; s < dublicateRemove?.length; s++) {
              if (
                moment(
                  res?.purchase_order_details[i]
                    .purchase_order_detail_del_dates[j]?.DeliveryDate
                ).format("DD-MMM-YYYY") === dublicateRemove[s] &&
                twoArr[i][s] == undefined
              ) {
                twoArr[i][s] = temp_obj;
              }
            }
          }
        }
      }

      setAsnDate(twoArr);

      // 2D ARRAY END

      let temps = [];
      let temp = [];
      let newTemp = [];
      let lastTemp = [];

      // NEW FORMULA

      res?.purchase_order_details?.map((item, ind) => {
        item?.purchase_order_detail_del_dates?.map((item1, ind1) => {
          temps.push(moment(item1?.DeliveryDate).format("DD-MMM-YYYY"));
        });
      });

      let dummyData = Array.from(new Set(temps));

      dummyData.sort((a, b) => Date.parse(a) - Date.parse(b));

      dummyData.map((item) => temp.push(item?.length));

      let allCount = temp.reduce((total, sum) => total + sum, 0);

      let max = Math.max(...temp);

      for (let i = 0; i < max; i++) {
        newTemp.push(i);
      }

      for (let i = 0; i < temps?.length; i++) {
        lastTemp.push(i);
      }

      setCounts(dummyData);
      setCountDate(lastTemp);

      let tempArr = [];
      res?.purchase_order_details?.map((items, inds) => {
        items.purchase_order_detail_del_dates?.map((item, ind) => {
          tempArr.push(moment(item?.DeliveryDate).format("DD-MMM-YYYY"));
        });
      });

      let dateArrs = Array.from(new Set(tempArr));

      // setCountDate(dateArr);

      let addres = [];

      if (res?.supplier?.Address1) {
        addres.push(res?.supplier?.Address1);
      }

      if (res?.supplier?.Address2) {
        addres.push(res?.supplier?.Address2);
      }

      if (res?.supplier?.Address3) {
        addres.push(res?.supplier?.Address3);
      }

      if (res?.supplier?.Address4) {
        addres.push(res?.supplier?.Address4);
      }

      if (res?.supplier?.Address5) {
        addres.push(res?.supplier?.Address5);
      }

      setList(res);
      setAdd(addres);
      setCounts(dublicateRemove);
      setAsnDate(twoArr);
    }
  };

  console.log("group", group);

  const twoArrFormeter = (res) => {
    // console.log("res", res);
    let dateArr = [];
    const twoArr = [];

    const mode_of_eta = Object.values(ExpectedtimeofArrival);
    const mode_of_ship = Object.values(modeShipment);

    mode_of_eta.map((item, ind) => {
      dateArr.push({
        date: moment(item).format("DD-MMM-YYYY"),
        shipment: mode_of_ship[ind],
      });
    });

    // console.log("dateArr", dateArr);
    let temp = [];
    let tempNew = [];
    let tempObj = [];
    res?.purchase_order_details?.map((item1, ind1) => {
      item1?.purchase_order_detail_del_dates?.map((item2, ind2) => {
        let obj = {};

        tempObj.push({
          ...item2,
          PurchaseOrderDetailID:
            +item2?.MaterialPurchaseOrderDetailID ||
            +item2?.LeatherPurchaseOrderDetailID,
          del_date: moment(
            ExpectedtimeofArrival[
              item2?.MaterialPurchaseOrderDetailDelDateID ||
                item2?.LeatherPurchaseOrderDetailDelDateID
            ]
          ).format("DD-MMM-YYYY"),
        });
      });
      // console.log("obj", obj);

      let finalObj = {};

      finalObj.AmnNo = item1?.AmnNo;
      finalObj.Approval = item1?.Approval;
      finalObj.ApprovalDate = item1?.ApprovalDate;
      finalObj.ApprovalUserID = item1?.ApprovalUserID;
      finalObj.BuyerID = item1?.BuyerID;
      finalObj.CreatedUserID = item1?.CreatedUserID;
      finalObj.FactoryID = item1?.FactoryID;
      finalObj.PurchaseOrderDetailID =
        item1?.MaterialPurchaseOrderDetailID ||
        item1?.LeatherPurchaseOrderDetailID;
      finalObj.PurchaseOrderID =
        item1?.MaterialPurchaseOrderID || item1?.LeatherPurchaseOrderID;
      finalObj.MaterialSKUID = item1?.MaterialSKUID;
      finalObj.Price = item1?.Price;
      finalObj.TempID = item1?.TempID;
      finalObj.YearID = item1?.YearID;
      finalObj.sku = item1?.sku;
      finalObj.sum_quantity = item1?.sum_quantity;
      finalObj.sum_received_quantity = item1?.sum_received_quantity;
      finalObj.supplier_product_code = item1?.supplier_product_code;
      finalObj.purchase_order_detail_del_dates = "";

      // if (
      //   +tempObj?.purchase_order_detail_del_dates
      //     ?.MaterialPurchaseOrderDetailID ||
      //   +tempObj?.purchase_order_detail_del_dates
      //     ?.LeatherPurchaseOrderDetailID == item1?.LeatherPurchaseOrderDetailID
      // ) {
      //   finalObj.purchase_order_detail_del_dates = Object.values(tempObj);
      // }
      // console.log("finalObj", finalObj);
      tempNew.push(finalObj);
    });

    tempNew.map((item, ind) => {
      let filters = tempObj?.filter(
        (i) => i?.PurchaseOrderDetailID == item?.PurchaseOrderDetailID
      );

      temp.push({
        ...item,
        purchase_order_detail_del_dates: filters,
      });

      // console.log("filters", filters);
    });

    // console.log("temp", temp);

    let detesList = [];

    temp.map((item, index) => {
      // console.log("item", item);
      item?.purchase_order_detail_del_dates?.map((dates, ind) => {
        let obj = {};

        // console.log("dates", dates);

        obj.date = moment(
          ExpectedtimeofArrival[
            dates?.MaterialPurchaseOrderDetailDelDateID ||
              dates?.LeatherPurchaseOrderDetailDelDateID
          ]
        ).format("DD-MMM-YYYY");
        obj.DeliveryDate = moment(dates?.DeliveryDate).format("DD-MMM-YYYY");
        obj.shipment =
          modeShipment[
            dates?.MaterialPurchaseOrderDetailDelDateID ||
              dates?.LeatherPurchaseOrderDetailDelDateID
          ];

        obj.date_of_dispatch = moment(
          ExpectedtimeofDispatch[
            dates?.MaterialPurchaseOrderDetailDelDateID ||
              dates?.LeatherPurchaseOrderDetailDelDateID
          ]
        ).format("DD-MMM-YYYY");
        obj.Quantity = dates?.Quantity;
        obj.skuCode = item?.sku?.LeatherSKUCode || item?.sku?.MaterialSKUCode;
        obj.skuName = item?.sku?.LeatherSKUName || item?.sku?.MaterialSKUName;

        detesList.push(obj);
      });
    });

    // console.log("detesList", detesList);

    const ids = detesList.map(({ date }) => date);
    const filtered = detesList.filter(
      ({ date }, index) => !ids.includes(date, index + 1)
    );

    // console.log("filtered", filtered);
    let dublicateRemove = Array.from(new Set(dateArr));

    dublicateRemove.sort((a, b) => Date.parse(a?.date) - Date.parse(b?.date));
    filtered.sort((a, b) => Date.parse(a?.date) - Date.parse(b?.date));

    // console.log("filtered", filtered);

    // console.log("temp", temp);

    for (let i = 0; i < temp?.length; i++) {
      twoArr[i] = [];
      for (let j = 0; j < filtered?.length; j++) {
        twoArr[i][j] = null;
      }
    }

    for (let i = 0; i < temp?.length; i++) {
      for (let j = 0; j < filtered?.length; j++) {
        if (
          moment(temp[i].purchase_order_detail_del_dates[j]?.del_date).format(
            "DD-MMM-YYYY"
          ) === filtered[j]?.date
        ) {
          twoArr[i][j] = temp[i].purchase_order_detail_del_dates[j];
        } else {
          let temp_obj = temp[i].purchase_order_detail_del_dates[j];
          for (let s = 0; s < filtered?.length; s++) {
            if (
              moment(
                temp[i].purchase_order_detail_del_dates[j]?.del_date
              ).format("DD-MMM-YYYY") === filtered[s]?.date &&
              twoArr[i][s] == undefined
            ) {
              twoArr[i][s] = temp_obj;
            }
          }
        }
      }
    }

    // console.log("twoArr", twoArr);

    setGroup(filtered);

    setCounts(filtered);
    setAsnDate(twoArr);
    setSaveBtn(true);
  };

  // HANDLE CHAGES

  const handleChangeExWork = (date, ind) => {
    // const value = e.target.value;
    setExWorkDate((val) => ({ ...val, [ind]: date }));
  };

  const handleChangeShipment = (e, ind) => {
    const value = e.target.value;
    setModeShipment((val) => ({ ...val, [ind]: value }));
  };

  const handleExpectedtimeofDispatch = (date, ind) => {
    // const value = e.target.value;
    setExpectedtimeofDispatch((val) => ({ ...val, [ind]: date }));
  };

  const handlesExpectedtimeofArrival = (date, ind) => {
    // const value = e.target.value;
    setExpectedtimeofArrival((val) => ({ ...val, [ind]: date }));
  };

  const handlesetRemarks = (e, ind) => {
    const value = e.target.value;
    setRemarks((val) => ({ ...val, [ind]: value }));
  };

  const saveHandler = () => {
    twoArrFormeter(list);
  };

  // console.log("table", table);

  // ADD SHIPMENTS

  const submitHandler = () => {
    var formdata = new FormData();

    if (data) {
      console.log(data, "data");

      if(data?.draft?.asn_type){
        formdata.append("type", data?.draft?.asn_type);
      }else{
        formdata.append("type", data?.type);
      }

      // formdata.append("type", 2);

      formdata.append("asn_type", data?.type);

      formdata.append("po_id", data?.purchase_order_id);

      formdata.append("factory_id", data?.FactoryID);
    } else if (table) {
      console.log(table, "table");
      
      if(table?.draft?.asn_type){
        formdata.append("type", table?.draft?.asn_type);
      }else{
        formdata.append("type", table?.type);
      }
      formdata.append("asn_type", table?.type);
      // formdata.append("type", 2);

      formdata.append("po_id", table?.purchase_order_id || table?.draft?.po_id);

      formdata.append(
        "factory_id",
        table?.FactoryID || table?.draft?.factory_id
      );
    }

    let validate = [];

    const mode_ex = Object.values(ExWorkDate);
    const mode_of = Object.keys(modeShipment);
    const mode_ofs = Object.values(modeShipment);
    const mode_of_etd = Object.values(ExpectedtimeofDispatch);
    const mode_of_eta = Object.values(ExpectedtimeofArrival);
    const mode_of_rem = Object.values(Remarks);

    mode_ofs?.map((item, ind) => {
      validate.push(ind);
      formdata.append(`shipment_details[${ind}][mode_of_shipment]`, item);
    });

    mode_of?.map((item, ind) => {
      validate.push(ind);
      formdata.append(`shipment_details[${ind}][del_date_id]`, item);
    });

    mode_ex?.map((item, ind) => {
      validate.push(ind);
      formdata.append(
        `shipment_details[${ind}][ewd]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    mode_of_etd?.map((item, ind) => {
      validate.push(ind);
      formdata.append(
        `shipment_details[${ind}][etd]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    mode_of_eta?.map((item, ind) => {
      validate.push(ind);
      formdata.append(
        `shipment_details[${ind}][eta]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    mode_of_rem?.map((item, ind) => {
      validate.push(ind);
      formdata.append(`shipment_details[${ind}][remarks]`, item ? item : "");
    });
    if (Array.from(new Set(validate))?.length == countDate?.length) {
      api.asnAdd(formdata).then((res) => {
        if (res?.status == "success") {
          setError(res);
          setErrorBox(true);
          if (table) {
            deleteDrafts(table?.id);
          } else {
            setTimeout(() => {
              navigate("/asn-listing");
            }, 800);
          }
        } else {
          setError(res);
          setErrorBox(true);
          asnAddModalClickHandler();
          // asnSaveClick();
        }
      });
    } else {
      setCapModal(true);
    }
  };

  // SAVE DRAFTS
  const submitDraftHandler = () => {
    var formdata = new FormData();

    if (data) {
      formdata.append("asn_type", data?.type);

      formdata.append("po_id", data?.purchase_order_id);

      formdata.append("factory_id", data?.FactoryID);

      formdata.append("type", 2);
    } else if (table) {
      formdata.append("asn_type", table?.type);
      formdata.append("type",2);
      formdata.append("po_id", table?.purchase_order_id);

      formdata.append("factory_id", table?.FactoryID);
    }

    let validate = [];

    const mode_ex = Object.values(ExWorkDate);
    const mode_of = Object.keys(modeShipment);
    const mode_ofs = Object.values(modeShipment);
    const mode_of_etd = Object.values(ExpectedtimeofDispatch);
    const mode_of_eta = Object.values(ExpectedtimeofArrival);
    const mode_of_rem = Object.values(Remarks);

    formdata.append(
      "mode_of_shipment_del_date_id",
      JSON.stringify(modeShipment)
    );
    formdata.append("ewd", JSON.stringify(ExWorkDate));
    formdata.append("etd", JSON.stringify(ExpectedtimeofDispatch));
    formdata.append("eta", JSON.stringify(ExpectedtimeofArrival));
    formdata.append("remarks", JSON.stringify(Remarks));
    formdata.append("list", JSON.stringify(list));

    if (counts?.length > 0) {
      formdata.append("counts", JSON.stringify(counts));
    }

    if (group?.length > 0) {
      formdata.append("group", JSON.stringify(group));
    }

    if (asnDate?.length > 0) {
      formdata.append("asnDate", JSON.stringify(asnDate));
    }

    mode_ofs?.map((item, ind) => {
      validate.push(ind);
      formdata.append(`shipment_details[${ind}][mode_of_shipment]`, item);
    });

    mode_of?.map((item, ind) => {
      validate.push(ind);
      formdata.append(`shipment_details[${ind}][del_date_id]`, item);
    });

    mode_ex?.map((item, ind) => {
      validate.push(ind);
      formdata.append(
        `shipment_details[${ind}][ewd]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    mode_of_etd?.map((item, ind) => {
      validate.push(ind);
      formdata.append(
        `shipment_details[${ind}][etd]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    mode_of_eta?.map((item, ind) => {
      validate.push(ind);
      formdata.append(
        `shipment_details[${ind}][eta]`,
        moment(item).format("YYYY-MM-DD")
      );
    });

    mode_of_rem?.map((item, ind) => {
      validate.push(ind);
      formdata.append(`shipment_details[${ind}][remarks]`, item ? item : "");
    });

    api.saveDraft(formdata).then((res) => {
      if (res?.status == "success") {
        if (table?.id) {
          deleteDrafts(table?.id);
        }
        let response = {
          status: "success",
          message: "ASN Draft Saved Success",
        };
        setError(response);
        setErrorBox(true);
        setTimeout(() => {
          navigate("/asn-listing");
        }, 1000);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // DELETE DRAFT
  const deleteDrafts = (id, resp) => {
    let data = {
      draft_id: id,
    };
    api.deleteDraft(data).then((res) => {
      if (res?.status === "success") {
        setTimeout(() => {
          navigate("/asn-listing");
        }, 800);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // UPDATE SHIPMENTS

  const storeShipments = () => {
    var formdata = new FormData();

    if (deliveryplanid?.MaterialPurchaseOrderDetailDelDateID) {
      formdata.append("type", "M");
    } else {
      formdata.append("type", "L");
    }

    formdata.append("factory_id", deliveryplanid?.FactoryID);

    formdata.append(
      "delivery_plan_id",
      deliveryplanid?.supplier_delivery_plan?.MaterialSupplierDeliveryPlanID ||
        deliveryplanid?.supplier_delivery_plan?.LeatherSupplierDeliveryPlanID
    );
    formdata.append("ETD", ExpectedtimeofDispatch2);
    formdata.append("ETA", ExpectedtimeofArrival2);
    formdata.append("ShipmentModeID", modeShipment2);
    formdata.append("Remarks", Remarks2);
    setBtn(true);
    api.asnUpdate(formdata).then((res) => {
      if (res?.status == "success") {
        setBtn(false);
        setShowModal(false);
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          getList();
          getPoGroup();
        }, 500);
        // asnSaveClick();
      } else {
        setBtn(false);
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // ASN TABLE VIEW

  const getView = () => {
    let ids = table?.ASNNo + "/" + table?.FactoryID + "/" + table?.MaterialType;
    api.asnView(ids).then((res) => {
      if (res?.status == "success") {
        let addres = [];

        if (res?.delivery_plan?.factory?.AddressLine1) {
          addres.push(res?.delivery_plan?.factory?.AddressLine1);
        }

        if (res?.delivery_plan?.factory?.AddressLine2) {
          addres.push(res?.delivery_plan?.factory?.AddressLine2);
        }

        setAdd(addres);
        setList(res?.delivery_plan);
        setAddExtraContShow(true);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // MODE OF SHIPMENTS LIST

  const asnCreates = () => {
    api.asnCreate().then((res) => {
      if (res?.status == "success") {
        setShipments(res?.shipments);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  // console.log("asnDate", asnDate);

  // TABLE DATES

  const dates = () => {
    let temp = [];
    list?.purchase_order_details?.map((items, inds) => {
      items.purchase_order_detail_del_dates?.map((item, ind) => {
        temp.push(moment(item?.DeliveryDate).format("DD-MMM-YYYY"));
      });
    });

    let dateArr;
    if (temp?.length > 0) {
      dateArr = Array.from(new Set(temp));
    }
    setShipmentDate(dateArr);
  };

  useEffect(() => {
    asnCreates();
    if (data) {
      getList();
    } else if (table) {
      console.log("test");
      getDraftList();
    }
    // if (table) {
    //   getView();
    // }
  }, []);

  // console.log("ExWorkDate", ExWorkDate);

  useEffect(() => {
    if (list) {
      dates();
    }
  }, [list]);

  //  ASN DEL DATE LIST

  const [delBtn, setDelBtn] = useState(false);

  const getDelDates = (items) => {
    console.log("items", items);
    let formdata = new FormData();

    if (data) {
      formdata.append("type", data?.type);
      formdata.append("po_id", data?.purchase_order_id);
      formdata.append("factory_id", data?.FactoryID);
    } else if (table) {
      formdata.append("type", table?.type);
      formdata.append("po_id", table?.purchase_order_id);
      formdata.append("factory_id", table?.FactoryID);
    }

    formdata.append("eta", items?.ETA);
    formdata.append("mode_of_shipment", items?.ShipmentModeID);

    if (
      shipmentModal == items?.MaterialPurchaseOrderDetailDelDateID ||
      items?.LeatherPurchaseOrderDetailDelDateID
    ) {
      setShipmentModal(null);
    } else {
      setDelBtn(true);
      api.asnDelDates(formdata).then((res) => {
        if (res?.status == "success") {
          setDelBtn(false);
          setDelDates(res?.supplier_del_plans);
          setShipmentModal(
            items?.MaterialPurchaseOrderDetailDelDateID ||
              items?.LeatherPurchaseOrderDetailDelDateID
          );
        } else {
          setDelBtn(false);
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  // console.log("delDates", delDates);

  const getDates = () => {
    let temp = [];
    let temps = [];

    list?.purchase_order_details?.map((item, ind) => {
      item?.purchase_order_detail_del_dates?.map((items, inds) => {
        temp.push(items);
        temps.push(items?.supplier_delivery_plan);
      });
    });
    // setCountDate(temps);
  };

  useEffect(() => {
    if (list) {
      getDates();
    }
  }, [list]);

  const toCreate = () => {
    if (
      // ExpectedtimeofDispatch[indexStore]?.length == undefined ||
      ExpectedtimeofDispatch[indexStore]?.length == 0 ||
      ExWorkDate[indexStore]?.length == 0 ||
      // ExWorkDate[indexStore]?.length == undefined ||
      // ExpectedtimeofArrival[indexStore]?.length == undefined ||
      modeShipment[indexStore]?.length == undefined ||
      // ExpectedtimeofArrival[indexStore]?.length == 0 ||
      modeShipment[indexStore]?.length == 0
    ) {
      setCapModal(true);
    } else {
      let validate = [];
      const mode_ex = Object.values(ExWorkDate);
      const mode_ofs = Object.values(modeShipment);
      const mode_of_etd = Object.values(ExpectedtimeofDispatch);
      const mode_of_eta = Object.values(ExpectedtimeofArrival);

      mode_ex?.map((item, ind) => {
        validate.pop(ind);
      });
      mode_ofs?.map((item, ind) => {
        validate.pop(ind);
      });
      mode_of_etd?.map((item, ind) => {
        validate.pop(ind);
      });
      mode_of_eta?.map((item, ind) => {
        validate.pop(ind);
      });

      setAsnNo(validate?.length);
      asnAddModalClickHandler();
      asnSaveClick();
    }
  };

  console.log("countDate", ExpectedtimeofArrival[indexStore]);

  return (
    <>
      <Col className="min-ht-80">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <TopBar />
        <TopHeader type="asn" />
        <Col className="px-5 m-xxl-auto my-5" xs={12}>
          <Col className="asn-top">
            <div className="d-flex justify-content-between">
              <div className="d-sm-flex flex-wrap">
                <p className="top-list light-primary3 me-4 me-sm-5">
                  PO ID:{" "}
                  <span className="text-dark">
                    {list?.purchase_order?.MaterialPurchaseOrderID ||
                      list?.purchase_order?.LeatherPurchaseOrderID ||
                      list?.leather_purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.purchase_order
                        ?.LeatherPurchaseOrderID ||
                      list?.material_purchase_order_detail_del_date
                        ?.material_purchase_order_detail?.purchase_order
                        ?.MaterialPurchaseOrderID}
                  </span>
                </p>
                <p className="top-list light-primary3 me-4 me-sm-5">
                  PO Ref.No:{" "}
                  <span className="text-dark">
                    {" "}
                    {list?.purchase_order?.MaterialPurchaseOrderNo ||
                      list?.purchase_order?.LeatherPurchaseOrderNo ||
                      list?.leather_purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.purchase_order
                        ?.LeatherPurchaseOrderNo ||
                      list?.material_purchase_order_detail_del_date
                        ?.material_purchase_order_detail?.purchase_order
                        ?.MaterialPurchaseOrderNo}
                  </span>
                </p>
                {/* <p className="top-list light-primary3  me-4 me-sm-5">
                  Amendment No:{" "}
                  <span className="text-dark">
                    {list?.purchase_order?.AmnNo ||
                      list?.leather_purchase_order_detail_del_date
                        ?.leather_purchase_order_detail?.purchase_order
                        ?.AmnNo ||
                      list?.material_purchase_order_detail_del_date
                        ?.material_purchase_order_detail?.purchase_order?.AmnNo}
                  </span>
                </p> */}
                <p className="top-list light-primary3 ">
                  PO Date:{" "}
                  <span className="text-dark">
                    {list?.purchase_order?.MaterialPurchaseOrderDate
                      ? moment(
                          list?.purchase_order?.MaterialPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : list?.purchase_order?.LeatherPurchaseOrderDate
                      ? moment(
                          list?.purchase_order?.LeatherPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : list?.leather_purchase_order_detail_del_date
                          ?.leather_purchase_order_detail?.purchase_order
                          ?.LeatherPurchaseOrderDate
                      ? moment(
                          list?.leather_purchase_order_detail_del_date
                            ?.leather_purchase_order_detail?.purchase_order
                            ?.LeatherPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : list?.material_purchase_order_detail_del_date
                          ?.material_purchase_order_detail?.purchase_order
                          ?.MaterialPurchaseOrderDate
                      ? moment(
                          list?.material_purchase_order_detail_del_date
                            ?.material_purchase_order_detail?.purchase_order
                            ?.MaterialPurchaseOrderDate
                        ).format("DD-MMM-YYYY")
                      : ""}
                  </span>
                </p>
              </div>
              <div>
                <button
                  className="rounded-2 border-0 p-2 bg-hash"
                  style={{ fontWeight: "bold" }}
                  onClick={() => navigate("/purchase-order/list")}
                >
                  Close
                </button>
              </div>
            </div>
            {/* <div className="d-flex flex-wrap">
              <div className="pe-2 pe-md-3 col-12 col-sm-6 col-lg-3 col-xl-3 mt-3">
                <p className="hash">
                  ASN No<span className="err">*</span>
                </p>
                <input
                  type="text"
                  className="asn-input"
                  onChange={(e) => setAsnNo(e.target.value)}
                  value={asnNo}
                />
              </div>
              <div className=" pe-md-3 col-12 col-sm-6 col-lg-3 col-xl-3 mt-3">
                <p className="hash">
                  ASN Date<span className="err">*</span>
                </p>
                <input
                  type="date"
                  className="asn-input"
                  onChange={(e) => setAsnDate(e.target.value)}
                  value={asnDate}
                />
              </div>
              <div className="pe-md-3  col-12 col-lg-5 col-xl-5 mt-3">
                <p className="hash">
                  Delivery Address<span className="err">*</span>
                </p>
                <input
                  type="text"
                  className="asn-input"
                  value={add.toString()}
                  disabled
                />
                <p className="hash">
                  Delivery Inco Term: Delivery at Designation
                  <span className="err">*</span>
                </p>
              </div>
            </div> */}
          </Col>
          <Col className="prduct-page mt-3">
            <div className="bill-material d-flex justify-content-between w-100 align-items-center">
              <div className="mb-3">
                <h5>Bill of Materials</h5>
                <p>No.of Items: {list?.purchase_order_details?.length}</p>
              </div>
            </div>

            <div
              className="product-tabless asn-table px-0 mx-0 d-flex"
              style={{ overflowX: "auto", minHeight: "300px" }}
            >
              <div>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="product_th text-center">S.No</th>
                      <th className="product_th text-center text-nowrap">
                        Supplier Mat.Code
                      </th>
                      <th className="product_th product_th text-start">
                        KH SKU Code
                      </th>
                      <th
                        className="product_th product_th text-start"
                        style={{ width: "200px" }}
                      >
                        KH Material SKU
                      </th>
                      <th className="product_th text-center">UOM</th>
                      <th className="product_th text-center">PO Qty</th>
                    </tr>
                    <tr className="">
                      <th className="text-center mt-2"></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th
                        className="text-center mt-5"
                        style={{ color: "#ffffff" }}
                      >
                        shipment
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.purchase_order_details?.map((item, ind) => {
                      // console.log("item", item);
                      return (
                        <>
                          <tr key={ind} className="asn-main-tble">
                            <td className="text-center tdData">{ind + 1}</td>
                            <td className="text-center tdData">{"-"}</td>
                            <td
                              className="text-start tdData"
                              style={{ minWidth: "150px" }}
                            >
                              {" "}
                              {item?.sku?.MaterialSKUCode ||
                                item?.sku?.LeatherSKUCode}
                            </td>
                            <td className="text-start tdData">
                              {" "}
                              {item?.sku?.MaterialSKUName ||
                                item?.sku?.LeatherSKUName}
                            </td>
                            <td className="text-center tdData">
                              <button className="cust-btn bg-lit-primary4">
                                {item?.uom?.UomDescription
                                  ? item?.uom?.UomDescription
                                  : item?.sku?.material_header?.uom?.UomCode
                                  ? item?.sku?.material_header?.uom?.UomCode
                                  : item?.sku?.leather_header?.uom?.UomCode
                                  ? item?.sku?.leather_header?.uom?.UomCode
                                  : "-"}
                              </button>
                            </td>
                            <td className="text-center tdData">
                              <button className="cust-btn bg-lit-primary4 me-1">
                                {Math.round(
                                  item?.purchase_order_detail_del_dates_sum_quantity
                                )}
                              </button>
                            </td>

                            {/* ADD  */}

                            {/* {counts?.map((dates, i) => {
                            // DATE FORMAT

                            let dateFormet;
                            if (
                              item?.purchase_order_detail_del_dates[i]
                                ?.DeliveryDate
                            ) {
                              dateFormet = moment(
                                item?.purchase_order_detail_del_dates[i]
                                  ?.DeliveryDate
                              ).format("DD-MMM-YYYY");
                            } else {
                              dateFormet = null;
                            }
                            return (
                              <td
                                className={"text-center"}
                                style={{ position: "relative" }}
                              >
                                <div className="d-flex flex-column">
                                  {item?.purchase_order_detail_del_dates[i]
                                    ?.Quantity ? (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none",
                                          fontSize: "13px",
                                          fontFamily: "f1",
                                        }}
                                        disabled={delBtn ? true : false}
                                      >
                                        {dateFormet}
                                      </button>

                                      <button
                                        className="cust-btn bg-lit-primary4 me-1"
                                        onClick={() =>
                                          asnAddClickHandler(
                                            item, // info
                                            item
                                              ?.purchase_order_detail_del_dates[
                                              i
                                            ], // details
                                            item
                                              ?.purchase_order_detail_del_dates[
                                              i
                                            ]
                                              ?.MaterialPurchaseOrderDetailDelDateID ||
                                              item
                                                ?.purchase_order_detail_del_dates[
                                                i
                                              ]
                                                ?.LeatherPurchaseOrderDetailDelDateID // del_detail_id
                                          )
                                        }
                                      >
                                        {Math.round(
                                          item?.purchase_order_detail_del_dates[
                                            i
                                          ]?.Quantity
                                        )}
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none",
                                          fontSize: "13px",
                                          fontFamily: "f1",
                                        }}
                                        disabled={delBtn ? true : false}
                                      >
                                        {"-"}
                                      </button>

                                      <button
                                        className={
                                          "cust-btn bg-lit-primary4 me-1"
                                        }
                                      >
                                        {" "}
                                        -{" "}
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            );
                          })} */}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <table className="w-100">
                  <thead>
                    <tr>
                      {counts?.map((name, indx) => (
                        <>
                          <th className="product_th text-center" key={indx}>
                            <span>Shipment {indx + 1}</span>
                          </th>
                        </>
                      ))}
                    </tr>
                    <tr>
                      {group?.length > 0
                        ? group?.map((item, ind) => {
                            // console.log("item", item);
                            let today = new Date(item?.DeliveryDate);

                            // console.log("today", today);
                            let endDate = new Date(item?.date);

                            // console.log("endDate", endDate);

                            // console.log("sss", today <= endDate);
                            return (
                              <th
                                className="text-center"
                                key={ind}
                                style={{ position: "relative" }}
                              >
                                <button
                                  className="cust-btn p-0 text-nowrap"
                                  style={
                                    today <= endDate
                                      ? {
                                          color: "red",
                                          fontSize: "13px",
                                          width: "92px !important",
                                        }
                                      : {
                                          color: "black",
                                          fontSize: "13px",
                                          width: "92px !important",
                                        }
                                  }
                                >
                                  {item?.date}
                                  <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        {item?.shipment == 185
                                          ? "AIR Transport"
                                          : item?.shipment == 186
                                          ? "SEA Transport"
                                          : item?.shipment == 348
                                          ? "ROAD Transport"
                                          : ""}
                                      </Tooltip>
                                    )}
                                    placement="bottom"
                                  >
                                    <img
                                      src={
                                        item?.shipment == 185
                                          ? Plane_Icon
                                          : item?.shipment == 186
                                          ? Ship_Icon
                                          : item?.shipment == 348
                                          ? Lorry_Icon
                                          : ""
                                      }
                                      style={{
                                        width: "15px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  </OverlayTrigger>
                                </button>
                              </th>
                            );
                          })
                        : counts?.map((name, indx) => (
                            <>
                              <th className=" text-center " key={indx}>
                                <span className="text-white">
                                  Shipment {indx + 1}
                                </span>
                              </th>
                            </>
                          ))}
                    </tr>
                  </thead>
                  <tbody>
                    {asnDate?.map((item, ind) => {
                      return (
                        <>
                          <tr key={ind} className="asn-main-tble">
                            {item?.map((dates, i) => {
                              // DATE FORMAT

                              let dateFormet;
                              if (item[i]?.DeliveryDate) {
                                dateFormet = moment(
                                  item[i]?.DeliveryDate
                                ).format("DD-MMM-YYYY");
                              } else {
                                dateFormet = null;
                              }
                              return (
                                <>
                                  <td
                                    className={"text-center tdData"}
                                    style={{ position: "relative" }}
                                  >
                                    <div className="d-flex flex-column">
                                      {item[i]?.Quantity ? (
                                        <>
                                          <button
                                            style={{
                                              border: "none",
                                              background: "none",
                                              fontSize: "13px",
                                              fontFamily: "f1",
                                            }}
                                            disabled={delBtn ? true : false}
                                          >
                                            {dateFormet}
                                          </button>

                                          <button
                                            className="cust-btn bg-lit-primary4 me-1"
                                            onClick={() =>
                                              asnAddClickHandler(
                                                item, // info
                                                item[i], // details
                                                item[i]
                                                  ?.MaterialPurchaseOrderDetailDelDateID ||
                                                  item[i]
                                                    ?.LeatherPurchaseOrderDetailDelDateID, // del_detail_id
                                                list?.purchase_order_details[
                                                  ind
                                                ],
                                                ind
                                              )
                                            }
                                          >
                                            {Math.round(item[i]?.Quantity)}
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            style={{
                                              border: "none",
                                              background: "none",
                                              fontSize: "13px",
                                              fontFamily: "f1",
                                              color: "transparent",
                                            }}
                                            disabled={delBtn ? true : false}
                                          >
                                            {moment(new Date()).format(
                                              "DD-MMM-YYYY"
                                            )}
                                          </button>

                                          <button
                                            className={
                                              "cust-btn bg-lit-primary4 me-1"
                                            }
                                          >
                                            {" "}
                                            -{" "}
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {addExtraContShow ? (
              <>
                {/* <Col
                  lg={8}
                  md={12}
                  xs={12}
                  className="d-md-flex gap-5 my-1 py-1 flex-wrap"
                >
                  <p className="my-1">
                    No of Packages:{" "}
                    {list?.no_of_packages
                      ? parseInt(list?.no_of_packages)
                      : list?.NoOfPackages
                      ? parseInt(list?.NoOfPackages)
                      : ""}
                  </p>
                  <p className="my-1">
                    Total Gross Weight:{" "}
                    {list?.total_gross_weight
                      ? parseInt(list?.total_gross_weight)
                      : list?.TotalGrossWeight
                      ? parseInt(list?.TotalGrossWeight)
                      : ""}{" "}
                    Kgs
                  </p>
                  <p className="my-1">
                    Total Net Weight:{" "}
                    {list?.total_net_weight
                      ? parseInt(list?.total_net_weight)
                      : list?.TotalNetWeight
                      ? parseInt(list?.TotalNetWeight)
                      : ""}
                  </p>
                  <p className="my-1">
                    Total Volume:{" "}
                    {list?.total_volumes
                      ? parseInt(list?.total_volumes)
                      : list?.TotalVolumn
                      ? parseInt(list?.TotalVolumn)
                      : ""}
                    sqf
                  </p>
                </Col> */}
                {/* <h5 className="fs-14px primary1 my-1 py-1 fnt-fam-f1 mt-4">
                  Overall Delivery Schedule
                </h5> */}
                <div className="d-sm-flex flex-wrap">
                  <Col
                    lg={12}
                    md={12}
                    xs={12}
                    className="d-md-flex gap-5 my-1 py-1 flex-wrap ps-lg-3 ms-lg-3 justify-content-end"
                  >
                    {currentBtnDisplay === "submit" && (
                      <div className="d-flex gap-3">
                        <button
                          className="cust-btn bg-primary1 text-white rounded-1"
                          onClick={saveHandler}
                        >
                          Save
                        </button>
                        <button
                          className="cust-btn bg-primary1 text-white rounded-1"
                          onClick={submitDraftHandler}
                        >
                          Save As Draft
                        </button>
                        {saveBtn && (
                          <button
                            className="cust-btn bg-primary1 text-white rounded-1"
                            // onClick={() => navigate("/asn-listing")}
                            onClick={submitHandler}
                            disabled={btn ? true : false}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    )}
                  </Col>
                </div>
              </>
            ) : null}
          </Col>
          {/* <GeneralPagination />  */}
        </Col>
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
      <Modal
        id="#verify"
        className="prod-filter"
        show={showModal}
        centered
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">
            {types == "edit" ? "Edit" : types == "view" ? "View" : "Add"}{" "}
            Shipment
          </h5>
        </Modal.Header>
        <Modal.Body className="d-flex flex-wrap modal-inputs px-0">
          <Col lg={12} md={12} xs={12}>
            <div className="d-flex justify-content-between">
              <p
                className="px-2"
                style={{ fontSize: "13px", fontWeight: "bold" }}
              >
                Item No : {+ExWorkDate2 + 1}
              </p>
              <p
                className="mx-2"
                style={{ fontSize: "13px", fontWeight: "bold" }}
              >
                Delivery Date :
                {moment(deliveryplanid?.DeliveryDate).format("DD-MMM-YYYY")}
              </p>
            </div>
          </Col>
          <Col lg={12} md={12} xs={12} className="mt-2">
            <div className="d-flex justify-content-between">
              <p
                className="px-2"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                KH Material SKU :{" "}
                {skuCode?.sku?.MaterialSKUName || skuCode?.sku?.LeatherSKUName}
              </p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Shipment Qty<span className="err">*</span>
              </label>
              <input
                type="text"
                placeholder=" "
                onChange={(e) => setshipmentQuty(e.target.value)}
                value={shipmentQuty}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                UOM<span className="err">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => setUOM(e.target.value)}
                value={UOM}
                disabled
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Ex. Work Date<span className="err">*</span>
              </label>
              <Datepicker
                selected={ExWorkDate[indexStore]}
                onChange={(date) => handleChangeExWork(date, indexStore)}
                peekNextMonth
                showMonthDropdown
                placeholderText="Select Date"
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd-MMM-yyyy"
                todayButton="Today"
                disabled={types == "view" ? true : false}
              />
              {/* <input
                type="date"
                className="mb-2"
                onChange={(e) => setExWorkDate(e.target.value)}
                value={ExWorkDate}
                disabled
              /> */}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Mode of Shipment<span className="err">*</span>
              </label>
              <select
                onChange={(e) => {
                  handleChangeShipment(e, indexStore);
                  setModeShipment2(e.target.value);
                }}
                value={
                  types == "add" ? modeShipment[indexStore] : modeShipment2
                }
                disabled={types == "view" ? true : false}
              >
                <option>Select Mode of Shipment </option>
                {shipments?.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Expected Time of Dispatch(ETD)<span className="err">*</span>
              </label>
              <Datepicker
                selected={ExpectedtimeofDispatch[indexStore]}
                onChange={(date) =>
                  handleExpectedtimeofDispatch(date, indexStore)
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Select ETD"
                dateFormat="dd-MMM-yyyy"
                todayButton="Today"
                disabled={types == "view" ? true : false}
              />
              {/* <input
                type="date"
                onChange={(e) => {
                  handleExpectedtimeofDispatch(e, indexStore);
                  setExpectedtimeofDispatch2(e.target.value);
                }}
                value={
                  types == "add"
                    ? ExpectedtimeofDispatch[indexStore]
                    : ExpectedtimeofDispatch2
                }
                disabled={types == "view" ? true : false}
              /> */}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="input-box mt-3">
              <label>
                Expected Time of Arrival(ETA)<span className="err">*</span>
              </label>
              <Datepicker
                selected={ExpectedtimeofArrival[indexStore]}
                onChange={(date) =>
                  handlesExpectedtimeofArrival(date, indexStore)
                }
                peekNextMonth
                showMonthDropdown
                placeholderText="Select ETA"
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd-MMM-yyyy"
                todayButton="Today"
                disabled={types == "view" ? true : false}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="input-box mt-3">
              <label>Remarks</label>
              <textarea
                className="w-100 bo"
                row="3"
                onChange={(e) => {
                  handlesetRemarks(e, indexStore);
                  setRemarks2(e.target.value);
                }}
                value={types == "add" ? Remarks[indexStore] : Remarks2}
                disabled={types == "view" ? true : false}
              />
            </div>
          </Col>
        </Modal.Body>

        <div className="d-flex justify-content-end">
          <button
            className="cust-btn mb-2 bg-transparent border-1 border me-1 text-dark"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>

          <button
            className="cust-btn mb-2 bg-primary1 me-1 text-white"
            // onClick={asnAddModalClickHandler}
            // onClick={submitHandler}
            onClick={toCreate}
            disabled={btn ? true : false}
          >
            Add
          </button>
        </div>
      </Modal>
      <Modal
        id="#verify"
        className="otp-modal"
        show={errorBoxs}
        centered
        onHide={() => setErrorBoxs(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="new-modal">
          <div className="w-100 d-flex-al-jb my-2">
            <Image className="fav-icon " src={FavLogo} />
            <button
              onClick={() => setErrorBoxs(false)}
              className="cust-btn p-0 mb-4"
            >
              <FaTimes />
            </button>
          </div>
          <div className="mt-2  my-5">
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              You Can't Add Data , The Supplier Delivery Plan is Empty
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        id="#sucess"
        className="otp-modal"
        show={capModal}
        size="md"
        centered
        onHide={() => setCapModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div
          className="new-modal"
          style={{ border: "1px solid gainsboro", borderRadius: "3px" }}
        >
          <Image className="fav-icon" src={FavLogo} />
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="px-2">
              <h5 className="fs-14" style={{ margin: "0" }}>
                Please Fill All Shipment Details
              </h5>
            </div>
          </div>

          <div className="d-flex-as-jc img_tick mb-2">
            <button
              className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
              onClick={() => setCapModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ASNScreen;
