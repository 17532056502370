import { Col } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { api } from "../../constants/Services";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ErrorModel from "../../constants/Services/Api/errorModel";
import ReactPaginate from "react-paginate";

function AccPaymentSettings({ setAddBooking, setPayment, setId }) {
  const permissions = localStorage.getItem("permissions");
  const data = [
    {
      bankName: "AXIS BANK",
      bankAddress: "SN/12,Krishna Complex,SipCot,Rannipet,TamilNadu 606512",
      ifscCode: "AXIS41071100",
      accNumber: "45612378941",
    },
    {
      bankName: "KOTAK BANK",
      bankAddress: "SN/12,Krishna Complex,SipCot,Rannipet,TamilNadu 606512",
      ifscCode: "KOTAX41071100",
      accNumber: "45612378941",
    },
    {
      bankName: "CITY BANK",
      bankAddress: "SN/12,Krishna Complex,SipCot,Rannipet,TamilNadu 606512",
      ifscCode: "HDFC41071100",
      accNumber: "45612378941",
    },
    {
      bankName: "SBI BANK",
      bankAddress: "SN/12,Krishna Complex,SipCot,Rannipet,TamilNadu 606512",
      ifscCode: "SBI41071100",
      accNumber: "45612378941",
    },
    {
      bankName: "ICICI BANK",
      bankAddress: "SN/12,Krishna Complex,SipCot,Rannipet,TamilNadu 606512",
      ifscCode: "ICICI41071100",
      accNumber: "45612378941",
    },
  ];

  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(10);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState("");

  const getList = () => {
    let params = `?page=${page}`;

    api.paymentGet(params).then((res) => {
      if (res?.status == "success") {
        let temp = res?.accounts.reverse();
        setList(temp);
        setBankId(res?.bank_name);
        setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected + 1);
  };

  const BankDetails = () => {
    api.supplierBankList().then((res) => {
      if (res?.status == "success") {
        setBanks(res?.banks);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const deletePayments = (id) => {
    api.paymentDelete(id).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setTimeout(() => {
          getList();
        }, 800);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    getList();
  }, [page]);

  useEffect(() => {
    BankDetails();
  }, []);

  return (
    <div className="acc-payment-cont mt-0 ms-md-4">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <div className="d-flex justify-content-between my-3">
        <h5 className="blue-fnt d-none d-md-block">Payment Settings</h5>
        {permissions?.includes("ADD-PAYMENT SETTINGS") && (
          <button
            onClick={() => {
              setAddBooking(true);
              setPayment(false);
              setId("");
            }}
            className="cust-btn bg-primary1 bg-primary1 px-3 py-1 text-white rounded-1 border-0"
          >
            Add +
          </button>
        )}
      </div>
      <h6 className=" acc-type">
        <span className="primary2 ">Acc Currency Type : </span>
        <span className="opac_half"> INR</span>
      </h6>
      {list?.map((item, index) => {
        let banks_value = banks?.filter((i) => i?.BankID == item?.bank_name);
        return (
          <div
            key={index}
            className="user-acc-detail-cont my-2 py-1 border-btm "
          >
            <h5 className="pt-2 mb-0">{banks_value[0]?.BankName}</h5>
            <div className="user-acc-detail-inner-cont d-flex align-items-center justify-content-between">
              <Col sm={10} lg={10}>
                <div className="my-2 d-flex gap-2">
                  <p>
                    <span className="">Account Number:</span> {item?.account_no}
                  </p>
                  <p>
                    <span className="">IFSC Code:</span>
                    {item?.ifsc_code}
                  </p>
                </div>
                <p className="pb-2 mb-1">
                  <span className="">Bank Address:</span>{" "}
                  {item?.account_holder_name}
                </p>
              </Col>
              <div className="d-flex gap-3">
                {permissions?.includes("EDIT-PAYMENT SETTINGS") && (
                  <button
                    className="rounded-5 border-0 p-2 bg-hash"
                    onClick={() => {
                      setAddBooking(true);
                      setPayment(false);
                      setId(item?.id);
                    }}
                  >
                    <BiEdit />
                  </button>
                )}
                {permissions?.includes("DELETE-PAYMENT SETTINGS") && (
                  <button
                    className="rounded-5 border-0 p-2 bg-hash"
                    onClick={() => deletePayments(item?.id)}
                  >
                    <RiDeleteBinLine />
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div className="page-nate-new py-2">
        <ReactPaginate
          nextLabel="›"
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          pageCount={pageCounts}
          previousLabel="‹"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export default AccPaymentSettings;
