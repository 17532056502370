import { useState } from "react";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import { Col, Image, Modal } from "react-bootstrap";
import Radio_icon from "../../assets/Icons/Normal Icons/RadioButton.svg";
import { FavLogo, Check } from "../../assets/img";

function EnquiryCard({ type }) {
  const [activeBtn, setActiveBtn] = useState(1);
  const [dropShow, setDropShow] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [submitAck, setSubmitAck] = useState(false);
  const [showInvModal, setShowInvModal] = useState(false);

  const data = [
    {
      terms: "Accept",
    },
    {
      terms: "Accept with Comments",
    },
    {
      terms: "Decline with Comments",
    },
  ];

  const invSelHandler = () => {
    setShowInvModal(true);
    setDropShow(false);
  };

  const dropDownMenuClickHandler = () => {
    setDropShow(false);
    setOpenCard(true);
  };
  const POCardCloseHandler = () => setOpenCard(false);
  const ackSubmitHandler = () => {
    setSubmitAck(true);
    setOpenCard(false);
  };

  const renderedRadioButtons = data.map((item, index) => {
    return (
      <button
        key={index}
        onClick={() => setActiveBtn(index)}
        className="d-flex align-items-center border-0 bg-transparent p-0 text-nowrap po-card-radio-btn"
      >
        <div className="radio-btn">
          {activeBtn == index ? (
            <img src={Radio_icon} className="wid-15px" />
          ) : (
            <div
              className="radio-btn-inner"
              style={{
                background: "#fff",
              }}
            ></div>
          )}
        </div>
        {item.terms}
      </button>
    );
  });

  const dropHandler = () => {
    setDropShow(!dropShow);
  };

  return (
    <div className="my-4">
      <div className="enquiry-list-card-cont p-0">
        <div className="position-absolute enq-drop-whole-container">
          <button
            className="cust-btn dropdown-container-tool p-0"
            onClick={dropHandler}
          >
            <FaEllipsisV />
          </button>
          {dropShow && (
            <div className="po-card-drop-down d-flex flex-column p-3">
              <Link to="/purchase-order/view" className="text-wht pb-1">
                View PO
              </Link>
              <Link to="/asn" className="text-wht pb-1">
                Create ASN
              </Link>
              <Link
                className="txt-org text-nowrap pb-1"
                onClick={dropDownMenuClickHandler}
              >
                Acknowledge PO
              </Link>
              <Link className="text-wht pb-1" onClick={invSelHandler}>
                Create Profomal Invoice
              </Link>
              <Link className="text-wht pb-1">Create Delivery Challan</Link>
              <Link
                className="text-wht text-nowrap py-1"
                onClick={invSelHandler}
              >
                Create Commercial Invoice
              </Link>
              <Link
                to="/purchase-order/shipping-tracker"
                className="text-wht py-1"
              >
                Update Shipping Tracks
              </Link>
            </div>
          )}
        </div>
        <Link
          type="enquiry-view"
          to="/purchase-order/view"
          className="pointer-cur"
        >
          <div className="d-flex gap-4  flex-wrap fnt-fam-f1 py-1 mt-2 px-3">
            <p className="fs-14px">
              <span className="opac_half">PO ID:</span> P00001
            </p>
            <p className="fs-14px">
              <span className="opac_half">PO Ref No:</span> P00001
            </p>
            <p className="fs-14px">
              <span className="opac_half">Amendment:</span> 01
            </p>
            <p className="fs-14px">
              <span className="opac_half">PO Date:</span> 01-12-2022
            </p>
          </div>
          {/* <p className="text-uppercase fs-14px fw-bold py-1 px-3 enuiryfont">
            Fortune promoseven llc (A division of NUkhaba press disbution and
            release)
          </p> */}
          <p className="py-1 mb-2 px-3">
            {type == "primary" && (
              <button
                type="button"
                className="btn btn-outline-primary po-card-btn"
              >
                Responded
              </button>
            )}
            {type == "Success" && (
              <button
                type="button"
                className="btn btn-outline-success po-card-btn"
              >
                Completed
              </button>
            )}
            {type == "danger" && (
              <button
                type="button"
                className="btn btn-outline-danger po-card-btn"
              >
                Decline
              </button>
            )}
            {type == "warning" && (
              <button type="button" className="btn warning-button po-card-btn">
                Inprogress
              </button>
            )}
          </p>
          <div className="d-flex gap-3 flex-wrap">
            <div className="py-1 mb-2 px-3">
              <label>Product Category</label>
              <p>Army Boots / Combat Boots</p>
            </div>
            <div className="py-1 mb-2 px-3">
              <label>PO Value</label>
              <p>INR 10,000.00</p>
            </div>
            <div className="py-1 mb-2 px-3">
              <label>Invoiced Value</label>
              <p>INR 6,000.00</p>
            </div>
            <div className="py-1 mb-2 px-3">
              <label>Payment Received</label>
              <p>INR 6,000.00</p>
            </div>
          </div>
          <div className="opac_half d-flex gap-3 align-items-center enquiry-footer-card py-2 px-3 flex-wrap">
            <p>
              <span>Delivery Schedule:</span>14.10.2023
            </p>
            <p>
              <span>Awarded :</span>KH India
            </p>
          </div>
        </Link>
      </div>

      <Modal
        id="#po-card-acknowledge"
        className="po-acknoweld-card-cont px-3 fnt-fam-f2  pt-3 pb-1"
        show={openCard}
        size="md"
        centered
        onHide={() => setOpenCard(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className=" d-flex justify-content-between pb-2 mt-3">
          <h5 className="primary1 fs-1r">Acknowledgement</h5>
          <span onClick={POCardCloseHandler}> X </span>
        </div>
        <div className="d-flex flex-wrap gap-3 mb-2">
          {renderedRadioButtons}
        </div>
        <label className="my-3">
          Comments<span className="red-text">*</span>
        </label>
        <p className="border-btm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu
          lacinia massa. Donec at diam sed mauris sollicitudin dignissim
          placerat eu nisl. Curabitur faucibus consequat sodales.
        </p>
        <p className="d-flex justify-content-end opac-85">Max 250</p>
        <div className="d-flex mt-4 mb-1 justify-content-end gap-3">
          <button
            className="clear-can-btn border-0 rounded-1 fnt-fam-f1"
            style={{ color: "black" }}
          >
            Clear
          </button>
          <button
            className="bg-primary1 bg-primary1 sub-app-btn text-white rounded-1 border-0 fnt-fam-f1"
            onClick={ackSubmitHandler}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        id="#sucess"
        className="otp-modal"
        show={submitAck}
        size="md"
        centered
        onHide={() => setSubmitAck(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="sup-modal ">
          <Image src={FavLogo} />
          <div className="mt-2">
            <div className="d-flex-as-jc img_tick ">
              <Image src={Check} />
              <div className="px-3">
                <p>Do you want to Submmit the Acknowledge ?</p>
              </div>
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="row">
                <div class="col-sm">
                  <Link
                    style={{
                      backgroundColor: "#D1D3D4",
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="#"
                    onClick={() => {
                      setSubmitAck(false);
                    }}
                  >
                    No
                  </Link>
                </div>
                <div class="col-sm">
                  <Link
                    style={{
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="#"
                    onClick={() => {
                      setSubmitAck(false);
                    }}
                  >
                    Yes
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        id="#sucess"
        className="proforma-com-inv"
        show={showInvModal}
        size="md"
        centered
        onHide={() => setShowInvModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Image
          style={{ width: "10%" }}
          className="ps-2 ms-1 mt-2 pt-1"
          src={FavLogo}
        />
        <div className="sup-modal ">
          <div>
            <div className="d-flex-as-jc img_tick ">
              {/* <Image src={Check} />  */}
              <div className="px-3 mb-2 pb-1">
                <p>Do you to Upload the Proforma Commercial invoice?</p>
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <div className="row">
                <div class="col-sm">
                  <Link
                    style={{
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="/invoice/create"
                  >
                    Upload
                  </Link>
                </div>
                <div class="col-sm">
                  <Link
                    style={{
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="/invoice"
                  >
                    Create
                  </Link>
                </div>
                <div className="col-sm">
                  <Link
                    style={{
                      backgroundColor: "#D1D3D4",
                      width: "70px",
                      height: "30px",
                    }}
                    className="my-3 modal_btn"
                    to="#"
                    onClick={() => {
                      setShowInvModal(false);
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EnquiryCard;
