import React, { useEffect, useState } from "react";
import TopHeader from "../navbar/TopHeader";
import LogInFooter from "../logincomponent/LogInFooter";
import ProductPages from "../component/ProductPages";
import Filter_Icon from "../assets/Icons/Normal Icons/Filter.svg";
import Search_Icon from "../assets/Icons/Normal Icons/Search.svg";
import Filter from "../component/Filter";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { Col, Pagination } from "react-bootstrap";
import { Offcanvas, Button } from "react-bootstrap";
import Radio_icon from "../assets/Icons/Normal Icons/RadioButton.svg";
import {
  FaAngleDown,
  FaArrowDown,
  FaCaretDown,
  FaCheck,
  FaFilter,
  FaSlidersH,
  FaTicketAlt,
  FaTimes,
} from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import {
//   factoryList,
//   filterCate,
//   materialCat,
//   materialGroupCat,
//   materialSubGroupCat,
// } from "../layout/dummyData";

import TopBar from "../navbar/TopBar";
import { productTableData } from "../layout/dummyData";
import { api } from "../constants/Services";
import ErrorModel from "../constants/Services/Api/errorModel";
import ReactPaginate from "react-paginate";
import useUser from "../constants/Storage/userData";

// const filterCate = [
//   { value: "1", name: "Material" },
//   { value: "2", name: "Leather" },
//   { value: "3", name: "Material & Leather" },
// ];

export const factoryList = [
  { value: "1", name: "GD" },
  { value: "2", name: "FWD" },
  { value: "3", name: "LGD" },
];

export const materialCat = [
  { value: "212", name: "General" },
  { value: "213", name: "Production" },
  { value: "1", name: "Maintanace Item" },
];
export const materialGroupCat = [
  { value: "28", name: "Stationary" },
  { value: "1", name: "Catering Material" },
  { value: "171", name: "Cleaning Material" },
];
export const materialSubGroupCat = [
  { value: "324", name: "Papper" },
  { value: "1", name: "Vaccum" },
  { value: "2", name: "Buffet Platter" },
];

const ProductMap = ({}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  // const [MapData, setMapData] = useState(data);
  const [filterShow, setFilterShow] = useState(true);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [catList, setCatList] = useState([]);
  const [catIndex, setCatIndex] = useState();
  const [pages, setPages] = useState(1);
  const [pageCounts, setPageCounts] = useState(10);
  const [searchs, setSearchs] = useState("");
  // const [showList, setShowList] = useState(true);
  const [showList, setShowList] = useState(1);
  const [filters1, setFilters1] = useState("1");
  const [filters2, setFilters2] = useState([]);
  const [filters3, setFilters3] = useState([]);
  const [filters4, setFilters4] = useState([]);
  const [filters5, setFilters5] = useState([]);
  const [filters6, setFilters6] = useState([]);
  const [filters7, setFilters7] = useState([]);
  const [filters8, setFilters8] = useState("");
  const [filters9, setFilters9] = useState([]);
  const [filters10, setFilters10] = useState([]);
  const [filters11, setFilters11] = useState([]);
  const [filterData, setFilerData] = useState([]);
  const [activePages, setActivePages] = useState({});
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [activeBtn, setActiveBtn] = useState(1);
  const filterShowHandler = () => {
    setFilterShow(!filterShow);
  };

  // PAGINATION
  const [adminData, setAdminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(10);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");
  const [allData, setAllData] = useState(false);

  const [filterCate, setFilterCate] = useState([]);
  useEffect(() => {
    let temp = [];

    if (
      user?.supplier?.SupplierCategory == "M" ||
      user?.supplier?.SupplierCategory == "M "
    ) {
      temp.push({
        value: "1",
        name: "Material",
      });
      setActiveBtn(1);
    }

    if (
      user?.supplier?.SupplierCategory == "L" ||
      user?.supplier?.SupplierCategory == "L "
    ) {
      temp.push({
        value: "2",
        name: "Leather",
      });
      setActiveBtn(2);
    }

    if (
      user?.supplier?.SupplierCategory == "ML" ||
      user?.supplier?.SupplierCategory == "ML "
    ) {
      temp.push(
        {
          value: "1",
          name: "Material",
        }
        // {
        //   value: "2",
        //   name: "Leather",
        // },
        // {
        //   value: "3",
        //   name: "Material & Leather",
        // }
      );
      setActiveBtn(1);
    }

    setFilterCate(temp);
  }, []);

  const checkBox = (e) => {
    setCatIndex(e);
  };
  const showListHandler = (data) => {
    if (data == showList) {
      setShowList();
    } else {
      setShowList(data);
    }
    // setShowList(!showList);
  };

  const [input, setInput] = useState([]);

  const details = () => {
    api.createDetails().then((res) => {
      if (res?.status == "success") {
        const sorter = (a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;

        res?.filter_source_types?.sort(sorter);
        res?.purchase_groups?.sort(sorter);

        setInput(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const masterFilter = (data) => {
    // let detail = {
    //   type: data,
    // };
    // if (data == showList - 1) {
    //   console.log("");
    // } else {
    api.masterProductsFilter({ type: 1 }).then((res) => {
      if (res?.status == "success") {
        // FOR SORT
        const sorter = (a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;

        res?.material_types?.sort(sorter);
        res?.material_sub_groups?.sort(sorter);
        res?.factories?.sort(sorter);
        res?.material_groups?.sort(sorter);
        res?.substance?.sort(sorter);
        res?.species?.sort(sorter);
        res?.article?.sort(sorter);

        setFilerData(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
    // }
  };

  useEffect(() => {
    masterFilter();
    details();
  }, []);

  const filterProCate = (data) => {
    let filterData = [];

    if (filterData.includes(data)) {
      let temp = filterData.indexOf(data);
      filterData.splice(temp, 1);
    } else {
      filterData.push(data);
    }
    setFilters1(filterData.toString());
  };

  const filterProType = (data, ind) => {
    let temp = [...filters2];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters2(temp);

    // filters2 == data ? setFilters2("") : setFilters2(data);
  };

  const filterProGroup = (data, ind) => {
    let temp = [...filters3];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters3(temp);
  };

  const filterProSubGroup = (data) => {
    let temp = [...filters4];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters4(temp);
  };

  const filterProFact = (data) => {
    let temp = [...filters5];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters5(temp);
  };

  const sourceTypeCate = (data) => {
    let temp = [...filters7];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters7(temp);
  };

  const purchaseGroup = (data) => {
    let temp = [...filters6];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters6(temp);
  };

  const species = (data) => {
    let temp = [...filters9];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters9(temp);
  };

  const substance = (data) => {
    let temp = [...filters10];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters10(temp);
  };

  const artical = (data) => {
    let temp = [...filters11];
    const value = data;

    const index = temp.indexOf(data);

    if (temp.includes(value)) {
      temp.splice(index, 1);
    } else {
      temp.push(value);
    }
    setFilters11(temp);
  };

  const statusChange = (data) => {
    // console.log("data", data);
    filters8 == data ? setFilters8("") : setFilters8(data);
  };

  // console.log("filters8", filters8);

  const submitHandle = (pages) => {
    // console.log("pages", pages);
    let formdata = new FormData();

    formdata.append(
      "product_category",
      +activeBtn == 1
        ? "M"
        : +activeBtn == 2
        ? "L"
        : +activeBtn == 3
        ? "ML"
        : "M"
    );

    if (filters2?.length > 0) {
      filters2?.map((item, ind) => {
        formdata.append(`material_type[${ind}]`, item);
      });
    }

    if (filters3?.length > 0) {
      filters3?.map((item, ind) => {
        formdata.append(`material_group[${ind}]`, item);
      });
    }

    if (filters4?.length > 0) {
      filters4?.map((item, ind) => {
        formdata.append(`material_sub_group[${ind}]`, item);
      });
    }

    if (filters5?.length > 0) {
      filters5?.map((item, ind) => {
        formdata.append(`factory_list[${ind}]`, item);
      });
    }

    if (filters6?.length > 0) {
      filters6?.map((item, ind) => {
        formdata.append(`p_group_types[${ind}]`, item);
      });
    }

    if (filters7?.length > 0) {
      filters7?.map((item, ind) => {
        formdata.append(`source_types[${ind}]`, item);
      });
    }

    if (filters9?.length > 0) {
      filters9?.map((item, ind) => {
        formdata.append(`species[${ind}]`, item);
      });
    }

    if (filters10?.length > 0) {
      filters10?.map((item, ind) => {
        formdata.append(`substance[${ind}]`, item);
      });
    }

    if (filters11?.length > 0) {
      filters11?.map((item, ind) => {
        formdata.append(`article[${ind}]`, item);
      });
    }

    if (filters8) {
      formdata.append("status", filters8);
    }

    // let data = `?product_category=${
    //   +filters1 == 1 ? "M" : +filters1 == 2 ? "L" : +filters1 == 3 ? "ML" : ""
    // }`;

    // if (filters2) {
    //   data = data + `&material_type=${filters2}`;
    // }

    // if (filters3) {
    //   data = data + `&material_group=${filters3}`;
    // }

    // if (filters4) {
    //   data = data + `&material_sub_group=${filters4}`;
    // }

    // if (filters5) {
    //   data = data + `&factory_list=${filters5}`;
    // }

    // if (pages) {
    //   data = data + `&page=${Number.isInteger(pages) ? pages : 1}`;
    // }

    let pageNum = Number.isInteger(pages);

    api
      .masterProductsGetPage(formdata, pageNum ? pages : 1)
      .then((res) => {
        setErrorPage(true);
        if (res?.status == "success") {
          if (
            res?.material_products?.length > 0 ||
            res?.leather_products?.length > 0
          ) {
            let temp = [];

            if (
              res?.all_materials?.length > 0 &&
              res?.all_leathers?.length > 0
            ) {
              res?.all_materials?.map((item) => temp.push(item));
              res?.all_leathers?.map((item) => temp.push(item));
              setAllData(true);
              setAdminData(temp);
              setActivePages({ total: temp.length });
            } else {
              res?.material_products?.map((item) => temp.push(item));
              res?.leather_products?.map((item) => temp.push(item));
              setPageCounts(
                res?.m_pagination?.total / res?.m_pagination?.per_page ||
                  res?.l_pagination?.total / res?.l_pagination?.per_page
              );

              if (res?.m_pagination?.total) {
                setActivePages(res?.m_pagination);
              } else if (res?.l_pagination?.total) {
                setActivePages(res?.l_pagination);
              }
              setAllData(false);
              setList(temp);
            }

            // console.log("temp", temp);
          } else {
            setList([]);
          }
          // console.log(temp);
        } else {
          setErrorPage(false);
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handlePageChange = (selectedObject) => {
    if (searchs !== "") {
      setPages(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
      submitHandle(parseInt(selectedObject.selected) + 1);
    }
    setCount(selectedObject.selected);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    setCount(event.selected);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  // console.log(filters1);

  // console.log(list);

  return (
    <div className="prduct-page">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      {/* <TopHeader type="product" /> */}
      <TopHeader type="map-product" />
      <Col className="d-flex-wrap min-ht-80">
        <Col
          className="prduct-page-inner mx-auto px-3 px-lg-0"
          xs={12}
          lg={11}
          xxl={6}
        >
          <div className="d-sm-flex justify-content-between mb-2 align-items-center ">
            {/* <ProductPages /> */}
            <div className="d-flex mt-2 mt-sm-0 d-flex justify-content-end">
              {/* <div className="search-box d-sm-flex pe-2 align-items-center">
                <input
                  type="search"
                  id="form1"
                  class="form-control search-field w-90"
                  placeholder="Search"
                  aria-label="Search"
                  // className="w-90"
                  onChange={(e) => setSearchs(e.target.value)}
                  value={searchs}
                />
                <img src={Search_Icon} className="wid-15px" />
              </div> */}
            </div>
          </div>
          <div className="product-table min-ht-80" style={{ width: "100%" }}>
            <div className="d-flex justify-content-between w-100 mb-2">
              <div className="action-page-cont">
                <p>Showing</p>
                <div className="page-status-cont">
                  <p
                    style={{
                      border: "1px solid #ced4da",
                      padding: "4px",
                      margin: "10px 0px",
                      textAlign: "center",
                      width: "35px",
                      height: "28px",
                    }}
                  >
                    {allData ? currentItems?.length : list?.length}
                  </p>
                </div>
                <p>of {activePages?.total} Map Products</p>
              </div>
              <button
                className="filter-btn bg-primary2"
                onClick={filterShowHandler}
              >
                <img src={Filter_Icon} className="wid-15px me-1" />
                Filter
              </button>
            </div>
            <div style={{ width: "100%" }}>
              <table className=" w-100">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>SKU Code</th>
                    <th>SKU Name</th>
                    <th>HSN Code</th>
                    <th>UOM</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {!allData && list?.length > 0 ? (
                    list?.map((list, index) => (
                      <tr>
                        <td>
                          {" "}
                          {index + 1 == 10 ? count + 1 : count}
                          {index + 1 == 10 ? 0 : index + 1}
                        </td>
                        <td>{list?.MaterialSKUCode || list?.LeatherSKUCode}</td>
                        <td>{list?.MaterialSKUName || list?.LeatherSKUName}</td>
                        <td>{list?.HSNCode}</td>
                        <td>{list?.UomDescription}</td>
                        <td>
                          <button
                            onClick={() =>
                              navigate("/product/addProduct", {
                                state: { data: list },
                              })
                            }
                            className="rounded-5 border-0 p-2 bg-hash"
                          >
                            <AiOutlineEye />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : allData && currentItems?.length > 0 ? (
                    currentItems?.map((list, index) => (
                      <tr>
                        <td>
                          {" "}
                          {index + 1 == 10 ? count + 1 : count}
                          {index + 1 == 10 ? 0 : index + 1}
                        </td>
                        <td>{list?.MaterialSKUCode || list?.LeatherSKUCode}</td>
                        <td>{list?.MaterialSKUName || list?.LeatherSKUName}</td>
                        <td>{list?.HSNCode}</td>
                        <td>{list?.UomDescription}</td>
                        <td>
                          <button
                            onClick={() =>
                              navigate("/product/addProduct", {
                                state: { data: list },
                              })
                            }
                            className="rounded-5 border-0 p-2 bg-hash"
                          >
                            <AiOutlineEye />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </tbody>
              </table>
            </div>
            {/* <div className="d-flex-al-jb my-3"> */}
            {/* <ProductPages /> */}
          </div>
          <div className="page-nate-new py-2">
            <ReactPaginate
              nextLabel="›"
              onPageChange={
                errorPage ? (allData ? handlePageClick : handlePageChange) : ""
              }
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={errorPage ? (allData ? pageCount : pageCounts) : ""}
              previousLabel="‹"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Col>
        <div
          style={{
            width: "250px",
            transition: "0.2s all linear",
            position: "fixed",
            right: filterShow ? "0" : "-300px",
            // bottom: 0,
            zIndex: 1111,
            background: "#fff",
          }}
        >
          {/* <Filter filterShowHandlers={filterShowHandler} /> */}
          <Col className="filter-right px-2">
            <button
              onClick={filterShowHandler}
              className="cust-btn d-flex justify-content-between px-1 align-items-center w-100"
            >
              <div className=" d-flex align-items-center">
                <FaSlidersH className="filter-icon" />
                <p className="filter-title ms-2">Product Filter</p>
              </div>
              <FaTimes className="primary1" />
            </button>
            <div>
              <button
                onClick={() => {
                  showListHandler(1);
                }}
                className="prod-cat"
              >
                <p className="cat-type mb-0">Product Category</p>
                <MdOutlineKeyboardArrowDown />
              </button>
              {showList == 1 && (
                <div>
                  {filterCate?.map((item, ind) => {
                    return (
                      <>
                        <div class=" filter_map d-flex gap-1">
                          {/* <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={filters1?.includes(item.value)}
                            onClick={() => filterProCate(item?.value)}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            {item?.name}
                          </label> */}
                          {filterCate?.length == 3 ? (
                            <div
                              className="radio-btn mt-1"
                              onClick={() => setActiveBtn(ind + 1)}
                            >
                              {+activeBtn == ind + 1 ? (
                                <img src={Radio_icon} className="wid-15px" />
                              ) : (
                                <div
                                  className="radio-btn-inner"
                                  style={{
                                    background: "#fff",
                                  }}
                                ></div>
                              )}
                            </div>
                          ) : (
                            <div className="radio-btn mt-1">
                              <img src={Radio_icon} className="wid-15px" />
                            </div>
                          )}
                          <p class="form-check-label">{item?.name}</p>
                        </div>

                        {/* <button
                          key={ind}
                          onClick={() => {
                            checkBox(ind);
                          }}
                          className="d-flex align-items-center mt-1 border-0 w-100 bg-transparent"
                        >
                          {" "}
                          <div
                            className={
                              catIndex == ind
                                ? "check-Btn bg-primary1"
                                : "check-Btn"
                            }
                          >
                            {catIndex == ind && <FaCheck />}
                          </div>
                          <p className="mb-0">{item?.name}</p>
                        </button> */}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
            {(+activeBtn == 1 || +activeBtn == 3) && (
              <>
                <div>
                  <button
                    onClick={() => {
                      showListHandler(2);
                      // masterFilter(1);
                    }}
                    className="prod-cat"
                  >
                    <p className="cat-type mb-0">Material Type</p>
                    <MdOutlineKeyboardArrowDown />
                  </button>
                  {showList == 2 && (
                    <div style={{ height: "35vh", overflow: "auto" }}>
                      {filterData?.material_types?.map((item, ind) => {
                        return (
                          <div class="form-check filter_map">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              // checked={filters2 == item?.id}
                              checked={filters2?.includes(item?.id)}
                              onClick={() => filterProType(item?.id, ind)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => {
                      showListHandler(3);
                      // masterFilter(2);
                    }}
                    className="prod-cat"
                  >
                    <p className="cat-type mb-0">Material Group</p>
                    <MdOutlineKeyboardArrowDown />
                  </button>
                  {showList == 3 && (
                    <div style={{ height: "35vh", overflow: "auto" }}>
                      {filterData?.material_groups?.map((item, ind) => {
                        return (
                          <div class="form-check filter_map">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={filters3?.includes(item?.id)}
                              onClick={() => filterProGroup(item?.id, ind)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => {
                      showListHandler(4);
                      // masterFilter(3);
                    }}
                    className="prod-cat"
                  >
                    <p className="cat-type mb-0">Material Sub Group</p>
                    <MdOutlineKeyboardArrowDown />
                  </button>
                  {showList == 4 && (
                    <div style={{ height: "35vh", overflow: "auto" }}>
                      {filterData?.material_sub_groups?.map((item, ind) => {
                        return (
                          <div class="form-check filter_map">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={filters4?.includes(item?.id)}
                              onClick={() => filterProSubGroup(item?.id)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}

            {(+activeBtn == 2 || +activeBtn == 3) && (
              <>
                <div>
                  <button
                    onClick={() => {
                      showListHandler(9);
                      // masterFilter(1);
                    }}
                    className="prod-cat"
                  >
                    <p className="cat-type mb-0">Species</p>
                    <MdOutlineKeyboardArrowDown />
                  </button>
                  {showList == 9 && (
                    <div style={{ height: "35vh", overflow: "auto" }}>
                      {filterData?.species?.map((item, ind) => {
                        return (
                          <div class="form-check filter_map">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              // checked={filters2 == item?.id}
                              checked={filters9?.includes(item?.id)}
                              onClick={() => species(item?.id, ind)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => {
                      showListHandler(10);
                      // masterFilter(2);
                    }}
                    className="prod-cat"
                  >
                    <p className="cat-type mb-0">Substance</p>
                    <MdOutlineKeyboardArrowDown />
                  </button>
                  {showList == 10 && (
                    <div style={{ height: "35vh", overflow: "auto" }}>
                      {filterData?.substance?.map((item, ind) => {
                        return (
                          <div class="form-check filter_map">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={filters10?.includes(item?.id)}
                              onClick={() => substance(item?.id, ind)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => {
                      showListHandler(11);
                      // masterFilter(2);
                    }}
                    className="prod-cat"
                  >
                    <p className="cat-type mb-0">Artical</p>
                    <MdOutlineKeyboardArrowDown />
                  </button>
                  {showList == 11 && (
                    <div style={{ height: "35vh", overflow: "auto" }}>
                      {filterData?.article?.map((item, ind) => {
                        return (
                          <div class="form-check filter_map">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={filters11?.includes(item?.id)}
                              onClick={() => artical(item?.id, ind)}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}

            <div>
              <button
                onClick={() => {
                  showListHandler(5);
                  // masterFilter(4);
                }}
                className="prod-cat"
              >
                <p className="cat-type mb-0">Factory</p>
                <MdOutlineKeyboardArrowDown />
              </button>
              {showList == 5 && (
                <div>
                  {filterData?.factories?.map((item, ind) => {
                    return (
                      <div class="form-check filter_map">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={filters5?.includes(item?.id)}
                          onClick={() => filterProFact(item?.id)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {item?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  showListHandler(6);
                  // masterFilter(4);
                }}
                className="prod-cat"
              >
                <p className="cat-type mb-0">Purchase Group</p>
                <MdOutlineKeyboardArrowDown />
              </button>
              {showList == 6 && (
                <div>
                  {input?.purchase_groups?.map((item, ind) => {
                    // +activeBtn == 1
                    return (
                      <div class="form-check filter_map">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={filters6?.includes(item?.id)}
                          onClick={() => purchaseGroup(item?.id)}
                          disabled={
                            +activeBtn == 1 && item?.name?.startsWith("LE")
                              ? true
                              : +activeBtn == 2 && item?.name?.startsWith("MA")
                              ? true
                              : false
                          }
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {item?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  showListHandler(7);
                  // masterFilter(4);
                }}
                className="prod-cat"
              >
                <p className="cat-type mb-0">Source Type</p>
                <MdOutlineKeyboardArrowDown />
              </button>
              {showList == 7 && (
                <div>
                  {input?.filter_source_types?.map((item, ind) => {
                    return (
                      <div class="form-check filter_map">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={filters7?.includes(item?.id)}
                          onClick={() => sourceTypeCate(item?.id)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {item?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  showListHandler(8);
                  // masterFilter(4);
                }}
                className="prod-cat"
              >
                <p className="cat-type mb-0">Status</p>
                <MdOutlineKeyboardArrowDown />
              </button>
              {showList == 8 && (
                <div>
                  {[
                    { name: "Active", id: 1 },
                    { name: "Inactive", id: 2 },
                    { name: "Locked", id: 3 },
                  ].map((item, ind) => {
                    return (
                      <div class="form-check filter_map">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={filters8 == item?.id}
                          onClick={() => statusChange(item?.id)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {item?.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end my-3">
              <button className="cust-btn bg-primary2 white me-1">Clear</button>
              <button
                className="cust-btn bg-primary1 white"
                onClick={submitHandle}
              >
                Apply
              </button>
            </div>
          </Col>
        </div>
      </Col>
      <Col xs={12} xxl={12} className="rg-footer mx-auto">
        <LogInFooter />
      </Col>
    </div>
  );
};
export default ProductMap;
