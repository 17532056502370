import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import "./responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layout/Layout";
import LogIn from "./login/LogIn";
import LogInForgot from "./login/LogInForgot";
import CreateProformaInvoice from "./routes/Invoice/CreateInvoice";
import ProformaInvoiceMainPage from "./routes/Invoice/invoicePage";
import BroadCastList from "./routes/BroadCast/BroadCastList";
import ProformaInvoiceLists from "./routes/Invoice/InvoiceList";
import Roles from "./routes/EnterPrise/Roles";
import ProformaFinalInvoice from "./routes/Invoice/FinalInvoice";
import VerifyOtp from "./login/VerifyOtp";
import NewPassword from "./login/NewPassword";
import PurchaseOrderView from "./routes/PurchaseOrder/PurchaseOrderView";
import POReturnNotice from "./routes/PurchaseOrder/ReturnNotice/ReturnNoticePage";
import Register from "./register/Register";
import MCProfile from "./routes/MCProfile";
import Transport from "./routes/Transport";
import Certificate from "./routes/Certificate";
import AddRegistered from "./routes/AddRegistered";
import AddTransport from "./routes/AddTransport";
import AddCertificate from "./routes/AddCertificate";
import AdminDashboard from "./routes/Admin/Admin_Dashboard/Ad_dashboard";
import AdminCreateBroadCast from "./routes/Admin/Admin_Broadcast/Ad_CreateBroadcast";
import AdminBroadcastView from "./routes/Admin/Admin_Broadcast/Ad_Brocast_view";
import AdminBroadcastList from "./routes/Admin/Admin_Broadcast/Ad_BroadCast_List";
import AdminSettings from "./routes/Admin/Admin_settings/AdminSettings";
import AdminLogIn from "./component/Admin/AdminLogin/Ad_Login";
// import AdminLogInForgot from "./component/Admin/AdminLogin/Ad_Login_Forget";
// import AdminNewPassword from "./component/Admin/AdminLogin/Ad_NewPassword";
// import AdminVerifyOtp from "./component/Admin/AdminLogin/Ad_Verify_Otp";
import ProductMap from "./routes/ProductMap";
import AddProduct from "./routes/AddProduct";
import Notification from "./routes/Nofication";
import ShippingTracker from "./routes/PurchaseOrder/ShippingTracker/LogisticsTracker";
import CreateCommercialInvoice from "./routes/Invoice/CreateCommercialInvoice";
// import EnterPriseUserList from "./routes/EnterPrise/UserList";
import EnterPriseUserList from "./routes/EnterPrise/UserListCopy";
import EnterPriseAddDepartment from "./routes/EnterPrise/EnterPriseDepartment";
import EnterPriseUserLoginDetails from "./routes/EnterPrise/EnterPriseUserLoginDetails";
import CreateBroadCast from "./routes/BroadCast/CreateBroadCast";
import CreateQuotation from "./routes/Quotation/CreateQuotation";
import POGRN from "./routes/PurchaseOrder/GRN/GRNTablePage";
import EnquiryList from "./routes/Enquiry/EnquiryList";
import ProductList from "./routes/ProductList";
import EnquiryView from "./routes/Enquiry/EnquiryView";
import PurchaseOrderList from "./routes/PurchaseOrder/POList";
import EnterPriseAddUser from "./routes/EntrerPrises/EnterPriseAddUser";
import EnterPriseModalPermission from "./routes/EntrerPrises/EnterPriseModalPermission";
import EmailTemplate from "./routes/EntrerPrises/EmailTemplate";
import AdminDetails from "./routes/EntrerPrises/AdminDetails";
import AccountSettings from "./routes/AccountSettings.js/AccountSettings";
import Dashboard from "./component/UserDashboard/dashboard";
import ASNScreen from "./routes/PurchaseOrder/ASNScreen/ASNScreen";
import CommercialInvoiceList from "./routes/Invoice/CommercialInvoiceList";
import ASNScreenListing from "./routes/PurchaseOrder/ASNScreen/ASNScreenListing";
import DeliveryChallanCreation from "./routes/PurchaseOrder/DeliveryChallan/DeliveryChallanCreation";
import useToken from "./constants/Storage/useToken";
import PrivateRoutes from "./constants/Services/PrivateRoutes";
import SetPassword from "./register/SetPassword";
import EnterPriseModalPermissionCopy from "./routes/EntrerPrises/EnterPriseModalPermission copy";
import QuotationList from "./routes/Quotation/QuotationList";
import ASNListScreen from "./routes/PurchaseOrder/ASNScreen/ASNListScreen";
import DcListScreen from "./routes/PurchaseOrder/DeliveryChallan/DcListScreen";
import GRNPages from "./routes/PurchaseOrder/GRN/GRNTableContent";
import SetPasswordUser from "./register/SetPasswordUser";
import ReturnPages from "./routes/PurchaseOrder/ReturnNotice/ReturnTablePage";
import ASNScreenView from "./routes/PurchaseOrder/ASNScreen/ASNScreenView";
import DashboardUser from "./component/UserDashboard/dashboardUser";
import GstView from "./routes/Invoice/GstView";
import DeliveryChallanCreationNew from "./routes/PurchaseOrder/DeliveryChallan/DeliveryChallanCreationNew";

const ReactRoutes = () => {
  const { token } = useToken();
  return (
    <Routes>
      {/* <Route path="/" index element={<LogIn />} />  */}
      <Route path="/register" index element={<Register />} />
      <Route path="/LogIn" index element={<LogIn />} />
      <Route path="/loginforgot" index element={<LogInForgot />} />
      <Route path="/verifyotp" index element={<VerifyOtp />} />
      <Route path="/newpassword" index element={<NewPassword />} />
      <Route path="/setpassword" index element={<SetPassword />} />
      <Route path="/setpassworduser" index element={<SetPasswordUser />} />
      <Route path="*" index element={token ? <Dashboard /> : <LogIn />} />
      {/* admin login */}
      <Route path="/" index element={<LogIn />} />
      <Route path="/admin/LogIn" index element={<AdminLogIn />} />
      {/* <Route path="/admin/loginforgot" index element={<AdminLogInForgot />} />
      <Route path="admin/verifyotp" index element={<AdminVerifyOtp />} />
      <Route path="admin/newpassword" index element={<AdminNewPassword />} /> */}
      <Route element={<PrivateRoutes token={token} />}>
        <Route element={<Layout />}>
          <Route path="/dashboard" index element={<Dashboard />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/dashboard-user" index element={<DashboardUser />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/mcprofile" element={<MCProfile />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/mcprofile/addregistered" element={<AddRegistered />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/mcprofile/transport" element={<Transport />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/mcprofile/certificate" element={<Certificate />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/mcprofile/addtransport" element={<AddTransport />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/mcprofile/addcertificate"
            element={<AddCertificate />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/product" element={<ProductMap />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/product/addProduct" element={<AddProduct />} />
        </Route>
        {/* start naren */}
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/userlist"
            element={<EnterPriseUserList />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/userlist/department"
            element={<EnterPriseAddDepartment />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/userlist/user/login-details"
            element={<EnterPriseUserLoginDetails />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/notification" element={<Notification />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/broadcast-list" element={<BroadCastList />} />
        </Route>
        {/* BroadCastList */}
        <Route element={<Layout />}>
          <Route path="/enquiry/enquiry-list" element={<EnquiryList />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enquiry/enquiry-list/enquiry-view"
            element={<EnquiryView />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/broadcast" element={<CreateBroadCast />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/quotation/list" element={<QuotationList />} />
        </Route>

        <Route element={<Layout />}>
          <Route
            path="/enquiry/create-quotation"
            element={<CreateQuotation />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/purchase-order/view" element={<PurchaseOrderView />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/purchase-order/list" element={<PurchaseOrderList />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/purchase-order/goods-received-note"
            element={<POGRN />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/purchase-order/grn" element={<GRNPages />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/purchase-order/return-notice-table"
            element={<ReturnPages />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/purchase-order/return-notice"
            element={<POReturnNotice />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/purchase-order/shipping-tracker"
            element={<ShippingTracker />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invoice/create" element={<CreateProformaInvoice />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/invoice/commercial/create"
            element={<CreateCommercialInvoice />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invoice" element={<ProformaInvoiceMainPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invoice/list" element={<ProformaInvoiceLists />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invoice/gst" element={<GstView />} />
        </Route>

        <Route element={<Layout />}>
          <Route
            path="/commercial-invoice/list"
            element={<CommercialInvoiceList />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/admin/broadcast/create-broadcast"
            element={<AdminCreateBroadCast />}
          />
        </Route>
        {/* ADMIN  */}
        <Route element={<Layout />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/admin/broadcast/view"
            element={<AdminBroadcastView />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/admin/broadcast/list"
            element={<AdminBroadcastList />}
          />
        </Route>

        <Route>
          <Route path="/invoice/print" element={<ProformaFinalInvoice />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/enterprise-admin/roles" element={<Roles />} />
        </Route>

        {/* end naren */}
        {/* start-rubesh */}
        <Route element={<Layout />}>
          <Route path="/product/productList" element={<ProductList />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/add-user"
            element={<EnterPriseAddUser />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/module-permission-settings"
            element={<EnterPriseModalPermissionCopy />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/email-template"
            element={<EmailTemplate />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/enterprise-admin/admin-detail"
            element={<AdminDetails />}
          />
        </Route>
        {/* admin/settings  */}
        <Route element={<Layout />}>
          <Route path="/account-settings" element={<AccountSettings />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="admin/settings" element={<AdminSettings />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/asn-listing" element={<ASNListScreen />} />
        </Route>
        {/* <Route element={<Layout />}>
          <Route path="/asn-listing" element={<ASNScreenListing />} />
        </Route> */}
        <Route element={<Layout />}>
          <Route path="/asn" element={<ASNScreen />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/asn/view" element={<ASNScreenView />} />
        </Route>
        <Route element={<Layout />}>
          {/* <Route path="/dc" element={<DeliveryChallanCreation />} /> */}
          <Route path="/dc" element={<DeliveryChallanCreationNew />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/dc-list" element={<DcListScreen />} />
        </Route>
      </Route>
      {/* end-rubesh */}
    </Routes>
  );
};
export default ReactRoutes;
