// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Table, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import edit_icon from "../../assets/Icons/Normal Icons/Edit.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ChooseInput from "../../component/EnterpriseAdmin/ChooseInput";
import Loader from "../../component/Loader";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";

const EnterPriseModalPermissionCopy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const Id = location?.state?.id;
  // console.log(location);
  const [roles, setRoles] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [name, setName] = useState("");

  const permission = localStorage.getItem("permissions");

  const createRoles = () => {
    api.roleCreate().then((res) => {
      if (res?.status == "success") {
        let permissionSetting = [
          "DELETE-DASHBOARD",
          "ADD-DASHBOARD",
          "EDIT-DASHBOARD",
          "VIEW-DASHBOARD",
          "STATUS-DASHBOARD",
          "STATUS-ROLES",
          "DELETE-ROLES",
          "DELETE-USERS",
          "DELETE-BASIC INFORMATION",
          "STATUS-NEAREST TRANSPORT POINTS",
          "STATUS-CERTIFICATE AWARDS",
          "VIEW-CERTIFICATE AWARDS",
          "VIEW-NEAREST TRANSPORT POINTS",
          "STATUS-MCP",
          "DELETE-MCP",
          "DELETE-PRODUCTS",
          "STATUS-PRODUCTS",
          "STATUS-PURCHASEORDER",
          "DELETE-PURCHASEORDER",
          "STATUS-ADVANCESHIPPINGNOTE",
          "DELETE-ADVANCESHIPPINGNOTE",
          "STATUS-DELIVERYCHALLAN",
          "DELETE-DELIVERYCHALLAN",
          "STATUS-INVOICE",
          "DELETE-INVOICE",
          "STATUS-GOODSRECEIPTNOTE",
          "DELETE-GOODSRECEIPTNOTE",
          "STATUS-RETURNNOTE",
          "DELETE-RETURNNOTE",
          "STATUS-ACCOUNT",
          "DELETE-ACCOUNT",
          "STATUS-PAYMENT SETTINGS",
          "VIEW-PAYMENT SETTINGS",
          "STATUS-REGISTER ADDRESS",
          "VIEW-REGISTER ADDRESS",
        ];

        let temp = [];

        res?.modules?.map((item, ind) => {
          let permission = item?.permissions?.filter(
            (i) => !permissionSetting?.includes(i?.code)
          );

          let obj = {};

          obj.code = item?.code;
          obj.created_at = item?.created_at;
          obj.id = item?.id;
          obj.module_type = item?.module_type;
          obj.permissions = permission;
          obj.status = item?.status;
          obj.type = item?.type;
          obj.updated_at = item?.updated_at;

          temp.push(obj);
        });

        setRoles(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };
  const viewRoles = () => {
    api.viewRole(Id).then((res) => {
      if (res?.status == "success") {
        setName(res?.role?.role);
        let temp = [];
        res?.permissions?.map((item) => {
          temp.push(+item);
        });

        setPermissions(temp);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    createRoles();
    if (Id) {
      viewRoles();
    }
  }, []);

  const roleId = (id) => {
    let roleId = [...permissions];

    if (roleId.includes(id)) {
      let ids = roleId.indexOf(id);
      roleId.splice(ids, 1);
      setPermissions(roleId);
    } else {
      roleId.push(id);
      setPermissions(roleId);
    }
  };

  const [buttonEnable, setButtonEnable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);

  const addRole = () => {
    let data = {
      role: name,
      permissions: permissions,
      type: 2,
    };

    if (Id) {
      data._method = "PUT";

      setButtonEnable(true);
      setLoader(true);

      api.editRole(Id, data).then((res) => {
        if (res?.status == "success") {
          navigate("/enterprise-admin/roles");
          setLoader(false);
          setError(res);
          setErrorBox(true);
        } else {
          setLoader(false);
          setError(res);
          setErrorBox(true);
          setButtonEnable(false);
        }
      });
    } else {
      setButtonEnable(true);
      setLoader(true);
      api.addRole(data).then((res) => {
        if (res?.status == "success") {
          navigate("/enterprise-admin/roles");
          setLoader(false);
          setError(res);
          setErrorBox(true);
        } else {
          setLoader(false);
          setError(res);
          setErrorBox(true);
          setButtonEnable(false);
        }
      });
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    if (Id == null) {
      setEnableEdit(!enableEdit);
    }
  }, []);

  return (
    <div className="col-md-12">
      <TopBar />
      {loader && <Loader />}
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="role" />
      <Col className=" m-lg-auto px-4 px-xxl-0 min-ht-80" xs={12} lg={10}>
        <Col className=" mt-2 me-1 ">
          <Link to="/enterprise-admin/roles" className="link-add-user">
            <FaArrowLeft className="me-2" />
            Role Permission Settings
          </Link>

          {Id == null ? null : permission?.includes("EDIT-ROLES") ? (
            <img
              src={edit_icon}
              alt="icon-edit"
              className="ms-2 pointer"
              style={{ width: "20px" }}
              onClick={() => setEnableEdit(!enableEdit)}
            />
          ) : null}
        </Col>

        <Col sm={12}>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Role"
              onChange={(e) => setName(e.target.value)}
              value={name}
              disabled={!enableEdit}
            />
          </div>
        </Col>
        <Col className="min-ht modal-permi d-flex flex-wrap pt-3 px-0">
          {roles?.map((item, index) => (
            <Col key={index} className="mb-4" xs={12} sm={6} lg={4} xxl={3}>
              <label className="d-flex ">
                <b>{item?.code}</b>
              </label>
              {item?.permissions?.map((per, index) => (
                <div key={index} className="row">
                  <label className="d-flex ">
                    <input
                      disabled={!enableEdit}
                      type="checkbox"
                      className="me-2"
                      checked={permissions?.includes(per?.id)}
                      onClick={() => roleId(per?.id)}
                    />
                    <span>{per?.code}</span>
                  </label>
                </div>
              ))}
            </Col>
          ))}
        </Col>
        <Col>
          {permissions.length > 0 && (
            <button
              className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
              onClick={addRole}
              disabled={!enableEdit}
            >
              Submit
            </button>
          )}
        </Col>
      </Col>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};

export default EnterPriseModalPermissionCopy;
