// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Image, Modal } from "react-bootstrap";
import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import UserCard from "../../component/EnterpriseAdmin/Card";
import DropDown from "../../component/EnterpriseAdmin/DropDown";
import Pagination from "react-bootstrap/Pagination";
import LogInFooter from "../../logincomponent/LogInFooter";
import { RxFile, RxFilePlus } from "react-icons/rx";
import Filter from "../../assets/Icons/Normal Icons/Filter.svg";
import "./EnterPrise.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import { VscBriefcase } from "react-icons/vsc";
import { Button, Col } from "react-bootstrap";
import { VscGear } from "react-icons/vsc";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { RxDotsVertical } from "react-icons/rx";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { api } from "../../constants/Services";
import { ToastContainer, toast } from "react-toastify";
import { Check, FavLogo, PersonImg, PersonLogo } from "../../assets/img";
import ErrorModel from "../../constants/Services/Api/errorModel";

function UserListCopy() {
  const navigate = useNavigate();
  const permission = localStorage.getItem("permissions");
  const [page, setPage] = useState();
  const [pageCounts, setPageCounts] = useState(10);
  const [show, setShow] = useState(false);
  const [dropShow, setDropShow] = useState(null);
  const [admin, setAdmin] = useState({});
  const [data, setData] = useState({});

  const [list, setList] = useState([]);
  const [pages, setPages] = useState(null);
  const [pagess, setPagess] = useState(null);
  const [roles, setRoles] = useState([]);
  const [dep, setDep] = useState([]);
  const [des, setDes] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [filteringData, setFilteringData] = useState({
    status: null,
    department: "",
    designation: "",
  });
  // console.log(
  // "🚀 ~ file: UserListCopy.js:46 ~ UserListCopy ~ filteringData:",
  // filteringData
  // );
  const [adminData, setAdminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [itemsPerSort, setItemsSort] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(9);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");
  const [OtpModal, setOtpModal] = useState(false);
  const [btn, setBtn] = useState(false);
  const [ID, setID] = useState();
  const [searchs, setSearchs] = useState("");
  const [depFilter, setDepFilter] = useState("");
  const [desFilter, setDesFilter] = useState("");
  const [active, setActive] = useState(1);
  const [activePages, setActivePages] = useState([]);

  const dropHandler = (data) => {
    // console.log(data);
    if (dropShow == data) {
      setDropShow(null);
    } else {
      setDropShow(data);
    }
  };

  const userFinalSubmitHandler = () => {
    let filteredList = list?.filter((user) => {
      if (
        user?.sm_designation_id == filteringData.designation &&
        user?.sm_department_id == filteringData.department &&
        user?.status == filteringData.status
      ) {
        return user;
      }
    });
    // console.log(
    // "🚀 ~ file: UserListCopy.js:72 ~ userFinalSubmitHandler ~ filteredList :",
    // filteredList
    // );
    setCurrentItems(filteredList);
    setShow(!show);
  };

  const userFilterHandler = (e) => {
    let sel_target = e.target.id;
    // console.log(
    // "🚀 ~ file: UserListCopy.js:85 ~ userFilterHandler ~ sel_target:",
    // sel_target
    // );
    if (sel_target == "modal-despartment-drop-box") {
      setFilteringData({ ...filteringData, department: e.target.value });
    } else if (sel_target == "modal-designination-drop-box") {
      console.log("coming to function -111");
      setFilteringData({ ...filteringData, designation: e.target.value });
    } else {
      setFilteringData({ ...filteringData, status: e.target.value });
    }
  };

  // console.log(dropShow, "dropShow");

  const createRoles = () => {
    api.createRole().then((res) => {
      if (res?.status == "success") {
        setRoles(res?.roles);
        setDep(res?.departments);
        setDes(res?.designations);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getList = () => {
    let params;

    if (pages !== null) {
      params = `?page=${pages}`;
    } else {
      params = "";
    }

    let param;

    if (pages !== null) {
      param = `?keyword=${searchs}&page=${pagess}`;
    } else {
      param = `?keyword=${searchs}`;
    }

    if (searchs !== "") {
      let data = {
        search: searchs,
      };
      api.filterSupplierUser(param).then((res) => {
        if (res?.status == "success") {
          let temps = res?.suppliers?.filter((i) => i?.id !== res?.admin?.id);
          setList(temps);
          setActivePages(res);
          setAdmin(res?.admin);
          setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    } else if (searchs == "") {
      api.listSupplierUser(params).then((res) => {
        if (res?.status == "success") {
          let temps = res?.suppliers?.filter((i) => i?.id !== res?.admin?.id);
          setList(temps);
          setAdmin(res?.admin);
          setPage(res?.suppliers.length);
          setActivePages(res);
          let temp = res?.suppliers.reverse();
          setAdminData(temps);
          setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  const handlePageChange = (selectedObject) => {
    if (searchs !== "") {
      setPagess(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }
  };

  const filterUser = () => {
    let params;

    params = `?status=${active}`;

    if (depFilter !== "") {
      params = params + `&department_id=${depFilter}`;
    }

    if (desFilter !== "") {
      params = params + `&designation_id=${desFilter}`;
    }

    if (pages !== null) {
      params = params + `&page=${pages}`;
    } else {
      params = params + "";
    }

    setBtn(true);
    api.filterSupplierUsers(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.suppliers);
        setBtn(false);
        setPageCounts(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res);
        setShow(!show);
      } else {
        setBtn(false);
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const statusChange = (id) => {
    setDropShow(null);
    setBtn(true);
    api.statusSupplierUser(id).then((res) => {
      if (res?.status == "success") {
        setBtn(false);
        setOtpModal(false);
        getList();
        setError(res);
        setErrorBox(true);
      } else {
        setError(res);
        setErrorBox(true);
        setBtn(false);
      }
    });
  };

  useEffect(() => {
    let obj = { ...admin };

    let dep_value = dep?.find((i) => i?.id == +admin?.sm_department_id);

    let des_value = des?.find((i) => i?.id == +admin?.sm_designation_id);

    setData({
      ...admin,
      sm_department_id: dep_value,
      sm_designation_id: des_value,
    });
  }, [admin]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    createRoles();
  }, []);

  // useEffect(() => {
  //   if (pages !== null) {
  //     getList();
  //   } else {
  //     getList();
  //   }
  // }, [pages]);

  useEffect(() => {
    if (pages !== null) {
      getList();
    } else if (pagess !== null) {
      getList();
    } else {
      getList();
    }
  }, [pages, searchs, pagess]);

  // PAGENATION

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.user_name.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    if (itemsPerSort == 2) {
      const temp = [...adminData];
      const reversed = temp?.reverse();
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(reversed?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(reversed?.length / itemsPerPage));
    } else if (itemsPerSort == 1) {
      const temps = [...adminData];
      const sortBy = temps?.sort((a, b) =>
        a.user_name.toLowerCase() < b.user_name.toLowerCase()
          ? -1
          : b.user_name.toLowerCase() > a.user_name.toLowerCase()
          ? 1
          : 0
      );
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(sortBy?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(sortBy?.length / itemsPerPage));
    } else {
      const tempss = [...adminData];
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(tempss?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(tempss?.length / itemsPerPage));
    }
  }, [itemsPerSort, itemOffset, itemsPerPage, adminData, list]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  const setSortItems = (e) => {
    const value = e.target.value;
    setItemsSort(parseInt(value));
  };

  // console.log("activePages?.suppliers?.length", activePages?.suppliers?.length);

  return (
    <div className="user-list-whole-container">
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type="enterprise_admin_userlist" />
      <div className="user-list-userlist-container my-4">
        {/* <ToastContainer /> */}
        <div className="search-bar-whole-cont px-2 d-sm-flex flex-wrap">
          <Col className="search-bar-cont">
            <input
              type="search"
              id="form1"
              class="form-control search-field"
              // placeholder="Search by Supplier Details" 11-04-2024
              placeholder="Search by Username"
              aria-label="Search"
              onChange={(e) => setSearchs(e.target.value)}
              value={searchs}
            />
          </Col>
          <div className="d-flex flex-wrap mt-1 mt-sm-0">
            <button
              className="filter-btn bg-primary2 "
              onClick={() => setShow(!show)}
            >
              <img src={Filter} className="wid-15px me-1" />
              Filter
            </button>
            {permission?.includes("ADD-USERS") && (
              <button
                // to="/enterprise-admin/add-user"
                onClick={(e) =>
                  navigate("/enterprise-admin/add-user", {
                    state: { type: "add" },
                  })
                }
                className="filter-btn adduser-btn"
              >
                Add User
              </button>
            )}
          </div>
        </div>
        <div className="action-container px-3 d-sm-flex justify-content-between">
          <div className="action-page-cont">
            <p>Showing</p>
            <div className="page-status-cont">
              <p
                style={{
                  border: "1px solid #ced4da",
                  padding: "4px",
                  margin: "10px 0px",
                  textAlign: "center",
                  width: "35px",
                  height: "28px",
                }}
              >
                {activePages?.suppliers?.length}
              </p>
              {/* <select onChange={setListItems} value={itemsPerPage} disabled>
                <option value={6}>{activePages?.per_page}</option>
                <option value={12}>{"12"}</option>
                <option value={list?.length}>{activePages?.total}</option>
              </select> */}
            </div>
            {/* <p>{activePages?.per_page} Per Page Users</p> */}
            <p>of {activePages?.pagination?.total} Users</p>
          </div>
          {/* <div className="action-page-cont">
             <DropDown title="Sort By" /> 
            <select onChange={setSortItems}>
              <option value={0}>{"Sort By"}</option>
              <option value={1}>{"Aa-Zz"}</option>
              <option value={2}>{"Latest"}</option>
            </select>
          </div> */}
        </div>
        <div className="card-list-cont d-flex flex-wrap justify-content-between min-ht-80 ">
          {data?.user_name !== undefined && Object.values(data)?.length > 0 && (
            <div className="col-12 col-lg-6 p-2">
              <div className="card-container user-list-card-cont mb-4">
                <div className="user-action-cont">
                  <div className="d-flex gap-3">
                    <img
                      className="user-img"
                      // src="https://picsum.photos/110/110"
                      src={data?.img_url}
                    />
                    <div className="user-status-cont">
                      <p>{data?.user_name}</p>
                      <p className="status-inner-cont">
                        {/* <span className="pnote-cont">
                          PNOTE-{admin?.emp_id}
                        </span> */}
                        <span
                          className={
                            data?.status == 1 ? "status-span" : "status-span2"
                          }
                        >
                          {data?.status == 1 ? "Active" : "Inactive"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div>
                      <p
                        className="pnote-cont text-uppercase mt-1"
                        style={{ backgroundColor: "forestgreen" }}
                      >
                        {"Admin"}
                      </p>
                    </div>
                    <div>
                      <button
                        onClick={() => dropHandler("admin")}
                        className="cust-btn dropdown-container-tool p-0"
                        style={{ marginBottom: "auto" }}
                      >
                        <FaEllipsisV />
                      </button>
                    </div>
                    {dropShow == "admin" && (
                      <div className="sort-drop">
                        {/* <Link to="/enterprise-admin/add-user">Edit</Link> */}
                        {permission?.includes("EDIT-USERS") && (
                          <button
                            className="btn-use-uses"
                            onClick={() =>
                              navigate("/enterprise-admin/add-user", {
                                state: {
                                  type: "edit",
                                  id: data?.id,
                                },
                              })
                            }
                          >
                            Edit
                          </button>
                        )}
                        {permission?.includes("VIEW-USERS") && (
                          <button
                            className="btn-use-uses"
                            onClick={() =>
                              navigate("/enterprise-admin/admin-detail", {
                                state: {
                                  type: "view",
                                  id: data?.id,
                                },
                              })
                            }
                          >
                            View
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <Col lg={5} sm={5}>
                    <p className="status-icon-span my-1">
                      <VscBriefcase />
                      Designination
                    </p>
                    <p>
                      {data?.sm_designation_id
                        ? data?.sm_designation_id?.value
                        : "-"}
                    </p>
                  </Col>
                  <Col lg={7} sm={7}>
                    <p className="status-icon-span my-1">
                      <VscGear />
                      Department
                    </p>
                    <p className="mx-2">
                      {data?.sm_department_id
                        ? data?.sm_department_id?.value
                        : "-"}
                    </p>
                  </Col>
                </div>
                <div className="contact-cont">
                  <p className="status-icon-span my-1">
                    <BsTelephone />
                    Phone
                  </p>
                  <p>
                    {data?.mobile_code
                      ? "+" + data?.mobile_code + " " + data?.mobile
                      : "" + " " + data?.mobile}
                  </p>
                </div>
                <div className="contact-cont">
                  <p className="status-icon-span my-1">
                    <AiOutlineMail />
                    Mail
                  </p>
                  <p>{data?.email}</p>
                </div>
              </div>
            </div>
          )}
          {list?.map((item, index) => {
            let dep_value = dep?.filter((i) => i?.id == item?.sm_department_id);

            let des_value = des?.filter(
              (i) => i?.id == item?.sm_designation_id
            );

            return (
              <div className="col-12 col-lg-6 p-2">
                <div className="card-container user-list-card-cont mb-4">
                  <div className="user-action-cont">
                    <div className="d-flex gap-3">
                      <img
                        className="user-img"
                        // src="https://picsum.photos/110/110"
                        src={item?.img_url}
                      />
                      <div className="user-status-cont">
                        <p>{item?.user_name}</p>
                        <p className="status-inner-cont">
                          <span className="pnote-cont">
                            PNOTE-{item?.emp_id}
                          </span>
                          <span
                            className={
                              item?.status == 1 ? "status-span" : "status-span2"
                            }
                          >
                            {item?.status == 1 ? "Active" : "Inactive"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      {/* <div>
                        <p
                          className="pnote-cont text-uppercase mt-1"
                          style={{ backgroundColor: "forestgreen" }}
                        >
                          {admin?.user_name}
                        </p>
                      </div> */}
                      <div>
                        <button
                          onClick={() => dropHandler(index)}
                          className="cust-btn dropdown-container-tool p-0"
                          style={{ marginBottom: "auto" }}
                        >
                          <FaEllipsisV />
                        </button>
                      </div>
                    </div>
                    {dropShow == index && (
                      <div className="sort-drop">
                        {/* <Link to="/enterprise-admin/add-user">Edit</Link> */}
                        {permission?.includes("EDIT-USERS") && (
                          <button
                            className="btn-use-uses"
                            onClick={() =>
                              navigate("/enterprise-admin/add-user", {
                                state: {
                                  type: "edit",
                                  id: item?.id,
                                },
                              })
                            }
                          >
                            Edit
                          </button>
                        )}
                        {permission?.includes("STATUS-USERS") && (
                          <button
                            className="btn-use-uses"
                            onClick={() => {
                              setOtpModal(true);
                              setID(item?.id);
                            }}
                          >
                            {item?.status == 1 ? "Inactive" : "Active"}
                          </button>
                        )}
                        {/* <button
                          className="btn-use-uses"
                          onClick={() =>
                            navigate(
                              "/enterprise-admin/userlist/user/login-details",
                              {
                                state: {
                                  type: "log",
                                  id: item?.id,
                                  firstName: item?.first_name,
                                  lastName: item?.last_name,
                                },
                              }
                            )
                          }
                        >
                          Monitor Log
                        </button> */}
                        {permission?.includes("VIEW-USERS") && (
                          <button
                            className="btn-use-uses"
                            onClick={() =>
                              navigate("/enterprise-admin/admin-detail", {
                                state: {
                                  type: "view",
                                  id: item?.id,
                                },
                              })
                            }
                          >
                            View
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <Col lg={5} sm={5}>
                      <p className="status-icon-span my-1">
                        <VscBriefcase />
                        Designination
                      </p>
                      <p>
                        {item?.sm_designation_id ? des_value[0]?.value : ""}
                      </p>
                    </Col>
                    <Col lg={7} sm={7}>
                      <p className="status-icon-span my-1">
                        <VscGear />
                        Department
                      </p>
                      <p className="mx-2">
                        {item?.sm_department_id ? dep_value[0]?.value : ""}
                      </p>
                    </Col>
                  </div>
                  <div className="contact-cont">
                    <p className="status-icon-span my-1">
                      <BsTelephone />
                      Phone
                    </p>
                    <p>{"+" + item?.mobile_code + " " + item?.mobile}</p>
                  </div>
                  <div className="contact-cont">
                    <p className="status-icon-span my-1">
                      <AiOutlineMail />
                      Mail
                    </p>
                    <p>{item?.email}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination-container">
          <div className="page-status-cont">
            {/* <p>Show</p> */}
            {/* <DropDown title="10" /> */}
            {/* <select onChange={setListItems} value={itemsPerPage}>
              <option value={6}>{"6"}</option>
              <option value={12}>{"12"}</option>
              <option value={list?.length}>{list?.length}</option>
            </select>
            <p>
              1-{itemsPerPage} of {last}
            </p> */}
          </div>
          <div className="page-inner-cont py-2">
            <ReactPaginate
              nextLabel="›"
              onPageChange={handlePageChange}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCounts}
              previousLabel="‹"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
      <Modal show={show} centered onHide={() => setShow(!show)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="w-90 text-center">Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column my-1 py-1">
            <select
              // style={{ border: "1px solid #ced4da" }}
              id="modal-despartment-drop-box"
              className="w-90 pointer my-2 p-2 rounded-1"
              onChange={(e) => setDepFilter(e.target.value)}
              value={depFilter}
            >
              <option value={""}>Select Department</option>
              {dep?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.value}
                </option>
              ))}
            </select>
            <select
              id="modal-designination-drop-box"
              className="w-90 pointer my-2 p-2 rounded-1"
              onChange={(e) => setDesFilter(e.target.value)}
              value={desFilter}
            >
              <option value={""}>Select Designation</option>
              {des?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.value}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex gap-2 w-90 my-1 py-1">
            <div className="d-flex gap-2">
              <lable>Active</lable>
              <input
                id="modal-active-box"
                type="radio"
                checked={active == 1}
                value={1}
                style={{ marginTop: "3px" }}
                className="pointer"
                onClick={(e) => setActive(1)}
              />
            </div>
            <div className="d-flex gap-2">
              <lable>InActive</lable>
              <input
                type="radio"
                checked={active == 0}
                id="modal-inactive-box"
                value={0}
                onClick={(e) => setActive(0)}
                style={{ marginTop: "3px" }}
                className="pointer"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <button
            style={{
              background: "#e2b473",
              color: "white",
              width: "20%",
              border: "none",
              borderRadius: "0.2rem",
              padding: "3px 2px 5px 0px",
            }}
            disabled={btn ? true : false}
            onClick={() => {
              setDepFilter("");
              setDesFilter("");
              getList();
              setShow(!show);
            }}
          >
            Clear
          </button>
          <button
            style={{
              background: "#247395",
              color: "white",
              width: "20%",
              border: "none",
              borderRadius: "0.2rem",
              padding: "3px 2px 5px 0px",
            }}
            disabled={btn ? true : false}
            onClick={filterUser}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {/* STATUS CHANGE */}

      <Modal
        className="otp-modal"
        show={OtpModal}
        size="md"
        centered
        onHide={() => setOtpModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="sup-modal ">
          <Image src={FavLogo} />
          <div className="mt-4">
            <div className="d-flex-as-jc img_tick ">
              <Image src={Check} />
              <div className="px-3">
                <p>Are You Sure !</p>
              </div>
            </div>
            <div className="d-flex justify-content-center py-3">
              <button
                className="my-3 modal_btn"
                onClick={() => {
                  setOtpModal(false);
                  setDropShow(null);
                }}
                disabled={btn ? true : false}
              >
                Cancel
              </button>
              <button
                className="my-3 modal_btn"
                onClick={() => statusChange(ID)}
                disabled={btn ? true : false}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UserListCopy;

{
  /* <select value={item?.sm_designation_id}>
{item?.sm_designation_id
  ? roles?.map((value) => (
      <option value={item?.sm_designation_id}>
        {value?.value}
      </option>
    ))
  : ""}
</select> */
}

// {item?.sm_designation_id
//   ? roles?.map((data) => {
//       let rol = data?.filter(
//         (i) => i?.id == item?.sm_designation_id
//       );
//       return rol[0]?.role;
//     })
//   : null}
