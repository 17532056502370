import React from "react";
import { useLocation } from "react-router-dom";
import MapProductDetail from "../component/MapProductDetail";
import LogInFooter from "../logincomponent/LogInFooter";
import TopBar from "../navbar/TopBar";
import TopHeader from "../navbar/TopHeader";

const AddProduct = () => {
  return (
    <>
      <div className="prduct-page min-ht-80">
        <TopBar />
        <TopHeader type="map-product" />
        <MapProductDetail />
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
};

export default AddProduct;
