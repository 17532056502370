import CreateAdminBroadcast from "../../../component/Admin/AdminBroadCast/Ad_CreateBroadCast";
import React from "react";
import TopHeader from "../../../navbar/TopHeader";
import TopBar from "../../../navbar/TopBar";
import LogInFooter from "../../../logincomponent/LogInFooter";

function AdminCreateBroadcast() {
  return (
    <>
      <TopBar />
      <TopHeader type="broadcast" />
      <CreateAdminBroadcast />
      {/* <div className="rg-footer">
        <LogInFooter />
      </div> */}
    </>
  );
}

export default AdminCreateBroadcast;
