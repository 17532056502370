export const filterCate = [
  { cat1: "Material" },
  { cat1: "Leather" },
  { cat1: "Material & Leather" },
];
export const shippmentCate = [
  { cat1: "Road" },
  { cat1: "Rail" },
  { cat1: "Sea" },
  { cat1: "Air" },
];
export const materialCat = [
  { cat1: "General" },
  { cat1: "Production" },
  { cat1: "Maintanace Item" },
];
export const materialGroupCat = [
  { cat1: "Stationary" },
  { cat1: "Catering Material" },
  { cat1: "Cleaning Material" },
];
export const materialSubGroupCat = [
  { cat1: "Papper" },
  { cat1: "Vaccum" },
  { cat1: "Buffet Platter" },
];
export const factoryList = [{ cat1: "GD" }, { cat1: "FWD" }, { cat1: "LGD" }];

export const prodSpecList = {
  "Material Finished": "a",
  "Material Finishedw": "b",
};

export const orderCapacity = [
  {
    btn: "Manufacturer",
  },
  {
    btn: "Trader",
  },
];
export const usersType = [
  {
    btn: "Map User",
  },
  {
    btn: "Create User",
  },
];

export const businessOptions = [
  { value: "Manufacture1", label: "Manufacture1" },
  { value: "Manufacture2", label: "Manufacture2" },
  // { value: "Dubai", label: "Dubai" },
];
export const countryOptions = [
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  // { value: "Dubai", label: "Dubai" },
];
export const chooseOptions = [
  { value: "Material & Leather", label: "Material & Leather" },
  { value: "Leather", label: "Leather" },
  { value: "Material", label: "Material" },
];

export const asnTableData = [
  {
    supplierCode: "01234",
    sku: "SKU:A01045151145 Shoe Rubber Soling Sheet",
    uom: "sqm",
    po: "20,000",
    rQty: "1789",
  },
  {
    supplierCode: "01234",
    sku: "ManuFacturers Sport Shoes, Breathable & LightWeight Sport Shoes, Flyknits Shoes",
    uom: "sqm",
    po: "20,000",
    rQty: "1789",
  },
  {
    supplierCode: "01234",
    sku: "SKU:A01045151145 Shoe Sole Rubber Soling Sheet",
    uom: "sqm",
    po: "20,000",
    rQty: "1789",
  },
  // {
  //   supplierCode: "01234",
  //   sku: "Agricultural Equipment and Accessories Cleaning machines for seed or gain",
  //   uom: "sqm",
  //   po: "20,000",
  //   rQty: "1789",
  // },
  // {
  //   supplierCode: "01234",
  //   sku: "Agricultural Equipment and Accessories Cleaning machines for seed or gain",
  //   uom: "sqm",
  //   po: "20,000",
  //   rQty: "1789",
  // },
];

export const productTableData = [
  {
    skucode: "P054RT598637894",
    name: " Agricultural Equipment and Accessories Cleaning machines for seed or gain or dried or leguminous vegetables",
    hsncode: "41071100",
    unit: "Meter",
  },
  {
    skucode: "P054RT598637894",
    name: " Agricultural Equipment and Accessories Cleaning machines for seed or gain or dried or leguminous vegetables",
    hsncode: "41071100",
    unit: "Meter",
  },
  {
    skucode: "P054RT598637894",
    name: " Agricultural Equipment and Accessories Cleaning machines for seed or gain or dried or leguminous vegetables",
    hsncode: "41071100",
    unit: "Meter",
  },
  {
    skucode: "P054RT598637894",
    name: " Agricultural Equipment and Accessories Cleaning machines for seed or gain or dried or leguminous vegetables",
    hsncode: "41071100",
    unit: "Meter",
  },
  {
    skucode: "P054RT598637894",
    name: " Agricultural Equipment and Accessories Cleaning machines for seed or gain or dried or leguminous vegetables",
    hsncode: "41071100",
    unit: "Meter",
  },
];
