import React, { useState, useEffect } from "react";

const Timer = ({ validity, setValidity }) => {
  // const [delay, setDelay] = useState();
  const minutes = Math.floor(validity / 60);
  const seconds = Math.floor(validity % 60);
  // console.log(validity);
  useEffect(() => {
    const timer = setInterval(() => {
      setValidity(validity - 1);
    }, 1000);
    if (validity === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <p className="primary1 ps-1 my-0">
      {/* {minutes}: */}
      {seconds}
    </p>
  );
};

export default Timer;
