import React, { useState } from "react";
import { Col, Image, Modal } from "react-bootstrap";
import { FaArrowLeft, FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-number-input/style.css";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import TimeZone from "./TimeZone";
import PermissionSetting from "./PermissionSetting";
import AddDescription from "./AddDescription";
import useUser from "../../constants/Storage/userData";
import moment from "moment/moment";
import { api } from "../../constants/Services";
import ErrorModel from "../../constants/Services/Api/errorModel";
import Loader from "../Loader";
import { Check, FavLogo } from "../../assets/img";
import Timer from "../../login/TimeCounter";

const ProfileRight = () => {
  const { user } = useUser();
  const [country, setCountry] = useState();
  const [editEmailBtn, setEditEmailBtn] = useState(false);
  const [reqotpEmailbtn, setReqotpEmailbtn] = useState(false);
  const [reqOtpinputEmail, setReqOtpinputEmail] = useState(false);
  const [editMobiBtn, setEditMobiBtn] = useState(false);
  const [reqotpMobibtn, setReqotpMobibtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [reqOtpPasswordBtn, setReqOtpPasswordBtn] = useState(false);
  const [capModal, setCapModal] = useState(false);

  const [show, setShow] = useState(false);
  const [departShow, setDepartShow] = useState(false);

  const departShowHandler = () => {
    setShow(true);
    setDepartShow(true);
  };
  const designationShowHandler = () => {
    setShow(true);
    setDepartShow(false);
  };

  const requestEmailBtnHandler = () => {
    setReqotpEmailbtn(true);
    setEditEmailBtn(true);
  };
  const otpEmailInputHandler = () => {
    setReqOtpinputEmail(true);
    setReqotpEmailbtn(false);
  };
  const otpsubmitHanlderEmail = () => {
    setEditEmailBtn(false);
    setReqOtpinputEmail(false);
  };

  const [reqOtpinputMobi, setReqOtpinputMobi] = useState(false);
  const requestMobiBtnHandler = () => {
    setReqotpMobibtn(true);
    setEditMobiBtn(true);
  };
  const otpMobiInputHandler = () => {
    setReqOtpinputMobi(true);
    setReqotpMobibtn(false);
  };
  const otpsubmitHanlderMobi = () => {
    setEditMobiBtn(false);
    setReqOtpinputMobi(false);
  };

  const [name, setName] = useState("Mohammed Hussan");
  const [validity, setValidity] = useState("45");

  const [pass, setPass] = useState("");
  const [ConPass, setConPass] = useState("");
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);

  const [buttonEnable, setButtonEnable] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [loader, setLoader] = useState(false);

  const submitHandle = (resend) => {
    let data = {
      email: user?.email,
      type: "change-password",
    };
    if (pass?.length > 7 && ConPass?.length > 7) {
      setLoader(true);
      setButtonEnable(true);
      api.changePassword(data).then((res) => {
        if (res?.status == "success") {
          if (resend == "resend") {
            setValidity("45");
          }
          setError(res);
          setErrorBox(true);
          setReqOtpPasswordBtn(true);
          setLoader(false);
          setButtonEnable(false);
        } else {
          setLoader(false);
          setError(res);
          setErrorBox(true);
          setButtonEnable(false);
        }
      });
    } else {
      setCapModal(true);
    }
  };

  const otpSubmitHandle = () => {
    if (otp?.length == 0) {
      setOtpErr(true);
    } else {
      let data = {
        email: user?.email,
        otp: Number(otp),
        type: "otp-verify",
      };
      setLoader(true);
      setButtonEnable(true);
      api.otp(data).then((res) => {
        if (res?.status == "success") {
          otpsubmitHanlderMobi();
          setError(res);
          setErrorBox(true);
          setLoader(false);
          passHandler();
          setButtonEnable(false);
        } else {
          setError(res);
          setErrorBox(true);
          setLoader(false);
          setOtp("");
          setButtonEnable(false);
        }
      });
    }
  };

  const passHandler = () => {
    let data = {
      user_name: user?.user_name,
      // email: user?.email,
      password: pass,
      password_confirmation: ConPass,
    };
    setLoader(true);
    api.setPass(data).then((res) => {
      if (res?.status == "success") {
        setError(res);
        setErrorBox(true);
        setLoader(false);
        window.location.reload();
      } else {
        setError(res);
        setErrorBox(true);
        setLoader(false);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <Col className="rit-add-user" xs={12} md={7}>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      {loader && <Loader />}

      <Col className="d-flex flex-wrap">
        <Col className="input-box mt-3 px-2" xs={12} lg={10}>
          <p>User Name</p>
          <p className="border-btm"> {user?.user_name} </p>

          {/* <p className="border-btm"> Mohammed Hussan </p> */}
        </Col>
        <Col className="input-box mt-3 px-2" xs={12} md={6} lg={5}>
          <p>
            First Name <span style={{ color: "red" }}>*</span>
          </p>
          <p className="border-btm"> {user?.first_name} </p>
        </Col>
        <Col className="input-box mt-3 px-2" xs={12} md={6} lg={5}>
          <p>
            Last Name <span style={{ color: "red" }}>*</span>
          </p>
          <p className="border-btm"> {user?.last_name} </p>
        </Col>
        <h5 className="title-comm-inf mb-1 mt-3 primary2 px-1">
          <b>Communication Information</b>
        </h5>
        <Col xs={12} lg={10} className="d-flex flex-wrap">
          <Col xs={12} lg={6} className="d-flex">
            <div style={{ width: "100%", position: "relative" }}>
              <Col className="input-box mt-1 px-2" xs={12}>
                <div style={{ width: "100%", position: "relative" }}>
                  <p>
                    Email Id <span style={{ color: "red" }}>*</span>
                  </p>
                  <p className="border-btm"> {user?.email} </p>
                  {/* {!editEmailBtn && (
                    <button
                      onClick={requestEmailBtnHandler}
                      className="cust-btn px-0"
                      style={{
                        position: "absolute",
                        right: "0",
                        bottom: "5px",
                      }}
                    >
                      <FaEdit />
                    </button>
                  )} */}
                  {reqotpEmailbtn && (
                    <button
                      onClick={otpEmailInputHandler}
                      className="cust-btn bg-primary1 d-flex text-white align-items-center justify-content-center px-2 req-otp-btn"
                    >
                      Request OTP
                    </button>
                  )}
                </div>
              </Col>
            </div>
          </Col>
          {reqOtpinputEmail && (
            <Col className="input-box mt-1 px-2" xs={12} md={5}>
              <div style={{ width: "100%", position: "relative" }}>
                <p>Enter OTP</p>
                <input type="email" />
                <button
                  onClick={otpsubmitHanlderEmail}
                  className="cust-btn bg-primary1 d-flex text-white align-items-center justify-content-center px-2 req-otp-btn"
                >
                  Verify
                </button>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <button className="primary1 cust-btn px-0">Resend OTP</button>
                <p>
                  Valid Till: <span className="primary1">09:59</span>
                </p>
              </div>
            </Col>
          )}
        </Col>
        <Col xs={12} lg={10} className="">
          <Col xs={12} lg={6} className="">
            <div className="input-box mt-3 px-2  w-100">
              <div style={{ width: "100%", position: "relative" }}>
                <div className="">
                  <div className="">
                    {/* <p style={{ width: "40px" }}>Code</p> */}
                    <p
                    // onClick={requestMobiBtnHandler}
                    >
                      Mobile
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-space">
                  <PhoneInput
                    // country="in"
                    value={user?.mobile_code + user?.mobile}
                    onChange={setCountry}
                    disableDropdown
                    disabled
                  />
                </div>

                {/* {editMobiBtn && (
                  <button
                    onClick={requestMobiBtnHandler}
                    className="cust-btn px-0"
                    style={{ position: "absolute", right: "0", bottom: "5px" }}
                  >
                    <FaEdit />
                  </button>
                )} */}
                {reqotpMobibtn && (
                  <button
                    onClick={otpMobiInputHandler}
                    className="cust-btn bg-primary1 d-flex text-white align-items-center justify-content-center px-2 req-otp-btn"
                  >
                    Request OTP
                  </button>
                )}
              </div>
            </div>
          </Col>
          {reqOtpinputMobi && (
            <Col className="input-box mt-3 px-2" xs={12} md={5}>
              <div style={{ width: "100%", position: "relative" }}>
                <p>Enter OTP</p>
                <input type="email" />
                <button
                  onClick={otpsubmitHanlderMobi}
                  className="cust-btn bg-primary1 d-flex text-white align-items-center justify-content-center px-2 req-otp-btn"
                >
                  Verify
                </button>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <button className="primary1 cust-btn px-0">Resend OTP</button>
                <div className="d-flex">
                  <p className="">Valid Till: </p>
                  <Timer setValidity={setValidity} validity={validity} />
                </div>
              </div>
            </Col>
          )}
        </Col>
        <Col xs={12} lg={10} className="d-flex flex-wrap">
          <Col xs={12} lg={6} className="mt-4">
            <h5 className="title-comm-inf mb-1 mt-3 px-1 d-flex">
              <b className="primary2">Change Password</b>
              {!showChangePassword && (
                <button
                  className="cust-btn p-0"
                  onClick={() => setShowChangePassword(true)}
                >
                  <FaEdit className="text-Dark ms-2" />
                </button>
              )}
            </h5>
            {!showChangePassword && (
              <Col className="input-box mt-1 px-1">
                <div style={{ width: "100%", position: "relative" }}>
                  <p>
                    Password Last update on -{" "}
                    {moment(user?.updated_at).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </Col>
            )}
          </Col>
          {showChangePassword && (
            <Col xs={12}>
              <div
                style={{ width: "100%", position: "relative", display: "flex" }}
              >
                <Col className="input-box mt-1 px-2" xs={12} md={6}>
                  <div style={{ width: "100%", position: "relative" }}>
                    <p>New Password</p>
                    <input
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPass(e.target.value)}
                      value={pass}
                    />
                    <button
                      onClick={() => setShowPassword(!showPassword)}
                      className="cust-btn px-0"
                      style={{
                        position: "absolute",
                        right: "0",
                        bottom: "5px",
                      }}
                    >
                      <FaEye />
                    </button>
                  </div>
                </Col>
                <Col className="input-box mt-1 px-2" xs={12} md={6}>
                  <div style={{ width: "100%", position: "relative" }}>
                    <p>Confirm New Password</p>
                    <input
                      type={showConPassword ? "text" : "password"}
                      onChange={(e) => setConPass(e.target.value)}
                      value={ConPass}
                    />
                    <button
                      onClick={() => setShowConPassword(!showConPassword)}
                      className="cust-btn px-0"
                      style={{
                        position: "absolute",
                        right: "0",
                        bottom: "5px",
                      }}
                    >
                      <FaEye />
                    </button>
                  </div>
                  <button
                    onClick={submitHandle}
                    disabled={
                      buttonEnable == true
                        ? true
                        : reqOtpPasswordBtn
                        ? true
                        : false
                    }
                    className="primary1 cust-btn px-2 mt-2 bg-primary1 text-white text-end d-block ms-auto me-0"
                  >
                    Request OTP
                  </button>
                </Col>
              </div>
              {reqOtpPasswordBtn && (
                <Col className="input-box mt-3 px-2" xs={6}>
                  <div style={{ width: "100%", position: "relative" }}>
                    <p>Enter OTP</p>
                    <input
                      type="email"
                      onChange={(e) => setOtp(e.target.value)}
                      value={otp}
                    />
                    {otp?.length == 0 && otpErr && (
                      <p className="text-danger">Enter OTP</p>
                    )}
                    <button
                      onClick={otpSubmitHandle}
                      disabled={buttonEnable == true ? true : false}
                      className="cust-btn bg-primary1 d-flex text-white align-items-center justify-content-center px-2 req-otp-btn"
                    >
                      Verify
                    </button>
                  </div>
                  <div className="d-flex flex-wrap justify-content-between">
                    <button
                      className="primary1 cust-btn px-0"
                      onClick={() => submitHandle("resend")}
                    >
                      Resend OTP
                    </button>
                    <div className="d-flex">
                      <p className="">Valid Till: </p>
                      <Timer setValidity={setValidity} validity={validity} />
                    </div>
                  </div>
                </Col>
              )}
            </Col>
          )}
          <Modal
            id="#sucess"
            className="otp-modal"
            show={capModal}
            size="md"
            centered
            onHide={() => setCapModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className="new-modal">
              <Image className="fav-icon" src={FavLogo} />
              <div className="d-flex justify-content-center align-items-center mt-3">
                <div className="px-2">
                  <h5 className="fs-14" style={{ margin: "0" }}>
                    Please Enter New Password
                  </h5>
                  <h5 className="fs-14" style={{ margin: "0" }}>
                    & Confirm New Password
                  </h5>
                </div>
              </div>

              <div className="d-flex-as-jc img_tick ">
                <button
                  className="my-2 bg-primary1 cust-btn m-auto d-flex text-white px-4"
                  onClick={() => setCapModal(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal>
        </Col>{" "}
        {/* <Col
          className="input-box mt-1 d-flex flex-wrap mt-2 px-2 pt-2"
          xs={12}
          md={6}
          lg={5}
        >
          <p style={{ width: "40px" }}>
            Department<span style={{ color: "red" }}>*</span>
          </p>
          <select className="">
            <option value="CRN(+2 others)">CRN(+2 others)</option>
            <option value="RNG(+2 others)">RNG(+2 others)</option>
          </select>

          <button onClick={departShowHandler} className="ad-depart ">
            Add Department
          </button>
        </Col> */}
        {/* <Col
          className="input-box mt-1 d-flex flex-wrap mt-2 px-2 pt-2"
          xs={12}
          md={6}
          lg={5}
        >
          <p style={{ width: "40px" }}>
            Designation<span style={{ color: "red" }}>*</span>
          </p>
          <select className="">
            <option value="Worker">Worker</option>
            <option value="Worker">Superviser</option>
          </select>
          <button onClick={designationShowHandler} className="ad-depart ">
            Add Designation
          </button>
        </Col> */}
      </Col>
      <AddDescription
        type={
          departShow == true
            ? "department-account-settings"
            : "designation-account-settings"
        }
        setShow={setShow}
        show={show}
      />
    </Col>
  );
};

export default ProfileRight;
