// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
// import { FaArrowLeft } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import ChangeAdmin from "../../component/AccountSeetings/ChangeAdmin";
// import ChangeAdminMapUser from "../../component/AccountSeetings/ChangeAdminMapUser";
import ProfileLeft from "../../component/AccountSeetings/ProfileLeft";
import SelectUser from "../../component/AccountSeetings/SelectUser";
import ProfileRight from "../../component/EnterpriseAdmin/ProfileRight";
// import RightAddUser from "../../component/EnterpriseAdmin/RightAddUser";
import PaymentSettings from "../../component/AccountSeetings/PaymentSettings";
import BankDetails from "../../component/AccountSeetings/BankDetails";
import { ToastContainer, toast } from "react-toastify";

// import { usersType } from "../../layout/dummyData";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";

const AccountSettings = () => {
  const [changeAdmin, setChangeAdmin] = useState(false);
  const [accountSett, setAccountSett] = useState(true);
  const [payment, setPayment] = useState(false);
  const [addBooking, setAddBooking] = useState(false);
  const [id, setId] = useState("");
  console.log(payment);
  return (
    <div className="user-list-whole-container">
      <TopBar />
      <TopHeader type="account-settings" />
      <ToastContainer />
      <Col className="enterprise-add-user d-md-flex flex-wrap">
        <ProfileLeft
          changeAdmin={changeAdmin}
          setAccountSett={setAccountSett}
          accountSett={accountSett}
          setChangeAdmin={setChangeAdmin}
          payment={payment}
          setPayment={setPayment}
          setAddBooking={setAddBooking}
        />
        {changeAdmin == true ? null : accountSett && <ProfileRight />}
        {changeAdmin && <SelectUser />}
        {/* {changeAdmin && <ChangeAdmin />} */}
        {payment && (
          <PaymentSettings
            setAddBooking={setAddBooking}
            setPayment={setPayment}
            setId={setId}
          />
        )}
        {addBooking && (
          <BankDetails
            setAddBooking={setAddBooking}
            setPayment={setPayment}
            id={id}
            setId={setId}
          />
        )}
      </Col>
      <Col xs={12} xxl={12} className="rg-footer mx-auto">
        <LogInFooter />
      </Col>
    </div>
  );
};

export default AccountSettings;
