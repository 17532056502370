import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import { Col, Image, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FavLogo, Check } from "../../assets/img";
import { useState } from "react";
import EnquiryViewFirstSec from "../../component/Enquiry/EnquiryViewFirstSec";
import EnquiryViewScope from "./EnqViewScope";
import EnqTermsAndCondition from "./EnqTerms&Condition";
import EnquiryCommunication from "../../component/Enquiry/EnqComunication";
import LogInFooter from "../../logincomponent/LogInFooter";

function EnquiryView() {
  const [currentActive, setCurrentActive] = useState("scope");
  const [closeCommunication, setCloseCommunication] = useState(false);

  const location = useLocation();

  const onclickChangeHandler = () => {
    setCloseCommunication(true);
  };
  let currentContent;
  switch (currentActive) {
    case "terms":
      currentContent = <EnqTermsAndCondition />;
      break;
    case "communication":
      currentContent = <EnquiryCommunication />;
      break;
    case "scope":
      currentContent = <EnquiryViewScope />;
      break;
    default:
      currentContent = <EnquiryViewScope />;
  }

  return (
    <>
      <TopBar />
      <TopHeader type="enquiry-view" />
      <div className="d-flex flex-wrap xtra-lg-screen-responsive-cont">
        <Col lg={3} md={4} sm={12} className="p-4 bor-right">
          <h5 className="text-wrap fnt-fam-f3 py-1 my-1 text-uppercase fs-15px">
            Fortune promoseven llc (A division of NUkhaba press disbution and
            release)
          </h5>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">RFQ ID: </span>REQ-10-001
          </p>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">RFQ REF ID: </span>REQ-001
          </p>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">RFQ Date : </span>03-10-2022
          </p>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">Closing Date & Time : </span>14-10-2022
            10.10 AM
          </p>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">KH Division: </span>KH Export
          </p>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">Buyner Name: </span>-
          </p>
          <p className="py-1 my-1 fs-14px fnt-fam-f2">
            <span className="opac_half">Issued By: </span>Mohammad Bilal
          </p>
          <div className="my-3 py-2">
            <h5 className="primary1 fs-15px">KH Contact Details</h5>
            <p className="py-1 my-1 fs-14px fnt-fam-f2">
              <span className="opac_half">Name : </span>Subair
            </p>
            <p className="py-1 my-1 fs-14px fnt-fam-f2">
              <span className="opac_half">Phone : </span>9784561232
            </p>
            <p className="py-1 my-1 fs-14px fnt-fam-f2">
              <span className="opac_half">Email-id : </span>google@dummy.com
            </p>
          </div>
        </Col>
        <Col
          lg={9}
          md={8}
          sm={12}
          xs={12}
          className="p-3 flex-wrap enqiry-view-2nd-sec"
        >
          <div className="enquiry-view-sec my-2">
            <EnquiryViewFirstSec
              responseType={location.state.type}
              currentActive={currentActive}
              setCurrentActive={setCurrentActive}
              setCloseCommunication={setCloseCommunication}
            />

            <div className="mt-3  ">
              <Modal
                id="#sucess"
                className="otp-modal"
                show={closeCommunication}
                size="md"
                centered
                onHide={() => setCloseCommunication(false)}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <div className="sup-modal ">
                  <Image src={FavLogo} />
                  <div className="mt-2">
                    <div className="d-flex-as-jc img_tick ">
                      <Image src={Check} />
                      <div className="px-3">
                        <p>Do you want to Decline the Enquiry ?</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row-reverse">
                      <div class="row">
                        <div class="col-sm">
                          <Link
                            style={{
                              backgroundColor: "#D1D3D4",
                              width: "70px",
                              height: "30px",
                            }}
                            className="my-3 modal_btn"
                            to="#"
                            onClick={() => {
                              setCloseCommunication(false);
                            }}
                          >
                            No
                          </Link>
                        </div>
                        <div class="col-sm">
                          <Link
                            style={{
                              width: "70px",
                              height: "30px",
                            }}
                            className="my-3 modal_btn"
                            to="#"
                            onClick={() => {
                              setCloseCommunication(false);
                            }}
                          >
                            Yes
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            {currentContent}
          </div>
        </Col>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default EnquiryView;
