import TopHeader from "../../navbar/TopHeader";
import TopBar from "../../navbar/TopBar";
import BroadCastDropDown from "../../component/BroadCast/BroacCastDropDown";
import LogInFooter from "../../logincomponent/LogInFooter";
import { Col, Image } from "react-bootstrap";
import { IoIosArrowRoundBack } from "react-icons/io";

function CreateBroadCast() {
  return (
    <>
      <TopBar />
      <TopHeader type="broadcast" />
      <div className="create-broadcast-cont my-5 p-2">
        <div className=" d-flex font-col-sec align-items-center gap-2 my-2">
          <h3>
            <IoIosArrowRoundBack />
          </h3>
          <h3>CreateBroadCast</h3>
        </div>
        <div className="flex-resposive">
          <Col sm={12} xs={12} lg={4} md={4} className="my-2">
            <h4 className="my-2 py-1">
              Selected Supplies <span className="font-col-sec">40</span>
            </h4>
            <p className="my-2 py-1 fs-6 opac_half d-flex gap-3">
              <input type="checkbox" /> Select All
            </p>
            <p className="my-2 py-1 fs-6 opac_half">Source Type</p>
            <BroadCastDropDown />
          </Col>
          <Col sm={12} xs={12} lg={8} md={8} className="my-2">
            Create Broad Cast 45
          </Col>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default CreateBroadCast;
