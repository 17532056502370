import React, { useEffect, useState } from "react";
import ReactRoutes from "./routes";
import httpClient from "./constants/HttpClient/httpClient";
import useToken from "./constants/Storage/useToken";
import Loader from "./component/Loader";

const App = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState(false);

  const tokens = localStorage.getItem("token");

  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || `Bearer ${tokens}`;
  }

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactRoutes />
    </>
  );
};

export default App;
