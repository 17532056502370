// @ts-nocheck
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Col, Image, Table } from "react-bootstrap";
import { FaArrowLeft, FaEdit, FaExclamationCircle } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CompanyImg, PersonImg } from "../../assets/img";
import RightAddUser from "../../component/EnterpriseAdmin/RightAddUser";
import Loader from "../../component/Loader";
import { api } from "../../constants/Services";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopBar from "../../navbar/TopBar";
import TopHeader from "../../navbar/TopHeader";

const AdminDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location?.state?.type;
  const Id = location?.state?.id;
  const [loading, setLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState();
  const [role, setRole] = useState();
  const [list, setList] = useState([]);
  const [dep, setDep] = useState([]);
  const [des, setDes] = useState({});

  const viewUser = () => {
    setLoading(true);
    api.viewSupplierUser(Id).then((res) => {
      console.log(
        "🚀 ~ file: AdminDetails.js:29 ~ api.viewSupplierUser ~ res:",
        res
      );

      // DEPARTMENET AND DESIGNATION
      // setUploadImage(res?.supplier_user?.img_url);

      api.createRole().then((resp) => {
        let dep_value = resp?.departments?.filter(
          (i) => i?.id == res?.supplier_user?.sm_department_id
        );

        let des_value = resp?.designations?.filter(
          (i) => i?.id == res?.supplier_user?.sm_designation_id
        );

        // Mst_Role_id

        let user_role = resp?.roles
          ?.filter((role) => role?.id == res?.supplier_user?.Mst_Role_id)
          .reduce((acc, fil_item) => fil_item?.code, "");

        // console.log(
        // "🚀 ~ file: AdminDetails.js:45 ~ api.createRole ~ user_role:",
        // user_role
        // );
        setRole(user_role);

        setDep(dep_value?.[0]);
        setDes(des_value?.[0]);
        setList(res?.supplier_user);
        setUploadImage(res?.supplier_user?.img_url);
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    if (type == "view") {
      viewUser();
    }
  }, []);

  return (
    <div className="col-md-12 d-grid">
      {loading ? <Loader /> : null}
      <TopBar />
      <TopHeader type="enterprice-admin" />
      <Col xxl={7} lg={8} md={10} sm={12} className="min-ht-80 mx-auto">
        <Col md={12} lg={12} sm={12}>
          <div className="d-flex justify-content-end">
            <button
              className="text-blue border-0 fnt-fam-f2   wid-9r h-2_5r bg-light"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </Col>
        <Col className="enterprise-add-user d-flex">
          <div className="mx-auto" style={{ width: "500px" }}>
            <div className="left-add-user mt-lg-4 d-flex mx-4">
              <div
                className="upload-img"
                style={{ borderWidth: uploadImage ? "1px" : "0px" }}
              >
                <Image
                  src={uploadImage ? uploadImage : PersonImg}
                  className="image-placeholder"
                />
              </div>
              <div className="left-txt mt-3 ms-2">
                <p className="mt-0 text-dark">
                  <b>{list?.user_name}</b>
                </p>
                {/* <p className="mt-0">KR LEATHERS PVT. LTD</p>
                <p className="mt-0">Maharastra</p> */}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} className="mx-auto">
          <div
            className="mt-3 mx-auto"
            style={{ paddingLeft: "20px", width: "500px" }}
          >
            <Col className="add-prod-left">
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Employee Id </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{list?.emp_id}</p>
              </div>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Date of Joining </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">
                  {list?.created_at
                    ? moment(list?.created_at).format("DD-MMM-YYYY")
                    : ""}
                </p>
              </div>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Department </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{dep?.value}</p>
              </div>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Designation </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{des?.value}</p>
              </div>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Role </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{role}</p>
              </div>
              <div className="hash-line" />
            </Col>
            <Col className="add-prod-left">
              <button className="cust-btn p-0 mb-1 mt-2">
                Communication Information
              </button>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Email </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{list?.email}</p>
              </div>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Mobile </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{list?.mobile}</p>
              </div>
              <div className="sku-code mt-0">
                <p className="lft-bio primary1 mb-0">Telephone </p>
                <p className="mb-0 primary1">:</p>
                <p className="lft-bio1 text-dark mb-0">{list?.telephone}</p>
              </div>
            </Col>
          </div>
          {/* <Col className="add-prod-left px-5  ">
          <p className="cust-btn p-0 mb-1 mt-2">
            <b>Roles</b>
          </p>
          <p className="desc-p pb-1">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea
            repellat a recusandae. Doloremque nostrum et itaque quaerat enim
            reprehenderit eius id aspernatur repudiandae delectus totam,
            assumenda harum quos quibusdam perspiciatis?
          </p>
          <div className="hash-line" />
          <p className="cust-btn p-0 mb-1 mt-2">
            <b>Brief Profile</b>
          </p>
          <p className="desc-p pb-1">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea
            repellat a recusandae. Doloremque nostrum et itaque quaerat enim
            reprehenderit eius id aspernatur repudiandae delectus totam,
            assumenda harum quos quibusdam perspiciatis?
          </p>
        </Col> */}
        </Col>
      </Col>

      <div className="rg-footer">
        <LogInFooter />
      </div>
    </div>
  );
};

export default AdminDetails;
