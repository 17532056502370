import { post, get, deletes } from "./method";
import { URL } from "../constants";

// LOGIN

export const login = async (data) => await post(URL.LOGIN, data);

export const logout = async () => await get(URL.LOGOUT);

// REGISTER

export const country = async () => await get(URL.COUNTRY);

export const register = async (data) => await post(URL.REGISTER, data);

export const otp = async (data) => await post(URL.VERIFY_OTP, data);

export const resendOtp = async (data) => await post(URL.RESEND_OTP, data);

export const tokenVerify = async (data) => await get(URL.TOKEN_VERIFY + data);

export const setPassword = async (data) => await post(URL.SET_PASSWORD, data);

export const setPass = async (data) => await post(URL.SET_PASS, data);

export const accept_reject = async (data) =>
  await post(URL.ACCEPT_REJECT, data);

// FORGOT PASSWORD

export const forgotPassword = async (data) =>
  await post(URL.FORGOT_PASSWORD, data);

export const forgotPasswordOtp = async (data) =>
  await post(URL.FORGOT_PASSWORD_OTP_VERIFY, data);

// PROFILE

export const profileView = async () => await get(URL.MC_PROFILE);

export const profileUpdate = async (data) =>
  await post(URL.MC_PROFILE_UPDATE, data);

export const getFactories = async () => await get(URL.MC_FACTORIES);

//  DEPARTMENT

export const departmentAdd = async (data) =>
  await post(URL.DEPART_DESIGN, data);

export const departmentEdit = async (id, data) =>
  await post(URL.DEPARTMENT_EDIT + id, data);

export const departmentView = async () => await get(URL.DEPARTMENT_LIST);

export const departmentStatus = async (id) =>
  await get(URL.DEPARTMENT_STATUS + id);

// ADDRESS

export const addressList = async () => await get(URL.ADDRESS);

export const addressAdd = async (data) => await post(URL.ADDRESS_ADD, data);

export const skipUpdate = async (data) => await post(URL.SKIP_UPDATE, data);

export const addressGet = async () => await get(URL.ADDRESS_ADD);

export const stateList = async (id) => await get(URL.STATELIST + id);

export const cityList = async (id) => await get(URL.CITYLIST + id);

export const addressDelete = async (id) =>
  await deletes(URL.ADDRESS_ADD + "/" + id);

export const addressEdit = async (id, data) =>
  await post(URL.ADDRESS_ADD + "/" + id, data);

export const addressView = async (id) => await get(URL.ADDRESS_ADD + "/" + id);

// CERTIFICATE AND AWARDS

export const certificate_awardList = async (params) =>
  await get(URL.CERTIFICATE_AWARD + params);

export const certificate_awardAdd = async (data) =>
  await post(URL.CERTIFICATE_AWARD, data);

export const certificate_awardEdit = async (id, data) =>
  await post(URL.CERTIFICATE_AWARD + "/" + id, data);

export const certificate_awardView = async (id) =>
  await get(URL.CERTIFICATE_AWARD + "/" + id);

export const certificate_awardDelete = async (id) =>
  await deletes(URL.CERTIFICATE_AWARD + "/" + id);

// PAYMENT SETTING

export const addPayment = async (data) => await post(URL.PAYMENT_ADD, data);

export const editPayment = async (id, data) =>
  await post(URL.PAYMENT_ADD + "/" + id, data);

export const viewPayment = async (id) => await get(URL.PAYMENT_ADD + "/" + id);

export const getPayment = async () => await get(URL.PAYMENT_ADD);

export const deletePayment = async (id) =>
  await deletes(URL.PAYMENT_ADD + "/" + id);

export const paymentGet = async (params) =>
  await get(URL.SUPPLIER_BANK + params);

export const paymentAdd = async (data) => await post(URL.SUPPLIER_BANK, data);

export const paymentEdit = async (id, data) =>
  await post(URL.SUPPLIER_BANK + "/" + id, data);

export const paymentView = async (id) =>
  await get(URL.SUPPLIER_BANK + "/" + id);

export const paymentDelete = async (id) =>
  await deletes(URL.SUPPLIER_BANK + "/" + id);

export const supplierBankList = async () => await get(URL.SUPPLIER_BANK_LIST);

export const supplierBranchList = async (data) =>
  await post(URL.SUPPLIER_BRANCH_LIST, data);

// SUPPLIER USER

export const addSupplierUser = async (data) =>
  await post(URL.SUPPLIER_USER, data);

export const listSupplierUser = async (params) =>
  await get(URL.SUPPLIER_USER + params);

export const filterSupplierUser = async (params) =>
  await post(URL.SUPPLIER_USER_FILTER + params);

export const filterSupplierUsers = async (params) =>
  await post(URL.SUPPLIER_USER_FILTERS + params);

export const viewSupplierUser = async (id) =>
  await get(URL.SUPPLIER_USER + "/" + id);

export const editSupplierUser = async (id, data) =>
  await post(URL.SUPPLIER_USER + "/" + id, data);

export const statusSupplierUser = async (id) =>
  await get(URL.SUPPLIER_USERS + "/status/" + id);

export const supplierCreate = async () =>
  await get(URL.SUPPLIER_USER + "/create");

export const monitorLog = async (id) => await get(URL.SUPPLIER_USER_LOGS + id);

// ROLES

export const createRole = async () => await get(URL.ROLES_CREATE);
export const roleCreate = async (id) => await get(URL.ROLE + "/create?type=2");

export const addRole = async (data) => await post(URL.ROLE, data);

export const editRole = async (id, data) =>
  await post(URL.ROLE + "/" + id, data);

export const getRoles = async () => await get(URL.ROLES);

export const viewRole = async (id) => await get(URL.ROLE + "/" + id + "/edit");

export const statusChange = async (id) => await get(URL.ROLE + "/status/" + id);

// CHANGE ADMIN

export const changeAdmin = async (id) => await get(URL.CHANGE_ADMIN + "/" + id);

export const changeAdminPass = async (data) =>
  await post(URL.CHANGE_ADMIN_PASSWORD, data);

export const changeAdminEmail = async (id) =>
  await get(URL.CHANGE_ADMIN_USER + id);

// BRANCH COUNTRY STATE CITY

export const branch = async () => await get(URL.BRANCH);

export const address = async () => await get(URL.ADDRESS);

// TRANSPORT

export const transportGet = async (params) => await get(URL.TRANSPORT + params);

export const transportAdd = async (data) => await post(URL.TRANSPORT, data);

export const transportEdit = async (id, data) =>
  await post(URL.TRANSPORT + "/" + id, data);

export const transportView = async (id) => await get(URL.TRANSPORT + "/" + id);

export const transportDelete = async (id) =>
  await deletes(URL.TRANSPORT + "/" + id);

// CHANGE PASSWORD

export const changePassword = async () => await get(URL.CHANGE_PASSWORD);

// NOTIFICATION

export const getNotify = async () => await get(URL.NOTIFICATION);

// CREATE DETAILS

export const createDetails = async () => await get(URL.CREATE_DETAILS);

// MASTER PRODUCTS

export const masterProductsGet = async (data) =>
  await post(URL.MASTER_PRODUCTS, data);

export const masterProductsGetPage = async (data, page) =>
  await post(URL.MASTER_PRODUCTS + `?page=${page}`, data);

export const masterProducetView = async (data) =>
  await post(URL.MASTER_PRODUCT_VIWE, data);

export const masterProdAdd = async (data) =>
  await post(URL.SUPPLIER_PRO_ADD, data);

export const masterProdView = async (id) =>
  await get(URL.SUPPLIER_PRO_VIEW + id);

export const masterProdEdit = async (data) =>
  await post(URL.SUPPLIER_PRO_EDIT, data);

export const supplierProducts = async (params) =>
  await get(URL.SUPPLIER_PRO_LIST + params);

export const masterProductsFilter = async (data) =>
  await post(URL.MASTER_PRODUCTS_FILTER, data);

export const masterProdFilter = async (data, params) =>
  await post(URL.SUPPLIER_POR_FILTER + params, data);

export const masterProDelete = async (id) =>
  await get(URL.SUPPLIER_PRO_DELETE + id);

export const masterFactorys = async (params) =>
  await post(URL.SUPPLIER_PRO_FACTORY + params);

export const masterDocumentEdit = async (data) =>
  await post(URL.SUPPLIER_DOCUMENT_EDIT, data);

export const masterDocumentDelete = async (id) =>
  await get(URL.SUPPLIER_DOCUMENT_DELETE + id);

// PURCHASE ORDER

export const purchaseOrderList = async (params) =>
  await get(URL.SUPPLIER_PURCHASE + params);

export const purchaseOrderView = async (data) =>
  await post(URL.SUPPLIER_PURCHASE_VIEW, data);

export const purchaseOrderUploadPageDetails = async (data) =>
  await post(URL.PO_UPLOAD_DATAS, data);

export const purchaseOrderUploadSave = async (data) =>
  await post(URL.PO_INVOICE_UPLOAD, data);

export const purchaseOrderInvDcItems = async (data) =>
  await post(URL.PO_DC_INV_LIST, data);

export const purchaseOrderInvAdd = async (data) =>
  await post(URL.PO_INV_ADD, data);

export const purchaseOrderFilter = async (m_type, status, po_date, sup_id) =>
  await post(
    `${URL.SUPPLIER_PURCHASE_FILTER}?material_type=${m_type}&status=${status}&po_date=${po_date}&supplier_id=${sup_id}`
  );

export const purchaseOrderSearch = async (search_word) =>
  await post(`${URL.SUPPLIER_PURCHASE_SEARCH}?keyword=${search_word}`);

export const purchaseOrderTerms = async (params) =>
  await post(URL.SUPPLIER_TERMS + params);

export const approvalRemark = async (data) =>
  await post(URL.APPROVALREMARKS_TERMS, data);

export const invoice_list = async (inv_type, page_no, search) =>
  await get(
    `${URL.INVOICE_LIST}?type=${inv_type}&page=${page_no}&invoice_no=${search}`
  );

export const invoice_view = async (inv_id) =>
  await get(`${URL.INVOICE_VIEW}/${inv_id}`);

export const invoiceFilter = async (params) =>
  await post(URL.INVOICE_FILTER + params);

// INVOICE

export const invoiceView = async (id) => await get(URL.INVOICE_VIEW + "/" + id);

export const invoiceUploadEdit = async (data) =>
  await post(URL.PO_INVOICE_UPLOAD_EDIT, data);

export const invoiceEdit = async (data) =>
  await post(URL.PO_INVOICE_EDIT, data);

// COMMON
export const is_igst = async () => await get(URL.IS_IGST);
export const tax_list = async () => await get(URL.TAX_LIST);

// ASN

export const asnList = async (params) => await get(URL.ASN_LIST + params);

export const asnAdd = async (data) => await post(URL.ASN_STORE, data);

export const asnTable = async (data) => await post(URL.ASN_TABLE, data);

export const asnStore = async (params) => await post(URL.ASN_STORE + params);

export const storeShipment = async (data) =>
  await post(URL.STORE_SHIPMENT, data);

export const asnView = async (ids) => await get(URL.ASN_LIST + "/" + ids);

export const asnCreate = async () => await get(URL.ASN_CREATE);

export const asnFilter = async (params) =>
  await post(URL.ASN_TABLE_FILTER + params);

export const asnDelDates = async (data) =>
  await post(URL.ASN_TABLE_DEL_DATES, data);

export const asnPoGroup = async (data) => await post(URL.ASN_PO_GROUP, data);

export const asnUpdate = async (data) => await post(URL.ASN_UPDATE, data);

// DASHBORD

export const dashboard = async () => await get(URL.DASHBORD);

export const dashboardAsn = async () => await get(URL.DASHBORD_ASN);

export const dashboardGrn = async () => await get(URL.DASHBORD_GRN);

export const dashboardDc = async () => await get(URL.DASHBORD_DC);

export const dashboardAmount = async (data) =>
  await post(URL.DASHBORD_AMOUNT, data);

export const dashboardRetrunNote = async () =>
  await get(URL.DASHBORD_RETRUN_NOTE);

export const dathboardTransit = async () =>
  await get(URL.DASHBORD_ON_IN_TRANSIT);

export const dashboardDelivered = async () =>
  await get(URL.DASHBORD_ON_DELIVERED);

export const dashboardOnGrn = async () => await get(URL.DASHBORD_ON_GRN);

export const dashboardReturn = async () => await get(URL.DASHBORD_ON_RETURN);

export const dashboardUpcoming = async () =>
  await get(URL.DASHBORD_UPCOMING_LIST);

// DC

export const dcCreate = async (data) => await post(URL.DC_CREATE, data);

export const dcPoDetails = async (data) => await post(URL.DC_PO_DETAILS, data);

export const dcPoDetail = async (data) => await post(URL.DC_PO_DETAIL, data);

export const dcFilter = async (data) => await post(URL.DC_TABLE_FILTER, data);

export const dcCreateStages = async (data) =>
  await post(URL.DC_CREATE_STAGES, data);

export const dcCreateStagesMany = async (data) =>
  await post(URL.DC_CREATE_STAGES_MANY, data);

export const dcUpdateStagesMany = async (data) =>
  await post(URL.DC_UPDATE_STAGES_MANY, data);

export const dcUpdate = async (data) => await post(URL.DC_UPDATE, data);

export const dcUpdateStages = async (data) =>
  await post(URL.DC_UPDATE_STAGES, data);

export const dcTables = async (params) => await get(URL.DC_TABLE + params);

export const dcView = async (params) => await post(URL.DC_VIEW + params);

export const dcStagesPort = async () => await get(URL.DC_STAGES_PORT);

export const dcUomList = async () => await get(URL.DC_UOM_LIST);

export const dcCoversion = async (data) => await post(URL.CONVERSION, data);

export const dcCubicCoversion = async (data) =>
  await post(URL.CUBIC_CONVERSION, data);

// GRN

export const grnTabel = async (params) => await get(URL.GRN_TABLE + params);

export const grnTabelFilter = async (params) =>
  await get(URL.GRN_TABLE_FILTER + params);

export const grnView = async (data) => await post(URL.GRN_VIEW, data);

export const grnResponse = async (data) => await post(URL.GRN_RESPONCE, data);

// RETURN NOTE

export const returnNoteView = async (params) =>
  await post(URL.RETURN_NOTE_VIEW + params);

export const returnTable = async (params) =>
  await get(URL.RETURN_NOTE_TABLE + params);

export const returnRemarks = async (params) =>
  await post(URL.RETURN_NOTE_REMARKS + params);

export const invoiceFine = async (data) => await post(URL.INVOICE_FINE, data);

// DRAFTS

export const listDraft = async () => await get(URL.DRAFTS);
export const listAsnDraft = async () => await get(URL.ASN_DARFTS);
export const listDcDraft = async () => await get(URL.DC_DARFTS);
export const saveDraft = async (data) => await post(URL.SAVE_DRAFTS, data);
export const deleteDraft = async (data) => await post(URL.DELETE_DRAFTS, data);
