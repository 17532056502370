import { Col, Image, Table } from "react-bootstrap";
import QuoLastSectionBtn from "../../component/QuoLasSecBtn";
import GeneralCommunication from "../GeneralCommunication";
function QuoCommuSec() {
  return (
    <>
      <GeneralCommunication />
      <QuoLastSectionBtn BtnText="Submit" BtnSize="wid-5r" />
    </>
  );
}

export default QuoCommuSec;
