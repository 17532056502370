import { useNavigate } from "react-router-dom";

function POMainBtn({ POAckClickHandler }) {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-end gap-2 d-flex-wrap my-3 py-2 fs-1r">
      {/* <button
        className="text-blue border-0 fnt-fam-f2 wid-9r h-2_5r bg-light"
        onClick={() => navigate("/purchase-order/list")}
      >
        Close
      </button> */}
      <button
        className="bg-primary1 h-2_5r fnt-fam-f2 text-white border-0 wid-9r rounded-1"
        onClick={POAckClickHandler}
      >
        Acknowledge
      </button>
    </div>
  );
}

export default POMainBtn;
