// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loaderIcon, Logo } from "../assets/img";
import LogInFooter from "../logincomponent/LogInFooter";
import LogInConnect from "../logincomponent/LogInConnect";
import { api } from "../constants/Services";
import Timer from "./TimeCounter";
import Loader from "../component/Loader";
const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [resentLoader, setResentLoader] = useState(false);
  const [RegOtpModal, setRegOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendOtp, setResendOtp] = useState(true);
  const [timer, setTimer] = useState(900);
  const [value, setValue] = useState(false);
  const [validity, setValidity] = useState("45");
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setResendOtp(false);
    }, 50000);
  }, [timer]);

  const submitHandle = () => {
    if (otp?.length == 4) {
      setDisabledBtn(true);
      let data = {
        email: location?.state,
        otp: otp,
      };
      api.otp(data).then((res) => {
        // console.log("res", res);
        setDisabledBtn(false);
        console.log(data);
        if (res?.status == "success") {
          navigate("/newpassword", { state: location?.state });
        }
      });
    } else {
      alert("Enter the Valid otp");
    }
  };
  const reSendOtp = () => {
    let data = {
      email: location?.state,
    };
    setResentLoader(true);
    setResendOtp(true);
    setRegOtpModal(true);
    api.otp(data).then((res) => {
      if (res?.status == "success") {
        setValidity("45");
        alert(res?.otp);
        console.log("res", res);
        setResentLoader(false);
        setTimeout(() => {
          setResendOtp(false);
        }, 45000);
      } else {
        console.log("res", res);
        setResentLoader(false);
      }
    });
  };
  return (
    <div className="login-bg">
      {/* {loader && <Loader />} */}
      <div className="d-flex-al-jc  log_cent">
        <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
          <div className="form_box">
            <Image src={Logo} />
            <h6>OTP Verification</h6>
            <p>Enter OTP sent to your registered Email ID </p>
            <div>
              <input
                className="form-control"
                type="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
              <div className="d-flex-al-jb">
                <button
                  disabled={resendOtp && true}
                  onClick={reSendOtp}
                  className="cust-btn p-0 primary1"
                >
                  <span style={{ opacity: resendOtp ? "0.3" : 1 }}>
                    Resend OTP{" "}
                  </span>
                  {resentLoader && (
                    <img style={{ width: "25px" }} src={loaderIcon} />
                  )}
                </button>
                <button className="cust-btn px-0 d-flex align-item-center">
                  Valid Till {" - "}
                  <Timer setValidity={setValidity} validity={validity} />
                </button>
              </div>
              <Button
                style={{
                  background: "#247395",
                  opacity: disabledBtn ? 0.3 : 1,
                }}
                onClick={submitHandle}
                className="bt-tx"
              >
                Verify
                {disabledBtn && (
                  <img src={loaderIcon} style={{ width: "19px" }} />
                )}
              </Button>
            </div>

            <LogInConnect />
          </div>
        </Col>
      </div>
      <LogInFooter />
    </div>
  );
};
export default VerifyOtp;
