// @ts-nocheck
import "./EnterPriseAdmin.scss";
import { VscBriefcase } from "react-icons/vsc";
import { Button, Col } from "react-bootstrap";
import { VscGear } from "react-icons/vsc";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { RxDotsVertical } from "react-icons/rx";
import Dropdown from "react-bootstrap/Dropdown";
import { FaEllipsisV } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import { ToastContainer, toast } from "react-toastify";
import { PersonImg, PersonLogo } from "../../assets/img";
import ReactPaginate from "react-paginate";

function Card({ setPage, setAdminData }) {
  const navigate = useNavigate();
  const [dropShow, setDropShow] = useState(null);
  const dropHandler = (data) => {
    // console.log(data);
    if (dropShow == data) {
      setDropShow(null);
    } else {
      setDropShow(data);
    }
  };

  // console.log(dropShow, "dropShow");

  const [list, setList] = useState([]);

  const getList = () => {
    api.listSupplierUser().then((res) => {
      // console.log(res);
      setList(res?.supplier_users);
      setPage(res?.supplier_users.length);
      setAdminData(res?.supplier_users);
    });
  };

  const statusChange = (id) => {
    api.statusSupplierUser(id).then((res) => {
      // console.log(res);
      toast(res?.message, { position: toast.POSITION.TOP_RIGHT });
      getList();
    });
  };

  useEffect(() => {
    getList();
  }, []);

  // PAGINATION

  return (
    <>
      <ToastContainer />
      {list == null ? (
        <div className="w-100 text-center mt-4">
          <h5>No User Found</h5>
        </div>
      ) : (
        <>
          {list?.map((item, index) => (
            <>
              <div className="col-12 col-lg-6 p-2">
                <div className="card-container user-list-card-cont mb-4">
                  <div className="user-action-cont">
                    <div className="d-flex gap-3">
                      <img
                        className="user-img"
                        // src="https://picsum.photos/110/110"
                        src={PersonImg}
                      />
                      <div className="user-status-cont">
                        <p>{item?.SPUD_UserName}</p>
                        <p className="status-inner-cont">
                          <span className="pnote-cont">PNOTE-120</span>
                          <span
                            className={
                              item?.SPUD_Status == 2
                                ? "status-span2"
                                : "status-span"
                            }
                          >
                            {item?.SPUD_Status == 2 ? "Deactive" : "Active"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => dropHandler(index)}
                      className="cust-btn dropdown-container-tool p-0"
                      style={{ marginBottom: "auto" }}
                    >
                      <FaEllipsisV />
                    </button>
                    {dropShow == index && (
                      <div className="sort-drop">
                        {/* <Link to="/enterprise-admin/add-user">Edit</Link> */}
                        <button
                          className="btn-use-uses"
                          onClick={() =>
                            navigate("/enterprise-admin/add-user", {
                              state: {
                                type: "edit",
                                id: item?.SupPortUserDtls_PK,
                              },
                            })
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="btn-use-uses"
                          onClick={() => statusChange(item?.SupPortUserDtls_PK)}
                        >
                          {item?.SPUD_Status == 1 ? "Active" : "Deactive"}
                        </button>
                        <button
                          className="btn-use-uses"
                          onClick={() =>
                            navigate(
                              "/enterprise-admin/userlist/user/login-details",
                              {
                                state: {
                                  type: "log",
                                  id: item?.SupPortUserDtls_PK,
                                },
                              }
                            )
                          }
                        >
                          Monitor Log
                        </button>
                        <button
                          className="btn-use-uses"
                          onClick={() =>
                            navigate("/enterprise-admin/admin-detail", {
                              state: {
                                type: "view",
                                id: item?.SupPortUserDtls_PK,
                              },
                            })
                          }
                        >
                          View
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <Col lg={5} sm={5}>
                      <p className="status-icon-span my-1">
                        <VscGear />
                        Department
                      </p>
                      <p>{item?.SPUD_Designation}</p>
                    </Col>
                    <Col lg={7} sm={7}>
                      <p className="status-icon-span my-1">
                        <VscBriefcase />
                        Designination
                      </p>
                      <p>{item?.SPUD_Department}</p>
                    </Col>
                  </div>
                  <div className="contact-cont">
                    <p className="status-icon-span my-1">
                      <BsTelephone />
                      Phone
                    </p>
                    <p>{item?.SPUD_Mobile}</p>
                  </div>
                  <div className="contact-cont">
                    <p className="status-icon-span my-1">
                      <AiOutlineMail />
                      Mail
                    </p>
                    <p>{item?.SPUD_email}</p>
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
      )}
    </>
  );
}

export default Card;
