import { Col, Image, Table } from "react-bootstrap";

function EnqCommunication() {
  return (
    <div className="m-hgt-70vh">
      <Col
        className="px-3 px-lg-0 pt-3 choos-profile-table"
        xs={12}
        md={12}
        lg={10}
      >
        <h5 className="font-col-sec my-2 py-1">Communication</h5>
        <div className="tbale-scroll-horizontal table-sm w-100 py-2 my-1">
          <Table className="px-2 mx-3">
            <tbody>
              <tr className="py-2 my-1">
                <td className="profil-img">
                  <Image
                    src="https://picsum.photos/110/110"
                    className="image-placeholder rounded-1"
                  />
                </td>
                <td className="px-2">
                  <h5 className="text-dark">Praveen</h5>
                  <p className="sub-title">Department</p>
                  <p>CRN(+2 others)</p>
                </td>
                <td className="px-2">
                  <p className="sub-title">Designation</p>
                  <p>Finance Head</p>
                </td>
                <td className="px-2">
                  <p className="sub-title">Mobile No</p>
                  <p>+98765432345</p>
                </td>
                <td className="px-2">
                  <p className="sub-title">Email</p>
                  <p>rubesh.asd@djkfh.cok</p>
                </td>
              </tr>
              <tr className="py-2 my-1">
                <td className="profil-img">
                  <Image
                    src="https://picsum.photos/110/110"
                    className="image-placeholder rounded-1"
                  />
                </td>
                <td className="px-2">
                  <h5 className="text-dark">Praveen</h5>
                  <p className="sub-title">Department</p>
                  <p>CRN(+2 others)</p>
                </td>
                <td className="px-2">
                  <p className="sub-title">Designation</p>
                  <p>Finance Head</p>
                </td>
                <td className="px-2">
                  <p className="sub-title">Mobile No</p>
                  <p>+98765432345</p>
                </td>
                <td className="px-2">
                  <p className="sub-title">Email</p>
                  <p>rubesh.asd@djkfh.cok</p>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>

      <Col
        className="d-flex mt-1 justify-content-end mb-2 pe-3 pe-lg-0"
        xs={12}
        md={12}
        lg={10}
      >
        <button
          // onClick={onclickChangeHandler}
          className="cust-btn text-white bg-primary1 fnt-fam-f2 "
        >
          Close
        </button>
      </Col>
    </div>
  );
}

export default EnqCommunication;
